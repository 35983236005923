import { Injectable } from '@angular/core';
import { Question } from '../../models/vcall/question';
import {
  DrillDownQuestionItem,
  DrillDownQuestions,
} from '../../models/application/application';
import { Applicant } from '../../models/application/applicant';
@Injectable({
  providedIn: 'root',
})
export class EzAppAnswerMapperService {
  /**
   * @ignore ignore
   */

  constructor() {}

  /**
   * @param curentQuestion current question
   * @param drillDownAnswerItems drilldownquestions list
   * @param type type of the question
   * @description find the type of question like qualifying or ailment question like
   */
  drillDownMapper(
    curentQuestion: Question,
    drillDownAnswerItems: DrillDownQuestionItem[],
    type?: string
  ): DrillDownQuestionItem[] {
    let searchText: string;
    let filterDrillDownAnswer = [];
    const filteredQuestions: DrillDownQuestionItem[] = [];

    // if ezapptag in current question then find the drilldown answers
    if (curentQuestion.details.Ezapptag !== undefined) {
      searchText = curentQuestion.details.Ezapptag.trim().split(',');
      drillDownAnswerItems.forEach((drilldownAnswer) => {
        if (this.findQuestion(drilldownAnswer.text, searchText) === true) {
          filteredQuestions.push(drilldownAnswer);
        }
      });
    }

    // After find the drilldown answers filter the drilldown answers based on the current question type
    filterDrillDownAnswer = filteredQuestions.filter(
      (drilldownAnswer) =>
        drilldownAnswer.type === (type ? type : curentQuestion.type)
    );

    // if the filtered answers is empty then filter with drilldownAnswer type 'MedicalPrescriptionAilment'
    if (filterDrillDownAnswer.length === 0) {
      filteredQuestions.map((drilldownAnswer) => {
        switch (drilldownAnswer.type) {
          case 'MedicalPrescriptionAilment': {
            filterDrillDownAnswer.push(drilldownAnswer);
            break;
          }
        }
      });
    }

    // if (filterDrillDownAnswer && filterDrillDownAnswer.length > 1) {
    //   // No two questions should match
    //   alert(
    //     'Found two nodes that match the current vcall question to the answers export out of ezApp. Please adjust ezapp Tags in the admin tool. Selecting the First one to proceeed.'
    //   );
    // }
    return filterDrillDownAnswer;
  }

  /**
   * @param curentQuestion current question
   * @param drillDownAnswerItems drilldownquestions list
   * @param type type of the question
   * @description find the type of question like qualifying or ailment question like
   */

  attributeMapper(curentQuestion: Question, applicant: Applicant) {
    if (
      curentQuestion.details &&
      curentQuestion.details.EzappApplicationAttribute
    ) {
      return applicant[curentQuestion.details.EzappApplicationAttribute];
    }
  }

  /**
   * @param text matched text
   * @param searchTexts which text we have to search in the question text
   * @description find matched text question
   */

  // checking the search text values in the drilldown answer text
  private findQuestion(text, searchTexts) {
    for (let i = 0; i < searchTexts.length; i++) {
      if (text.toLowerCase().indexOf(searchTexts[i].toLowerCase()) === -1) {
        i = searchTexts.length + 1;
        return false;
      }
    }
    return true;
  }
}
