import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { Subscription } from 'rxjs';
import { VcallQuestionTypeBooleanDetail } from '../types/boolean/boolean.component';
import { ApplicationService } from 'src/app/shared/services/application/application.service';

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.scss'],
})
export class ClosingComponent implements OnInit, OnDestroy, AfterViewInit {
  question: Question = null;
  details: VcallQuestionTypeBooleanDetail = new VcallQuestionTypeBooleanDetail();
  subscriptions: Array<Subscription> = new Array<Subscription>();
  elementQuestionIdTrackerId: number;

  @ViewChild('booleanyes') yesSelection: ElementRef;
  @ViewChild('booleanno') noSelection: ElementRef;

  constructor(
    private script: ScriptService,
    private application: ApplicationService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.script.SelectedQuestion.subscribe((q) => {
        this.question = q;
        Object.assign(this.details, this.question.details);
      })
    );
  }

  // HACK: need to refactor after the navigatio logic from Goverdhan is factored in
  ngAfterViewInit() {
    this.subscriptions.push(
      this.script.SelectedQuestion.subscribe((q) => {
        // HACK: need to refactor after the navigation logic from Goverdhan is factored in
        if (this.elementQuestionIdTrackerId !== q.id) {
          this.yesSelection.nativeElement.checked = false;
          this.noSelection.nativeElement.checked = false;
          this.elementQuestionIdTrackerId = q.id;
        }
      })
    );
  }

  optionChanged(answer: any) {
    // this.question.answer = answer;
    this.application.RegisterAnswerByTag(
      answer,
      answer,
      this.question.answerTag,
      null
    );
    this.script.UpdateAnswer(answer, answer);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
