import { Component, OnInit } from '@angular/core';
import { HandledHttpClient } from 'src/app/shared/services/api/handledHttpClient';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
})
export class ReleaseNotesComponent implements OnInit {
  notes: ReleaseNotes[] = [];
  selectedNote;
  constructor(private http: HandledHttpClient) {}

  ngOnInit() {
    this.http
      .get(environment.releaseNotesConfigUrl)
      .subscribe((resp: ReleaseNotes[]) => {
        if (resp) {
          this.notes = resp;
          this.eachNote(this.notes[0]);
        }
      });
  }

  eachNote(note) {
    this.selectedNote = note;
  }
}

export class ReleaseNotes {
  name: string;
  items: Array<string>;
}
