import { PropertyDecorator } from '../../services/application/modelMapperService.service';
import { DiscoverableChainItem } from './discoverableChainItem';
import { DecoratedModelConfiguration } from './decoratedModelConfiguration';
import { Discoverable } from './discoverable';

export class Contact implements Discoverable {
  constructor() {
    this.state = null;
    this.streetAddress = null;
    this.city = null;
    this.eFulfillment = null;
    this.email = null;
    this.homePhone = null;
    this.mobilePhone = null;
    this.state = null;
    this.workPhone = null;
    this.zipCode = null;
  }

  chain: Array<DiscoverableChainItem>;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'streetAddress')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Street address')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'streetAddress')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  streetAddress: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'city')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'City')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'city')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  city: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'state')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'State')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'State')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 30)
  state: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'zipCode')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Zip Code')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'Zip Code')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 40)
  zipCode: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'email')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Email Address')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'email')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 50)
  email: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'homePhone')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Home Phone')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'homePhone')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 60)
  homePhone: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'mobilePhone')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Mobile Phone')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'mobilePhone')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 70)
  mobilePhone: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'workPhone')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Work Phone')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'workPhone')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 80)
  workPhone: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'eFulfillment')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'eFulfillment')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'eFulfillment')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 90)
  eFulfillment: boolean;
}
