import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Application } from 'src/app/shared/models/application/application';
import { Payment } from 'src/app/shared/models/application/payment';
import { VcallQuestionTypePaymentDetail } from 'src/app/shared/models/vcall/paymentDetail';
import { Question } from 'src/app/shared/models/vcall/question';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { BaseQuestionType } from '../../../types/baseQuestionType';
import { Answer } from 'src/app/shared/models/application/answer';

@Component({
  selector: 'app-question-monthly-payment-mode',
  templateUrl: './monthly-payment-mode.component.html',
  styleUrls: ['./monthly-payment-mode.component.scss'],
})
export class MonthlyPaymentModeComponent
  extends BaseQuestionType<VcallQuestionTypePaymentDetail>
  implements OnInit, OnDestroy {
  details: VcallQuestionTypePaymentDetail;
  question: Question = null;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  paymentDetails = new VcallQuestionTypePaymentDetail();
  application: Application = null;
  payment: Payment = null;
  editPayment: Payment;
  donotagree = false;
  paymentQuestionAnswered = false;

  @ViewChild('paymentno') paymentno: ElementRef;

  promptText: string;
  isVerified = false;
  openModal = false;
  openConfirmationModal = false;
  paymentAnswers: Answer[];
  isVerifiedButton = null;

  constructor(
    public script: ScriptService,
    public applicationService: ApplicationService,
    private modalService: NgbModal,
    public uiService: UiServiceService
  ) {
    super(script, applicationService, uiService);
  }

  ApplicationChanged(): void {
    const newText = this.safeInterpolate(
      this.paymentDetails.methodOfPaymentAgreementText
    );
    // HACK : not sure why this made it work;
    setTimeout(() => (this.promptText = newText), 0);
  }

  QuestionChanged(q: Question): void {
    Object.assign(this.paymentDetails, this.question.details);
    this.promptText = this.safeInterpolate(
      this.paymentDetails.methodOfPaymentAgreementText
    );
  }
  cancel(action: any) {
    if (action === 'Cancel') {
      this.openModal = false;
    }
  }

  CanGoNext(): boolean {
    this.isVerified =
      this.isVerified ||
      (this.answer !== null &&
      this.answer !== undefined &&
      this.answer.verification !== undefined
        ? this.answer.verification
        : false);
    return this.isVerified;
  }

  ResetView(): void {}

  optionChanged(verificationSelection: any) {
    this.isVerified = verificationSelection;
    this.isVerifiedButton = this.isVerified;

    this.saveAnswer(verificationSelection, this.selectedValue);
  }

  editQuestion(content: any): void {
    this.editPayment = Object.assign({}, this.payment);
    if (content === 'Done') {
      this.paymentno.nativeElement.checked = false;
      this.originalValue = this.selectedValue;
      this.saveAnswer(this.isVerified, this.selectedValue);
      this.openModal = false;
      this.checkIfPaymentQuestionsAnswered();
    }
  }
  checkIfPaymentQuestionsAnswered() {
    const paymentAnswerTags = JSON.parse(this.details.paymentAnswerTags);
    // const paymentAnswerTags = ['Payment.InitialBankInformation', 'payment.jointAccount', 'payment.initialFamilyInformation', 'Payment.BankInitialAuthorization'];
    this.paymentAnswers = this.applicationService.GetSectionAnswers(
      paymentAnswerTags
    );
    if (this.paymentAnswers.length > 0) {
      this.openConfirmationModal = true;
    }
  }
  confirm(action: any) {
    if (action === 'Done') {
      this.applicationService.DeleteQuestionAnswers(this.paymentAnswers);
    }
  }
  cancelPaymentInfo(action: any) {
    if (action === 'Cancel') {
      this.openConfirmationModal = false;
    }
  }
}
