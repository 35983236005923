<div class="row justify-content-end">
  <div>
    <button class="btn btn-primary btn-md mb-3" (click)="open(content)">
      Edit
    </button>
  </div>
</div>
<div>
  <app-verifiable-list
    [model]="verifiableList"
    *ngIf="GetModel()"
    (formComplete)="verificationComplete($event)"
    [isCustomEdit]="true"
  ></app-verifiable-list>

  <div
    *ngIf="question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Edit Demographic Details
      </h4>
      <button
        type="button"
        class="close outline-0"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #f="ngForm">
        <app-usps-address
          (formComplete)="OnformCompleted($event)"
          (formValidity)="OnAddressFormValidity($event)"
          [address]="{
            streetAddress: editContact.streetAddress,
            city: editContact.city,
            state: editContact.state,
            zipCode: editContact.zipCode
          }"
          [saveClicked]="saveClicked"
        ></app-usps-address>
        <div *ngIf="isAddressInValid" class="alert alert-warning small">
          Address is invalid, please make sure you entered correct Street
          Address, Zip, City and State. However, you can still continue to
          proceed.
        </div>
        <div class="position-relative">
          <ngx-spinner
            bdOpacity="0.9"
            bdColor="#f4f4f9"
            size="default"
            color="#000000"
            type="ball-spin-clockwise"
            [fullScreen]="false"
          >
          </ngx-spinner>
        </div>
        <!-- <app-usps [address]="{streetAddress: editContact.streetAddress, city: editContact.city, state: editContact.state, zipCode: editContact.zipCode}"></app-usps> -->
        <div class="form-group">
          <label for="homePhone"
            >Home Phone <span class="text-danger"></span
          ></label>
          <div class="input-group">
            <input
              id="homePhone"
              pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
              type="tel"
              name="homePhone"
              [(ngModel)]="editContact.homePhone"
              #homePhone="ngModel"
              class="form-control"
              placeholder="Home Phone"
              mask="(000) 000-0000"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
            />
          </div>
          <div
            *ngIf="
              homePhone.invalid &&
              (homePhone.dirty || homePhone.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="homePhone.errors.required">
              Home Phone is required.
            </div>
            <div *ngIf="homePhone.errors.pattern">
              Invalid phone format (XXX) XXX-XXXX.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="mobilePhone"
            >Mobile Phone <span class="text-danger"></span
          ></label>
          <div class="input-group">
            <input
              id="mobilePhone"
              pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
              type="tel"
              name="mobilePhone"
              [(ngModel)]="editContact.mobilePhone"
              #mobilePhone="ngModel"
              class="form-control"
              placeholder="Mobile Phone"
              mask="(000) 000-0000"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
            />
          </div>
          <div
            *ngIf="
              mobilePhone.invalid &&
              (mobilePhone.dirty || mobilePhone.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="mobilePhone.errors.required">
              Mobile Phone is required.
            </div>
            <div *ngIf="mobilePhone.errors.pattern">
              Invalid phone format (XXX) XXX-XXXX.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="workPhone"
            >Work Phone <span class="text-danger"></span
          ></label>
          <div class="input-group">
            <input
              id="workPhone"
              pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
              type="tel"
              name="workPhone"
              [(ngModel)]="editContact.workPhone"
              #workPhone="ngModel"
              class="form-control"
              placeholder="Work Phone"
              mask="(000) 000-0000"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
            />
          </div>
          <div
            *ngIf="
              workPhone.invalid &&
              (workPhone.dirty || workPhone.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="workPhone.errors.required">
              Work Phone is required.
            </div>
            <div *ngIf="workPhone.errors.pattern">
              Invalid phone format (XXX) XXX-XXXX.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email <span class="text-danger">*</span></label>
          <div class="input-group">
            <input
              id="email"
              class="form-control"
              [email]="true"
              name="email"
              required
              type="email"
              [(ngModel)]="editContact.email"
              #email="ngModel"
              placeholder="Email"
            />
          </div>
          <div
            *ngIf="
              email.invalid && (email.dirty || email.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="email.errors.required">
              Email is required.
            </div>
            <div *ngIf="email.errors.email">
              Invalid Email.
            </div>
          </div>
        </div>
        <!-- <div class="form-group">
          <label for="eFullFillment">eFullFillment *</label>
          <div>
            <label class="radio-inline mr-3">
              <input type="radio" id="eFullFillment" required name="eFullFillment" value="true"
                [(ngModel)]="editContact.eFullFillment" #eFullFillment="ngModel" name="eFullFillment" /> TRUE</label>
            <label class="radio-inline">
              <input type="radio" id="eFullFillment" required name="eFullFillment" value="false"
                [(ngModel)]="editContact.eFullFillment" #eFullFillment="ngModel" name="eFullFillment" /> FALSE</label>
          </div>
          <div *ngIf="
              eFullFillment.invalid &&
              (eFullFillment.dirty || eFullFillment.touched)
            " class="mt-1 text-danger small">
            <div *ngIf="eFullFillment.errors.required">
              eFullFillment is required.
            </div>
          </div>
        </div> -->
      </form>
    </div>
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.dismiss('Cancel')"
      >
        Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="Save(f)">
        Save
      </button>
    </div>
  </ng-template>
</div>
