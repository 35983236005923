import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SearchRecall } from '../../models/recall/SearchRecall';
import { RecallStatus } from '../../models/recall/RecallStatus';
import { RecallInfo } from '../../models/recall/RecallInfo';
import { Recall } from '../../models/recall/Recall';
import { VcallApiService } from '../api/vcall/vcall-api.service';
import { ApplicationService } from '../application/application.service';

@Injectable({
  providedIn: 'root',
})
export class RecallService {
  constructor(
    private appService: ApplicationService,
    private vcallApi: VcallApiService
  ) {}
  recallCallComplete(appointment) {
    // this.appService.RecallQuestions.subscribe(ques => {
    //   const filteredQues = ques.filter(el => el.appointmentId === appointment.appointmentId).map(item => {
    //     item.status = RecallStatus.completed;
    //     item.questionList.map(data => data.status === RecallStatus.completed);
    //     return item;
    //   }).shift();
    //   this.vcallApi.insertRecallQuestions(filteredQues, String(appointment.appId));
    // });
  }
}
