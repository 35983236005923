declare var $: any;
import { DatePipe } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Application } from 'src/app/shared/models/application/application';
import { RecallType } from 'src/app/shared/models/recall/RecallType';
import { RecallStatus } from 'src/app/shared/models/recall/RecallStatus';
import { RecallQuestion } from 'src/app/shared/models/recall/RecallQuestion';
import { RecallInfo } from 'src/app/shared/models/recall/RecallInfo';
import { RecallContact } from 'src/app/shared/models/recall/RecallContact';
import { Recall } from 'src/app/shared/models/recall/Recall';
import { RecallsViewModel } from 'src/app/shared/models/recall/RecallsViewModel';
import { RecallReasons } from 'src/app/shared/models/recall/recallReason';
import { CallZoneApiService } from 'src/app/shared/services/api/callzone/callzone-api.service';
import { VcallApiService } from 'src/app/shared/services/api/vcall/vcall-api.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { Answer } from 'src/app/shared/models/application/answer';
import { ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { CallZoneUser } from 'src/app/shared/models/callzone-user';
import {
  TabsPersistenceService,
  Tab,
} from 'src/app/shared/services/tabspersistence.service';
import {
  QuestionData,
  QuestionModal,
} from '../recall-addquestion/recall-addquestion-modal';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { AdditionalInfoService } from 'src/app/underwriting/components/additional-info/additional-info.service';
import {
  ApplicationStatus,
  ApplicationTrackerType,
} from 'src/app/underwriting/models/enum';
import { ApplicationTracker } from 'src/app/underwriting/components/additional-info/additional-info-modal';

@Component({
  selector: 'app-manage-recall',
  templateUrl: './manage-recall.component.html',
  styleUrls: ['./manage-recall.component.scss'],
  providers: [DatePipe],
})
export class ManageRecallComponent implements OnInit, OnDestroy, DoCheck {
  applicationId: number;
  addQuestionForm: UntypedFormGroup;
  showFooter = true;
  appId;
  recallAppointmentData: QuestionModal;
  listofAddedQuestions: RecallQuestion[] = [];
  appointmentId: number; //
  application: Application;
  subscriptions = new Array<Subscription>();
  recallIndex: number;
  questionIndex: number; //
  questionList: Array<RecallQuestion> = new Array<RecallQuestion>();
  recallReasons: RecallReasons;
  recallApplicants: RecallContact[];
  openModal = false;
  openDeleteModal = false;
  editQuestionScript = false;
  underWriter: string;
  applicantQuestionsSize: number;
  thirdPartyQuestionsSize: boolean;
  vm: RecallsViewModel;
  recallBeingEdited: Recall;
  questionBeingEdited: RecallQuestion;
  canSearch = true;
  openViewModal = false;
  scheduleButtonDisabled = false;
  recallResp: RecallQuestion;
  callzoneUser: CallZoneUser;
  clearData = false;
  deleteRecallModal = false;
  deleteRecallObj;
  saveActionType = 'Save' || 'Save and Schedule';
  getNameKey = RecallQuestion.GetNameKey;
  public reasonsList = [
    { name: 'Specific Health Question' },
    { name: 'MIB' },
    { name: 'RX Profile' },
    { name: 'Bank Auth' },
    { name: 'Claims HX' },
    { name: 'V-Call Error by Verifier' },
    { name: 'Other' },
  ];
  timeZone: string;
  disableRecallButton: boolean = false;
  applicationTrackerRecord: ApplicationTracker;
  recalls: Recall[] = [];

  constructor(
    private appService: ApplicationService,
    private userProfileService: UserProfileService,
    private date: DatePipe,
    private vcallApiService: VcallApiService,
    private callzoneApiService: CallZoneApiService,
    private fb: UntypedFormBuilder,
    private toast: ToastService,
    private route: ActivatedRoute,
    private userService: UserProfileService,
    public tabsPersistenceService: TabsPersistenceService,
    private additionalInfoService: AdditionalInfoService
  ) {}

  ngOnInit() {
    this.timeZone = this.userProfileService.getCallzoneUser()?.timeZone;
    this.initForm();
    // this.addQuestionForm.controls.applicantName.valueChanges.subscribe((e) => (this.selectedApplicant = e));
    const id = this.route.snapshot.params['applicationId'];
    this.applicationId = id;

    if (id) {
      this.appId = id;
      this.additionalInfoService.fetchApplicationTrackerRecords(this.appId);
      this.subscriptions.push(
        this.additionalInfoService.applicationTrackerRecords.subscribe(
          (response) => {
            if (response && response.length > 0) {
              this.applicationTrackerRecord = response.find(
                (a) =>
                  a.applicationTrackerType === ApplicationTrackerType.Primary
              );
              if (
                this.applicationTrackerRecord &&
                Object.keys(this.applicationTrackerRecord).length > 0
              ) {
                this.disableRecallButton =
                  !this.additionalInfoService.isApplicationStatusValid(
                    this.applicationTrackerRecord
                  );
              }
            }
          }
        )
      );
      this.getDetails();
      this.canSearch = false;
    }

    this.subscriptions.push(
      this.appService.SelectedApplication.subscribe((res) => {
        if (res) {
          this.application = res;
          this.processRecalls();
        }
      })
    );

    this.subscriptions.push(
      this.userProfileService.userProfile.subscribe((res) => {
        if (res) {
          this.underWriter = res.name;
        }
      })
    );
    this.subscriptions.push(
      this.userService.callZoneUser.subscribe((u) => {
        if (u) {
          this.callzoneUser = u;
        }
      })
    );

    this.subscriptions.push(
      this.appService.ApplicationRecalls.subscribe((recalls) => {
        this.recalls = recalls;
        this.processRecalls();
      })
    );
  }

  processRecalls() {
    const appId = Number(this.applicationId) || this.application.id;
    if (this.recalls && this.recalls.every((r) => r.appId === appId)) {
      const paymentMonthly = this.appService.GetAnswerForAnswerTag(
        RecallInfo.applicationPayorMonthly
      );

      const paymentInitial = this.appService.GetAnswerForAnswerTag(
        RecallInfo.applicationPayorInitial
      );

      const answerList = new Array<Answer>();
      this.recalls.map((r) => {
        r.questionList.map((q) => {
          const answer = this.appService.GetAnswerForAnswerTag(q.answerTag);
          if (answer) {
            answerList.push(answer);
          }
        });
      });

      this.vm = new RecallsViewModel(
        this.recalls,
        paymentMonthly,
        paymentInitial,
        this.application,
        answerList
      );
    }
  }

  ngDoCheck() {
    $('[data-toggle="popover"]').popover();
    $('.popover').css({ minWidth: '330px' });
  }

  initForm() {
    this.addQuestionForm = this.fb.group({
      recallReason: [null, Validators.required],
      applicant: [null, Validators.required],
      questionText: [null, Validators.required],
    });
  }

  get formData() {
    return this.addQuestionForm.value;
  }

  getDetails() {
    this.appService.SelectApplication(this.appId);
  }

  ScheduleAppointment(recall: Recall) {
    this.scheduleButtonDisabled = true;
    const randomGuid = UUID.UUID();
    const applicationId = {
      applicationId: Number(this.appId),
      UnderwriterResourceId: this.callzoneUser.resourceId,
      RecallId: randomGuid,
      scheduledDate: Date.now().toString(),
    };
    this.subscriptions.push(
      this.callzoneApiService
        .CreateAppointment(applicationId)
        .subscribe((res: any) => {
          const appointmentId = res ? res.appointmentId : null;
          if (appointmentId && appointmentId > 0) {
            this.scheduleButtonDisabled = false;

            recall.appointmentId = appointmentId;
            recall.status = RecallStatus.scheduled;
            recall.questionList.map((q) => (q.status = RecallStatus.scheduled));
            recall.recallId = randomGuid;
            // this.filterRecallQuestions();
            this.constructAnswerTagForRecall(recall, appointmentId);
            this.saveRecall(recall);
            this.appService.LoadRecall(this.appId);
          } else {
            this.scheduleButtonDisabled = true;
            this.toast.error(
              null,
              'Callzone was unable to create a valid appointment. Please, retry'
            );
            return;
          }
        })
    );
  }

  clearSearch() {
    if (this.appId === '') {
      this.application = null;
    }
  }

  createRecall(contactType: RecallType) {
    this.saveActionType = 'Save and Schedule';
    this.recallBeingEdited = this.constructRecall(contactType);
    this.recallApplicants = this.vm.GetContactInformation(contactType);
    this.editQuestionScript = false;
    this.openModal = true;
    this.addQuestionForm.reset();
  }

  addQuestion(recall: Recall) {
    this.saveActionType =
      recall.status === 'Scheduled' ? 'Save' : 'Save and Schedule';
    this.recallBeingEdited = recall;
    this.editQuestionScript = false;
    this.recallApplicants = this.vm.GetContactInformation(recall.contactType);
    this.openModal = true;
    this.showFooter = true;
    this.addQuestionForm.reset();
  }

  constructRecall(contactType: RecallType): Recall {
    return {
      appId: Number(this.appId),
      status: RecallStatus.unsaved,
      created: this.date.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
      lastUpdated: null,
      state: this.application.state,
      appointmentId: null,
      contactType,
      questionList: [],
    };
  }

  constructQuestion(question: RecallQuestion) {
    if (question) {
      question.recallReason = this.formData.recallReason;
      question.applicantName = this.formData.applicant.fullName;
      question.questionText = this.formData.questionText;
      question.lastUpdated = this.date.transform(
        new Date(),
        'MM/dd/yyyy hh:mm a'
      );
      question.clientNo = this.formData.applicant.clientNo;
      question.relationship = this.formData.applicant.relationship;
      return question;
    } else {
      return {
        createdBy: this.underWriter,
        recallReason: this.formData.recallReason,
        applicantName: this.formData.applicant.fullName,
        questionText: this.formData.questionText,
        created: this.date.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
        lastUpdated: null,
        status: RecallStatus.pending,
        answerTag: null,
        appointmentId: null,
        clientNo: this.formData.applicant.clientNo,
        relationship: this.formData.applicant.relationship,
      };
    }
  }
  /**
   * Called when Submit action is triggered in recallAddQuestion Popup
   */
  saveCustomAddedQuestions() {
    this.listofAddedQuestions.map((eachQuestion: any, index) => {
      eachQuestion.clientNo = this.recallAppointmentData.clientNo;
      eachQuestion.recallReason =
        this.recallAppointmentData[index]?.reason ||
        this.recallAppointmentData?.reason;
      eachQuestion.reason =
        this.recallAppointmentData[index]?.reason ||
        this.recallAppointmentData?.reason;
      eachQuestion.applicantName =
        this.recallAppointmentData[index]?.applicantName ||
        this.recallAppointmentData.applicantName;
      eachQuestion.relationship =
        this.recallAppointmentData[index]?.relationship ||
        this.recallAppointmentData.relationship;
      eachQuestion.clientNo =
        this.recallAppointmentData[index]?.clientNo ||
        this.recallAppointmentData.clientNo;
      eachQuestion.questionText = eachQuestion.text;
      eachQuestion.type = eachQuestion.type;
      eachQuestion.sequence = index + 1;
      eachQuestion.created = this.date.transform(
        new Date(),
        'MM/dd/yyyy hh:mm a'
      );
      (eachQuestion.createdBy = this.underWriter),
        (eachQuestion.lastUpdated = this.date.transform(
          new Date(),
          'MM/dd/yyyy hh:mm a'
        ));
      return eachQuestion;
    });
    this.listofAddedQuestions.unshift(...this.recallBeingEdited.questionList);
    const recall = this.recallBeingEdited;
    recall.questionList = this.listofAddedQuestions;
    if (recall.appointmentId === null) {
      this.scheduleNow(recall);
    } else {
      this.constructAnswerTagForRecall(recall, recall.appointmentId);
      this.saveRecall(recall);
    }
    this.listofAddedQuestions = [];
  }

  saveQuestion() {
    this.openModal = false;
    const question = this.constructQuestion(this.questionBeingEdited);
    // if id is null, it means it is a new recall.
    const recall = this.recallBeingEdited;
    recall.contactType = this.formData.applicant.contactType;

    if (this.editQuestionScript === false) {
      recall.questionList.push(question);
      if (recall.status === RecallStatus.unsaved) {
        this.vm.AddRecall(recall);
      }

      // this.filterRecallQuestions();
    } else {
      recall.questionList[this.questionIndex] = Object.assign(
        recall.questionList[this.questionIndex],
        question
      );
    }
    this.constructAnswerTagForRecall(recall, recall.appointmentId);
    this.saveRecall(recall);
  }
  constructAnswerTagForRecall(recall, appointmentId) {
    recall.questionList.map((q, index) => {
      q.status = recall.status;
      q.answerTag = (
        RecallInfo.callType +
        '-' +
        String(appointmentId) +
        '-' +
        index
      ).toString();
    });
    return recall;
  }

  deleteQuestion(
    question: RecallQuestion,
    questionIndex: number,
    recall: Recall
  ) {
    this.questionIndex = questionIndex;
    this.recallBeingEdited = recall;
    this.questionBeingEdited = question;
    this.openDeleteModal = true;
  }

  confirmDelete(action: any) {
    if (action === RecallInfo.done) {
      this.recallBeingEdited.questionList.splice(this.questionIndex, 1);
      this.saveRecall(this.recallBeingEdited);
    }

    this.openDeleteModal = false;
  }

  editQuestion(question: RecallQuestion, questionIndex, recall) {
    this.questionBeingEdited = question;
    this.recallBeingEdited = recall;
    this.questionIndex = questionIndex; // we should just add id in the question model to keep this simple
    this.openModal = true;
    this.showFooter = false;
    this.recallApplicants = this.vm.GetContactInformation(recall.contactType);
    this.editQuestionScript = true; // this flag controls the popup template between editquestion and new question.
    question['applicant'] = this.recallApplicants.find(
      (a) => a.fullName === question.applicantName
    );
    this.addQuestionForm.patchValue(question);
    delete question['applicant'];
  }

  cancel() {
    // this.getNotifyEvent();
    // this.addQuestionForm.reset();
    this.openModal = false;
    this.deleteRecallModal = false;
  }
  clearRecallQuestionsForm() {
    this.recallAppointmentData = {};
    this.listofAddedQuestions = [];
  }
  SubmitAndCancelHandler(ev) {
    this.openModal = false;
    this.openViewModal = false;
    if (ev?.toLowerCase() === 'done') {
      this.saveCustomAddedQuestions();
    }
    this.clearData = true;
    this.clearRecallQuestionsForm();
  }

  getNotifyEvent() {
    this.openModal = false;
    this.openViewModal = false;
  }

  getRecallReasons() {
    this.subscriptions.push(
      this.callzoneApiService.GetRecallReasons().subscribe((res: any) => {
        if (res) {
          this.reasonsList = res;
        }
      })
    );
  }

  scheduleNow(recall: Recall) {
    this.ScheduleAppointment(recall);
  }

  // TODO: move this to the recall service
  saveRecall(recall: Recall) {
    if (recall.status === RecallStatus.unsaved) {
      recall.status = RecallStatus.pending;
    }
    const message = recall;
    this.vcallApiService
      .insertRecallQuestions(message, this.appId)
      .subscribe((res: any) => {
        this.appService.getRecallQuestions(this.appId);
        this.appService.LoadRecall(this.appId);
      });
  }

  viewResp(question: RecallQuestion) {
    this.openViewModal = true;
    this.recallResp = question;
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }
  getUniqueUsers(application: any) {
    return [
      ...new Set(application.questionList.map((item) => item.clientNo)),
    ] as string[];
  }

  getApplicantNameFromClientNo(clientNo: string, recall) {
    return this.getNameKey(
      recall.questionList.find((item) => item.clientNo === clientNo)
    );
  }

  getUniqueUsersFromCompletedRecalls() {
    const users = [];
    this.vm.completedRecalls.forEach((application) => {
      application.questionList.forEach((question) => {
        users.push(this.getNameKey(question));
      });
    });
    return [...new Set(users)] as string[];
  }
  saveListOfAddedQuestions(ev) {
    this.listofAddedQuestions = ev;
  }

  saveFormData(ev) {
    this.recallAppointmentData = ev;
  }
  saveSelection(uniqueId: string, tabNumber: number) {
    const tab: Tab = {};
    tab.uniqueId = uniqueId;
    tab.value = tabNumber;
    this.tabsPersistenceService.addOrUpdateSelectedTab(tab);
  }

  getFilteredAppointment(appointmentId) {
    const appointmentsList =
      this.appService.GetAnswerForAnswerTag('appointment').value;
    return appointmentsList.filter(
      (elemnt) => appointmentId === elemnt.appointmentId
    )[0];
  }
  // delete Recall start
  deleteRecall(recall: Recall) {
    this.deleteRecallObj = recall;
    this.deleteRecallModal = true;
  }

  confirmDeleteRecall() {
    this.vcallApiService
      .deleteRecall(this.deleteRecallObj, this.appId)
      .subscribe((res: any) => {
        this.appService.getRecallQuestions(this.appId);
        this.appService.LoadRecall(this.appId);
        this.saveSelection(this.appId + '-selectedRecall', 1);
      });
    this.deleteRecallModal = false;
  }
  // delete Recall start
}
