import { Injectable } from '@angular/core';
import { HandledHttpClient } from './api/handledHttpClient';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RxProfile } from './processors/types/rxProfileAuthorizationProcessor';

@Injectable({
  providedIn: 'root',
})
export class RxProfileService {
  baseUrl: string;
  constructor(private http: HandledHttpClient) {
    this.baseUrl = environment.config.apis.rxProfile;
  }

  /**
   * @param applicationId application Id
   * @param relationship relationship as a string
   * @description fetch the rxProfile information
   */
  public GetRxProfile(applicationId: string, relationship: string) {
    return this.http.get<boolean>(
      `${this.baseUrl}/IsAuthorizedAsync/${applicationId}/${relationship}`
    );
  }

  /**
   * @param rxprofile rxProfile
   * @description get authorized rxProfile information
   */
  public AuthorizeRxProfile(rxprofile: RxProfile) {
    return this.http
      .post<string>(this.baseUrl + '/AuthorizeRxProfileAsync', rxprofile, {
        errorMessage: `Error authorizing the rx profile for '${rxprofile.applicationId}'`,
        successMessage: 'Changes saved!',
      })
      .toPromise();
  }
}
