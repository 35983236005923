import { Injectable, Injector } from '@angular/core';
import { AppointmentService } from '../../appointment/appointment.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';

@Injectable({
  providedIn: 'root',
})
export class EndCallAsCompletedProcessor implements Processor {
  name = 'Setting appointment status to Complete in CallZone';
  mustWaitForFinish: boolean;
  stopIfError: any;
  stopProcessingResult: boolean;

  constructor(private injector: Injector) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    let appointmentService = this.injector.get(AppointmentService);
    this.stopProcessingResult = await appointmentService.EndCallAsCompleted(
      model.application,
      model.appointment,
      model.userProfile,
      model['completeCallType'],
      model['recallGuid']
    );
    return Promise.resolve({
      result: true,
      stopProcessing: !this.stopProcessingResult,
    });
  }
}
