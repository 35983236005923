import { FilterParameter } from '../question-filter.service';
import { BaseFilter } from './base-filter';

export class ApplicationAttributeFilter extends BaseFilter {
  Type = 'ApplicationAttribute';

  Filter(parameter: FilterParameter) {
    let canDisplay = false;
    if (
      !Array.isArray(
        parameter.application[parameter.config.path.split('.')[0]]
      ) &&
      parameter.application[parameter.config.path.split('.')[0]][
        parameter.config.path.split('.')[1]
      ] === parameter.config.value
    ) {
      canDisplay = true;
    } else if (
      Array.isArray(parameter.application[parameter.config.path.split('.')[0]])
    ) {
      const value = parameter.application[
        parameter.config.path.split('.')[0]
      ].filter(
        (obj) =>
          obj[parameter.config.path.split('.')[1]] === parameter.config.value
      );
      if (value.length > 0) {
        canDisplay = true;
      }
    }
    return canDisplay;
  }
}
