import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  constructor() {}

  private type = {};

  public start(id: number) {
    this.type[id] = {}
    this.type[id].time = new Date();
  }

  public stop(id: number) {
    const now = dayjs();
    this.type[id].duration = Math.round(
      now.diff(this.type[id].time, 'seconds', true)
    );
  }

  public getDuration(id: number) {
    return this.type[id].duration;
  }
}
