<div class="row justify-content-end">
  <div class="col-sm-4 text-right font-weight-bold">
    Cannot proceed without a 'Yes' response.
  </div>
  <div class="col-sm-11 mr-5 mt-4 pb-3">
    <label class="float-right ml-3" for="introductory-1a">
      <div
        class="btn btn-success btn-check-next carousel-next"
        (click)="optionChanged(this.details.yesValue)"
      >
        <input
          class="form-check-input"
          type="radio"
          name="boolean-1"
          #booleanyes
          value="{{ this.details.yesValue }}"
          id="introductory-1a"
        />&nbsp; {{ this.details.yesText }}
      </div>
    </label>
    <label class="float-right" for="introductory-1b">
      <div
        class="btn btn-danger btn-check-next carousel-next"
        (click)="optionChanged(this.details.noValue)"
      >
        <input
          class="form-check-input"
          type="radio"
          name="boolean-1"
          #booleanno
          value="{{ this.details.noValue }}"
          id="introductory-1b"
        />&nbsp; {{ this.details.noText }}
      </div>
    </label>
  </div>
</div>
