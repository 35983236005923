import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';

import { VcallQuestionTypePaymentDetail } from 'src/app/shared/models/vcall/paymentDetail';
import { BaseVerifiableListQuestionType } from '../../types/verifiableList/baseVerifiableListQuestionType';
import { Payment } from 'src/app/shared/models/application/payment';

@Component({
  selector: 'app-common-bank-view',
  templateUrl: './common-bank-view.component.html',
  styleUrls: ['./common-bank-view.component.scss'],
})
export class VcallCommonBankViewComponent
  extends BaseVerifiableListQuestionType<VcallQuestionTypePaymentDetail, string>
  implements OnDestroy {
  subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(
    public scriptService: ScriptService,
    applicationService: ApplicationService
  ) {
    super(scriptService, applicationService);
  }

  GetModel(): Payment {
    return this.application.payment;
  }
  GetSubGroup() {
    if (this.question) {
      return this.question.type;
    }
  }

  ApplicationModified() {}

  QuestionModified() {}

  CanProceedNext = () => {
    return true;
  };

  GetBaseAnswerTag = () => {
    return 'payment';
  };

  ResetView(): void {}
  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }

  GetText(): string {
    return this.applicationService.getQuestionText(this.question);
  }
}
