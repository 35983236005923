import { Injectable } from '@angular/core';
import { ApplicationStringsService } from '../shared/services/applicationStrings/application-strings.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStringsInit {
  constructor(private applicationStringsService: ApplicationStringsService) {}

  Init(): Promise<void[]> {
    return Promise.all([
      this.applicationStringsService.loadStrings(
        'notificationMessages',
        'VCallStrings',
        'strings'
      ),
      this.applicationStringsService.loadStrings(
        'voiceMailModelMessages',
        'VCallStrings',
        'VoiceMailStrings'
      ),
      this.applicationStringsService.loadStrings(
        'APS',
        'RequestStrings',
        'RequestStrings'
      ),
    ]);
  }
}
