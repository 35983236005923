<div class="row">
  <div class="col-3 left-nav py-4 pl-5">
    <div class="row">
      <h6 class="col pb-2 mr-5 mb-3 border-bottom text-center">
        Training Manuals
      </h6>
    </div>
    <div class="row" *ngFor="let content of Documents">
      <div
        class="col pt-2 pb-2 ui-active mr-5"
        (click)="eachDocument(content)"
        [ngClass]="{ 'ui-active': selectedDocument === content }"
      >
        {{ content?.title }}
      </div>
    </div>
  </div>
  <div class="col p-2" style="height: calc(100vh - 118px);">
    <div class="cnt-area p-3" *ngIf="Documents.length > 0">
      <iframe
        width="100%"
        height="772"
        [src]="selectedDocument?.url | safe: 'resourceUrl'"
      ></iframe>
    </div>
  </div>
</div>
