<ng-template [ngIf]="simpleValueOutput"
  ><b>{{ simpleValueOutput }}</b></ng-template
>

<ng-template [ngIf]="objectValueOutput">
  <div *ngFor="let key of objectKeys(objectValueOutput)">
    <div>
      <b>{{ key }}</b>
    </div>
    <div>{{ objectValueOutput[key] }}</div>
  </div>
</ng-template>

<ng-template [ngIf]="arrayValueOutput">
  <div *ngIf="arrayValueOutput.length > 0">
    <table
      class="table-striped table-sm table-bordered col-sm-6"
      *ngFor="let item of arrayValueOutput"
    >
      <!-- TODO:// Create templates -->
      <tr *ngFor="let key of objectKeys(item)">
        <td *ngIf="isNotAnObject(item[key])">
          <b>{{ key }}</b>
        </td>
        <td *ngIf="isNotAnObject(item[key])">{{ item[key] }}</td>
        <td *ngIf="!isNotAnObject(item[key]) && isHandled(key, item[key])">
          <b>{{ key }}</b>
        </td>
        <td *ngIf="!isNotAnObject(item[key]) && isHandled(key, item[key])">
          {{ getDisplayValue(key, item[key]) }}
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="arrayValueOutput.length === 0">
    <b>No records found</b>
  </div>
</ng-template>
