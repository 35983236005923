<form class="smart-form" id="sForm" [formGroup]="smartForm">
  <ng-container *ngFor="let opt of smartOptions">
    <input
      class="radio hide-radio-inputs"
      type="radio"
      formControlName="dateOption"
      [value]="opt.value"
      [id]="opt.value"
    />
    <label
      class="mr-2 btn m-0"
      [for]="opt.value"
      (click)="selecteDateType(opt.value)"
      [ngClass]="
        isSelectedItem(opt.value) ? 'btn-success' : 'btn-outline-success'
      "
      >{{ opt.name }}</label
    >
  </ng-container>
  <div class="pt-2">
    <div class="form-group" *ngIf="selectedItems['type'] === 'ExactDate'">
      <div class="input-group col-md-6 col-sm-12 p-0">
        <input
          #d="ngbDatepicker"
          class="form-control"
          formControlName="date"
          ngbDatepicker
          (click)="d.toggle()"
          readonly
          [maxDate]="maxDate"
          [minDate]="minDate"
          (ngModelChange)="changeDate()"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary fa fa-calendar"
            type="button"
            (click)="d.toggle()"
          ></button>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="selectedItems['type'] === 'DateRange'">
      <div class="form-group col-md-6 col-sm-12 p-0 pt-2">
        <select
          name="period"
          id="period"
          formControlName="period"
          (change)="changeDate()"
          class="form-control"
        >
          <option value="" selected disabled>Please Select</option>
          <option *ngFor="let opt of periodOptions" [value]="opt.value">{{
            opt.name
          }}</option>
        </select>
      </div>
      <div class="form-group col-md-8 col-sm-12 p-0" *ngIf="!hideNotes">
        <label for="notes" class="text-muted" [innerHTML]="notesLabel"></label>
        <textarea
          formControlName="notes"
          id="notes"
          name="notes"
          type="text"
          class="form-control"
          autocomplete="off"
          rows="5"
        ></textarea>
      </div>
    </div>
  </div>
  <!-- <div class="form-group mt-3">
    <input type="button" class="btn btn-primary" form="sForm" [disabled]="smartForm.invalid" (click)="onSubmit()"
      value="Submit" />
  </div> -->
</form>
