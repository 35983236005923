import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { PrescriptionLookupComponent } from '../vcall/prescription-lookup/prescription-lookup.component';
import { ApplicantQualifiedOccupationComponent } from '../vcall/questions/applicant-qualified-controls/applicant-qualified-occupation/applicant-qualified-occupation.component';
import { ApiDataComponent } from './api-data/api-data.component';
import { DeveloperComponent } from './developer.component';
import { ProcessorTestComponent } from './processors/processor-test/processor-test.component';
import { ProviderLookupComponent } from './providerLookup/providerLookup.component';
import { SmokeTestComponent } from './smoke-test/smoke-test.component';

const routes: Routes = [
  {
    path: 'developer/api-data',
    component: ApiDataComponent,
  },
  {
    path: 'developer/processors',
    component: ProcessorTestComponent,
  },
  {
    path: 'developer/controls',
    component: DeveloperComponent,
  },
  {
    path: 'developer/controls/occupationWrapper',
    component: ApplicantQualifiedOccupationComponent,
  },
  {
    path: 'developer/controls/prescriptionWrapper',
    component: PrescriptionLookupComponent,
  },
  {
    path: 'developer/controls/providerWrapper',
    component: ProviderLookupComponent,
  },
  {
    path: 'developer/smoke-test',
    component: SmokeTestComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [
    DeveloperComponent,
    ProviderLookupComponent,
    ApiDataComponent,
    ProcessorTestComponent,
    SmokeTestComponent,
  ],
})
export class DeveloperModule {}
