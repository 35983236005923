export class CallZoneUser {
  public resourceId: number;
  public name: string;
  public email: string;
  public timeZone?: string;
  public lastLogin: Date;
  status?: number;
  error?: string;
  isActive?: boolean;
}

export class NgbDateModel {
  public year: number;
  public month: number;
  public day: number;
}
