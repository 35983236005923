<div class="row m-0">
  <div class="col-sm-12 pb-3 border-bottom">
    <div class="row">
      <div class="col-sm-6">
        <label for=""
          >Select Applicant
          <span class="text-danger">*</span>
        </label>

        <select
          class="form-control"
          name="applicantName"
          (change)="selectChange($event)"
          [(ngModel)]="selectedApplicant"
        >
          <option [ngValue]="null"></option>
          <option
            class="text-uppercase"
            *ngFor="let applicant of recallApplicants"
            [ngValue]="applicant"
            [selected]="recallApplicants.length === 1 ? true : false"
          >
            {{ applicant.fullName }}
            <span
              *ngIf="
                applicant.relationship &&
                (applicant.relationship !== null ||
                  applicant.relationship !== '')
              "
              >({{ applicant.relationship }})</span
            >
          </option>
        </select>
      </div>
      <div class="col-sm-6">
        <label for="">Select Reason <span class="text-danger">*</span></label>
        <select
          class="form-control"
          name="reason"
          (change)="selectChange($event)"
          [(ngModel)]="selectedReason"
        >
          <option></option>
          <option *ngFor="let reason of reasonsList" [value]="reason.name">
            <p>
              {{ reason.name }}
            </p>
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-sm-12 pb-3 pt-2 border-bottom">
    <div class="row">
      <div class="col-sm-6 border-right">
        <label for="">
          Search Question Bank
        </label>
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center">
            <label for="keyword" class="pl-3 mr-5 mr-3 questionfilter-label">
              <input
                type="radio"
                id="keyword"
                name="questionFilter"
                class="radio-prop mr-3"
                value="question"
                [(ngModel)]="questionFilter"
              />
              Keyword</label
            >
            <label for="drillDown" class="pl-3 mr-5 mr-3 questionfilter-label">
              <input
                type="radio"
                id="drillDown"
                name="questionFilter"
                class="radio-prop mr-3"
                value="section"
                [(ngModel)]="questionFilter"
              />
              Drill Down</label
            >
          </div>
        </div>

        <div class="row">
          <div class="col-sm-9 pr-0">
            <ng-select
              [typeahead]="searchQuestion$"
              groupBy="group"
              [items]="searchedQuestions"
              [multiple]="true"
              bindLabel="text"
              [closeOnSelect]="false"
              [(ngModel)]="selectedQuestions"
              [selectableGroup]="true"
              autofocus
              width="55px"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
                let-item$="item$"
              >
                <div class="custom-control">
                  <input
                    type="checkbox"
                    [(ngModel)]="item$.selected"
                    class="custom-control-input"
                    class=""
                  />&nbsp;
                  <label
                    class="form-check-label ml-2"
                    data-toggle="tooltip"
                    data-placement="left"
                    [title]="removeHtmlTags(item.text)"
                    [innerHTML]="item.text"
                  ></label>
                </div>
              </ng-template>
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
                *ngIf="questionFilter === 'section'"
              >
                <input
                  id="item-{{ index }}"
                  type="checkbox"
                  *ngIf="questionFilter === 'section'"
                />&nbsp;
                <span>{{ item.group }}</span>
              </ng-template>
              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <input
                  id="item-{{ index }}"
                  type="checkbox"
                  [(ngModel)]="item$.selected"
                  class="custom-control-input"
                />&nbsp;
                <span
                  data-toggle="tooltip"
                  data-placement="left"
                  [title]="removeHtmlTags(item.text)"
                  [innerHTML]="item.text"
                ></span>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-sm-3 pl-0">
            <button
              class="btn btn-primary border ml-2"
              (click)="ImportFromQuestionBank()"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <label for="">
          Custom Question
        </label>
        <textarea
          cols="50"
          rows="6"
          [(ngModel)]="customQuestionText"
          class="form-control"
        ></textarea>
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-8 d-flex justify-content-end p-0">
              <button
                class="btn btn-light border m-2"
                (click)="clearCustomQuestionText()"
              >
                Clear
              </button>
              <button
                class="btn btn-primary border m-2"
                (click)="addCustomQuestionTolistofAddedQuestions()"
                [disabled]="!customQuestionText"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 pt-2">
    <div>
      <div
        class="row"
        *ngFor="let addedQuestion of listOfAddedQuestions; index as index"
      >
        <div class="col-sm-11 p-0 mb-2">
          <ng-container *ngIf="!addedQuestion.isEdit; else displayTextarea">
            <div
              [innerHTML]="addedQuestion.text"
              class="mb-2 border addQuestionItem"
            ></div>
          </ng-container>
          <ng-template #displayTextarea>
            <textarea
              name="note"
              id="notes"
              class="form-control pb-1"
              rows="3"
              [(ngModel)]="addedQuestion.text"
              [ngClass]="{ 'bg-white': addedQuestion.isEdit }"
              [disabled]="!addedQuestion.isEdit"
            ></textarea>
          </ng-template>
        </div>
        <div class="col-sm-1 p-0">
          <div class="row-reverse">
            <div class="col-sm-6 p-0">
              <button
                class="btn btn-light border p-2 action-buttons"
                *ngIf="!addedQuestion.isEdit"
                (click)="editAddedQuestionFromList(index)"
              >
                <i
                  class="fa fa-edit edit-icon p-1 cursor-pointer text-center text-primary"
                ></i>
              </button>
              <button
                class="btn btn-light border p-2 action-buttons"
                *ngIf="addedQuestion.isEdit"
                [disabled]="!addedQuestion.text"
                (click)="saveEditedQuestion(index, addedQuestion.text)"
              >
                <i class="fas fa-save text-primary p-1"></i>
              </button>
            </div>
            <div class="col-sm-6 p-0">
              <button
                class="btn btn-light border p-2 action-buttons"
                *ngIf="!addedQuestion.isEdit || !addedQuestion.text"
                (click)="deleteQuestionFromListOfQuestion(index)"
              >
                <i
                  class="far fa-trash-alt text-danger p-1 cursor-pointer text-center"
                  [ngClass]="{
                    'f-14': !addedQuestion.text,
                    'f-16': !addedQuestion.isEdit
                  }"
                ></i>
              </button>
            </div>
            <div class="col-sm-6 p-0">
              <button
                class="btn btn-light border p-2 action-buttons"
                *ngIf="addedQuestion.isEdit && addedQuestion.text"
                (click)="addedQuestion.isEdit = false"
              >
                <i class="fas fa-times p-1 text-danger f-16"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
