import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CallZoneApiService } from '../api/callzone/callzone-api.service';
import { HandledHttpClient } from '../api/handledHttpClient';
import { ApplicationService } from '../application/application.service';
import { RxProfile } from '../processors/types/rxProfileAuthorizationProcessor';

@Injectable({
  providedIn: 'root',
})
export class EFullfillmentService {
  baseUrl: string;
  constructor(private http: HandledHttpClient) {
    this.baseUrl = environment.config.apis.efulfillment;
  }

  /**
   * @param applicationId application Id
   * @param relationship relationship as a string
   * @description fetch the efulfillment information
   */
  public GetEfulfillment(applicationId: string) {
    return this.http.get<boolean>(
      `${this.baseUrl}/IsAuthorizedAsync/${applicationId}/p`
    );
  }

  /**
   * @param applicationId , email address.
   * @description get authorized rxProfile information
   */
  public SendEfulfillmentEmail(applicationId: number, emailAddress: string) {
    return this.http
      .post(
        this.baseUrl + '/SendEmailAsync',
        { applicationID: applicationId, email: emailAddress },
        {
          errorMessage: `Error Sending Efulfillment email for '${applicationId}'`,
          successMessage: 'Efulfillment Email Sent',
        }
      )
      .toPromise();
  }
}
