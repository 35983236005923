import { Injectable, Injector } from '@angular/core';
import { AppointmentAnswerService } from '../../appointmentAnswer/appointmentAnswer.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';

@Injectable({
  providedIn: 'root',
})
export class SetAppointmentStatusToCompleteProcessor implements Processor {
  name = 'Setting appointment status to Complete in Vcall';
  mustWaitForFinish: boolean;
  stopIfError: any;
  stopProcessingResult: boolean = false;

  constructor(private injector: Injector) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    let appointmentAnswerService = this.injector.get(AppointmentAnswerService);
    await appointmentAnswerService.setAppointmentStatusToComplete(
      model.question,
      model.appointment
    );
    return Promise.resolve({
      result: true,
      stopProcessing: this.stopProcessingResult,
    });
  }
}
