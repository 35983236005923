import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
  name: 'maskAccountNumber'
})
export class MaskAccountNumberPipe implements PipeTransform {
 
  transform(accountNumber: string): string {
    if (!accountNumber) return accountNumber;
    const visibleDigits = 4;
    const maskedSection = '*'.repeat(accountNumber.length - visibleDigits);
    const visibleSection = accountNumber.slice(-visibleDigits);
    return maskedSection + visibleSection;
  }
}
 