<div [innerHtml]="getDisplayText()"></div>
<div class="row justify-content-end">
  <div
    *ngIf="question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>
  <div
    *ngIf="details.instructionsIfYes && booleanyes.checked"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ details.instructionsIfYes }}
  </div>
  <div
    *ngIf="details.instructionsIfNo && booleanno.checked"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ details.instructionsIfNo }}
  </div>
  <!-- <div class="col-sm-2 font-weight-bold">
    <div class="form-check">
      <label class="form-check-label" for="introductory-1a">
        <input class="form-check-input" (click)="optionChanged(this.details.yesValue)" type="radio" name="boolean-1"
          #booleanyes value="{{ this.details.yesValue }}" id="introductory-1a" />
        {{ this.details.yesText }}
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label" for="introductory-1b">
        <input class="form-check-input" #booleanno (click)="optionChanged(this.details.noValue)" type="radio"
          name="boolean-1" value="{{ this.details.noValue }}" id="introductory-1b" />
        {{ this.details.noText }}
      </label>
    </div>
  </div> -->
  <div class="col-sm-11 mr-5 mt-4 pb-3">
    <label class="float-right ml-3 hover-success" for="introductory-1a">
      <div
        [ngClass]="{ 'btn-success': booleanyes.checked }"
        class="btn border border-success btn-check-next carousel-next first-letter-yes"
        (click)="optionChanged(this.details.yesValue)"
      >
        <input
          class="form-check-input"
          [style.visibility]="'hidden'"
          type="radio"
          name="boolean-1"
          #booleanyes
          value="{{ this.details.yesValue }}"
          id="introductory-1a"
        />{{ this.details.yesText }}
      </div>
    </label>
    <label class="float-right hover-danger" for="introductory-1b">
      <div
        [ngClass]="{ 'btn-danger': booleanno.checked }"
        class="btn border border-danger btn-check-next carousel-next first-letter-no"
        (click)="optionChanged(this.details.noValue)"
      >
        <input
          class="form-check-input"
          [style.visibility]="'hidden'"
          type="radio"
          name="boolean-1"
          #booleanno
          value="{{ this.details.noValue }}"
          id="introductory-1b"
        />{{ this.details.noText }}
      </div>
    </label>
  </div>
</div>
