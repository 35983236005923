import { Injectable } from '@angular/core';
import { VcallApiService } from '../../api/vcall/vcall-api.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';

@Injectable({
  providedIn: 'root',
})
export class TestProcessor implements Processor {
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'MarkApplicationExtendedFlagProcessor';

  constructor(private api: VcallApiService) {}

  Process(model: ProcessorModel): Promise<ProcessingResult> {
    let resolve;
    const promise = new Promise<ProcessingResult>((r) => (resolve = r));
    this.api.GetApplication('4003418').subscribe((a) => {
      const result = { result: a, stopProcessing: false };
      resolve(result);
    });

    return promise;
  }
}
