<form #effectiveDate="ngForm">
  <div class="row">
    <div class="col-6">
      <p [innerHTML]="questionText$ | async" class="m-0"></p>
      <ng-container
        *ngTemplateOutlet="samePaymentForRecurringInitial"
      ></ng-container>
      <div *ngIf="samePaymentTypeConfirmation">
        <b>{{ question.details?.initialPayment }}</b>
        <ng-container
          *ngTemplateOutlet="samePaymentForRecurringInitial"
        ></ng-container>
        <span>{{ question.details?.isThisCorrect }}</span>
      </div>
    </div>
    <ng-template #samePaymentForRecurringInitial>
      <span *ngIf="payment.requestedEffectiveDate === 'SpecificDate'">
        {{ question.details?.onTheSpecificDate }}
      </span>
      <span
        *ngIf="this.payment.requestedEffectiveDate === 'ApplicationApproval'"
      >
        {{ question.details?.onTheDateOfApplicationApproval }}
      </span>
      <span *ngIf="this.payment.requestedEffectiveDate === 'OnTheNext'">
        {{ question.details?.onTheNextDate }}
      </span>
    </ng-template>
    <div class="col-2 text-right">
      <label
        class="cursor-pointer btn border border-success"
        [ngClass]="
          paymentAnswers.effectiveDate.verified === 'true'
            ? 'btn-success'
            : 'btn-outline-success'
        "
      >
        <input
          type="radio"
          required
          name="effectiveDateVerify"
          id="effectiveDateVerify"
          [value]="details.verifiedValue"
          [checked]="paymentAnswers.effectiveDate.verified === 'true'"
          (change)="optionChanged(details.verifiedValue)"
          class="hide-radio-inputs"
        />
        {{ details.verifiedText }}
      </label>
    </div>
    <div class="col-2">
      <label
        class="cursor-pointer btn border border-danger"
        [ngClass]="
          paymentAnswers.effectiveDate.verified === 'false'
            ? 'btn-danger'
            : 'btn-outline-danger'
        "
      >
        <input
          type="radio"
          required
          [checked]="paymentAnswers.effectiveDate.verified === 'false'"
          name="effectiveDateVerify"
          id="effectiveDateVerify"
          [value]="details.notVerifiedValue"
          (change)="optionChanged(details.notVerifiedValue)"
          class="hide-radio-inputs"
        />

        {{ details.notVerifiedText }}
      </label>
    </div>
    <div class="col-2 text-right">
      <button
        class="btn btn-primary carousel-next"
        (click)="editQuestion(content)"
      >
        Edit
      </button>
    </div>
  </div>
</form>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Edit Recurring Payment Details
    </h4>
    <button
      type="button"
      class="close outline-0"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #effectiveEdit="ngForm">
      <div class="row mb-2 pt-3">
        <div class="col-sm-3">
          <label>Requested Effective Date:</label>
        </div>
        <div class="col-sm-9">
          <div class="row m-0">
            <div class="col-sm-3 p-0">
              <label
                class="btn"
                [ngClass]="
                  editPayment.requestedEffectiveDate === 'SpecificDate'
                    ? 'btn-success'
                    : 'btn-outline-success'
                "
              >
                <input
                  type="radio"
                  name="effectiveDate"
                  id="specificDate"
                  value="SpecificDate"
                  #effectiveDate="ngModel"
                  [(ngModel)]="editPayment.requestedEffectiveDate"
                  class="hide-radio-inputs"
                />

                {{ details.specificDate }}
              </label>
            </div>

            <div class="col-sm-4">
              <label
                class="btn"
                [ngClass]="
                  editPayment.requestedEffectiveDate === 'OnTheNext'
                    ? 'btn-success'
                    : 'btn-outline-success'
                "
              >
                <input
                  type="radio"
                  name="effectiveDate"
                  id="nextDate"
                  value="OnTheNext"
                  #effectiveDate="ngModel"
                  [(ngModel)]="editPayment.requestedEffectiveDate"
                  class="hide-radio-inputs"
                />

                {{ details.nextDate }}
              </label>
            </div>

            <div class="col-sm-5 p-0">
              <label
                class="btn"
                [ngClass]="
                  editPayment.requestedEffectiveDate === 'ApplicationApproval'
                    ? 'btn-success'
                    : 'btn-outline-success'
                "
              >
                <input
                  type="radio"
                  name="effectiveDate"
                  id="applicationApproval"
                  value="ApplicationApproval"
                  #effectiveDate="ngModel"
                  [(ngModel)]="editPayment.requestedEffectiveDate"
                  class="hide-radio-inputs"
                />

                {{ details.applicationApproval }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row mb-2 pt-3 mx-0"
        *ngIf="editPayment.requestedEffectiveDate === 'SpecificDate'"
      >
        <div class="col-sm-2">Effective Date:</div>

        <ngb-datepicker
          [startDate]="paymentAnswers.effectiveDate.value"
          [displayMonths]="1"
          [navigation]="'select'"
          [showWeekNumbers]="false"
          [outsideDays]="'visible'"
          [markDisabled]="isDisabled"
          name="effectiveDateSelect"
          [minDate]="minDate"
          [maxDate]="maxDate"
          #effectiveDateSelect="ngModel"
          [(ngModel)]="paymentAnswers.effectiveDate.value"
        >
        </ngb-datepicker>
      </div>
      <div class="row pt-3 pb-3">
        <div class="col-sm-3" for="frequency" aria-label="Frequency of Payment">
          Frequency of Payment
        </div>
        <div class="col-sm-4">
          <select
            class="form-control"
            id="frequency"
            name="paymentFrequency"
            #paymentFrequency="ngModel"
            [(ngModel)]="editPayment.modeOfPayment"
          >
            <option disabled>Select Frequency</option>
            <option *ngFor="let frequency of frequencies" [value]="frequency">{{
              frequency | titlecase
            }}</option>
          </select>
        </div>
        <div
          class="col-sm-5 d-flex"
          *ngIf="editPayment.requestedEffectiveDate === 'OnTheNext'"
        >
          <label class="col-sm-5 pl-3">Select Date</label>
          <select
            id="selectedNextDt"
            name="selectedNextDt"
            #selectedNextDt="ngModel"
            [(ngModel)]="editPayment.onTheNextDateDay"
            class="col-sm-6 form-control"
          >
            <option *ngFor="let item of nextDateList">{{ item + 1 }}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-end">
    <!-- <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cancel')"
    >
      Cancel
    </button> -->
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="effectiveEdit.invalid"
      (click)="modal.close('Done')"
    >
      Save
    </button>
  </div>
</ng-template>
