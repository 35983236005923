import { KeyValue } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { HandledHttpClient } from 'src/app/shared/services/api/handledHttpClient';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { AppKeyValuePair } from 'src/app/vcall/components/cascaded-dropdown/cascaded-dropdown.component';
import { CascadedDropdownConfigurator } from 'src/app/vcall/components/cascaded-dropdown/cascaded-dropdwon-configurator';
import { environment } from 'src/environments/environment';
import {
  ApplicantQualifiedBaseComponent,
  BaseApplicantQualifiedAnswer,
} from '../applicant-qualified-base-component';
import { ApplicantQualifiedDropDownGeneric } from '../default-applicant-qualified-question-detail';

@Component({
  selector: 'app-applicant-qualified-occupation',
  templateUrl: './applicant-qualified-occupation.component.html',
  styleUrls: ['./applicant-qualified-occupation.component.scss'],
})
export class ApplicantQualifiedOccupationComponent extends ApplicantQualifiedBaseComponent<
  ApplicantQualifiedDropDownGeneric,
  OccupationDetailsAnswer
> {
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    private http: HandledHttpClient,
    protected applicantFilterService: ApplicantFilterService
  ) {
    super(script, appService, ui, applicantFilterService);
  }
  public configurator: CascadedDropdownConfigurator = {
    label1: 'Domain/Field Name',
    label2: 'Occupation',
    dataKey: 'Occupation',
    key1: null, // Fill with the stored value if any
    key2: null, // Fill with the stored value if any
  };

  list: AppKeyValuePair[];
  baseUrl = environment.config.apis.ezapp;
  details: ApplicantQualifiedDropDownGeneric =
    new ApplicantQualifiedDropDownGeneric();
  duties = '';
  items: Array<KeyValue<string, string>> = [];
  configuratorO: Observer<CascadedDropdownConfigurator>;
  configurator$: Observable<CascadedDropdownConfigurator> =
    new Observable<CascadedDropdownConfigurator>((observer) => {
      this.configuratorO = observer;
    });
  isEdit = false;
  nextEnabled = false;

  Save() {
    this.currentAnswer.duties = this.duties;
    this.currentAnswer.fieldName = null;
    this.currentAnswer.fieldCode = null;
    this.currentAnswer.occupationName = null;
    this.currentAnswer.occupationCode = null;

    if (this.ezappValue !== null) {
      this.currentAnswer.ezappfieldCode = this.ezappValue.key;
      this.currentAnswer.ezappfieldName = this.ezappValue.value;
    }

    if (this.items.length > 0) {
      this.currentAnswer.fieldName = this.items[0].value;
      this.currentAnswer.fieldCode = this.items[0].key;
    }
    if (this.items.length > 1) {
      this.currentAnswer.occupationName = this.items[1].value;
      this.currentAnswer.occupationCode = this.items[1].key;
    }

    super.Save(this.currentAnswer);
  }

  CascadeResult(result: Array<KeyValue<string, string>>) {
    this.items = result;
    this.Save();
  }

  ResetApplicant() {
    this.duties = null;
    this.items = [];
  }

  BindApplicant() {
    this.nextEnabled = false;
    this.ezappValue = { Key: this.selectedApplicant.occupation, Value: null };
    this.GetEzAppOccupation();

    if (this.currentAnswer.fieldCode) {
      this.isEdit = true;
    }
  }

  CanGoNext(): boolean {
    return (
      this.currentAnswer !== null &&
      this.currentAnswer.fieldCode &&
      this.currentAnswer.fieldCode !== '' &&
      this.currentAnswer.occupationCode &&
      this.currentAnswer.occupationCode !== '' &&
      this.currentAnswer.duties !== undefined &&
      this.currentAnswer.duties !== null &&
      this.currentAnswer.duties !== ''
    );
  }

  EnableNext() {
    this.currentAnswer.duties = this.duties;
    if (!this.nextEnabled) {
      super.Save(this.currentAnswer);
      this.nextEnabled = true;
    }
    if (this.duties === '') {
      super.Save(this.currentAnswer);
      this.nextEnabled = false;
    }
  }
  InitializeApplicantAnswerInstance(): OccupationDetailsAnswer {
    return new OccupationDetailsAnswer();
  }
  canEditOccupation() {
    this.isEdit = true;
  }

  SendConfiguration() {
    this.duties = this.currentAnswer ? this.currentAnswer.duties : '';
    // this.configurator.label1 = this.details.firstLabel ?? this.details.firstLabel;
    // this.configurator.label2 = this.details.secondLabel;
    this.configurator.key1 =
      this.currentAnswer &&
      this.currentAnswer.fieldCode !== null &&
      this.currentAnswer.fieldCode !== undefined &&
      this.currentAnswer.fieldCode !== ''
        ? this.currentAnswer.fieldCode
        : this.ezappValue.key;
    this.configurator.key2 =
      this.currentAnswer &&
      this.currentAnswer.occupationCode !== null &&
      this.currentAnswer.occupationCode !== ''
        ? this.currentAnswer.occupationCode
        : null;

    if (this.details.label1) {
      this.configurator.label1 = this.details.label1;
    }

    if (this.details.label2) {
      this.configurator.label2 = this.details.label2;
    }

    if (this.configuratorO) {
      this.configuratorO.next(this.configurator);
    }
  }

  // TODO: Ideally this should be implemented in a service

  private GetEzAppOccupation() {
    if (this.ezappValue.Key !== null) {
      if (this.list === undefined) {
        this.http
          .get<Array<AppKeyValuePair>>(
            this.baseUrl + `${this.configurator.dataKey}`
          )
          .subscribe((data: any) => {
            this.list = data;
            const item = this.list.find((i) => i.Key === this.ezappValue.Key);
            if (item) {
              this.ezappValue = { key: item.Key, value: item.Value };
            }
            this.SendConfiguration();
          });
      } else {
        const item = this.list.find((i) => i.Key === this.ezappValue.Key);
        if (item) {
          this.ezappValue = { key: item.Key, value: item.Value };
        }
        this.SendConfiguration();
      }
    }
  }
}

export class OccupationDetailsAnswer extends BaseApplicantQualifiedAnswer {
  fieldName: string;
  fieldCode: string;
  occupationName: string;
  occupationCode: string;
  duties: string;
  ezappfieldName: string;
  ezappfieldCode: string;
  answerType = 'OccupationDetailsAnswer';
}
