import { Answer } from 'src/app/shared/models/application/answer';
import { Question } from 'src/app/shared/models/vcall/question';
import {
  FilterConfiguration,
  FilterParameter,
} from '../question-filter.service';
import { BaseFilter } from './base-filter';
import { QualifiedQuestionFilter } from './qualified-question-filter';

export class ExpressQuestionFilter extends BaseFilter {
  Type = 'ExpressQuestion';
  private EXPRESS_MODE = 'expressmode';

  Filter(parameter: FilterParameter) {
    if (
      parameter.question.details[this.EXPRESS_MODE] === undefined ||
      parameter.question.details[this.EXPRESS_MODE] === Expressmode.express ||
      // these questions are just stand alone question that does not participate in the express/dormant strategy
      parameter.question.details[this.EXPRESS_MODE] === Expressmode.always
    ) {
      return true;
    }

    let canDisplay = false;

    const questions = parameter.script.items.find(
      (i) => i.section.name === parameter.question.section.name
    ).questions;

    const expressMode: Expressmode =
      parameter.question.details[this.EXPRESS_MODE];
    switch (expressMode) {
      case Expressmode.dormant:
      case Expressmode.prescriptionBottom:
        canDisplay =
          parameter.application.isLong ||
          this.IfAnyExpressIsPositive(questions, parameter.answers) ||
          this.IfCatchAllTopIsTrue(questions, parameter.answers);
        break;
      case Expressmode.catchAllTop:
      case Expressmode.prescriptionTop:
        // if isLong return false, if Express check if all Express Are negative.
        canDisplay = parameter.application.isLong
          ? false
          : this.AreAllExpressNegative(questions, parameter.answers);
        break;
      case Expressmode.catchAllBottom:
        // if is Long always ask this question.
        canDisplay = parameter.application.isLong
          ? true
          : this.IfAnyExpressIsPositive(questions, parameter.answers);
        break;
      default:
        console.log('Invalid ExpressMode, race condition');
        alert('Invalid ExpressMode, race condition');
        break;
    }

    return canDisplay;
  }

  private AreAllExpressNegative(
    questions: Question[],
    answers: Answer[]
  ): boolean {
    let result = true;
    const tags = this.GetQuestionTagsByExpressMode(
      Expressmode.express,
      questions
    );
    const results = this.ApplyQualifiedQuestionFilter(answers, tags);

    result = !results.some((r) => r.canDisplay === true);
    return result;
  }

  private IfAnyExpressIsPositive(
    questions: Question[],
    answers: Answer[]
  ): boolean {
    let result = true;
    const tags = this.GetQuestionTagsByExpressMode(
      Expressmode.express,
      questions
    );
    const results = this.ApplyQualifiedQuestionFilter(answers, tags);

    result = results.some((r) => r.canDisplay === true);
    return result;
  }

  private IfCatchAllTopIsTrue(
    questions: Question[],
    answers: Answer[]
  ): boolean {
    let result = true;
    const tags = this.GetQuestionTagsByExpressMode(
      Expressmode.catchAllTop,
      questions
    );
    const results = this.ApplyQualifiedQuestionFilter(answers, tags);

    result = results.some((r) => r.canDisplay === true);
    return result;
  }

  private GetQuestionTagsByExpressMode(
    mode: Expressmode,
    questions: Question[]
  ) {
    return questions
      .filter(
        (q) =>
          q.details[this.EXPRESS_MODE] !== undefined &&
          q.details[this.EXPRESS_MODE] === mode.toString()
      )
      .map((q) => q.answerTag);
  }

  private ApplyQualifiedQuestionFilter(answers: Answer[], tags: string[]) {
    const qqFilter = new QualifiedQuestionFilter();
    const qqConfiguration: FilterConfiguration = {
      type: 'QualifiedQuestion',
      path: null,
      value: 'true',
    };
    const param: FilterParameter = {
      question: null,
      application: null,
      answers,
      config: qqConfiguration,
    };

    const results = tags.map((t) => {
      param.config.path = t; // run for each tag calculated.
      return qqFilter.Apply(param);
    });

    return results;
  }
}

export enum Expressmode {
  express = 'express',
  dormant = 'dormant',
  catchAllTop = 'catchAllTop',
  catchAllBottom = 'catchAllBottom',
  always = 'always',
  prescriptionTop = 'prescriptionTop',
  prescriptionBottom = 'prescriptionBottom',
}
