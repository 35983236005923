export const orderConfig = {
  labs: [
    {
      displayLabel: 'Exam/Blood/Urine',
      key: 'EXAM_BLOOD_URINE',
      groupName: 'groupA',
      types: ['LAB_AE', 'LAB_OE'],
    },
    {
      displayLabel: 'Urinalysis',
      key: 'URINALYSIS',
      groupName: 'groupB',
      types: ['LAB_AE', 'LAB_OE'],
    },
    {
      displayLabel: 'Height/Weight + BP',
      key: 'HEIGHT_WEIGHT_BP',
      groupName: 'groupB',
      types: ['LAB_AE', 'LAB_OE', 'LAB_AE_HTWTBP'],
    },
    {
      displayLabel: 'Saliva',
      key: 'SALIVA',
      groupName: 'groupB',
      types: ['LAB_AE', 'LAB_OE'],
    },
    {
      displayLabel: 'MVR',
      key: 'MVR',
      groupName: 'groupB',
      types: ['LAB_AE', 'LAB_OE'],
    },
    {
      displayLabel: 'Other',
      key: 'OTHER',
      groupName: 'groupB',
      types: ['LAB_AE', 'LAB_OE'],
    },
  ],

  requestsList: [
    { request: 'APS', label: 'APS' },
    { request: 'LAB_AE', label: 'LAB_AE' },
    { request: 'LAB_OE', label: 'LAB_OE' },
    { request: 'LAB_AE_HTWTBP', label: 'HTWTBP' },
  ],

  orderStatus: [
    {
      id: 0,
      value: 'Open',
    },
    {
      id: 1,
      value: 'Inprogress',
    },
    {
      id: 2,
      value: 'Created',
    },
    {
      id: 3,
      value: 'Pending',
    },
    {
      id: 4,
      value: 'Closed',
    },
    {
      id: 5,
      value: 'Completed',
    },
    {
      id: 6,
      value: 'Canceled',
    },
    {
      id: 7,
      value: 'Appointment Met',
    },
  ],

  orderRequests: [
    {
      orderName: 'Active Requests',
      list: [],
      type: 'active',
    },
    {
      orderName: 'Completed Requests',
      list: [],
      type: 'completed',
    },
  ],
};
