import { Component, OnInit } from '@angular/core';
import { Notification } from '../../interfaces/Notification';
import { ApplicationService } from '../../services/application/application.service';

@Component({
  selector: 'app-sms-notifications',
  templateUrl: './sms-notifications.component.html',
  styleUrls: ['./sms-notifications.component.scss'],
})
export class SmsNotificationsComponent implements OnInit {
  notifications: Notification[] = [];
  constructor(private applicationService: ApplicationService) {}

  ngOnInit() {
    this.applicationService.Notifications.subscribe(
      (notifications: Notification[]) => {
        this.notifications = notifications;
      }
    );
  }
}
