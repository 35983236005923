/**
 * User Profile
 */
export class UserProfile {
  constructor(name: string, roles: any, uniqueName: string, username: string) {
    this.name = name;
    this.roles = roles;
    this.uniqueName = uniqueName;
    this.userName = username;
    const names = this.name.split(',');
    this.firstName = names && names.length > 1 ? names[1].trim() : ' ';
    this.lastName = names && names.length > 0 ? names[0].trim() : ' ';
    this.domainUserName = 'HQ\\' + this.userName.split('@')[0];
    this.initial = this.firstName[0] + this.lastName[0];
  }

  name: string;
  roles: string[];
  uniqueName: string;
  userName: string;
  firstName: string;
  lastName: string;
  initial: string;
  domainUserName: string;

  public IsSame(user: string) {
    return (
      user === this.name ||
      user === this.domainUserName ||
      user?.toLowerCase()?.replace(/\\/g, '') ===
        this.domainUserName.toLowerCase().replace(/\\/g, '')
    );
  }
}
