export interface FormDataModal {
  relationship?: string;
  applicantName?: string;
  reason?: string;
  clientNo?: string;
}

export interface QuestionModal {
  text?: string;
  applicantName?: string;
  reason?: string;
  isEdit?: boolean;
  group?: string;
  clientNo?: string;
  relationship?: string;
}

export interface SelectedApplicantModel {
  fullName: string;
  clientNo: string;
  relationship: string;
}
export interface SearchedQuestionsModal {
  id: number;
  name: string;
  ansControlTypeID: number;
  text: string;
  displayWhen: string;
  instructions: string;
  value: string;
  type: string;
  answerTag: string;
  sequence: number;
  allowNextForValue: string;
  required: boolean;
  details: string;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  isProduct: boolean;
  questions?: Array<object>;
  sections?: Array<object>;
}

export class QuestionData {
  Question: QuestionModal = {};
  constructor(text, applicantName, reason, tags?, clientNo?, relationship?) {
    this.Question.text = `${text}${
      tags?.length ? `\t \n \n (${tags?.join(',')})` : ''
    }`;
    this.Question.applicantName = applicantName;
    this.Question.reason = reason;
    this.Question.clientNo = clientNo;
    this.Question.relationship = relationship;
  }
}
