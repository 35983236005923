import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-question-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  @Output() selectedAnswer = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  selected(answer: any) {
    this.selectedAnswer.emit(answer);
  }
}
