<app-modal
  [open]="openCreateOrderModal$"
  [modalOptions]="{ size: 'lg' }"
  [footer]="false"
  [buttonsConfig]="{
    position: 'center',
    modalMode: 'customButtons',
    buttonSize: 'large'
  }"
  class="border-bottom-0"
>
  <div title class="row justify-content-center ml-3">
    <ng-container *ngIf="editOrder === true; else elseBlock"
      >Update Request</ng-container
    >
    <ng-template #elseBlock>Create Request</ng-template>
  </div>
  <div body class="px-3">
    <form [formGroup]="createOrderForm">
      <div class="row m-0">
        <div class="col-6">
          <select
            class="form-control form-control-sm"
            formControlName="applicant"
          >
            <option [ngValue]="null" disabled selected>Select Applicant</option>
            <option
              *ngFor="let item of applicants; index as i"
              [ngValue]="item.clientNo || item.fullName"
              >{{ item.fullName || item.name }}
              <span *ngIf="item.relationship"
                >( {{ item.relationship }} )</span
              ></option
            >
          </select>
        </div>
        <div class="col-6">
          <select
            class="form-control form-control-sm"
            formControlName="requestType"
            (ngModelChange)="changeRequestType($event)"
          >
            <option [ngValue]="null" disabled selected
              >Select Request Type</option
            >
            <option
              *ngFor="let item of requestsList; index as i"
              [ngValue]="item.request"
              >{{ item.label }}</option
            >
          </select>
        </div>
      </div>
      <div
        class="row m-0 mt-4"
        formGroupName="aps"
        *ngIf="labRequestType === labAPSRequest"
      >
        <div class="col-6">
          <select
            class="form-control form-control-sm"
            formControlName="physician"
            (ngModelChange)="getPhysicianInfo($event)"
          >
            <option [ngValue]="null" disabled selected>Select Provider</option>
            <option
              *ngFor="let item of providers; index as i"
              [ngValue]="item.fullname"
              >{{ item.fullname }}
              <ng-container *ngIf="item.client_nos?.length">
                (<span
                  style="font-weight: bold;"
                  *ngFor="
                    let number of item.client_nos;
                    let i = index;
                    let isLast = last
                  "
                  >{{ getApplicantName(number) }}
                  <span *ngIf="!isLast">,&nbsp;</span> </span
                >)
              </ng-container>
            </option>
          </select>
        </div>
        <div class="col-6">
          <select class="form-control form-control-sm" formControlName="reason">
            <option [ngValue]="'Other'" selected>Other (Reason)</option>
          </select>
        </div>
        <div class="col-6 mt-4">
          <div class="doc-details-bg p-2" *ngIf="selectedDoctor">
            <p>Name: {{ selectedDoctor.fullname || "N/A" }}</p>
            <p>Street: {{ selectedDoctor.street_address || "N/A" }}</p>
            <p>City: {{ selectedDoctor.city || "N/A" }}</p>
            <p>State: {{ selectedDoctor.state || "N/A" }}</p>
            <p>Phone: {{ selectedDoctor.phone_numbers || "N/A" }}</p>
            <p>Tax ID: {{ selectedDoctor.tax_id || "N/A" }}</p>
            <p *ngIf="selectedDoctor.additionalInfo">
              Fax: {{ selectedDoctor.additionalInfo.FaxNumber || "N/A" }}
            </p>
          </div>
        </div>
        <div class="col-6 mt-4">
          <label class="label"
            >UW Notes <span class="text-danger">*</span></label
          >
          <textarea
            class="form-control"
            rows="6"
            formControlName="uWSNotes"
          ></textarea>
          <div
            class="mt-1 text-danger small"
            *ngIf="
              uwApsNotes.invalid && (uwApsNotes.dirty || uwApsNotes.touched)
            "
          >
            <div *ngIf="uwApsNotes.errors?.['required']">
              notes is required.
            </div>
            <div *ngIf="uwApsNotes.errors?.['pattern']">
              Single and Double quotes are not allowed
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" *ngIf="labRequestType !== labAPSRequest">
        <div formArrayName="tests" class="testContainer">
          <ng-container
            *ngFor="let control of labTests().controls; let i = index"
          >
            <div
              class="col-12"
              *ngIf="control.value.groupName === 'groupA'"
              [ngClass]="{
                wrapper: disableActionsForGroup?.value?.groupName === 'groupB'
              }"
            >
              <div
                class="col-12"
                [formGroupName]="i"
                [ngClass]="{
                  'actions-none':
                    disableActionsForGroup?.value?.groupName === 'groupB'
                }"
              >
                <label class="d-block cursor-pointer" placement="top">
                  <input
                    type="checkbox"
                    name="ailment"
                    formControlName="checked"
                    (click)="enableTestNotes(control, i)"
                  />
                  <span class="pl-2"
                    >{{ control.value.displayLabel }}
                    <span *ngIf="control.value.checked" class="text-danger"
                      >*</span
                    ></span
                  >
                </label>
                <textarea
                  class="col-12 form-control"
                  rows="6"
                  formControlName="notes"
                  (keyup)="getNotesChange(control, i)"
                ></textarea>
                <div
                  class="mt-1 text-danger small"
                  *ngIf="
                    labTests().controls[i].get('notes').invalid &&
                    (labTests().controls[i].get('notes').dirty ||
                      labTests().controls[i].get('notes').touched)
                  "
                >
                  <div
                    *ngIf="labTests().controls[i].get('notes').errors?.['required']"
                  >
                    notes is required.
                  </div>
                  <div
                    *ngIf="labTests().controls[i].get('notes').errors?.['pattern']"
                  >
                    Single and Double quotes are not allowed
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="control.value.groupName === 'groupA'"
              class="col-12 d-flex justify-content-center m-0 p-3"
            >
              <hr class="col ml-2" />
              <span class="col-2 text-center">OR</span>
              <hr class="col mr-2" />
            </div>
            <div
              class="col-6 labTest"
              *ngIf="control.value.groupName === 'groupB'"
              [ngClass]="{
                wrapper: disableActionsForGroup?.value?.groupName === 'groupA'
              }"
            >
              <div
                [formGroupName]="i"
                [ngClass]="{
                  'actions-none':
                    disableActionsForGroup?.value?.groupName === 'groupA'
                }"
              >
                <label class="d-block cursor-pointer" placement="top">
                  <input
                    type="checkbox"
                    name="ailment"
                    formControlName="checked"
                    (click)="enableTestNotes(control, i)"
                  />
                  <span class="pl-2"
                    >{{ control.value.displayLabel }}
                    <span *ngIf="control.value.checked" class="text-danger"
                      >*</span
                    ></span
                  >
                </label>
                <textarea
                  class="col-12 form-control"
                  rows="6"
                  formControlName="notes"
                  (keyup)="getNotesChange(control, i)"
                ></textarea>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
    <div class="mt-5 d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="cancelOrderRequest()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary ml-4"
        (click)="submit()"
        [disabled]="!createOrderForm.valid"
      >
        {{ editOrder === true ? "Update" : "Create" }}
      </button>
    </div>
  </div>
</app-modal>
