<ng-template #content let-modal>
  <form [formGroup]="form">
    <div class="modal-header">
      <h4 id="modal-basic-title" class="modal-title col-11">
        <div title class="row justify-content-between ml-2">
          <div class="d-flex">
            <i class="fas fa-user fa-modal-header text-black-50 mt-2"></i>
            <div class="d-block ml-3">
              <p class="label-Name m-0">Applicant Name</p>
              <p class="applicant-Name">
                {{ application.primaryApplicant | fullname }}
              </p>
            </div>
          </div>
          <div class="d-flex">
            <i
              class="fas fa-calendar-alt fa-modal-header text-black-50 mt-2"
            ></i>
            <div class="d-block ml-3">
              <p class="label-Name m-0">Scheduled Time</p>
              <p class="applicant-Name">
                {{
                  selectedAppointment.scheduledDate
                    | datewithTimeZone: timeZone:"date-time"
                }}
              </p>
            </div>
          </div>
        </div>
      </h4>
      <button
        type="button"
        class="close outline-0 col-1"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="call-status mt-3 text-center mb-5">
        With whom are you speaking?
      </p>
      <div class="row justify-content-center">
        <div class="d-block">
          <div
            class="form-check mb-4"
            formArrayName="applicants"
            *ngFor="let a of applicants; let i = index"
          >
            <label class="form-check-label cursor-pointer">
              <input
                class="form-check-input radio-prop"
                type="checkbox"
                [formControlName]="i"
              />
              <i
                class="fas fa-user fa-modal-header text-black-50 mt-1 ml-3"
              ></i>
              <span class="applicant-Name ml-3">{{ a.fullName }}</span>
            </label>
          </div>
          <div
            class="row justify-content-center m-0"
            *ngIf="selectedAppointment.callType !== 'Recall'"
          >
            <button
              type="button"
              class="btn btn-primary btn-prop btn-sm mt-3"
              (click)="triggerStopCallModal()"
            >
              Reschedule
            </button>
          </div>
        </div>
      </div>
      <br />
      <div *ngIf="isFormInvalid" class="text-center text-danger small">
        <!-- <ngb-alert> -->
        At least one applicant must be selected
        <!-- </ngb-alert> -->
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-outline-dark btn-prop"
        (click)="modal.dismiss('Cancel')"
      >
        Cancel
      </button>
      <!-- [disabled]="!form.valid" -->
      <button
        type="button"
        class="btn btn-primary btn-prop active"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </form>
</ng-template>
<app-end-call-modal> </app-end-call-modal>
