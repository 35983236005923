import { Injectable } from '@angular/core';
import { ApplicationService } from '../../application/application.service';
import { UiServiceService } from '../../ui-service.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';

@Injectable({
  providedIn: 'root',
})
export abstract class CopyAnswerTagProcessor implements Processor {
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'CopyAnswerTagProcessor';

  constructor(private applicationService: ApplicationService) {}

  abstract Process(model: ProcessorModel): Promise<ProcessingResult>;
  public copy(tagFrom, tagTo) {
    if (this.applicationService.isQuestionGenericMode()) {
      const sourceValue = this.applicationService.GetAnswerForAnswerTag(
        tagFrom
      );
      if (sourceValue) {
        this.applicationService.RegisterAnswerByTag(
          sourceValue.verification,
          sourceValue.value,
          tagTo,
          null,
          null,
          sourceValue.prompt,
          sourceValue.propertyType
        );
      }
    }
    return Promise.resolve({ result: true, stopProcessing: false });
  }

  public createCustomRegisterAnswerByTag(tag, prompt, value, type) {
    if (this.applicationService.isQuestionGenericMode()) {
      this.applicationService.RegisterAnswerByTag(
        true,
        value,
        tag,
        null,
        null,
        prompt,
        type
      );
    }
    return Promise.resolve({ result: true, stopProcessing: false });
  }
}
