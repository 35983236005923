<form [formGroup]="ailmentForm">
  <div class="box">
    <label
      placement="top"
      ngbTooltip="{{ ailment }}"
      [ngClass]="{ active: ailmentForm.get('ailments')['controls'][i].value }"
      formArrayName="ailments"
      class="d-flex align-items-center ailment mb-4 m-2"
      *ngFor="let ailment of details.items; let i = index"
    >
      <input
        type="checkbox"
        name="ailment"
        id="{{ ailment }}"
        [formControlName]="i"
        (change)="optionChanged(ailment, $event)"
      />
      <span class="cr"><i class="cr-icon fas fa-check"></i></span>

      <span
        class="ailmentLabel"
        [ngClass]="{ customAilmentLabel: details.items.length < '3' }"
        >{{ ailment }}</span
      >
    </label>
  </div>
</form>
<!-- <div class="row pt-5">
  <span *ngFor="let a of ApplicableApplicants(); let i = index">
    <label
      class="d-flex align-items-center mb-4 m-2"
      [ngClass]="{ 'font-weight-bold': selectedIndex === i }"
    >
      <span class="cr"
        ><i
          class="cr-icon text-success fas"
          [ngClass]="{
            'fa-check':
              qualifiedList[i].ailments && qualifiedList[i].ailments.length > 0
          }"
        ></i>
      </span>
      {{ a }}
    </label>
  </span>
</div> -->
