import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss'],
})
export class DeveloperComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  devlookup(value: any) {
    this.router.navigateByUrl(`/developer/controls/${value}`);
  }
}
