<div class="offset-1 col-10 p-0">
  <div class="col-sm-12 p-0">
    <app-application-info></app-application-info>
  </div>
  <div class="row mt-4">
    <div class="col-12 mb-4">
      <nav ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="activeTab">
        <ng-container
          *ngIf="
            viewModeConfig.includes('review') || viewModeConfig.includes('all')
          "
        >
          <li ngbNavItem="review">
            <a
              ngbNavLink
              (click)="getCurrentTab('Review')"
              [appPermission]="'CanAccesDiscrepencyTab'"
              >Review</a
            >
            <ng-template ngbNavContent>
              <app-review
                [appPermission]="'CanAccesDiscrepencyTab'"
              ></app-review>
            </ng-template>
          </li>
        </ng-container>
        <ng-container
          *ngIf="
            viewModeConfig.includes('vCall') || viewModeConfig.includes('all')
          "
        >
          <li ngbNavItem="vcall">
            <a ngbNavLink (click)="getCurrentTab('VCall')">VCall</a>
            <ng-template ngbNavContent>
              <app-print-view
                [printViewData]="{ applicationId: applicationId }"
              ></app-print-view>
            </ng-template>
          </li>
        </ng-container>
        <ng-container
          *ngIf="
            viewModeConfig.includes('ezApp') || viewModeConfig.includes('all')
          "
        >
          <li ngbNavItem="ezapp">
            <a
              ngbNavLink
              (click)="getCurrentTab('EZApp')"
              [appPermission]="'CanAccessPDFScreen'"
              >EZApp</a
            >
            <ng-template ngbNavContent>
              <div class="card pdf-div" *ngIf="pdfUrl">
                <iframe
                  width="100%"
                  height="100%"
                  [src]="pdfUrl | sanitizeUrl"
                  frameborder="0"
                ></iframe>
              </div>
            </ng-template>
          </li>
        </ng-container>
        <ng-container
          *ngIf="
            viewModeConfig.includes('recalls') || viewModeConfig.includes('all')
          "
        >
          <li ngbNavItem="recalls">
            <a
              ngbNavLink
              (click)="getCurrentTab('Recalls')"
              [appPermission]="'CanAccesRecallTab'"
              >Recalls</a
            >
            <ng-template ngbNavContent>
              <app-manage-recall></app-manage-recall>
            </ng-template>
          </li>
        </ng-container>
        <ng-container
          *ngIf="
            canDisplayAdditionalInfoTab &&
            (viewModeConfig.includes('apsrequests') ||
              viewModeConfig.includes('all'))
          "
        >
          <li ngbNavItem="apsrequests">
            <a
              ngbNavLink
              (click)="getCurrentTab('APSRequests')"
              [appPermission]="'CanAccessAPSRequestTab'"
              >APS Requests</a
            >
            <ng-template ngbNavContent>
              <app-additional-info></app-additional-info>
            </ng-template>
          </li>
        </ng-container>
        <ng-container
          *ngIf="
            canDisplayAdditionalInfoTab &&
            (viewModeConfig.includes('addOns') ||
              viewModeConfig.includes('all'))
          "
        >
          <li ngbNavItem="addons">
            <a
              ngbNavLink
              (click)="getCurrentTab('addOns')"
              [appPermission]="'CanAccessAddonTab'"
              >Add On</a
            >
            <ng-template ngbNavContent>
              <app-add-ons></app-add-ons>
            </ng-template>
          </li>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
