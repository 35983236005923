import { Injectable } from '@angular/core';

export interface Tab {
  uniqueId?: string;
  value?: number;
}

@Injectable({
  providedIn: 'root',
})
export class TabsPersistenceService {
  tabsList: Tab[] = [];
  constructor() {
    this.tabsList = this.loadTabsHistory();
  }

  addOrUpdateSelectedTab(data: Tab) {
    this.deleteToNElements();
    let tabFound = false;
    this.tabsList.forEach((element) => {
      if (element.uniqueId === data.uniqueId) {
        element.value = data.value;
        tabFound = true;
      }
    });
    if (!tabFound) {
      this.tabsList.push(data);
      localStorage.setItem('TabsHistory', JSON.stringify(this.tabsList));
      this.tabsList = this.loadTabsHistory();
    } else {
      localStorage.setItem('TabsHistory', JSON.stringify(this.tabsList));
    }
  }

  getStoredOrDefault(uniqueId: string) {
    const tab = this.tabsList.filter(
      (element) => element.uniqueId === uniqueId
    );
    if (tab.length) {
      return tab[0].value;
    } else {
      return 1;
    }
  }
  loadTabsHistory() {
    if (localStorage.getItem('TabsHistory')) {
      return (this.tabsList = JSON.parse(localStorage.getItem('TabsHistory')));
    } else {
      return [];
    }
  }
  deleteToNElements() {
    const n = 200;
    if (this.tabsList.length > n) {
      this.tabsList.splice(0, this.tabsList.length - n);
    }
  }
}
