import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { debounceTime, switchMap, map, catchError, tap } from 'rxjs/operators';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { DocLookupService, SearchTerms } from './doc-lookup.service';
import {
  DoctorLookup,
  DoctorLookupAnswer,
} from 'src/app/shared/models/drLookup';
import { Observable, of, Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Application } from 'src/app/shared/models/application/application';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-vcall-doc-lookup',
  templateUrl: './doc-lookup.component.html',
  styleUrls: ['./doc-lookup.component.scss'],
})
export class DoctorLookupComponent implements OnInit {
  searchType = [
    {
      placeholder: 'Please enter Healthcare Provider',
      type: 'text',
      minLength: 3,
      maxLength: 200,
      searchSelection: 'healthcare',
    },
    {
      placeholder: 'Please enter Name of the Facility',
      type: 'text',
      minLength: 3,
      maxLength: 200,
      searchSelection: 'facility',
    },
    {
      // prettier-ignore
      placeholder: 'Please enter Provider\'s Phone Number ( ex : 1234567890 )',
      type: 'number',
      minLength: 10,
      maxLength: 10,
      searchSelection: 'phonenumber',
    },
  ];
  searchBarProps = {
    placeholder: 'Please enter Healthcare Provider',
    type: 'text',
    minLength: 3,
    maxLength: 200,
    searchSelection: 'healthcare',
  };
  searchFieldType = 'text';
  // @Input() prlookup: DoctorLookup;
  radiusList: number[] = [50, 75, 100];
  isExpanded = false;
  skipMinMaxValidation = false;
  openModal = false;
  searching = false;
  searchFailed = false;
  isDoctorUpdated = false;
  healthProvider: string;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  application: Application;
  providerLookup: UntypedFormGroup;
  @Output()
  formComplete = new EventEmitter();
  @Output()
  formValidity = new EventEmitter();
  @Output()
  notifyProviderLookup = new EventEmitter();
  @Input() initialDoctorList = new DoctorLookupAnswer();
  @Input() initialProviderList$: Observable<DoctorLookupAnswer>;
  @Input() questionDetails;
  @Input() showProviderConfirmation = true;
  @Input() resetRequest$: Observable<DoctorLookupAnswer>;
  updatedDoctorList = new DoctorLookupAnswer();
  @Input() clientNumber: string;
  deleteDrObj: DoctorLookup;
  doctorExist = false;
  previousDoctorsList: DoctorLookup[] = [];
  showingRecent = false;
  searchTerms: UntypedFormGroup;
  lastVisitedOptions;
  showLastVisited = false;
  additionalInfo = {};
  constructor(
    private docLookupService: DocLookupService,
    private applicationService: ApplicationService
  ) {
    this.providerLookup = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      city: new UntypedFormControl(null, Validators.required),
      fullname: new UntypedFormControl(null, Validators.required),
      distance: new UntypedFormControl(null),
      specialties: new UntypedFormControl(null),
      phone_numbers: new UntypedFormControl(null, Validators.required),
      state: new UntypedFormControl(null, Validators.required),
      street_address: new UntypedFormControl(null, Validators.required),
      zipcode: new UntypedFormControl(null, Validators.required),
      other_phone: new UntypedFormControl(null),
      last_visited: new UntypedFormControl(null),
      tax_id: new UntypedFormControl(null),
      CorporateOwnerLastName: new UntypedFormControl(null),
      FaxNumber: new UntypedFormControl(null),
      additionalInfo: new UntypedFormGroup({
        FaxNumber: new UntypedFormControl(null),
        CorporateOwnerLastName: new UntypedFormControl(null),
      }),
    });
    this.searchTerms = new UntypedFormGroup({
      healthcareProviderSearchType: new UntypedFormControl('0'),
      searchString: new UntypedFormControl(''),
      applicantZipcode: new UntypedFormControl(''),
      areaCode: new UntypedFormControl(''),
      phoneNumber: new UntypedFormControl(''),
      radius: new UntypedFormControl(0),
    });
  }

  ngOnInit() {
    if (this.resetRequest$) {
      this.resetRequest$.subscribe((res) => {
        if (typeof res === 'object') {
          this.updatedDoctorList.list = [];
        }
        this.updatedDoctorList.isProviderAvailable = true;
        this.resetForm();
      });
    }
    this.previousDoctorsList = this.docLookupService.GetApplicationProviders();
    if (this.initialProviderList$) {
      this.initialProviderList$.subscribe((list) => {
        if (list.list) {
          this.updatedDoctorList.list = list.list;
          this.updatedDoctorList.isProviderAvailable = list.isProviderAvailable;
        }
      });
    } else {
      this.updatedDoctorList.list = [...this.initialDoctorList.list];
      this.updatedDoctorList.isProviderAvailable =
        this.initialDoctorList.isProviderAvailable;
    }

    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a) {
          this.application = a;
        }
      })
    );
    if (this.questionDetails?.hasOwnProperty('lastVisitedOptions')) {
      this.showLastVisited = true;
      this.lastVisitedOptions = JSON.parse(
        this.questionDetails?.lastVisitedOptions
      );
      this.providerLookup
        .get('last_visited')
        .setValidators(Validators.required);
    } else {
      this.providerLookup.get('last_visited').clearValidators();
      this.showLastVisited = false;
    }
    this.providerLookup.get('last_visited').updateValueAndValidity();
  }

  onLookupType = (text$: Observable<string>) => {
    return text$.pipe(
      tap(() => (this.doctorExist = false)),
      debounceTime(200),
      switchMap((lookup) => {
        this.showingRecent = false;
        const objToAPI: SearchTerms = { ...this.searchTerms.value };
        objToAPI.applicantZipcode = objToAPI.applicantZipcode
          ? objToAPI.applicantZipcode.toString()
          : this.application.contact.zipCode;
        if (
          (lookup.length >= this.searchBarProps.minLength &&
            lookup.length <= this.searchBarProps.maxLength) ||
          this.skipMinMaxValidation
        ) {
          if (this.searchBarProps.searchSelection === 'phonenumber') {
            objToAPI.areaCode = objToAPI.searchString.substring(0, 3);
            objToAPI.phoneNumber = objToAPI.searchString.substring(3, 10);
            objToAPI.searchString = '*';
          } else {
            objToAPI.areaCode = '';
            objToAPI.phoneNumber = '';
            objToAPI.searchString += '*';
          }
          objToAPI.healthcareProviderSearchType =
            +objToAPI.healthcareProviderSearchType;
          this.searching = true;
          const methodReference =
            this.searchBarProps.searchSelection === 'phonenumber'
              ? this.docLookupService.getProviderLookupByPhoneNumber(objToAPI)
              : this.docLookupService.getProviderLookup(objToAPI);
          return methodReference.pipe(
            map((results: DoctorLookup[]) => {
              this.searchFailed = false;
              results = this.formatPhoneNumbers(results);
              this.searchFailed = results.length < 1;
              return results;
            }),
            catchError((err) => {
              if (err) {
                this.searchFailed = true;
              }
              return of([]);
            })
          );
        } else if (lookup === '') {
          this.searching = false;
          this.showingRecent = true;
          this.searchFailed = false;
          return of(this.previousDoctorsList);
        } else {
          this.searchFailed = false;
          return of([]);
        }
      }),
      tap(() => (this.searching = false))
    );
  };

  // format phone numbers :- (000) 000-0000
  formatPhoneNumbers(result) {
    result.filter((ele) => {
      const phoneNumbers = ele.phone_numbers.split(',');
      ele.phone_numbers = phoneNumbers[0].replace(
        /^(\d{3})(\d{3})(\d{4})$/g,
        '($1) $2-$3'
      );
      if (phoneNumbers[1]) {
        ele.other_phone = phoneNumbers[1]
          .trim()
          .replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3');
      }
    });
    return result;
  }

  formCompleted() {
    this.formComplete.emit(this.providerLookup.value);
    this.formIsValid();
  }

  formIsValid() {
    let allItemsValid = true;
    Object.keys(this.providerLookup.controls).map(
      (c) =>
        (allItemsValid =
          allItemsValid &&
          this.providerLookup.controls[c]['status'] === 'VALID')
    );
    this.formValidity.emit(allItemsValid);
    return allItemsValid;
  }

  selectedItem(e) {
    e.preventDefault();
    this.providerLookup.patchValue({
      id: e.item.id,
      city: e.item.city,
      fullname: e.item.name ? e.item.name : e.item.fullname,
      distance: e.item.distance,
      specialties: e.item.specialties,
      phone_numbers: e.item.phone_numbers,
      state: e.item.state,
      street_address: e.item.street_address,
      zipcode: e.item.zipcode,
      other_phone: e.item.other_phone,
      tax_id: e.item.tax_id,
      CorporateOwnerLastName: e.item.additionalInfo?.CorporateOwnerLastName,
      FaxNumber: e.item.additionalInfo?.FaxNumber,
    });

    this.additionalInfo = e.item.additionalInfo;
    this.healthProvider = null;
    this.isDoctorUpdated = false;
    this.formCompleted();
  }

  formatter = (x: any) => {
    if (typeof x === 'object') {
      this.providerLookup.patchValue({
        id: x.id,
        city: x.city,
        fullname: x.name,
        distance: x.distance,
        specialties: x.specialties,
        phone_numbers: x.phone_numbers,
        state: x.state,
        street_address: x.street_address,
        zipcode: x.zipcode,
        other_phone: x.other_phone,
        tax_id: x.item.tax_id,
        CorporateOwnerLastName: x.additionalInfo.CorporateOwnerLastName,
        FaxNumber: x.additionalInfo.FaxNumber,
        additionalInfo: {
          CorporateOwnerLastName: x.additionalInfo.CorporateOwnerLastName,
          FaxNumber: x.additionalInfo.FaxNumber,
        },
      });
    } else {
      return x;
    }
  };

  addDoctor(isUpdate) {
    const i = this.updatedDoctorList.list.findIndex(
      (e) => e.id === this.providerLookup.value.id
    );
    const fullname = this.providerLookup.value.fullname.split(' ');
    const len = this.updatedDoctorList.list.length;
    if (isUpdate && i > -1) {
      this.providerLookupListData(i, fullname, isUpdate);
    } else {
      if (this.providerLookup.value.id !== null) {
        this.providerLookupListData(len, fullname, isUpdate);
      } else {
        // const randomGuid = Math.floor(1000 + Math.random() * 9000);
        const randomGuid = UUID.UUID();
        this.providerLookup.value.id = randomGuid;
        this.providerLookupListData(len, fullname, isUpdate);
      }
    }
    this.previousDoctorsList = this.docLookupService.AddProviders(
      this.updatedDoctorList.list,
      this.clientNumber
    );
    this.notifyProviderLookup.emit(this.updatedDoctorList);
    this.isDoctorUpdated = false;
    this.resetForm();
    this.openModal = false;
  }

  providerLookupListData(i, fullname, isUpdate) {
    if (
      this.updatedDoctorList.list.findIndex(
        (x) => x.id === this.providerLookup.value.id
      ) === -1 ||
      isUpdate
    ) {
      this.updatedDoctorList.list[i] = this.providerLookup.value;
      this.updatedDoctorList.list[i].first_name = fullname[0];
      this.updatedDoctorList.list[i].last_name = fullname[1];
      this.updatedDoctorList.list[i].additionalInfo =
        this.getCurrentAdditionalInfoFields();

      if (this.clientNumber !== null && this.clientNumber !== undefined) {
        this.updatedDoctorList.list[i].client_nos = this.updatedDoctorList.list[
          i
        ].hasOwnProperty('client_nos')
          ? this.updatedDoctorList.list[i].client_nos
          : [];
        this.updatedDoctorList.list = this.mapClientNoToProvider(
          this.updatedDoctorList.list
        );
      }
      this.doctorExist = false;
    } else {
      this.doctorExist = true;
    }
  }

  mapClientNoToProvider(doctors) {
    doctors.map((doc) => {
      doc.client_nos = [...doc.client_nos, ...[this.clientNumber]];
      return doc;
    });
    return doctors;
  }

  resetForm() {
    this.providerLookup.reset();
  }

  editDoctor(e) {
    if (e.last_name === null || e.last_name === undefined) {
      e.last_name = '';
      this.providerLookupFormValues(e);
    } else {
      this.providerLookupFormValues(e);
    }
    this.openModal = false;
    this.isDoctorUpdated = true;
  }

  deleteDoctor(data) {
    this.openModal = true;
    this.deleteDrObj = data;
  }

  confirm(action: any) {
    if (action === 'Done') {
      const index = this.updatedDoctorList.list.findIndex(
        (n) => n.id === this.deleteDrObj.id
      );

      if (index > -1) {
        this.updatedDoctorList.list.splice(index, 1);
      }
    }
    if (this.updatedDoctorList.list.length === 0) {
      this.updatedDoctorList.isProviderAvailable = false;
      // this.notifyProviderLookup.emit(this.updatedDoctorList);
    }
    // For delete the provider from existing answres model
    this.docLookupService.AddProviders(
      this.updatedDoctorList.list,
      this.clientNumber,
      this.deleteDrObj.id
    );
    this.notifyProviderLookup.emit(this.updatedDoctorList);
    this.openModal = false;
  }
  optionChanged(providerValue) {
    this.updatedDoctorList.isProviderAvailable = providerValue;
    if (!providerValue) {
      this.updatedDoctorList.list = [];
    }
    this.notifyProviderLookup.emit(this.updatedDoctorList);
  }

  // edit providerLookup values and appened to form
  providerLookupFormValues(e) {
    this.providerLookup.patchValue({
      id: e.id,
      city: e.city,
      fullname: e.first_name + ' ' + e.last_name,
      distance: e.distance,
      specialties: e.specialties,
      phone_numbers: e.phone_numbers,
      state: e.state,
      street_address: e.street_address,
      zipcode: e.zipcode,
      other_phone: e.other_phone,
      last_visited: e.last_visited,
      FaxNumber: e.FaxNumber,
      CorporateOwnerLastName: e.CorporateOwnerLastName,
      tax_id: e.tax_id,
      additionalInfo: {
        FaxNumber: e.additionalInfo.FaxNumber,
        CorporateOwnerLastName: e.additionalInfo.CorporateOwnerLastName,
      },
    });
  }

  onFocus(e: Event): void {
    this.skipMinMaxValidation = false;
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  searchTypeChanged() {
    this.healthProvider = null;
  }

  fireSearchWithZipcode(elem: any) {
    const zipCodeLength = this.searchTerms.value.applicantZipcode
      ? this.searchTerms.value.applicantZipcode.toString().length
      : 0;
    if (zipCodeLength === 5 || zipCodeLength === 0) {
      this.skipMinMaxValidation = true;
      setTimeout(() => {
        const inputEvent: Event = new Event('input');
        elem.dispatchEvent(inputEvent);
      }, 0);
    }
  }

  fireSearchwithRadius(elem: any) {
    this.skipMinMaxValidation = true;
    const selectedRadius = this.searchTerms.value.radius
      ? this.searchTerms.value.radius
      : 0;
    this.searchTerms.patchValue({
      radius: +selectedRadius,
    });
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      elem.dispatchEvent(inputEvent);
    }, 0);
  }

  getCurrentAdditionalInfoFields() {
    const additionalInfo = Object.keys(
      this.providerLookup.value.additionalInfo
    );
    const updatedAdditionalInfo = {};
    for (const key in this.providerLookup.value) {
      if (additionalInfo.includes(key)) {
        updatedAdditionalInfo[key] = this.providerLookup.get(key)?.value;
      }
    }
    return updatedAdditionalInfo;
  }
}
