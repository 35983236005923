import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {
  @Input() documents;
  @Output() sendPdfFile = new EventEmitter();
  public selectedDocIndex = 0;
  timeZone: string;
  constructor(private profileService: UserProfileService,) {}

  ngOnInit() {
    this.getProfileDetails();
  }

  viewPdf(file, index) {
    this.selectedDocIndex = index;
    this.sendPdfFile.emit(file);
  }

  getProfileDetails() {
    this.timeZone = this.profileService.getCallzoneUser().timeZone;
  }
}
