import { Pipe, PipeTransform } from '@angular/core';
import { CallTypeDuration } from '../models/appointment';

@Pipe({
  name: 'cbduration',
})
export class CallBackDurationPipe implements PipeTransform {
  callTypeArray: CallTypeDuration[] = [
    {
      callType: 'Standard',
      duration: 45,
      durationType: 'min',
    },
    {
      callType: 'Express',
      duration: 45,
      durationType: 'min',
    },
    {
      callType: 'Short',
      duration: 15,
      durationType: 'min',
    },
    {
      callType: 'Recall',
      duration: 15,
      durationType: 'min',
    },
    {
      callType: 'Long',
      duration: 45,
      durationType: 'min',
    },
  ];

  /**
   *
   * @param value value
   * @returns  returns the call type array with call duration
   */
  transform(value: string): any {
    const index = this.callTypeArray.findIndex(
      (x: any) => x.callType === value
    );
    return index > -1
      ? this.callTypeArray[index].duration +
          ' ' +
          this.callTypeArray[index].durationType
      : null;
  }
}
