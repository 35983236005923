import { Component, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { SecondsToMinutesFilterPipe } from '../../pipes/seconds-to-minutes-filter.pipe';
import { Router } from '@angular/router';
import { ApplicationService } from '../../services/application/application.service';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { ScriptService } from '../../services/script/script.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-idle-tracker',
  templateUrl: './user-idle-tracker.component.html',
  styleUrls: ['./user-idle-tracker.component.scss'],
  providers: [SecondsToMinutesFilterPipe],
})
export class UserIdleTrackerComponent implements OnInit {
  idleState: string;
  openUserIdleModal = false;
  END_COUNTDOWN_TIMER = 900;
  CHECK_COUNTDOWN_TIMER = 300;
  isIdle: boolean;
  idleTimer: number; // Stores the idle timer in seconds

  constructor(
    private idle: Idle,
    private secondsToMinutesFilter: SecondsToMinutesFilterPipe,
    private router: Router,
    private applicationService: ApplicationService,
    private appointmentService: AppointmentService,
    private scriptService: ScriptService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.initIdleTimer();
  }

  initIdleTimer(): void {
    this.idle.setIdle(this.idleTimer); // Set the idle timer
    this.idle.setTimeout(this.END_COUNTDOWN_TIMER); // Set the timeout duration
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // Set up idle events
    this.idle.onIdleStart.subscribe(() => {
      this.isIdle = true;
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.isIdle = false;
    });

    this.idle.onTimeout.subscribe(() => {
      this.isIdle = true;
      this.loggedOut();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState =
        'Your VCALL session has been inactive on this browser/tab for 15 minutes and will expire in ' +
        this.secondsToMinutesFilter.transform(countdown) +
        ' seconds. Please click "Continue" if you want to continue this VCALL session.';
      if (countdown === this.CHECK_COUNTDOWN_TIMER) {
        this.openUserIdleModal = true;
        this.idle.clearInterrupts();
      }
    });

    // Start watching for idleness
    this.idle.watch();
  }

  // Change the idle timer setting
  changeIdleTimer(newTimer: number): void {
    this.idleTimer = newTimer;
    this.idle.stop(); // Stop the idle service
    this.initIdleTimer(); // Reinitialize the idle timer with the new settings
  }

  reset() {
    this.openUserIdleModal = false;
    this.initIdleTimer();
  }

  loggedOut() {
    this.applicationService.Clear();
    this.appointmentService.Clear();
    this.scriptService.Clear();
    this.openUserIdleModal = false;
    this.modalService.dismissAll();
    this.router.navigate(['/logged-out']);
    this.idle.stop();
  }
}
