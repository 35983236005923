<div class="container">
  <div class="row">
    <div class="col-3">
      <input type="text" class="form-control" [(ngModel)]="applicationId" />
    </div>
    <div class="col-3">
      <button class="btn btn-success" (click)="getdata()">Get Data</button>
    </div>
  </div>
  <br />
  <br />
  <h3 (click)="dapp = !dapp">Application</h3>
  <pre *ngIf="dapp">{{ application | json }}</pre>

  <h3 (click)="dscript = !dscript">Script</h3>
  <pre *ngIf="dscript">{{ script | json }}</pre>

  <h3 (click)="danswers = !danswers">Answers</h3>
  <pre *ngIf="danswers">{{ answers | json }}</pre>

  <h3 (click)="dlogs = !dlogs">CallLogs</h3>
  <pre *ngIf="dlogs">{{ calllogs | json }}</pre>
</div>
