import { Component, OnInit } from '@angular/core';
import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss'],
})
export class LoggedOutComponent implements OnInit {
  constructor(private idle: Idle) {
    idle.stop();
  }
  ngOnInit() {}
}
