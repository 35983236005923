import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Answer } from 'src/app/shared/models/application/answer';
import { Application } from 'src/app/shared/models/application/application';
import { MedicalItem } from 'src/app/shared/models/medicalItem';
import { Question } from 'src/app/shared/models/vcall/question';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';
import { ApplicantComparisonFilter } from 'src/app/shared/services/filter/question-filter/filters/attribute-comparison-filter';
import { FilterConfiguration } from 'src/app/shared/services/filter/question-filter/question-filter.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { BaseQuestionType } from '../types/baseQuestionType';
import { Applicant } from 'src/app/shared/models/application/applicant';

@Component({
  selector: 'app-qualifying-question-generic',
  templateUrl: './qualifying-question-generic.component.html',
  styleUrls: ['./qualifying-question-generic.component.scss'],
})
export class QualifyingQuestionGenericComponent extends BaseQuestionType<
  VcallQualifyingQuestionStudentDetail
> {
  question: Question = null;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  elementQuestionIdTrackerId: number;
  application: Application;
  answer: Answer = null;
  selectedValue: string;
  originalValue: string;
  details = new VcallQualifyingQuestionStudentDetail();
  answerTag: string;
  isNoneChecked: boolean;
  filterConfig: FilterConfiguration;
  selectedApplicants = [];
  openModal = false;
  clientNumber: string;
  answerType = 'ApplicantQualifyingGeneric';

  @Input() configType: string;

  applicants: MedicalItem[];
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    protected filterService: ApplicantFilterService
  ) {
    super(script, appService, ui);
  }

  // ngOnDestroy() {
  //   this.subscriptions.forEach((s) => s.unsubscribe());
  // }

  ResetView() {}

  ApplicationChanged(): void {}

  QuestionChanged(q: Question): void {
    // this is to ensure that lingering subscription don't cause any issue
    if (q.type !== this.configType) {
      return;
    }

    const allApplicants = this.application.applicants;
    this.filterConfig = this.details.comparisonFilters;
    const applicantFilter = new ApplicantComparisonFilter();
    const details = this.details.items;
    if (this.answer) {
      // already answer saved earlier
      // TODO: simplify this logic, not easier to maintain
      // TODO: create an appropriate model instead of dynamic properties.
      // TODO: Need better handling for lingering subscription
      if (this.answer.value) {
        const list: MedicalItem[] = this.answer.value;

        const allApplicantsData = [
          ...[allApplicants, list]
            .reduce(
              // loop - left join merge where on clientNo, like sql left join
              (m, a) => (
                a &&
                  a.forEach(
                    (o) =>
                      (m.has(o.clientNo) &&
                        Object.assign(m.get(o.clientNo), o)) ||
                      m.set(o.clientNo, o)
                  ),
                m
              ),
              new Map()
            )
            .values(),
        ];

        allApplicantsData.map((a) => {
          a['details'] = details;
        });

        this.applicants = allApplicantsData.filter((a) => {
          return this.filterService.Apply({
            answers: this.script.Answers,
            application: this.application,
            applicant: a,
            filterJson: this.details.filters,
            question: q,
          });
        });
      }
    } else {
      // if no answer, fresh start
      allApplicants.map((a) => {
        a['details'] = details;
      });

      const filterApplicants = allApplicants.filter((x) => {
        return this.filterService.Apply({
          answers: this.script.Answers,
          application: this.application,
          applicant: x,
          filterJson: this.details.filters,
          question: q,
        });
      });
      const applicantsData = filterApplicants.map((x) => ({
        ...x,
        applicable: null,
        value: null,
        details,
        verification: null,
      }));

      this.applicants = applicantsData;
    }
  }

  CanGoNext(): boolean {
    const notSelected = this.applicants.filter((a) => {
      return a.applicable === null;
    });

    if (notSelected.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  ezAppValue(clientNo: string) {
    switch (this.question.answerTag) {
      case 'Demographic.Employment':
        return this.application.applicants.find((a) => a.clientNo === clientNo)
          .occupation;
    }

    return null;
  }

  optionChanged(clientNumber, chosenValue) {
    this.selectedApplicants = [];
    this.clientNumber = clientNumber;
    this.selectedValue = chosenValue;
    const selectedClient = this.applicants.filter((a) => {
      return a.clientNo === clientNumber;
    });
    let existing = [];
    if (this.answer && this.answer.value) {
      existing = this.answer.value.filter((v) => {
        return v.clientNo === this.clientNumber;
      });
    }
    if (selectedClient.length > 0) {
      selectedClient[0].applicable = chosenValue;
    }
    if (
      selectedClient[0].value !== chosenValue &&
      existing.length > 0 &&
      existing[0].values &&
      existing[0].values.length > 0
    ) {
      this.selectedApplicants.push(selectedClient[0]);
      this.openModal = true;
    } else {
      this.confirm('Done'); // HACK: don't like this construct
    }

    selectedClient[0].value = chosenValue;
  }

  confirm(action: any) {
    if (action === 'Done') {
      if (this.answer && this.answer.value) {
        const existing = this.answer.value.filter((v) => {
          return v.clientNo === this.clientNumber;
        });
        if (existing.length > 0) {
          existing[0].applicable = true;
          existing[0].value = this.selectedValue;
          existing[0].values = [];
        } else {
          this.answer.value.push({
            clientNo: this.clientNumber,
            applicable: true,
            value: this.selectedValue,
            ezappValue: this.ezAppValue(this.clientNumber),
            verification: true,
          });
        }
        // this.saveAnswer(true, this.answer.value);
        this.saveAll(this.answer.value);
      } else {
        this.answer = new Answer();
        this.answer.value = [
          {
            clientNo: this.clientNumber,
            applicable: true,
            value: this.selectedValue,
            ezappValue: this.ezAppValue(this.clientNumber),
            verification: true,
          },
        ];
        // this.saveAnswer(true, this.answer.value);
        this.saveAll(this.answer.value);
      }
      this.openModal = false;
    }
  }

  saveAll(items: MedicalItem[]) {
    // Creating nodes for all applicants
    // for applicants that are currently being displayed setting verfication = true;
    // means if verification = null, those applicants were not shown the question.

    const defaultList = new Array<MedicalItem>();
    this.application.applicants.map((a) =>
      defaultList.push({
        clientNo: a.clientNo,
        applicable: false,
        value: null,
        verification: null,
      })
    );

    items.map((i) => {
      let defaultItem = defaultList.find((d) => d.clientNo === i.clientNo);
      defaultItem = Object.assign(defaultItem, i);
    });

    this.saveAnswer(true, defaultList, this.answerType);
  }

  cancel(action: any) {
    if (action === 'Cancel') {
      this.answer.value.forEach((element) => {
        if (element.clientNo === this.clientNumber) {
          this.applicants.forEach((a) => {
            if (a.clientNo === this.clientNumber) {
              a.value = element.value;
            }
          });
        }
      });
      this.openModal = false;
    }
  }
}

export class VcallQualifyingQuestionStudentDetail {
  options: string;
  filters: string;
  informationText: string;
  get items() {
    if (this.options) {
      const result = JSON.parse(this.options);
      return result;
    } else {
      return [];
    }
  }
  get comparisonFilters() {
    if (this.filters) {
      const result = JSON.parse(this.filters);
      return result;
    } else {
      return [];
    }
  }
}
