import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HandledHttpClient } from '../handledHttpClient';

@Injectable({
  providedIn: 'root',
})
export class LoggerApiService {
  private baseUrl: string;
  constructor(private http: HandledHttpClient) {
    this.baseUrl = environment.config.apis.logger;
  }
  /**
   * @description for store the error messages in portal azure
   */
  public logMessage<T>(logMessage): Observable<T> {
    return this.http.post(this.baseUrl + '/logger/v1/LogMessage', logMessage, {
      errorMessage: `Error Logger`,
      donotLog: true,
    });
  }
}
