<div class="row p-3">
  <div
    class="radio mr-4"
    data-toggle="buttons"
    *ngFor="let applicant of applicants"
  >
    <div class="d-flex flex-row justify-content-center font-weight-bold">
      <p>
        {{ applicant.firstName | titlecase }}
        {{ applicant.lastName | titlecase }} &nbsp;
      </p>
      <p>({{ applicant.relationship | relation }})</p>
    </div>
    <div>
      <label
        *ngFor="let detail of applicant.details"
        class="btn applicants"
        [ngClass]="{ active: applicant.value === detail.value }"
      >
        <input
          [id]="applicant.clientNo + detail.value"
          type="radio"
          [checked]="applicant.value === detail.value"
          [name]="applicant.clientNo"
          (click)="optionChanged(applicant.clientNo, detail.value)"
        />
        <span class="cr mt-2 mb-2"><i class="cr-icon fas fa-check"></i></span>
        <div>
          <p class="mb-0">
            {{ detail.key }}
          </p>
        </div>
      </label>
    </div>
  </div>
</div>
<div class="row justify-content-end">
  <div
    *ngIf="question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>
</div>
<app-modal
  [open]="openModal"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event); cancel($event)"
>
  <div title>
    Confirmation
  </div>
  <div body>
    <div class="pl-2">
      {{ details.informationText }}
      <span *ngFor="let item of selectedApplicants"
        ><div class="text-capitalize">
          {{ item.firstName.toLowerCase() + " " + item.lastName.toLowerCase() }}
        </div></span
      >
    </div>
  </div>
</app-modal>
