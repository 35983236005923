import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Answer } from '../../models/application/answer';
import { Application } from '../../models/application/application';
import { Appointment } from '../../models/appointment';
import { DisplayWhen } from '../../models/vcall/displayWhen';
import { Question } from '../../models/vcall/question';
import { Script } from '../../models/vcall/script';
import { ScriptItem } from '../../models/vcall/scriptItem';
import { Section } from '../../models/vcall/section';
import { VcallParams } from '../../models/vcall/vcallParams';
import { VcallApiService } from '../api/vcall/vcall-api.service';
import { ApplicationService } from '../application/application.service';
import { QuestionFilterService } from '../filter/question-filter/question-filter.service';
import { ProcessingService } from '../processors/processing.service';
import { ProcessorModel } from '../processors/processor-model';
import { TimerService } from '../utility/timer.service';
import * as dayjs from 'dayjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  constructor(
    private api: VcallApiService,
    private router: Router,
    private applicationService: ApplicationService,
    private filterService: QuestionFilterService,
    private processor: ProcessingService,
    private timer: TimerService
  ) {
    this.applicationService.SelectedApplication.subscribe((a) => {
      this.application = a;
    });

    this.applicationService.Answers.subscribe((a) => (this.Answers = a));
  }

  public Sections: BehaviorSubject<Section[]> = new BehaviorSubject<Section[]>(
    null
  );
  public ApplicationScript: BehaviorSubject<Script> = new BehaviorSubject<
    Script
  >(null);
  public vCallParams: BehaviorSubject<VcallParams> = new BehaviorSubject<
    VcallParams
  >(null);

  public SelectedQuestion: BehaviorSubject<Question> = new BehaviorSubject<
    Question
  >(new Question());
  public SelectedSection: BehaviorSubject<Section> = new BehaviorSubject<
    Section
  >(new Section());
  public Answers: Array<Answer> = new Array<Answer>();
  public Navigator = new BehaviorSubject<NavigationParameter>({
    canGoNext: false,
    answerValue: null,
  });

  public PromptText = new BehaviorSubject<string>('');
  public CurrentAppointment: Appointment = null;
  public scriptVersion: ScriptVersion;

  private script: Script = null;
  private selectedQuestion: Question = null;
  private selectedSection: Section = null;
  private appointmentId: string = null;
  private application: Application = null;
  private displayWhen: DisplayWhen[] = null;
  private mode: VcallMode = VcallMode.Standard;
  private isQuestion = false;
  private canClose = false;
  private token: string;

  /**
   * @param options applicationId, appointmentId, questionId, sectionId and all the inforamtion
   * @description load the script data (all the question)
   */
  public async LoadScript(options: VcallParams) {
    this.mode = options.mode;
    this.appointmentId = options.appointmentId;

    // console.log(`script-service loading script for ${options.applicationId}`);
    // console.log('script-service', options);

    if (this.script && this.script.applicationId === options.applicationId) {
      // console.log('script-service', this.script);
      this.ApplicationScript.next(this.script);
      // this.ApplicationScript.next(this.script);
      this.Sections.next(this.script.items.map((s) => s.section));
      // Load the Section / Question to be displayed.
      if (!options.stopNavigation) {
        this.FindQuestionToLoad(
          options.questionId,
          options.sectionId,
          options.mode
        );
      }
    } else {
      // removed the observable as it was causing unexpected behavior when redirecting after vcall complete.
      const scriptVersion = this.applicationService.getScriptVersionFromAnswer();
      if (this.scriptVersion !== scriptVersion) {
        this.loadScriptVersion(options, scriptVersion);
      }
    }
  }

  private loadScriptVersion(options, scriptVersion) {
    this.api.GetScript(options, scriptVersion).subscribe((script) => {
      // tagging all questions with section for easiness.
      script.applicationId = options.applicationId;
      script.items.map((s) => {
        s.section.totalQuestions = s.questions.length;
        s.questions.map((q) => ((q.section = s.section), (q.isFirst = false)));
      });

      // Mark the first question so can drive some UI Element
      if (
        script.items &&
        script.items.length > 0 &&
        script.items[0].questions &&
        script.items[0].questions.length > 0
      ) {
        const firstQuestionOfTheScript = script.items
          .sort((s) => s.section.sequence)[0]
          .questions.sort((q) => q.sequence)[0];
        firstQuestionOfTheScript.isFirst = true;
      }

      this.script = script;
      Object.freeze(script);
      this.ApplicationScript.next(script);
      this.Sections.next(script.items.map((s) => s.section));
      // Load the Section / Question to be displayed.
      if (options.stopNavigation === null) {
        this.FindQuestionToLoad(
          options.questionId,
          options.sectionId,
          options.mode
        );
      }
    });
  }

  /**
   * @param options applicationId, appointmentId, questionId, sectionId and all the inforamtion
   * @description if the route is changed check the question
   */
  public RouteChanged(options: VcallParams) {
    // Condition 1 : no active script and params are there
    // Condition 2 : both script and params are there and applicationId is different requesting a different script
    this.appointmentId = options.appointmentId;
    this.mode = options.mode;

    if (
      (!this.script && options) ||
      (options &&
        this.script &&
        options.applicationId !== this.script.applicationId.toString())
    ) {
      this.LoadScript(options);
    } else {
      this.FindQuestionToLoad(
        options.questionId,
        options.sectionId,
        options.mode
      );
    }
  }

  public LoadRoute(options: VcallParams) {
    // Condition 1 : no active script and params are there
    // Condition 2 : both script and params are there and applicationId is different requesting a different script
    this.appointmentId = options.appointmentId;
    this.mode = options.mode;
    this.LoadScript(options);
  }

  // this.filterService.Apply({question : q , application : this.application, answers : this.Answers})

  /**
   * @param fromQuestion current question
   * @description go to previous question
   */
  public GoToPreviousQuestion(fromQuestion: Question) {
    const nextQuestion = this.script.items
      .find((q) => q.section.name === fromQuestion.section.name)
      .questions.sort((a, b) => b.sequence - a.sequence)
      .find(
        (q) =>
          q.sequence < fromQuestion.sequence &&
          this.filterService.Apply({
            question: q,
            application: this.application,
            answers: this.Answers,
            mode: this.mode,
            script: this.script,
          })
      );

    if (nextQuestion != null) {
      this.LoadQuestion(
        nextQuestion,
        fromQuestion.section,
        this.script.applicationId.toString(),
        this.mode
      );
    } else {
      const items = [];
      Object.assign(items, this.script.items);
      const previousSection = items
        .sort((prev, next) => next.section.sequence - prev.section.sequence)
        .find((q) => q.section.sequence < fromQuestion?.section.sequence);

      const previousQuestion = this.script.items
        .find((q) => q.section.name === previousSection?.section.name)
        ?.questions.sort((a, b) => b.sequence - a.sequence);

      if (previousSection !== null && previousQuestion !== undefined) {
        const tempQuestion = previousQuestion.find((q) =>
          this.filterService.Apply({
            question: q,
            application: this.application,
            answers: this.Answers,
            mode: this.mode,
            script: this.script,
          })
        );
        this.FindQuestionToLoad(
          tempQuestion.id.toString(),
          previousSection.section.name,
          this.mode
        );
      }
    }
  }

  /**
   * @param fromQuestion current question
   * @description go to next question
   */
  public async GoToNextQuestion(fromQuestion: Question) {
    const nextProcessing = fromQuestion.details['nextProcessingKey'];
    const model = new ProcessorModel();
    model.application = this.application;
    model.script = this.script;
    model.answers = this.Answers;
    model.agent = this.application['agent'];
    model.logger = null;
    model.question = fromQuestion;
    model.appointment = this.CurrentAppointment;
    console.log(model);

    if (nextProcessing) {
      this.processor.Run(nextProcessing, model);
    }
    this.timer.stop(fromQuestion.id);
    this.processor.Run('LogProgress', model);

    const isSuccess = await this.applicationService.PersistAnswers();

    if (isSuccess === false) {
      throw new Error('Could not save the Answers');
    }
    // So either this control decides the next question or call an API to bring back the next question

    // if fromQuestion is null goto the first question of the first section

    const nextQuestion = this.script.items
      .find((q) => q.section.name === fromQuestion.section.name)
      .questions.sort((a, b) => a.sequence - b.sequence)
      .find(
        (q) =>
          q.sequence > fromQuestion.sequence &&
          this.filterService.Apply({
            question: q,
            application: this.application,
            answers: this.Answers,
            mode: this.mode,
            script: this.script,
          })
      );

    if (nextQuestion != null) {
      this.LoadQuestion(
        nextQuestion,
        fromQuestion.section,
        this.script.applicationId.toString(),
        this.mode
      );
    } else {
      const nextSection = this.script.items
        .sort((q) => q.section.sequence)
        .find((q) => q.section.sequence > fromQuestion.section.sequence);

      if (nextSection != null) {
        this.FindQuestionToLoad(null, nextSection.section.name, this.mode);
      } else {
        this.canClose = true;
      }
    }
  }

  public Clear() {
    this.Reset(this.SelectedQuestion, 'selectedQuestion');
    this.Reset(this.SelectedSection, 'selectedSection');
    this.Reset(this.ApplicationScript, 'script');
  }

  private Reset(subject: BehaviorSubject<any>, property?: any) {
    if (property) {
      this[property] = null;
    }

    subject.next(null);
  }

  /**
   * @param questionId current question Id
   * @param sectionId section Id
   * @param modeStatus mode status like standard or recall
   * @description find the question to load
   */
  private FindQuestionToLoad(
    questionId: string,
    sectionId: string,
    modeStatus: VcallMode
  ) {
    const isNothingLoaded =
      this.selectedQuestion === null && this.selectedSection === null;
    const hasRouteChanged =
      this.selectedSection &&
      this.selectedQuestion &&
      (questionId !== this.selectedQuestion.id.toString() ||
        sectionId !== this.selectedSection.name);

    if (isNothingLoaded || hasRouteChanged) {
      // Route could have been changed due to url update from navigation so unless required don't forward the request
      // if section was provided find that section and load it.
      // if section was not in the options, select the first section
      // if the question was provided find that question
      // if the question was not provided load the first question

      let sectionItemToLoad: ScriptItem = null;
      let questionToLoad: Question = null;

      if (sectionId) {
        sectionItemToLoad = this.script.items.find(
          (q) => q.section.name === sectionId
        );
      }

      if (sectionItemToLoad === null) {
        console.log(
          'if you are here meaning either no section was provided or non-available section was provided'
        );
        sectionItemToLoad = this.script.items[0];
      }

      if (questionId) {
        questionToLoad = sectionItemToLoad.questions.find(
          (q) => q.id.toString() === questionId.trim()
        );
      }

      if (!questionToLoad) {
        questionToLoad = sectionItemToLoad.questions
          .sort((a, b) => a.sequence - b.sequence)
          .find((q) => {
            return this.filterService.Apply({
              question: q,
              application: this.application,
              answers: this.Answers,
              mode: this.mode,
              script: this.script,
            });
          });
        // questionToLoad = sectionItemToLoad.questions[0];
        console.log(
          'if you are here means the sectionId and questionId are not in sync. or questionId was not provided.'
        );
        if (questionToLoad === undefined) {
          const nextSection = this.script.items
            .sort((q) => q.section.sequence)
            .find(
              (question) =>
                question.section.sequence > sectionItemToLoad.section.sequence
            );

          if (nextSection != null) {
            this.FindQuestionToLoad(null, nextSection.section.name, modeStatus);
          }
        }
      }

      this.LoadQuestion(
        questionToLoad,
        sectionItemToLoad.section,
        this.script.applicationId.toString(),
        modeStatus
      );
    }
  }

  /**
   * @param question whole question information
   * @param section section related information
   * @param applicationId application Id
   * @description load the current question
   */
  public LoadQuestion(
    question: Question,
    section: Section,
    applicationId: string,
    mode?: VcallMode
  ) {
    if (this.selectedQuestion !== question) {
      this.selectedQuestion = JSON.parse(JSON.stringify(question));
      this.timer.start(question.id);
      this.SelectedQuestion.next(this.selectedQuestion);
    }

    if (this.selectedSection !== section) {
      this.selectedSection = section;
      this.SelectedSection.next(section);
    }

    // TODO: create this url using router.
    // Update the route
    // if (mode) {
    //   this.router.navigate([
    //     `/vcall/application/${applicationId}/section/${section.name}/question/${question.id}/appointment/` +
    //       `${this.appointmentId}/mode/${mode}`,
    //     { state: ['forward'] },
    //   ]);
    // } else

    // Avoid cross contamination.
    if (this.application && applicationId === this.application.id.toString()) {
      this.router.navigate([
        `/vcall/application/${applicationId}/section/${section.name}/question/${question.id}/appointment/${this.appointmentId}`,
        { state: ['forward'] },
      ]);
    } else {
      alert(
        'Unexpected application id and appointment encountered. Please, close the browser and relaunch the application.'
      );
    }
  }

  /**
   * @param fromQuestion current question
   * @description find the section and questions length
   */
  public FindSectionQuestionsLength(fromQuestion: Question): number {
    return this.script === null
      ? 0
      : this.script.items.find(
          (q) => q.section.name === fromQuestion.section.name
        ).questions.length;
  }

  private FindQuestionOnAttributeType(question: Question): boolean {
    let isQuestion = true;
    if (question.displayWhen !== null) {
      isQuestion = false;
      this.displayWhen = JSON.parse(question.displayWhen);
      this.applicationService.SelectedApplication.subscribe((a) => {
        this.application = a;

        this.displayWhen.forEach((q) => {
          const path = q.path.split('.');
          if (q.type === 'ApplicationAttribute') {
            if (
              !Array.isArray(this.application[path[0]]) &&
              this.application[path[0]][path[1]] === q.value
            ) {
              isQuestion = true;
            } else if (Array.isArray(this.application[path[0]])) {
              const value = this.application[path[0]].filter(
                (obj) => obj[path[1]] === q.value
              );
              if (value.length > 0) {
                isQuestion = true;
              }
            }
          } else if (q.type === 'AnswerTag' && isQuestion) {
            const answer = this.applicationService.GetAnswerForAnswerTag(
              q.path
            );
            if (answer && answer.value === q.value.toString()) {
              isQuestion = true;
            } else {
              isQuestion = false;
            }
          }
        });
      });
    } else {
      return isQuestion;
    }
    return isQuestion;
  }
  public canCloseSession() {
    return this.canClose;
  }

  // TODO: looks not being used by an active component
  // need to confirm.
  public RegisterAnswer(answer: any, question: Question) {
    question.answer = answer;
    this.selectedQuestion = question;
    this.SelectedQuestion.next(question);
  }

  public UpdateAnswer(canGoNext: boolean, answer: any) {
    const param: NavigationParameter = {
      canGoNext,
      answerValue: answer,
    };
    this.Navigator.next(param);
  }

  /**
   * @param text text as string
   * @description update the prompt text
   */
  public UpdatePromptText(text: string) {
    this.PromptText.next(text);
  }

  public SetScriptVersion() {
    const answer = new Answer();
    const formattedDate = dayjs(new Date()).format('MM-DD-YYYY');
    answer.answerTag = 'Script.Version';
    answer.value = {
      startDate: formattedDate,
    };

    this.applicationService.RegisterAnswerByTag(
      true,
      answer.value,
      answer.answerTag
    );
    this.applicationService.PersistAnswers();
  }

  public GetScriptVersionFromAnswer(): Observable<ScriptVersion> {
    return this.applicationService.Answers.pipe(
      map((answers: Answer[]) => {
        if (answers && answers.length) {
          const scriptValue = answers.find(
            (item: Answer) =>
              item.answerTag.toLowerCase() === 'Script.Version'.toLowerCase()
          );
          return scriptValue ? scriptValue.value : null;
        }
        return null;
      })
    );
  }
}

export interface NavigationParameter {
  canGoNext: boolean;
  answerValue: any;
}

export enum VcallMode {
  Standard = 'Standard',
  Recall = 'Recall',
}

export interface ScriptVersion {
  startDate: string;
}
