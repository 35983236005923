import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Question } from 'src/app/shared/models/vcall/question';
import { Subscription, BehaviorSubject } from 'rxjs';
import { VcallQuestionTypeBooleanDetail } from '../../../types/boolean/boolean.component';
import { Application } from 'src/app/shared/models/application/application';
import { Payment } from 'src/app/shared/models/application/payment';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VcallQuestionTypePaymentDetail } from 'src/app/shared/models/vcall/paymentDetail';

@Component({
  selector: 'app-question-initial-payment-mode',
  templateUrl: './initial-payment-mode.component.html',
  styleUrls: ['./initial-payment-mode.component.scss'],
})
export class InitialPaymentModeComponent implements OnInit, OnDestroy {
  question: Question = null;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  paymentDetails = new VcallQuestionTypePaymentDetail();
  application: Application = null;
  payment: Payment = null;
  editPayment: Payment;
  @ViewChild('paymentno') paymentno: ElementRef;
  donotagree = false;
  constructor(
    private script: ScriptService,
    private applicationService: ApplicationService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.script.SelectedQuestion.subscribe((q) => {
        this.question = q;
        Object.assign(this.paymentDetails, this.question.details);
      })
    );
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a !== null) {
          this.application = a;
          this.payment = a.payment;
        }
      })
    );
  }
  optionChanged(answer: any) {
    if (answer) {
      this.script.RegisterAnswer(answer, this.question);
    }
  }
  editQuestion(content: any): void {
    this.editPayment = Object.assign({}, this.payment);
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      })
      .result.then(
        (result) => {
          if (result === 'Save') {
            this.paymentno.nativeElement.checked = false;
            this.payment = Object.assign({}, this.editPayment);
          }
        },
        (reason) => {}
      );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
