import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { NgForm } from '@angular/forms';
import { Contact } from 'src/app/shared/models/application/contact';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UspsService } from 'src/app/shared/services/usps.service';
import { Address } from 'src/app/shared/models/address';
import { BaseVerifiableListQuestionType } from '../types/verifiableList/baseVerifiableListQuestionType';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
})
export class DemographicsComponent
  extends BaseVerifiableListQuestionType<string, Contact>
  implements OnDestroy {
  editContact: Contact;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  isAddressInValid = false;
  private modalRef: NgbModalRef;
  @ViewChild('uspsAddress') private form: NgForm;
  saveClicked = false;
  addressFormIsValid;

  constructor(
    private modalService: NgbModal,
    public scriptService: ScriptService,
    applicationService: ApplicationService,
    private uspsService: UspsService,
    private SpinnerService: NgxSpinnerService
  ) {
    super(scriptService, applicationService);
  }

  Save(f) {
    this.saveClicked = true;
    if (f.valid) {
      if (this.addressFormIsValid) {
        if (this.isAddressInValid) {
          this.editCompleted();
        } else {
          const address = {
            city: this.editContact.city,
            state: this.editContact.state,
            streetAddress: this.editContact.streetAddress,
            zipCode: this.editContact.zipCode,
          };
          this.SpinnerService.show();
          this.subscriptions.push(
            this.uspsService
              .isAddressValid(address)
              .subscribe((isValid: boolean) => {
                this.SpinnerService.hide();
                if (!isValid) {
                  this.isAddressInValid = true;
                } else {
                  this.editCompleted();
                }
              })
          );
        }
      }
    }
  }

  editCompleted() {
    Object.keys(this.editContact).map((k) => {
      const item = this.verifiableList.find((v) => v.propertyName === k);
      if (item && item.originalValue !== this.editContact[k]) {
        item.changedValue = this.editContact[k];
        item.verification = null;
      }
    });
    this.ResetCanGoNext();
    this.modalRef.close();
  }

  open(content) {
    this.editContact = Object.assign({}, this.GetModel());
    this.isAddressInValid = false;
    const options: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    Object.keys(this.editContact).map((k) => {
      const item = this.verifiableList.find((v) => v.propertyName === k);
      if (item && item.originalValue !== this.editContact[k]) {
        this.editContact[k] = item.changedValue;
      }
    });
    this.modalRef = this.modalService.open(content, options);
  }

  OnformCompleted(result: Address) {
    this.editContact.streetAddress = result.streetAddress;
    this.editContact.city = result.city;
    this.editContact.state = result.state;
    this.editContact.zipCode = result.zipCode;
  }
  OnAddressFormValidity(valid: boolean) {
    this.addressFormIsValid = valid;
  }

  GetModel(): Contact {
    return this.application.contact;
  }

  GetBaseAnswerTag = () => {
    return 'contact';
  };

  ApplicationModified() {}

  QuestionModified() {}
  CanProceedNext() {
    return true;
  }

  GetSubGroup() {
    return null;
  }

  ResetView(): void {}

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
