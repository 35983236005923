import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges {
  @ViewChild('modalReuse') modalElement: ElementRef;
  @Input() open: any = false;
  @Input() modalOptions = {}; // { centered: false, size: 'lg' };
  @Input() buttonsConfig = {};
  @Input() footer = true;
  @Input() formValidation = true;
  @Input() bgClass = false;

  private modalRef: NgbModalRef;
  @Output() actions = new EventEmitter();
  constructor(private modalService: NgbModal) {
    this.buttonsConfig = {
      ...{
        // predefined
        modalMode: 'form', // modalmode: form or msg
        submitButtonName: 'Submit',
        cancelButtonName: 'Cancel',
        showModalCrossIcon: true,
        showModalHeader: true,
      },
      ...this.buttonsConfig,
    }; // overwrite
  }

  /**
   * @ignore ignore
   */
  ngOnInit(): void {}

  /**
   * @param changes property name changes
   * @description decide the modal should close or not
   */
  ngOnChanges(changes: SimpleChanges) {
    const change = changes['open'];
    if (change && !change.firstChange) {
      if (change.currentValue === true) {
        this.openModal(this.modalElement);
      } else {
        this.modalRef.close();
      }
    }
  }

  /**
   * @param content modal data
   * @description display the modal
   */
  openModal(content) {
    const predefined: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static', // dont override backdrop prop due to multitype support boolean | string (go to definition of NgbModalOptions)
      keyboard: false,
      centered: true,
    };
    const options: NgbModalOptions = { ...predefined, ...this.modalOptions };
    this.modalRef = this.modalService.open(content, options);

    this.modalRef.result.then(
      (result) => {
        // save - Done
        console.log(result);
        this.actions.emit(result);
      },
      (reason) => {
        this.actions.emit(reason);
      }
    );
  }

  /**
   * @param action modal actions like 'cancel, 'confirm', and etc
   */
  onSave(action) {
    this.modalRef.close();
    this.actions.emit(action);
    this.openModal(this.modalElement);
  }
}
