import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { STATES } from 'src/app/shared/models/states';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentSearchResult } from 'src/app/shared/models/callzone-appointment';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { Subscription, Observable } from 'rxjs';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { CallStatusData } from './call-status';
import {
  CallType,
  CallZoneApiService,
  StatusType,
} from 'src/app/shared/services/api/callzone/callzone-api.service';
import { DetailOptions } from 'src/app/shared/models/detail-options';
import { ApplicationService } from 'src/app/shared/services/application/application.service';

@Component({
  selector: 'app-search-appointments',
  templateUrl: './search-appointments.component.html',
  styleUrls: ['./search-appointments.component.scss'],
})
export class SearchAppointmentsComponent implements OnInit {
  searchForm: UntypedFormGroup;
  isSearch: boolean;
  states: any;
  pageSize = 10;
  page = 0;
  appointmentList;
  previousPage: number;
  pageNumber = 1;
  totalNoOfRecords: number;
  timeZone: string;
  subscription: Subscription;
  selectedValue: string;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  status = CallStatusData;
  openModal = false;
  config: DetailOptions = {
    canStartVerification: false,
    canAddNotes: true,
  };
  callTypes: CallType[] = [];
  statusTypes: StatusType[] = [];
  sortOrder = true;
  sortColumn = 'ScheduledDateUTC';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appointmentService: AppointmentService,
    private SpinnerService: NgxSpinnerService,
    private profileService: UserProfileService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public callzoneService: CallZoneApiService,
    public applicationService: ApplicationService
  ) {}
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    const isValidDate = this.calendar.isValid(NgbDate.from(parsed));
    if (!isValidDate) {
      if (!this.fromDate || !this.toDate) {
        this.searchForm.patchValue({
          date: null,
        });
      }
    }
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  ngOnInit() {
    this.getProfileDetails();
    this.initForm();
    this.isSearch = false;
    this.getQueryparamsAndSearch();
    this.formValueChanges();
    this.getCallTypes();
    this.getStatusTypes();
  }
  getCallTypes() {
    this.callzoneService.GetCallTypes().subscribe((res: CallType[]) => {
      if (res) {
        this.callTypes = res;
      }
    });
  }
  getStatusTypes() {
    this.callzoneService.GetStatusTypes().subscribe((res: CallType[]) => {
      if (res) {
        this.statusTypes = res;
        this.statusTypes.unshift({
          is_Active: true,
          label: 'All',
          order: '0',
        });
      }
    });
  }

  getQueryparamsAndSearch() {
    const uriParams = this.route.snapshot.queryParams;
    const { dateTo, dateFrom } = uriParams;
    if (Object.keys(uriParams).length > 0) {
      this.toDate = this.parsedDate(dateTo);
      this.fromDate = this.parsedDate(dateFrom);
      this.searchForm.patchValue({
        applicationId: uriParams.applicationId || '',
        firstName: uriParams.applicantFirstName || '',
        lastName: uriParams.applicantLastName || '',
        state: uriParams.state || '',
        toDate: this.toDate || '',
        fromDate: this.fromDate || '',
        date: this.toDate || this.fromDate || null,
        policyNumber: uriParams.policyNumber || '',
        callType: uriParams.callTypeId || '',
        statusType: uriParams.statusId || '',
      });
      this.searchApplications();
    }
  }

  initForm() {
    this.searchForm = new UntypedFormGroup(
      {
        applicationId: new UntypedFormControl('', Validators.pattern('[0-9]+')),
        firstName: new UntypedFormControl(''),
        lastName: new UntypedFormControl(''),
        policyNumber: new UntypedFormControl(''),
        callType: new UntypedFormControl(''),
        statusType: new UntypedFormControl(''),
        state: new UntypedFormControl(''),
        date: new UntypedFormControl(null),
        fromDate: new UntypedFormControl(null),
        toDate: new UntypedFormControl(null),
      },
      this.atLeastOneInputHasValue()
    );
  }

  // not displaying message, but making form-invalid
  // & disable button if atleast one value available for search
  atLeastOneInputHasValue = () => {
    return (group: UntypedFormGroup) => {
      if (!Object.values(group.value).find((value) => value !== '')) {
        return { message: 'Please input at least one value' };
      }
      return null;
    };
  };
  parsedDate(date) {
    if (date) {
      const parsed = this.formatter.parse(date);
      return parsed && this.calendar.isValid(NgbDate.from(parsed))
        ? NgbDate.from(parsed)
        : null;
    }
  }
  /**
   * Subscribes to the value changes of form and sets error to the form if all the inputs are empty
   */
  formValueChanges() {
    this.searchForm.valueChanges.subscribe((values) => {
      const emptyValue = Object.keys(this.searchForm.value).every(
        (eachFormValue) => !this.searchForm.value[eachFormValue]
      );
      emptyValue
        ? this.searchForm.get('date').setErrors({ emptyInputs: true })
        : this.searchForm.get('date').setErrors(null);
    });
  }

  navigate(url, appId, tabName, item) {
    if (url) {
      !tabName
        ? window.open(`${url}/${appId}`, '_blank')
        : window.open(`${url}/${appId}?tabName=${tabName}`);
    } else {
      const applicationId = item.applicationId.toString();
      this.applicationService.SelectApplication(applicationId);
      this.appointmentService.SelectAppointment(item);
      this.openModal = true;
    }
  }

  getProfileDetails() {
    this.timeZone = this.profileService.getCallzoneUser()?.timeZone;
  }

  loadPage(page: number) {
    this.pageNumber = page;
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.searchApplications();
    }
  }

  // sorting appointments with respective column
  sort(columnName, eve) {
    this.sortColumn = columnName;
    this.sortOrder = !this.sortOrder;
    const element = eve.target;
    const sortType =
      element.className.includes('active') && this.sortOrder ? 'ASC' : 'DESC';
    this.searchForm.value['SortBy'] = this.sortColumn + ' ' + sortType;
    this.searchApplications();
  }

  searchApplications() {
    if (this.searchForm.valid) {
      this.isSearch = true;
      this.SpinnerService.show();
      // search Query
      const appointmentSearchRequest = this.appointmentSearchRequest(
        this.searchForm.value,
        true
      );
      const search = this.appointmentSearchQuery(appointmentSearchRequest);
      // If sorting with column name with sortType ex: "SortBy: 'statusName DESC'"
      if (this.searchForm.value.hasOwnProperty('SortBy')) {
        search['SortBy'] = this.searchForm.value['SortBy'];
      } else {
        delete search['SortBy'];
      }
      // for setting the query params
      const appointmentQueryParams = this.appointmentSearchRequest(
        this.searchForm.value,
        false
      );
      const searchQuery = this.appointmentSearchQuery(appointmentQueryParams);
      // navigation with all the parameters
      const { ...fields }: any = searchQuery;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: fields,
      });
      // api call
      if (Object.keys(search).length !== 0) {
        this.appointmentService
          .SearchAppointments(search)
          .then((data: AppointmentSearchResult) => {
            if (data) {
              this.SpinnerService.hide();
              this.appointmentList = data.items;
              this.appointmentList.map((item) => {
                if (item.statusName === 'TODO') {
                  item.statusName = 'To Do';
                }
              });
              this.totalNoOfRecords = data.metaData.numberOfRecords;
            }
          });
      } else {
        this.SpinnerService.hide();
        this.isSearch = false;
      }
    }
  }

  appointmentSearchQuery(appointmentSearchRequest) {
    let search = Object.keys(appointmentSearchRequest).reduce((acc, el) => {
      // excluding empty valued parameters before passing to api
      if (appointmentSearchRequest[el] !== '') {
        acc[el] = appointmentSearchRequest[el];
      }
      if (acc['applicationId'] === 0) {
        delete acc['applicationId'];
      }
      return acc;
    }, {});
    if (Object.keys(search).length <= 2) {
      search = {};
    }
    return search;
  }

  appointmentSearchRequest(formData, isQueryParams: boolean) {
    const appointmentSearchRequest = {
      applicationId: Number(formData.applicationId),
      applicantFirstName: formData.firstName,
      applicantLastName: formData.lastName,
      state: formData.state,
      pageNumber: this.pageNumber - 1,
      pageSize: this.pageSize,
      policyNumber: formData.policyNumber,
      statusId: Number(formData.statusType),
      callTypeId: Number(formData.callType),
    };
    if (this.fromDate || this.toDate) {
      if (isQueryParams) {
        appointmentSearchRequest['dateFrom'] = this.getTimeStamp(this.fromDate);
        appointmentSearchRequest['dateTo'] = this.getTimeStamp(this.toDate);
      } else {
        appointmentSearchRequest['dateFrom'] =
          Object.values(this.fromDate || []).join('-') ?? null;
        appointmentSearchRequest['dateTo'] =
          Object.values(this.toDate || []).join('-') ?? null;
      }
    }
    return appointmentSearchRequest;
  }

  getTimeStamp(date) {
    if (date) {
      return new Date(date.year, date.month - 1, date.day + 1);
    }
  }
  getNotifyEvent() {
    this.openModal = false;
    this.appointmentService.Clear();
    this.applicationService.Clear();
  }
}
