import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      let dateParts;
      if (value.trim().includes('/')) {
        dateParts = value.trim().split('/');
      } else {
        const date = value.trim().split('-');
        return {
          year: toInteger(date[0]),
          month: toInteger(date[1]),
          day: toInteger(date[2]),
        };
      }

      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: toInteger(dateParts[0]), month: null, year: null };
      } else if (
        dateParts.length === 2 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1])
      ) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: null,
        };
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: toInteger(dateParts[2]),
        };
      }
    }
    return null;
  }

  /**
   * @param date date
   * @description format the date
   */
  format(date: NgbDateStruct): string {
    return date
      ? `${isNumber(date.month) ? padNumber(date.month) : ''}-${
          isNumber(date.day) ? padNumber(date.day) : ''
        }-${date.year}`
      : '';
  }
}

/**
 * @description format to integer
 */
export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

/**
 * @description format to number
 */
export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}
