import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Permission } from '../../models/vcall/permission';
import { UserProfileService } from '../user/user-profile.service.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  SupportedRoles = environment.config.adRoles.split(',').map((r) => r.trim());
  // This is will come from the API in laster versions;
  permissionMatrix: Array<KeyValue<string, Permission[]>> = [
    {
      key: 'PRD_VCall2_HQSysAdmin',
      value: [
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessImpersonate,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
        Permission.MembershipAdmin,
      ],
    },
    {
      key: 'PRD_VCall2_ReadOnly',
      value: [
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
        Permission.MembershipAddOnRead,
      ],
    },
    {
      key: 'PRD_VCall2_HQSupervisor',
      value: [
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.CanAccessToITHelpCenter,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'PRD_VCall2_CCSupervisor',
      value: [
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'PRD_VCall2_HQCCVerifiers',
      value: [
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'PRD_VCall2_HQUnderwriter',
      value: [
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },

    // Vendors roles
    {
      key: 'PRD_VCall2_CCSupervisor_Vendors',
      value: [
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanCreateRecall,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessAppointments,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'PRD_VCall2_HQCCVerifiers_Vendors',
      value: [
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanCreateRecall,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessAppointments,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'PRD_VCall2_HQSupervisor_Vendors',
      value: [
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanCreateRecall,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessAppointments,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    // Vendors roles

    {
      key: 'TST_VCall2_HQSysAdmin',
      value: [
        Permission.CanAccessDeveloperModule,
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
        Permission.MembershipAdmin,
      ],
    },
    {
      key: 'TST_VCall2_ReadOnly',
      value: [
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessViewVcall,
        Permission.CanAccessViewRecall,
        Permission.CanAccessCallLog,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessAppointments,
        Permission.CanAccesRecallTab,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
        Permission.MembershipAddOnRead,
      ],
    },
    {
      key: 'TST_VCall2_HQSupervisor',
      value: [
        Permission.CanCreateRecall,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessViewVcall,
        Permission.CanAccessViewRecall,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessToITHelpCenter,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'TST_VCall2_CCSupervisor',
      value: [
        Permission.CanAccessDeveloperModule,
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'TST_VCall2_HQCCVerifiers',
      value: [
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewVcall,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessAppointments,
        Permission.CanAccesRecallTab,
        Permission.CanAccessViewVcallScreen,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'TST_VCall2_HQUnderwriter',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'DEV_VCall2_HQSysAdmin',
      value: [
        Permission.CanAccessDeveloperModule,
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessImpersonate,
        Permission.CanAccessViewRecall,
        Permission.CanAccessViewVcall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
        Permission.MembershipAdmin,
      ],
    },
    {
      key: 'DEV_VCall2_ReadOnly',
      value: [
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessViewVcall,
        Permission.CanAccessViewRecall,
        Permission.CanAccessCallLog,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessAppointments,
        Permission.CanAccesRecallTab,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
        Permission.MembershipAddOnRead,
      ],
    },
    {
      key: 'DEV_VCall2_HQSupervisor',
      value: [
        Permission.CanCreateRecall,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessViewVcall,
        Permission.CanAccessViewRecall,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessToITHelpCenter,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'DEV_VCall2_CCSupervisor',
      value: [
        Permission.CanCreateRecall,
        Permission.CanAccessAdmin,
        Permission.CanAccessAdminSearch,
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessApplicationSearchCosmosData,
        Permission.CanAccessApplicationSearchCallZoneData,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuQuickLinks,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccessAppointments,
        Permission.CanAccessViewVcallScreen,
        Permission.CanAccessUnderwriting,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'DEV_VCall2_HQCCVerifiers',
      value: [
        Permission.CanAccessStartVcallOrRecall,
        Permission.CanAccessCallLog,
        Permission.CanAccessNotes,
        Permission.CanAccessMenuStopCall,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewVcall,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAppointmentSearchCallZoneData,
        Permission.CanAccessAppointments,
        Permission.CanAccesRecallTab,
        Permission.CanAccessViewVcallScreen,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'DEV_VCall2_HQUnderwriter',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_Supervisor_TST',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_SysAdmin_TST',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
        Permission.MembershipAdmin,
      ],
    },
    {
      key: 'USHG_APS2_TeamMember_TST',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_FieldSupport_TST',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_MatchupMember_TST',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_Supervisor_PRD',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_SysAdmin_PRD',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_TeamMember_PRD',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_FieldSupport_PRD',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
    {
      key: 'USHG_APS2_MatchupMember_PRD',
      value: [
        Permission.CanAccesDiscrepencyTab,
        Permission.CanAccessViewStatusOfApplication,
        Permission.CanAccesRecallTab,
        Permission.CanAccessRecallCreateQuestions,
        Permission.CanAccessRecallUpdateQuestions,
        Permission.CanCreateRecall,
        Permission.CanAccessRecallDeleteQuestions,
        Permission.CanAccessRecallReadQuestionsAndAnswers,
        Permission.CanAccessUnderwriting,
        Permission.CanAccessViewRecall,
        Permission.CanAccessAPSRequestTab,
        Permission.CanAccessPDFScreen,
        Permission.CanAccessAddonTab,
        Permission.MembershipVCallRead,
        Permission.MembershipVCallReadWrite,
        Permission.MembershipRecallRead,
        Permission.MembershipRecallReadWrite,
        Permission.MembershipAddOnRead,
        Permission.MembershipAddOnReadWrite,
      ],
    },
  ];

  UserRoles: string[];
  serializedPermissions = null;

  constructor(private userProfileService: UserProfileService) {
    this.userProfileService.Roles.subscribe((roles) => {
      this.UserRoles = roles;
    });
  }

  /**
   * @param permission all the permissions with respected role
   * @description check the permission
   */
  IsPermitted(permission: Permission): Observable<boolean> {
    return new Observable<boolean>((s) => {
      this.userProfileService.Roles.subscribe((roles) => {
        if (roles && roles.length > 0) {
          const appliedRoles = roles.filter((r) =>
            this.SupportedRoles.includes(r)
          );
          const result = appliedRoles.some((role) =>
            this.permissionMatrix.some(
              (m) => m.key === role && m.value.includes(permission)
            )
          );
          s.next(result);
        }
      });
    });
  }

  /**
   * @description which role and whih permissions get access
   */
  PermissionSnapShot(): Observable<any[]> {
    return new Observable<any[]>((s) =>
      this.userProfileService.Roles.subscribe((roles) => {
        const userRoles = roles.filter((r) => this.SupportedRoles.includes(r));
        let permissions = [];
        userRoles.map(
          (r) =>
            (permissions = permissions.concat(
              this.permissionMatrix.find((i) => i.key === r)
            ))
        );
        console.log(permissions);
        s.next(permissions);
      })
    );
  }

  /**
   * @description which role and whih permissions get access
   */
  PermissionSnapShotSerialized(): string {
    if (this.serializedPermissions) {
      return this.serializedPermissions;
    }

    const userRoles = this.UserRoles.filter((r) =>
      this.SupportedRoles.includes(r)
    );
    let permissions = [];
    let result = [];
    userRoles.map(
      (r) =>
        (permissions = permissions.concat(
          this.permissionMatrix.find((i) => i.key === r)
        ))
    );

    // console.log('Total Roles Permissions ', permissions);
    // only keeping distinct values.
    permissions.map((p) =>
      p.value.map((v) => {
        if (!result.find((r) => r === v)) {
          result.push(v);
        }
      })
    );

    // console.log('Distinct Permissions ', result);
    this.serializedPermissions = result.join(',');
    // console.log('Serialized Permissions ', this.serializedPermissions);

    return this.serializedPermissions;
  }

  /**
   * @description which role is applied for the logged user
   */
  AllRoles(): Observable<any[]> {
    return new Observable<any[]>((s) =>
      this.userProfileService.Roles.subscribe((roles) => {
        const result = [];
        this.SupportedRoles.map((rs) =>
          result.push({ applied: roles.includes(rs), role: rs })
        );
        s.next(result);
      })
    );
  }
}
