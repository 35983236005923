<app-modal
  [open]="browserModal"
  [footer]="false"
  [buttonsConfig]="{
    showModalHeader: false
  }"
  [modalOptions]="{ size: 'lg' }"
>
  <div body class="browserModal-container">
    <div class="browserModal-content">
      <img
        src="./assets/images/ushg/info-button.svg"
        alt="Info Button"
        class="info-button"
      />
      <p>
        We're sorry, but it appears that you are using an unsupported web
        browser. Please switch to one of our supported browsers.
      </p>
      <div class="supported-browsers">
        <img src="./assets/images/ushg/chrome.svg" alt="Google Chrome" />
        <img src="./assets/images/ushg/microsoft.svg" alt="Microsoft Edge" />
      </div>
    </div>
  </div>
</app-modal>
