import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-initial-bank-view',
  templateUrl: './initial-bank-view.component.html',
  styleUrls: ['./initial-bank-view.component.scss'],
})
export class VcallInitialBankViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
