import { Component, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Application } from 'src/app/shared/models/application/application';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { Appointment } from 'src/app/shared/models/appointment';
import {
  CallLog,
  ApplicationNote,
  Log,
} from 'src/app/shared/models/vcall/applicationNote';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { DetailOptions } from 'src/app/shared/models/detail-options';
import { ToastService } from 'src/app/shared/services/toast/toast.service';

@Component({
  selector: 'app-notes-detail',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesDetailComponent implements OnDestroy {
  appointment: Appointment;
  application: Application;
  uniqueList = [];
  subscriptions: Array<Subscription> = new Array<Subscription>();
  notes: ApplicationNote[];
  noteForm: UntypedFormGroup;
  selected = 'All';
  systemGeneratedNotes = '[System Generated]';
  list: any;
  selectedList: any;
  callLogs: CallLog[];
  openModal = false;
  viewIcon = false;
  selectedIndex = -1;
  @Input() detailOptions: DetailOptions;
  constructor(
    private appointmentService: AppointmentService,
    private applicationService: ApplicationService,
    private userProfileService: UserProfileService,
    private uiService: UiServiceService,
    private toaster: ToastService
  ) {
    this.noteForm = new UntypedFormGroup({
      notes: new UntypedFormControl('', Validators.required),
    });

    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe(
        (a) => (this.appointment = a)
      )
    );

    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a) {
          if (this.application?.id !== a.id) {
            this.selected = 'All';
          }
          this.application = a;
        }
      })
    );

    this.applicationService.Notes.subscribe((notes) => {
      if (notes) {
        this.notes = notes;
        this.notes.map((note) => (note['pinIcon'] = true));
        this.concatNotesAndLogs(this.notes, this.callLogs);
      }
    });

    this.applicationService.CallLogs.subscribe((logs) => {
      if (logs) {
        this.callLogs = logs;
        this.concatNotesAndLogs(this.notes, this.callLogs);
      }
    });
  }

  concatNotesAndLogs(notes: Log[], callLogs: Log[]) {
    this.list =
      notes !== undefined && callLogs !== undefined
        ? [...callLogs.concat(notes)].sort((a, b) => {
            return +new Date(b.date) - +new Date(a.date);
          })
        : [];

    this.selectedList = this.list;
    if (this.selectedList && this.selectedList.length > 0) {
      this.selectedList.forEach((e: any) => {
        this.uniqueList.includes(e.name)
          ? this.uniqueList.includes('')
          : this.uniqueList.push(e.name);
      });
    }
    if (this.selected !== 'All') {
      this.selectedList = this.selectedList.filter((s) => {
        return s.name === this.selected;
      });
    }

    // for the old notes don't have utcDate and do't have pinnedIcon
    let oldNotesWithoutUtcDate = [];
    oldNotesWithoutUtcDate = this.selectedList.filter(
      (item) => !item.hasOwnProperty('pinned')
    );

    // sort the list of notes by using utcDate
    const exists = this.selectedList.filter((log) => {
      return log.hasOwnProperty('utcDate');
    }).length;

    // if all the notes have utcDate sorting the notes lists
    if (this.selectedList.length !== 0 && exists === this.selectedList.length) {
      oldNotesWithoutUtcDate = oldNotesWithoutUtcDate.sort((a, b) => {
        return new Date(b.utcDate).getTime() - new Date(a.utcDate).getTime();
      });
    }

    // sorting the pinned notes lists
    let pinnedNotesLists = [];
    pinnedNotesLists = this.selectedList.filter((item) =>
      item.hasOwnProperty('pinned')
    );
    pinnedNotesLists = pinnedNotesLists.sort((a, b) => {
      return (
        new Date(b.pinned.utcDate).getTime() -
        new Date(a.pinned.utcDate).getTime()
      );
    });

    // combined the pinned, un-pinned and old-notes(if we have)
    this.selectedList = [...pinnedNotesLists, ...oldNotesWithoutUtcDate];
  }

  onSelect(val) {
    this.selected = val;
    this.selectedList = [];
    if (this.selected === 'All') {
      this.selectedList = this.list;
    } else {
      this.selectedList = [];
      this.selectedList = this.list.filter((x: any) => x.name === val);
    }
  }

  pinMessage(pinNotes) {
    this.userProfileService.userProfile
      .pipe(first())
      .subscribe((userProfile) => {
        const pinned = {
          utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
          by: userProfile.name,
        };
        // assing the pinned info to selected  note
        pinNotes.pinned = pinned;
        this.applicationService.UpdateNote(pinNotes).then((data) => {
          this.toaster.success(null, 'Notes Pinned successfully');
        });
      });
  }

  unPinMessage(unPinNotes) {
    // removing the pinned Key from the selected note
    delete unPinNotes.pinned;
    this.applicationService.UpdateNote(unPinNotes).then((data) => {
      this.toaster.success(null, 'Notes UnPinned successfully');
    });
  }

  // TODO: Simplify Date Logic to a utility component
  submitNote(action: any) {
    if (action === 'Done') {
      this.userProfileService.userProfile
        .pipe(first())
        .subscribe((userProfile) => {
          if (this.noteForm.valid) {
            const note = {
              notes: this.noteForm.get('notes').value,
              name: userProfile.name,
              date: this.uiService.formatCurrentDate(),
              utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
              id: this.application.id,
              state: this.application.state,
              reason: '',
            };
            this.applicationService.SaveNote(note).then((data) => {
              this.noteForm.reset();
              this.openModal = false;
            });
          }
        });
    } else {
      this.noteForm.reset();
      this.openModal = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}

export interface PinnedMessage {
  utcDate: Date | string;
  by: string;
}
