import { Pipe, PipeTransform } from '@angular/core';
import { FamilyRelation } from '../models/application/application';

@Pipe({
  name: 'relation',
})
export class RelationshipPipe implements PipeTransform {
  relationArray: FamilyRelation[] = [
    {
      shortname: 'S',
      relation: 'Spouse',
    },
    {
      shortname: 'P',
      relation: 'Primary',
    },
  ];

  transform(value: string): any {
    const index = this.relationArray.findIndex(
      (x: any) => x.shortname === value
    );
    return index > -1 && this.relationArray[index].relation
      ? this.relationArray[index].relation
      : 'Other';
  }
}
