<form form="NgForm">
  <div class="row">
    <div class="col-6">
      <span [innerHTML]="prompt"></span>
    </div>
    <div class="col-2">
      <select
        class="form-control"
        name="question.answerTag"
        (change)="optionChanged(selectedValue)"
        [(ngModel)]="selectedValue"
      >
        <option *ngFor="let opt of options" [value]="opt">{{ opt }}</option>
      </select>
    </div>
  </div>
</form>
