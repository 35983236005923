import { Component, OnInit, Input } from '@angular/core';
import { BaseQuestionType } from '../types/baseQuestionType';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { AilmentDashboardModel } from 'src/app/shared/models/application/ailmentDashboard';
import { MedicalItem } from 'src/app/shared/models/medicalItem';
import { Answer } from 'src/app/shared/models/application/answer';
import { stringify } from 'querystring';
import { DrilldownStatus } from 'src/app/shared/models/vcall/detailQuestion';

@Component({
  selector: 'app-medical-ailment-dashboard',
  templateUrl: './medical-ailment-dashboard.component.html',
  styleUrls: ['./medical-ailment-dashboard.component.scss'],
})
export class MedicalAilmentDashboardComponent extends BaseQuestionType<
  VcallQuestionTypeAilment
> {
  ailmentDashboardModel: AilmentDashboardModel = new AilmentDashboardModel();
  details: VcallQuestionTypeAilment = new VcallQuestionTypeAilment();
  medicalAnswers: Answer[];
  familyAilmentsList = new Array<any>();
  openModal = false;
  AilmentReceived = null;
  answer: Answer = null;
  isAllDone = false;
  @Input() configType: string;
  @Input() dashboardType = 'default';
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService
  ) {
    super(script, appService, ui);
  }
  ResetView() {}

  ApplicationChanged(): void {}

  QuestionChanged(q: Question): void {
    // this is to ensure that lingering subscription don't cause any issue
    if (q.type !== this.configType) {
      return;
    }
    const answerTag = this.question.answerTag.split('.')[0];
    if (this.dashboardType === 'drug') {
      this.appService.GetDashboardAnswersForDrugs();
    } else {
      this.appService.GetDashboardAnswers(answerTag);
    }

    this.load();
  }

  load() {
    this.isAllDone = false;
    this.familyAilmentsList = [];
    if (this.medicalAnswers) {
      let ailmentsModelList;
      if (this.dashboardType === 'drug') {
        ailmentsModelList = this.ailmentDashboardModel.getDrugPrescriptionDashboardModel(
          this.medicalAnswers
        );
      } else {
        ailmentsModelList = this.ailmentDashboardModel.getDashboardModel(
          this.medicalAnswers,
          'Pending',
          this.questions
        );
      }

      const primaryAilmentsList = ailmentsModelList
        .map((a) => {
          return a.filter((k) => {
            return (
              k && k.clientNo === this.application.primaryApplicant.clientNo
            );
          });
        })
        .filter((fl) => {
          return fl.length > 0;
        });
      if (primaryAilmentsList.length > 0) {
        this.familyAilmentsList.push({
          clientNo: this.application.primaryApplicant.clientNo,
          applicantName:
            this.application.primaryApplicant.firstName +
            ' ' +
            this.application.primaryApplicant.lastName,
          [this.application.primaryApplicant.clientNo]: primaryAilmentsList,
          applicantType: 'Primary',
        });
      }

      this.application.family.forEach((f) => {
        const list = ailmentsModelList
          .map((a) => {
            return a.filter((k) => {
              return k && k.clientNo === f.clientNo;
            });
          })
          .filter((final) => {
            return final.length > 0;
          });
        if (list.length > 0) {
          this.familyAilmentsList.push({
            clientNo: f.clientNo,
            applicantName: f.firstName + ' ' + f.lastName,
            [f.clientNo]: list,
            applicantType: f.relationship === 'S' ? 'Spouse' : 'Dependent',
          });
        }
      });

      console.log(this.familyAilmentsList);
      this.isAllDone = this.isAllCompleted(this.familyAilmentsList);
      if (this.answer) {
        if (this.answer.value !== undefined) {
          if (this.answer.value !== this.isAllDone) {
            this.saveAnswer(true, this.isAllDone);
          }
        }
      } else {
        this.saveAnswer(true, this.isAllDone);
      }
    }
  }

  isAllCompleted(familyAilmentsList) {
    return familyAilmentsList.every((e) => {
      const clientNo = e.clientNo;
      const qualifyingsArray = e[clientNo];
      const inner = qualifyingsArray.every((element) => {
        const find = element.find(
          (s) => s.status !== DrilldownStatus.Completed
        ); // 'Completed'
        console.log(find);
        return find === undefined ? true : false;
      });
      return inner === false ? false : true;
    });
  }

  CanGoNext(): boolean {
    return this.isAllDone;
  }

  goToDetails(dashboardItem, applicantName) {
    if (this.dashboardType === 'drug') {
      this.AilmentReceived = { ...dashboardItem, applicantName };
      this.appService.GetDetailQuestions(
        'MedicalHistory.PrescriptionDrugs',
        'MedicalHistory PrescriptionDrug DrillDown'
      );
      console.log(dashboardItem);
    } else {
      if (dashboardItem !== null) {
        this.AilmentReceived = { ...dashboardItem, applicantName };
        this.appService.GetDetailQuestions(
          dashboardItem.answerTag,
          dashboardItem.ailment
        );
        console.log(dashboardItem);
      }
    }

    this.openModal = true;
  }

  ModalAction(action: any) {
    this.AilmentReceived = null;
    console.log('performed action', action);
    if (action === 'Done') {
      // future code if any based on condition
      this.openModal = false;
    } else {
      // future code if any based on condition
      this.openModal = false;
    }
    this.appService.CleanDetailQuestions();
    this.load();
  }

  DynamicActions(action: any) {
    this.AilmentReceived = null;
    this.openModal = false;
    this.load();
  }

  progress(state, prop) {
    let style;
    switch (state) {
      case DrilldownStatus.Pending:
        style = { badge: 'badge-danger', btn: 'btn-primary', btnText: 'Start' };
        break;
      case DrilldownStatus.Inprogress:
        style = {
          badge: 'badge-warning',
          btn: 'btn-primary',
          btnText: 'Continue',
        };
        break;
      case DrilldownStatus.Completed:
        style = {
          badge: 'badge-success',
          btn: 'btn-outline-dark',
          btnText: 'View Responses',
        };
        break;
      default:
        style = { badge: 'badge-danger', btn: 'btn-primary', btnText: 'Start' };
    }
    return style[prop];
  }
}
export class VcallQuestionTypeAilment {
  required: boolean;
}
