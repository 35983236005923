<div class="mt-3 card p-4 rounded">
  <div class="row m-0 justify-content-end">
    <button
      type="button"
      class="btn btn-outline-primary btn-sm ml-2 px-4"
      (click)="addprovider()"
    >
      Add Provider
    </button>
    <button
      type="button"
      class="btn btn-primary btn-sm ml-2 px-4"
      (click)="createRequest()"
    >
      Create APS/Lab Request
    </button>
  </div>
  <div class="box-shadow mt-3" *ngIf="orderRequestList.length">
    <div class="row m-0 justify-content-between p-4">
      <div>
        Assigned User:
        <span class="f-w-600">{{
          applicationTracker.assignedTo || "N/A"
        }}</span>
      </div>
      <div>
        Assigned Date:
        <span class="f-w-600">{{
          (applicationTracker.assignedOn | date : "MM/dd/yyyy") || "N/A"
        }}</span>
      </div>
      <div>
        Returned Date:
        <span class="f-w-600">{{
          (applicationRequests.aPSReturnedDate | date : "MM/dd/yyyy") || "N/A"
        }}</span>
      </div>
      <div>
        Status:
        <span class="f-w-600">{{
          ApplicationStatus[applicationTracker.applicationStatusId]
        }}</span>
      </div>
    </div>
  </div>
  <div
    class="bg-success p-2 mt-3 text-center"
    *ngIf="!orderRequestList.length && !completedOrdersList.length"
  >
    No Additional information has been requested for this application.
  </div>
  <div class="bg-success p-2 mt-3 text-center" *ngIf="!canCreateOrder">
    Application is not assigned to you, so you are not allowed to make any
    changes.
  </div>
  <div class="mt-2" *ngIf="applicationTracker">
    <app-order-details
      [applicationTrackerId]="applicationTracker.id"
    ></app-order-details>
  </div>
</div>

<!-- create order popup starts from here -->
<app-create-order
  [openCreateOrderModal$]="openCreateOrderModal"
  [applicants]="applicantsList"
  [applicationTrackerId]="applicationTracker?.id"
></app-create-order>
<!-- create order ends here -->

<app-modal
  [open]="canDisplayProvider"
  [modalOptions]="{ size: 'xl' }"
  [buttonsConfig]="{
    modalMode: 'form',
    position: 'center',
    submitButtonName: 'Save',
    cancelButtonName: 'Cancel'
  }"
  [formValidation]="updatedDoctorsList.length"
  (actions)="confirm($event)"
>
  <div title class="text-center">Add provider</div>
  <div body>
    <nav ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="activeTab">
      <li ngbNavItem="search">
        <a ngbNavLink (click)="getCurrentTab('search')">Search Form</a>
        <ng-template ngbNavContent>
          <app-vcall-doc-lookup
            [initialDoctorList]="initialList"
            [questionDetails]="details"
            [showProviderConfirmation]="false"
            [resetRequest$]="resetProviderRequest"
            (notifyProviderLookup)="getUpdatedDrList($event)"
          >
          </app-vcall-doc-lookup>
        </ng-template>
      </li>
      <li ngbNavItem="basic">
        <a ngbNavLink (click)="getCurrentTab('basic')">Basic Form</a>
        <ng-template ngbNavContent>
          <app-basic-doctor-form
            [resetForm$]="resetBasicForm"
          ></app-basic-doctor-form>
        </ng-template>
      </li>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</app-modal>
