<ul>
  <li *ngFor="let api of responses">
    <h4 (click)="showResponse(api)" class="api-name">{{ api.nameOfTheApi }}</h4>
    <pre class="max-height">
      {{ api.response | json }}
    </pre>
  </li>
</ul>
<ul>
  <h1 class="text-danger border-bottom border-top">
    Errors
  </h1>
  <li *ngFor="let api of errors">
    <h3>{{ api.nameOfTheApi }}</h3>
    <pre class="max-height">
      {{ api.response | json }}
    </pre>
  </li>
</ul>
