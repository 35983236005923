import { Injectable, OnInit } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from 'src/app/shared/services/permission/permission.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { Permission } from 'src/app/shared/models/vcall/permission';
/**
 * Authorization Guard
 */
@Injectable({
  providedIn: 'root',
})
export class TabAccessGuard {

  constructor(
    private uiService: UiServiceService,
    private permissionService: PermissionService
  ) {
  }

  /**
 * Checks if user is authorized & returns boolean
 * @param next Activated route snapshot
 * @param state Router state snapshot
 */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const tabName = next.queryParams && next.queryParams.tabName;
    let requiredPermission;

    switch (tabName) {
      case 'APSRequests':
        requiredPermission = Permission.CanAccessAPSRequestTab;
        break;
      case 'addOns':
        requiredPermission = Permission.CanAccessAddonTab;
        break;
      default:
        requiredPermission = Permission.CanAccessUnderwriting;
        break;
    }

    return new Observable<boolean>((o) => {
      this.permissionService
        .IsPermitted(requiredPermission)
        .subscribe((authorized) => {
          console.log(
            'Permission Guard Authorized = ',
            authorized,
            ' for ',
            requiredPermission
          );
          if (authorized) {
            o.next(true);
          } else {
            this.uiService.PermissionDenied(requiredPermission);
            o.next(false);
          }
        });
    });
  }

}
