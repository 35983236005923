import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reschedule-appointment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrls: ['./reschedule-appointment.component.scss'],
})
export class RescheduleAppointmentComponent implements OnInit {
  rescheduleAppointmentUrl: string;
  rescheduleBaseUrl: string;
  selfServiceUrl: string;
  useSelfService: boolean;
  @Input() public application;
  @Input() public selectedAppointment;
  @Input() public timeZone;
  constructor(
    private service: AppointmentService,
    public activeModal: NgbActiveModal,
    private applicationService: ApplicationService,
    private appointmentService: AppointmentService,
    private scriptService: ScriptService,
    private userProfileService: UserProfileService,
    private router: Router,
    private msalService: MsalService,
  ) {
    this.rescheduleBaseUrl = environment.rescheduleAppointmentUrl;
    this.selfServiceUrl = environment.selfServiceUrl;
    this.useSelfService = environment.features.useSelfService;
  }

  ngOnInit() {

    if(this.useSelfService) {
      let userProfile = this.userProfileService.getUserProfile();
      let callZoneProfile = this.userProfileService.getCallzoneUser();
      this.rescheduleAppointmentUrl = this.createRescheduleAppointmentUrl(
        this.application.id,
        userProfile.userName,
        callZoneProfile.resourceId,
        this.selectedAppointment.appointmentId,
        this.timeZone
      );
    }
    else {
      this.getApplicationHashCode(
        this.application.id,
        this.selectedAppointment.appointmentId
      );
    }
  }

  getApplicationHashCode(appId, appointmentId) {
    this.service
        .applicationHashCode(appId)
        .then((hashCode) => {
          if (hashCode) {
            this.rescheduleAppointmentUrl = `${this.rescheduleBaseUrl}?AppId=${appId}&AppointmentId=${appointmentId}&HashCode=${hashCode}&FromAgent=false&Reschedule=true&CreateNewAppointment=true`;
          }
        });
  }

  createRescheduleAppointmentUrl(appId, userName, resourceId, appointmentId, timeZone) {
    const account = this.msalService.instance.getAllAccounts()[0];
    let key = `${account.homeAccountId}-login.windows.net-accesstoken-${environment.authConfig.clientId}-${environment.authConfig.tenant}-${environment.authConfig.addedProtectedResourceMap[0].list[0]}--`;
    let value:any = JSON.parse(localStorage.getItem(key));
    let token = value.secret;
    /*
      Reschedule Query Parameters:
        appId,
        appointmentId,
        mode,
        userName,
        resourceId,
        userType,
        timeZoneName,
        token
    */
    return `${this.selfServiceUrl}/reschedule?appId=${appId}&appointmentId=${appointmentId}&mode=iframe&userName=${userName}&resourceId=${resourceId}&userType=verifier&timeZoneName=${timeZone}&token=${token}`;
  }

  closeModal() {
    this.activeModal.dismiss();
    setTimeout(() => {
      this.router.navigate(['/'], {
        queryParams: { refresh: new Date().getTime() },
        queryParamsHandling: 'merge',
      });
      this.applicationService.Clear();
      this.appointmentService.Clear();
      this.scriptService.Clear();
    }, 100);
  }
}
