import 'reflect-metadata';
import { Application } from '../../models/application/application';
import { Answer } from '../../models/application/answer';
import { DiscoverableChainItem } from '../../models/application/discoverableChainItem';
import { Discoverable } from '../../models/application/discoverable';
import { DecoratedModelConfiguration } from '../../models/application/decoratedModelConfiguration';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModelMapperService {
  public Map(application: Application, answer: Answer) {
    if (answer && answer.answerTag) {
      this.find(
        application,
        answer.answerTag.split('.'),
        answer.value,
        answer.clientNo
      );
    }
  }

  /**
   * @param application application data
   * @param answerTag answer tag
   * @param clientNo client number
   * @description Used to find the value of answer tag
   */
  public FindValueForAnswerTag(
    application: Application,
    answerTag: string,
    clientNo: string
  ) {
    if (clientNo) {
      return this.find(application, answerTag.split('.'), undefined, clientNo);
    } else {
      return this.find(application, answerTag.split('.'), undefined);
    }
  }

  public GetAll(target: any, key: string) {
    const list = Reflect.getMetadataKeys(target).map((k) =>
      Reflect.getMetadata(k, target)
    );
    return list.filter(
      (i) => i[DecoratedModelConfiguration.MetaKeyName] === key
    )[0];
  }

  public GetKeyName(target: any, key: string) {
    const list = Reflect.getMetadataKeys(target).map((k) =>
      Reflect.getMetadata(k, target)
    );
    // console.log('GetKeyName');
    // console.log(list);
    const result = list.filter(
      (i) => i[DecoratedModelConfiguration.MetaKeyName] === key
    )[0][DecoratedModelConfiguration.KeyName];
    // console.log(result);
    return result;
  }

  public GetObjectPropertyNameForAnswerTag(target: any, tag: string) {
    const list = Reflect.getMetadataKeys(target).map((k) => {
      // console.log(k);
      return Reflect.getMetadata(k, target);
    });

    // console.log("GetObjectPropertyNameForAnswerTag");
    // console.log(target);
    // console.log(tag);
    // console.log(list);

    const flist = list.filter(
      (i) => i !== undefined && i[DecoratedModelConfiguration.AnswerTag] === tag
    );

    if (flist && flist.length > 0) {
      // console.log(flist[0][DecoratedModelConfiguration.MetaKeyName]);
      return flist[0][DecoratedModelConfiguration.MetaKeyName];
    } else {
      // console.log('Either its a property on the object not tagged or a bug');
      return null;
    }
  }

  public GetAnswerTagForObjectPropertyName(target: any, propertyName: string) {
    // console.log(target);
    // console.log(propertyName);
    // console.log(Reflect.getMetadataKeys(target));
    const list = Reflect.getMetadataKeys(target).map((k) =>
      Reflect.getMetadata(k, target)
    );
    // console.log(list);
    // const flist = list.filter((i) => {
    // 	i[DecoratedModelConfiguration.MetaKeyName].toString() === propertyName;
    // });

    for (const element of list) {
      if (
        element[DecoratedModelConfiguration.MetaKeyName].toString() ===
        propertyName
      ) {
        return element[DecoratedModelConfiguration.AnswerTag];
      }
    }

    // console.log('Either its a property on the object not tagged or a bug');
    return null;
  }

  public GetDecoratorValueForObjectPropertyName(
    target: any,
    propertyName: string,
    decorator: string
  ) {
    const list = Reflect.getMetadataKeys(target).map((k) =>
      Reflect.getMetadata(k, target)
    );

    for (const element of list) {
      if (
        element[DecoratedModelConfiguration.MetaKeyName].toString() ===
        propertyName
      ) {
        return element[decorator];
      }
    }

    // console.log('Either its a property on the object not tagged or a bug');
    return null;

    // Reflect.getOwnMetadata(DecoratedModelConfiguration.Label,target,)
  }

  private find(
    target: any,
    answerTagParts: string[],
    value?: any,
    clientNo?: string
  ) {
    const chain = answerTagParts;

    if (chain.length > 0) {
      const tag = chain[0]; // this is the tag on the property
      const propertyName = this.GetObjectPropertyNameForAnswerTag(target, tag);

      if (propertyName === null) {
        return;
      }

      if (chain.length === 1) {
        if (value !== undefined) {
          // leaf
          target[propertyName] = value;
        }
        // use case when only used to request the value
        return target[propertyName];
      }

      // Array checking
      if (Array.isArray(target[propertyName])) {
        // if array
        // console.log('is array');
        const keyPropertyName = this.GetKeyName(target, propertyName);
        target = target[propertyName].filter(
          (i) => i[keyPropertyName] === clientNo
        )[0];
        // console.log(target);
      } else {
        target = target[propertyName];
      }

      chain.shift();
      return this.find(target, chain, value, clientNo);
    }
  }
}

export const PropertyDecorator = (
  property,
  propertyValue
): PropertyDecorator => {
  return (target, propertyName) => {
    // console.log('property target: ', target);
    const metadata = Reflect.getMetadata(propertyName, target) || {};
    metadata[property] = propertyValue;
    metadata['name'] = propertyName;
    Reflect.defineMetadata(propertyName, metadata, target);
  };
};
