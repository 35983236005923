<div>
  <div [innerHtml]="GetText()"></div>
  <app-verifiable-list
    [model]="verifiableList"
    *ngIf="GetModel()"
    (formComplete)="verificationComplete($event)"
    [isCustomEdit]="false"
  >
  </app-verifiable-list>
</div>
