<div class="card mx-5 pt-3 pb-4 my-3">
  <app-vcall-sections></app-vcall-sections>
  <div
    class="row"
    *ngIf="question?.type !== 'Recall'; else recallApplicantHeader"
  >
    <p
      *ngIf="question"
      class="mx-5 pt-3"
      [innerHTML]="questionTextO$ | async"
    ></p>
  </div>
  <ng-template #recallApplicantHeader>
    <div class="alert alert-secondary ml-4 mr-4">
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-6 font-weight-bold text-right">Applicant:</div>
            <div class="col-6">{{ question.details.applicantName }}</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-4 font-weight-bold text-right">Reason:</div>
            <div class="col-6">{{ question.details.recallReason }}</div>
          </div>
        </div>
      </div>
    </div>
    <p
      *ngIf="question"
      class="ml-4 mr-4 pl-1"
      [innerHTML]="questionTextO$ | async"
    ></p>
  </ng-template>
  <div class="row pb-3">
    <div class="col px-5">
      <ng-container *ngIf="question" [ngSwitch]="question.type">
        <app-third-party-payor-verification
          #questionType
          *ngSwitchCase="'ThirdPartyPayorVerification'"
        >
        </app-third-party-payor-verification>
        <app-qualifying-question-generic
          [configType]="'QualifyingStudent'"
          *ngSwitchCase="'QualifyingStudent'"
          #questionType
        >
        </app-qualifying-question-generic>
        <app-applicant-recall-question
          *ngSwitchCase="'ApplicantRecall'"
          #questionType
        >
        </app-applicant-recall-question>
        <app-question-boolean
          *ngSwitchCase="'Boolean'"
          #questionType
          (selected)="selected($event)"
        ></app-question-boolean>
        <app-question-textarea
          *ngSwitchCase="'TextArea'"
          #questionType
        ></app-question-textarea>
        <app-question-recall
          *ngSwitchCase="'Recall'"
          #questionType
        ></app-question-recall>
        <app-medical-qualifying
          #questionType
          *ngSwitchCase="'MedicalQualifying'"
          [configType]="'MedicalQualifying'"
        >
        </app-medical-qualifying>
        <app-question-product
          *ngSwitchCase="'Product'"
          #questionType
          (selectedAnswer)="selected($event)"
        ></app-question-product>
        <app-question-monthly-payment-mode
          *ngSwitchCase="'MonthlyPaymentMode'"
          #questionType
        >
        </app-question-monthly-payment-mode>
        <!-- TODO: Refactor app-payor-information to generic -->
        <app-common-bank-view
          *ngSwitchCase="'MonthlyBankInformation'"
          #questionType
        ></app-common-bank-view>
        <app-question-monthly-effective-date
          *ngSwitchCase="'MonthlyEffectiveDate'"
          #questionType
        >
        </app-question-monthly-effective-date>
        <app-question-initial-payment-mode
          *ngSwitchCase="'InitialPaymentMode'"
          #questionType
        >
        </app-question-initial-payment-mode>
        <app-common-bank-view
          *ngSwitchCase="'InitialBankInformation'"
          #questionType
        ></app-common-bank-view>
        <app-demographics
          *ngSwitchCase="'DemographicAddress'"
          #questionType
        ></app-demographics>
        <app-demographic-applicants
          *ngSwitchCase="'DemographicApplicants'"
          #questionType
        ></app-demographic-applicants>
        <!-- <app-closing *ngSwitchCase="'Closing'"></app-closing> -->
        <app-radiobutton-list
          *ngSwitchCase="'RadioButtonList'"
          #questionType
        ></app-radiobutton-list>
        <app-payor-information
          #questionType
          *ngSwitchCase="'PayorInformation'"
        ></app-payor-information>
        <app-question-dropdown #questionType *ngSwitchCase="'Dropdown'">
        </app-question-dropdown>
        <app-medical-ailment
          #questionType
          [configType]="'MedicalAilment'"
          *ngSwitchCase="'MedicalAilment'"
        >
        </app-medical-ailment>
        <app-medical-ailment-dashboard
          *ngSwitchCase="'AilmentDashboard'"
          [configType]="'AilmentDashboard'"
          [dashboardType]="'default'"
        >
        </app-medical-ailment-dashboard>

        <app-medical-ailment-dashboard
          *ngSwitchCase="'PrescriptionDrugDashboard'"
          [configType]="'PrescriptionDrugDashboard'"
          [dashboardType]="'drug'"
        >
        </app-medical-ailment-dashboard>
        <app-applicant-qualified-textbox
          #questionType
          *ngSwitchCase="'ApplicantQualifiedTextBox'"
          [configType]="'ApplicantQualifiedTextBox'"
        >
        </app-applicant-qualified-textbox>
        <app-applicant-qualified-boolean-textbox
          #questionType
          *ngSwitchCase="'ApplicantQualifiedBoolean'"
          [configType]="'ApplicantQualifiedBoolean'"
        >
        </app-applicant-qualified-boolean-textbox>
        <app-applicant-qualified-input-text
          #questionType
          *ngSwitchCase="'ApplicantQualifiedInputText'"
          [configType]="'ApplicantQualifiedInputText'"
        >
        </app-applicant-qualified-input-text>
        <app-applicant-qualified-occupation
          #questionType
          *ngSwitchCase="'ApplicantQualifiedOccupation'"
          [configType]="'ApplicantQualifiedOccupation'"
        >
        </app-applicant-qualified-occupation>
        <app-applicant-qualified-doctor-lookup
          #questionType
          *ngSwitchCase="'ApplicantQualifiedDoctor'"
          [configType]="'ApplicantQualifiedDoctor'"
        >
        </app-applicant-qualified-doctor-lookup>
        <app-applicant-qualified-medication-lookup
          #questionType
          *ngSwitchCase="'ApplicantQualifiedMedication'"
          [configType]="'ApplicantQualifiedMedication'"
        >
        </app-applicant-qualified-medication-lookup>
      </ng-container>
      <app-ezapp-answers-display [question]="service.SelectedQuestion">
      </app-ezapp-answers-display>
      <div class="alert alert-secondary" role="alert" *ngIf="notesText && question?.details?.showNotes">
        <h6 class="alert-heading">Notes</h6>
        {{ notesText }}
      </div>
    </div>
  </div>
</div>
<div *ngIf="question" class="px-5">
  <div class="ml-auto flex-row">
    <button
      class="btn float-left border bg-white carousel-next btn-check-next p-0 m-0"
      data-html="true"
      data-toggle="popover"
      data-trigger="hover"
      title="<i class='fas fa-keyboard keyboard-btn' aria-hidden='true'></i> Hot Keys"
      data-content="
        <p> Use <span class='btn btn-outline-secondary border btn-sm'>
          <b>Y</b>
        </span>
          <span class='btn btn-outline-secondary border btn-sm'><b>N</b></span> Keys for <b>Yes/No</b> responses
        </p>
        <p> Use <span class='btn btn-outline-secondary border btn-sm'>
          <i class='fa fa-arrow-left' aria-hidden='true'></i>
        </span>
        <span class='btn btn-outline-secondary border btn-sm'>
          <i class='fa fa-arrow-right' aria-hidden='true'></i>
        </span> Keys for navigating <b>Back/Next</b>
      </p>
      <p><b>Note :</b> Click outside the text field to use the keyboard shortcuts</p>
      "
    >
      <i
        class="fa fa-keyboard keyboard-btn"
        [ngClass]="{ red: ifInputFocus }"
        aria-hidden="true"
      ></i>
    </button>
    <br />
    <ng-container *ngIf="ifInputFocus">
      <div class="hot-key-note red">
        <b>Note</b>: Click outside the text field to use the keyboard shortcuts.
      </div>
    </ng-container>
    <app-question-notes></app-question-notes>
  </div>
  <app-smart-navigation
    *ngIf="environment.features.developerNavigation"
  ></app-smart-navigation>
  <div *ngIf="!hideEndCall" class="d-flex flex-row justify-content-end">
    <button
      [ngClass]="{
        'btn btn-secondary btn-lg carousel-next carousel-next-arrow mr-2':
          question.isFirst === false
      }"
      (click)="goBack()"
    >
      <i class="fa fa-caret-left" aria-hidden="true"></i> Back
    </button>
    <app-endcall
      [hidden]="hideEndCall"
      [disableBtn]="canGoNextFlag"
    ></app-endcall>
  </div>
  <div
    *ngIf="
      hideEndCall === true ||
      (question.type === 'Closing' && model.appointment.callType === 'Recall')
    "
    class="ml-auto"
  >
    <button
      class="btn btn-primary btn-lg carousel-next carousel-next-arrow float-right ml-1"
      [disabled]="!canGoNextFlag"
      [hidden]="!hideEndCall"
      (click)="goNext()"
    >
      Next <i class="fa fa-caret-right" aria-hidden="true"></i>
    </button>
    <button
      [hidden]="!hideEndCall"
      [ngClass]="{
        'btn btn-secondary btn-lg carousel-next float-right mr-1':
          question.isFirst === false,
        'no-button':
          question.isFirst === true ||
          (question.isFirst === undefined &&
            question.type !== 'Closing' &&
            model.appointment.callType !== 'Recall')
      }"
      (click)="goBack()"
    >
      <i class="fa fa-caret-left" aria-hidden="true"></i> Back
    </button>
  </div>
  <app-end-call-modal></app-end-call-modal>
</div>
<app-modal
    [open]="openModal"
    [buttonsConfig]="{
      modalMode: 'form',
      submitButtonName: 'Confirm',
      cancelButtonName: 'Cancel'
    }"
    (actions)="confirm($event); cancel($event)"
  >
    <div title>Confirmation</div>
    <div body>
      <div class="pl-2">Please confirm the removal of information.</div>
    </div>
</app-modal>
