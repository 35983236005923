<div class="">
  <app-prescription-lookup
    [initialMedicationList$]="initialMedicationList"
    (notifyMedicationList)="getMedicationList($event)"
  >
  </app-prescription-lookup>
  <div
    *ngIf="question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>
</div>
