<ngx-spinner
  bdOpacity="0.9"
  bdColor="transparent"
  size="default"
  color="#000000"
  type="ball-spin-clockwise"
  [fullScreen]="true"
>
</ngx-spinner>
<div body>
  <div class="row m-0">
    <div class="col-sm-2 section p-0 pt-3">
      <p class="border-bottom text-white text-center pb-2">SECTIONS</p>
      <nav
        ngbNav
        #nav="ngbNav"
        class="nav flex-column nav-pills sticky-top"
        [(activeId)]="activeSection"
      >
        <ng-container *ngFor="let section of sections; let i = index">
          <a
            ngbNavLink
            [fragment]="section.name"
            [routerLink]="'.'"
            (click)="navigateToSection(section.name, i)"
            class="text-white text-uppercase"
            [ngClass]="{ active: activeSection === i }"
            [ngbNavItem]="i"
            >{{ section.name }}
          </a>
        </ng-container>
      </nav>
    </div>
    <div class="col-sm-10 pt-4 scroll-popup bg-white">
      <h4 class="text-center">New Business Verification Call</h4>

      <div
        class="row border border-secondary rounded d-flex justify-content-around m-1 mb-3 pt-1"
        *ngIf="application"
      >
        <div class="row">
          <div class="col-sm-5">Verified With:</div>
          <div class="col-sm-6 p-0">
            <div>
              <b> {{ application.primaryApplicant | fullname }}</b>
            </div>
            <div>
              <b>{{ application.contact.streetAddress }}</b>
            </div>
            <div>
              <b
                >{{ application.contact.city }},
                {{ application.contact.state }}</b
              >
            </div>
            <div>
              <b> {{ application.contact.zipCode }}</b>
            </div>
            <div>
              <b>{{ application.contact.homePhone }}</b>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-sm-4">Verification Details:</div>
          <div class="col-sm-7 p-0">
            <div *ngIf="agent" class="row">
              <span class="col-sm-6 p-0"><b>Verifier</b></span>
              <span class="col-sm-6 p-0"
                ><b>: {{ agent.name || "N/A" }}</b></span
              >
            </div>

            <div class="row">
              <span class="col-sm-6 p-0"><b>AppID</b></span>
              <span *ngIf="completedCallList" class="col-sm-6 p-0">
                <b>: {{ completedCallList.applicationId }}</b>
              </span>
            </div>
            <div class="row">
              <span class="col-sm-6 p-0"><b>AppType ID</b></span>
              <span *ngIf="completedCallList" class="col-sm-6 p-0">
                <b>: {{ completedCallList.appType }}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Appointments information section start -->
      <div class="row border border-secondary rounded m-1 mb-3 scroll">
        <table class="table table-hover table-striped tbl-log text-center">
          <thead>
            <tr class="table-dark table_header_fixed">
              <th scope="col">Scheduled Date</th>
              <th scope="col">Completed Date</th>
              <th scope="col">Verifier</th>
              <th scope="col">Call Type</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of appointments">
              <td>{{ item?.scheduledDate | date : "MM/dd/yyyy" || "N/A" }}</td>
              <td>{{ item?.completedDate | date : "MM/dd/yyyy" || "N/A" }}</td>
              <td>{{ item?.callzoneVerifier || "N/A" }}</td>
              <td>{{ item?.callType || "N/A" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Appointments information section end -->
      <div *ngFor="let i of item">
        <nav
          class="navbar navbar-light bg-secondary rounded align-center m-1 mb-2 mt-1 justify-content-center"
          id="{{ i.section.name }}"
        >
          <span class="navbar-brand mb-0 h1 text-white"
            >{{ i.section.name }} Section</span
          >
        </nav>

        <ng-container *ngIf="i.section.name === 'Payment'">
          Recurring Payment Detail:
          <table class="table table-striped table-sm table-bordered mt-4">
            <tbody>
              <tr>
                <th scope="row" style="width: 16.66%">Bank Name</th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.bankName }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getVerification('payment.bankName');
                    then verified;
                    else notVerified
                  "
                ></td>
                <ng-template #verified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #notVerified>
                  <td style="width: 16.66%"><b>Not Verified</b></td>
                </ng-template>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Routing #</th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.bankRoutingCode }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getVerification('payment.bankRoutingCode');
                    then verified;
                    else notVerified
                  "
                ></td>
                <ng-template #verified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #notVerified>
                  <td style="width: 16.66%"><b>Not Verified</b></td>
                </ng-template>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Account #</th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.bdAccountNumber }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getVerification('payment.bdAccountNumber');
                    then verified;
                    else notVerified
                  "
                ></td>
                <ng-template #verified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #notVerified>
                  <td style="width: 16.66%"><b>Not Verified</b></td>
                </ng-template>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">
                  Requested Effective Date
                </th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.requestedEffectiveDate }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getVerification('payment.requestedEffectiveDate');
                    then verified;
                    else notVerified
                  "
                ></td>
                <ng-template #verified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #notVerified>
                  <td style="width: 16.66%"><b>Not Verified</b></td>
                </ng-template>
              </tr>
              <tr
                *ngIf="
                  application.payment.nextOrSpecificDate &&
                  application.payment.requestedEffectiveDate === 'SpecificDate'
                "
              >
                <th style="width: 16.66%" scope="row">
                  Next or Specific Date
                  {{ application.payment.requestedEffectiveDate }}
                </th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.nextOrSpecificDate }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td *ngIf="getVerification('payment.nextOrSpecificDate')">
                  <b>Verified</b>
                </td>
                <ng-template #verified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #notVerified>
                  <td style="width: 16.66%"><b>Not Verified</b></td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="i.section.name === 'Payment'">
          Initial Payment Detail:
          <table class="table table-striped table-sm table-bordered mt-4">
            <tbody>
              <tr>
                <th style="width: 16.66%" scope="row">Initial Payment By</th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.initialPaymentBy }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getVerification('payment.initialPaymentBy');
                    then methodOfPmtverified;
                    else methodOfPmtNotVerified
                  "
                ></td>
                <ng-template #methodOfPmtverified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #methodOfPmtNotVerified>
                  <td style="width: 16.66%"><b>Not Verified</b></td>
                </ng-template>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Bank Name</th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.initPmtBankName }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getVerification('payment.initPmtBankName');
                    then nameverified;
                    else nameNotVerified
                  "
                ></td>
                <ng-template #nameverified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #nameNotVerified>
                  <td style="width: 16.66%"><b>Not Verified</b></td>
                </ng-template>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Routing #</th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.initPmtBankRoutingCode }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getVerification('payment.initPmtBankRoutingCode');
                    then routingVerified;
                    else routingNotVerified
                  "
                ></td>
                <ng-template #routingVerified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #routingNotVerified>
                  <td style="width: 16.66%">
                    <b>Not Verified</b>
                  </td>
                </ng-template>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Account #</th>
                <td style="width: 16.66%">
                  <b>{{ application.payment.initPmtBDAccountNumber | maskAccountNumber }}</b>
                </td>
                <td style="width: 16.66%">Verification status</td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getVerification('payment.initPmtBDAccountNumber');
                    then verified;
                    else notVerified
                  "
                ></td>
                <ng-template #verified>
                  <td style="width: 16.66%"><b>Verified</b></td>
                </ng-template>
                <ng-template #notVerified>
                  <td style="width: 16.66%">
                    <b>Not Verified</b>
                  </td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container *ngIf="i.section.name === 'Payment'">
          <div>Third party payor details:</div>
          <table
            class="table table-striped table-sm table-bordered mt-4"
            *ngIf="
              (getAnswerforTag('Payment.ApplicationPayorMonthly') &&
                getAnswerforTag('Payment.ApplicationPayorMonthly')?.value !==
                  'PayorIsApplicant') ||
              (getAnswerforTag('Payment.ApplicationPayorInitial') &&
                getAnswerforTag('Payment.ApplicationPayorInitial')?.value !==
                  'PayorIsApplicant')
            "
          >
            <thead>
              <tr>
                <th scope="col"></th>
                <th
                  scope="col"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  Third party payor Recurring Details
                </th>
                <th
                  scope="col"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  Third party payor Initial Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th style="width: 16.66%" scope="row">Billing Name</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.billingName }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.billingName }}</b>
                </td>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Street Address</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.streetAddress }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.streetAddress }}</b>
                </td>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">City</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.city }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.city }}</b>
                </td>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">State</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.state }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.state }}</b>
                </td>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Zipcode</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.zipCode }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.zipCode }}</b>
                </td>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Phone</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.phone }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.phone }}</b>
                </td>
              </tr>

              <tr>
                <th style="width: 16.66%" scope="row">Business Name</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.businessName }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.businessName }}</b>
                </td>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Business Owner</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.businessOwner }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.businessOwner }}</b>
                </td>
              </tr>
              <tr
                *ngIf="
                  thirdPartyDetailsMonthly.authorizedName ||
                  thirdPartyDetailsInitial.authorizedName
                "
              >
                <th style="width: 16.66%" scope="row">Authorized Name</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.authorizedName }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.authorizedName }}</b>
                </td>
              </tr>
              <tr
                *ngIf="
                  thirdPartyDetailsMonthly.authorizedPhone ||
                  thirdPartyDetailsInitial.authorizedPhone
                "
              >
                <th style="width: 16.66%" scope="row">Authorized Phone</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.authorizedPhone }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.authorizedPhone }}</b>
                </td>
              </tr>
              <tr
                *ngIf="
                  thirdPartyDetailsMonthly.notes ||
                  thirdPartyDetailsInitial.notes
                "
              >
                <th style="width: 16.66%" scope="row">Notes</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.notes }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.notes }}</b>
                </td>
              </tr>
              <tr>
                <th style="width: 16.66%" scope="row">Email</th>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorMonthly')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsMonthly.email }}</b>
                </td>
                <td
                  style="width: 16.66%"
                  *ngIf="
                    getAnswerforTag('Payment.ApplicationPayorInitial')
                      ?.value !== 'PayorIsApplicant'
                  "
                >
                  <b>{{ thirdPartyDetailsInitial.email }}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="font-weight-bold col-4"
            *ngIf="
              (getAnswerforTag('Payment.ApplicationPayorMonthly') ===
                undefined ||
                getAnswerforTag('Payment.ApplicationPayorMonthly')?.value ===
                  'PayorIsApplicant') &&
              (getAnswerforTag('Payment.ApplicationPayorInitial') ===
                undefined ||
                getAnswerforTag('Payment.ApplicationPayorInitial')?.value ===
                  'PayorIsApplicant')
            "
          >
            There are no third party payor details
          </div>
        </ng-container>

        <ng-container *ngIf="i.section.name === 'Demographic'">
          <table class="table table-striped table-sm table-bordered">
            <tbody>
              <tr>
                <th scope="row">Street Address</th>
                <td>{{ application.contact.streetAddress }}</td>
              </tr>
              <tr>
                <th scope="row">City</th>
                <td>{{ application.contact.city }}</td>
              </tr>
              <tr>
                <th scope="row">State</th>
                <td>{{ application.contact.state }}</td>
              </tr>
              <tr>
                <th scope="row">Zip</th>
                <td>{{ application.contact.zipCode }}</td>
              </tr>

              <tr>
                <th scope="row">Home Phone</th>
                <td>{{ application.contact.homePhone }}</td>
              </tr>
              <tr>
                <th scope="row">Work Phone</th>
                <td>{{ application.contact.workPhone }}</td>
              </tr>
              <tr>
                <th scope="row">Mobile Phone</th>
                <td>{{ application.contact.mobilePhone }}</td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>{{ application.contact.email }}</td>
              </tr>
              <tr>
                <th scope="row">e-Fullfillment</th>
                <td>{{ application.contact.eFulfillment }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container *ngIf="i.section.name === 'Demographic'">
          <div
            *ngFor="let question of i.questions; index as q"
            class="m-1 mb-4"
          >
            <table
              *ngIf="family && q === 0"
              class="table table-striped table-sm table-bordered"
            >
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Middle Name</th>
                  <th>DOB</th>

                  <th>Gender</th>
                  <th>Height</th>
                  <th>Weight</th>
                  <th>SSN</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ primaryApplicant.firstName }}</td>
                  <td>{{ primaryApplicant.lastName }}</td>
                  <td>{{ primaryApplicant.middleName }}</td>
                  <td>
                    {{ primaryApplicant.birthDate | date : "MM-dd-yyyy" }}
                  </td>

                  <td>{{ primaryApplicant.gender }}</td>
                  <td>{{ primaryApplicant.height }}</td>
                  <td>{{ primaryApplicant.weight }}</td>
                  <td>{{ primaryApplicant.ssn | mask : 4 }}</td>
                </tr>
                <tr *ngFor="let applicant of family; let i = index">
                  <ng-container *ngIf="applicant.applicantType === 'Regular'">
                    <td>{{ applicant.firstName }}</td>
                    <td>{{ applicant.lastName }}</td>
                    <td>{{ applicant.middleName }}</td>
                    <td>{{ applicant.birthDate | date : "MM-dd-yyyy" }}</td>

                    <td>{{ applicant.gender }}</td>
                    <td>{{ applicant.height }}</td>
                    <td>{{ applicant.weight }}</td>
                    <td>{{ applicant.ssn | mask : 4 }}</td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>

        <div *ngFor="let question of i.questions" class="m-1 mb-4">
          <!-- Handling multiple question prompt display -->
          <div *ngIf="question && getQuestionInstanceAnswered(question)">
            <div *ngIf="question" class="mb-2">
              <div
                *ngIf="
                  question.type !== 'MedicalAilment' &&
                  getAnswerforQuestion(question) !== '' &&
                  (question.details.answerTag === null ||
                    question.details.answerTag === undefined) &&
                  question.type !== 'AilmentDashboard'
                "
              >
                <b>Q. &nbsp;</b>
                <span [innerHTML]="getPromptforQuestion(question)"></span>
              </div>
              <ng-container *ngIf="question?.details?.mode === 'product'">
                <ul>
                  <li *ngFor="let item of indemnityCoverage">
                    {{ item.planName }}
                  </li>
                </ul>
              </ng-container>
              <ng-container *ngIf="question?.details?.mode === 'rider'">
                <ul>
                  <li *ngFor="let item of indemnityCoverage">
                    {{ item.planName }} - {{ item.riderDescription }}
                  </li>
                </ul>
              </ng-container>
              <ng-container *ngIf="question?.details?.mode === 'otherCoverage'">
                <ul>
                  <li *ngFor="let coverage of otherCoverage">
                    {{ coverage.otherPlanName }}
                  </li>
                </ul>
              </ng-container>
              <ng-container
                *ngIf="question?.details?.mode === 'policyInformation'"
              >
                <ul>
                  <li *ngFor="let policy of getPolicyInformation(question)">
                    {{ policy.product }}
                  </li>
                </ul>
              </ng-container>

              <div *ngIf="question">
                <div
                  *ngIf="
                    getAnswerforQuestion(question) !== '' &&
                    question.type !== 'AilmentDashboard'
                  "
                >
                  <b
                    [ngClass]="{
                      'text-danger':
                        highlightToRed(question) &&
                        getAnswerforQuestion(question) !== 'None' &&
                        getAnswerforQuestion(question) !== 'No'
                    }"
                    >{{ getAnswerforQuestion(question) }}</b
                  >
                </div>
                <div *ngIf="notes">
                  <b
                    ><span>QuestionNotes: </span><span>{{ notes }}</span></b
                  >
                </div>
              </div>

              <div *ngIf="detailAnswers && detailAnswers.length > 0">
                <div
                  *ngFor="let detail of getDetailAnswerForQuestion(question)"
                >
                  <!-- if applicant name select for 'MedicalQualifying' questions  start-->
                  <ng-container *ngIf="detail.ailments.length === 0">
                    <div
                      *ngFor="
                        let item of detail.medicalQualifyingQuestionApplicants
                      "
                    >
                      <div>
                        <b
                          [ngClass]="{
                            'text-danger': highlightToRed(question)
                          }"
                        >
                          {{ getNameForClientNo(item.clientNumber) }}</b
                        >
                      </div>
                    </div>
                  </ng-container>
                  <!-- if applicant name select for 'MedicalQualifying' questions  end-->
                  <div
                    *ngIf="
                      question.type !== 'MedicalQualifying' &&
                      question.type !== 'QualifyingStudent'
                    "
                  >
                    <div *ngFor="let item of detail.ailments">
                      <div *ngIf="item.value.length > 0">
                        <b>
                          {{ getNameForClientNo(item.clientNumber) }} -
                          {{ item.value }}</b
                        >
                      </div>
                    </div>
                    <div *ngFor="let item of detail.values">
                      <!-- Due to employment script changes added for backward compatibility post vcall 1.8 release -->
                      <ng-container
                        *ngIf="
                          item.value[0] &&
                            ((item.value[0].value.answerType ===
                              'OccupationDetailsAnswer' &&
                              question?.details?.answerTag ===
                                item.value[0].key) ||
                              (question?.details?.answerTag === 'CreditHours' &&
                                item.value[0].key === 'Employed'));
                          else doctorLookUp
                        "
                      >
                        <div *ngIf="item.value.length > 0">
                          <b> {{ getNameForClientNo(item.clientNumber) }}</b>
                        </div>
                        <table
                          class="table table-striped table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <th style="width: 16.66%" scope="col">
                                Domain/Field Name
                              </th>
                              <th style="width: 16.66%" scope="col">
                                Occupation
                              </th>
                              <th style="width: 16.66%" scope="col">
                                Duties/Details
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 16.66%">
                                {{ item.value[0].value.fieldName }}
                              </td>
                              <td style="width: 16.66%">
                                {{ item.value[0].value.occupationName }}
                              </td>
                              <td style="width: 16.66%">
                                {{ item.value[0].value.duties }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-template #doctorLookUp>
                        <ng-container
                          *ngIf="
                            item.value[0] &&
                              item.value[0].value.answerType ===
                                'DoctorDetailsAnswer' &&
                              question?.details?.answerTag ===
                                item.value[0].key;
                            else Prescription
                          "
                        >
                          <div *ngIf="item.value.length > 0">
                            <b> {{ getNameForClientNo(item.clientNumber) }}</b>
                          </div>
                          <div *ngIf="item.value[0]?.value?.list.length > 0">
                            <table
                              class="table table-striped table-sm table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 16.66%">
                                    Name of Doctor
                                  </th>
                                  <th scope="col" style="width: 16.66%">
                                    Street Address
                                  </th>
                                  <th scope="col" style="width: 16.66%">
                                    City
                                  </th>
                                  <th scope="col" style="width: 16.66%">
                                    State
                                  </th>
                                  <th scope="col" style="width: 16.66%">
                                    Zip Code
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let listItem of item.value[0].value.list
                                  "
                                >
                                  <td style="width: 16.66%">
                                    {{ listItem.fullname }}
                                  </td>
                                  <td style="width: 16.66%">
                                    {{ listItem.street_address }}
                                  </td>
                                  <td style="width: 16.66%">
                                    {{ listItem.city }}
                                  </td>
                                  <td style="width: 16.66%">
                                    {{ listItem.state }}
                                  </td>
                                  <td style="width: 16.66%">
                                    {{ listItem.zipcode }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div *ngIf="item.value[0]?.value?.list.length === 0">
                            <b>No</b>
                          </div>
                        </ng-container>
                      </ng-template>
                      <ng-template #Prescription>
                        <ng-container
                          *ngIf="
                            item.value[0] &&
                              item.value[0].value.answerType ===
                                'MedicationDetailsAnswer' &&
                              question?.details?.answerTag ===
                                item.value[0].key;
                            else remaining
                          "
                        >
                          <div *ngIf="item.value.length > 0">
                            <b> {{ getNameForClientNo(item.clientNumber) }}</b>
                          </div>
                          <table
                            class="table table-striped table-sm table-bordered"
                          >
                            <thead>
                              <tr>
                                <th scope="col" style="width: 16.66%">
                                  Medication
                                </th>
                                <th scope="col" style="width: 16.66%">
                                  Dosage
                                </th>
                                <th scope="col" style="width: 16.66%">
                                  Last Date
                                </th>
                                <th scope="col" style="width: 16.66%">
                                  Doctor Name
                                </th>
                                <th scope="col" style="width: 16.66%">
                                  Reason
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let listItem of item.value[0].value.list
                                "
                              >
                                <td style="width: 16.66%">
                                  {{ listItem.medication }}
                                </td>
                                <td style="width: 16.66%">
                                  {{ listItem.dosage }}
                                </td>
                                <td style="width: 16.66%">
                                  <ng-container
                                    *ngIf="
                                      listItem.lastDate &&
                                        listItem.lastDate.year;
                                      else elseBlock
                                    "
                                  >
                                    {{ listItem.lastDate?.day }}/{{
                                      listItem.lastDate?.month
                                    }}/{{ listItem.lastDate?.year }}
                                  </ng-container>
                                  <ng-template #elseBlock>
                                    {{ listItem.lastDate }}
                                  </ng-template>
                                </td>
                                <td style="width: 16.66%">
                                  {{ listItem.doctorName }}
                                </td>
                                <td style="width: 16.66%">
                                  {{ listItem.reason }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>
                      </ng-template>
                      <ng-template #remaining>
                        <ng-container>
                          <div *ngFor="let val of item.value">
                            <div
                              class="d-flex flex-column pb-2"
                              *ngIf="
                                val && question?.details?.answerTag === val.key
                              "
                            >
                              <div>
                                <b>Q. &nbsp;</b>
                                <span [innerHTML]="val.prompt"></span>
                                <div *ngIf="item.value.length > 0">
                                  <b
                                    >Selected Applicant -
                                    {{
                                      getNameForClientNo(item.clientNumber)
                                    }}</b
                                  >
                                </div>
                              </div>
                              <div
                                *ngIf="val.value.applicable !== null"
                                class="pb-2"
                              >
                                <div *ngIf="val.value.applicable === 'true'">
                                  <b>Yes</b>
                                </div>
                                <div *ngIf="val.value.applicable === 'false'">
                                  <b> No</b>
                                </div>
                              </div>
                              <div *ngIf="val.value.notes" class="pb-2">
                                <b>Notes: &nbsp; {{ val.value.notes }}</b>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-template>
                    </div>

                    <ng-container *ngIf="detail.details">
                      <div *ngFor="let drilldown of detail.details">
                        <div class="border-bottom pt-1">
                          <b class="pb-1">
                            {{ drilldown.ailment }} -
                            {{ getNameForClientNo(drilldown.clientNumber) }}
                          </b>
                        </div>
                        <div *ngFor="let answer of drilldown.answers">
                          <div *ngIf="answer.prompt; else remain">
                            <p [innerHTML]="answer.prompt"></p>
                          </div>
                          <ng-template #remain>
                            {{ question.text }}
                          </ng-template>
                          <div *ngIf="answer.value?.list; else simpleVal">
                            <app-drill-down-values-view
                              [value]="answer.value.list"
                            ></app-drill-down-values-view>
                          </div>
                          <ng-template #simpleVal>
                            <app-drill-down-values-view
                              [value]="answer.value"
                            ></app-drill-down-values-view>
                          </ng-template>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Applicant Employment Status to dispaly in Demographic section start-->
                  <ng-container *ngIf="question.type === 'QualifyingStudent'">
                    <div *ngFor="let item of detail.values">
                      <div *ngIf="item.value.length > 0">
                        <div *ngFor="let val of item.value">
                          <b *ngIf="val.key !== 'isApplicantFullTimeStudent'">
                            {{ getNameForClientNo(item.clientNumber) }} -
                            {{ val.key || val.value }}</b
                          >
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- Applicant Employment Status end-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav
        class="navbar navbar-light bg-secondary rounded align-center m-1 mb-2 mt-1 justify-content-center"
        id="Critical"
      >
        <span class="navbar-brand mb-0 h1 text-white">Critical Section</span>
      </nav>
      <ng-container>
        <div *ngFor="let i of item">
          <div *ngFor="let question of i.questions">
            <ng-container *ngIf="question.details.Critical">
              <div
                [innerHTML]="'<b>Q. &nbsp;</b>' + question.text"
                *ngIf="
                  getAnswerforQuestion(question) !== 'None' &&
                  getAnswerforQuestion(question) !== 'No' &&
                  getAnswerforQuestion(question) !== ''
                "
              ></div>
              <b
                [ngClass]="{ 'text-danger': highlightToRed(question) }"
                *ngIf="
                  getAnswerforQuestion(question) !== 'None' &&
                  getAnswerforQuestion(question) !== 'No' &&
                  getAnswerforQuestion(question) !== ''
                "
                >{{ getAnswerforQuestion(question) }}</b
              >
              <div *ngIf="detailAnswers && detailAnswers.length > 0">
                <div
                  *ngFor="let detail of getDetailAnswerForQuestion(question)"
                >
                  <ng-container *ngIf="detail.ailments.length === 0">
                    <div
                      *ngFor="
                        let med_item of detail.medicalQualifyingQuestionApplicants
                      "
                    >
                      <div>
                        <b
                          [ngClass]="{
                            'text-danger': highlightToRed(question)
                          }"
                        >
                          {{ getNameForClientNo(med_item.clientNumber) }}</b
                        >
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
