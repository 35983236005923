import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { HandledHttpClient } from 'src/app/shared/services/api/handledHttpClient';
import { environment } from 'src/environments/environment';
import { CascadedDropdownConfigurator } from './cascaded-dropdwon-configurator';

@Component({
  selector: 'app-cascaded-dropdown',
  templateUrl: './cascaded-dropdown.component.html',
  styleUrls: ['./cascaded-dropdown.component.scss'],
})
export class CascadedDropDownComponent implements OnInit {
  constructor(private http: HandledHttpClient) {}

  firstList: AppKeyValuePair[] = [];
  secondList: AppKeyValuePair[] = [];
  baseUrl = environment.config.apis.ezapp;
  result: Array<KeyValue<string, string>> = [
    { key: null, value: null },
    { key: null, value: null },
  ];
  item1: AppKeyValuePair = null;
  item2: AppKeyValuePair;
  configurator: CascadedDropdownConfigurator;

  @Input() configurator$: Observable<CascadedDropdownConfigurator>;
  @Output() Save = new EventEmitter();
  @Input() editOccupation;

  ngOnInit() {
    this.configurator$.subscribe((c) => setTimeout(() => this.Process(c), 0));
  }

  private Process(c: CascadedDropdownConfigurator) {
    this.configurator = c;
    this.item1 = null; // { Key : null, Value : null};
    this.item2 = null;
    this.secondList = [];

    if (this.firstList.length === 0) {
      this.http
        .get<Array<AppKeyValuePair>>(
          this.baseUrl + `${this.configurator.dataKey}`
        )
        .subscribe((data: any) => {
          this.firstList = data;
          this.bindFirstList();
        });
    } else {
      this.bindFirstList();
    }
  }

  bindFirstList() {
    if (this.configurator.key1) {
      this.item1 = this.firstList.find((i) => i.Key === this.configurator.key1);
      this.result[0].value = this.item1.Value;
      this.result[0].key = this.item1.Key;

      this.bindSecondList(this.item1);
    }
  }

  bindSecondList(e: AppKeyValuePair) {
    this.http
      .get(this.baseUrl + `${this.configurator.dataKey}` + '/' + `${e.Key}`)
      .subscribe((selected: any) => {
        this.secondList = selected;
        if (this.configurator.key2) {
          this.item2 = this.secondList.find(
            (i) => i.Key === this.configurator.key2
          );
          this.result[1].value = this.item2.Value;
          this.result[1].key = this.item2.Key;
          this.Save.emit(this.result);
        }
      });
  }

  selectItem(e: AppKeyValuePair) {
    if (e != null) {
      this.http
        .get(this.baseUrl + `${this.configurator.dataKey}` + '/' + `${e.Key}`)
        .subscribe((selected: any) => {
          this.secondList = selected;
        });
    } else {
      this.secondList = [];
    }

    this.result[0].value = e === null ? null : e.Value;
    this.result[0].key = e === null ? null : e.Key;
    this.result[1].value = null;
    this.result[1].key = null;
    this.item2 = null;
    this.Save.emit(this.result);
  }

  secondListSelected(e) {
    this.result[1].value = e === null ? null : e.Value;
    this.result[1].key = e === null ? null : e.Key;
    this.Save.emit(this.result);
  }

  save(item1: AppKeyValuePair, item2: AppKeyValuePair) {
    this.result[0].value = item1 === null ? null : item1.Value;
    this.result[0].key = item1 === null ? null : item1.Key;
    this.result[1].value = item2 === null ? null : item2.Value;
    this.result[1].key = item2 === null ? null : item2.Key;

    this.Save.emit(this.result);
  }
}

export interface AppKeyValuePair {
  Key: string;
  Value: string;
}
