<!-- verifications process starts from here -->
<app-modal
  [open]="openModal"
  [modalOptions]="{ size: 'lg' }"
  [footer]="false"
  dismiss
  [buttonsConfig]="{
    modalMode: 'customButtons',
    submitButtonName: 'Continue',
    cancelButtonName: 'Cancel',
    showModalCrossIcon: false
  }"
  (actions)="getNotifyEvent()"
>
  <div title class="row justify-content-between ml-2">
    <div class="d-flex" *ngIf="application">
      <i class="fas fa-user fa-modal-header text-black-50 mt-2"></i>
      <div class="d-block ml-3">
        <p class="label-Name m-0">Applicant Name</p>
        <p class="applicant-Name">
          {{ application.primaryApplicant | fullname }}
        </p>
      </div>
    </div>
    <div class="d-flex" *ngIf="selectedAppointment">
      <i class="fas fa-calendar-alt fa-modal-header text-black-50 mt-2"></i>
      <div class="d-block ml-3">
        <p class="label-Name m-0">Scheduled Time</p>
        <p class="applicant-Name">
          {{
            selectedAppointment.scheduledDate
              | datewithTimeZone : timeZone : "date-time"
          }}
        </p>
      </div>
    </div>
  </div>
  <div body class="mt-1 mx-5" *ngIf="selectedAppointment">
    <ng-container
      *ngIf="
        vm &&
        (vm.dialingState === 'InitialDialing' ||
          vm.dialingState === 'FollowupDialing')
      "
    >
      <div class="call-info">
        <ng-container *ngIf="vm.dialingState === 'InitialDialing'"
          >Attempt 01: Please make first call</ng-container
        >
        <ng-container *ngIf="vm.dialingState === 'FollowupDialing'"
          >Attempt 02: Please make Second call</ng-container
        >
      </div>
      <div
        class="mt-3 row text-center m-0"
        [ngClass]="{
          'row justify-content-center':
            selectedAppointment.callType !== 'Recall' ||
            (selectedAppointment.callType === 'Recall' &&
              (contactType === 'Applicant' || contactType === undefined))
        }"
      >
        <p
          class="p-0 col-sm-12 f-18"
          [ngClass]="{
            'col-sm-5':
              selectedAppointment.callType !== 'Recall' ||
              (selectedAppointment.callType === 'Recall' &&
                (contactType === 'Applicant' || contactType === undefined))
          }"
        >
          Using your telephone, please dial the desired contact number
          <span
            class="tel-no"
            *ngIf="
              selectedAppointment.callType === 'Recall' &&
              (contactType === 'Payment.ApplicationPayorInitial' ||
                contactType === 'Payment.ApplicationPayorMonthly')
            "
            >: {{ dialingInformation.businessPhone || "N/A" }}</span
          >
        </p>
        <ng-container
          *ngIf="
            selectedAppointment.callType === 'Recall' &&
            (contactType === 'Payment.ApplicationPayorInitial' ||
              contactType === 'Payment.ApplicationPayorMonthly')
          "
        >
          <div class="d-flex flex-column">
            <h5>3rd Party Payor Details</h5>
            <h6 class="f-w-600 text-capitalize pt-2">
              Person/Business Name:
              <span class="pl-2 font-weight-bold">{{
                dialingInformation.businessName || "N/A"
              }}</span>
            </h6>
            <h6 class="f-w-600 text-capitalize">
              Business Owner/Manager:
              <span class="pl-2 font-weight-bold">{{
                dialingInformation.businessOwner || "N/A"
              }}</span>
            </h6>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            selectedAppointment.callType !== 'Recall' ||
            (selectedAppointment.callType === 'Recall' &&
              (contactType === 'Applicant' || contactType === undefined))
          "
        >
          <div class="col">
            <div class="row">
              <div class="col-6 text-right applicant-detail-nm">Home:</div>
              <div class="col-6 text-left">
                {{ dialingInformation.homePhone || "N/A" }}
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-right applicant-detail-nm">Work:</div>
              <div class="col-6 text-left">
                {{ dialingInformation.workPhone || "N/A" }}
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-right applicant-detail-nm">Mobile:</div>
              <div class="col-6 text-left">
                {{ dialingInformation.mobilePhone || "N/A" }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <hr class="voice-mail-divider" />
      <p class="applicant-detail-nm mt-3 text-center">
        Choose the relevant response
      </p>
      <div class="row justify-content-center">
        <select
          class="form-control col-sm-6"
          [(ngModel)]="callStatus"
          (ngModelChange)="setCallResponse($event)"
        >
          <option
            *ngFor="let response of callResponseType"
            [value]="response.key"
          >
            {{ response.label }}
          </option>
        </select>
      </div>
      <div class="row justify-content-center mb-5 mt-5">
        <ng-container>
          <button
            type="button"
            class="btn btn-outline-dark btn-prop"
            (click)="cancelModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary ml-5 btn-prop"
            [ngClass]="{ 'disable-btn': callStatus === undefined }"
            [disabled]="!callStatus"
            (click)="proceedCallAnswered()"
          >
            Continue
          </button>
        </ng-container>
      </div>
    </ng-container>
    <!-- call response no starts from here -->
    <!-- voice mail script starts from here -->
    <ng-container *ngIf="vm && vm.dialingState === 'VoiceScript'">
      <div class="mt-1">
        <p class="call-info mt-3 p-2 text-center">
          Attempt 01: Please read below voice mail script before ending the
          call.
        </p>
        <div class="voice-mail-color">
          <p class="mt-3">Good Morning/Afternoon/Evening,</p>
          <p class="mt-2">
            {{ voiceMailMsg }}
          </p>
          <p class="mt-2">Thank You!</p>
        </div>
        <div class="mt-4 text-center mb-4 f-18 block-conent-center">
          <div class="mr-5">
            <span class="text-danger">*</span>Left voicemail
          </div>
          <input
            type="radio"
            name="leftvoicemail"
            required
            id="leftvoicemailYes"
            class="radio-prop"
            [(ngModel)]="voicemailResponseFirst.leftVoiceMail"
            [value]="true"
          />
          <label
            for="leftvoicemailYes"
            class="pl-3 mr-5 call-status cursor-pointer"
            >Yes</label
          >
          <input
            type="radio"
            name="leftvoicemail"
            required
            class="radio-prop"
            id="leftvoicemailNo"
            [(ngModel)]="voicemailResponseFirst.leftVoiceMail"
            [value]="false"
          />
          <label
            for="leftvoicemailNo"
            class="pl-3 mr-5 call-status cursor-pointer"
            >No</label
          >
        </div>
        <div class="mt-5">
          <p class="call-status text-danger">
            *Please add comments in the text field. Max 200 characters are
            allowed.
          </p>
          <textarea
            class="form-control"
            rows="4"
            [maxLength]="200"
            [(ngModel)]="voicemailResponseFirst.comments"
          ></textarea>
        </div>
        <div class="row justify-content-center mt-5">
          <button
            type="button"
            class="btn btn-primary btn-prop"
            (click)="closeVoiceMail()"
            [ngClass]="{
              'disable-btn':
                voicemailResponseFirst.leftVoiceMail === undefined ||
                !voicemailResponseFirst.comments
            }"
            [disabled]="
              voicemailResponseFirst.leftVoiceMail === undefined ||
              !voicemailResponseFirst.comments
            "
          >
            Close
          </button>
        </div>
      </div>
    </ng-container>
    <!-- voice mail script starts from here -->
    <!-- timer starts from here -->
    <ng-container *ngIf="vm && vm.dialingState === 'ClockTimer'">
      <p class="call-info mt-3 p-2 text-center">
        Attempt 02: You have to wait for 180 sec to call again
      </p>
      <div class="mt-5 text-center mb-5">
        <p class="timer">{{ count }}</p>
        <p class="call-status">seconds remaining</p>
      </div>
      <div class="row justify-content-end">
        <div class="col-sm-offset-6">
          <button
            class="btn btn-outline-primary"
            (click)="skipTimer()"
            *ngIf="count > 5"
          >
            Skip Timer
          </button>
        </div>
      </div>
    </ng-container>
    <!-- timer ends here -->
    <!-- sent mail after second attempt if answer is no starts from here -->
    <ng-container *ngIf="vm && vm.dialingState === 'RegNoShow'">
      <p class="call-info mt-3 p-2 text-center">
        Attempt 02: Please submit the relevant information
      </p>
      <div class="voice-mail-color">
        <p class="mt-3">Good Morning/Afternoon/Evening,</p>
        <p class="mt-2">
          {{ voiceMailMsgFollowup }}
        </p>
        <p class="mt-2">Thank You!</p>
      </div>
      <div class="mt-4 text-center mb-4 f-18 block-conent-center">
        <div class="mr-5"><span class="text-danger">*</span>Left voicemail</div>
        <input
          type="radio"
          name="leftvoicemailSecond"
          id="leftvoicemailYesSecond"
          class="radio-prop"
          [(ngModel)]="voicemailResponseSecond.leftVoiceMail"
          [value]="true"
        />
        <label
          for="leftvoicemailYesSecond"
          class="pl-3 mr-5 call-status cursor-pointer"
          >Yes</label
        >
        <input
          type="radio"
          name="leftvoicemailSecond"
          class="radio-prop"
          id="leftvoicemailNoSecond"
          [(ngModel)]="voicemailResponseSecond.leftVoiceMail"
          [value]="false"
        />
        <label
          for="leftvoicemailNoSecond"
          class="pl-3 mr-5 call-status cursor-pointer"
          >No</label
        >
      </div>
      <div class="mt-5">
        <p class="call-status text-danger">
          *Please add comments in the text field. Max 200 characters are
          allowed.
        </p>
        <textarea
          class="form-control"
          rows="4"
          [maxLength]="200"
          [(ngModel)]="voicemailResponseSecond.comments"
        ></textarea>
      </div>
      <div class="row justify-content-center mt-5">
        <button
          type="button"
          class="btn btn-secondary btn-prop mr-5"
          (click)="goBack()"
        >
          Back
        </button>
        <button
          type="button"
          class="btn btn-primary btn-prop"
          [ngClass]="{
            'disable-btn':
              voicemailResponseSecond.leftVoiceMail === undefined ||
              voicemailResponseSecond.leftVoiceMail === null ||
              !voicemailResponseSecond.comments
          }"
          [disabled]="
            voicemailResponseSecond.leftVoiceMail === undefined ||
            voicemailResponseSecond.leftVoiceMail === null ||
            !voicemailResponseSecond.comments
          "
          (click)="finalSubmit()"
        >
          Submit
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="vm && vm.dialingState === 'IsSelectedAppointment'">
      <div class="mt-1">
        <p class="call-info mt-3 p-2 text-center">
          Sorry, the appointment has been re-assigned
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="callStatus === 'WrongNumber'">
      <app-end-call-modal> </app-end-call-modal>
    </ng-container>

    <!-- sent mail after second attempt if answer is no ends here -->
    <!-- call response no ends here -->
  </div>
</app-modal>

<!-- verifications process starts from here -->
<app-modal
  [open]="openModalNoAppointment"
  [modalOptions]="{ size: 'lg' }"
  [footer]="true"
  dismiss
  [buttonsConfig]="{
    modalMode: 'msg',
    submitButtonName: 'Close',
    cancelButtonName: 'Cancel',
    position: 'center'
  }"
  (actions)="getNotifyEvent()"
>
  <div title class="row justify-content-between ml-2">
    <div class="d-flex">
      <i class="fas fa-user fa-modal-header text-black-50 mt-2"></i>
      <div class="d-block ml-3"></div>
    </div>
  </div>
  <div body class="mt-1 mx-5">
    <div class="mt-1">
      <p class="call-info mt-3 p-2 text-center">
        Sorry, the appointment has been re-assigned
      </p>
    </div>
  </div>
</app-modal>

<!-- verifications process ends here-->
<app-online-applicants></app-online-applicants>
