import { Injectable } from '@angular/core';
import { VcallApiService } from '../api/vcall/vcall-api.service';
export interface ApplicationString {
  answerTag: string;
  text: string;
}
@Injectable({
  providedIn: 'root',
})
export class ApplicationStringsService {
  applicationStrings: ApplicationString[] = [];

  constructor(private vcallApiService: VcallApiService) {}

  loadStrings(appId: string, tag: string, questionId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.vcallApiService
        .GetDetailQuestions(appId, tag, questionId, false)
        .subscribe(
          (data: ApplicationString[]) => {
            if (Array.isArray(data)) {
              this.applicationStrings = [...this.applicationStrings, ...data];
            }
            resolve();
          },
          (error) => {
            reject();
          }
        );
    });
  }

  getStringByKey(key: string, defaultKey?: string) {
    let result = this.applicationStrings.filter(
      (item) => key === item.answerTag
    );
    if (result.length === 0 && defaultKey) {
      result = this.applicationStrings.filter(
        (item) => defaultKey === item.answerTag
      );
    }
    return result.length ? result[0].text : `${key}.NOTFOUND`;
  }
}
