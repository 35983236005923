import { ParamMap } from '@angular/router';
import { VcallMode } from '../../services/script/script.service';

export class VcallParams {
  constructor(params?: ParamMap) {
    if (params) {
      this.applicationId = params.get('applicationId');
      this.appointmentId = params.get('appointmentId');
      this.questionId = params.get('questionId');
      this.sectionId = params.get('sectionId');
      this.stopNavigation = params.get('stopNavigation');
    }
  }

  applicationId: string;
  appointmentId: string;
  sectionId: string;
  questionId: string;
  mode: VcallMode;
  stopNavigation: string;
}

export class ImpersonateParams {
  constructor(params: ParamMap) {
    this.resource = params.get('resource');
    this.start = params.get('start');
    this.end = params.get('end');
    this.appid = params.get('appid');
    this.canAccessFakeAppointment = params.get('canAccessFakeAppointment');
  }

  resource: string;
  start: string;
  end: string;
  appid: string;
  canAccessFakeAppointment: string;
}
