import {
  FilterParameter,
  FilterResult,
} from '../../question-filter/question-filter.service';
import { BaseFilter } from '../../question-filter/filters/base-filter';

export class ApplicationApplicantAttributeFilter extends BaseFilter {
  Type = 'ApplicantAttribute';

  protected Filter(parameter: FilterParameter): boolean {
    const applicantFilter = new ApplicantAttributeFilter();
    const result = parameter.application.applicants.map((a) => {
      parameter.applicant = a;
      return applicantFilter.Filter(parameter);
    });
    return result.find((a) => a);
  }
}

export abstract class BaseApplicantFilter {
  abstract Type: string;
  protected abstract Filter(parameter: FilterParameter): boolean;

  public Apply(parameter: FilterParameter): FilterResult {
    const canDisplay = this.Filter(parameter);
    console.log(
      'Type : ' +
        this.Type +
        ' AnswerTag : ' +
        parameter.question.answerTag +
        ' CanDisplay : ' +
        canDisplay
    );
    return { canDisplay, type: this.Type };
  }
}

export class ApplicantAttributeFilter extends BaseApplicantFilter {
  Type = 'ApplicantAttribute';

  public Filter(parameter: FilterParameter) {
    // e.g. gender = 'F'
    // e.g. relationShip = 'S'
    // if the condition is true for any of the applicants this filter returns true;
    const applicant = parameter.applicant;
    const propertyName = parameter.config.path;
    const propertyValue = parameter.config.value;

    return applicant[propertyName] === propertyValue;
  }
}
