import { KeyValue } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { ProcessorModel } from './processor-model';
import { Processor } from './processor';
import { MarkApplicationExtendedFlagProcessor } from './types/markApplicationExtendedFlagProcessor';
import { TestProcessor } from './types/testProcessor';
import { RxProfileAuthorizationProcessor } from './types/rxProfileAuthorizationProcessor';
import { SendEfullmentEmailProcessor } from './types/sendEfulfillmentEmailProcessor';
import { CopyPaymentBankInformationProcessor } from './types/copyPaymentBankInformationProcessor';
import { CopyPaymentJointBankProcessor } from './types/copyPaymentJointBankProcessor';
import { CopyPaymentBankAuthorizationProcessor } from './types/copyPaymentBankAuthorizationProcessor';
import { CopyPaymentPayorInformationProcessor } from './types/copyPaymentPayorInformationProcessor';
import { CopyPaymentPaymentByProcessor } from './types/copyPaymentPaymentByProcessor';
import { CopyPaymentEffectiveDateProcessor } from './types/copyPaymentEffectiveDateProcessor';
import { SendProgressToAPI } from './types/sendProgressToAPIProcessor';
import { ValidateDataBeforeCompleteProcessor } from './types/validateDataBeforeCompleteProcessor';
import { SendSecureEmailProcessor } from './types/sendSecureEmailProcessor';
import { BehaviorSubject } from 'rxjs';
import { SetAppointmentStatusToCompleteProcessor } from './types/setAppointmentStatusToCompleteProcessor';
import { EndCallAsCompletedProcessor } from './types/endCallAsCompletedProcessor';
import { LoggerService } from 'src/app/core/logger.service';
import { ApplicationChangeProcessor } from './types/applicationChangeProcessor';
@Injectable({
  providedIn: 'root',
})
export class ProcessingService {
  private Processors = new Array<KeyValue<string, Processor[]>>();
  public ProcessingStatus$ = new BehaviorSubject<Array<ProcessingStatusItem>>(
    []
  );
  allProsessorStatus: ProcessingStatusItem[] = [];
  processorkeys: string[] = ['complete', 'CompleteStatus'];

  displayTryAgainButton: boolean = false;
  defaultError = [];

  constructor(private injector: Injector) {
    this.Processors.push({
      key: 'complete',
      value: [
        injector.get(ValidateDataBeforeCompleteProcessor),
        injector.get(MarkApplicationExtendedFlagProcessor),
        injector.get(RxProfileAuthorizationProcessor),
        injector.get(SendEfullmentEmailProcessor),
        injector.get(ApplicationChangeProcessor),
        injector.get(SendSecureEmailProcessor),
      ],
    });
    this.Processors.push({ key: 'test', value: [injector.get(TestProcessor)] });
    this.Processors.push({
      key: 'CopyBankInformation',
      value: [injector.get(CopyPaymentBankInformationProcessor)],
    });
    this.Processors.push({
      key: 'CopyJointBank',
      value: [injector.get(CopyPaymentJointBankProcessor)],
    });
    this.Processors.push({
      key: 'CopyBankAuthorization',
      value: [injector.get(CopyPaymentBankAuthorizationProcessor)],
    });
    this.Processors.push({
      key: 'CopyPayorInformation',
      value: [injector.get(CopyPaymentPayorInformationProcessor)],
    });
    this.Processors.push({
      key: 'CopyPaymentBy',
      value: [injector.get(CopyPaymentPaymentByProcessor)],
    });
    this.Processors.push({
      key: 'CopyEffectiveDate',
      value: [injector.get(CopyPaymentEffectiveDateProcessor)],
    });
    this.Processors.push({
      key: 'LogProgress',
      value: [injector.get(SendProgressToAPI)],
    });
    this.Processors.push({
      key: 'AS400Process',
      value: [injector.get(ApplicationChangeProcessor)],
    });
    this.Processors.push({
      key: 'CompleteStatus',
      value: [
        injector.get(EndCallAsCompletedProcessor),
        injector.get(SetAppointmentStatusToCompleteProcessor),
      ],
    });
  }

  async Run(
    group: string,
    model: ProcessorModel,
    ignoredProcessors?: ProcessingStatusItem[]
  ): Promise<any> {
    if (this.processorkeys.includes(group)) {
      this.injector.get(LoggerService).ErrorsList.subscribe((error) => {
        if (error && error !== undefined && error !== null) {
          this.defaultError = [...this.defaultError, error].filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.message === obj.message)
          );
        }
      });
    }
    this.displayTryAgainButton = false;
    const processors = this.Processors.find((i) => i.key === group);
    let resolve: any;
    let reject: any;
    const final = new Promise((r, j) => {
      resolve = r;
      reject = j;
    });

    if (processors && processors.value) {
      for (const processor of processors.value) {
        let findProcessor =
          ignoredProcessors &&
          ignoredProcessors.length > 0 &&
          ignoredProcessors.find((i) => i.name === processor.name);
        if (findProcessor) {
          this.setProsessorStatus(
            group,
            processor.name,
            ProcessorStatus.completed,
            findProcessor.hasOwnProperty('message')
              ? findProcessor.message
              : undefined
          );
          continue; // ignored the processors which already completed
        }
        const result = await processor.Process(model);
        if (this.processorkeys.includes(group)) {
          model.ignoreTheErros !== null &&
          model.ignoreTheErros !== undefined &&
          model.ignoreTheErros === true
            ? (result.stopProcessing = false)
            : result;
        }
        if (result && result.stopProcessing) {
          this.displayTryAgainButton = true;
          this.setProsessorStatus(
            group,
            processor.name,
            ProcessorStatus.failed,
            result && result.message ? result.message : null
          );
          reject(false);
          break;
        }
        this.setProsessorStatus(
          group,
          processor.name,
          ProcessorStatus.completed,
          result && result.message ? result.message : null
        );
      }
      resolve(true);
    }

    if (this.processorkeys.includes(group)) {
      sessionStorage.setItem(
        model.application.id + '_completeStage',
        JSON.stringify(this.allProsessorStatus)
      );
    }
    return final;
  }

  setProsessorStatus(
    group,
    processorName: string,
    statusOfProcessor: string,
    message?: string
  ) {
    if (this.processorkeys.includes(group)) {
      if (
        this.allProsessorStatus &&
        this.allProsessorStatus.length > 0 &&
        this.allProsessorStatus.find((i) => i.name === processorName)
      ) {
        this.allProsessorStatus.map((obj) => {
          if (obj.name === processorName) {
            obj.message = message;
            obj.status.push(statusOfProcessor);
          }
          return obj;
        });
      } else {
        this.allProsessorStatus.push({
          name: processorName,
          status: [statusOfProcessor],
          message: message,
        });
      }
      this.ProcessingStatus$.next(this.allProsessorStatus);
    }
  }
  
  async RunCompleteStage(model: ProcessorModel): Promise<any> {
    this.displayTryAgainButton = false;
    let result;
    let ignoredProcessors = [];
    // when try again to complete the call
    const lastResults: Array<ProcessingStatusItem> = JSON.parse(
      sessionStorage.getItem(model.application.id + '_completeStage')
    );
    if (
      lastResults !== null &&
      lastResults !== undefined &&
      Array.isArray(lastResults)
    ) {
      ignoredProcessors = lastResults.filter((p) => {
        return p.status.includes(ProcessorStatus.completed);
      });
      result = this.Run('complete', model, ignoredProcessors);
    } else {
      result = this.Run('complete', model);
    }
    return result;
  }
}

export class ProcessingStatusItem {
  name: string;
  status: string[];
  message?: string;
}

export enum ProcessorStatus {
  inprogress = 'inProgress',
  completed = 'completed',
  failed = 'failed',
}
