export enum Permission {
  CanAccessDeveloperModule = 'CanAccessDeveloperModule',
  CanAccessAdmin = 'CanAccessAdmin',
  CanAccessAdminSearch = 'CanAccessAdminSearch',
  CanCreateRecall = 'CanCreateRecall',
  CanAccessImpersonate = 'CanAccessImpersonate',

  CanAccessStartVcallOrRecall = 'CanAccessStartVcallOrRecall',
  CanAccessApplicationSearchCosmosData = 'CanAccessApplicationSearchCosmosData',
  CanAccessApplicationSearchCallZoneData = 'CanAccessApplicationSearchCallZoneData',
  CanAccessAppointmentSearchCallZoneData = 'CanAccessAppointmentSearchCallZoneData',
  CanAccessCallLog = 'CanAccessCallLog',
  CanAccessNotes = 'CanAccessNotes',
  CanAccessMenuQuickLinks = 'CanAccessMenuQuickLinks',
  CanAccessMenuStopCall = 'CanAccessMenuStopCall',
  CanAccessPDFScreen = 'CanAccessPDFScreen',
  CanAccesDiscrepencyTab = 'CanAccesDiscrepencyTab',
  CanAccesRecallTab = 'CanAccesRecallTab',
  CanAccessRecallCreateQuestions = 'CanAccessRecallCreateQuestions',
  CanAccessRecallUpdateQuestions = 'CanAccessRecallUpdateQuestions',
  CanAccessRecallDeleteQuestions = 'CanAccessRecallDeleteQuestions',
  CanAccessRecallReadQuestionsAndAnswers = 'CanAccessRecallReadQuestionsAndAnswers',
  CanAccessViewStatusOfApplication = 'CanAccessViewStatusOfApplication',

  CanAccessViewVcallScreen = 'CanAccessViewVcallScreen',
  CanAccessAppointments = 'CanAccessAppointments',
  CanAccessUnderwriting = 'CanAccessUnderwriting',

  CanAccessViewVcall = 'CanAccessViewVcall',
  CanAccessViewRecall = 'CanAccessViewRecall',
  CanAccessAPSRequestTab = 'CanAccessAPSRequestTab',
  CanAccessAddonTab = 'CanAccessAddonTab',
  CanAccessToITHelpCenter = 'CanAccessToITHelpCenter',

  MembershipVCallReadWrite = 'MembershipVCallReadWrite',
  MembershipVCallRead = 'MembershipVCallRead',
  MembershipRecallReadWrite = 'MembershipRecallReadWrite',
  MembershipRecallRead = 'MembershipRecallRead',
  MembershipAddOnReadWrite = 'MembershipAddOnReadWrite',
  MembershipAddOnRead = 'MembershipAddOnRead',
  MembershipAdmin = 'MembershipAdmin',
}
