<div class="p-3" *ngIf="notifications?.length; else NoData">
  <div class="row">
    <div class="col">
      <table class="table table-hover table-striped tbl-log mt-3">
        <thead>
          <tr class="table-dark table_header_fixed">
            <!-- <th scope="col">S. No</th> -->
            <th scope="col">Date</th>
            <th scope="col">SMS Notification</th>
          </tr>
        </thead>
        <tbody
          *ngFor="
            let notification of notifications;
            let i = index;
            let odd = odd
          "
        >
          <tr [ngClass]="{ 'bg-white': odd }">
            <td class="custom-width">{{ notification.date }}</td>
            <td>{{ notification.smsMessage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #NoData>
  <div class="p-5">
    <div class="no-data-available-info m-1">
      No Data Available
    </div>
  </div>
</ng-template>
