export const STATES = [
  { state: 'AA' },
  { state: 'AE' },
  { state: 'AK' },
  { state: 'AL' },
  { state: 'AP' },
  { state: 'AR' },
  { state: 'AS' },
  { state: 'AZ' },
  { state: 'CA' },
  { state: 'CO' },
  { state: 'CT' },
  { state: 'DC' },
  { state: 'DE' },
  { state: 'FL' },
  { state: 'FM' },
  { state: 'GA' },
  { state: 'GU' },
  { state: 'HI' },
  { state: 'IA' },
  { state: 'ID' },
  { state: 'IN' },
  { state: 'IL' },
  { state: 'KS' },
  { state: 'KY' },
  { state: 'LA' },
  { state: 'MA' },
  { state: 'MD' },
  { state: 'ME' },
  { state: 'MH' },
  { state: 'MI' },
  { state: 'MO' },
  { state: 'MN' },
  { state: 'MP' },
  { state: 'MS' },
  { state: 'MT' },
  { state: 'NC' },
  { state: 'ND' },
  { state: 'NE' },
  { state: 'NH' },
  { state: 'NJ' },
  { state: 'NM' },
  { state: 'NV' },
  { state: 'NY' },
  { state: 'OH' },
  { state: 'OK' },
  { state: 'OR' },
  { state: 'PR' },
  { state: 'PW' },
  { state: 'PA' },
  { state: 'RI' },
  { state: 'SC' },
  { state: 'SD' },
  { state: 'TN' },
  { state: 'TX' },
  { state: 'UT' },
  { state: 'VA' },
  { state: 'VI' },
  { state: 'VT' },
  { state: 'WA' },
  { state: 'WI' },
  { state: 'WV' },
  { state: 'WY' },
];
