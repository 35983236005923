import { Component, OnInit } from '@angular/core';
import { HandledHttpClient } from 'src/app/shared/services/api/handledHttpClient';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
})
export class UserGuideComponent implements OnInit {
  contents: UserGuide[];
  selectedDocument;
  contentType = 'video';
  documents: UserGuide[];
  videos: UserGuide[];
  constructor(private http: HandledHttpClient) {}

  ngOnInit() {
    this.http
      .get<string>(environment.userguideConfig, {
        excludeToken: true,
        responseType: 'blob' as 'json',
      })
      .subscribe((resp) => {
        new Blob([resp]).text().then((t) => {
          this.contents = JSON.parse(t.replace(/(\r\n|\n|\r)/gm, ''));
          const videos = this.contents.filter((item) => item.type === 'video');
          this.videos = videos;
          this.selectedDocument = this.contents[0];
          this.eachVideoContent(this.selectedDocument);
        });
      });
  }

  eachVideoContent(content) {
    this.selectedDocument = content;
  }

  getCurrentTab(tabName) {
    this.contentType = tabName;
    if (tabName === 'pdf') {
      this.documents = this.contents.filter((item) => item.type === 'pdf');
    }
    {
      this.selectedDocument = this.contents[0];
    }
  }
}

export class UserGuide {
  url: string;
  type: string;
  title: string;
}
