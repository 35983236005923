import { FilterParameter } from '../question-filter.service';
import { BaseFilter } from './base-filter';

export class ListFilter extends BaseFilter {
  Type = 'ListFilter';

  Filter(parameter: FilterParameter) {
    let canDisplay = false;
    const applicationAttributeArray = parameter.config.path.split('.');
    if (applicationAttributeArray.length > 0) {
      const value = parameter.application[applicationAttributeArray[0]].filter(
        (p) => {
          return p[applicationAttributeArray[1]].includes(
            parameter.config.value
          );
        }
      );
      if (value.length > 0) {
        canDisplay = true;
      }
    }

    return canDisplay;
  }
}
