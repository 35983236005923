import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-drill-down-values-view',
  templateUrl: './drill-down-values-view.component.html',
  styleUrls: ['./drill-down-values-view.component.scss'],
})
export class DrillDownValuesViewComponent implements OnInit {
  @Input() value: any;

  constructor() {}

  simpleValueOutput: string;
  arrayValueOutput: any[];
  objectValueOutput: any;

  ngOnInit() {
    let simpleValue;

    if (this.value) {
      simpleValue = this.value.toString();

      if (
        simpleValue === 'true' ||
        simpleValue === 'Y' ||
        simpleValue === 'y'
      ) {
        this.simpleValueOutput = 'Yes';
      } else if (
        simpleValue === 'false' ||
        simpleValue === 'N' ||
        simpleValue === 'n'
      ) {
        this.simpleValueOutput = 'No';
      } else {
        const temp = this.value;
        if (typeof temp === 'object') {
          if (Array.isArray(temp)) {
            this.arrayValueOutput = temp;
          } else {
            this.objectValueOutput = temp;
          }
        } else {
          this.simpleValueOutput = this.value;
        }
      }
    }
  }

  objectKeys(item) {
    return Object.keys(item);
  }

  isNotAnObject(value) {
    return typeof value !== 'object';
  }

  isHandled(propertyName, propertyValue) {
    switch (true) {
      // this will handle all object properties that has date word in the key name.
      case /Date/.test(propertyName):
        return typeof propertyValue === 'object';
      default:
        return false;
    }
  }

  getDisplayValue(propertyName, propertyValue) {
    switch (true) {
      // this will handle all object thas Date in the key name.
      case /Date/.test(propertyName):
        return propertyValue.year && propertyValue.day && propertyValue.month
          ? `${propertyValue.month}/${propertyValue.day}/${propertyValue.year}`
          : JSON.stringify(propertyValue);
      default:
        return false;
    }
  }

  tryParseJSON(jsonString) {
    try {
      const o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {}

    return false;
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
