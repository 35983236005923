<div class="container">
  <div class="row m-2 mt-4" *ngIf="updatedMedicationList?.length > 0">
    <div
      class="col-6 col-lg-3 pb-3 drug-item"
      *ngFor="let list of updatedMedicationList; index as i"
    >
      <div class="doctor-card card py-3 px-3 pointer">
        <div class="row">
          <div class="col text-primary pb-2">
            {{ list?.medication }}
            <span class="pl-2" *ngIf="list.dosage">({{ list?.dosage }})</span>
          </div>
        </div>
        <div class="row">
          <div class="col pb-1">
            {{ list?.doctorName }}
          </div>
        </div>
        <div class="row">
          <div class="col pb-1">
            {{ list?.reason }}
          </div>
        </div>
        <div class="d-flex justify-content-between pt-1">
          <div *ngIf="list.lastDate && list.lastDate.month; else elseblock">
            {{ list.lastDate.month }}/{{ list.lastDate.day }}/{{
              list.lastDate.year
            }}
          </div>
          <ng-template #elseblock>{{ list.lastDate }}</ng-template>
        </div>
        <div class="action-icons">
          <i
            class="fa fa-edit text-primary"
            (click)="editMedication(list, i)"
          ></i>
          <i
            class="far fa-trash-alt text-danger pl-2"
            (click)="deleteMedication(i)"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <div class="p-4">
    <div class="border-top py-4">
      <div class="input-group progress-icon">
        <input
          type="text"
          minlength="3"
          [class.is-invalid]="searchFailed"
          [(ngModel)]="prescription"
          autocomplete="disabled"
          class="form-control"
          placeholder="Please enter medication/drug details here"
          [ngbTypeahead]="onSearchMedication"
          autocomplete="disabled"
          (selectItem)="selectedItem($event)"
        />
        <i *ngIf="searching" class="fas fa-spinner fa-spin text-primary"></i>
      </div>
      <div
        class="invalid-feedback d-block mb-3"
        *ngIf="searchFailed && !searching"
      >
        Sorry, suggestions could not be loaded.
      </div>
      <!-- <div class="invalid-feedback d-block mb-3" *ngIf="isDrugExist">
        This medication has already been added, you cannot add the same medication twice.
      </div> -->
      <p class="text-center alert alert-warning mt-3 mb-0">
        If you cannot locate the medication using the Search function - please
        enter the information in the text fields below.
      </p>
    </div>
    <div class="py-3" [formGroup]="prescriptionLookup">
      <div class="row justify-content-between">
        <div class="col-sm-4">
          <input
            class="form-control"
            [formControlName]="'medication'"
            placeholder="Name of the medication"
            autocomplete="disabled"
          />
        </div>
        <div class="col-sm-4">
          <input
            class="form-control"
            [formControlName]="'dosage'"
            placeholder="Dosage"
            autocomplete="disabled"
          />
        </div>
        <div class="col-sm-4">
          <input
            class="form-control"
            [formControlName]="'doctorName'"
            placeholder="Name of the doctor"
            autocomplete="disabled"
          />
        </div>
      </div>
      <div class="row justify-content-between pt-3"></div>
      <div class="mt-3">
        <label class="font-weight-bold pb-2"
          >Date Medication was last taken</label
        >
        <app-smart-date
          (notifySmartDateChanges)="getSmartDateDetails($event)"
          [hideNotes]="hideNotes"
        ></app-smart-date>
      </div>
      <div class="row m-0 pt-2">
        <textarea
          class="form-control"
          [formControlName]="'reason'"
          placeholder="Reason/Comment"
        ></textarea>
      </div>
      <div class="row pt-3 justify-content-end">
        <input
          type="button"
          class="btn btn-secondary"
          value="Clear"
          (click)="resetForm()"
        />
        <input
          type="button"
          class="btn btn-primary ml-3"
          [disabled]="prescriptionLookup.invalid"
          [value]="isMedicationUpdated ? 'Update' : 'Add'"
          (click)="addMedication(isMedicationUpdated)"
        />
      </div>
    </div>
  </div>
</div>

<app-modal
  [open]="openModal"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event)"
>
  <div title>
    Confirmation
  </div>
  <div body>
    <div class="pl-2">
      Are you sure you want to delete this medication?
    </div>
  </div>
</app-modal>
