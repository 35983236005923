import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'cst',
})
export class CstPipe implements PipeTransform {
  transform(): any {
    return moment.utc(new Date()).toISOString();
  }
}
