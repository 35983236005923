import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { ApplicantQualifiedBaseComponent } from '../applicant-qualified-base-component';
import { DefaultApplicantQualifiedQuestionDetail } from '../default-applicant-qualified-question-detail';
import { TextBoxAnswer } from '../textbox-answer';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';

@Component({
  selector: 'app-applicant-qualified-textbox',
  templateUrl: './applicant-qualified-textbox.component.html',
  styleUrls: ['./applicant-qualified-textbox.component.scss'],
})
export class ApplicantQualifiedTextBoxComponent extends ApplicantQualifiedBaseComponent<
  DefaultApplicantQualifiedQuestionDetail,
  TextBoxAnswer
> {
  details: DefaultApplicantQualifiedQuestionDetail =
    new DefaultApplicantQualifiedQuestionDetail();
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    protected applicantFilterService: ApplicantFilterService
  ) {
    super(script, appService, ui, applicantFilterService);
  }

  nextEnabled = false;

  SaveText() {
    super.Save(this.currentAnswer);
  }
  ResetApplicant() {}
  BindApplicant() {
    this.nextEnabled = false;
    super.Save(this.currentAnswer);
  }
  CanGoNext(): boolean {
    if (
      !this.currentAnswer.notes ||
      this.currentAnswer.notes === '' ||
      this.currentAnswer.notes.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  // To improve user experience by enabling the next button
  // But not calling Save on every change avoiding un necessary Question Change events.
  EnableNext() {
    if (!this.nextEnabled) {
      super.Save(this.currentAnswer);
      this.nextEnabled = true;
    }

    if (this.currentAnswer.notes === '') {
      super.Save(this.currentAnswer);
      this.nextEnabled = false;
    }
  }

  InitializeApplicantAnswerInstance(): TextBoxAnswer {
    return new TextBoxAnswer();
  }
}
