import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  SmartDateAnswer,
  SmartDateAnswerType,
} from 'src/app/shared/components/smart-date/smart-date.component';
import { Application } from 'src/app/shared/models/application/application';
import { Question } from 'src/app/shared/models/vcall/question';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { QuestionFilterService } from 'src/app/shared/services/filter/question-filter/question-filter.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import {
  DetailQuestion,
  DrilldownStatus,
} from './../../../../shared/models/vcall/detailQuestion';
import { DoctorLookupAnswer } from 'src/app/shared/models/drLookup';

export enum KEY_CODE {
  RIGHT_ARROW = 'ArrowRight',
  LEFT_ARROW = 'ArrowLeft',
  YES = 'KeyY',
  NO = 'KeyN',
}
@Component({
  selector: 'app-dynamic-details',
  templateUrl: './dynamic-details.component.html',
  styleUrls: ['./dynamic-details.component.scss'],
})
export class DynamicDetailsComponent implements OnInit, OnDestroy {
  @Input() AlimentData = null;
  detailQuestions: DetailQuestion[] = [];
  question: DetailQuestion;
  form: UntypedFormGroup;
  questionIndex = 0;
  isCustom = false;
  customControlAnswer: any = []; // TODO: add model
  answerCollection: any[] = []; // TODO: add model
  existingAilmentAnswerCollection = {}; // of all users
  subscriptions: Array<Subscription> = new Array<Subscription>();
  application: Application;
  maxDate: object;
  minDate: object;
  @Output() actions = new EventEmitter();
  openConfirmationModal = false;
  childAnswersList: any[] = []; // TODO: add model
  selectedElementAnswer: any; // TODO: add model
  isAdditionalTagsChanged = false;
  ifInputFocus = false;
  @ViewChild('drillDownNext') next;
  constructor(
    public appService: ApplicationService,
    public script: ScriptService,
    private filterService: QuestionFilterService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.openConfirmationModal = false;
    const existing = this.appService.GetAnswerForAnswerTags(
      this.AlimentData.answerTag
    );
    if (existing) {
      this.existingAilmentAnswerCollection = existing;
      // look for client
      // look for details (untouch case it will not found)
      // then look for ailment name (untouch case it will not found) (if found, assign those to answercollection array)
      console.log('before ', this.existingAilmentAnswerCollection['value']);

      this.existingAilmentAnswerCollection['value'].map((e) => {
        if (e.clientNo === this.AlimentData.clientNo) {
          if (e.details !== undefined) {
            // get existing answers
            const ailmentIndex = e.details.findIndex(
              (a) => a.ailment === this.AlimentData.ailment
            );
            if (ailmentIndex > -1) {
              // existed details > found ailment obj> update answers
              this.answerCollection = e.details[ailmentIndex].answers;
            } else {
              // existed details but not found ailment obj
              e.details.push({
                ailment: this.AlimentData.ailment,
                answers: [],
                status: DrilldownStatus.Pending,
              });
            }
          } else {
            e.details = [
              {
                ailment: this.AlimentData.ailment,
                answers: [],
                status: DrilldownStatus.Pending,
              },
            ];
            this.answerCollection = [];
          }
        }
      });
    } else {
      console.log(
        'something wrong in previous sections: ailments, before dashboard'
      );
    }

    this.subscriptions.push(
      this.appService.DetailQuestions.subscribe((questionData) => {
        if (
          questionData &&
          questionData.length > 0 &&
          this.AlimentData != null
        ) {
          // construct answertag & options conversion from  string to json
          this.detailQuestions = this.answerTagInit(
            questionData,
            this.AlimentData.ailment
          );
          if (this.detailQuestions && this.detailQuestions.length > 0) {
            this.question = this.detailQuestions[this.questionIndex];
            this.setMaxDate(this.question);
            this.setForm(this.question);
          }
        } else {
          this.detailQuestions = null;
        }
      })
    );

    this.subscriptions.push(
      this.appService.SelectedApplication.subscribe((a) => {
        if (a.id !== 0) {
          this.application = a;
        }
      })
    );

    const date = new Date();
    // For max date
    this.setMaxDate(this.question);
    this.minDate = {
      year: date.getFullYear() - 100,
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  answerTagInit(source: DetailQuestion[], alimentPrefix: string) {
    if (source && this.AlimentData != null) {
      source.map((s) => {
        if (s.details !== null && s.details !== undefined) {
          if (s.details.options !== undefined) {
            s.details.options = this.optionsFormatter(s.details.options);
          }
          if (s.details.groupTag !== undefined) {
            s.answerTag = `${s.details.groupTag}.${s.answerTag}`;
          }
          // merge (update value if already stored in db-api answers)
          const answered = this.answerCollection.find(
            (e) => e.answerTag === s.answerTag
          );
          if (answered) {
            s.value = answered.value;
          }
        }

        if (s.displayWhen != null) {
          const paths = this.optionsFormatter(s.displayWhen);
          if (typeof paths === 'object') {
            s.displayTag = null;
          }
          if (
            s.details !== null &&
            s.details !== undefined &&
            Array.isArray(paths)
          ) {
            s.displayTag = `${s.details.groupTag}.${paths[0].path}`;
          } else {
            s.displayTag = null;
          }
        } else {
          s.displayTag = null;
        }

        if (s.type === 'Doctor') {
          s.value = s.value === null ? new DoctorLookupAnswer() : s.value;
        }
        if (s.type === 'SmartDate') {
          s.value = s.value === null ? {} : s.value;
        }
      });
    }
    return source;
  }

  // function name going to change when dealing with multiple custom controls like customControlsAnswersListner
  getUpdatedDrList(e) {
    this.customControlAnswer = e;
  }

  getMedicationList(data) {
    this.customControlAnswer = data;
  }

  getUpdatedSmartDate(e: SmartDateAnswer) {
    // goNext commmits the answer to answertag.
    this.customControlAnswer = e ? e.dateOption : null;
    if (e) {
      // console.log(e, 'received');
      // this is saving dependent items;
      this.smartDateFormat(e, this.question);
    }
  }

  // differentiate ui & events between custom & dynamic
  customCheck() {
    const customControls = ['Doctor', 'SmartDate', 'Medication']; // list
    return customControls.indexOf(this.question.type) > -1 ? true : false;
  }

  // custom control data validation
  customValidation() {
    if (this.isCustom === true) {
      if (this.question.required === false) {
        return false;
      } else {
        const custValue = this.question.value;
        if (this.question.type === 'Date') {
          return this.customControlAnswer.length > 0 ? false : true;
        }
        if (this.question.type === 'SmartDate') {
          return this.customControlAnswer === null;
        }
        if (this.question.type === 'Doctor') {
          return (
            this.customControlAnswer.list.length === 0 &&
            this.customControlAnswer.isProviderAvailable
          );
        }
        if (this.question.type === 'Medication') {
          return this.customControlAnswer?.length > 0 ? false : true;
        }
        // else {
        // 	// SmartDate
        // 	return this.customControlAnswer === false
        // 		? true
        // 		: Object.keys(this.customControlAnswer).length > 0 ? false : true;
        // }
        // return this.question.value.length > 0 ? false : true;
      }
    }
    return true;
  }

  setForm(question) {
    this.isCustom = this.customCheck();
    this.clearElements();
    const group: any = {};
    if (this.isCustom === false) {
      // only for dynamic, not for custom
      group[question.id] = question.required
        ? new UntypedFormControl(
            this.questionValue(question),
            Validators.required
          )
        : new UntypedFormControl(this.questionValue(question));
    } else {
      if (question.type === 'SmartDate') {
        // do nothing;
      } else {
        // Date
        this.customControlAnswer = this.question.value;
      }
    }

    this.form = new UntypedFormGroup(group);
  }

  questionValue(question) {
    if (question.type === 'Date') {
      return this.displayFormat(question.value);
    }
    return question.value || '';
  }

  clearElements() {
    if (this.form && this.form.controls) {
      Object.keys(this.form.controls).forEach((key) => {
        console.log(key);
        this.form.removeControl(key);
      });
    }
  }

  goBack() {
    const questionDisplay = this.stepper('back');
    this.display(questionDisplay);
    if (this.question) {
      this.setMaxDate(this.question);
    }
  }

  goNext() {
    let move = 'next';
    if (this.question) {
      const currentAnswer = this.form.value[this.question.id];
      if (
        this.question.details &&
        this.question.details.stopAtValue &&
        ((currentAnswer &&
          this.question.details.stopAtValue.toLowerCase() ===
            currentAnswer.toLowerCase()) ||
          this.question.details.stopAtValue.toLowerCase() === '*')
      ) {
        move = 'end';
      }
      const answer =
        this.isCustom === false
          ? this.form.value[this.question.id]
          : this.customControlAnswer;
      const save = this.saveAnswerModal(answer, this.question);
      if (save === true) {
        const questionDisplay = this.stepper(move);
        this.display(questionDisplay);
      }
      this.setMaxDate(this.question);
    } else {
      console.log('close, dissmis modal');
      // submit & dismiss close
    }
  }
  setMaxDate(question) {
    const date = new Date();
    if (question?.details && question?.details['showFutureDate'] === 'true') {
      this.maxDate = {
        year: date.getFullYear() + 10,
        month: 1,
        day: 1,
      };
    } else {
      this.maxDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
    }
  }
  display(questionDisplay) {
    if (Number.isFinite(questionDisplay)) {
      this.question = this.detailQuestions[questionDisplay];
      const que = this.detailQuestions[questionDisplay];
      this.setForm(que);
    } else if (questionDisplay === Infinity) {
      this.question = null;
    }
  }

  saveAnswerModal(answer, question) {
    if (question.type === 'Date') {
      answer = this.ngbFormatDate(answer);
    }
    // if (question.type === 'SmartDate') {
    // 	// alert('smartdate - saveAnswerModal');
    // 	// console.log(answer);
    // 	// smart-date
    // 	// answer = this.smartDateFormat(answer, question);
    // }

    this.detailQuestions.map((q) => {
      if (q.id === question.id) {
        q.value = answer;
      }
    });

    const ans = {
      answerTag: question.answerTag,
      verification: true,
      value: answer,
      prompt: question.text,
    };

    const foundIndex = this.answerCollection.findIndex(
      (x) => x.answerTag === question.answerTag
    );
    console.log(foundIndex);
    const IsAnswerChanged = true;
    if (foundIndex > -1) {
      // update answer to earlier answered
      console.log(this.answerCollection[foundIndex]);
      // extra check
      if (this.answerCollection[foundIndex].value === answer) {
        // answer not changed
        // IsAnswerChanged = false;
        // if (question.type === 'SmartDate') {
        // if (answer === 'General Time Period') {
        // 	IsAnswerChanged = this.isAdditionalTagsChanged;
        // }
        // }
      } else {
        // prompt confirmation logic - not to every question only group
        if (question.details !== null && question.details !== undefined) {
          if (question.details.groupTag !== undefined) {
            // only for group
            this.childAnswersList = [];
            this.childAnswersList = this.identifyAnsweredChilds(
              question.answerTag,
              []
            );
            console.log(this.childAnswersList);
            if (this.childAnswersList.length > 0) {
              // only for group & which are having child answered grater than 0
              // show popup
              this.selectedElementAnswer = answer;
              this.openConfirmationModal = true;
              // break regular logic, go to new logic
              return false;
            }
          }
        }
        this.answerCollection[foundIndex].value = answer;
      }
    } else {
      // fresh answer
      this.answerCollection.push(ans);
    }

    this.saveAnswerChanged(IsAnswerChanged, false);
    return true;
  }

  saveAnswerChanged(IsAnswerChanged, fromPopupChange) {
    if (IsAnswerChanged === true) {
      this.existingAilmentAnswerCollection['value'].map((e) => {
        if (e.clientNo === this.AlimentData.clientNo) {
          if (e.details !== undefined) {
            const ailmentIndex = e.details.findIndex(
              (a) => a.ailment === this.AlimentData.ailment
            );
            if (ailmentIndex > -1) {
              // update answers
              console.log(e.details[ailmentIndex]);
              // status-check
              if (
                e.details[ailmentIndex].status !== DrilldownStatus.Completed &&
                e.details[ailmentIndex].status !== DrilldownStatus.Inprogress
              ) {
                e.details[ailmentIndex].status = DrilldownStatus.Inprogress;
              }

              if (fromPopupChange === true) {
                // from popup - answer change
                if (
                  e.details[ailmentIndex].status === DrilldownStatus.Completed
                ) {
                  e.details[ailmentIndex].status = DrilldownStatus.Inprogress;
                }
              }
              e.details[ailmentIndex].answers = this.answerCollection;
            }
          }
        }
      });

      this.saveToApi(
        this.existingAilmentAnswerCollection['value'],
        this.AlimentData.answerTag
      );
    }
  }

  identifyAnsweredChilds(ReceivedanswerTag, childAnswersList) {
    const locates = this.detailQuestions.filter(
      (que) => que.displayTag === ReceivedanswerTag
    );
    locates.forEach((locateObj) => {
      const locate = locateObj.answerTag;
      if (locate !== undefined) {
        const childs = this.answerCollection.filter(
          (que) => que.answerTag === locate
        );
        childs.forEach((child) => {
          if (child !== undefined) {
            childAnswersList.push(child);
            // look for childs next levels if any
            return this.identifyAnsweredChilds(
              child.answerTag,
              childAnswersList
            );
          }
        });
      }
    });
    return childAnswersList;
  }

  saveToApi(answer, tag) {
    this.appService.RegisterAnswerByTag(true, answer, tag, null, null);
    this.appService.PersistAnswers();
  }

  end() {
    let endUpdate = false;
    this.existingAilmentAnswerCollection['value'].map((e) => {
      if (e.clientNo === this.AlimentData.clientNo) {
        if (e.details !== undefined) {
          const ailmentIndex = e.details.findIndex(
            (a) => a.ailment === this.AlimentData.ailment
          );

          if (ailmentIndex > -1) {
            if (e.details[ailmentIndex].status !== DrilldownStatus.Completed) {
              endUpdate = true;
              e.details[ailmentIndex].status = DrilldownStatus.Completed;
            }
          }
        }
      }
    });

    if (endUpdate) {
      this.saveToApi(
        this.existingAilmentAnswerCollection['value'],
        this.AlimentData.answerTag
      );
    }
  }

  stepper(move) {
    if (move === 'next') {
      if (this.detailQuestions.length > this.questionIndex + 1) {
        this.questionIndex = this.questionIndex + 1;
      } else {
        // end forward
        this.end();
        return Infinity;
      }
    } else if (move === 'end') {
      this.end();
      return Infinity;
    } else {
      // back
      if (this.questionIndex - 1 >= 0) {
        this.questionIndex = this.questionIndex - 1;
      } else {
        // end backward
        return -Infinity;
      }
    }

    const upcoming: DetailQuestion = this.detailQuestions[this.questionIndex];
    if (upcoming.displayWhen != null) {
      const verif = this.displayWhenFilter(upcoming);
      if (verif === false) {
        // false, look for next one
        return this.stepper(move);
      } else {
        return this.questionIndex;
      }
    } else {
      return this.questionIndex;
    }
  }

  displayWhenFilter(upcoming) {
    // talks to filterservice & groupanswertag filter
    const display = this.filterService.Apply({
      answers: this.answerCollection,
      application: this.application,
      mode: null,
      question: upcoming, // next question
    });
    return display;
  }

  optionsFormatter(options) {
    if (typeof options === 'string') {
      return JSON.parse(options);
    }
    return options;
  }

  tagsConstructor(tag) {
    if (tag !== null) {
      const chain = [];
      tag.split('.').map((i) => chain.push({ tag: i }));
      return chain;
    }
    return tag;
  }

  displayFormat(value) {
    if (typeof value === 'object') {
      // remove
      return value;
    }
    if (typeof value === 'string') {
      const date = new Date(value);
      const obj = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
      return obj;
    }
    return value;
  }

  // clearSmartDateSwitch(tag){
  //   const foundIndex = this.answerCollection.findIndex(
  //     (x) => x.answerTag === question.answerTag
  //   );

  //   this.answerCollection = this.answerCollection.filter(
  //     (a) => a.answerTag !== tag
  //   );
  // }

  additionalTagsManage(tag, dataValue, question, action = '') {
    this.isAdditionalTagsChanged = false;
    const foundIndex = this.answerCollection.findIndex(
      (x) => x.answerTag === tag
    );

    if (foundIndex > -1) {
      // existed : update or delete
      // update
      // alert('update');
      if (
        action !== 'clear' &&
        this.answerCollection[foundIndex].value !== dataValue
      ) {
        // answer not changed
        // IsAnswerChanged = false;
        // alert('additinal updates');
        this.isAdditionalTagsChanged = true;
        this.answerCollection[foundIndex].value = dataValue;
        return this.isAdditionalTagsChanged;
      }

      // clear
      if (action === 'clear') {
        // Removing the element from the collection.
        this.answerCollection.forEach((item, index) => {
          if (item.answerTag === tag) {
            this.answerCollection.splice(index, 1);
          }
        });
      }
    } else {
      // new-insert
      if (action !== 'clear') {
        const ans = {
          answerTag: tag,
          verification: true,
          value: dataValue,
          prompt: question.text,
        };
        this.answerCollection.push(ans);
      }
    }
    return this.isAdditionalTagsChanged;
  }

  // TODO: This is too kludgy need to better this
  smartDateFormat(data: SmartDateAnswer, question) {
    switch (data.dateOption) {
      case SmartDateAnswerType.DontKnow: {
        // remove if dont know.
        this.additionalTagsManage(
          `${question.answerTag}.Period`,
          data.period,
          question,
          'clear'
        );
        this.additionalTagsManage(
          `${question.answerTag}.Notes`,
          data.notes,
          question,
          'clear'
        );
        this.additionalTagsManage(
          `${question.answerTag}.Date`,
          data.date,
          question,
          'clear'
        );
        break;
      }
      case SmartDateAnswerType.DateRange: {
        // General Time Period
        // insert 2 jsons or update
        this.additionalTagsManage(
          `${question.answerTag}.Date`,
          data.notes,
          question,
          'clear'
        );
        this.additionalTagsManage(
          `${question.answerTag}.Period`,
          data.period,
          question
        );
        this.additionalTagsManage(
          `${question.answerTag}.Notes`,
          data.notes,
          question
        );
        break;
      }
      case SmartDateAnswerType.ExactDate: {
        // Specific Date
        this.additionalTagsManage(
          `${question.answerTag}.Period`,
          data.period,
          question,
          'clear'
        );
        this.additionalTagsManage(
          `${question.answerTag}.Notes`,
          data.notes,
          question,
          'clear'
        );
        this.additionalTagsManage(
          `${question.answerTag}.Date`,
          data.formattedDate(),
          question
        );
        break;
      }
    }
  }

  ngbFormatDate(date) {
    if (date === null) {
      return date;
    }
    let month = '' + date.month;
    let day = '' + date.day;
    const year = date.year;
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [month, day, year].join('-');
  }

  resetInstance() {
    this.clearElements();
    this.AlimentData = null;
    this.detailQuestions = null;
    this.question = null;
    this.questionIndex = 0;
    this.isCustom = false;
    this.customControlAnswer = [];
    this.answerCollection = [];
    this.existingAilmentAnswerCollection = {};
    this.subscriptions.forEach((a) => a.unsubscribe());
  }

  closeFromDynamic() {
    this.appService.CleanDetailQuestions();
    this.resetInstance();
    this.actions.emit(null);
  }

  ngOnDestroy() {
    console.log('destroy reached');
    this.resetInstance();
  }

  confirmation(action: any) {
    if (action === 'Done') {
      const foundIndex = this.answerCollection.findIndex(
        (x) => x.answerTag === this.question.answerTag
      );
      this.answerCollection[foundIndex].value = this.selectedElementAnswer;
      this.childAnswersList.forEach((child) => {
        console.log(child.answerTag);
        this.detailQuestions.map((que) => {
          if (que.answerTag === child.answerTag) {
            // que.value = que.type === 'Doctor' ? [] : null;
            if (que.type === 'Doctor') {
              que.value = [];
            } else if (que.type === 'SmartDate') {
              que.value = {};
            } else {
              que.value = null;
            }
          }
        });
        this.answerCollection = this.answerCollection.filter(
          (a) => a.answerTag !== child.answerTag
        );
      });

      this.saveAnswerChanged(true, true);

      const questionDisplay = this.stepper('next');
      this.display(questionDisplay);

      this.openConfirmationModal = false;
    } else {
      this.openConfirmationModal = false;
    }
  }
  @HostListener('mousedown', ['$event'])
  onMousedown(event) {
    const tag = document.activeElement.tagName;
    const isTagMatched = tag !== 'INPUT' && tag !== 'TEXTAREA';
    if (!isTagMatched) {
      this.ifInputFocus = false;
      this.keyFunction('navigation', event);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (
      event.key === KEY_CODE.RIGHT_ARROW ||
      event.key === KEY_CODE.LEFT_ARROW
    ) {
      this.keyFunction('navigation', event);
    } else if (event.code === KEY_CODE.YES || event.code === KEY_CODE.NO) {
      {
        this.keyFunction('booleans', event);
      }
    }
  }

  keyFunction(type, event) {
    this.ifInputFocus = false;
    const tag = document.activeElement.tagName;
    const isTagMatched = tag !== 'INPUT' && tag !== 'TEXTAREA';
    if (type === 'navigation') {
      if (this.next.nativeElement.disabled === false) {
        if (event.key === KEY_CODE.RIGHT_ARROW) {
          if (isTagMatched) {
            this.goNext();
          } else {
            this.ifInputFocus = true;
          }
        }
      } else {
        if (event.key === KEY_CODE.RIGHT_ARROW) {
          this.toastService.error('', 'Please select an option to proceed');
        }
      }

      if (event.key === KEY_CODE.LEFT_ARROW) {
        if (isTagMatched) {
          this.goBack();
        } else {
          this.ifInputFocus = true;
        }
      }
    } else {
      // yes no boolean keys
      if (this.question.type === 'Boolean') {
        const control = this.question.id;

        if (event.code === KEY_CODE.YES) {
          if (isTagMatched) {
            if (this.form.value !== 'y') {
              this.form.patchValue({ [control]: 'y' });
            }
          }
        } else {
          // if (event.code === KEY_CODE.NO) {
          if (isTagMatched) {
            if (this.form.value !== 'n') {
              this.form.patchValue({ [control]: 'n' });
            }
          }
        }
      }
    }
  }

  sortTheBooleanOptions(question: DetailQuestion) {
    if (question && question.type === 'Boolean') {
      question.details.options = question.details.options.sort((a, b) =>
        a.key.toLowerCase().localeCompare(b.key.toLowerCase())
      );
    }
    return question.details.options;
  }
}
