<div class="container-fluid">
  <div class="row subhdr-row pt-2">
    <div class="col-12">
      <div class="row">
        <ul class="nav nav-tabs">
          <li class="nav-item mr-1" (click)="getCurrentTab('video')">
            <a
              href="#"
              id="log-tab"
              data-toggle="tab"
              role="tab"
              class="nav-link active"
              >Training Videos</a
            >
          </li>
          <li class="nav-item mr-1" (click)="getCurrentTab('pdf')">
            <a
              href="#"
              id="policy-tab"
              data-toggle="tab"
              role="tab"
              class="nav-link"
              >Training Documents</a
            >
          </li>
          <li class="nav-item mr-1" (click)="getCurrentTab('notes')">
            <a
              href="#"
              id="policy-tab"
              data-toggle="tab"
              role="tab"
              class="nav-link"
              >Release Notes</a
            >
          </li>
          <li class="nav-item" (click)="getCurrentTab('businessNotes')">
            <a
              href="#"
              id="policy-tab"
              data-toggle="tab"
              role="tab"
              class="nav-link"
              >Business Release Notes</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <ng-container *ngIf="contentType === 'video'">
      <div class="col-3 left-nav py-4 pl-5">
        <div class="row">
          <h6 class="col pb-2 mr-5 mb-3 border-bottom text-center">
            VCall 2.0 Training Videos
          </h6>
        </div>
        <div class="row" *ngFor="let content of videos">
          <div
            class="col pt-2 pb-2 mr-5"
            (click)="eachVideoContent(content)"
            [ngClass]="{ 'ui-active': selectedDocument === content }"
          >
            <i class="bi bi-play-circle align-middle pr-2"></i>
            <span class="align-middle">{{ content.title }}</span>
          </div>
        </div>
      </div>
      <div class="col p-2" style="height: calc(100vh - 118px);">
        <div class="cnt-area py-4 px-5" *ngIf="videos?.length > 0">
          <div class="text-center mb-4">
            <iframe
              id="video-player-1"
              title="{{ selectedDocument?.title }}"
              width="100%"
              height="600"
              [src]="selectedDocument?.url | safe: 'resourceUrl'"
              allowfullscreen
              style="border: none;"
            ></iframe>
          </div>
          <h5>{{ selectedDocument?.title }}</h5>
          <p>{{ selectedDocument?.title }}</p>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="contentType === 'pdf'">
    <app-user-training-documents
      [Documents]="documents"
    ></app-user-training-documents>
  </ng-container>
  <ng-container *ngIf="contentType === 'notes'">
    <app-release-notes></app-release-notes>
  </ng-container>
  <ng-container *ngIf="contentType === 'businessNotes'">
    <app-business-release-notes></app-business-release-notes>
  </ng-container>
</div>
