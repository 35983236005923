import { Answer } from 'src/app/shared/models/application/answer';
import { BaseApplicantFilter } from '../../applicant-filter/filters/applicant-attribute-filter';
import { FilterParameter } from '../question-filter.service';
import { BaseFilter } from './base-filter';

export class ApplicantGroupFilter extends BaseApplicantFilter {
  Type = 'ApplicantGroupFilter';
  Filter(parameter: FilterParameter) {
    let canDisplay = false;
    let filter = parameter.config;
    let answer: Answer = parameter.answers.find(
      (a) => a.answerTag === filter.path
    );
    let qualifiedAnswer = answer.value.find(
      (a) => a.clientNo === parameter.applicant.clientNo
    );
    let parentAnswer =
      qualifiedAnswer.values !== undefined &&
      qualifiedAnswer.values !== null &&
      qualifiedAnswer.values.length > 0
        ? qualifiedAnswer.values.find((v) => v.key === filter.comparisonValue)
        : undefined;
    if (qualifiedAnswer && parentAnswer !== undefined) {
      canDisplay =
        qualifiedAnswer.applicable.toString() === filter.value &&
        parentAnswer.value.applicable === filter.value;
    }
    return canDisplay;
  }
}

export class ApplicantQuestionGroupFilter extends BaseFilter {
  Type = 'ApplicantQuestionGroupFilter';
  applicantQuestionGroupFilter = new ApplicantGroupFilter();
  Filter(parameter: FilterParameter) {
    let showQuestion = false;
    parameter.application.applicants.forEach((a) => {
      parameter.applicant = a;
      if (this.applicantQuestionGroupFilter.Filter(parameter)) {
        showQuestion = true;
      }
    });
    return showQuestion;
  }
}
