export class EffectiveDateRules {
  defaultValue: number;
  rules: EffectiveDateRule[];
}

export class EffectiveDateRule {
  startMonth: string;
  startDate: string;
  endMonth: string;
  endDate: string;
  value: string;
  mode?: EffectiveDateRulesMode;
}

export enum EffectiveDateRulesMode {
  sliding = 'sliding',
  fixed = 'fixed',
}
