import { Injectable } from '@angular/core';
import {
  FilterConfiguration,
  FilterParameter,
  FilterMode,
  FilterResult,
  DisplayWhen,
} from '../question-filter/question-filter.service';
import {
  ApplicantAttributeFilter,
  BaseApplicantFilter,
} from './filters/applicant-attribute-filter';
import { ApplicantComparisonFilter } from '../question-filter/filters/attribute-comparison-filter';
import { RXProfileFilter } from './filters/rx-profile-filter';
import { ApplicantGroupFilter } from '../question-filter/filters/applicant-group-filter';

@Injectable({
  providedIn: 'root',
})
export class ApplicantFilterService {
  private filters: Array<BaseApplicantFilter> =
    new Array<BaseApplicantFilter>();

  constructor() {
    this.filters.push(new ApplicantAttributeFilter());
    this.filters.push(new ApplicantComparisonFilter());
    this.filters.push(new RXProfileFilter());
    this.filters.push(new ApplicantGroupFilter());
  }

  /**
   * @description filter the application Data
   */
  public Apply(parameters: FilterParameter): boolean {
    let filterMode = FilterMode.AND;

    let configuredFilters: FilterConfiguration[] =
      new Array<FilterConfiguration>();

    if (parameters.filterJson) {
      const displayWhenObject = JSON.parse(parameters.filterJson);
      let displayWhen: DisplayWhen = null;

      // displayWhen could just be a list of filters.
      if (Array.isArray(displayWhenObject)) {
        displayWhen = { mode: FilterMode.AND, filters: displayWhenObject };
      } else {
        // displayWhen could be of the format {mode : <mode> , filters : <filters>}
        displayWhen = displayWhenObject;
      }

      // if mode not defined AND is the default
      if (displayWhen.mode === undefined || displayWhen.mode === null) {
        displayWhen.mode = FilterMode.AND;
      }

      configuredFilters = displayWhen.filters;
      filterMode = displayWhen.mode;
    }

    // If no filters
    if (configuredFilters.length === 0) {
      return true;
    }

    let result: FilterResult[];

    result = configuredFilters.map((cf) => {
      const typeFilter = this.filters.find((ff) => ff.Type === cf.type);
      if (typeFilter) {
        parameters.config = cf;
        return typeFilter.Apply(parameters);
      }
    });

    if (
      filterMode === FilterMode.AND ||
      filterMode === FilterMode.INVERTED_AND
    ) {
      const displayWhen = result.every((r) => r.canDisplay === true);
      return FilterMode.AND ? displayWhen : !displayWhen;
    } else if (
      filterMode === FilterMode.OR ||
      filterMode === FilterMode.INVERTED_OR
    ) {
      const displayWhen = result.some((r) => r.canDisplay === true);
      return FilterMode.OR ? displayWhen : !displayWhen;
    }
  }
}
