import { ApplicationService } from '../shared/services/application/application.service';
import { AppointmentService } from '../shared/services/appointment/appointment.service';
import { UserProfileService } from '../shared/services/user/user-profile.service.service';

export class LogData {
  applicationId: number;
  appointmentId: number;
  resourceId: number;
  QuestionId: number;
  userName: string;
  constructor(
    private applicationService: ApplicationService,
    private appointmentService: AppointmentService,
    private userProfileService: UserProfileService
  ) {
    this.getApplicationId();
    this.getAppointemntId();
    this.getUserDetails();
  }
  getApplicationId() {
    this.applicationId = this.applicationService.currentApplicationId
  }

  getAppointemntId() {
    this.appointmentId = this.appointmentService.currentAppointmentId;
    this.resourceId = this.appointmentService.currentResourceId;
  }

  getUserDetails() {
    const userDetails = this.userProfileService.getCallzoneUser();
    this.userName = userDetails?.name;
  }
}
