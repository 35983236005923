<div class="row d-flex justify-content-center">
  <div class="col-3 pt-4 text-center">
    <select
      [ngModel]="resourceId"
      (change)="changeuser($event)"
      class="form-control"
    >
      <option value="5212">HQ\perezj</option>
      <option value="3779">HQ\pereiraj</option>
      <option value="3790">HQ\ferreirad</option>
      <option value="3802">HQ\netof</option>
      <option value="4433">HQ\Gloverr</option>
    </select>
  </div>
  <div class="col-3 pt-4">
    <input type="text" class="form-control" [(ngModel)]="resourceId" />
  </div>
  <div class="col-3 pt-4">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="appId"
      placeholder="appId ex: 4002382 / 4002397"
    />
  </div>
</div>
<div class="row">
  <div class="col-4 offset-4 pt-4 text-center">
    <ngb-datepicker
      #dp
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="1"
      [navigation]="'select'"
      [showWeekNumbers]="false"
      [outsideDays]="'visible'"
    >
    </ngb-datepicker>
  </div>
</div>
<div class="row">
  <div class="col-4 offset-4 pt-4 text-center">
    <button
      class="btn btn-primary btn-sm"
      (click)="launch(resourceId, appId, from, to)"
    >
      Launch VCall Application
    </button>
  </div>
</div>
<br />
<br />
<br />
<div class="row offset-2">
  <table class="table">
    <tr>
      <th>Application Id</th>
      <th>Resource Id</th>
      <th>Description</th>
      <th>StartDate</th>
      <th>EndDate</th>
    </tr>
    <tr>
      <td>
        <button
          class="btn btn-primary"
          (click)="launch(5212, '4002394', '2020-11-05', '2020-11-06')"
        >
          4002394
        </button>
      </td>
      <td>5212</td>
      <td>Association AppTypeId = ?</td>
      <td>2020-11-05</td>
      <td>2020-11-06</td>
    </tr>
    <tr>
      <td>
        <button
          class="btn btn-primary"
          (click)="launch(5212, '4003418', '2015-11-05', '2020-11-06')"
        >
          4003418
        </button>
      </td>
      <td>5212</td>
      <td>Medical History</td>
      <td>2020-11-05</td>
      <td>2020-11-06</td>
    </tr>
  </table>
</div>

<div class="row p-4">
  <h3>Roles Impersonation</h3>
  <div class="col-12" *ngFor="let role of allRoles">
    <span *ngIf="!role.applied">
      <button (click)="assumeRole(role.role)">{{ role.role }}</button>
    </span>
    Applied = {{ role.applied }}
    {{ role.role }}
  </div>
</div>

<div class="row p-4">
  <input
    type="text"
    [(ngModel)]="applicationId"
    class="mr-3"
    placeholder="appId ex: 4002382 / 4002397"
  />
  <button class="btn btn-primary btn-sm" (click)="launchRecall()">
    Launch ReCall Dashboard
  </button>
  <button class="btn btn-primary btn-sm ml-3" (click)="dashboard()">
    Launch VCall Dashboard
  </button>
</div>

<div class="row p-4">
  <h3>Current</h3>
  <div class="col-12" *ngFor="let matrixItem of permissions">
    {{ matrixItem.key }}
    <ul>
      <li class="col-12" *ngFor="let p of matrixItem.value">
        {{ p }}
      </li>
    </ul>
  </div>
</div>
<div class="row p-4">
  <h3>User Roles</h3>
  <div class="col-12" *ngFor="let role of roles">
    {{ role }}
  </div>
</div>

<div class="row p-4">
  <h3>Current Roles</h3>
  <div class="col-12" *ngFor="let role of roles">
    {{ role }}
  </div>
</div>
