import { Injectable } from '@angular/core';
import { HandledHttpClient } from './api/handledHttpClient';
import { Address } from '../models/address';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UspsService {
  baseUrl: string;
  constructor(
    private handledHttp: HandledHttpClient,
    private http: HttpClient
  ) {
    this.baseUrl = environment.config.apis.usps;
  }

  /**
   * @param zip zip code as a string
   * @description get the usps address
   */
  public getUsps(zip: string) {
    return this.handledHttp.get(`${this.baseUrl}/CityAndStateLookup/${zip}`);
  }

  /**
   * @param address address
   * @description check is address is valid or not
   */
  public isAddressValid(address: Address) {
    const add = {
      street: address.streetAddress,
      city: address.city,
      state: address.state,
      zipCode: address.zipCode,
    };
    return this.handledHttp.post(`${this.baseUrl}/isAddressValid`, add);
  }
}
