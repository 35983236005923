export enum DialingType {
  voiceScript = 'VoiceScript',
  initialDialing = 'InitialDialing',
  followupDialing = 'FollowupDialing',
  clockTimer = 'ClockTimer',
  regNoShow = 'RegNoShow',
  isSelectedAppointment = 'IsSelectedAppointment',
}

export enum VoiceMailModelInfo {
  voiceMailData = 'VoicemailData',
  answered = 'Answered',
}

export enum CallStatus {
  answered = 'Answered',
  NoResponse = 'NoResponse',
  WrongNumber = 'WrongNumber',
}
