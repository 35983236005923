<table *ngIf="family" class="table table-striped table-bordered">
  <thead>
    <tr>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Middle Name</th>
      <th>DOB</th>

      <th *ngIf="!details.hideOccupation">Occupation</th>
      <th>Gender</th>
      <th *ngIf="details.mode !== 'lightDemographic'">Height</th>
      <th *ngIf="details.mode !== 'lightDemographic'">Weight</th>
      <th *ngIf="details.mode !== 'lightDemographic'">SSN</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ primaryApplicant.firstName }}</td>
      <td>{{ primaryApplicant.lastName }}</td>
      <td>{{ primaryApplicant.middleName }}</td>
      <td>{{ primaryApplicant.birthDate | date : "MM-dd-yyyy" }}</td>
      <td *ngIf="!details.hideOccupation">{{ primaryApplicant.occupation }}</td>
      <td>{{ primaryApplicant.gender }}</td>
      <td *ngIf="details.mode !== 'lightDemographic'">
        {{ primaryApplicant.height }}
      </td>
      <td *ngIf="details.mode !== 'lightDemographic'">
        {{ primaryApplicant.weight }}
      </td>
      <td *ngIf="details.mode !== 'lightDemographic'">
        <div *ngIf="primaryApplicant && primaryApplicant.ssn !== ''">
          <span *ngIf="primarySsnContent">{{
            primaryApplicant.ssn | mask : 4
          }}</span>
          <span *ngIf="!primarySsnContent">{{ primaryApplicant.ssn }}</span>
          <i
            class="fa fa-solid ml-2 btn btn-sm btn-outline-secondary"
            [ngClass]="{
              'fa-eye-slash': primarySsnContent,
              'fa-eye': !primarySsnContent
            }"
            (click)="primaryApplicantSsnMasking()"
            aria-hidden="true"
          ></i>
        </div>
      </td>
      <td>
        <button class="btn btn-primary btn-md" (click)="openReuseModal(-1)">
          Edit
        </button>
      </td>
    </tr>
    <tr *ngFor="let applicant of family; let i = index">
      <td>{{ applicant.firstName }}</td>
      <td>{{ applicant.lastName }}</td>
      <td>{{ applicant.middleName }}</td>
      <td>{{ applicant.birthDate | date : "MM-dd-yyyy" }}</td>
      <td *ngIf="!details.hideOccupation">{{ applicant.occupation }}</td>
      <td>{{ applicant.gender }}</td>
      <td *ngIf="details.mode !== 'lightDemographic'">
        {{ applicant.height }}
      </td>
      <td *ngIf="details.mode !== 'lightDemographic'">
        {{ applicant.weight }}
      </td>
      <td *ngIf="details.mode !== 'lightDemographic'">
        <div *ngIf="applicant && applicant.ssn !== ''">
          <span *ngIf="!familySsnMaskingContent[i]"
            >{{ applicant.ssn | mask : 4 }}
          </span>
          <span *ngIf="familySsnMaskingContent[i]">{{ applicant.ssn }} </span>
          <i
            class="fa fa-solid btn ml-2 btn btn-sm btn-outline-secondary"
            [ngClass]="{
              'fa-eye-slash': !familySsnMaskingContent[i],
              'fa-eye': familySsnMaskingContent[i]
            }"
            aria-hidden="true"
            (click)="familyApplicantSsnMasking(i)"
          ></i>
        </div>
      </td>
      <td>
        <button class="btn btn-primary btn-md" (click)="openReuseModal(i)">
          Edit
        </button>
      </td>
    </tr>
  </tbody>
</table>

<div
  *ngIf="question.instructions"
  class="col-sm-12 alert alert-warning text-center mt-3"
  role="alert"
>
  {{ question.instructions }}
</div>

<app-modal
  [open]="openModal"
  [buttonsConfig]="{
    modalMode: 'enabledButtonForm',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="ModalAction($event, f)"
>
  <div title>
    <h4
      class="modal-title"
      id="modal-basic-title"
      *ngIf="primaryApplicant && applicantIndex === -1"
    >
      Edit Applicant ({{ primaryApplicant.firstName }}
      {{ primaryApplicant.lastName }})
    </h4>
    <div *ngIf="applicantIndex !== -1 && family[applicantIndex]">
      <h4 class="modal-title" id="modal-basic-title">
        Edit Applicant ({{ family[applicantIndex]?.firstName }}
        {{ family[applicantIndex]?.lastName }})
      </h4>
    </div>
  </div>
  <div body>
    <form #f="ngForm">
      <ng-container *ngIf="editApplicant">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="firstName"
                >First Name <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  name="firstName"
                  id="firstName"
                  [(ngModel)]="editApplicant.firstName"
                  #firstName="ngModel"
                  required
                  class="form-control"
                  placeholder="First Name"
                />
              </div>
              <div
                *ngIf="
                  firstName.invalid &&
                  (firstName.dirty || firstName.touched || saveClicked)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="firstName.errors.required">
                  First Name is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="middleName">Middle Name</label>
              <div class="input-group">
                <input
                  name="middleName"
                  id="middleName"
                  [(ngModel)]="editApplicant.middleName"
                  #middleName="ngModel"
                  class="form-control"
                  placeholder="Middle Name"
                />
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="lastName"
                >Last Name <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  name="lastName"
                  id="lastName"
                  [(ngModel)]="editApplicant.lastName"
                  #lastName="ngModel"
                  required
                  class="form-control"
                  placeholder="Last Name"
                />
              </div>
              <div
                *ngIf="
                  lastName.invalid &&
                  (lastName.dirty || lastName.touched || saveClicked)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="lastName.errors.required">
                  Last Name is required.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="birthDate">DOB <span class="text-danger">*</span></label>
          <div class="input-group">
            <input
              name="birthDate"
              id="birthDate"
              ngbDatepicker
              #d="ngbDatepicker"
              [ngModel]="tempBirthDate"
              #birthDate="ngModel"
              [maxDate]="minDate"
              [minDate]="{ year: 1900, month: 1, day: 1 }"
              (dateSelect)="onDateSelection($event)"
              required
              class="form-control"
              placeholder="DOB"
              readonly
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary fa fa-calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
          </div>
          <div
            *ngIf="
              birthDate.invalid &&
              (birthDate.dirty || birthDate.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="birthDate.errors.required">DOB is required.</div>
          </div>
        </div>
        <div class="form-group">
          <div>
            <label for="gender" class="radio-inline mr-3"
              >Gender <span class="text-danger">*</span></label
            >
            <label class="radio-inline mr-3">
              <input
                type="radio"
                id="gender"
                required
                name="gender"
                value="M"
                [(ngModel)]="editApplicant.gender"
                #gender="ngModel"
                name="gender"
              />
              Male</label
            >
            <label class="radio-inline">
              <input
                type="radio"
                id="gender"
                required
                name="gender"
                value="F"
                [(ngModel)]="editApplicant.gender"
                #gender="ngModel"
                name="gender"
              />
              Female</label
            >
          </div>
          <div
            *ngIf="
              gender.invalid && (gender.dirty || gender.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="gender.errors.required">Gender is required.</div>
          </div>
        </div>
        <div class="row" *ngIf="details.mode !== 'lightDemographic'">
          <div class="col-6">
            <div class="form-group">
              <label for="height"
                >Height <span class="text-danger">*</span></label
              >
              <select
                class="form-control"
                id="height"
                name="height"
                [(ngModel)]="editApplicant.height"
                #height="ngModel"
                placeholder="Height"
                required
              >
                <option value="null">Choose...</option>
                <!-- <option *ngFor="let height of personHeights" [ngValue]="height">{{
                  height
                }}</option> -->
                <option value="2' 0''">2' 0''</option>
                <option value="2' 1''">2' 1''</option>
                <option value="2' 2''">2' 2''</option>
                <option value="2' 3''">2' 3''</option>
                <option value="2' 4''">2' 4''</option>
                <option value="2' 5''">2' 5''</option>
                <option value="2' 6''">2' 6''</option>
                <option value="2' 7''">2' 7''</option>
                <option value="2' 8''">2' 8''</option>
                <option value="2' 9''">2' 9''</option>
                <option value="2' 10''">2' 10''</option>
                <option value="2' 11''">2' 11''</option>
                <option value="3' 0''">3' 0''</option>
                <option value="3' 1''">3' 1''</option>
                <option value="3' 2''">3' 2''</option>
                <option value="3' 3''">3' 3''</option>
                <option value="3' 4''">3' 4''</option>
                <option value="3' 5''">3' 5''</option>
                <option value="3' 6''">3' 6''</option>
                <option value="3' 7''">3' 7''</option>
                <option value="3' 8''">3' 8''</option>
                <option value="3' 9''">3' 9''</option>
                <option value="3' 10''">3' 10''</option>
                <option value="3' 11''">3' 11''</option>
                <option value="4' 0''">4' 0''</option>
                <option value="4' 1''">4' 1''</option>
                <option value="4' 2''">4' 2''</option>
                <option value="4' 3''">4' 3''</option>
                <option value="4' 4''">4' 4''</option>
                <option value="4' 5''">4' 5''</option>
                <option value="4' 6''">4' 6''</option>
                <option value="4' 7''">4' 7''</option>
                <option value="4' 8''">4' 8''</option>
                <option value="4' 9''">4' 9''</option>
                <option value="4' 10''">4' 10''</option>
                <option value="4' 11''">4' 11''</option>
                <option value="5' 0''">5' 0''</option>
                <option value="5' 1''">5' 1''</option>
                <option value="5' 2''">5' 2''</option>
                <option value="5' 3''">5' 3''</option>
                <option value="5' 4''">5' 4''</option>
                <option value="5' 5''">5' 5''</option>
                <option value="5' 6''">5' 6''</option>
                <option value="5' 7''">5' 7''</option>
                <option value="5' 8''">5' 8''</option>
                <option value="5' 9''">5' 9''</option>
                <option value="5' 10''">5' 10''</option>
                <option value="5' 11''">5' 11''</option>
                <option value="6' 0''">6' 0''</option>
                <option value="6' 1''">6' 1''</option>
                <option value="6' 2''">6' 2''</option>
                <option value="6' 3''">6' 3''</option>
                <option value="6' 4''">6' 4''</option>
                <option value="6' 5''">6' 5''</option>
                <option value="6' 6''">6' 6''</option>
                <option value="6' 7''">6' 7''</option>
                <option value="6' 8''">6' 8''</option>
                <option value="6' 9''">6' 9''</option>
                <option value="6' 10''">6' 10''</option>
                <option value="6' 11''">6' 11''</option>
                <option value="7' 0''">7' 0''</option>
              </select>
              <div
                *ngIf="
                  height.invalid &&
                  (height.dirty || height.touched || saveClicked)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="height.errors.required">Height is required.</div>
              </div>
            </div>
          </div>
          <div class="col-6" *ngIf="details.mode !== 'lightDemographic'">
            <div class="form-group">
              <label for="weight"
                >Weight <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  id="weight"
                  class="form-control"
                  name="weight"
                  required
                  type="number"
                  [(ngModel)]="editApplicant.weight"
                  #weight="ngModel"
                  placeholder="Weight"
                />
              </div>
              <div
                *ngIf="
                  weight.invalid &&
                  (weight.dirty || weight.touched || saveClicked)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="weight.errors.required">Weight is required.</div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="!details.hideOccupation">
          <label for="occupation"
            >Occupation <span class="text-danger">*</span></label
          >
          <div class="input-group">
            <input
              id="occupation"
              class="form-control"
              name="occupation"
              required
              type="text"
              [(ngModel)]="editApplicant.occupation"
              #occupation="ngModel"
              placeholder="Occupation"
            />
          </div>
          <div
            *ngIf="
              occupation.invalid &&
              (occupation.dirty || occupation.touched || saveClicked)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="occupation.errors.required">
              Occupation is required.
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="details.mode !== 'lightDemographic'">
          <label for="ssn">SSN </label>
          <div class="input-group">
            <input
              id="ssn"
              class="form-control"
              name="ssn"
              type="text"
              [(ngModel)]="editApplicant.ssn"
              #ssn="ngModel"
              pattern="^[0-9]{3}-[0-9]{2}-[0-9]{4}$"
              placeholder="SSN"
              mask="000-00-0000"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
            />
          </div>
          <div
            *ngIf="ssn.invalid && (ssn.dirty || ssn.touched || saveClicked)"
            class="mt-1 text-danger small"
          >
            <div *ngIf="ssn.errors.pattern">Invalid SSN.</div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</app-modal>
