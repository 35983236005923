<!-- <label
      class="pl-2 pr-4 m-0"
      [ngClass]="{
        'btn-success': updatedDoctorList.isProviderNotAvailable === false,
        'btn-outline-success': updatedDoctorList.isProviderNotAvailable === true
      }"
      ><input
        class="form-check-input btn"
        type="radio"
        required
        name="provider"
        id="providerAvailable"
        [value]="false"
        [checked]="updatedDoctorList.isProviderNotAvailable === false"
        (change)="optionChanged(false)"
        style="visibility: hidden;"
      />
      Yes
    </label>


    <label
      class="cursor-pointer btn "
      [ngClass]="{
        'btn-danger': updatedDoctorList.isProviderNotAvailable === true,
        'btn-outline-danger': updatedDoctorList.isProviderNotAvailable === false
      }"
      ><input
        class="form-check-input btn"
        type="radio"
        required
        name="provider"
        id="providerNotAvailable"
        [value]="true"
        [checked]="updatedDoctorList.isProviderNotAvailable === true"
        (change)="optionChanged(true)"
        style="visibility: hidden;"
      />
      No
    </label> -->
<div class="row justify-content-end mt-3 m-0" *ngIf="showProviderConfirmation">
  <label class="hover-success" for="introductory-1a">
    <div
      [ngClass]="{
        'btn-success': updatedDoctorList.isProviderAvailable === true
      }"
      class="btn border border-success btn-check-next carousel-next first-letter-yes"
      (click)="optionChanged(true)"
    >
      <input
        class="form-check-input"
        [style.visibility]="'hidden'"
        type="radio"
        name="boolean-1"
        #booleanyes
        value="true"
        id="introductory-1a"
      />Yes
    </div>
  </label>
  <label class="ml-3 hover-danger" for="introductory-1b">
    <div
      [ngClass]="{
        'btn-danger': updatedDoctorList.isProviderAvailable === false
      }"
      class="btn border border-danger btn-check-next carousel-next first-letter-no"
      (click)="optionChanged(false)"
    >
      <input
        class="form-check-input"
        [style.visibility]="'hidden'"
        type="radio"
        name="boolean-1"
        #booleanno
        value="false"
        id="introductory-1b"
      />No
    </div>
  </label>
</div>

<div class="container pb-2" *ngIf="updatedDoctorList.isProviderAvailable">
  <div
    class="row pb-2 py-4 mt-2"
    *ngIf="updatedDoctorList && updatedDoctorList.list.length > 0"
  >
    <div *ngFor="let list of updatedDoctorList.list">
      <div class="doctor-card card py-3 px-3 pointer m-2">
        <div class="text-primary pt-2">
          {{ list.first_name | titlecase }} {{ list.last_name | titlecase }}
        </div>
        <div class="text-primary pt-2">
          {{ list.CorporateOwnerLastName }}
        </div>
        <div class="pt-2">
          {{ list.street_address }}
        </div>
        <div class="pt-2">{{ list.city }}, {{ list.state }}</div>
        <div class="pt-2">
          {{ list.zipcode }}
        </div>
        <div class="row">
          <div class="col-9">
            {{ list.phone_numbers }}
          </div>
          <div class="col-9">
            {{ list.FaxNumber }}
          </div>
          <div class="col-9" *ngIf="showLastVisited">
            {{ list.last_visited }}
          </div>
          <div class="col-9">
            {{ list.tax_id }}
          </div>
          <div class="col d-flex justify-content-end">
            <i
              class="fa fa-edit text-primary mr-1"
              (click)="editDoctor(list)"
            ></i>
            <i
              class="far fa-trash-alt text-danger"
              (click)="deleteDoctor(list)"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row p-2" *ngIf="updatedDoctorList.length == 0">
    <span class="col-12 text-center alert alert-danger ml-2">
      Doctor Information is not present in the list.
    </span>
  </div> -->
  <div class="row" [formGroup]="searchTerms">
    <div class="col-12" *ngIf="showProviderConfirmation">
      <hr />
    </div>
    <div class="col-12 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-link p-3 mb-2"
        (click)="isExpanded = !isExpanded"
        [attr.aria-expanded]="!isExpanded"
        aria-controls="collapseExample"
      >
        Advanced Search
      </button>
    </div>
    <div class="col-6 d-flex">
      <div class="position-relative w-100">
        <div class="input-group progress-icon search-results-holder">
          <ng-template #rt let-r="result" let-t="term">
            <div class="provider-row">
              <div class="mr-2" *ngIf="showingRecent">
                <i
                  class="fas fa-save"
                  style="position: relative; top: 0px; color: inherit;"
                ></i>
              </div>
              <div>
                <div class="row">
                  <div class="col-7 font-weight-bold">
                    {{
                      (r.first_name
                        ? r.first_name + " " + r.last_name
                        : r.name
                      ) | titlecase
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    {{ r.street_address }}, {{ r.city }}, {{ r.state }}
                    {{ r.zipcode }}-{{ r.phone }}
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <input
            id="searchBarId"
            #searchBar
            [type]="searchBarProps.type"
            #instance="ngbTypeahead"
            minlength="3"
            [ngbTypeahead]="onLookupType"
            [class.is-invalid]="searchFailed"
            [inputFormatter]="formatter"
            (selectItem)="selectedItem($event)"
            [resultFormatter]="formatter"
            [resultTemplate]="rt"
            [(ngModel)]="healthProvider"
            formControlName="searchString"
            autocomplete="disabled"
            class="form-control"
            [placeholder]="searchBarProps.placeholder"
            (focus)="onFocus($event)"
          />
          <i *ngIf="searching" class="fas fa-spinner fa-spin text-primary"></i>
        </div>
      </div>
    </div>
    <div class="col-3 d-flex">
      <div class="d-flex align-items-start ml-2">
        <input
          class="form-check-input"
          type="radio"
          name="healthcareProviderSearchType"
          id="by-doctor"
          value="0"
          formControlName="healthcareProviderSearchType"
          (click)="searchBarProps = searchType[0]"
          (change)="searchTypeChanged()"
        />
        <label class="form-check-label" for="by-doctor">
          Doctor's Name
        </label>
      </div>
      <div class="d-flex align-items-start ml-5">
        <input
          class="form-check-input"
          type="radio"
          name="healthcareProviderSearchType"
          id="by-facility"
          value="1"
          formControlName="healthcareProviderSearchType"
          (click)="searchBarProps = searchType[1]"
          (change)="searchTypeChanged()"
        />
        <label class="form-check-label" for="by-facility">
          Facility Name
        </label>
      </div>
    </div>
    <div class="col-3 align-items-end" *ngIf="isExpanded">
      <div class="d-flex ml-4">
        <input
          class="form-check-input"
          type="radio"
          id="phone-number"
          placeholder="Phone number"
          name="healthcareProviderSearchType"
          (click)="searchBarProps = searchType[2]"
          (change)="searchTypeChanged()"
        />
        <label class="form-check-label" for="phone-number">
          Phone Number
        </label>
      </div>
    </div>
    <div class="col-3 mt-3 offset-6" *ngIf="isExpanded">
      <div class="d-flex align-items-center">
        <!-- <label for="distance">Within</label> -->
        <select
          class="form-select form-control ml-2"
          aria-label="Default select example"
          formControlName="radius"
          (change)="fireSearchwithRadius(searchBar)"
        >
          <option selected [value]="0">Select range</option>
          <option *ngFor="let range of radiusList" [value]="range"
            >{{ range }} miles</option
          >
          <option [value]="0">Any distance</option>
        </select>
      </div>
    </div>
    <div class="col-3 mt-3" *ngIf="isExpanded">
      <div class="d-flex align-items-center">
        <!-- <label for="zipcode">Area Code</label> -->
        <div>
          <input
            type="number"
            class="form-control ml-2"
            id="zipcode"
            placeholder="Zipcode"
            formControlName="applicantZipcode"
            (input)="fireSearchWithZipcode(searchBar)"
          />
        </div>
      </div>
    </div>

    <div class="col-12 mb-4 mt-3">
      <div
        class="invalid-feedback d-block mb-3"
        *ngIf="searchFailed && !searching"
      >
        Sorry, suggestions could not be loaded.
      </div>
      <div class="invalid-feedback d-block mb-3" *ngIf="doctorExist">
        This Doctor already exists.
      </div>
      <p class="text-center alert alert-warning mt-3 mb-0">
        If you cannot find your doctor in the search provided above, you may add
        them below
      </p>
    </div>
  </div>
  <form [formGroup]="providerLookup">
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <input
            class="form-control"
            [formControlName]="'fullname'"
            (change)="formCompleted()"
            placeholder="Name of Doctor"
            autocomplete="disabled"
          />
        </div>
      </div>
      <div class="col-8">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            formControlName="CorporateOwnerLastName"
            (change)="formCompleted()"
            placeholder="Facility Name"
            autocomplete="disabled"
          />
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-12">
        <div class="form-group">
          <input
            class="form-control"
            [formControlName]="'street_address'"
            (change)="formCompleted()"
            placeholder="Street Address"
            autocomplete="disabled"
          />
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-4">
        <div class="form-group">
          <input
            class="form-control"
            [formControlName]="'city'"
            (change)="formCompleted()"
            placeholder="City"
            autocomplete="disabled"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input
            class="form-control"
            [formControlName]="'state'"
            (change)="formCompleted()"
            placeholder="State"
            autocomplete="disabled"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input
            class="form-control"
            [formControlName]="'zipcode'"
            (change)="formCompleted()"
            placeholder="Zip Code"
            autocomplete="disabled"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input
            class="form-control"
            [formControlName]="'phone_numbers'"
            (change)="formCompleted()"
            autocomplete="disabled"
            mask="(000) 000-0000"
            [showMaskTyped]="true"
            name="phone_numbers"
            [dropSpecialCharacters]="false"
            pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input
            class="form-control"
            formControlName="FaxNumber"
            (change)="formCompleted()"
            placeholder="Fax"
            autocomplete="disabled"
          />
        </div>
      </div>
      <div class="col-4" *ngIf="showLastVisited">
        <div class="form-group">
          <select
            name="last_visited"
            id="last_visited"
            [formControlName]="'last_visited'"
            class="form-control"
          >
            <option value="null" selected disabled>Last Visited</option>
            <option
              *ngFor="let opt of lastVisitedOptions"
              [value]="opt.value"
              >{{ opt.key }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input
            class="form-control"
            [formControlName]="'tax_id'"
            (change)="formCompleted()"
            placeholder="Tax ID"
            autocomplete="disabled"
          />
        </div>
      </div>
      <div class="col text-right">
        <input
          type="button"
          class="btn btn-secondary"
          value="Clear"
          (click)="resetForm()"
        />
        <input
          type="button"
          class="btn btn-primary ml-2"
          [disabled]="providerLookup.invalid"
          [value]="isDoctorUpdated ? 'Update' : 'Add'"
          (click)="addDoctor(isDoctorUpdated)"
        />
      </div>
    </div>
  </form>
</div>

<app-modal
  [open]="openModal"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event)"
>
  <div title>
    Confirmation
  </div>
  <div body>
    <div class="pl-2">
      Are you sure of deleting this doctor detail?
    </div>
  </div>
</app-modal>
