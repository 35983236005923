import { FilterParameter } from '../question-filter.service';
import { BaseFilter } from './base-filter';

export class DependentLastNameFilter extends BaseFilter {
  Type = 'DependentLastNameFilter';

  Filter(parameter: FilterParameter) {
    let canDisplay = false;
    const spouse = parameter.application.applicants.filter((a) => {
      return a.relationship === 'S';
    });

    const dependentLastName = parameter.application.dependents.filter((d) => {
      return (
        parameter.application.primaryApplicant.lastName !== d.lastName ||
        (spouse.length > 0 && spouse[0].lastName !== d.lastName)
      );
    });
    if (dependentLastName.length > 0) {
      canDisplay = true;
    }
    return canDisplay;
  }
}
