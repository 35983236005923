import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Application } from 'src/app/shared/models/application/application';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { AddOnsService } from './addon.service';
import { AddonApplicant, AddOnApplication } from './addon-application';
import { AdditionalInfoService } from '../additional-info/additional-info.service';
import { ApplicationStatus, ApplicationTrackerType } from '../../models/enum';
import { ApplicationTracker } from '../additional-info/additional-info-modal';
import { AddonApplicantComponent } from '../addon-applicant/addon-applicant.component';
import { DoctorlookupDetailsAnswer } from 'src/app/vcall/questions/applicant-qualified-doctor-lookup/applicant-qualified-doctor-lookup.component';
import { DoctorLookup } from 'src/app/shared/models/drLookup';

@Component({
  selector: 'app-add-ons',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss'],
  providers: [AddonApplicantComponent],
})
export class AddonComponent implements OnInit, OnDestroy {
  openApplicantModal: boolean;
  addApplicantList = new Array<AddonApplicant>();
  editedApplicant: AddonApplicant;
  openDeleteApplicantModal: boolean;
  addonLogs = [];
  application: Application;
  selectedApplication: AddOnApplication;
  subscriptions = new Array<Subscription>();
  profile: UserProfile;
  applicationTrackers: ApplicationTracker[];
  resetProviderRequest = new BehaviorSubject(null);
  canDisplayAddonBtn = false;
  canDisplayProvider = false;
  initialList = new DoctorlookupDetailsAnswer();
  details = {};
  updatedDoctorsList: DoctorLookup[] = [];
  constructor(
    private addonsService: AddOnsService,
    private appService: ApplicationService,
    private service: AdditionalInfoService,
    private addonComp: AddonApplicantComponent
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.appService.SelectedApplication.subscribe((res) => {
        if (res.id !== 0) {
          this.application = res;
          this.addonsService.getAddOnApplications(this.application.id);
          this.service.fetchOrderRequests(this.application.id);
          this.service.fetchNotes(this.application.id);
        }
      })
    );

    this.subscriptions.push(
      this.addonsService.getAddOnApplicationLogs.subscribe((res) => {
        if (res !== null && res !== undefined) {
          this.addonLogs = res;
        }
      })
    );

    this.subscriptions.push(
      this.service.orderRequests.subscribe((response) => {
        if (
          response !== null &&
          response['data'] &&
          response['data'].application
        ) {
          this.applicationTrackers = response[
            'data'
          ].application.applicationTracker.filter(
            (a) =>
              a.applicationTrackerType ===
              ApplicationTrackerType.AddOnApplication
          );
          this.canDisplayAddonBtn =
            this.applicationTrackers.length === 0 ||
            (this.applicationTrackers.length > 0 &&
              this.applicationTrackers.every(
                (a) =>
                  a.applicationStatusId ===
                    ApplicationStatus['Routed To Issue'] ||
                  a.applicationStatusId === ApplicationStatus['APS Returned']
              ));
        }
      })
    );
    this.getApplicantDetails();
  }

  getApplicantDetails() {
    setTimeout(() => {
      if (this.application) {
        this.addonComp.fetchApplicants(this.application.id);
      }
    }, 500);
  }

  get ApplicationStatus(): typeof ApplicationStatus {
    return ApplicationStatus;
  }

  createAddOn() {
    this.openApplicantModal = true;
  }

  addApplicant(a) {
    this.openApplicantModal = true;
    this.selectedApplication = a;
  }

  editApplicantForm(applicant) {
    this.openApplicantModal = true;
    this.editedApplicant = applicant;
  }

  closeApplicantModal(res) {
    this.openApplicantModal = res;
    this.ngOnInit();
  }

  addprovider() {
    this.canDisplayProvider = true;
    this.resetProviderRequest.next({ list: [], isProvider: true });
  }

  confirm(event) {
    if (event === 'Done') {
      this.appService.PersistAnswers();
    }
    this.canDisplayProvider = false;
    this.addonComp.fetchApplicants(this.application.id);
  }

  getUpdatedDrList(doc) {
    this.updatedDoctorsList = doc.list;
    if (!this.updatedDoctorsList.length) {
      this.resetProviderRequest.next(true);
    }
  }

  updateFetchApplicants(event) {
    this.getApplicantDetails();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
