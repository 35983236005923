import { Answer } from 'src/app/shared/models/application/answer';
import { FilterParameter } from '../question-filter.service';
import { BaseFilter } from './base-filter';

export class PendingAnswerFilter extends BaseFilter {
  Type = 'PendingAnswer';

  Filter(parameter: FilterParameter) {
    let canDisplay = false;

    const answer = parameter.answers.find((a) => {
      if (!(a instanceof Answer)) {
        let item = new Answer();
        item = Object.assign(item, a);
        return item.answerTag === parameter.question.answerTag;
      }
    });
    if (answer) {
      canDisplay = false;
    } else {
      canDisplay = true;
    }
    return canDisplay;
  }
}
