import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  /**
   * @param header header text as a string
   * @param description to display the toaster body information
   * @description show the success toaster
   */
  success(header: string, description: string) {
    this.show(description, {
      classname: 'bg-success text-light',
      delay: 2000,
      autohide: true,
      headertext: header,
    });
  }

  /**
   * @param header header text as a string
   * @param description to display the toaster body information
   * @description show the error toaster
   */
  error(header: string, description: string) {
    this.show(description, {
      classname: 'bg-danger text-light',
      delay: 5000,
      autohide: true,
      headertext: header,
    });
  }

  /**
   * @param header header text as a string
   * @param description to display the toaster body information
   * @description show the information toaster
   */
  info(header: string, description: string) {
    this.show(description, {
      classname: 'bg-info text-light',
      delay: 5000,
      autohide: true,
      headertext: header,
    });
  }
  // Push new Toasts to array with content and options
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  // Callback method to remove Toast DOM element from view
  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
