export class DoctorLookup {
  first_name: string;
  last_name: string;
  constructor(options) {
    Object.assign(this, options);
  }
  fullname: string;
  street_address: string;
  city: string;
  state: string;
  zipcode: string;
  id: string;
  distance: string;
  specialties: string;
  phone_numbers: number;
  other_phone: number;
  tax_id?: string;
  client_nos?: string[] = [];
  additionalInfo?: AdditionalInfo;
  fax?: string;
  facility_name?: string;
  is_basic: boolean;
}
export class DoctorLookupAnswer {
  list: DoctorLookup[] = [];
  isProviderAvailable = false;
  answerType = 'DoctorDetailsAnswer';
}

interface AdditionalInfo {
  [key: string]: any;
}
