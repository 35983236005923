import { Applicant } from 'src/app/shared/models/application/applicant';

export interface GetOrders {
  data?: Data;
}

export interface Data {
  application?: Application;
}

export interface Application {
  applicationTracker: Array<ApplicationTracker>;
  id: number;
  orderDetails: Array<OrderRequestDetails>;
  primaryApplicant: Applicant;
  state: string;
  __typename: string;
}

export interface ApplicationTracker {
  applicationStatusId: number;
  assignedTo: string;
  assignedOn: Date;
  id: number;
  applicationStatusHistories: Array<ApplicationStatusHistory>;
  dueDate: Date;
  applicationTrackerType: string;
  addOnApplicationId: string;
  __typename: string;
}

export interface ApplicationStatusHistory {
  applicationStatusId: number;
  id: number;
  createdDate: string;
  applicationTrackerId: number;
}

export interface OrderRequestDetails {
  applicationId: number;
  clientNumber: string;
  requestType: string;
  createdBy: string;
  createdDate: string;
  state: string;
  assignedTo: string;
  firstName: string;
  middleName: string;
  lastName: string;
  id: string;
  orderTrackerId: number;
  labs: Array<Lab>;
  aps: APSRequestDetail;
  index?: number;
  orderDocuments?: OrderDocuments;
  orderStatusId?: number;
  applicationTrackerId?: number;
}

export interface Provider {
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  phone: number;
  firstName: string;
  id: string;
  lastName: string;
  fullName: string;
  taxId?: string;
  facilityName: string;
  fax: string;
  additionalInfo: AdditionalInfo;
  is_basic: boolean;
}

interface AdditionalInfo {
  [key: string]: any;
}

export interface APSRequestDetail {
  provider: Provider;
  reason: string;
  uWSNotes: string;
  Team: number;
}

export interface Lab {
  name: string;
  notes: string;
  status: boolean;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
}

export interface RouteToAPS {
  id: number;
  applicationId: number;
  assignedTo: string;
  updateBy: string;
  createdDate: string;
  createdBy: string;
  team: string;
  convertedVersion: string;
}

export interface OrderDocuments {
  appId: string;
  orderId: string;
  clientNumber: string;
  documents: Array<Documents>;
}

export interface Documents {
  name: string;
  content: any;
  __typename: string;
}

export interface GetOrderDocuments {
  data?: OrderData;
}

export interface OrderData {
  downloadOrderFiles: DownloadOrderFiles;
}

export interface DownloadOrderFiles {
  documents: Array<Documents>;
  __typename: string;
}

export enum OrderStatus {
  Open = 0,
  Inprogress = 1,
  Created = 2,
  Pending = 3,
  Closed = 4,
  Completed = 5,
  Cancelled = 6,
  AppointmentMet = 7,
}

export enum RequestType {
  'APS' = 'APS',
  'LAB_AE' = 'LAB_AE',
  'LAB_OE' = 'LAB_OE',
  'LAB_AE_HTWTBP' = 'HTWTBP',
}

export interface AuditNote {
  id: number;
  information: string;
  loggedBy: string;
  loggedDate: Date | string;
  auditParameter: string;
  orderTrackerId?: number | string;
  applicationTrackerId: number;
  auditTypeId: number;
  applicationId: number;
}
