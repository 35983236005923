import { AfterViewInit, Directive } from '@angular/core';
import {
  NavigationAwareQuestionType,
  NavigationArgument,
  NavigationDirection,
} from '../types/baseQuestionType';
import { Question } from 'src/app/shared/models/vcall/question';
import {
  ApplicantDetailItem,
  ApplicantQualifiedDetailAnswer,
} from './applicant-detail-item';
import { DefaultApplicantQualifiedQuestionDetail } from './default-applicant-qualified-question-detail';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { Input } from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';

@Directive()
export abstract class ApplicantQualifiedBaseComponent<
    TDetail extends DefaultApplicantQualifiedQuestionDetail,
    TAnswer extends BaseApplicantQualifiedAnswer
  >
  extends NavigationAwareQuestionType<TDetail>
  implements AfterViewInit
{
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    protected applicantFilterService: ApplicantFilterService,
    public appointmentService?: AppointmentService
  ) {
    super(script, appService, ui);
  }

  promptText: string;
  selectedItem: ApplicantDetailItem<TAnswer>;
  currentAnswer: TAnswer;
  isFormInvalid = true;
  qualifiedList: ApplicantDetailItem<TAnswer>[];
  applicantName: string;
  selectedIndex = 0;
  selectedApplicant: Applicant;
  ezappValue: any;
  answerType = 'ApplicationQualifyGeneric';

  @Input() configType: string;
  abstract ResetApplicant(): void;
  abstract BindApplicant(): void;
  abstract InitializeApplicantAnswerInstance(): TAnswer;

  ApplicationChanged(): void {}

  ApplicableApplicants() {
    return this.qualifiedList.map((q) => {
      return this.application.applicants.filter(
        (a) => a.clientNo === q.clientNo
      )[0].fullName;
    });
  }

  QuestionChanged(q: Question): void {
    // this is to ensure that lingering subscription don't cause any issue
    if (q.type !== this.configType) {
      return;
    }

    if (
      this.answer !== null &&
      this.answer !== undefined &&
      this.answer.value !== null
    ) {
      this.qualifiedList = null;
      this.qualifiedList = this.answer.value.filter((rq) => {
        if (q.details.applicantFilterValue) {
          return (
            rq.applicable &&
            rq.value.toString() === q.details.applicantFilterValue
          );
        } else {
          return rq.applicable;
        }
      });

      if (this.filterApplicants().length) {
        this.qualifiedList = this.qualifiedList.filter((ql) => {
          return (
            this.filterApplicants().filter((f) => {
              return ql.clientNo === f.clientNo;
            }).length !== 0
          );
        });
      }

      if (this.qualifiedList.length === 0) {
        console.log(' Race condition...go next');
        alert('unexpected condition, click back !!');
      }

      this.loadApplicant();
      this.saveAnswer(true, this.answer.value); // force correct the Next button.
    }
  }

  filterApplicants() {
    return this.application.applicants.filter((a) => {
      return this.applicantFilterService.Apply({
        answers: this.script.Answers,
        application: this.application,
        applicant: a,
        filterJson: this.question.details.filters,
        question: this.question,
      });
    });
  }

  loadApplicant() {
    console.log('loadApplicant()');
    // Reset
    this.currentAnswer = this.InitializeApplicantAnswerInstance();

    this.ResetApplicant();

    // When the question is transitioning to avoid transiet issues.
    if (
      this.selectedIndex >= 0 &&
      this.selectedIndex < this.qualifiedList.length
    ) {
      this.selectedItem = this.qualifiedList[this.selectedIndex];

      if (this.selectedItem && this.selectedItem.values) {
        const answer = this.selectedItem.values.find(
          (a) =>
            a.key &&
            this.details.answerTag &&
            a.key.toLowerCase() === this.details.answerTag.toLowerCase()
        );
        if (answer) {
          console.log(this.currentAnswer);
          this.currentAnswer = answer.value;
        }
      }

      const applicant = this.application.applicants.filter(
        (a) => a.clientNo === this.selectedItem.clientNo
      )[0];

      this.selectedApplicant = applicant;
      this.appService.SelectApplicant(applicant);
      this.BindApplicant();
    }
  }
  // ngAfterViewInit(): void {
  //   this.subscriptions.push(
  //     this.script.SelectedQuestion.subscribe((q) => {
  //       setTimeout(() => {
  //         // 		// this is to ensure that lingering subscription don't cause any issue
  //         if (this.question.type !== this.configType) {
  //           return;
  //         }
  //         // this.ResetApplicant();
  //         // this.loadApplicant();
  //         // this.BindApplicant();
  //       }, 0);
  //     })
  //   );
  // }

  ResetView() {
    this.selectedIndex = 0;
  }

  CanGoNext(): boolean {
    return this.currentAnswer !== null && this.currentAnswer !== undefined;
  }

  Navigating(direction: NavigationDirection): NavigationArgument {
    const arg = new NavigationArgument();
    if (this.getApplicantStopCallStatus()) {
      this.saveAnswer(true, this.answer.value);
      this.applicationService.PersistAnswers();
      this.appointmentService.OpenStopCallPopup(true);
      arg.stopPropagation = true;
    } else {
      if (direction === NavigationDirection.Next) {
        this.selectedIndex++;
      } else if (direction === NavigationDirection.Previous) {
        this.selectedIndex--;
      }

      const remain = !(
        this.selectedIndex < 0 ||
        this.selectedIndex >= this.qualifiedList.length
      );

      arg.stopPropagation = remain;

      if (remain) {
        this.loadApplicant(); // This will load the next or previous applicant
        this.saveAnswer(true, this.answer.value); // force correct the Next button.
      }
    }

    return arg;
  }

  getApplicantStopCallStatus() {
    const currentAnswer = this.answer.value.filter((a) => {
      return a.clientNo === this.selectedApplicant.clientNo;
    });
    if (
      currentAnswer.length > 0 &&
      this.question.details.allowNextForValue !== null &&
      this.question.details.allowNextForValue !== undefined
    ) {
      return (
        currentAnswer[0].values &&
        currentAnswer[0].values[0]?.value.applicable ===
          this.question.details.allowNextForValue
      );
    } else {
      return false;
    }
  }

  Save(toSave: TAnswer) {
    // to avoid reference being changed by async process
    const valuesToSave = JSON.parse(JSON.stringify(toSave));
    const answerTag = this.details.answerTag;

    if (answerTag === null) {
      alert('Please refresh the browswer to recover');
      return; // this is to avoid null answers.
    }

    if (!this.selectedItem.values) {
      this.selectedItem.values = new Array<ApplicantQualifiedDetailAnswer>();
    }

    const savedAnswer: ApplicantQualifiedDetailAnswer =
      this.selectedItem.values.find((a) => a.key === answerTag);

    if (savedAnswer === null || savedAnswer === undefined) {
      const newItem: ApplicantQualifiedDetailAnswer = {
        key: answerTag,
        value: valuesToSave,
        prompt: this.safeInterpolate(this.question.text),
      };

      if (this.ezappValue) {
        newItem.ezappValue = this.ezappValue;
      }

      this.selectedItem.values.push(newItem);
    } else {
      savedAnswer.value = valuesToSave;
    }

    this.saveAnswer(true, this.answer.value, this.answerType);
  }
}

export abstract class BaseApplicantQualifiedAnswer {
  abstract answerType: string;
}
