import { Answer } from 'src/app/shared/models/application/answer';
import { FilterParameter } from '../question-filter.service';
import { BaseFilter } from './base-filter';

export class AnswerTagFilter extends BaseFilter {
  Type = 'AnswerTag';

  Filter(parameter: FilterParameter) {
    let canDisplay = false;
    const answer = parameter.answers.find((a) => {
      if (!(a instanceof Answer)) {
        let item = new Answer();
        item = Object.assign(item, a);
        return item.answerTag === parameter.config.path;
      } else {
        return a.answerTag === parameter.config.path;
      }
    });

    if (answer) {
      canDisplay = answer.value === parameter.config.value;
      const ezAppValue =
        parameter.config.params !== undefined
          ? parameter.config.params.find((p) => p.key === 'ezAppValue')
          : null;
      if (ezAppValue) {
        canDisplay =
          canDisplay &&
          ((answer.ezAppValue === undefined &&
            answer.value === ezAppValue.value) ||
            answer.ezAppValue === ezAppValue.value);
      }
    } else {
      const defaultValue =
        parameter.config.params !== undefined
          ? parameter.config.params.find((p) => p.key === 'defaultValue')
          : null;
      let defaultAnswer = 'false';
      if (defaultValue) {
        defaultAnswer = defaultValue.value;
      }
      canDisplay = defaultAnswer === 'true';
    }
    return canDisplay;
  }
}
