import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VcallApiService } from 'src/app/shared/services/api/vcall/vcall-api.service';
import { CallZoneApiService } from 'src/app/shared/services/api/callzone/callzone-api.service';

@Component({
  selector: 'app-smoke-test',
  templateUrl: './smoke-test.component.html',
  styleUrls: ['./smoke-test.component.scss'],
})
export class SmokeTestComponent implements OnInit {
  responses = [];
  errors = [];
  openApi;
  constructor(
    private vcallApiService: VcallApiService,
    private callZoneApiService: CallZoneApiService
  ) {}
  listOfServicesWithApis = environment.listOfApisAndServicesForSmokeTest;
  ngOnInit(): void {
    this.callApis();
  }

  /**
   * iterating through the listofservices array (listOfServicesWithApis) having Get Apis  with Payload
   * and pushing response to the respective arrays
   */
  callApis() {
    this.listOfServicesWithApis.forEach(async (eachService) => {
      eachService.listofApisWithPayload.forEach(async (eachApi) => {
        const response = await this[eachService.nameOfTheService]
          [eachApi.apiName](eachApi.payload)
          .toPromise();
        if (response && !response['error']) {
          this.responses.push({ nameOfTheApi: eachApi.apiName, response });
        } else {
          this.errors.push({
            nameOfTheApi: eachApi.apiName,
            response,
          });
        }
      });
    });
  }
  showResponse(api) {
    this.openApi = api.nameOfTheApi;
  }
}
