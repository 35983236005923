import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HandledHttpClient } from 'src/app/shared/services/api/handledHttpClient';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionService {
  baseUrl: string;

  constructor(private http: HandledHttpClient) {
    this.baseUrl = environment.config.apis.prescription;
  }
  /**
   * @param search search text for search appications
   * @description search the application
   * @returns application lists
   */
  public SearchMedicationOrDrug<T>(searchString: any): Observable<T[]> {
    return this.http.get<T[]>(`${this.baseUrl}/${searchString}`, {
      errorMessage: `Error in retrieving search results`,
    });
  }
}
