import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PermissionService } from '../permission/permission.service';
import { LogDataService } from 'src/app/core/logData.service';

@Injectable({
  providedIn: 'root',
})
export class HandledHttpClient {
  constructor(
    public http: HttpClient,
    private permissionService: PermissionService,
    private logDataService: LogDataService
  ) {}

  get<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      errorMessage?: string;
      successMessage?: string;
      responseDecider?: string;
      excludeToken?: boolean;
      donotLog?: boolean;
      dontAddPermissions?: boolean;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();
    options = options ? options : {};

    if (options && options.errorMessage) {
      headers = headers.append('x-error-message', options.errorMessage);
    }

    if (options && options.successMessage) {
      headers = headers.append('x-success-message', options.successMessage);
    }

    if (options && options.responseDecider) {
      headers = headers.append(
        'x-response-decider-key',
        options.responseDecider
      );
    }

    if (options && options.excludeToken) {
      headers = headers.append(
        'x-exclude-token',
        options.excludeToken.toString()
      );
    }

    if (options && options.donotLog) {
      headers = headers.append('x-dont-log', options.donotLog.toString());
    }

    if (options && options.dontAddPermissions) {
      console.log('Client opted to not add the permissions header');
    } else {
      const permissions = this.permissionService.PermissionSnapShotSerialized();
      headers = headers.append('x-permissions', permissions);
    }
    
    var requestId = this.logDataService.createLogData();
    headers = headers.append('x-request-id', requestId);
    
    options.headers = headers;

    return this.http.get<T>(url, options);
  }

  post<T>(
    url: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      errorMessage?: string;
      successMessage?: string;
      responseDecider?: string;
      excludeToken?: boolean;
      donotLog?: boolean;
      dontAddPermissions?: boolean;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();
    options = options ? options : {};

    if (options && options.errorMessage) {
      headers = headers.append('x-error-message', options.errorMessage);
    }

    if (options && options.successMessage) {
      headers = headers.append('x-success-message', options.successMessage);
    }

    if (options && options.responseDecider) {
      headers = headers.append(
        'x-response-decider-key',
        options.responseDecider
      );
    }

    if (options && options.excludeToken) {
      headers = headers.append(
        'x-exclude-token',
        options.excludeToken.toString()
      );
    }

    if (options && options.donotLog) {
      headers = headers.append('x-dont-log', options.donotLog.toString());
    }

    if (options && options.dontAddPermissions) {
      console.log('Client opted to not add the permissions header');
    } else {
      const permissions = this.permissionService.PermissionSnapShotSerialized();
      headers = headers.append('x-permissions', permissions);
    }

    var requestId = this.logDataService.createLogData();
    headers = headers.append('x-request-id', requestId);    

    options.headers = headers;

    return this.http.post<T>(url, body, options);
  }

  delete<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      errorMessage?: string;
      successMessage?: string;
      responseDecider?: string;
      excludeToken?: boolean;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();
    options = options ? options : {};

    if (options && options.errorMessage) {
      headers = headers.append('x-error-message', options.errorMessage);
    }
    if (options && options.successMessage) {
      headers = headers.append('x-success-message', options.successMessage);
    }

    if (options && options.responseDecider) {
      headers = headers.append(
        'x-response-decider-key',
        options.responseDecider
      );
    }

    if (options && options.excludeToken) {
      headers = headers.append(
        'x-exclude-token',
        options.excludeToken.toString()
      );
    }

    var requestId = this.logDataService.createLogData();
    headers = headers.append('x-request-id', requestId);

    options.headers = headers;

    return this.http.delete<T>(url, options);
  }

  put<T>(
    url: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      errorMessage?: string;
      successMessage?: string;
      responseDecider?: string;
      excludeToken?: boolean;
      dontAddPermissions?: boolean;
    }
  ): Observable<T> {
    let headers = new HttpHeaders();
    options = options ? options : {};
    if (options && options.errorMessage) {
      headers = headers.append('x-error-message', options.errorMessage);
    }
    if (options && options.successMessage) {
      headers = headers.append('x-success-message', options.successMessage);
    }

    if (options && options.responseDecider) {
      headers = headers.append(
        'x-response-decider-key',
        options.responseDecider
      );
    }

    if (options && options.excludeToken) {
      headers = headers.append(
        'x-exclude-token',
        options.excludeToken.toString()
      );
    }

    if (options && options.dontAddPermissions) {
      console.log('Client opted to not add the permissions header');
    } else {
      const permissions = this.permissionService.PermissionSnapShotSerialized();
      headers = headers.append('x-permissions', permissions);
    }

    var requestId = this.logDataService.createLogData();
    headers = headers.append('x-request-id', requestId);

    options.headers = headers;

    return this.http.put<T>(url, body, options);
  }
}
