import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask',
})
export class MaskPipe implements PipeTransform {
  transform(value: string, visibleChars?: number): string {
    if (!value) {
      return '';
    }
    const stringValue = value.toString();
    const maskedValue =
      stringValue.slice(0, -visibleChars).replace(/\d/g, '*') +
      stringValue.slice(-visibleChars);
    return maskedValue;
  }
}
