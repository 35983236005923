<div
  *ngIf="
    details.mode === 'booleanAndNotesIfFalse' &&
    booleanyes.checked &&
    !question.details.booleanIfNo
  "
  class="col-sm-12 alert alert-warning text-center mt-3"
  role="alert"
>
  {{ details.instructionsIfYes }}
</div>
<div class="col-sm-11 mb-5 mr-5 mt-4 pb-3">
  <label class="float-right ml-3 hover-success" for="introductory-1a">
    <div
      [ngClass]="{ 'btn-success': booleanyes.checked }"
      class="btn border border-success btn-check-next carousel-next first-letter-yes"
      (click)="optionChanged(this.details.yesValue)"
    >
      <input
        class="form-check-input"
        [style.visibility]="'hidden'"
        type="radio"
        name="boolean-1"
        #booleanyes
        value="{{ this.details.yesValue }}"
        id="introductory-1a"
      />{{ this.details.yesText }}
    </div>
  </label>
  <label class="float-right hover-danger" for="introductory-1b">
    <div
      [ngClass]="{ 'btn-danger': booleanno.checked }"
      class="btn border border-danger btn-check-next carousel-next first-letter-no"
      (click)="optionChanged(this.details.noValue)"
    >
      <input
        class="form-check-input"
        [style.visibility]="'hidden'"
        type="radio"
        name="boolean-1"
        #booleanno
        value="{{ this.details.noValue }}"
        id="introductory-1b"
      />{{ this.details.noText }}
    </div>
  </label>
</div>
<div
  class="row"
  *ngIf="
    (question.details.booleanIfYes && booleanyes.checked) ||
    (question.details.booleanIfYes === undefined &&
      (booleanyes.checked || booleanno.checked))
  "
>
  <div
    class="col-12 p-3"
    *ngIf="details.textboxTitle && details.mode === 'booleanAndNotesIfTrue'"
  >
    {{ details.textboxTitle }}
  </div>
  <div class="col-8" *ngIf="details.mode === 'booleanAndNotesIfTrue'">
    <textarea
      type="text"
      class="form-control detail"
      name="detail"
      (blur)="SaveText()"
      (keyup)="EnableNext()"
      [(ngModel)]="currentAnswer.notes"
    >
    </textarea>
  </div>
</div>
<div class="row" *ngIf="question.details.booleanIfNo && booleanno.checked">
  <div
    class="col-12 p-3"
    *ngIf="details.textboxTitle && details.mode === 'booleanAndNotesIfFalse'"
  >
    {{ details.textboxTitle }}
  </div>
  <div class="col-8" *ngIf="details.mode === 'booleanAndNotesIfFalse'">
    <textarea
      type="text"
      class="form-control detail"
      name="detail"
      (blur)="SaveText()"
      (keyup)="EnableNext()"
      [(ngModel)]="currentAnswer.notes"
    >
    </textarea>
  </div>
</div>
<div class="row justify-content-end">
  <div
    *ngIf="question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>
</div>
