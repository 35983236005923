import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  AfterContentChecked,
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  FormDataModal,
  QuestionData,
  QuestionModal,
  SearchedQuestionsModal,
  SelectedApplicantModel,
} from './recall-addquestion-modal';

import { SearchApiService } from 'src/app/shared/services/search/search-api.service';
import { Applicant } from 'src/app/shared/models/application/applicant';

@Component({
  selector: 'app-recall-addquestion',
  templateUrl: './recall-addquestion.component.html',
  styleUrls: ['./recall-addquestion.component.scss'],
})
export class RecallAddquestionComponent
  implements OnInit, OnChanges, AfterContentChecked {
  customQuestionText: string = null;
  formData: FormDataModal = {};
  @Output() formData$ = new EventEmitter<QuestionModal>();
  @Output() listOfQuestions$ = new EventEmitter<QuestionModal[]>();
  @Input() recallApplicants: string[];
  @Input() clearData: boolean;
  selectedReason: string;
  searchQuestion$ = new Subject<string>();
  searchedQuestions: SearchedQuestionsModal[] = [];
  public reasonsList = [
    { name: 'Specific Health Question' },
    { name: 'MIB' },
    { name: 'RX Profile' },
    { name: 'Bank Auth' },
    { name: 'Claims HX' },
    { name: 'V-Call Error by Verifier' },
    { name: 'Other' },
  ];
  questionFilter = 'question';
  listOfAddedQuestions: QuestionModal[] = [];
  selectedQuestions: QuestionModal[] = [];
  selectedApplicant: SelectedApplicantModel;
  constructor(private searchApiService: SearchApiService) {}

  ngOnInit(): void {
    this.setIntialValues();
    this.SearchQuestion();
  }

  ngAfterContentChecked() {
    if (this.recallApplicants?.length === 1) {
      this.formFieldValues();
    }
  }

  /**
   * Resetting the values to null and clearing the selection
   */
  setIntialValues() {
    this.formData = {
      reason: null,
      applicantName: null,
    };
    this.selectedReason = null;
    this.listOfAddedQuestions = [];
    this.customQuestionText = null;
    this.formFieldValues();
  }

  // recall form field values
  formFieldValues() {
    this.formData.applicantName =
      this.recallApplicants?.length === 1
        ? (this.formData$.emit(this.formData),
          this.recallApplicants[0]['fullName'])
        : null;
    this.formData.clientNo =
      this.recallApplicants?.length === 1
        ? (this.formData$.emit(this.formData),
          this.recallApplicants[0]['clientNo'])
        : null;
    this.formData.relationship =
      this.recallApplicants?.length === 1
        ? (this.formData$.emit(this.formData),
          this.recallApplicants[0]['relationship'])
        : null;
  }
  /**
   * when the Close or Cancel actions are triggered this.clearData becomes true
   * and we will clear the SelectedValues
   */
  ngOnChanges() {
    if (this.clearData) {
      this.setIntialValues();
    }
  }
  /**
   * Subscribed to TypeAhead variable and calls searchQuestionApi with entered text
   */
  SearchQuestion() {
    this.searchQuestion$.pipe(debounceTime(500)).subscribe((text: string) => {
      this.searchQuestionApi(text);
    });
  }
  /**
   *
   * @param text keyword
   * api returns list of Questions either in Group or individual
   */
  searchQuestionApi(text: string) {
    const mergedQuestion = [];
    if (this.questionFilter === 'section') {
      this.searchApiService
        .getDrillDownQuestions(text)
        .subscribe((resp: SearchedQuestionsModal[]) => {
          this.searchedQuestions = resp;
          if (this.questionFilter === 'section') {
            this.searchedQuestions = this.searchedQuestions.flatMap(
              (eachQuestion) => {
                eachQuestion.questions.flatMap((each) => {
                  each['sectionName'] = eachQuestion['section'].displayName;
                  each['group'] = eachQuestion['section'].displayName;
                  return each;
                });
                delete eachQuestion['section'];
                mergedQuestion.push(...eachQuestion.questions);
                return eachQuestion;
              }
            );
            this.searchedQuestions = mergedQuestion;
          }
        });
    } else if (this.questionFilter === 'question') {
      this.searchApiService
        .getKeyWordQuestions(text)
        .subscribe((resp: SearchedQuestionsModal[]) => {
          this.searchedQuestions = resp;
        });
    }
  }
  /**
   * Entered custom Question will be added to listOfAddedQuestions array
   *
   */

  addCustomQuestionTolistofAddedQuestions() {
    const { Question } = new QuestionData(
      this.customQuestionText,
      this.formData.applicantName,
      this.formData.reason,
      null,
      this.formData.clientNo,
      this.formData.relationship
    );
    this.listOfAddedQuestions.push(Question);
    this.customQuestionText = null;
    this.listOfQuestions$.emit(this.listOfAddedQuestions);
  }

  clearCustomQuestionText() {
    this.customQuestionText = null;
  }
  /**
   *
   * @param selectedIndex index of the clicked Question
   * deletes Question From listOfAddedQuestions
   */
  deleteQuestionFromListOfQuestion(selectedIndex) {
    this.listOfAddedQuestions = this.listOfAddedQuestions.filter(
      (each, index) => {
        return index !== selectedIndex;
      }
    );
    this.listOfQuestions$.emit(this.listOfAddedQuestions);
  }
  /**
   *
   * @param index index of the clicked Question
   * edits Question From listOfAddedQuestions
   */
  editAddedQuestionFromList(index) {
    this.listOfAddedQuestions[index].isEdit = true;
  }
  /**
   *
   * @param index index of the clicked Question
   * @param editedQuestion text after editing
   * overriding the text in listOfAddedQuestions based on index
   */
  saveEditedQuestion(index, editedQuestion) {
    this.listOfAddedQuestions[index].isEdit = false;
    this.listOfAddedQuestions[index].text = editedQuestion;
    this.listOfQuestions$.emit(this.listOfAddedQuestions);
  }
  /**
   *
   * @param event data of changed input element(name,value)
   * setting the name and value to this.formData Object
   */
  selectChange(event) {
    const { value, name } = event.target;
    this.formData[name] =
      name === 'applicantName'
        ? this.selectedApplicant && this.selectedApplicant.fullName
        : value;
    this.formData.clientNo =
      this.selectedApplicant && this.selectedApplicant.clientNo;
    this.formData.relationship =
      this.selectedApplicant && this.selectedApplicant.relationship;
    this.formData$.emit(this.formData);
  }

  /**
   * push Selected Questions From QuestionBank to listOfAddedQuestions
   */
  ImportFromQuestionBank() {
    if (this.questionFilter === 'question') {
      this.selectedQuestions.map((eachquestion) => {
        const tags = eachquestion['details']?.options
          ? JSON.parse(eachquestion['details']?.options).map(
              (eachQ) => eachQ.value
            )
          : [];
        const { Question } = new QuestionData(
          eachquestion.text,
          this.formData.applicantName,
          this.formData.reason,
          tags,
          this.formData.clientNo,
          this.formData.relationship
        );
        this.listOfAddedQuestions.push(Question);
      });
      this.listOfQuestions$.emit(this.listOfAddedQuestions);
    } else {
      this.pushGroupQuestions();
    }
    this.selectedQuestions = null;
  }
  /**
   * if the Question Bank has Sections iterate over every element in the Array of SearchedQuestions
   * and pushed to listOfAddedQuestions
   */
  pushGroupQuestions() {
    let groupSelected = false;
    this.selectedQuestions.map((eachQuestion) => {
      if (!eachQuestion.text) {
        // if user selects the sectionsName(Parent selection) to select all Question this will iterate over the
        // questions from Search api and pushes to the listOfAddedQuestions with same sectionName
        this.searchedQuestions.forEach((eachSearchedQuestion: object) => {
          if (eachSearchedQuestion['group'] === eachQuestion.group) {
            const questionText = `${eachQuestion.group} - ${eachSearchedQuestion['text']}`;
            const tags = eachSearchedQuestion['details']?.options
              ? JSON.parse(eachSearchedQuestion['details']?.options).map(
                  (eachQ) => eachQ.value
                )
              : [];
            const { Question } = new QuestionData(
              questionText,
              this.formData.applicantName,
              this.formData.reason,
              tags,
              this.formData.clientNo
            );

            this.listOfAddedQuestions.push(Question);
            groupSelected = true;
          }
        });
      }
    });
    // if its not groupSelected list of selectedQuestions will be pushed to listOfQuestions
    if (!groupSelected) {
      this.selectedQuestions.map((eachquestion) => {
        const questionText = `${eachquestion.group} - ${eachquestion.text}`;
        const tags = eachquestion['details']?.options
          ? JSON.parse(eachquestion['details']?.options).map(
              (eachQ) => eachQ.value
            )
          : [];
        const { Question } = new QuestionData(
          questionText,
          this.formData.applicantName,
          this.formData.reason,
          tags,
          this.formData.clientNo
        );
        this.listOfAddedQuestions.push(Question);
      });
    }
    this.listOfQuestions$.emit(this.listOfAddedQuestions);
  }

  // Remove the html tags from the question text ex:- tooltip
  removeHtmlTags(QuestionText) {
    const dom = new DOMParser().parseFromString(
      '<!doctype html><body>' + QuestionText,
      'text/html'
    );
    const decodedString = dom.body.textContent;
    return decodedString;
  }
}
