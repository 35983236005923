<ng-container *ngIf="noText; else NoData">
  <p class="p-5 text-justify">{{ ezappNotes }}</p>
</ng-container>
<ng-template #NoData>
  <div class="p-5 text-justify">
    <p class="call-info m-1">
      No Data Available
    </p>
  </div>
</ng-template>
