import { Injectable } from '@angular/core';
import { VcallApiService } from '../../api/vcall/vcall-api.service';
import { TimerService } from '../../utility/timer.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';

@Injectable({
  providedIn: 'root',
})
export class SendProgressToAPI implements Processor {
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'SendProgressToAPI';

  constructor(
    private timer: TimerService,
    private vcallApiService: VcallApiService
  ) {}

  Process(model: ProcessorModel): Promise<ProcessingResult> {
    const duration = this.timer.getDuration(model.question.id);
    this.vcallApiService.trackQuestionDuration(model, duration).subscribe();

    return Promise.resolve({ result: true, stopProcessing: false });
  }
}
