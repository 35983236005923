import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyValue',
})
export class EmptyValuePipe implements PipeTransform {
  /**
   * @param value value
   * @param args arguments
   * @description check the empty value
   * @returns returns the arguments
   */
  transform(value: any, args?: any): any {
    return (args !== null || args !== undefined) &&
      (value === null || value === undefined)
      ? args
      : value;
  }
}
