import { Injectable } from '@angular/core';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';
import { CopyAnswerTagProcessor } from './copyAnswerTagProcessor';

@Injectable({
  providedIn: 'root',
})
export abstract class CopyPaymentJointBankProcessor
  extends CopyAnswerTagProcessor
  implements Processor {
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'CopyJointBank';

  Process(model: ProcessorModel): Promise<ProcessingResult> {
    return this.copy('Payment.MonthlyJointAccount', 'Payment.JointAccount');
  }
}
