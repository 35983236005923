import {
  FilterParameter,
  FilterConfiguration,
} from '../question-filter.service';
import { BaseFilter } from './base-filter';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { BaseApplicantFilter } from '../../applicant-filter/filters/applicant-attribute-filter';
const GREATERTHAN = '>';
const LESSTHAN = '<';
export class AttributeComparisonFilter extends BaseFilter {
  Type = 'AttributeComparison';

  Filter(parameter: FilterParameter) {
    if (parameter.config.params) {
      const comparisonOperator = parameter.config.params.filter((a) => {
        if (a.key === 'comparisonFilter') {
          return a.value;
        }
      });
      const parentProperty = parameter.config.path.split('.')[0];
      const childProperty = parameter.config.path.split('.')[1];
      let canDisplay = false;
      if (
        !Array.isArray(parameter.application[parentProperty]) &&
        this.parse(
          parameter.application[parentProperty][childProperty] +
            comparisonOperator[0] +
            parameter.config.value
        )
      ) {
        canDisplay = true;
      } else if (Array.isArray(parameter.application[parentProperty])) {
        const value = parameter.application[parentProperty].filter((obj) =>
          this.parse(
            obj[childProperty] +
              comparisonOperator[0].value +
              parameter.config.value
          )
        );
        if (value.length > 0) {
          canDisplay = true;
        }
      }
      return canDisplay;
    }
  }
  parse(str) {
    return Function(`'use strict'; return (${str})`)();
  }
}

export class ApplicantComparisonFilter extends BaseApplicantFilter {
  Type = 'ApplicantComparisonFilter';
  parse(str) {
    return Function(`'use strict'; return (${str})`)();
  }
  Filter(parameter: FilterParameter) {
    if (
      parameter.config.params[0].value.toString() === GREATERTHAN ||
      parameter.config.params[0].value.toString() === LESSTHAN
    ) {
      return this.parse(
        parseInt(parameter.applicant[parameter.config.path], 10) +
        parameter.config.params[0].value +
        parseInt(parameter.config.value, 10)
      );
    } else {
      return this.parse(
        `'` +
          parameter.applicant[parameter.config.path] +
          `'` +
          parameter.config.params[0].value +
          `'` +
          parameter.config.value +
          `'`
      );
    }
  }
}
