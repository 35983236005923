<div class="mt-3 card p-4 rounded">
  <div class="row m-0 justify-content-end">
    <button
      type="button"
      class="btn btn-outline-primary btn-sm ml-2 px-4"
      (click)="addprovider()"
    >
      Add Provider
    </button>
    <button
      type="button"
      class="btn btn-outline-primary btn-sm ml-2 px-4"
      (click)="createAddOn()"
      *ngIf="canDisplayAddonBtn"
    >
      Create Add On
    </button>
  </div>
  <ng-container *ngIf="addonLogs?.length; else noLogMessage">
    <app-addon-logs
      [applicationLogs]="addonLogs"
      (deleteAddonApplicant)="updateFetchApplicants($event)"
    ></app-addon-logs>
  </ng-container>
  <ng-template #noLogMessage>
    <div class="bg-success p-2 mt-3 text-center">
      There are no Add On's found for this application.
    </div>
  </ng-template>
</div>

<!-- Add applicant popup starts from here -->
<app-addon-applicant
  [openAddApplicantModal]="openApplicantModal"
  (closeApplicantModal)="closeApplicantModal($event)"
></app-addon-applicant>
<!-- Add applicant popup ends here -->

<app-modal
  [open]="canDisplayProvider"
  [modalOptions]="{ size: 'xl' }"
  [buttonsConfig]="{
    modalMode: 'form',
    position: 'center',
    submitButtonName: 'Save',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event)"
>
  <div title class="text-center">
    Add provider
  </div>
  <div body>
    <app-vcall-doc-lookup
      [initialDoctorList]="initialList"
      [questionDetails]="details"
      [showProviderConfirmation]="false"
      [resetRequest$]="resetProviderRequest"
      (notifyProviderLookup)="getUpdatedDrList($event)"
    >
    </app-vcall-doc-lookup>
  </div>
</app-modal>
