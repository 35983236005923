import { FilterParameter, FilterResult } from '../question-filter.service';

export abstract class BaseFilter {
  abstract Type: string;
  protected abstract Filter(parameter: FilterParameter): boolean;

  public Apply(parameter: FilterParameter): FilterResult {
    const canDisplay = this.Filter(parameter);
    console.log(
      'Type : ' +
        this.Type +
        ' AnswerTag : ' +
        (parameter.question ? parameter.question.answerTag : 'null answerTag') +
        ' CanDisplay : ' +
        canDisplay
    );
    return { canDisplay, type: this.Type };
  }
}
