<form [formGroup]="inputGeneric">
  <div class="row">
    <div class="p-3 col-12">{{ details.textboxTitle }}</div>
    <div class="col-2 pt-3">
      <input
        type="textarea"
        class="form-control"
        name="detail"
        (blur)="SaveText()"
        (keyup)="EnableNext()"
        [minlength]="details.minLength"
        [maxlength]="details.maxLength"
        [max]="details.maxValue"
        [(ngModel)]="currentAnswer.notes"
        [formControlName]="details.answerTag"
        required
      />
    </div>
  </div>
  <div
    *ngIf="isValid(details.answerTag) !== null"
    class="mt-1 text-danger small"
  >
    <div *ngIf="isValid(details.answerTag).required">
      Required!
    </div>
    <div
      *ngIf="
        isValid(details.answerTag).maxLength ||
        isValid(details.answerTag).minLength ||
        isValid(details.answerTag).max
      "
    >
      {{ details.answerTag }} cannot be more than {{ details.maxValue }}!
    </div>
  </div>
</form>

<div
  *ngIf="question.instructions"
  class="col-sm-12 alert alert-warning text-center mt-3"
  role="alert"
>
  {{ question.instructions }}
</div>
