import { Injectable, Injector } from "@angular/core";
import { ApplicationService } from "../shared/services/application/application.service";
import { AppointmentService } from "../shared/services/appointment/appointment.service";
import { UserProfileService } from "../shared/services/user/user-profile.service.service";
import { LogData } from './log-data';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LogDataService {
  logDataDictionary: Array<any> = [];
  applicationService: ApplicationService;
  appointmentService: AppointmentService;
  userProfileService: UserProfileService

  constructor(private injector: Injector) {}

  initializeServices() {
    if(!this.applicationService)  this.applicationService = this.injector.get(ApplicationService);
    if(!this.appointmentService)  this.appointmentService = this.injector.get(AppointmentService);
    if(!this.userProfileService)  this.userProfileService = this.injector.get(UserProfileService);
  }

  createLogData(logData: any = undefined): string {
    this.initializeServices();

    var data = logData || new LogData(
      this.applicationService,
      this.appointmentService,
      this.userProfileService
    );

    const { applicationId, appointmentId, resourceId, userName } = data;
    
    var requestId = crypto.randomUUID();

    this.logDataDictionary.push({
      requestId,
      applicationId,
      resourceId,
      appointmentId,
      userName
    });

    return requestId;
  }

  getLogData(requestId: string): any {
    // lodash find will return undefined if it cant find it.
    return _.find(this.logDataDictionary, (x:any) => x.requestId === requestId);
  }

  deleteLogData(requestId: string): boolean {
    if(_.isNil(requestId) || _.isEmpty(requestId)) return false;

    _.remove(this.logDataDictionary, (x: any) => x.requestId === requestId);

    var data = _.find(this.logDataDictionary, (x:any) => x.requestId === requestId);
    return data === undefined;
  }
}