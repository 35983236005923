import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-item-value',
  templateUrl: './review-item-value.component.html',
  styleUrls: ['./review-item-value.component.scss'],
})
export class ReviewItemValueComponent implements OnInit {
  @Input() value: any;

  constructor() {}

  simpleValueOutput: string;
  arrayValueOutput: any[];
  objectValueOutput: any;

  ngOnInit() {
    let simpleValue;
    if (this.value) {
      simpleValue = this.value.toString();

      if (simpleValue === 'true' || simpleValue === 'Y') {
        this.simpleValueOutput = 'Yes';
      } else if (simpleValue === 'false' || simpleValue === 'N') {
        this.simpleValueOutput = 'No';
      } else {
        const temp = this.value;
        if (this.tryParseJSON(temp)) {
          const json = JSON.parse(temp);
          if (Array.isArray(json)) {
            this.arrayValueOutput = json;
          } else {
            this.objectValueOutput = json;
          }
        } else {
          this.simpleValueOutput = this.value;
        }
      }
    }
  }

  objectKeys(item) {
    return Object.keys(item);
  }

  tryParseJSON(jsonString) {
    try {
      const o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {}

    return false;
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
