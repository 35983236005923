import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpResponse,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { of, Observable } from 'rxjs';

@Injectable()
export class DataMockingInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.method === 'POST' || request.method === 'PUT') {
      for (const element of postUrls) {
        if (request.url.match(element.urlRegx)) {
          console.log('intercepted post for :' + request.url);
          console.log(request.body);
          console.log('Responding from interceptor : ' + request.url);
          return of(new HttpResponse({ status: 200, body: element.json }));
        }
      }
    }

    if (request.method === 'DELETE') {
      for (const element of deleteUrls) {
        if (request.url.match(element.urlRegx)) {
          console.log('intercepted post for :' + request.url);
          console.log('Responding from interceptor : ' + request.url);
          return of(new HttpResponse({ status: 200, body: element.json }));
        }
      }
    }

    for (const element of urls) {
      if (request.urlWithParams.match(element.urlRegx)) {
        console.log('Loaded from json  : ' + request.url);
        return of(new HttpResponse({ status: 200, body: element.json }));
      }
    }
    return next.handle(request);
  }
}

const postUrls = [
  {
    urlRegx: /questionTracker/,
    json: {
      response: true,
    },
  },
];

const deleteUrls = [];

const urls = [
  {
    urlRegx: /\/scriptxyz/,
    json: {
      applicationId: 4002501,
      items: [
        {
          section: {
            sectionId: 17,
            name: 'Introduction',
            displayName: 'Introduction-Association|Dental|Vision',
            sequence: 1,
            sectionPropertyBag: [
              {
                key: 'Rule',
                value: 'HasOtherCoverage',
                section: null,
              },
            ],
          },
          questions: [
            {
              id: 6,
              text:
                'Hi this is {{agent.name}} with {{application.company}} Company of America a USHEALTH Group company. I am calling to go over the information provided in your recent electronic {{application.product.description}} insurance application. Is this still a good time?',
              instructions: 'Cannot proceed without a "Yes" response.',
              value: null,
              type: 'Boolean',
              answerTag: 'IntroductionGreeting',
              displayWhen: null,
              sequence: 1,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 9,
              text:
                'First we want to let you know this call is being recorded for quality assurance purposes and this recorded call will become part of your Application file. We will be relying on your responses and authorizations during this call to confirm information on your application, and to use as your electronic signatures on the Application and other documents in the application Package. If this is okay with you, can we get started? ',
              instructions: 'Cannot proceed without a "Yes" response.',
              value: null,
              type: 'Boolean',
              answerTag: 'IntroductionCallRecorded',
              displayWhen: null,
              sequence: 2,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 10,
              text: 'I see on your Application that you have selected our :',
              instructions: 'Cannot proceed without a "Yes" response.',
              value: null,
              type: 'Product',
              answerTag: 'IntroductionProductConfirmation',
              displayWhen: null,
              sequence: 3,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
          ],
        },
        {
          section: {
            sectionId: 23,
            name: 'Payment',
            displayName: 'Payment',
            sequence: 2,
            sectionPropertyBag: [
              {
                key: 'Rule',
                value: '',
                section: null,
              },
            ],
          },
          questions: [
            {
              id: 1,
              text:
                'Who is the authorized Bank account holder for the Banking information on your application?',
              instructions: null,
              value: null,
              type: 'RadioButtonList',
              details: {
                options:
                  '[{ "key" : "PayorIsApplicant" , "value" : "Payor is the same as the applicant"}, { "key" : "PayorIsSomeoneElseAndOnline" , "value" : "Payor is a third party and available now"}, {"key":"PayorIsSomeoneElseAndOffline" , "value" : "Payor is a third party and not available now"}]',
              },
              answerTag: 'ApplicationPayor',
              displayWhen: null,
              sequence: 1,
              allowNextForValue: null,
              required: true,
            },
            {
              id: 2,
              text: '',
              instructions: null,
              value: null,
              type: 'PayorInformation',
              details: {
                PayorIsApplicant:
                  'As you told us that you will be paying for the application, can you please confirm the following details.',
                PayorIsSomeoneElseAndOnline:
                  'As you told us that some one else will pay for the insurance, may I talk to them to collect some information.',
                PayorIsSomeoneElseAndOffline:
                  'As you told us that some one else will pay for the insurance, and since they are not available, let me collect their contact information so we can get in touch with them later.',
              },
              answerTag: 'PayorInformation',
              displayWhen: null,
              sequence: 2,
              allowNextForValue: null,
              required: true,
            },
            {
              id: 3,
              text: '',
              instructions:
                'Read if Initial and Recurring Payment is Bank Draft',
              value: null,
              type: 'MonthlyPaymentMode',
              answerTag: 'Payment.MonthlyPaymentMode',
              displayWhen: null,
              sequence: 3,
              allowNextForValue: null,
              required: null,
              details: {
                directBillText: 'Direct Billing',
                directBillValue: 'DIRECT BILLING',
                bankDraftText: 'Bank Draft',
                bankDraftValue: 'BANK DRAFT',
                modeOfPaymentQuestion:
                  'Could you confirm the monthly payment type as ',
                changeModeOfPaymentQuestion:
                  'Would you like to change the payment method?',
              },
            },
            {
              id: 4,
              text:
                'I have the name of your bank as {{application.payment.bankName}}. I have your routing number as  {{application.payment.bankRoutingCode}}. I have the last 4 digits of your account number as {{application.payment.bdAccountNumber}}',
              instructions:
                'Read if Initial and Recurring Payment is Bank Draft',
              value: null,
              type: 'BankInformation',
              answerTag: 'Payment.BankInformation',
              displayWhen:
                '[{"type": "ApplicationAttribute","path": "payment.methodOfPayment","value": "BANK DRAFT"}]',
              sequence: 4,
              allowNextForValue: 'true',
              required: true,
              details: {
                verifiedText: 'Verified',
                verifiedValue: 'true',
                notVerifiedText: 'Not Verified',
                notVerifiedValue: 'false',
                isEdit: 'false',
              },
            },
            {
              id: 5,
              text: 'Is this a Joint Bank Account?',
              instructions: 'Cannot proceed without a "Yes/No" response.',
              value: null,
              type: 'Boolean',
              answerTag: 'payment.monthlyJointAccount',
              displayWhen:
                '[{"type": "ApplicationAttribute","path": "payment.methodOfPayment","value": "BANK DRAFT"}]',
              sequence: 5,
              allowNextForValue: '',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 6,
              text: '',
              instructions: '',
              value: null,
              type: 'Dropdown',
              answerTag: 'payment.monthlyFamilyInformation',
              displayWhen:
                '[{"type": "ApplicationAttribute","path": "payment.methodOfPayment","value": "BANK DRAFT"}]',
              sequence: 6,
              allowNextForValue: null,
              required: true,
              details: {
                jointAccountQuestion:
                  'What is your relationship to  {{application.primaryApplicant.firstName}}   {{application.primaryApplicant.lastName}}',
                options: '["Spouse","Son","Daughter","Father","Mother"]',
              },
            },
            {
              id: 7,
              text: '',
              instructions: null,
              value: null,
              type: 'MonthlyEffectiveDate',
              answerTag: 'Payment.MonthlyEffectiveDate',
              displayWhen: null,
              sequence: 7,
              allowNextForValue: null,
              required: true,
              details: {
                verifiedText: 'Verified',
                verifiedValue: 'true',
                notVerifiedText: 'Not Verified',
                notVerifiedValue: 'false',
                specificDateQuestion:
                  'I have your monthly payment effective date as ',
                specificDate: 'Specific Date',
                nextDate: 'On the next Date',
                applicationApproval: 'Date of Application Approval',
                frequency: '["MONTHLY","QUARTERLY","SEMI-ANNUALLY","ANNUALLY"]',
                frequencyQuestion: 'I have your monthly payment frequency as ',
                effectiveDateRules:
                  '{"defaultValue" : 60,"rules" : [{"startMonth" : "01", "startDate":"01", "endMonth" : "10", "endDate" : "31", "value" : "30"},{"startMonth" : "11", "startDate":"01", "endMonth" : "12", "endDate" : "31", "value" : "45"}]}',
              },
            },
            {
              id: 8,
              text: '',
              instructions: null,
              value: null,
              type: 'BankAuthorization',
              answerTag: 'Payment.BankAuthorization',
              displayWhen:
                '[{"type": "ApplicationAttribute","path": "payment.methodOfPayment","value": "BANK DRAFT"}]',
              sequence: 8,
              allowNextForValue: true,
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: true,
                noText: 'No',
                noValue: false,
              },
            },
            {
              id: 9,
              text: '',
              instructions:
                'Read if Initial and Recurring Payment is Bank Draft',
              value: null,
              type: 'InitialPaymentMode',
              answerTag: 'Payment.InitialPaymentMode',
              displayWhen: null,
              sequence: 9,
              allowNextForValue: null,
              required: false,
              details: {
                creditCardText: 'Credit Card',
                creditCardValue: 'CREDIT CARD',
                bankDraftText: 'Bank Draft',
                bankDraftValue: 'BANK DRAFT',
                modeOfPaymentQuestion:
                  'Could you confirm the Initial payment type as ',
                changeModeOfPaymentQuestion:
                  'Would you like to change the payment method?',
              },
            },
            {
              id: 10,
              text: null,
              instructions: null,
              value: null,
              type: 'BankInitialView',
              answerTag: 'Payment.BankInitialView',
              displayWhen:
                '[{"type": "ApplicationAttribute","path": "payment.initialPaymentBy","value": "BANK DRAFT"}]',
              sequence: 10,
              allowNextForValue: null,
              required: true,
              details: {
                verifiedText: 'Verified',
                verifiedValue: 'true',
                notVerifiedText: 'Not Verified',
                notVerifiedValue: 'false',
              },
            },
            {
              id: 11,
              text: 'Is this a Joint Bank Account?',
              instructions: 'Cannot proceed without a "Yes/No" response.',
              value: null,
              type: 'Boolean',
              answerTag: 'payment.jointAccount',
              displayWhen:
                '[{"type": "ApplicationAttribute","path": "payment.initialPaymentBy","value": "BANK DRAFT"}]',
              sequence: 11,
              allowNextForValue: null,
              required: null,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 12,
              text: '',
              instructions: '',
              value: null,
              type: 'Dropdown',
              answerTag: 'payment.familyInformation',
              displayWhen:
                '[{"type": "ApplicationAttribute","path": "payment.initialPaymentBy","value": "BANK DRAFT"}]',
              sequence: 12,
              allowNextForValue: null,
              required: true,
              details: {
                jointAccountQuestion:
                  'What is your relationship to  {{application.primaryApplicant.firstName}}   {{application.primaryApplicant.lastName}}',
                options: '["Spouse","Son","Daughter","Father","Mother"]',
              },
            },
            {
              id: 13,
              text: null,
              instructions: null,
              value: null,
              type: 'BankInitialAuthorization',
              answerTag: 'Payment.BankInitialAuthorization',
              displayWhen:
                '[{"type": "ApplicationAttribute","path": "payment.initialPaymentBy","value": "BANK DRAFT"}]',
              sequence: 13,
              allowNextForValue: null,
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
          ],
        },
        {
          section: {
            sectionId: 19,
            name: 'Demographic',
            displayName: 'Demographic-Association|Dental|Vision',
            sequence: 3,
            sectionPropertyBag: [
              {
                key: 'Rule',
                value: 'HasOtherCoverage',
                section: null,
              },
            ],
          },
          questions: [
            {
              id: 37,
              text:
                'You understand that dependent children on your application can have access to your membership benefits as long as they are under the age of 19 years, or they are a dependent up to 24 years of age and enrolled as a full-time student. Correct?',
              instructions: 'Read only if dependent listed on application.',
              value: null,
              type: 'Boolean',
              answerTag: 'ChildrenAgeMessage',
              displayWhen: null,
              sequence: 1,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 29,
              text:
                'You understand to add a family member to your membership that you will have to complete a supplemental enrollment application form and the enrollment of the family member will become effective until when acknowledged in writing by the association. Correct?',
              instructions:
                'Advise customer we will send email to Agent to contact them. End Call with Reason.',
              value: null,
              type: 'Boolean',
              answerTag: 'SupplementalEnrollmentForm',
              displayWhen: null,
              sequence: 2,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 39,
              text:
                'And to verify your understanding of the privileges, discounts and benefits available to members, You understand as a member, you are entitled to many privileges, discounts and benefits such as HRA planning and Web services, along with discounts on office supplies, payroll processing, LensCrafters,Reebok, and access to MDLive – just to name a few. Correct? ',
              instructions: null,
              value: null,
              type: 'DemographicApplicants',
              answerTag: 'DemographicApplicants',
              displayWhen: null,
              sequence: 3,
              allowNextForValue: null,
              required: false,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 40,
              text:
                'You also understand that a member can only be enrolled once, a member cannot have duplicate or multiple memberships. Correct?',
              instructions: null,
              value: null,
              type: 'Boolean',
              answerTag: 'DuplicateMembershipWarning',
              displayWhen: null,
              sequence: 4,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 41,
              text:
                'Are you and your spouse listed on the application lawfully married?',
              instructions:
                'When Spouse is listed on application, ask the following question',
              value: null,
              type: 'Boolean',
              answerTag: 'MarriageConfirmation',
              displayWhen: null,
              sequence: 5,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 42,
              text: '',
              instructions: null,
              value: null,
              type: 'DemographicAddress',
              answerTag: 'DemographicAddress',
              displayWhen: null,
              sequence: 6,
              allowNextForValue: null,
              required: null,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
          ],
        },
        {
          section: {
            sectionId: 20,
            name: 'Closing',
            displayName: 'Closing-Association|Dental|Vision',
            sequence: 4,
            sectionPropertyBag: [
              {
                key: 'Rule',
                value: 'HasOtherCoverage',
                section: null,
              },
            ],
          },
          questions: [
            {
              id: 15,
              text:
                'So, to finish up, then, I want to confirm that I have your permission to use your verbal statements and authorizations during this call as confirmation of your electronic signature to complete your Application package?',
              instructions:
                'Advise customer we will send email to Agent to contact them. End Call with Reason.',
              value: null,
              type: 'Boolean',
              answerTag: 'ElectronicSignatureConfirmation',
              displayWhen: null,
              sequence: 1,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 17,
              text:
                'Well that completes this verification call. Thank you for your time. After your enrollment has been processed, you should receive in the mail a Membership Guide that will provide you detailed information on how to access all of the benefits included with your membership. If you have any questions about your membership benefits, please call our Membership Services department at 1-800-992-8044. Thank you and have a great day.',
              instructions: '',
              value: null,
              type: 'Boolean',
              answerTag: 'CallCompleteMessage',
              displayWhen: null,
              sequence: 2,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 16,
              text:
                'As a valued applicant, I would like to remind you that, if approved, your coverage packet may be e-mailed to you via secure e-mail. If you have not already provided your authorization, you will be receiving an e-mail at the completion of this verification call requesting confirmation of you authorization.',
              instructions: 'Click Next after reading',
              value: null,
              type: 'Boolean',
              answerTag: 'PostApprovalMessage',
              displayWhen: null,
              sequence: 3,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
              },
            },
            {
              id: 42,
              text:
                'Thanks again for being so patient today and for choosing us for your health insurance needs. If you have any questions, please call us at 1-800-387-9027 ICA) 1-800-221-9039 (NFL) 1-800-606-4482 (ELIC).',
              instructions: null,
              value: null,
              type: 'Closing',
              answerTag: 'FinalThankYouAndClosing',
              displayWhen: null,
              sequence: 4,
              allowNextForValue: 'true',
              required: true,
              details: {
                yesText: 'Yes',
                yesValue: 'true',
                noText: 'No',
                noValue: 'false',
                endCallPopupText:
                  'That is all the quesitons I have. Please be advised that the coverage you are applying for is not effective until approved, and your first payment applied as of the effective date of coverage. If you have coverage today, we recommend you not to cancel your coverage until you receive and review your Certificate, if issued. <br /> <br />As a valued applicant, I would like to remind you that, if approved, your coverage packet may be e-mailed to you via secure e-mail. If you have not already provided your authorization, you will be receiving an e-mail at the completion of this verificaiton call requesting confirmation of you authorization. <br /> <br />Thank you for your valuable time to complete this interview. We appricate you choosing us for your health insurance needs. If you have any questions, please contact us at 1-800-387-9027.',
              },
            },
          ],
        },
      ],
    },
  },
];
