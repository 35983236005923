import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { UserTrainingDocumentsComponent } from './user-training-documents/user-training-documents.component';
import { SafePipeModule } from 'safe-pipe';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { BusinessReleaseNotesComponent } from './business-release-notes/business-release-notes.component';
const routes: Routes = [
  {
    path: '',
    component: UserGuideComponent,
  },
];

@NgModule({
  declarations: [
    UserGuideComponent,
    UserTrainingDocumentsComponent,
    ReleaseNotesComponent,
    BusinessReleaseNotesComponent,
  ],
  imports: [CommonModule, RouterModule.forChild(routes), SafePipeModule],
})
export class UserGuideModule {}
