import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datedisplay',
})
export class DatedisplayPipe implements PipeTransform {
  /**
   * @param value value as a string
   * @description Date display pipe format
   */
  transform(value: string) {
    const d = new Date(value);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [month, day, year].join('-');
  }
}
