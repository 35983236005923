<div class="mw-auto m-0 p-0">
  <div class="row m-0 p-0">
    <div class="col-3 left-col">
      <app-appointments-list></app-appointments-list>
    </div>
    <div class="col-9">
      <app-appointments-detail></app-appointments-detail>
    </div>
  </div>
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="transparent"
    size="default"
    color="#000000"
    type="ball-spin-clockwise"
    [fullScreen]="true"
  >
  </ngx-spinner>
</div>
