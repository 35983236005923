import { ApplicationService } from '../../services/application/application.service';
import { Answer } from '../application/answer';
import { Appointment } from '../appointment';
import { CallStatus, DialingType, VoiceMailModelInfo } from './dialingType';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { Subscription } from 'rxjs';

export class VoiceMailModel {
  dialingState: string;
  callStatus: CallStatus;
  attempt: number;
  selectedAppointent: Appointment;
  isAppointmentStillAvailable = true;
  subscriptions = new Array<Subscription>();

  constructor(
    private service: ApplicationService,
    private appointment: Appointment,
    private appointmentService: AppointmentService
  ) {
    this.selectedAppointent = JSON.parse(JSON.stringify(appointment));
    this.subscriptions.push(
      this.appointmentService.Appointments.subscribe((a) => {
        if (a && a.length) {
          const isAppointmentStillAvailable = a.some(
            (appt) =>
              appt.appointmentId === this.selectedAppointent.appointmentId
          )
            ? true
            : false;

          // only process if changed;
          if (
            this.isAppointmentStillAvailable !== isAppointmentStillAvailable
          ) {
            this.isAppointmentStillAvailable = isAppointmentStillAvailable;
            this.processAppointmentReassign();
          }
        }
      })
    );

    this.process(true);
  }

  public setCallStatus(callStatus: CallStatus) {
    this.callStatus = callStatus;
  }

  // this components needs more refactoring
  private storeCurrentStateAndTime() {
    const answerTag =
      VoiceMailModelInfo.voiceMailData +
      '-' +
      this.selectedAppointent.appointmentId;
    const answer = new Answer();
    answer.answerTag = answerTag;
    const voiceMail = {
      attempt: this.dialingState === DialingType.voiceScript ? 1 : 0,
      time: this.dialingState === DialingType.clockTimer ? new Date() : null,
      currentState: this.dialingState,
    };
    answer.value = voiceMail;
    this.service.RegisterAnswerByTag(true, voiceMail, answerTag, null, null);
  }

  public getSavedAnswer() {
    const answerTag =
      VoiceMailModelInfo.voiceMailData +
      '-' +
      this.selectedAppointent.appointmentId;
    const savedAnswer = this.service.GetAnswerForAnswerTag(answerTag);
    if (savedAnswer !== undefined && savedAnswer !== null) {
      const date = savedAnswer.value.time;
      const currTimeSec = new Date().getTime() / 1000;
      const callNoteTimeSec = new Date(date).getTime() / 1000;
      const isTimeLessThreeMin = Number(
        (currTimeSec - callNoteTimeSec).toFixed()
      );
      return {
        timeDiff: isTimeLessThreeMin,
        answer: savedAnswer,
      };
    }
  }

  process(init = false) {
    if (init === false) {
      // TODO: revisit this logic, keeping it around not to break anything else;
      this.storeCurrentStateAndTime();
    }

    const savedAnswer = this.getSavedAnswer();
    const lastDialingState = savedAnswer
      ? savedAnswer.answer.value.currentState
      : null;
    switch (lastDialingState) {
      case DialingType.initialDialing:
        this.dialingState = DialingType.initialDialing;
        break;
      case DialingType.voiceScript:
        this.dialingState = DialingType.voiceScript;
        break;
      case DialingType.clockTimer:
        if (savedAnswer.timeDiff < 180) {
          this.dialingState = DialingType.clockTimer;
        } else if (savedAnswer.timeDiff >= 180) {
          this.TimerElapsed();
        }
        break;
      case DialingType.followupDialing:
        this.dialingState = DialingType.followupDialing;
        break;
      case DialingType.regNoShow:
        this.dialingState = DialingType.regNoShow;
        break;
      default:
        this.dialingState = DialingType.initialDialing;
    }
  }

  processAppointmentReassign() {
    if (!this.isAppointmentStillAvailable) {
      // it means the appointment got reassigned
      this.dialingState = DialingType.isSelectedAppointment;
      return;
    }
  }

  public VoiceMailProcess(dialingState) {
    if (dialingState === DialingType.followupDialing) {
      this.SecondVoiceMailConfirmation();
    } else {
      this.VoiceMailConfirmation();
    }
  }

  public VoiceMailConfirmation() {
    this.dialingState = DialingType.voiceScript;
    this.storeCurrentStateAndTime();
    this.service.PersistAnswers(false);
  }

  public CancelVoiceMailDialog() {
    this.dialingState = DialingType.clockTimer;
    this.storeCurrentStateAndTime();
    this.service.PersistAnswers(false);
  }

  public TimerElapsed() {
    this.dialingState = DialingType.followupDialing;
    this.storeCurrentStateAndTime();
    this.service.PersistAnswers(false);
  }

  public SecondVoiceMailConfirmation() {
    this.dialingState = DialingType.regNoShow;
    this.storeCurrentStateAndTime();
    this.service.PersistAnswers(false);
  }

  public SubmitButtonClickedInSecondVoicemailScreen() {
    this.dialingState = DialingType.initialDialing;
    this.storeCurrentStateAndTime();
    this.service.PersistAnswers(false);
  }

  public BackToSecondVociemailScreen() {
    this.dialingState = DialingType.followupDialing;
    this.storeCurrentStateAndTime();
    this.service.PersistAnswers(false);
  }

  public Reset() {
    this.subscriptions.map((s) => s.unsubscribe());
  }

  public checkSelectedAppointment() {}
}
