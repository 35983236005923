import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { ApplicationService } from '../../services/application/application.service';
import { ScriptService } from '../../services/script/script.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent implements OnInit, OnDestroy {
  logo: string;
  constructor(
    private profileService: UserProfileService,
    private route: Router,
    private appointmentService: AppointmentService,
    private applicationService: ApplicationService,
    private scriptService: ScriptService,
    private activeRoute: ActivatedRoute
  ) {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const activatedChild = this.activeRoute.firstChild;
        this.logo = activatedChild.snapshot.data.logo;
      }
    });
  }
  today: string = new Date().toDateString();
  profile: UserProfile;
  subscription: Subscription;
  roles = '';
  tab = 'home';
  ngOnInit() {
    this.subscription = this.profileService.userProfile.subscribe((s) => {
      this.profile = s;
      this.getCallZoneUser();
    });
  }
  getCallZoneUser() {
    this.profileService.callZoneUser.subscribe((u) => {
      if (u) {
        this.profile['timeZone'] = u.timeZone;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  navToModule(navUrl, tabVal) {
    this.tab = tabVal;
    this.appointmentService.Clear();
    this.applicationService.Clear();
    this.scriptService.Clear();
    this.route.navigate([`./${navUrl}`]);
  }
}
