<table
  class="table table-striped mb-4 table-sm"
  *ngFor="let familyAil of familyAilmentsList"
>
  <thead class="table-active">
    <tr>
      <th colspan="5">
        {{ familyAil.applicantName }} ( {{ familyAil.applicantType }} )
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let ail of familyAil[familyAil.clientNo]">
      <tr *ngFor="let a of ail">
        <td *ngIf="a !== undefined" style="width: 20%;" class="align-middle">
          {{ a.ailment }}
        </td>
        <td *ngIf="a !== undefined" style="width: 55%;" class="align-middle">
          {{ a.ailmentDescription }}
        </td>
        <td style="width: 10%;" class="align-middle text-center">
          <div class="box">
            <span
              class="badge badge-pill p-1 pr-2 pl-2"
              [ngClass]="progress(a.status, 'badge')"
              >{{ a.status === "Inprogress" ? "In-Progress" : a.status }}</span
            >
          </div>
        </td>
        <td class="align-middle text-center navigate" style="width: 10%;">
          <button
            class="btn btn-md"
            [ngClass]="progress(a.status, 'btn')"
            (click)="goToDetails(a, familyAil.applicantName)"
          >
            {{ progress(a.status, "btnText") }}
          </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<div class="row justify-content-end">
  <div
    *ngIf="question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>
</div>

<app-modal
  [open]="openModal"
  [modalOptions]="{ size: 'lg' }"
  [footer]="false"
  (actions)="ModalAction($event)"
>
  <div title class="header-title">
    <h5 class="modal-title" id="modal-basic-title">
      {{ AilmentReceived?.ailment || "Ailment Drilldown questions" }}
    </h5>
    <h6 class="text-muted m-0">{{ AilmentReceived?.applicantName }}</h6>
  </div>
  <div body>
    <app-dynamic-details
      *ngIf="openModal"
      [AlimentData]="AilmentReceived"
      (actions)="DynamicActions($event)"
    ></app-dynamic-details>
  </div>
</app-modal>
