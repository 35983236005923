import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private logger: LoggerService) {
    super();
  }

  handleError(error: Error) {
    this.logger.logException(error); // Manually log exception
  }
}
