import { Application } from '../application/application';
import { CallZoneUser } from '../callzone-user';
import { Appointment } from '../appointment';
import { Applicant } from '../application/applicant';

export class TemplateModel {
  application: Application;
  agent: CallZoneUser;
  appointment: Appointment;
  selectedApplicant: Applicant;
}
