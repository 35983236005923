<div class="container">
  <h5 class="pt-3 font-weight-bold" *ngIf="doctorInitialList.length > 0">
    Initial list of Doctors ( {{ doctorInitialList.length }} )
  </h5>
  <div class="container pt-3" *ngIf="doctorInitialList.length > 0">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th>Street Address</th>
          <th>City</th>
          <th>State</th>
          <th>Zip Code</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of doctorInitialList">
          <th>
            {{ el.first_name | titlecase }} {{ el.last_name | titlecase }}
          </th>
          <td>{{ el.street_address }}</td>
          <td>{{ el.city }}</td>
          <td>{{ el.state }}</td>
          <td>{{ el.zipcode }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h5
    class="pt-3 border-top font-weight-bold"
    *ngIf="updatedDoctorList.length > 0"
  >
    Updated List of Doctors ( {{ updatedDoctorList.length }} )
  </h5>
  <div class="container pt-3" *ngIf="updatedDoctorList.length > 0">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th>Street Address</th>
          <th>City</th>
          <th>State</th>
          <th>Zip Code</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of updatedDoctorList">
          <th>
            {{ el.first_name | titlecase }} {{ el.last_name | titlecase }}
          </th>
          <td>{{ el.street_address }}</td>
          <td>{{ el.city }}</td>
          <td>{{ el.state }}</td>
          <td>{{ el.zipcode }}</td>
          <td>{{ el.tax_id }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <hr />
  <app-vcall-doc-lookup
    [initialDoctorList]="doctorInitialList"
    (notifyProviderLookup)="getUpdatedDrList($event)"
  >
  </app-vcall-doc-lookup>
</div>
