import { Component, OnInit } from '@angular/core';
import { Answer } from 'src/app/shared/models/application/answer';
import { TemplateModel } from 'src/app/shared/models/vcall/templateModel';
import { VcallParams } from 'src/app/shared/models/vcall/vcallParams';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ProcessingService } from 'src/app/shared/services/processors/processing.service';
import { ProcessorModel } from 'src/app/shared/services/processors/processor-model';
import {
  ScriptService,
  VcallMode,
} from 'src/app/shared/services/script/script.service';

@Component({
  selector: 'app-processor-test',
  templateUrl: './processor-test.component.html',
  styleUrls: ['./processor-test.component.scss'],
})
export class ProcessorTestComponent implements OnInit {
  model: ProcessorModel = {};

  constructor(
    private service: ProcessingService,
    private appService: ApplicationService,
    private script: ScriptService,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    const applicationId = '4003418';
    this.service.Run('test', null).then((r) => console.log(r));
    this.appService.SelectApplication(applicationId);

    const vcall = new VcallParams();
    vcall.applicationId = applicationId;
    vcall.mode = VcallMode.Standard;
    vcall.appointmentId = '562';
    vcall.stopNavigation = 'true';
    this.script.LoadScript(vcall);

    this.appointmentService.RouteChanged(vcall);
    this.appService.SelectedApplication.subscribe(
      (a) => (this.model.application = a)
    );
    this.appService.Answers.subscribe((a) => (this.model.answers = a));
    this.script.ApplicationScript.subscribe((s) => (this.model.script = s));
    this.appointmentService.SelectedAppointment.subscribe(
      (a) => (this.model.appointment = a)
    );
  }

  runsubmit() {
    this.service.RunCompleteStage(this.model).then((r) => console.log(r));
  }
}
