const getReleaseNumberFromBuild = async () => {
  const headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  const response = await fetch('/', {
    method: 'GET',
    headers,
  });
  const xml = await response.text();
  return xml;
};

const parseReleasedVersion = (xml) =>
  xml.match(/DEFAULT_VERSION_NUMBER=".*"/g)
    ? xml.match(/DEFAULT_VERSION_NUMBER=".*"/g)[0]
    : '';

export { getReleaseNumberFromBuild, parseReleasedVersion };
