import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { CreateApplicationTrackerRecord } from 'src/app/underwriting/components/add-on/addon-application';
import {
  GetOrderDocuments,
  GetOrders,
  AuditNote,
  OrderRequestDetails,
  RouteToAPS,
} from 'src/app/underwriting/components/additional-info/additional-info-modal';
import {
  CREATE_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER,
  getOrderRequest,
  ROUTETO_APS,
  ORDER_DOCUMENTS_REQUEST,
  GET_AUDIT_NOTES,
  INSERT_AUDIT_NOTE,
  CREATE_APPLICATION_TRACKER_RECORD,
  GET_APPLICATION_TRACKER_RECORDS,
} from '../../../graphql/query/query-config';
import { errorMessage } from '../../models/graphql-error-message';
import { successMessage } from '../../models/graphql-success-message';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NbcApiService {
  MAX_PREVIEW_SIZE: number = 5242880; // 5MB
  constructor(private apollo: Apollo, private http: HttpClient) {}

  getOrders(applicationId: number) {
    return this.apollo.query({
      query: getOrderRequest(applicationId),
      context: {
        headers: this.addErrorHandlingHeaders(errorMessage.getOrder),
      },
    });
  }

  createOrder(request: OrderRequestDetails) {
    return this.apollo.mutate({
      mutation: CREATE_ORDER,
      variables: {
        request: JSON.stringify(request),
      },
      context: {
        headers: this.addErrorHandlingHeaders(
          errorMessage.createOrder,
          successMessage.createOrder
        ),
      },
    });
  }

  updateOrder(request: OrderRequestDetails) {
    return this.apollo.mutate({
      mutation: UPDATE_ORDER,
      variables: {
        request: JSON.stringify(request),
      },
      context: {
        headers: this.addErrorHandlingHeaders(
          errorMessage.updateOrder,
          successMessage.updateOrder
        ),
      },
    });
  }

  deleteOrder(request: OrderRequestDetails) {
    return this.apollo.mutate({
      mutation: DELETE_ORDER,
      variables: {
        request: JSON.stringify(request),
      },
      context: {
        headers: this.addErrorHandlingHeaders(
          errorMessage.deleteOrder,
          successMessage.deleteOrder
        ),
      },
    });
  }

  routeToAPS(request: RouteToAPS) {
    return this.apollo.mutate({
      mutation: ROUTETO_APS,
      variables: {
        request: JSON.stringify(request),
      },
      context: {
        headers: this.addErrorHandlingHeaders(errorMessage.routeToAps),
      },
    });
  }

  createApplicationTrackerRecord(request: CreateApplicationTrackerRecord) {
    return this.apollo.mutate({
      mutation: CREATE_APPLICATION_TRACKER_RECORD,
      variables: {
        request: JSON.stringify(request),
      },
      context: {
        headers: this.addErrorHandlingHeaders(
          errorMessage.applicationTrackerRecord
        ),
      },
    });
  }

  uint8ArrayToBase64(uint8Array: Uint8Array): string {
    let binary = '';
    const length = uint8Array.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binary);
  }

  downloadFileFromAzureFileShare(sasUrl: string, contentLength: number): any {
    return this.http.get(sasUrl, { responseType: 'arraybuffer' }).pipe(
      map((arrayBuffer: ArrayBuffer) => {
        const uint8Array = new Uint8Array(arrayBuffer);
        const base64String = this.uint8ArrayToBase64(uint8Array);
        return {
          base64String,
          fileTooLarge: false,
          contentLength,
        };
      })
    );
  }

  getOrderDocuments(payload: any) {
    let that = this;
    return that.http
      .post(
        environment.apsApiUrl + '/api/documents/v1/file/getsasinfo',
        payload
      )
      .pipe(
        switchMap((res: any) => {
          let responses = new Array();
          for (let i = 0; i < res.length; i++) {
            let result = {
              base64String: res[i].sasUrl,
              fileTooLarge: res[i].contentLength > that.MAX_PREVIEW_SIZE,
              contentLength: res[i].contentLength,
            };
            responses.push(result);
          }
          let data = { downloadOrderFiles: { ...payload } };
          for (let i = 0; i < data.downloadOrderFiles.documents.length; i++) {
            data.downloadOrderFiles.documents[i] = {
              ...data.downloadOrderFiles.documents[i],
              isDownload: responses[i].fileTooLarge,
              contentLength: responses[i].contentLength,
              content: responses[i].base64String,
            };
          }
          let result = { data };
          return of(result);
        })
      );
  }

  postEvent(payload: any) {
    let self = this;
    return self.http    
      .post(environment.apsApiUrl + '/v1/publishevent', payload)
      .toPromise();
  }

  addErrorHandlingHeaders(message, successMsg?) {
    return new HttpHeaders()
      .set('x-error-message', `${message}`)
      .set('x-response-decider-key', 'graphql')
      .set('x-success-message', `${successMsg}`);
  }

  getAuditNotes(applicationId: number) {
    return this.apollo.query({
      query: GET_AUDIT_NOTES,
      variables: {
        applicationId: applicationId,
      },
      context: {
        headers: this.addErrorHandlingHeaders(errorMessage.getOrder),
      },
    });
  }

  insertAuditNote(data: AuditNote) {
    return this.apollo.mutate({
      mutation: INSERT_AUDIT_NOTE,
      variables: {
        auditLogRecord: data,
      },
      context: {
        headers: this.addErrorHandlingHeaders(
          errorMessage.addNote,
          successMessage.addNote
        ),
      },
    });
  }

  getApplicationTrackerRecords(applicationId: number) {
    return this.apollo.query({
      query: GET_APPLICATION_TRACKER_RECORDS(applicationId),
      context: {
        headers: this.addErrorHandlingHeaders(
          errorMessage.getApplicationTrackerRecords
        ),
      },
    });
  }
}

