import { Applicant } from './applicant';
import { Contact } from './contact';
import { PolicyItem } from '../policyItem';
import { IndemnityCoverage, Product } from './product';
import { Payment } from './payment';
import { DiscoverableChainItem } from './discoverableChainItem';
import { DecoratedModelConfiguration } from './decoratedModelConfiguration';
import { Discoverable } from './discoverable';
import { PropertyDecorator } from '../../services/application/modelMapperService.service';
import { ApplicationService } from '../../services/application/application.service';

export class Application implements Discoverable {
  chain: DiscoverableChainItem[] = new Array<DiscoverableChainItem>();
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'id')
  @PropertyDecorator(DecoratedModelConfiguration.IsKey, true)
  id: number;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'appTypeId')
  appTypeId: number;
  appType: number;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'primaryApplicant')
  primaryApplicant: Applicant;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'family')
  @PropertyDecorator(DecoratedModelConfiguration.KeyName, 'clientNo')
  family: Array<Applicant>;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'state')
  state: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'createDate')
  createDate: Date;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'submitDate')
  submitDate: Date;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'status')
  status: number;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'contact')
  contact: Contact;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'policyInformation')
  policyInformation: PolicyItem[];
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'product')
  product: Product;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'indemnityCoverage')
  indemnityCoverage: IndemnityCoverage[];
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'payment')
  payment: Payment;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'otherCoverage')
  otherCoverage: Array<OtherCoverage>;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'groupNumber')
  groupNumber;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'company')
  company;

  drillDownQuestions: Array<DrillDownQuestionItem>;

  expressQualifyFlags: ExpressVCallQualifyFags;

  get isExpress() {
    return !(
      this.expressQualifyFlags.accidentHistQ ||
      this.expressQualifyFlags.currentPriorCovQ4 ||
      this.expressQualifyFlags.exchange ||
      this.expressQualifyFlags.medicalHistQ ||
      this.expressQualifyFlags.mibHit ||
      this.expressQualifyFlags.prescriptionDrugs ||
      this.expressQualifyFlags.primaryAge ||
      this.expressQualifyFlags.spouseAge ||
      Object.keys(this.expressQualifyFlags).length === 0
    );
  }

  get isLong() {
    return !this.isExpress;
  }

  get applicants(): Applicant[] {
    return [...[this.primaryApplicant], ...this.family];
  }
  get dependents(): Applicant[] {
    return this.applicants.filter((a) => {
      return a.relationship !== 'P' && a.relationship !== 'S';
    });
  }
  get adults(): Applicant[] {
    return [...[this.primaryApplicant], ...this.family].filter(
      (res) => res.relationship === 'S' || res.relationship === 'P'
    );
  }
  get isValid() {
    return this.id !== undefined && this.id !== null && this.id !== 0;
  }
  public static DefaultInstance() {
    const app = new Application();
    app.payment = new Payment();
    app.family = new Array<Applicant>();
    app.primaryApplicant = new Applicant();
    app.product = new Product();
    app.contact = new Contact();
    app.policyInformation = new Array<PolicyItem>();
    app.otherCoverage = new Array<OtherCoverage>();
    app.drillDownQuestions = new Array<DrillDownQuestionItem>();
    app.expressQualifyFlags = new ExpressVCallQualifyFags();

    app.id = 0;
    return app;
  }
  public static Reset(current: Application, original: Application) {
    Object.keys(current.payment).map((k) => {
      current.payment[k] = original.payment[k];
    });
    return current;
  }

  public static Copy(from: Application) {
    const app = new Application();
    Object.assign(app, from);

    app.payment = new Payment();
    app.family = new Array<Applicant>();
    app.primaryApplicant = new Applicant();
    app.product = new Product();
    app.contact = new Contact();
    app.policyInformation = new Array<PolicyItem>();
    app.otherCoverage = new Array<OtherCoverage>();
    app.drillDownQuestions = new Array<DrillDownQuestionItem>();
    app.expressQualifyFlags = new ExpressVCallQualifyFags();

    Object.assign(app.payment, from.payment);
    if (from && from.family) {
      from.family.map((f) => {
        const applicant = new Applicant();
        Object.assign(applicant, f);
        app.family.push(applicant);
      });
    }

    Object.assign(app.primaryApplicant, from.primaryApplicant);
    Object.assign(app.product, from.product);
    Object.assign(app.contact, from.contact);
    Object.assign(app.policyInformation, from.policyInformation);
    Object.assign(app.otherCoverage, from.otherCoverage);
    Object.assign(app.drillDownQuestions, from.drillDownQuestions);
    Object.assign(app.expressQualifyFlags, from.expressQualifyFlags);

    return app;
  }
}

export class ApiApplication {
  createdDate: Date;
  id: string;
  message: Application;
}

export class OtherCoverage {
  appId: string;
  otherPlanName: string;
}

export class FamilyRelation {
  shortname: string;
  relation: string;
}

export class DrillDownQuestions {
  text: string;
  drillDownAnswer: object;
}

export class DrillDownQuestionItem {
  text: string;
  type: string;
  answer: DrillDownAnswer;

  static GetApplicantAnswer(item: DrillDownQuestionItem[], clientNo: string) {
    if (item && item.length > 0) {
      return item[0].answer[clientNo] !== undefined
        ? item[0].answer[clientNo]
        : null;
    } else {
      return null;
    }
  }
}

export interface DrillDownAnswer {
  [key: string]: string;
}

export class ExpressVCallQualifyFags {
  primaryAge: boolean;
  spouseAge: boolean;
  mibHit: boolean;
  currentPriorCovQ4: boolean;
  prescriptionDrugs: boolean;
  exchange: boolean;
  medicalHistQ: boolean;
  accidentHistQ: boolean;
}
