<ng-container *ngFor="let requests of orderRequests">
  <ng-container *ngIf="requests.list.length > 0">
    <div class="d-flex pt-2">
      <div class="col-5 p-0"><hr /></div>
      <div class="col-2 text-center font-weight-bold">
        {{ requests.orderName }}
      </div>
      <div class="col-5 p-0"><hr /></div>
    </div>
    <table class="table table-striped mt-3">
      <thead class="border bg-secondary bg-gradient">
        <tr>
          <th>Applicant</th>
          <th>Request Type & Date</th>
          <th>Doctor/Physician</th>
          <th>UW Reason/Notes</th>
          <th>APS Notes</th>
          <th>Documents</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="requests.list.length; else NoData">
          <tr *ngFor="let order of requests.list; index as i">
            <td>{{ getApplicantName(order.clientNumber) || "N/A" }}</td>
            <td>
              {{ requestType[order.requestType] || "N/A" }}
              <p class="pt-2">
                {{ (order.createdDate | date : "MM/dd/yyyy") || "N/A" }}
              </p>
            </td>
            <td>
              <ng-container *ngIf="order.requestType === 'APS'; else NotAps">
                <div>
                  {{ order?.aps?.provider.fullname }}
                </div>
                <div class="pt-1" *ngIf="!order?.aps?.provider.is_basic">
                  <span
                    >{{ order?.aps?.provider.streetAddress }},
                    {{ order?.aps?.provider.city }},
                    {{ order?.aps?.provider.state }}</span
                  >
                </div>
                <div class="pt-1">
                  {{ order?.aps?.provider.phone }}
                </div>
              </ng-container>
              <ng-template #NotAps>{{ "N/A" }}</ng-template>
            </td>
            <td>
              <ng-container *ngIf="order && order.requestType !== 'APS'">
                <div *ngIf="order.labs && !!order.labs[0]">
                  <p class="m-0 f-w-600">
                    {{ order.labs[0].name }}
                    <span
                      class="px-2 text-info cursor-pointer"
                      *ngIf="order.labs.length > 1"
                      (click)="editOrder(order, i)"
                      [ngClass]="{ 'show-more': !canShowActionIcons }"
                      >{{ order.labs.length - 1 }} more</span
                    >
                  </p>
                  <p class="m-0 pt-2">{{ order.labs[0].notes }}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="order.requestType === 'APS'">{{
                order.aps.uWSNotes
              }}</ng-container>
            </td>
            <td *ngIf="order.requestType === 'APS'">
              {{ order.aps?.aPSNotes || "N/A" }}
            </td>
            <td *ngIf="order.requestType !== 'APS'">
              {{ (order.labs && order.labs[0]?.aPSNotes) || "N/A" }}
            </td>
            <td>
              <ng-container
                *ngIf="
                  getMedicalRecords(order?.orderDocuments?.documents)?.length;
                  else NoDocuments
                "
              >
                <div
                  class="cursor-pointer"
                  *ngFor="
                    let document of getMedicalRecords(
                      order?.orderDocuments?.documents
                    )
                  "
                  (click)="viewMedicalDocuments(order, document)"
                >
                  <span class="document">{{ document.name }}</span>
                  <p class="pt-2">
                    {{
                      (document.updatedDate
                        | datewithTimeZone : timeZone : "date-time") || "N/A"
                    }}
                  </p>
                </div>
              </ng-container>
              <ng-template #NoDocuments>{{ "N/A" }}</ng-template>
            </td>
            <td>
              <ng-container
                *ngTemplateOutlet="orderStatus; context: { $implicit: order }"
              ></ng-container>
            </td>
            <td>
              <ng-container *ngIf="order.orderStatusId === 0; else elseBlock">
                <i
                  class="fa fa-edit edit-icon p-1 cursor-pointer text-center"
                  (click)="editOrder(order, i)"
                  [ngClass]="{ 'actions-none text-gray': !canShowActionIcons }"
                ></i>
                <i
                  class="far fa-trash-alt text-danger p-1 pl-3 cursor-pointer text-center"
                  (click)="deleteOrder(order)"
                  [ngClass]="{ 'actions-none text-gray': !canShowActionIcons }"
                ></i>
                <img
                  src="assets\images\ushg\chat.svg"
                  class="pl-3 chat-icon"
                  alt="image"
                  (click)="openAddNoteModal(order)"
                />
              </ng-container>
              <ng-template #elseBlock>
                <div
                  *ngIf="
                    order.orderDocuments && order.orderDocuments.documents;
                    else noDocuments
                  "
                >
                  <div class="d-inline mr-3 position-relative">
                    <img
                      src="assets\images\ushg\pdf_icon.svg"
                      alt="image"
                      (click)="viewOrderDocuments(order)"
                    />
                    <sup class="position-absolute"
                      >({{ order.orderDocuments.documents.length }})</sup
                    >
                  </div>
                  <div class="position-relative d-inline ml-2">
                    <img
                      src="assets\images\ushg\chat.svg"
                      alt="image"
                      class="chat-icon"
                      (click)="openAddNoteModal(order)"
                    />
                    <sup class="position-absolute"
                      >({{ getNotesCount(order) }})</sup
                    >
                  </div>
                </div>
                <ng-template #noDocuments>
                  <div class="f-w-600">N/A</div>
                </ng-template>
              </ng-template>
            </td>
          </tr>
        </ng-container>
        <ng-template #NoData>
          <tr>
            <td colspan="7">
              <div class="call-info m-1">No Data Available</div>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </ng-container>
</ng-container>

<!-- Delete Order popup starts from here -->
<app-modal
  [open]="openDeleteModal"
  [modalOptions]="{ size: 'lg' }"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event)"
>
  <div title class="text-center">Delete Request</div>
  <div body>
    <div class="pl-2 pt-2 pb-5" *ngIf="deleteOrderRequest">
      Are you sure to delete the
      <b>"{{ requestType[deleteOrderRequest.requestType] }} Request"</b>
      for
      <b>"{{ getApplicantName(deleteOrderRequest.clientNumber) }}"</b>
    </div>
  </div>
</app-modal>

<!-- Delete order popup ends here -->

<app-modal
  [open]="openPdfDocumentsModal"
  [footer]="false"
  [modalOptions]="{ size: 'xl', windowClass: 'modal-xxl' }"
  [buttonsConfig]="{
    position: 'center',
    modalMode: 'customButtons',
    buttonSize: 'large',
    showModalCrossIcon: true
  }"
  (actions)="getNotifyEvent()"
>
  <div title class="text-center">
    <div title class="d-flex">
      <div class="col-3">
        <select
          class="form-control"
          [(ngModel)]="pdfFile"
          (ngModelChange)="displayPdf($event)"
        >
          <option *ngFor="let file of pdfDocumentsList" [ngValue]="file">
            {{ file.documentType }}&nbsp; ({{ file.name }} ,
            {{ file.updatedDate | date : "MM/dd/yyyy" }})
          </option>
        </select>
      </div>
      <div class="col-6 text-center">Document Preview</div>
    </div>
  </div>
  <div body>
    <div class="row m-0">
      <div class="card pdf-div col-12">
        <div
          style="height: 850px; width: 100%"
          *ngIf="downloadHtml"
          [innerHTML]="downloadHtml"
        ></div>
        <iframe
          *ngIf="!downloadHtml"
          width="100%"
          height="850"
          [src]="pdfUrl | safe : 'resourceUrl'"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</app-modal>

<!-- add note popup starts from here -->
<app-modal
  [open]="openAddNotesModal"
  [modalOptions]="{ size: 'xl' }"
  [footer]="false"
  [buttonsConfig]="{
    position: 'center',
    modalMode: 'customButtons',
    buttonSize: 'large',
    showModalCrossIcon: true
  }"
  (actions)="getNotifyEvent()"
>
  <div title class="text-center">ORDER ID - {{ order?.orderTrackerId }}</div>
  <div body>
    <div
      class="row mx-4 p-3 mb-3"
      style="background-color: rgba(0, 0, 0, 0.05)"
    >
      <div class="col-4 p-0">
        <p>
          <label class="f-w-500"
            >Applicant Name :
            <b class="pl-2">{{
              getApplicantName(order?.clientNumber) || "N/A"
            }}</b></label
          >
        </p>
      </div>
      <div class="col-4 p-0">
        <p>
          <label class="f-w-500"
            >Request Type : <b class="pl-2">{{ order?.requestType }}</b></label
          >
        </p>
      </div>
      <div class="col-4 p-0">
        <p>
          <label class="f-w-500"
            >Status :
            <b class="pl-2">
              <ng-container
                *ngTemplateOutlet="orderStatus; context: { $implicit: order }"
              ></ng-container>
            </b>
          </label>
        </p>
      </div>
      <div class="col-4 p-0">
        <div>
          <label class="f-w-500 m-0"
            >Doctor/Physician :
            <b class="pl-2">{{
              order?.aps?.provider.fullname || "N/A"
            }}</b></label
          >
        </div>
      </div>
      <div class="col-4 p-0">
        <div>
          <label class="f-w-500 m-0"
            >Assigned To : <b class="pl-2">{{ assignedTo }}</b></label
          >
        </div>
      </div>
      <div class="col-4 p-0">
        <div>
          <label class="f-w-500 m-0"
            >Order ID : <b class="pl-2">{{ order?.orderTrackerId }}</b></label
          >
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-sm-6">
        <div style="max-height: 600px" class="overflow-auto">
          <table class="table table-striped table-striped">
            <thead class="bg-secondary sticky">
              <tr class="table_header_fixed">
                <th scope="col" class="text-center w-20 col-5">Logs</th>
                <th scope="col" class="text-center w-20 col-7">Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let note of orderLevelNotes">
                <td class="col-6">
                  <div class="d-flex align-items-center">
                    <div>
                      <img
                        [src]="
                          note.auditParameter === 'TO_UWS'
                            ? './assets/images/ushg/APS.svg'
                            : './assets/images/ushg/UWS.svg'
                        "
                        alt="APS"
                        width="36px"
                      />
                    </div>
                    <div class="ml-3">
                      {{ note?.loggedBy }}
                      <p class="p-0 m-0 mt-1">
                        {{
                          note?.loggedDate
                            | datewithTimeZone : timeZone : "date-time"
                        }}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="col-7">
                  {{ note?.information }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr class="vr" />
      <div class="col-sm-5">
        <strong>Due Date</strong>
        <p>{{ (dueDate | date : "MM/dd/yyyy") || "N/A" }}</p>
        <hr />
        <label for="notes">Add your notes here</label>
        <form #addNoteForm="ngForm">
          <textarea
            name="note"
            id="notes"
            class="form-control"
            [(ngModel)]="note"
            placeholder="Please add note here"
            rows="7"
            maxlength="160"
            autocomplete="off"
            required
          ></textarea>
          <div class="text-right">
            <button
              class="btn btn-primary mt-2"
              (click)="addNote()"
              [disabled]="!addNoteForm.valid"
            >
              Add Note
            </button>
          </div>
        </form>
      </div>
      <div class="row justify-content-center m-0 mt-4 ml-5">
        <button
          type="button"
          class="btn btn-outline-secondary btn-prop ml-5"
          (click)="getNotifyEvent()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>
<!-- add notes order popup ends here -->

<ng-template #orderStatus let-order>
  <span
    class="badge badge-pill"
    [ngClass]="{
      'badge-warning': order?.orderStatusId === 4,
      'badge-success': order?.orderStatusId === 5,
      'badge-info': order?.orderStatusId === 2,
      'badge-danger': order?.orderStatusId === 3,
      'badge-secondary': order?.orderStatusId === 0,
      'badge-light-gray': order?.orderStatusId === 6,
      'bg-dark text-white': order?.orderStatusId === 7
    }"
    >{{ getOrderStatus(order?.orderStatusId) }}
  </span>
</ng-template>
