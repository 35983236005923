import { Injectable } from '@angular/core';
import {
  SecureEmailModel,
  SecureEmailService,
} from '../../secure-email/secure-email.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';

@Injectable({
  providedIn: 'root',
})
export class SendSecureEmailProcessor implements Processor {
  name = 'Sending Secure Email';
  mustWaitForFinish: boolean;
  stopIfError: any;
  tags: string[] = [
    'payment.emailInitialOnline',
    'payment.emailInitialOffline',
  ];
  hasStopProsessing: boolean = false;

  constructor(private secureEmailService: SecureEmailService) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    const answer = model.answers.find(
      (a) =>
        a.answerTag === 'Payment.SecureEmailUpdateCreditCard' ||
        a.answerTag === 'Payment.SecureEmailSwitchPaymentMode'
    );
    let sendSecureEmailResult;
    if (answer && answer.value === 'true') {
      if (model.application.policyInformation.length > 0) {
        const secureEmailModel = {} as SecureEmailModel;
        secureEmailModel.policyNumber =
          model.application.policyInformation[0].policyNumber;
        secureEmailModel.applicationId = model.application.id;
        secureEmailModel.applicantName =
          model.application.primaryApplicant.fullName;
        let existTagAnswer = model.answers.find((a) =>
          this.tags.includes(a.answerTag)
        );
        if (existTagAnswer && existTagAnswer.value !== undefined) {
          secureEmailModel.emailAddress = existTagAnswer.value;
        } else {
          secureEmailModel.emailAddress = model.application.contact.email;
        }
        sendSecureEmailResult = await this.secureEmailService.SendSecureEmail(
          secureEmailModel
        );
        this.hasStopProsessing =
          sendSecureEmailResult && sendSecureEmailResult.hasOwnProperty('error')
            ? true
            : false;
      }
    }
    return Promise.resolve({
      result: true,
      stopProcessing: this.hasStopProsessing,
    });
  }
}
