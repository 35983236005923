import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-browser-compatability-check',
  templateUrl: './browser-compatability-check.component.html',
  styleUrls: ['./browser-compatability-check.component.scss'],
})
export class BrowserCompatibilityCheckComponent implements OnInit {
  browserModal = false;

  constructor() {}

  ngOnInit() {
    const nav = navigator as any;
    const userAgentData = nav?.userAgentData;
    if (userAgentData && userAgentData.brands) {
      let browserBrands = userAgentData.brands;

      let allowedBrowsers = environment.allowedBrowsers.browsers;

      let isAllowedBrowser = allowedBrowsers.find((type) =>
        browserBrands.some(
          (brand: { brand: string }) =>
            brand.brand.toLowerCase() === type.toLowerCase()
        )
      );

      if (isAllowedBrowser) {
        this.browserModal = false;
      } else {
        this.browserSpecificModal();
      }
    } else {
      this.browserSpecificModal();
    }
  }

  browserSpecificModal() {
    setTimeout(() => {
      this.browserModal = true;
    }, 0);
  }
}
