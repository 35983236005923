import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { VcallApiService } from 'src/app/shared/services/api/vcall/vcall-api.service';
import {
  ApplicationReviewItem,
  ApplicationReviewItemConfiguration,
  ApplicationReviewItemViewModel,
} from '../models/review-item';

@Injectable({
  providedIn: 'root',
})
export class ApplicationReviewService {
  reviewItems: ApplicationReviewService[];

  constructor(private api: VcallApiService) {}

  GetReviewItems(applicationId: string) {
    return this.api.GetApplicationExport(applicationId);
  }

  GetGroups(
    groups: ApplicationReviewItemConfiguration[],
    items: ApplicationReviewItem[]
  ): ApplicationReviewItemViewModel[] {
    const model = new Array<ApplicationReviewItemViewModel>();
    groups.map((g) => {
      const flist = items.filter((i) => {
        const result = i.tag.match(g.tagExpression);
        return result;
      });

      if (flist && flist.length > 0) {
        const modelItem = ApplicationReviewItemViewModel.Create(g.type, this);
        modelItem.items = flist;
        modelItem.configuration = g;
        modelItem.all = items;
        model.push(modelItem);
      }
    });

    return model;
  }

  // Automatically filter for ClientNo
  GetMedicalHistoryDrillDownReviewItem(
    applicableItem: ApplicationReviewItem,
    all: ApplicationReviewItem[]
  ) {
    const baseTag = applicableItem.tag.replace('.APPLICABLE', ''); // need a better way;
    const ailmentsTag = baseTag + '.AILMENTS';
    const baseTagForDetails = baseTag + '.AILMENT.';

    const result = new DrillDownApplicationReviewItem();
    result.applicableItem = applicableItem;

    const allUnderQualifying = all.filter(
      (t) => t.tag.startsWith(baseTag) && t.clientNo === applicableItem.clientNo
    );
    const everythingElse = allUnderQualifying.filter(
      (a) =>
        !(
          a.tag.indexOf('.AILMENTS') > 0 ||
          a.tag.indexOf('.AILMENT') > 0 ||
          a.tag.indexOf(applicableItem.tag) !== -1
        )
    );

    // FIND AILMENTS IF ANY
    result.conditions = all.find(
      (a) => a.tag === ailmentsTag && a.clientNo === applicableItem.clientNo
    );
    // FIND DETAILS
    result.items = [];
    result.followupItems = [];
    const ailments = result.conditions?.vCall?.value?.toString().split(',');
    ailments?.map((a) => {
      let conditionTag = a.replace(/ /g, '-').toUpperCase();
      conditionTag = baseTagForDetails + conditionTag;
      const items = all.filter(
        (item) =>
          item.tag.startsWith(conditionTag) &&
          applicableItem.clientNo === item.clientNo
      );
      result.items.push({ key: a, value: items });
    });
    if (everythingElse) {
      result.followupItems.push({ key: 'Followup', value: everythingElse });
    }
    return result;
  }
}

export class DrillDownApplicationReviewItem {
  applicableItem: ApplicationReviewItem;
  conditions: ApplicationReviewItem;
  // details: ApplicationReviewItem[];
  items: Array<KeyValue<string, ApplicationReviewItem[]>>;
  followupItems: Array<KeyValue<string, ApplicationReviewItem[]>>;
  contains(query: string) {
    return JSON.stringify(this).toLowerCase().indexOf(query.toLowerCase()) > -1;
  }
}
