import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResponseDeciderService {
  constructor() {}

  /**
   * @param key key as a string
   * @param response response as any information
   * @description if any error shows that error
   * @returns boolean
   */
  public IsError(key: string, response: any) {
    switch (key) {
      case 'application':
        if (response.body !== undefined) {
          return response.body === false;
        }
        return false;
      case 'SaveStatusCodeAndReason':
        if (
          response.body !== undefined ||
          response.body.message !== 'Success'
        ) {
          return response.body === false;
        }
        return false;
      case 'graphql':
        return response.body.errors && response.body.errors.length > 0;
      default:
        return true;
    }
  }
}
