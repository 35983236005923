<ng-template [ngIf]="simpleValueOutput">{{ simpleValueOutput }}</ng-template>

<ng-template [ngIf]="objectValueOutput">
  <ng-container
    *ngIf="!objectValueOutput.hasOwnProperty('isProviderAvailable')"
  >
    <div *ngFor="let key of objectKeys(objectValueOutput)">
      <div>{{ key }}</div>
      <div>{{ objectValueOutput[key] }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="objectValueOutput.hasOwnProperty('isProviderAvailable')">
    <div *ngFor="let item of objectValueOutput.list">
      <tr *ngFor="let key of objectKeys(item)">
        <td>{{ key }}</td>
        <td>{{ item[key] }}</td>
      </tr>
    </div>
  </ng-container>
</ng-template>

<ng-template [ngIf]="arrayValueOutput">
  <table class="table table-striped" *ngFor="let item of arrayValueOutput">
    <tr *ngFor="let key of objectKeys(item)">
      <td>{{ key }}</td>
      <td>{{ item[key] | mapValue }}</td>
    </tr>
  </table>
</ng-template>
<!-- 
<ng-template [ngIf]="arrayValueOutput">
  <table class="table table-striped" >
    <tr>
      <th *ngFor="let key of objectKeys(arrayValueOutput[0])">{{key}}</th>
    </tr>
    <tr *ngFor="let item of arrayValueOutput" >
    <ng-template *ngFor="let k of objectKeys(item)">
      <td>{{item[k]}}</td>
    </ng-template>
    </tr>
  </table>
</ng-template> -->
