import { Injectable } from '@angular/core';
import { VcallApiService } from '../../api/vcall/vcall-api.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';
import { RxProfileService } from '../../rx-profile.service';
import { ApplicationService } from '../../application/application.service';
import { last } from 'rxjs/internal/operators/last';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { KeyValue } from '@angular/common';
import { Answer } from 'src/app/shared/models/application/answer';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RxProfileAuthorizationProcessor implements Processor {
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'RxProfile Authorization';
  products: number[] = environment.productAppTypes;
  

  constructor(
    private rxApi: RxProfileService,
    private appService: ApplicationService
  ) {}

  Process(model: ProcessorModel): Promise<ProcessingResult> {
    let rxProfileSkipMessage: string =  'RX Profile validation skipped, SSN not found for ';
    const employment = this.appService.GetAnswerForAnswerTag(
      'Demographic.Employment'
    );

    let filteredApplicants = [];
    model.application.applicants.forEach((ma) => {
      
      if (
        this.products.includes(model.application.appType) &&
        (ma.relationship === 'P' || ma.relationship === 'S') &&
        (ma.age >= 50 ||
          this.checkOccupation(
            ma,
            { key: 'occupation', value: '[29,31]' },
            employment
          ))
      ) {
        // for concat the applicants to create the Rx Profile skip message
        if (ma.ssn === '') {
          rxProfileSkipMessage += `<strong>${ma.firstName} ${ma.lastName}</strong>, `;
        }
        filteredApplicants.push(ma);
      }
    });

    let resolve;
    const promise = new Promise<ProcessingResult>((r) => (resolve = r));
    if (
      filteredApplicants &&
      filteredApplicants.filter((applicant) => applicant.ssn === '').length
    ) {
      rxProfileSkipMessage = rxProfileSkipMessage.slice(0, -2);
    } else {
      rxProfileSkipMessage = '';
    }
    filteredApplicants = filteredApplicants.filter(
      (applicant) => applicant.ssn !== ''
    );
    const promiseArr = filteredApplicants.map((a) => {
      const rxProfile = new RxProfile();
      rxProfile.applicationId = model.application.id;
      rxProfile.firstName = a.firstName;
      rxProfile.lastName = a.lastName;
      rxProfile.socialSecurityNumber = a.ssn;
      rxProfile.gender = a.gender;
      rxProfile.dateOfBirth = a.birthDate;
      rxProfile.relationship = a.relationship;
      rxProfile.signatureType = 1;
      if (model.application?.contact?.zipCode) {
        rxProfile.zipCode = model.application.contact.zipCode;
      }
      rxProfile['FORCE'] = this.checkIfApplicantValueChange(a);
      return this.rxApi.AuthorizeRxProfile(rxProfile);
    });

    Promise.all(promiseArr).then((resultsArray) => {
      if (
        (Array.isArray(resultsArray) && resultsArray.length === 0) ||
        resultsArray.every((r) => r === null)
      ) {
        resolve({
          result: true,
          stopProcessing: false,
          message: rxProfileSkipMessage,
        });
      } else {
        resolve({
          result: true,
          stopProcessing: true,
          message: rxProfileSkipMessage,
        });
      }
    });

    return promise;
  }
  private checkOccupation(
    applicant: Applicant,
    params: KeyValue<string, string>,
    employment: Answer
  ) {
    let UpdatedOccupation = null;
    if (employment && employment.value.length > 0) {
      employment.value.forEach((e) => {
        if (e.clientNo === applicant.clientNo && e.applicable) {
          if (e.values !== undefined && e.values.length > 0) {
            UpdatedOccupation = e?.values[0]?.value?.occupationCode;
          }
        }
      });
    }
    if (params.value) {
      const healthList = JSON.parse(params.value);
      const isHealthworker = healthList.filter((h) => {
        return (
          UpdatedOccupation && UpdatedOccupation.split('-')[0] === h.toString()
        );
      });
      if (isHealthworker.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  // checking  if the applicat values changed or not
  private checkIfApplicantValueChange(a) {
    const primaryApplicantTags = [
      'primaryApplicant.firstName',
      'primaryApplicant.lastName',
      'primaryApplicant.birthDate',
    ];
    const familyApplicantTags = [
      'family.firstName',
      'family.lastName',
      'family.birthDate',
    ];
    const tags =
      a.relationship === 'P' ? primaryApplicantTags : familyApplicantTags;
    let changed = false;
    tags.forEach((tag) => {
      const answer = this.appService.GetAnswerForAnswerTags(tag, a.clientNo);
      if (
        answer &&
        (answer !== null || answer !== undefined) &&
        answer.value !== answer.ezAppValue
      ) {
        changed = true;
      }
    });
    return changed;
  }
}
export class RxProfile {
  applicationId: number;
  firstName: string;
  lastName: string;
  email: string;
  socialSecurityNumber: string;
  gender: string;
  dateOfBirth: string;
  zipCode: string;
  relationship: string;
  signatureType: number;
}
