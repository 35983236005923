<div class="full-hdr">
  <div class="row no-gutters">
    <div class="col-sm-5 pl-4">
      <div class="pl-3 py-1 cursor-pointer" (click)="openModal = true">
        <span class="float-left small pt-1"><b>Speaking with: </b></span>
        <div class="pl-2 float-left" *ngFor="let a of onlineApplicants">
          {{ a.applicant.firstName | titlecase }}
          {{ a.applicant.lastName | titlecase }} ({{
            a.applicant.relationship | relation
          }})
        </div>
        <div class="pt-1">
          <i class="fas fa-info-circle pl-2"></i
          ><i class="bi bi-info-circle"></i>
        </div>
      </div>
    </div>

    <app-modal
      [open]="openModal"
      [modalOptions]="{ size: 'xl' }"
      [buttonsConfig]="{
        modalMode: 'msg',
        submitButtonName: 'Close'
      }"
      (actions)="getNotifyEvent()"
    >
      <div title>
        Application details
      </div>
      <div body>
        <app-appointments-detail
          [detailOptions]="config"
        ></app-appointments-detail>
      </div>
    </app-modal>

    <div class="col-sm-3 text-left insured-parent py-1">
      <div *ngIf="appointment.callType">
        {{ appointment.callType }}
        <span *ngIf="appointment.callType !== 'Group'">
          ({{ appointment.callType | cbduration }})</span
        >
        <span *ngIf="appointment.callType === 'Group'"
          >({{ application?.groupNumber }})</span
        >
      </div>
    </div>

    <div class="col-sm-4 text-right pr-5">
      <div class="stop-call justify-content-end">
        <div class="btn-group" role="group">
          <!-- <div class="btn-group" role="group">
            <button
              class="btn btn-white dropdown-toggle px-3"
              type="button"
              id="dropdownMenuButton-1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              [appPermission]="'CanAccessMenuQuickLinks'"
            >
              Quick Links
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton-1"
            >
              <a class="dropdown-item direct-ailments" href="#">Add Ailments</a>
              <a class="dropdown-item" href="#">Add Doctor</a>
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#notes-modal"
                >Add Note</a
              >
            </div>
          </div> -->
          <div class="btn-group" role="group">
            <button
              class="btn btn-white dropdown-toggle px-3"
              type="button"
              id="{{ dropdownMenu }}"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              (click)="onStopCallReason = true"
              [appPermission]="'CanAccessMenuStopCall'"
            >
              Stop Call
            </button>
            <app-end-call-modal
              *ngIf="onStopCallReason"
              [menu]="dropdownMenu"
            ></app-end-call-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
