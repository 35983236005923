import { VcallQuestionTypeBooleanDetail } from 'src/app/vcall/questions/types/boolean/boolean.component';
import { Section } from './section';
import { Payment } from '../application/payment';
import { Answer } from '../application/answer';
import { RadiobuttonListComponentDetail } from 'src/app/vcall/questions/types/radiobuttonList/radiobutton-list.component';
import { VcallQuestionTypePaymentDetail } from './paymentDetail';
import { VcallQuestionTypeClosingDetail } from './closingDetail';
import { VcallQuestionTypeMedicalAilmentDetail } from 'src/app/vcall/questions/medical-ailment/medical-ailment.component';
import { VcallQuestionTypeDemographicDetail } from 'src/app/vcall/questions/demographic-applicants/demographic-applicants.component';

export class Question {
  id: number;
  text: string;
  details:
    | VcallQuestionTypeBooleanDetail
    | VcallQuestionTypePaymentDetail
    | VcallQuestionTypeClosingDetail
    | RadiobuttonListComponentDetail
    | VcallQuestionTypeMedicalAilmentDetail
    | VcallQuestionTypeDemographicDetail
    | any;
  type: string;
  payment: Payment;
  answerTag: string;
  displayWhen: string;
  sequence: number;
  value: string;
  answer: Answer;
  allowNextForValue: any;
  required: boolean;
  section: Section;
  isFirst: boolean;
  canGoNext: boolean;
  endCallPopupText: string;
  instructions: string;
  promptText: string;
  sectionName?: string;
  name?: string;
}
