import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ModelMapperService } from 'src/app/shared/services/application/modelMapperService.service';
import { Answer } from 'src/app/shared/models/application/answer';
import {
  NavigationArgument,
  NavigationDirection,
} from '../types/baseQuestionType';
import { Question } from 'src/app/shared/models/vcall/question';

export abstract class NavigationAwareQuestionTypeSimple {
  public abstract Navigating(
    direction: NavigationDirection
  ): NavigationArgument;
}

@Component({
  selector: 'app-demographic-applicants',
  templateUrl: './demographic-applicants.component.html',
  styleUrls: ['./demographic-applicants.component.scss'],
})
export class DemographicApplicantsComponent
  extends NavigationAwareQuestionTypeSimple
  implements OnInit, OnDestroy
{
  question: Question;
  details = new VcallQuestionTypeDemographicDetail();
  primaryApplicant: Applicant;
  editApplicant: Applicant;
  applicantIndex: number;
  family: Array<Applicant>;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  tempBirthDate: any;
  minDate;
  openModal = false;
  @ViewChild('f') modalForm;
  tempBirthDateChange: any = null;
  index: any;
  answer: Answer;
  saveClicked = false;
  primarySsnContent: boolean = true;
  familySsnMaskingContent: boolean[] = [];

  constructor(
    private scriptService: ScriptService,
    private modalService: NgbModal,
    private applicationService: ApplicationService,
    private mapper: ModelMapperService
  ) {
    super();
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.scriptService.SelectedQuestion.subscribe((q) => {
        if (q) {
          this.question = q;
          this.details = q.details;
        }
      })
    );

    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a && a !== null) {
          this.family = a.family;
          this.primaryApplicant = a.primaryApplicant;
        }
      })
    );

    this.scriptService.UpdateAnswer(true, null);
  }

  onDateSelection(date: NgbDate) {
    this.tempBirthDateChange = this.ngbFormatDate(
      `${date.year}-${date.month}-${date.day}`
    );
  }

  openReuseModal(index) {
    this.tempBirthDateChange = null;
    if (index === -1) {
      this.tempBirthDate = this.displayFormat(this.primaryApplicant.birthDate);
      this.primaryApplicant.birthDate = this.ngbFormatDate(
        this.primaryApplicant.birthDate
      );
      this.editApplicant = Object.assign({}, this.primaryApplicant);
    } else {
      this.tempBirthDate = this.displayFormat(this.family[index].birthDate);
      this.family[index].birthDate = this.ngbFormatDate(
        this.family[index].birthDate
      );
      this.editApplicant = Object.assign({}, this.family[index]);
    }
    this.applicantIndex = index;
    this.index = index;
    this.openModal = true;
  }

  displayFormat(value) {
    const date = new Date(value);
    // date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    const obj = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    return obj;
  }

  ModalAction(action: any, applicantForm) {
    this.saveClicked = true;
    if (action === 'Done') {
      if (applicantForm.valid) {
        if (this.tempBirthDateChange != null) {
          // added
          this.editApplicant.birthDate = this.tempBirthDateChange;
          this.tempBirthDateChange = null;
        }

        // REFACTOR : little kludgy need to refactor
        Object.keys(this.editApplicant).map((k) => {
          const target =
            this.index === -1 ? this.primaryApplicant : this.family[this.index];
          const baseTag = this.index === -1 ? 'primaryApplicant' : 'family';
          // adding clientNo to primaryApplicant is necessary for export.
          const clientNo =
            this.index === -1
              ? this.primaryApplicant.clientNo
              : this.family[this.index].clientNo;

          if (target[k] !== this.editApplicant[k]) {
            const t = this.mapper.GetAnswerTagForObjectPropertyName(target, k);

            this.applicationService.RegisterAnswerByTag(
              true,
              this.editApplicant[k],
              baseTag + '.' + k, // answertag
              null,
              clientNo, // this is required and must be populated for export use cases
              k
            );
          }
        });
        this.modalForm.form.reset();
        this.openModal = false;
      } else {
        this.openModal = true;
      }
    } else {
      if (action === 'Cancel') {
        this.modalForm.form.reset();
        this.openModal = false;
      }
    }
  }

  ngbFormatDate(date) {
    // any change to this method needs testing for multiple timezones
    let dateParts;
    if (date.includes('/')) {
      dateParts = date.split('/');
    } else if (date.includes('-')) {
      dateParts = date.split('-');
    }

    let month = dateParts[1];
    let day = dateParts[2];
    const year = dateParts[0];

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }

  public Navigating(direction: NavigationDirection): NavigationArgument {
    if (direction === NavigationDirection.Next) {
      this.applicationService.RegisterAnswerByTag(
        true,
        true,
        this.question.answerTag,
        null,
        null,
        this.question.text,
        this.question.type
      );
    }

    const result = new NavigationArgument();
    result.stopPropagation = false;
    return result;
  }

  // for primary applicant masking ssn number
  primaryApplicantSsnMasking() {
    this.primarySsnContent = !this.primarySsnContent;
  }

  // for family applicants masking ssn number
  familyApplicantSsnMasking(i) {
    this.familySsnMaskingContent[i] = !this.familySsnMaskingContent[i];
  }
}

export class VcallQuestionTypeDemographicDetail {
  hideOccupation: string;
  mode: string;
}
