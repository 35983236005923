import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VcallParams } from '../shared/models/vcall/vcallParams';
import { HandledHttpClient } from '../shared/services/api/handledHttpClient';
import { ApplicationService } from '../shared/services/application/application.service';
import { UiServiceService } from '../shared/services/ui-service.service';
import { UserProfileService } from '../shared/services/user/user-profile.service.service';
import { ApplicationReviewItemViewModel } from './models/review-item';

@Component({
  selector: 'app-underwriting',
  templateUrl: './underwriting.component.html',
  styleUrls: ['./underwriting.component.scss'],
})
export class UnderwritingComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private router: Router,
    private http: HttpClient,
    private hhttp: HandledHttpClient,
    private userProfileService: UserProfileService,
    private uiService: UiServiceService
  ) {}

  viewModel: ApplicationReviewItemViewModel[];
  viewModel$ = new BehaviorSubject<ApplicationReviewItemViewModel[]>(null);
  baseUrl: string;
  applicationId: string;
  pdfUrl: SafeResourceUrl;
  activeTab: string;
  canDisplayAdditionalInfoTab = environment.features.UW_AdditionalInfo;
  viewMode: string;
  viewModeConfig = ['all'];
  accessTabs: string[] = ['recalls'];
  ngOnInit() {
    const vCall = new VcallParams(this.route.snapshot.paramMap);
    this.activeTab = this.route.snapshot.queryParams.tabName;
    if (this.activeTab) {
      this.activeTab = this.activeTab.toLowerCase();
      if (this.accessTabs.includes(this.activeTab)) {
        this.getCurrentTab(this.activeTab);
      }
    } else {
      this.activeTab = 'review';
    }
    this.applicationId = vCall.applicationId;
    this.applicationService.SelectApplication(vCall.applicationId);
    this.baseUrl = environment.baseApplicationDocumentUrl;

    this.route.queryParams.subscribe((params) => {
      this.viewMode = params.viewmode;
      this.viewModeConfig = this.viewMode
        ? environment.viewmode[this.viewMode]
        : environment.viewmode.default;
    });
    this.downloadPdf();
  }

  downloadPdf() {
    const headers = new HttpHeaders();
    this.http
      .get(
        this.baseUrl +
          '/Applications/' +
          this.applicationId +
          '/ApplicationPdf',
        {
          responseType: 'blob',
        }
      )
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        this.pdfUrl = window.URL.createObjectURL(blob);
      });
  }

  getCurrentTab(tabName?) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tabName, viewmode: this.viewMode },
    });
  }
}
