<div class="row">
  <div class="p-3 col-12">
    <textarea
      type="text"
      class="form-control detail"
      name="detail"
      (blur)="SaveText(notes)"
      (keyup)="EnableNext(notes)"
      [(ngModel)]="notes"
    >
    </textarea>
  </div>
</div>
