import { Component, OnInit, OnDestroy } from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Subscription } from 'rxjs';
import { Section, SectionStatus } from 'src/app/shared/models/vcall/section';
import { Question } from 'src/app/shared/models/vcall/question';
import { Script } from 'src/app/shared/models/vcall/script';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Application } from 'src/app/shared/models/application/application';

@Component({
  selector: 'app-vcall-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
})
export class VcallSectionsComponent implements OnInit, OnDestroy {
  service: ScriptService;
  sections: Array<Section>;
  selectedSection: Section;
  selectedQuestion: Question;
  subscriptions = Array<Subscription>();
  previousAnsweredQuestions: any;
  sectionPreviousQuestions: Question[];
  percentageDone = 0;
  script: Script;
  application: Application;
  disAllowedTypes = [
    'ApplicantQualifiedTextBox',
    'ApplicantQualifiedBoolean',
    'ApplicantQualifiedInputText',
    'ApplicantQualifiedOccupation',
    'ApplicantQualifiedDoctor',
    'ApplicantQualifiedMedication',
    'MedicalAilment',
  ];

  constructor(
    service: ScriptService,
    private router: Router,
    private applicationService: ApplicationService
  ) {
    this.service = service;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.service.Sections.subscribe((sections) => {
        this.sections = sections;
        this.evaluate(null, null);
      })
    );
    this.subscriptions.push(
      this.service.ApplicationScript.subscribe((script) => {
        this.script = script;
        this.evaluate(null, null);
      })
    );

    this.subscriptions.push(
      this.service.SelectedSection.subscribe((selectedSection) => {
        this.evaluate(selectedSection, null);
      })
    );

    this.subscriptions.push(
      this.service.SelectedQuestion.subscribe((selectedQuestion) => {
        this.evaluate(null, selectedQuestion);
      })
    );
  }

  evaluate(newSection: Section, selectedQuestion: Question) {
    if (selectedQuestion && selectedQuestion !== null) {
      this.selectedQuestion = selectedQuestion;
    }

    if (newSection != null) {
      this.selectedSection = newSection;
    }

    if (this.selectedSection && this.sections) {
      if (this.sections !== null) {
        this.sections.map((s) => {
          if (s.sequence === this.selectedSection.sequence) {
            s.status = SectionStatus.Current;
            if (this.selectedQuestion && this.selectedSection !== null) {
              if (this.script !== null) {
                const selectedSectionQuestions = this.script.items.filter(
                  (item) => item.section.name === this.selectedSection.name
                );
                selectedSectionQuestions[0].questions.sort(
                  (a, b) => a.sequence - b.sequence
                );
                s.questionsDone = Math.floor(
                  ((selectedSectionQuestions[0].questions.findIndex(
                    (question) => question.id === this.selectedQuestion.id
                  ) +
                    1 -
                    1) /
                    this.selectedSection.totalQuestions) *
                    100
                );
              }
            }
          } else if (s.sequence < this.selectedSection.sequence) {
            s.status = SectionStatus.Completed;
            s.questionsDone = 100;
          } else {
            s.status = SectionStatus.Pending;
            s.questionsDone = 0;
          }
        });
      }
    }
  }

  public viewPreviousQuestions(section: Section): void {
    this.filterScriptDisplay();
    if (section.sequence > this.previousAnsweredQuestions?.length) {
      return;
    }

    if (section.toggle) {
      section.toggle = !section.toggle;
      return;
    }

    this.sections.map((s: Section) => {
      s.toggle = false;
    });

    section.toggle = !section.toggle;
    this.sectionPreviousQuestions = this.previousAnsweredQuestions[
      section.sequence - 1
    ].questions;
  }

  public routeToQuestion(question: Question): void {
    this.service.LoadQuestion(
      question,
      question.section,
      this.script.applicationId
    );
    window.scroll(0, 0);
  }
  /*
    questions is an array of arrays
    filterScriptDisplay first builds current available sections, then
    loops over the initial array and again with the nested array
  */
  private filterScriptDisplay(): void {
    this.previousAnsweredQuestions = null;
    const questions = [];
    const answeredQuestions = [];

    for (let i = 0; i < this.selectedSection.sequence; i++) {
      questions.push(this.script.items[i]);
    }

    for (const [i] of questions.entries()) {
      const section = [] as Question[];
      for (const [j] of questions[i].questions.entries()) {
        if (questions[i].section.sequence > this.selectedSection.sequence) {
          break;
        }
        if (
          questions[i].section.sequence >= this.selectedSection.sequence &&
          questions[questions.length - 1].questions[j].sequence >=
            this.selectedQuestion.sequence
        ) {
          break;
        }
        const answer = this.applicationService.GetAnswerForAnswerTag(
          questions[i].questions[j].answerTag
        );
        if (
          answer &&
          (!answer.questionId ||
            answer.questionId === questions[i].questions[j].id)
        ) {
          questions[i].questions[j].value = answer.value;
          if (!this.checkForDisallowedTypes(questions[i].questions[j].type)) {
            section.push(questions[i].questions[j]);
          }
        }
      }
      answeredQuestions.push(section);
    }
    this.previousAnsweredQuestions = answeredQuestions;
  }

  private checkForDisallowedTypes(type: string) {
    return this.disAllowedTypes.find((t: string) => t === type);
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }
}
