<div class="row p-3">
  <div class="btn-group checkbox" data-toggle="buttons">
    <label
      class="btn applicants"
      [ngClass]="{ active: applicant.applicable === true }"
      *ngFor="let applicant of applicants | async"
    >
      <input
        type="checkbox"
        name="applicant"
        [id]="applicant.clientNo"
        [(ngModel)]="applicant.applicable"
        (change)="optionChanged(applicant.clientNo)"
      />
      <span class="cr mt-2 mb-2"><i class="cr-icon fas fa-check"></i></span>
      <div>
        <p class="mb-0">
          {{ applicant.firstName | titlecase }}
          {{ applicant.lastName | titlecase }}
        </p>
        <p>({{ applicant.relationship | relation }})</p>
        <p>{{ applicant.gender | gender }}, {{ applicant.birthDate | age }}</p>
      </div>
    </label>
    <label
      class="btn applicants"
      [ngClass]="{ active: isNoneChecked === true }"
    >
      <input
        type="checkbox"
        [(ngModel)]="isNoneChecked"
        name="applicant"
        (change)="optionChanged('', $event)"
      />
      <span class="cr mt-2 mb-3"><i class="cr-icon fas fa-check"></i></span>
      <div>
        <p class="mb-0 mt-2">None of the applicants</p>
      </div>
    </label>
  </div>
</div>
<div
  *ngIf="question.instructions"
  class="col-sm-12 alert alert-warning text-center mt-3"
  role="alert"
>
  {{ question.instructions }}
</div>

<div
  *ngIf="question?.details?.information"
  class="col-sm-12 alert alert-info text-center mt-3"
  role="alert"
>
  {{ question.details.information }}
</div>
<app-modal
  [open]="openModal"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event); cancel($event)"
>
  <div title>Confirmation</div>
  <div body>
    <div class="pl-2">
      Please confirm the removal of health condition information for the
      following individual(s).
      <span *ngFor="let item of selectedApplicants"
        ><div class="text-capitalize">{{ item }}</div></span
      >
    </div>
  </div>
</app-modal>
