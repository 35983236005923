import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseQuestionType } from '../baseQuestionType';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { KeyValue } from '@angular/common';
import { Answer } from 'src/app/shared/models/application/answer';

@Component({
  selector: 'app-radiobutton-list',
  templateUrl: './radiobutton-list.component.html',
  styleUrls: ['./radiobutton-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadiobuttonListComponent extends BaseQuestionType<
  RadiobuttonListComponentDetail
> {
  constructor(script: ScriptService, applicationService: ApplicationService) {
    super(script, applicationService);
  }
  paymentAnswers: Answer[];
  openConfirmationModal = false;
  paymentQuestionAnswered = false;
  details: RadiobuttonListComponentDetail = new RadiobuttonListComponentDetail();

  QuestionChanged(q: Question): void {
    // if (this.answer) {
    //   debugger;
    //   this.selectedValue = this.answer.value;
    // }
  }

  optionChanged(item: any) {
    // console.log(item);
    this.selectedValue = item;
    const paymentAnswerTags = JSON.parse(this.details.paymentAnswerTags);
    this.paymentAnswers = this.applicationService.GetSectionAnswers(
      paymentAnswerTags
    );
    if (this.paymentAnswers.length > 0) {
      this.openConfirmationModal = true;
    }

    this.saveAnswer(true, item);
  }
  confirm(action: any) {
    if (action === 'Done') {
      this.applicationService.DeleteQuestionAnswers(this.paymentAnswers);
    }
  }
  cancelPaymentInfo(action: any) {
    if (action === 'Cancel') {
      this.openConfirmationModal = false;
    }
  }

  CanGoNext() {
    const value =
      this.selectedValue !== null
        ? this.selectedValue
        : this.answer !== null
        ? this.answer.value
        : null;
    return (
      value && this.details.items().filter((i) => i.key === value).length > 0
    );
  }

  ApplicationChanged(): void {}

  ResetView(): void {}
}

export class RadiobuttonListComponentDetail {
  options: string;
  paymentAnswerTags: string;
  items(): Array<KeyValue<string, string>> {
    if (this.options) {
      const result = JSON.parse(this.options);
      // console.log(result);
      return result;
    } else {
      return [];
    }
  }
}
