<div class="row">
  <div class="col-3 left-nav py-4 pl-5">
    <div class="row">
      <h6 class="col pb-2 mr-5 mb-3 border-bottom text-center">
        Release Notes
      </h6>
    </div>
    <div class="row" *ngFor="let note of notes">
      <div
        class="col pt-2 pb-2 mr-5"
        (click)="eachNote(note)"
        [ngClass]="{ 'ui-active': selectedNote === note }"
      >
        {{ note?.name }}
      </div>
    </div>
  </div>
  <div class="col p-2" style="height: calc(100vh - 126px); overflow-y: auto;">
    <div class="cnt-area p-3">
      <ul
        class="list-group list-group-flush"
        *ngIf="selectedNote?.items.length > 0; else noData"
      >
        <li
          *ngFor="let selectedItem of selectedNote?.items | keyvalue"
          class="list-group-item release-note-item"
        >
          <i class="fas fa-arrow-right mt-2 mr-2"></i>
          <p class="release-note-item-info mb-0 lead">
            {{ selectedItem?.value }}
          </p>
        </li>
      </ul>
      <ng-template #noData>
        <div class="call-info">No release items...</div>
      </ng-template>
    </div>
  </div>
</div>
