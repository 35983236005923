<ng-template #modalReuse let-modal>
  <div
    class="modal-header"
    [ngClass]="{ 'bg-light': bgClass }"
    *ngIf="buttonsConfig['showModalHeader'] === false ? false : true"
  >
    <h4 class="modal-title col-11" id="modal-basic-title">
      <ng-content select="[title]"></ng-content>
    </h4>

    <button
      type="button"
      class="close outline-0"
      aria-label="Close"
      *ngIf="buttonsConfig['showModalCrossIcon']"
      (click)="modal.dismiss('Cancel')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [ngClass]="{ 'bg-light': bgClass }">
    <ng-content select="[body]"></ng-content>
  </div>
  <div
    [ngClass]="{
      'modal-footer': buttonsConfig['modalMode'] !== 'customButtons'
    }"
    [class.justify-content-center]="buttonsConfig['position'] === 'center'"
    [class.justify-content-between]="
      buttonsConfig['modalMode'] === 'form' &&
      buttonsConfig['position'] !== 'center'
    "
    *ngIf="footer === true"
  >
    <ng-container *ngIf="buttonsConfig['modalMode'] === 'form'">
      <button
        type="button"
        class="btn btn-outline-dark"
        [ngClass]="{ 'w-21 m-2 mr-4': (buttonsConfig['buttonSize'] = 'large') }"
        (click)="modal.dismiss('Cancel')"
      >
        {{ buttonsConfig["cancelButtonName"] }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ 'w-21 m-2': (buttonsConfig['buttonSize'] = 'large') }"
        [disabled]="!formValidation"
        (click)="modal.close('Done')"
      >
        {{ buttonsConfig["submitButtonName"] }}
      </button>
    </ng-container>

    <ng-container *ngIf="buttonsConfig['modalMode'] === 'enabledButtonForm'">
      <button
        type="button"
        class="btn btn-outline-dark mr-auto"
        (click)="modal.dismiss('Cancel')"
      >
        {{ buttonsConfig["cancelButtonName"] }}
      </button>
      <button type="button" class="btn btn-primary" (click)="onSave('Done')">
        {{ buttonsConfig["submitButtonName"] }}
      </button>
    </ng-container>
    <ng-container *ngIf="buttonsConfig['modalMode'] === 'customButtons'">
      <ng-content [select]="footer"></ng-content>
    </ng-container>
    <ng-container *ngIf="buttonsConfig['modalMode'] === 'msg'">
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!formValidation"
        (click)="modal.close('Done')"
      >
        {{ buttonsConfig["submitButtonName"] }}
      </button>
    </ng-container>
  </div>
</ng-template>
