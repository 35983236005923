<table class="table table-hover table-active tbl-log">
  <thead>
    <tr class="table-dark table_header_fixed">
      <th>#</th>
      <th>Applicant</th>
      <th>Question</th>
      <th>Reason</th>
      <th>Creator</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="questionList?.length; else NoData">
      <tr *ngFor="let ques of questionList; index as i">
        <td>{{ i + 1 }}</td>
        <td>{{ ques.applicantName }}</td>
        <td>
          <span
            class="truncate"
            placement="top"
            ngbTooltip="{{ ques.questionText }}"
          >
            {{ ques.questionText }}
          </span>
        </td>
        <td>{{ ques.recallReason }}</td>
        <td>{{ ques.createdBy }}</td>
        <td>{{ ques.status }}</td>
      </tr>
    </ng-container>
    <ng-template #NoData>
      <tr>
        <td colspan="6">
          <div class="call-info m-1">
            No Data Available
          </div>
        </td>
      </tr>
    </ng-template>
  </tbody>
</table>
