import { formatDate } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { PrescriptionLookup } from 'src/app/vcall/prescription-lookup/prescription-lookup.component';
import { Answer } from '../../models/application/answer';
import { Question } from '../../models/vcall/question';

@Component({
  selector: 'app-smart-date',
  templateUrl: './smart-date.component.html',
  styleUrls: ['./smart-date.component.scss'],
})
export class SmartDateComponent implements OnInit, OnChanges {
  @Output() notifyDate = new EventEmitter<SmartDateAnswer>();
  @Output() notifySmartDateChanges = new EventEmitter();
  @Input() answerCollection: Answer[];
  @Input() question: Question;
  @Input() hideNotes: boolean;

  smartForm: UntypedFormGroup;
  smartOptions;
  periodOptions;
  maxDate: object;
  minDate: object;
  answer: SmartDateAnswer;
  notesLabel: string;
  selectedItems: SelectedDateType[] = [];
  constructor() {
    this.smartForm = new UntypedFormGroup(
      {
        dateOption: new UntypedFormControl('', Validators.required),
        date: new UntypedFormControl(null),
        period: new UntypedFormControl(''),
        notes: new UntypedFormControl(''),
      },
      { validators: this.conditionallyRequiredValidator() }
    );
    this.smartOptions = [
      {
        name: 'Specific Date',
        value: SmartDateAnswerType.ExactDate,
      },
      {
        name: 'General Time Period',
        value: SmartDateAnswerType.DateRange,
      },
      // {
      //   name: `I Don't Remember`,
      //   value: SmartDateAnswerType.DontKnow,
      // },
    ];
    this.periodOptions = [
      {
        name: '6 months ago',
        value: '6 months ago',
      },
      {
        name: '1 year ago',
        value: '1 year ago',
      },
      {
        name: '2 years ago',
        value: '2 years ago',
      },
      {
        name: '3 years ago',
        value: '3 years ago',
      },
      {
        name: '4 years ago',
        value: '4 years ago',
      },
      {
        name: '5 years ago',
        value: '5 years ago',
      },
      {
        name: 'More than 5 years ago',
        value: 'More than 5 years ago',
      },
    ];

    const date = new Date();
    this.maxDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };

    this.minDate = {
      year: date.getFullYear() - 100,
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  ngOnInit(): void {
    this.checkEachquestionAnswers();
  }

  ngOnChanges() {
    this.checkEachquestionAnswers();
  }

  resetForm() {
    this.smartForm.reset();
    this.selectedItems['type'] = SmartDateAnswerType.DateRange;
  }

  updateSmartDate(prescData: PrescriptionLookup) {
    if (
      prescData &&
      typeof prescData.lastDate === 'object' &&
      prescData.lastDate.year
    ) {
      this.selectedItems['type'] = SmartDateAnswerType.ExactDate;
      this.smartForm.patchValue({
        date: prescData.lastDate,
        dateOption: SmartDateAnswerType.ExactDate,
      });
    } else {
      this.selectedItems['type'] = SmartDateAnswerType.DateRange;
      this.smartForm.patchValue({
        period: prescData.lastDate,
        dateOption: SmartDateAnswerType.DateRange,
      });
    }
    this.notifySmartDateChanges.emit(this.smartForm);
  }
  // To check each question answer changed or not
  checkEachquestionAnswers() {
    this.answer = new SmartDateAnswer(
      this.question?.answerTag,
      this.answerCollection
    );
    this.smartForm.patchValue(this.answer);
    this.processForm(this.answer); // this is to make sure that if already answered the calling component is well informed.
    this.smartForm.valueChanges
      .pipe(debounce(() => timer(1000)))
      .subscribe((opt) => this.processForm(opt));
    this.selecteDateType(this.answer.dateOption);
  }

  selecteDateType(dateType) {
    this.selectedItems['type'] = dateType;
  }

  changeDate() {
    this.notifySmartDateChanges.emit(this.smartForm);
  }

  isSelectedItem(dateType) {
    return (
      this.selectedItems['type'] && this.selectedItems['type'] === dateType
    );
  }
  processForm(opt) {
    // console.log(this.smartForm.valid);
    // console.log(opt);
    // console.log(this.smartForm.value);
    if (this.smartForm.valid) {
      this.answer.dateOption = opt.dateOption;
      this.answer.date = opt.date;
      this.answer.notes = opt.notes;
      this.answer.period = opt.period;
      this.notifyDate.emit(this.answer);
    } else {
      // invalid
      this.notifyDate.emit(null);
    }
  }

  /**
   * @description conditional required fields validation method like DateOption, date, and period etc.
   */
  conditionallyRequiredValidator() {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const masterControl = group.controls['dateOption'];
      let slaveControl;
      if (masterControl.value === SmartDateAnswerType.ExactDate) {
        slaveControl = group.controls['date'];
        return Validators.required(slaveControl);
      } else if (masterControl.value === SmartDateAnswerType.DateRange) {
        slaveControl = group.controls['period'];
        if (
          group.controls['period'].value ===
          this.periodOptions[this.periodOptions.length - 1].value
        ) {
          slaveControl = group.controls['notes'];
          this.notesLabel =
            '<span class="red">*</span> Add comments if "General Time Period" is "More than 5 years ago"';
        } else {
          this.notesLabel = 'Add your comments here (optional)';
        }
        return Validators.required(slaveControl);
      } else {
        group.controls['date'].setErrors(null);
        group.controls['period'].setErrors(null);
      }
      return null;
    };
  }

  /**
   * @param value value
   * @description format the date
   * @returns object
   */
  displayFormat(value) {
    if (typeof value === 'object') {
      // remove
      return value;
    }
    if (typeof value === 'string') {
      const date = new Date(value);
      const obj = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
      return obj;
    }
    return value;
  }

  /**
   * @description smart date to display the format
   */
  smartDateDisplayFormat() {
    return new SmartDateAnswer(this.question?.answerTag, this.answerCollection);
  }

  isTypeSame(type1: SmartDateAnswerType, type2) {
    return type1 === type2;
  }
}

export class SmartDateAnswer {
  constructor(tag: string, answers: Answer[]) {
    const value = (t) =>
      answers &&
      answers.find(
        (a) => a.answerTag === t // `${question.answerTag}.Period`
      );

    this.dateOption = value(`${tag}`)?.value || SmartDateAnswerType.DateRange; // SmartDateAnswerType
    (this.period = value(`${tag}.Period`)?.value || ''), // '6 months ago', // fetch from stored collection
      (this.notes = value(`${tag}.Notes`)?.value || ''); // 'notes of 6 months ago', // fetch from stored collection
    const date = value(`${tag}.Date`)?.value || ''; //

    if (date && date !== '') {
      this.date = this.formatDate(date);
    }
  }

  dateOption: SmartDateAnswerType;
  date: NgbDate;
  period: string;
  notes: string;

  /**
   * @param value value
   * @description format the date
   * @returns object
   */
  formatDate(value) {
    const date = new Date(value);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    const obj = new NgbDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    return obj;
  }

  formattedDate() {
    const myDate = new Date(this.date.year, this.date.month - 1, this.date.day);
    return formatDate(myDate, 'yyyy-MM-dd', 'en-US');
  }
}

export enum SmartDateAnswerType {
  DateRange = 'DateRange',
  DontKnow = 'DontKnow',
  ExactDate = 'ExactDate',
}

export class SelectedDateType {
  type: string;
}
