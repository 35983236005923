<div class="erros-button" (click)="opoenErrorDetailsDialog(content)">
  <span></span>
  <button type="button" class="btn btn-danger btn-sm">
    <span class="badge bg-danger">{{ errorsCaptured.length }}</span>
  </button>
</div>

<ng-template #content let-modal>
  <div class="modal-body">
    <div
      class="alert alert-danger"
      role="alert"
      *ngFor="let error of errorsCaptured"
    >
      <strong class="alert-heading">{{ error[0].message }}</strong>
      <hr />
      <small>
        {{ error[0].stack }}
      </small>
    </div>
    <div
      class="alert alert-info"
      role="alert"
      *ngIf="errorsCaptured.length === 0"
    >
      <small>
        No errors found.
      </small>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cancel')"
    >
      Close
    </button>
  </div>
</ng-template>
