export enum DecoratedModelConfiguration {
  // Answer Tag
  AnswerTag = 'answertag',
  // Is Key
  IsKey = 'key',
  // Property / Attribute name of the object
  MetaKeyName = 'name',
  // Primary Key Attribute name
  KeyName = 'keyname',
  // Label
  Label = 'label',
  // order
  Order = 'order',
  // SubGroup
  SubGroup = 'subgroup',
  // Required field for the element
  Required = 'required',
  // Controltype
  ControlType = 'controlType',
  // Type
  Type = 'type',
  // Pattern
  Pattern = 'pattern',
  // MaxLength
  MaxLength = 'maxLength',
  // MinLength
  MinLength = 'minLength',
  // NeedsEncryption
  NeedsEncryption = 'needsEncryption',
  // CopyMapper
  CopyMapper = 'copyMapper',
}
