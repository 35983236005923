import { BaseQuestionType } from '../baseQuestionType';
import { Question } from 'src/app/shared/models/vcall/question';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { VerifiableModel } from './verifiable-list.component';
import { Directive } from '@angular/core';

@Directive()
export abstract class BaseVerifiableListQuestionType<
  TDetails,
  TModel
> extends BaseQuestionType<TDetails> {
  details: TDetails;
  verifiableList: VerifiableModel<TModel>[];
  verificationDone = false;

  abstract GetModel(): any;
  abstract GetBaseAnswerTag();
  abstract ApplicationModified();
  abstract QuestionModified();
  abstract CanProceedNext();
  abstract GetSubGroup();

  ApplicationChanged(): void {
    if (this.GetModel() !== null) {
      this.verifiableList = VerifiableModel.GetList<TModel>(
        this.GetModel(),
        this.GetBaseAnswerTag(),
        this.GetSubGroup()
      );
      this.verifiableList.map((l) => {
        const a = this.applicationService.GetAnswerForAnswerTag(l.answerTag);
        if (a) {
          l.verification = a.verification;
          l.answerTag = a.answerTag;
        }
      });
    }

    this.ApplicationModified();
  }

  QuestionChanged(q: Question): void {
    this.subscriptions.push(
      this.script.PromptText.subscribe((a) => {
        this.promptText = a;
      })
    );
    this.verifiableList = VerifiableModel.GetList<TModel>(
      this.GetModel(),
      this.GetBaseAnswerTag(),
      this.GetSubGroup()
    );
    this.verifiableList.map((l) => {
      const a = this.applicationService.GetAnswerForAnswerTag(l.answerTag);
      if (a) {
        l.verification = a.verification;
        l.answerTag = a.answerTag;
      }
    });
    this.QuestionModified();
  }
  CanGoNext(): boolean {
    const notVerifiedRow = this.verifiableList.filter((v) => {
      return v.verification === null;
    });

    console.log('can go next = ' + this.verificationDone);
    return (
      this.verificationDone &&
      notVerifiedRow.length === 0 &&
      this.CanProceedNext()
    );
  }

  verificationComplete(form: any) {
    this.verificationDone = form !== null && form.length > 0;

    if (this.verificationDone) {
      this.saveAnswers(this.verifiableList);
      // HACK: this is implemeneted after Navigation was moved out of the Question component
      // Verifiable list some how needs the QuestionChange to occure twice
      // Not sure why
      this.QuestionChanged(this.question);
    }
  }

  ResetCanGoNext() {
    this.script.UpdateAnswer(this.CanGoNext(), this.verifiableList);
  }

  saveAnswers(answers: VerifiableModel<any>[]) {
    this.applicationService.RegisterAnswerByTag(
      true,
      true,
      this.question.answerTag,
      null,
      null,
      this.promptText || this.GetText(),
      null,
      null,
      null,
      this.question.id
    );
    this.applicationService.RegisterAnswersByTag(answers);
    this.script.UpdateAnswer(this.CanGoNext(), answers); //
  }

  constructor(
    scriptService: ScriptService,
    applicationService: ApplicationService
  ) {
    super(scriptService, applicationService);
  }
}
