import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateobj',
})
export class DateobjPipe implements PipeTransform {
  /**
   * @param value value as a date string
   * @description create the date object
   * @returns returns the date object
   */
  transform(value: string) {
    const date = new Date(value);
    const obj = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    return obj;
  }
}
