import { Answer } from '../application/answer';
import { Application } from '../application/application';
import { RecallType } from './RecallType';
import { RecallStatus } from './RecallStatus';
import { PayorType } from './PayorType';
import { RecallContact } from './RecallContact';
import { Recall } from './Recall';
import { ApplicationService } from '../../services/application/application.service';

export class RecallsViewModel {
  recalls: Recall[];
  monthlyThirdParty: Answer;
  initialThirdParty: Answer;
  application: Application;
  applicableTypes: any[];
  currentRecalls: Recall[];
  completedRecalls: Recall[];
  answerList: Answer[];

  constructor(
    recalls: Recall[],
    monthlyThirdParty: Answer,
    initialThirdParty: Answer,
    application: Application,
    answerList: Answer[]
  ) {
    this.recalls = recalls;
    this.monthlyThirdParty = monthlyThirdParty;
    this.initialThirdParty = initialThirdParty;
    this.application = application;
    this.answerList = answerList;
    this.Process();
  }

  private Process() {
    this.applicableTypes = this.ApplicableRecallTypes();
    this.currentRecalls = this.CurrentRecalls();
    this.completedRecalls = this.CompletedRecalls();
  }

  private ApplicableRecallTypes() {
    const list = [];
    if (this.GetActiveRecall(RecallType.applicant).length === 0) {
      list.push(RecallType.applicant);
    }

    // for payment monthly
    if (
      this.GetActiveRecall(RecallType.applicationPayorMonthly).length === 0 &&
      ((this.monthlyThirdParty &&
        this.monthlyThirdParty.value ===
          PayorType.payorIsSomeoneElseAndOffline) ||
        (this.monthlyThirdParty &&
          this.monthlyThirdParty.value ===
            PayorType.payorIsSomeoneElseAndOnline))
    ) {
      list.push(RecallType.applicationPayorMonthly);
    }

    if (
      this.GetActiveRecall(RecallType.applicationPayorInitial).length === 0 &&
      ((this.initialThirdParty &&
        this.initialThirdParty.value ===
          PayorType.payorIsSomeoneElseAndOffline) ||
        (this.initialThirdParty &&
          this.initialThirdParty.value ===
            PayorType.payorIsSomeoneElseAndOnline))
    ) {
      list.push(RecallType.applicationPayorInitial);
    }

    return list;
  }

  private GetActiveRecall(type: RecallType) {
    const result = this.recalls.filter(
      (r) => r.status !== RecallStatus.completed
    );
    return result.filter((r) => r.contactType === type);
  }

  public GetContactInformation(type: RecallType): RecallContact[] {
    switch (type) {
      case RecallType.applicant: {
        return this.application.applicants.map((a) => {
          return {
            firstName: a.firstName ? a.firstName.toUpperCase() : '',
            fullName: a.fullName ? a.fullName.toUpperCase() : '',
            clientNo: a.clientNo,
            contactType: RecallType.applicant,
            relationship: a.relationship,
          };
        });
      }
      case RecallType.applicationPayorInitial: {
        return [
          {
            firstName: (
              this.application.payment.businessNameInitialOffline ||
              this.application.payment.businessNameInitialOnline
            ).toUpperCase(),
            fullName: (
              this.application.payment.businessNameInitialOffline ||
              this.application.payment.businessNameInitialOnline
            ).toUpperCase(),
            contactType: type,
          },
        ];
      }
      case RecallType.applicationPayorMonthly: {
        return [
          {
            firstName: (
              this.application.payment.businessNameMonthlyOffline ||
              this.application.payment.businessNameMonthlyOnline
            ).toUpperCase(),
            fullName: (
              this.application.payment.businessNameMonthlyOffline ||
              this.application.payment.businessNameMonthlyOnline
            ).toUpperCase(),

            contactType: type,
          },
        ];
      }
    }
  }

  public AddRecall(recall: Recall) {
    this.recalls.push(recall);
  }

  private CurrentRecalls() {
    const result = this.recalls.filter(
      (r) => r.status !== RecallStatus.completed
    );
    console.log(result);
    return result;
  }

  private CompletedRecalls() {
    const result = this.recalls.filter(
      (r) => r.status === RecallStatus.completed
    );
    result.map((r) => {
      r.questionList.map((q) => {
        this.answerList.map((a) => {
          if (a.answerTag === q.answerTag) {
            q.recallAnswer = a.value;
            q.ignored = a.ignored;
            q.prompt = a.prompt;
          }
        });
      });
    });

    return result;
  }
}
