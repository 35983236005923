import { Component, OnDestroy, Input } from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { BaseQuestionType } from './../types/baseQuestionType';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import {
  Application,
  DrillDownQuestionItem,
} from 'src/app/shared/models/application/application';
import { Answer } from 'src/app/shared/models/application/answer';
import { Subscription, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { MedicalItem } from 'src/app/shared/models/medicalItem';
import { KeyValue } from '@angular/common';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';
import { EzAppAnswerMapperService } from 'src/app/shared/services/ezappAnswers/ez-app-answer-mapper.service';

@Component({
  selector: 'app-medical-qualifying',
  templateUrl: './medical-qualifying.component.html',
  styleUrls: ['./medical-qualifying.component.scss'],
})
export class MedicalQualifyingComponent
  extends BaseQuestionType<MedicalQualifyingDetail>
  implements OnDestroy
{
  openModal = false;
  question: Question = null;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  elementQuestionIdTrackerId: number;
  application: Application;
  answer: Answer = null;
  selectedValue: string;
  originalValue: string;
  details = new MedicalQualifyingDetail();
  answerTag: string;
  isNoneChecked: boolean;
  anyAnswered: boolean;
  questionId: number;
  selectedClientNumber: string;
  selectedApplicants = [];
  answerType = 'ApplicantQualifying';
  @Input() configType: string;
  currentAnswer: any;

  applicants: Observable<any>;
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    private applicantFilterService: ApplicantFilterService,
    private ezappMapper: EzAppAnswerMapperService
  ) {
    super(script, appService, ui);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ResetView() {}

  ApplicationChanged(): void {}

  QuestionChanged(q: Question): void {
    // this is to ensure that lingering subscription don't cause any issue
    if (q.type !== this.configType) {
      return;
    }

    this.isNoneChecked = false;
    let allApplicants = this.application.applicants;

    if (this.details.filters) {
      allApplicants = this.filterApplicants();
    }

    if (this.answer) {
      // already answer saved earlier
      // TODO: simplify this logic, not easier to maintain
      // TODO: create an appropriate model instead of dynamic properties.
      // TODO: Need better handling for lingering subscription
      if (this.answer.value) {
        this.currentAnswer = JSON.parse(JSON.stringify(this.answer.value));
        if (
          this.currentAnswer !== null &&
          this.currentAnswer !== undefined &&
          !Array.isArray(this.currentAnswer)
        ) {
          this.currentAnswer = [];
        }
        // const list: MedicalItem[] = this.currentAnswer;
        const allApplicantsData = this.getApplicantModel(
          allApplicants,
          this.currentAnswer
        );

        // TODO: need to simplify the above logic and merge it with the below requirement.
        this.mapApplicants(allApplicants, allApplicantsData);
      }
    } else {
      // if no answer, fresh start
      const applicantsData = allApplicants.map((x) => ({
        ...x,
        applicable: false,
      }));
      this.applicants = of(applicantsData);
    }
  }

  CanGoNext(): boolean {
    return this.isNoneChecked || this.anyAnswered;
  }

  mapApplicants(applicants, allApplicantsData) {
    allApplicantsData = allApplicantsData.filter(
      (a) => applicants.find((all) => all.clientNo === a.clientNo) !== undefined
    );
    if (
      allApplicantsData.filter((e) => e.applicable === false).length ===
      allApplicantsData.length
    ) {
      this.isNoneChecked = true; // selecting none if all options are false
    } else {
      this.isNoneChecked = false;
    }
    this.anyAnswered =
      allApplicantsData.filter((e) => e.applicable === true).length > 0;
    this.applicants = of(allApplicantsData);
  }

  optionChanged(clientNumber, option?) {
    this.selectedClientNumber = clientNumber;
    this.selectedApplicants = [];
    this.subscriptions.push(
      this.applicants.pipe(first()).subscribe((val) => {
        const selected: MedicalItem[] = val.map(({ clientNo, applicable }) => ({
          clientNo,
          applicable,
          verification: true,
        }));

        if (option !== undefined) {
          if (option.currentTarget.checked === true) {
            // execute only for none-option event
            for (let i = 0; i < val.length; i++) {
              val[i].applicable = false;
              selected[i].applicable = val[i].applicable;
            }
            if (this.answer) {
              const answeredAilment = this.currentAnswer.filter((a) => {
                return (
                  (a.hasOwnProperty('ailments') && a.ailments.length > 0) ||
                  (a.values && a.values.length > 0)
                );
              });
              if (answeredAilment.length > 0 && this.isNoneChecked) {
                this.currentAnswer.forEach((a) => {
                  val.forEach((element) => {
                    if (
                      a.clientNo === element.clientNo &&
                      a.applicable === true &&
                      ((a.ailments && a.ailments.length > 0) ||
                        (a.values && a.values.length > 0))
                    ) {
                      this.selectedApplicants.push(
                        element.firstName.toLowerCase() +
                          ' ' +
                          element.lastName.toLowerCase()
                      );
                    }
                  });
                });
                this.openModal = true;
              }
            }
          }
        } else {
          this.selectedClientNumber = clientNumber;

          const deselectedApplicant = selected.filter((s) => {
            return !s.applicable && s.clientNo === clientNumber;
          });
          if (this.answer) {
            const answeredAilment = this.currentAnswer.filter((a) => {
              return (
                (a.clientNo === this.selectedClientNumber &&
                  a.hasOwnProperty('ailments') &&
                  a.ailments.length > 0) ||
                (a.values && a.values !== null)
              );
            });
            if (answeredAilment.length > 0 && deselectedApplicant.length > 0) {
              const deselected = val.filter((v) => {
                return v.clientNo === this.selectedClientNumber;
              });
              this.selectedApplicants.push(
                deselected[0].firstName.toLowerCase() +
                  ' ' +
                  deselected[0].lastName.toLowerCase()
              );
              this.openModal = true;
            }
          }
        }

        if (selected.filter((e) => e.applicable === true).length > 0) {
          // unselect none if any applicant selected
          if (this.isNoneChecked && !this.openModal) {
            this.isNoneChecked = false;
          }

          this.anyAnswered = true;
        } else {
          const none = selected.filter((s) => {
            return s.applicable === true;
          });

          if (none.length === 0) {
            this.anyAnswered = false;
          }
        }
        this.saveAnswers(selected);
      })
    );
  }

  saveAll(items: MedicalItem[]) {
    // Creating nodes for all applicants
    // for applicants that are currently being displayed setting verfication = true;
    // means if verification = null, those applicants were not shown the question.

    const defaultList = new Array<MedicalItem>();
    this.application.applicants.map((a) =>
      defaultList.push({
        clientNo: a.clientNo,
        applicable: false,
        value: null,
        verification: null,
      })
    );

    items.map((i) => {
      let defaultItem = defaultList.find((d) => d.clientNo === i.clientNo);
      defaultItem = Object.assign(defaultItem, i);
    });

    this.saveAnswer(true, defaultList, this.answerType);
  }

  confirm(action: any) {
    if (action === 'Done') {
      this.applicants.pipe(first()).subscribe((val) => {
        const selected = val.map(({ clientNo, applicable }) => ({
          clientNo,
          applicable,
        }));
        if (this.selectedClientNumber === '') {
          for (let i = 0; i < val.length; i++) {
            val[i].applicable = false;
            selected[i].applicable = val[i].applicable;
          }

          this.currentAnswer.forEach((element) => {
            element.ailments = [];
            element.details = [];
            element.applicable = false;
            element.values = [];
            element.verification = true;
          });
        } else {
          this.currentAnswer.forEach((element) => {
            if (element.clientNo === this.selectedClientNumber) {
              element.applicable = false;
              element.ailments = [];
              element.details = [];
              element.values = [];
              element.verification = true;
            }
          });
          const none = selected.filter((s) => {
            return s.applicable === true;
          });

          if (none.length === 0) {
            // this.isNoneChecked = true;
            this.anyAnswered = false;
          }
        }
        this.saveAnswers(selected);
        this.openModal = false;
      });
    }
  }

  mapEzAppAnswers(list: MedicalItem[]) {
    const ezAppQualification = this.ezappMapper.drillDownMapper(
      this.question,
      this.application.drillDownQuestions
    );
    const ezAppAilments = this.ezappMapper.drillDownMapper(
      this.question,
      this.application.drillDownQuestions,
      'MedicalAilment'
    );
    if (Array.isArray(list)) {
      list.map((q) => {
        const ezAppAttributeMappedValue = this.ezappMapper.attributeMapper(
          this.question,
          this.application.applicants.find((a) => a.clientNo === q.clientNo)
        );
        const ezAppDrillDownValue = DrillDownQuestionItem.GetApplicantAnswer(
          ezAppQualification,
          q.clientNo
        );

        // if attribute is true or Y just take it
        // don't worry about the other flag.
        if (
          ezAppAttributeMappedValue &&
          (ezAppAttributeMappedValue === true ||
            ezAppAttributeMappedValue === 'Y')
        ) {
          q.ezappValue = 'Y';
        } else {
          q.ezappValue = ezAppDrillDownValue;
        }

        q.ezappAilments = DrillDownQuestionItem.GetApplicantAnswer(
          ezAppAilments,
          q.clientNo
        );
      });
    }
  }

  cancel(action: any) {
    if (action === 'Cancel') {
      this.openModal = false;
      if (this.selectedClientNumber === '') {
        this.isNoneChecked = false;
        this.applicants.pipe(first()).subscribe((val) => {
          const selected = val.map(({ clientNo, applicable }) => ({
            clientNo,
            applicable,
          }));

          this.currentAnswer.forEach((a) => {
            if (
              (a.hasOwnProperty('ailments') && a.ailments.length > 0) ||
              (a.values && a.values.length > 0)
            ) {
              for (let i = 0; i < val.length; i++) {
                if (a.clientNo === val[i].clientNo) {
                  val[i].applicable = true;
                  selected[i].applicable = true;
                }
              }
              a.applicable = true;
            }
          });
          const none = selected.filter((s) => {
            return s.applicable === true;
          });

          if (none.length !== 0) {
            this.anyAnswered = true;
          }
        });
      } else {
        this.applicants.pipe(first()).subscribe((val) => {
          const selected = val.map(({ clientNo, applicable }) => ({
            clientNo,
            applicable,
          }));
          selected.forEach((element) => {
            if (element.clientNo === this.selectedClientNumber) {
              element.applicable = true;
            }
          });
          const allApplicants = this.filterApplicants();
          const allApplicantsData = this.getApplicantModel(
            allApplicants,
            selected
          );
          this.currentAnswer.forEach((element) => {
            if (element.clientNo === this.selectedClientNumber) {
              element.applicable = true;
            }
          });
          // this.saveAnswer(true, this.answer.value, this.answerType);
          this.mapApplicants(this.application.applicants, allApplicantsData);
        });
      }
      this.saveAll(this.currentAnswer);
    }
  }

  filterApplicants() {
    return this.application.applicants.filter((a) => {
      return this.applicantFilterService.Apply({
        answers: this.script.Answers,
        application: this.application,
        applicant: a,
        filterJson: this.details.filters,
        question: this.question,
      });
    });
  }

  getApplicantModel(applicants, selected) {
    const allApplicantsData = [
      ...[applicants, selected]
        .reduce(
          // loop - left join merge where on clientNo, like sql left join
          (m, a) => (
            Array.isArray(a) &&
              a.forEach(
                (o) =>
                  (m.has(o.clientNo) && Object.assign(m.get(o.clientNo), o)) ||
                  m.set(o.clientNo, o)
              ),
            m
          ),
          new Map()
        )
        .values(),
    ];
    return allApplicantsData;
  }

  saveAnswers(selected) {
    if (this.answer) {
      if (this.currentAnswer) {
        const questionData: MedicalItem[] = this.getApplicantModel(
          this.currentAnswer,
          selected
        );
        this.mapEzAppAnswers(questionData);
        // this.saveAnswer(true, questionData, this.answerType);
        this.saveAll(questionData);
      }
    } else {
      this.mapEzAppAnswers(selected);
      // this.saveAnswer(true, selected, this.answerType);
      this.saveAll(selected);
    }
  }
}

export class MedicalQualifyingDetail {
  filters?: string;
  public get ApplicantFilters(): KeyValue<string, string>[] {
    if (this.filters) {
      return JSON.parse(this.filters);
    } else {
      return [];
    }
  }
}
