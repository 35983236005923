import { Component, OnInit } from '@angular/core';
import { BaseQuestionType } from '../baseQuestionType';
import { VcallQuestionTypePaymentDetail } from 'src/app/shared/models/vcall/paymentDetail';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';

@Component({
  selector: 'app-question-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends BaseQuestionType<
  VcallQuestionTypePaymentDetail
> {
  details: VcallQuestionTypePaymentDetail;
  paymentDetails = new VcallQuestionTypePaymentDetail();
  options: Array<string>;
  selectedValue: any = '';
  prompt: string;
  constructor(
    script: ScriptService,
    application: ApplicationService,
    uiservice: UiServiceService
  ) {
    super(script, application, uiservice);
  }
  ApplicationChanged(): void {}

  optionChanged(value: string) {
    this.selectedValue = value;
    this.promptText = this.prompt;
    this.saveAnswer(true, value);
  }

  QuestionChanged(q: Question): void {
    if (this.answer) {
      this.selectedValue = this.answer.value as string;
    }

    Object.assign(this.paymentDetails, this.question.details);
    this.options = JSON.parse(this.details.options);
    this.prompt = this.safeInterpolate(
      this.paymentDetails.jointAccountQuestion
    );
  }

  CanGoNext(): boolean {
    if (this.selectedValue !== undefined && this.selectedValue !== null) {
      return true;
    }
  }

  ResetView(): void {}
}
