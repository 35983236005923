import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-user-training-documents',
  templateUrl: './user-training-documents.component.html',
  styleUrls: ['./user-training-documents.component.scss'],
})
export class UserTrainingDocumentsComponent implements OnInit {
  @Input() Documents;
  selectedDocument;
  constructor() {}

  ngOnInit() {
    this.selectedDocument = this.Documents[0];
    this.eachDocument(this.selectedDocument);
  }

  eachDocument(content) {
    this.selectedDocument = content;
  }
}
