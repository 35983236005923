import { Applicant } from 'src/app/shared/models/application/applicant';
import { Contact } from 'src/app/shared/models/application/contact';

export interface ApplicationLogs {
  createdDate: string;
  applicationType: string;
  createdBy: string;
  applicantName: string;
}

export interface AddOnApplication {
  id?: string;
  appId: number;
  appType: number;
  type: string;
  state: string;
  createdOn: string;
  createdBy: string;
  family: any;
  applicantCount?: number;
  applicantNames?: string[];
  addOnAppId?: string;
}

export interface AddonApplicantAuditItem {
  updatedBy: string;
  updatedOn: string;
  comment: string;
}

export interface AddonApplicant extends Applicant {
  contact: Contact;
}

export interface CreateApplicationTrackerRecord {
  applicationId: number;
  applicationCreatedDate: string;
  primaryContactName: string;
  agentName: string;
  product: string;
  controlNumber: string;
  applicationStatusId: number;
  assignedTo: string;
  createdBy: string;
  createdDate: string;
  applicationTrackerType: string;
  addOnApplicationId: string;
  state: string;
}

export enum ApplicantActionType {
  addApplicant = 'addApplicant',
  editApplicant = 'editApplicant',
}

export enum ApplicantType {
  AddOn = 'AddOn',
  AddOnApplication = 'AddOnApplication',
  Regular = 'Regular',
}
