import { Pipe, PipeTransform } from '@angular/core';
import { IName } from '../interfaces/IName';

@Pipe({
  name: 'fullname',
})
export class FullnamePipe implements PipeTransform {
  /**
   *
   * @param value value
   * @param args arguments
   * @description create the full name pipe filter
   * @returns returns the full name
   */
  transform(value: IName, args?: any): any {
    return value !== null ? value.firstName + ' ' + value.lastName : '';
  }
}
