<div class="p-3">
  <div class="row pb-3 pt-1">
    <div class="col-4 form-inline" *ngIf="notes">
      <label class="pr-3 font-weight-bold">View:&nbsp;</label>
      <select
        class="form-control form-control-sm col-sm-8"
        [(ngModel)]="selected"
        (ngModelChange)="onSelect(selected)"
      >
        <option value="All">All</option>
        <option *ngFor="let verifier of uniqueList" [value]="verifier">
          {{ verifier }}
        </option>
      </select>
    </div>
    <div class="col" *ngIf="detailOptions?.canAddNotes !== false">
      <button
        class="btn btn-sm btn-outline-primary float-right"
        (click)="openModal = true"
        [disabled]="appointment.statusId === '1' || !application.isValid"
        [appPermission]="'CanAccessNotes'"
      >
        Add Note
      </button>
    </div>
  </div>
  <div class="row scroll">
    <div class="col">
      <table class="table table-hover table-striped tbl-log">
        <thead>
          <tr class="table-dark table_header_fixed">
            <th scope="col" class="text-center w-20"></th>
            <th scope="col" class="text-center w-20">Verifier</th>
            <th scope="col" class="text-center w-20">Verified Date</th>
            <th scope="col" class="text-center w-20">Call Status</th>
            <th scope="col" class="text-center w-20">Notes</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of selectedList; index as i; let odd = odd">
          <tr [ngClass]="{ 'bg-white': odd }">
            <td class="text-center w-20">
              <div *ngIf="item.pinIcon === true">
                <i
                  class="fa fa-thumbtack f-16 text-secondary"
                  *ngIf="!item?.pinned"
                  (click)="pinMessage(item)"
                ></i>
                <i
                  class="fa fa-thumbtack f-16 text-primary"
                  *ngIf="item?.pinned"
                  (click)="unPinMessage(item)"
                ></i>
              </div>
            </td>
            <th scope="row" class="text-center text-nowrap w-20">
              {{ item.name }}
            </th>
            <td class="text-center text-nowrap w-20">{{ item.date }}</td>
            <td class="text-center text-nowrap w-20">
              {{ item.reason ? item.reason : "-" }}
            </td>
            <td class="text-center w-20">
              <span>{{ item.notes }}</span>
              <div>
                <div *ngIf="item.section">
                  Section: <span class="text-primary">{{ item.section }}</span>
                </div>
                <div *ngIf="item.answerTag">
                  Question:
                  <span class="text-primary">{{ item.answerTag }}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-modal
  [open]="openModal"
  [modalOptions]="{ size: 'lg' }"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Submit',
    cancelButtonName: 'Cancel'
  }"
  (actions)="submitNote($event)"
  [formValidation]="noteForm.valid"
>
  <div title>
    <h4 class="modal-title" id="modal-basic-title">
      <div class="row">
        <div class="col-7">
          <div class="row">
            <div class="col-1">
              <i class="fas fa-user fa-modal-header text-black-50 mt-2"></i>
            </div>
            <div class="col">
              <h5>Applicant Name</h5>
              <div class="note-hdr">
                {{ application.primaryApplicant | fullname }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-2">
              <i
                class="fas fa-calendar-alt fa-modal-header text-black-50 mt-2"
              ></i>
            </div>
            <div class="col">
              <h5>Scheduled Time</h5>
              <div class="note-hdr">
                <div *ngIf="appointment">
                  {{ appointment.scheduledDate | date: "short" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </h4>
  </div>
  <div body>
    <form [formGroup]="noteForm" id="addNoteForm">
      <div>
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <div class="call-info text-center text-lg mb-2" role="alert">
                <strong>Please enter your notes in the text field</strong> ( 160
                characters max )
              </div>
            </div>
          </div>
          <textarea
            name="note"
            id="notes"
            formControlName="notes"
            required
            class="form-control"
            placeholder="Please add notes here"
            rows="7"
            maxlength="160"
            autocomplete="off"
          ></textarea>
          <div
            *ngIf="
              noteForm.controls['notes'].invalid &&
              (noteForm.controls['notes'].dirty ||
                noteForm.controls['notes'].touched)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="noteForm.controls['notes'].errors?.required">
              Note is required.
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-modal>
