<app-modal
  [open]="openAddApplicantModal"
  [modalOptions]="{ size: 'xl' }"
  [footer]="false"
  [buttonsConfig]="{
    modalMode: 'form',
    position: 'center',
    submitButtonName: 'Save',
    cancelButtonName: 'Cancel'
  }"
>
  <div title class="text-center mb-3">
    <ng-container *ngIf="addOnActionType === 'editApplicant'; else elseBlock">
      Edit
    </ng-container>
    <ng-template #elseBlock>
      Add
    </ng-template>
    Applicant
  </div>
  <div body>
    <form #applicantForm="ngForm">
      <ng-container>
        <h5 class="text-center">Personal Details</h5>
        <div class="mt-2 px-5 py-3" *ngIf="addPersonalDetails">
          <div class="row">
            <div class="col-4 form-group">
              <label for="firstName"
                >First Name <span class="text-danger">*</span></label
              >
              <input
                name="firstName"
                id="firstName"
                [(ngModel)]="addPersonalDetails.firstName"
                pattern="^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$"
                #firstName="ngModel"
                required
                class="form-control"
                placeholder="First Name"
                (keyup)="titleCase('firstName', firstName.model)"
              />
              <div
                *ngIf="
                  firstName.invalid &&
                  (firstName.dirty || firstName.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="firstName.errors.required">
                  First Name is required.
                </div>
                <div *ngIf="firstName.errors.pattern">
                  Enter Valid First Name.
                </div>
              </div>
            </div>
            <div class="col-4 form-group">
              <label for="middleName">Middle Name</label>
              <input
                name="middleName"
                id="middleName"
                [(ngModel)]="addPersonalDetails.middleName"
                #middleName="ngModel"
                pattern="^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$"
                class="form-control"
                placeholder="Middle Name"
                (keyup)="titleCase('middleName', middleName.model)"
              />
              <div
                *ngIf="
                  middleName.invalid &&
                  (middleName.dirty || middleName.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="middleName.errors.pattern">
                  Enter Valid Last Name.
                </div>
              </div>
            </div>
            <div class="col-4 form-group">
              <label for="lastName"
                >Last Name <span class="text-danger">*</span></label
              >
              <input
                name="lastName"
                id="lastName"
                [(ngModel)]="addPersonalDetails.lastName"
                #lastName="ngModel"
                required
                pattern="^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$"
                class="form-control"
                placeholder="Last Name"
                (keyup)="titleCase('lastName', lastName.model)"
              />
              <div
                *ngIf="
                  lastName.invalid &&
                  (lastName.dirty || lastName.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="lastName.errors.required">
                  Last Name is required.
                </div>
                <div *ngIf="lastName.errors.pattern">
                  Enter Valid Last Name.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 form-group">
              <label for="gender" class="radio-inline mr-3"
                >Gender <span class="text-danger">*</span></label
              >
              <div>
                <label class="radio-inline mr-3">
                  <input
                    type="radio"
                    id="gender"
                    name="gender"
                    value="M"
                    [(ngModel)]="addPersonalDetails.gender"
                    #gender="ngModel"
                    name="gender"
                    required
                  />
                  Male</label
                >
                <label class="radio-inline">
                  <input
                    type="radio"
                    id="gender"
                    name="gender"
                    value="F"
                    [(ngModel)]="addPersonalDetails.gender"
                    #gender="ngModel"
                    name="gender"
                    required
                  />
                  Female</label
                >
              </div>
              <div
                *ngIf="
                  gender.invalid &&
                  (gender.dirty || gender.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="gender.errors.required">
                  Gender is required.
                </div>
              </div>
            </div>
            <div class="col-4 form-group">
              <label for="height"
                >Height <span class="text-danger">*</span></label
              >
              <select
                class="form-control"
                id="height"
                name="height"
                [(ngModel)]="addPersonalDetails.height"
                #height="ngModel"
                placeholder="Height"
                required
              >
                <option value="null">Choose Height...</option>
                <ng-container
                  *ngFor="let height of service.getApplicantHeight()"
                >
                  <option [ngValue]="height.value">{{ height.value }}</option>
                </ng-container>
              </select>
              <div
                *ngIf="
                  height.invalid &&
                  (height.dirty || height.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="height.errors.required">
                  Height is required.
                </div>
              </div>
            </div>
            <div class="col-4 form-group">
              <label for="weight"
                >Weight(lbs)<span class="text-danger">*</span></label
              >
              <input
                name="weight"
                id="weight"
                [(ngModel)]="addPersonalDetails.weight"
                pattern="^[0-9]+$"
                #weight="ngModel"
                type="text"
                required
                class="form-control"
                maxlength="3"
              />
              <div
                *ngIf="
                  weight.invalid &&
                  (weight.dirty || weight.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="weight.errors.required">
                  Weight is required.
                </div>
                <div *ngIf="weight.errors.pattern">
                  Enter valid Weight
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 form-group">
              <label for="birthDate"
                >DOB <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  name="birthDate"
                  id="birthDate"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  [(ngModel)]="addPersonalDetails.birthDate"
                  #birthDate="ngModel"
                  [maxDate]="minDate"
                  [minDate]="{ year: 1900, month: 1, day: 1 }"
                  required
                  class="form-control"
                  placeholder="DOB"
                  (click)="d.toggle()"
                  readonly
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary fa fa-calendar"
                    (click)="d.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-4 form-group">
              <label for="ssn">SSN</label>
              <input
                name="ssn"
                id="ssn"
                [(ngModel)]="addPersonalDetails.ssn"
                #ssn="ngModel"
                pattern="^[0-9]{3}-[0-9]{2}-[0-9]{4}$"
                mask="000-00-0000"
                class="form-control"
                [showMaskTyped]="true"
                [dropSpecialCharacters]="false"
              />
              <div
                *ngIf="
                  ssn.invalid && (ssn.dirty || ssn.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="ssn.errors.pattern">
                  Invalid SSN.
                </div>
              </div>
            </div>
            <div class="col-4 form-group">
              <label for="occupation">Occupation/Duties</label>
              <input
                name="occupation"
                id="occupation"
                [(ngModel)]="addPersonalDetails.occupation"
                #occupation="ngModel"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <h5 class="text-center">Contact Details</h5>
        <div class="mt-2 px-5 py-3" *ngIf="addContact">
          <div class="row">
            <div class="col form-group">
              <label for="firstName"
                >Street Address <span class="text-danger">*</span></label
              >
              <input
                name="street"
                id="street"
                [(ngModel)]="addContact.streetAddress"
                #street="ngModel"
                required
                class="form-control"
              />
              <div
                *ngIf="
                  street.invalid &&
                  (street.dirty || street.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="street.errors.required">
                  Street Address is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 form-group">
              <label for="city">City <span class="text-danger">*</span></label>
              <input
                name="city"
                id="city"
                [(ngModel)]="addContact.city"
                #city="ngModel"
                pattern="^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$"
                required
                class="form-control"
              />
              <div
                *ngIf="
                  city.invalid && (city.dirty || city.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="city.errors.required">
                  City is required.
                </div>
                <div *ngIf="city.errors.pattern">
                  Enter valid City.
                </div>
              </div>
            </div>
            <div class="col-4 form-group">
              <label for="state"
                >State <span class="text-danger">*</span></label
              >
              <select
                name="state"
                id="state"
                [(ngModel)]="addContact.state"
                #state="ngModel"
                required
                class="form-control"
              >
                <option value="" selected>Select State</option>
                <option *ngFor="let opt of getStates()" [value]="opt.state">{{
                  opt.state
                }}</option>
              </select>
              <div
                *ngIf="
                  state.invalid &&
                  (state.dirty || state.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="state.errors.required">
                  State is required.
                </div>
              </div>
            </div>
            <div class="col-4 form-group">
              <label for="zip"
                >Zip Code <span class="text-danger">*</span></label
              >
              <input
                name="zip"
                id="zip"
                [(ngModel)]="addContact.zipCode"
                pattern="^([0-9]{5,6}(-[0-9]{4})?)?$"
                #zipCode="ngModel"
                type="text"
                required
                class="form-control"
              />
              <div
                *ngIf="
                  zipCode.invalid &&
                  (zipCode.dirty || zipCode.touched || applicantSave)
                "
                class="mt-1 text-danger small"
              >
                <div *ngIf="zipCode.errors.required">
                  Zip code is required.
                </div>
                <div *ngIf="zipCode.errors.pattern">
                  Enter five digit valid ZipCode.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 form-group">
              <label for="home"
                >Home Phone <span class="text-danger">*</span></label
              >
              <input
                name="home"
                pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                type="tel"
                id="home"
                [(ngModel)]="addContact.homePhone"
                #home="ngModel"
                mask="(000) 000-0000"
                required
                class="form-control"
                [showMaskTyped]="true"
                [dropSpecialCharacters]="false"
              />
            </div>
            <div class="col-4 form-group">
              <label for="mobile">Mobile Phone</label>
              <input
                name="mobile"
                id="mobile"
                type="tel"
                [(ngModel)]="addContact.mobilePhone"
                #mobile="ngModel"
                pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                class="form-control"
                mask="(000) 000-0000"
                [showMaskTyped]="true"
                [dropSpecialCharacters]="false"
              />
            </div>
            <div class="col-4 form-group">
              <label for="work">Work Phone</label>
              <input
                name="work"
                type="tel"
                id="work"
                [(ngModel)]="addContact.workPhone"
                #work="ngModel"
                pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                mask="(000) 000-0000"
                [showMaskTyped]="true"
                [dropSpecialCharacters]="false"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="mt-2 px-5 py-3" *ngIf="addOnActionType === 'editApplicant'">
          <textarea
            name="comment"
            id="comment"
            class="col-12 border"
            rows="5"
            [(ngModel)]="updateReason"
          ></textarea>
        </div>
        <div class="mt-5 d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="closeModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4 px-4"
            (click)="submit()"
            [disabled]="applicantForm.invalid"
          >
            Save
          </button>
        </div>
      </ng-container>
    </form>
  </div>
</app-modal>
