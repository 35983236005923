<div class="alert alert-secondary ml-4 mr-4">
  <div class="row">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-6 font-weight-bold text-right">Applicant:</div>
        <div class="col-6">{{ getNameKey(details["questionList"][0]) }}</div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="row">
        <div class="col-4 font-weight-bold text-right">Reason:</div>
        <div class="col-6">{{ details["questionList"][0].recallReason }}</div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="offset-sm-10">
    <button
      type="button"
      class="btn btn-primary btn-sm px-4 add-question-button"
      (click)="openAddQuestionModal()"
    >
      Add Question
    </button>
  </div>
</div>
<ul>
  <li
    *ngFor="let question of details['questionList']; index as i"
    class="d-inline-flex w-100"
  >
    <div class="row d-inline-flex w-100 question-container">
      <div class="col px-5">
        <p
          class="mr-4 pl-1"
          [innerHTML]="interpolateQuestionText(question.questionText)" [attr.data-question]="question | json"
        ></p>
        <div class="row mt-4">
          <div class="col-12">
            <textarea
              type="text"
              class="form-control border"
              rows="5"
              name="detail"
              (paste)="SavePastedText(question, $event)"
              [value]="getAnswerForAnswerTag(question.answerTag)"
              (keyup)="SaveText(question, $event)"
              [disabled]="getIgnoredFlagForAnswerTag(question.answerTag)"
              placeholder="Please enter your answer"
              [ngClass]="[
                getAnswerForAnswerTag(question.answerTag) === ''
                  ? 'textarea_bacground'
                  : '',
                getIgnoredFlagForAnswerTag(question.answerTag) === true
                  ? 'textarea_bacground_none'
                  : ''
              ]"
              (focusout)="handleFocusOut(question, $event)"
            ></textarea>
          </div>
        </div>
        <label>
          <input
            type="checkbox"
            class="m-1 mt-3"
            (change)="saveIgnoredFlag(question, $event)"
            [checked]="getIgnoredFlagForAnswerTag(question.answerTag)"
          />
          Ignore
        </label>
        <hr />
        <br />
      </div>
    </div>
  </li>
</ul>

<app-modal
  [open]="openModal"
  [modalOptions]="{ size: 'lg' }"
  [footer]="showFooter"
  [buttonsConfig]="{
    position: 'center',
    modalMode: 'form',
    buttonSize: 'large',
    submitButtonName: 'Submit',
    cancelButtonName: 'Cancel'
  }"
  class="border-bottom-0"
  [formValidation]="
    recallAppointmentData?.reason &&
    recallAppointmentData?.applicantName &&
    listofAddedQuestions.length >= 1
  "
  (actions)="SubmitAndCancelHandler($event)"
>
  <div title class="row justify-content-center ml-3">
    <ng-container>
      Add Question
    </ng-container>
  </div>
  <div body class="px-3">
    <ng-container>
      <app-recall-addquestion
        [recallApplicants]="[
          {
            fullName: details['questionList'][0].applicantName,
            relationship: details['questionList'][0].relationship,
            clientNo: details['questionList'][0].clientNo
          }
        ]"
        (listOfQuestions$)="saveListOfAddedQuestions($event)"
        [clearData]="clearData"
        (formData$)="saveFormData($event)"
      >
      </app-recall-addquestion>
    </ng-container>
    <app-modal></app-modal></div
></app-modal>
