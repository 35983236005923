import { LoggerService } from 'src/app/core/logger.service';
import { Answer } from '../../models/application/answer';
import { Application } from '../../models/application/application';
import { Appointment } from '../../models/appointment';
import { CallZoneUser } from '../../models/callzone-user';
import { Question } from '../../models/vcall/question';
import { Script } from '../../models/vcall/script';
import { UserProfile } from '../../models/user-profile';

export class ProcessorModel {
  application?: Application;
  agent?: CallZoneUser;
  appointment?: Appointment;
  answers?: Answer[];
  script?: Script;
  logger?: LoggerService;
  question?: Question;
  userProfile?: UserProfile;
  ignoreTheErros?: boolean;
}
