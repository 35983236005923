import { Component, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { PermissionService } from '../shared/services/permission/permission.service';
import { UserProfileService } from '../shared/services/user/user-profile.service.service';

@Component({
  selector: 'app-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.scss'],
})
export class ImpersonationComponent implements OnInit {
  constructor(
    private router: Router,
    public permissionService: PermissionService,
    private profile: UserProfileService
  ) {}

  applicationId;
  resourceId: any = 5212;
  from = ''; // '2020-07-14';
  to = '';
  appId = ''; // 4002382

  permissions = [];
  roles = [];
  allRoles = [];

  ngOnInit() {
    this.permissionService
      .PermissionSnapShot()
      .subscribe((ps) => (this.permissions = ps));
    this.permissionService.AllRoles().subscribe((all) => (this.allRoles = all));
    this.profile.Roles.subscribe((rs) => (this.roles = rs));
  }

  launch(resourceId, appId, from, to) {
    // `/appointments?${this.application.id}/appointment/${this.appointment.appointmentId}`
    this.router.navigateByUrl(
      `appointments?resource=${resourceId}&start=${from}&end=${to}&appid=${appId.trim()}`
    );
  }

  dashboard() {
    this.router.navigateByUrl('/');
  }

  changeuser(user) {
    this.resourceId = user.currentTarget.value;
  }

  onDateSelection(date: NgbDate) {
    this.from = date.year + '-' + date.month + '-' + date.day;
    this.to = date.year + '-' + date.month + '-' + (date.day + 1);
  }

  assumeRole(newRole: string) {
    this.profile.setRoles([newRole.trim()]);
  }

  launchRecall() {
    if (this.applicationId !== undefined && this.applicationId !== '') {
      this.router.navigateByUrl(
        '/underwriting/worksheet/application/' + this.applicationId
      );
    }
  }
}
