import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

/**
 * Not Authorized Component
 */
@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent implements OnInit {
  /**
   * Not Authorized Constructor
   */
  constructor(private router: Router, private msalService: MsalService) {}

  /**
   * Component initialization
   */
  ngOnInit() {
    const loggedIn = !!this.msalService.instance.getActiveAccount();
    if (!loggedIn) {
      this.msalService.loginRedirect();
    } else {
      this.router.navigate(['/']);
    }
  }
}
