import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Application } from 'src/app/shared/models/application/application';
import { Script } from 'src/app/shared/models/vcall/script';
import { VcallParams } from 'src/app/shared/models/vcall/vcallParams';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import {
  ScriptService,
  VcallMode,
} from 'src/app/shared/services/script/script.service';
import {
  ApplicationReviewItem,
  ApplicationReviewItemConfiguration,
  ApplicationReviewItemFilter,
  ApplicationReviewItemType,
  ApplicationReviewItemViewModel,
  DiscrepancyApplicationReviewItemViewModel,
  DropDrownApplicationReviewItemViewModel,
  GeneralDetailsApplicationReviewItemViewModel,
  OccupationApplicationReviewItemViewModel,
  OccupationReviewItem,
  PaymentApplicationReviewItemViewModel,
  QuestionNotesApplicationReviewItemViewModel,
  RxProfileApplicationReviewItemViewModel,
  RxProfileGlobalApplicationReviewItemViewModel,
} from '../../models/review-item';
import { ApplicationReviewService } from '../../services/application-review.service';
// import { AdditionalInfoComponent } from '../additional-info/additional-info.component';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  models: ApplicationReviewItemViewModel[];
  application: Application;
  vcallApplication: Application;
  selectedApplicant: Applicant;
  searchParameter: string;
  filter: ApplicationReviewItemFilter = {};
  all: ApplicationReviewItem[];
  active = 1;
  applicationData;
  selectedIndex: number;
  storageData;
  selectedGroup: ApplicationReviewItemViewModel;
  drillDownGroup: DropDrownApplicationReviewItemViewModel; // TO drive the html.
  discrepancyGroup: DiscrepancyApplicationReviewItemViewModel;
  medicalGroup: DropDrownApplicationReviewItemViewModel;
  demoGraphicGroup: DropDrownApplicationReviewItemViewModel;
  occupationGroup: OccupationApplicationReviewItemViewModel;
  rxProfileGroup: RxProfileApplicationReviewItemViewModel;
  rxProfileGlobalGroup: RxProfileGlobalApplicationReviewItemViewModel;
  questionNotesGroup: QuestionNotesApplicationReviewItemViewModel;
  paymentGroup: PaymentApplicationReviewItemViewModel;
  generalDetailsGroup: GeneralDetailsApplicationReviewItemViewModel;
  employedList: OccupationReviewItem[] = [];
  unEmployedList: OccupationReviewItem[] = [];
  fullTimeStudentList: OccupationReviewItem[] = [];
  script: Script = null;
  criticalQuestionDetailsModel: any;
  // additionalInfoComp: AdditionalInfoComponent;
  // medicalHistoryDrilldownGroup:

  // What we want to display in the list on the LHS.
  groups: Array<ApplicationReviewItemConfiguration> = [
    {
      type: ApplicationReviewItemType.Demographic,
      tagExpression: /(DEMOGRAPHIC.(?!EMPLOYMENT)(?!STUDENT)(?!RXAUTHORIZATION)([a-zAZ]*).APPLICABLE|APPLICATION.CONTACT|APPLICATION.PRIMARYAPPLICANT)/i,
      displayLabel: 'Demographic',
    },
    {
      type: ApplicationReviewItemType.MedicalApplicability,
      tagExpression: /^MEDICAL.([a-zAZ]*).APPLICABLE$/i,
      displayLabel: 'Medical',
    },
    {
      type: ApplicationReviewItemType.MedicalHistoryApplicability,
      tagExpression: /MEDICALHISTORY.([a-zAZ]*).APPLICABLE|MEDICALHISTORY.PRESCRIPTIONDRUGS.PRESCRIPTIONDRUGS|MEDICALHISTORY.ADDRESSOFDOCTOR.ADDRESSOFDOCTOR/i,
      displayLabel: 'Medical History',
    },
  ];
  tobaccoUsageStatus: string | boolean;
  openCreateOrderModal = false;
  canCreateOrderUW: boolean;

  constructor(
    private appService: ApplicationService,
    private reviewService: ApplicationReviewService,
    private route: ActivatedRoute,
    protected scriptService: ScriptService
  ) {}

  ngOnInit() {
    const vCall = new VcallParams(this.route.snapshot.paramMap);
    this.appService.SelectedApplication.subscribe((a) => {
      this.vcallApplication = a;
      this.processItems();
    });
    this.reviewService
      .GetReviewItems(vCall.applicationId)
      .subscribe((items) => {
        this.all = items;
        this.applicationData = this.vcallApplication;
        this.processItems();
      });
    this.appService.ezAppApplication.subscribe((val) => {
      this.application = val;
      this.processItems();
    });

    // this is to load the script for critical questions validation
    vCall.mode = VcallMode.Standard;
    vCall.stopNavigation = 'true';
    this.scriptService.LoadScript(vCall);
    this.scriptService.ApplicationScript.subscribe((a) => {
      this.script = a;
      this.processItems();
    });
    // Keeping it as commented for future use case
    // this.apsComp.ngOnInit();
    // this.canCreateOrderUW = this.apsComp.canCreateOrder;
  }

  getType(clientNo, occupationList) {
    occupationList = occupationList.filter((o) => {
      return o.clientNo !== null;
    });
    this.employedList = occupationList.filter(
      (item) => item.type === 'Employed'
    );
    this.unEmployedList = occupationList.filter(
      (item) => item.type === 'Unemployed'
    );
    this.fullTimeStudentList = occupationList.filter(
      (item) => item.type === 'FullTime Student'
    );
    let val;
    occupationList.forEach((o) => {
      if (o.clientNo !== undefined && o.clientNo === clientNo) {
        val = o.type;
      }
    });
    return val;
  }
  getOccupation(occupationList, clientNo) {
    const val = [];
    occupationList?.forEach((o) => {
      if (o.clientNo !== undefined && o.clientNo === clientNo) {
        if (o.type === 'Employed' && o.displayLabel === 'Occupation') {
          val.push(o.vCallValue);
        } else if (o.type !== 'Employed') {
          val.push(o.type);
        }
      }
    });
    if (val === null) {
      val.push(this.selectedApplicant.occupation);
    }
    return val;
  }

  processItems() {
    if (this.all && this.application && this.script) {
      const items = this.all;
      const list: ApplicationReviewItem[] = new Array<ApplicationReviewItem>();
      items.map((i) => list.push(new ApplicationReviewItem(i)));
      this.models = this.reviewService.GetGroups(this.groups, list);

      const changedViewModel = ApplicationReviewItemViewModel.Create(
        ApplicationReviewItemType.Discrepancies,
        this.reviewService
      );
      changedViewModel.all = list;
      changedViewModel.configuration = {
        displayLabel: 'Discrepancies',
        tagExpression: null,
        type: ApplicationReviewItemType.Discrepancies,
      };
      changedViewModel.Process(this.application);

      const occupationModel = ApplicationReviewItemViewModel.Create(
        ApplicationReviewItemType.Occupation,
        this.reviewService
      );
      occupationModel.all = list;
      occupationModel.configuration = {
        displayLabel: 'Occupation',
        tagExpression: null,
        type: ApplicationReviewItemType.Occupation,
      };
      occupationModel.Process(this.application);

      const rxPrfoleModel = ApplicationReviewItemViewModel.Create(
        ApplicationReviewItemType.RxProfile,
        this.reviewService
      );
      rxPrfoleModel.all = list;
      rxPrfoleModel.configuration = {
        displayLabel: 'Rx Profile',
        tagExpression: null,
        type: ApplicationReviewItemType.RxProfile,
      };
      rxPrfoleModel.Process(this.application);
      const rxProfileGlobalModel = ApplicationReviewItemViewModel.Create(
        ApplicationReviewItemType.RxProfileGlobal,
        this.reviewService
      );
      rxProfileGlobalModel.all = list;
      rxProfileGlobalModel.configuration = {
        displayLabel: 'Rx Profile',
        tagExpression: null,
        type: ApplicationReviewItemType.RxProfileGlobal,
      };
      rxProfileGlobalModel.Process(this.application);

      const notesModel = ApplicationReviewItemViewModel.Create(
        ApplicationReviewItemType.Notes,
        this.reviewService
      );
      notesModel.all = list;
      notesModel.configuration = {
        displayLabel: 'VCall Notes',
        tagExpression: null,
        type: ApplicationReviewItemType.Notes,
      };
      notesModel.Process(this.application);

      const paymentModel = ApplicationReviewItemViewModel.Create(
        ApplicationReviewItemType.Payment,
        this.reviewService
      );
      paymentModel.all = list;
      paymentModel.configuration = {
        displayLabel: 'Payment',
        tagExpression: null,
        type: ApplicationReviewItemType.Payment,
      };
      paymentModel.Process(this.application);

      const generalDetailsModel = ApplicationReviewItemViewModel.Create(
        ApplicationReviewItemType.GeneralDetails,
        this.reviewService
      );
      generalDetailsModel.all = list;
      generalDetailsModel.configuration = {
        displayLabel: 'GeneralDetails',
        tagExpression: null,
        type: ApplicationReviewItemType.GeneralDetails,
      };
      generalDetailsModel.Process(this.application);

      const criticalItemsModel = ApplicationReviewItemViewModel.Create(
        ApplicationReviewItemType.CriticalQuestion,
        null
      );

      criticalItemsModel.all = list;
      criticalItemsModel.configuration = {
        displayLabel: 'CriticalItems',
        tagExpression: null,
        type: ApplicationReviewItemType.CriticalQuestion,
      };

      criticalItemsModel.script = this.script;
      criticalItemsModel.Process(this.application);

      this.models.push(changedViewModel);
      this.models.push(occupationModel);
      this.models.push(rxPrfoleModel);
      this.models.push(rxProfileGlobalModel);
      this.models.push(notesModel);
      this.models.push(paymentModel);
      this.models.push(generalDetailsModel);
      this.models.push(criticalItemsModel);

      this.linkSelected(this.vcallApplication.applicants[0]);
    }
  }

  toggleYesAndNo(event) {
    const yesOnly = event.currentTarget.checked;
    this.filter.showYesOnly = yesOnly;
    this.models.map((model) => {
      if (
        model.configuration.type ===
        ApplicationReviewItemType.MedicalHistoryApplicability
      ) {
        this.selectedGroup = model;
        this.selectedGroup.Filter(this.filter);
      }
    });
  }

  applicantChanged() {
    this.filter.applicant = this.selectedApplicant;
    // this.filter.showYesOnly = true;
    // const vCall = new VcallParams(this.route.snapshot.paramMap);
    // const storageData = JSON.parse(localStorage.getItem('review'));
    // console.log('dropdown storage data', this.selectedGroup.stats);
    // const configInfo = {
    //   appId: vCall.applicationId,
    //   labelName: this.selectedGroup.configuration.displayLabel, applicant: this.selectedApplicant
    // };
    // if (storageData === null) {
    //   localStorage.setItem('review', JSON.stringify(configInfo));
    // } else {
    //   localStorage.clear();
    //   localStorage.setItem('review', JSON.stringify(configInfo));
    // }
    this.filter.showYesOnly = true;
    this.selectedGroup.Filter(this.filter);
  }

  search() {
    this.filter.searchString = this.searchParameter;
    this.selectedGroup.Filter(this.filter);
  }

  linkSelected(applicant) {
    this.selectedApplicant = applicant;
    this.filter.applicant = applicant;
    this.models.map((model) => {
      this.selectedGroup = model;
      this.selectedGroup.Process(this.application);
      this.selectedGroup.Filter(this.filter);
      switch (model.configuration.type) {
        case ApplicationReviewItemType.MedicalHistoryApplicability: {
          this.drillDownGroup = model;
          return;
        }
        case ApplicationReviewItemType.Discrepancies: {
          this.discrepancyGroup = model;
          return;
        }
        case ApplicationReviewItemType.MedicalApplicability: {
          this.medicalGroup = model;

          return;
        }
        case ApplicationReviewItemType.Demographic: {
          this.demoGraphicGroup = model;
          return;
        }
        case ApplicationReviewItemType.Occupation: {
          this.occupationGroup = model;
          return;
        }
        case ApplicationReviewItemType.RxProfile: {
          this.rxProfileGroup = model;
          return;
        }
        case ApplicationReviewItemType.RxProfileGlobal: {
          this.rxProfileGlobalGroup = model;
          return;
        }
        case ApplicationReviewItemType.Notes: {
          this.questionNotesGroup = model;
          return;
        }
        case ApplicationReviewItemType.Payment: {
          this.paymentGroup = model;
          return;
        }
        case ApplicationReviewItemType.GeneralDetails: {
          this.generalDetailsGroup = model;
          return;
        }
        case ApplicationReviewItemType.CriticalQuestion: {
          this.criticalQuestionDetailsModel = model;
          return;
        }
      }
    });

    const tobaccoVcallValue = this.medicalGroup?.drillDownitems
      ?.filter(
        (m) =>
          m.applicableItem.tag === 'MEDICAL.TOBACCO.APPLICABLE' &&
          m.applicableItem.clientNo === applicant.clientNo
      )
      .pop()?.applicableItem?.vCall?.value;
    const tobaccoEzappValue = this.medicalGroup?.drillDownitems
      ?.filter(
        (m) =>
          m.applicableItem.tag === 'MEDICAL.TOBACCO.APPLICABLE' &&
          m.applicableItem.clientNo === applicant.clientNo
      )
      .pop()?.applicableItem?.ezApp?.value;
    this.tobaccoUsageStatus =
      tobaccoVcallValue?.toString() === 'true' ||
      tobaccoEzappValue?.toString() === 'Y'
        ? 'Yes'
        : 'No';
  }
  isValueAnArray(ezAppVal) {
    if (ezAppVal instanceof Array) {
      return true;
    } else {
      return false;
    }
  }

  isValueIsBoolean(val) {
    if (val.endsWith('NOTES')) {
      return false;
    } else {
      return true;
    }
  }

  objectKeys(item) {
    return Object.keys(item);
  }

  createRequest() {
    this.openCreateOrderModal = true;
  }
  highlightInRed(answerTag: string) {
    let questionExists = false;
    this.criticalQuestionDetailsModel.criticalQuestions.forEach((question) => {
      if (
        question.answerTag.toLocaleLowerCase() === answerTag.toLocaleLowerCase()
      ) {
        questionExists = true;
      }
    });

    return questionExists;
  }
}
