import { KeyValue } from '@angular/common';

export class VcallQuestionTypePaymentDetail {
  creditCardText: string;
  bankDraftText: string;
  bankDraftValue: string;
  creditCardValue: string;
  directBillText: string;
  directBillValue: string;
  modeOfPaymentQuestion: string;
  changeModeOfPaymentQuestion: string;
  specificDateQuestion: string;
  verifiedText: string;
  verifiedValue: string;
  notVerifiedText: string;
  notVerifiedValue: string;
  specificDate: string;
  nextDate: string;
  applicationApproval: string;
  options: string;
  frequencyQuestion: string;
  effectiveDateRules: string;
  methodOfPaymentAgreementText: string;
  methodOfPaymentChangePrompt: string;
  availableTypes: string;
  jointAccountQuestion: string;
  PayorIsApplicant: string;
  PayorIsSomeoneElseAndOffline: string;
  PayorIsSomeoneElseAndOnline: string;
  PayorSelectorTag: string;
  directBillNote: string;
  popUpPromptText: string;
  methodOfPaymentTag: string;
  paymentAnswerTags: string;
  AnsweredQuestionNote: string;

  get items() {
    if (this.availableTypes) {
      const result = JSON.parse(this.availableTypes);
      return result;
    } else {
      return [];
    }
  }
}
