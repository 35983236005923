<div class="col-6 p-3">
  Third party payor details
  <button class="btn btn-sm btn-primary pull-right" (click)="openModal = true">
    Show 3rd party information
  </button>
</div>

<div class="col-6">
  <table class="table table-striped mb-4 border">
    <tbody>
      <tr>
        <td>Person/BusinessName</td>

        <td *ngIf="application?.payment?.businessNameMonthlyOffline">
          {{ application.payment.businessNameMonthlyOffline }}
        </td>

        <td *ngIf="application?.payment?.businessNameInitialOffline">
          {{ application.payment.businessNameInitialOffline }}
        </td>
      </tr>

      <tr>
        <td>Business Owner/Manager</td>

        <td *ngIf="application?.payment?.businessOwnerMonthlyOffline">
          {{ application.payment.businessOwnerMonthlyOffline }}
        </td>

        <td *ngIf="application?.payment?.businessOwnerInitialOffline">
          {{ application.payment.businessOwnerInitialOffline }}
        </td>
      </tr>
      <tr>
        <td>Street Address</td>

        <td
          *ngIf="
            this.payorOfflineMonthly?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.methodOfPayment === 'DIRECT BILLING' &&
            application?.payment?.billingAddress
          "
        >
          {{ application.payment.billingAddress }}
        </td>
        <td
          *ngIf="
            this.payorOfflineMonthly?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.methodOfPayment === 'BANK DRAFT' &&
            application?.payment?.addressOnCheck
          "
        >
          {{ application.payment.addressOnCheck }}
        </td>
        <td
          *ngIf="
            this.payorOfflineInitial?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.initialPaymentBy === 'BANK DRAFT' &&
            application?.payment?.initPmtBankAddressOnCheck
          "
        >
          {{ application.payment.initPmtBankAddressOnCheck }}
        </td>
        <td
          *ngIf="
            this.payorOfflineInitial?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.initialPaymentBy === 'CREDIT CARD' &&
            application?.payment?.initPmtBillingAddress
          "
        >
          {{ application.payment.initPmtBillingAddress }}
        </td>
      </tr>
      <tr>
        <td>City</td>

        <td
          *ngIf="
            this.payorOfflineMonthly?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.methodOfPayment === 'DIRECT BILLING' &&
            application?.payment?.billingCity
          "
        >
          {{ application.payment.billingCity }}
        </td>
        <td
          *ngIf="
            this.payorOfflineMonthly?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.methodOfPayment === 'BANK DRAFT' &&
            application?.payment?.cityOnCheck
          "
        >
          {{ application.payment.cityOnCheck }}
        </td>
        <td
          *ngIf="
            this.payorOfflineInitial?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.initialPaymentBy === 'BANK DRAFT' &&
            application?.payment?.initPmtBankCityOnCheck
          "
        >
          {{ application.payment.initPmtBankCityOnCheck }}
        </td>
        <td
          *ngIf="
            this.payorOfflineInitial?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.initialPaymentBy === 'CREDIT CARD' &&
            application?.payment?.initPmtBillingCity
          "
        >
          {{ application.payment.initPmtBillingCity }}
        </td>
      </tr>
      <tr>
        <td>State</td>

        <td
          *ngIf="
            this.payorOfflineMonthly?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.methodOfPayment === 'DIRECT BILLING' &&
            application?.payment?.billingState
          "
        >
          {{ application.payment.billingState }}
        </td>
        <td
          *ngIf="
            this.payorOfflineMonthly?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.methodOfPayment === 'BANK DRAFT' &&
            application?.payment?.stateOnCheck
          "
        >
          {{ application.payment.stateOnCheck }}
        </td>
        <td
          *ngIf="
            this.payorOfflineInitial?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.initialPaymentBy === 'BANK DRAFT' &&
            application?.payment?.initPmtBankStateOnCheck
          "
        >
          {{ application.payment.initPmtBankStateOnCheck }}
        </td>
        <td
          *ngIf="
            this.payorOfflineInitial?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.initialPaymentBy === 'CREDIT CARD' &&
            application?.payment?.initPmtBillingState
          "
        >
          {{ application.payment.initPmtBillingState }}
        </td>
      </tr>
      <tr>
        <td>Zip</td>

        <td
          *ngIf="
            this.payorOfflineMonthly?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.methodOfPayment === 'DIRECT BILLING' &&
            application?.payment?.billingZip
          "
        >
          {{ application.payment.billingZip }}
        </td>
        <td
          *ngIf="
            this.payorOfflineMonthly?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.methodOfPayment === 'BANK DRAFT' &&
            application?.payment?.zipOnCheck
          "
        >
          {{ application.payment.zipOnCheck }}
        </td>
        <td
          *ngIf="
            this.payorOfflineInitial?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.initialPaymentBy === 'CREDIT CARD' &&
            application?.payment?.initPmtBillingZip
          "
        >
          {{ application.payment.initPmtBillingZip }}
        </td>
        <td
          *ngIf="
            this.payorOfflineInitial?.value ===
              'PayorIsSomeoneElseAndOffline' &&
            application?.payment?.initialPaymentBy === 'BANK DRAFT' &&
            application?.payment?.initPmtBankZipOnCheck
          "
        >
          {{ application.payment.initPmtBankZipOnCheck }}
        </td>
      </tr>
      <tr>
        <td>Notes</td>

        <td *ngIf="application?.payment?.notesMonthlyOffline">
          {{ application.payment.notesMonthlyOffline }}
        </td>
        <td *ngIf="application?.payment?.notesInitialOffline">
          {{ application.payment.notesInitialOffline }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div
  *ngIf="question?.instructions"
  class="col-sm-12 alert alert-warning text-center mt-3"
  role="alert"
>
  {{ question.instructions }}
</div>

<div class="col-sm-11 mr-5 mt-4 pb-3">
  <label class="float-right ml-3 hover-success" for="callAnswered">
    <div
      [ngClass]="{ 'btn-success': callAnswered.checked }"
      class="btn border border-success btn-check-next carousel-next"
      (change)="optionChanged(true)"
    >
      <input
        class="form-check-input"
        [style.visibility]="'hidden'"
        type="radio"
        name="verification"
        #callAnswered
        value="true"
        id="callAnswered"
      />Call Answered
    </div>
  </label>
  <label class="float-right hover-danger" for="noResponse">
    <div
      [ngClass]="{ 'btn-danger': noResponse.checked }"
      class="btn border border-danger btn-check-next carousel-next"
      (change)="optionChanged(false)"
    >
      <input
        class="form-check-input"
        [style.visibility]="'hidden'"
        type="radio"
        name="verification"
        #noResponse
        value="false"
        id="noResponse"
      />No Response
    </div>
  </label>
</div>

<!-- for third party user information -->
<app-modal
  [open]="openModal"
  [modalOptions]="{ size: 'lg' }"
  [footer]="false"
  dismiss
  [buttonsConfig]="{
    showModalCrossIcon: false,
    modalMode: 'msg'
  }"
  (actions)="getNotifyEvent()"
>
  <div title class="text-center ml-5">
    <h4>Third party payor verification required</h4>
  </div>
  <div body class="mt-1">
    <h4 class="text-center mb-4 mt-0">
      Could you please dial {{ application.payment.billingOfflinePhone }}
    </h4>
    <h5 class="mb-3">3rd party payor details</h5>
    <div class="col-12 row mb-4">
      <div class="col-4">
        <h6 class="f-w-600 text-capitalize">Person/Business Name</h6>
      </div>
      <div class="col-8 p-0">
        <span class="font-weight-bold">: {{ businessName || "N/A" }}</span>
      </div>
      <div class="col-4">
        <h6 class="f-w-600 text-capitalize">Business Owner/Manager</h6>
      </div>
      <div class="col-8 p-0">
        <span class="font-weight-bold">: {{ businessOwner || "N/A" }}</span>
      </div>
    </div>
    <h5 class="mb-3">Introduction message</h5>
    <div class="col-12">{{ thirdPartyIntroMessage }}</div>

    <div
      *ngIf="question?.instructions"
      class="col-sm-12 alert alert-warning text-center mt-3"
      role="alert"
    >
      {{ question.details.instructions }}
    </div>

    <h5 class="mb-3">Voice Mail Script</h5>
    <div [innerHTML]="thirdPartyVoicemailMessage" class="col-12"></div>

    <div class="mt-4 text-center mb-4 f-18 block-conent-center">
      <button
        (click)="closeModal()"
        class="btn btn-md btn-outline-secondary pl-4 pr-4"
      >
        Close
      </button>
    </div>
  </div>
</app-modal>
<!-- for third party user information -->
