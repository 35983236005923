import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Query,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Application } from 'src/app/shared/models/application/application';
import { DoctorLookup } from 'src/app/shared/models/drLookup';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { DoctorlookupDetailsAnswer } from 'src/app/vcall/questions/applicant-qualified-doctor-lookup/applicant-qualified-doctor-lookup.component';
import { ApplicationStatus, ApplicationTrackerType } from '../../models/enum';
import { ApplicantType } from '../add-on/addon-application';
import {
  ApplicationTracker,
  OrderRequestDetails,
  RouteToAPS,
} from './additional-info-modal';
import { AdditionalInfoService } from './additional-info.service';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent implements OnInit, OnDestroy {
  openCreateOrderModal = false;
  openDeleteModal = false;
  completedOrdersList: OrderRequestDetails[] = [];
  application: Application;
  orderRequestList: OrderRequestDetails[] = [];
  subscriptions = new Array<Subscription>();
  profile: UserProfile;
  canCreateOrder = false;
  canDisplayProvider = false;
  initialList = new DoctorlookupDetailsAnswer();
  details = {};
  updatedDoctorsList: DoctorLookup[] = [];
  resetProviderRequest = new BehaviorSubject(null);
  resetBasicForm = new BehaviorSubject(null);
  applicationStatus: string;
  applicationTracker: ApplicationTracker;
  applicantsList: Applicant[];
  applicationRequests: any;
  selectedProvider: string = 'searchForm';
  activeTab = 'search';

  constructor(
    private toaster: ToastService,
    private service: AdditionalInfoService,
    private appService: ApplicationService,
    private profileService: UserProfileService
  ) {
    this.initialList.isProviderAvailable = true;
  }

  ngOnInit() {
    this.service.openOrderRequest.subscribe(
      (res) => (this.openCreateOrderModal = res)
    );

    this.subscriptions.push(
      this.appService.SelectedApplication.subscribe((res) => {
        if (res && res.id !== 0) {
          this.application = res;
          this.applicantsList = [res.primaryApplicant, ...res.family].filter(
            (a) => a['applicantType'] === ApplicantType.Regular
          );
          this.service.fetchOrderRequests(this.application.id);
          this.service.fetchNotes(this.application.id);
        }
      })
    );

    this.subscriptions.push(
      this.profileService.userProfile.subscribe((s) => {
        this.profile = s;
      })
    );

    this.subscriptions.push(
      this.service.orderRequests.subscribe((response) => {
        if (
          response !== null &&
          response['data'] &&
          response['data'].application
        ) {
          this.applicationRequests = response['data'].application;
          this.orderRequestList = response['data'].application.orderDetails;
          this.applicationTracker = response[
            'data'
          ].application.applicationTracker.find(
            (a) => a.applicationTrackerType === ApplicationTrackerType.Primary
          );
          this.canCreateOrder = this.service.isApplicationStatusValid(
            this.applicationTracker
          );
        }
      })
    );
  }

  public get ApplicationStatus(): typeof ApplicationStatus {
    return ApplicationStatus;
  }

  createRequest() {
    this.openCreateOrderModal = true;
  }

  selectOption(option: string) {
    this.selectedProvider = option;
  }

  addprovider() {
    this.canDisplayProvider = true;
    this.resetProviderRequest.next({ list: [], isProvider: true });
  }

  getUpdatedDrList(doc) {
    this.updatedDoctorsList = doc.list;
    if (!this.updatedDoctorsList.length) {
      this.resetProviderRequest.next(true);
    }
  }

  getCurrentTab(tab) {
    this.activeTab = tab;
  }

  confirm(event) {
    if (event === 'Done') {
      this.appService.PersistAnswers();
    }
    this.canDisplayProvider = false;
    this.resetBasicForm.next(true);
    this.selectedProvider = 'searchForm';
    this.activeTab = 'search';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
