export enum RecallInfo {
  applicant = 'Applicant',
  payorIsSomeoneElseAndOffline = 'PayorIsSomeoneElseAndOffline',
  payorIsSomeoneElseAndOnline = 'PayorIsSomeoneElseAndOnline',
  applicationPayorMonthly = 'Payment.ApplicationPayorMonthly',
  applicationPayorInitial = 'Payment.ApplicationPayorInitial',
  scheduled = 'Scheduled',
  done = 'Done',
  value = 'value',
  completed = 'Completed',
  appointmentId = 'appointmentId',
  callType = 'Recall',
}
