import { Injectable } from '@angular/core';
import { ApplicationService } from '../application/application.service';
import { Router } from '@angular/router';
import { UserProfileService } from '../user/user-profile.service.service';
import { Application } from '../../models/application/application';
import { Observable } from 'rxjs';

export interface LogDetails {
  notes?: string;
  reason?: string;
  name?: string;
  date?: string;
  id?: number;
  state?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LogStatusService {
  logDetails: LogDetails = {};
  userName: string;
  constructor(
    private applicationService: ApplicationService,
    private router: Router,
    private profileService: UserProfileService
  ) {
    this.profileService.userProfile.subscribe((userProfile) => {
      this.userName = userProfile?.name;
    });
  }

  /**
   * @param reason log reason
   * @param notes notes
   * @param state log state
   * @param application application
   * @description log the status of the application
   */
  public logStatus(
    reason: string,
    notes: string,
    state: string,
    application: Application
  ) {
    this.logDetails.id = application.id;
    this.logDetails.name = this.userName;
    this.logDetails.date =
      new Date().toLocaleDateString() +
      ' ' +
      new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      });
    this.logDetails.reason = reason;
    this.logDetails.notes = notes;
    this.logDetails.state = state;

    return this.applicationService.InsertCallLog(this.logDetails, true);
  }
}
