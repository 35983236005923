import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { tap } from 'rxjs/internal/operators/tap';
import { UserProfileService } from '../../shared/services/user/user-profile.service.service';
import { OnlineApplicantsComponent } from '../../shared/components/online-applicants/online-applicants.component';
import { ApplicationService } from '../../shared/services/application/application.service';
import {
  AppointmentService,
  StartVerificationParameters,
} from '../../shared/services/appointment/appointment.service';
import {
  Appointment,
  AppointmentStatus,
} from '../../shared/models/appointment';
import { DialingInformationModel } from '../../shared/models/application/dialingInformation';
import { DatePipe } from '@angular/common';
import { VoiceMailModel } from 'src/app/shared/models/vcall/voiceMailModel';
import { first } from 'rxjs/operators';
import { CallStatus } from 'src/app/shared/models/vcall/dialingType';
import { Application } from 'src/app/shared/models/application/application';
import { ImpersonateParams } from 'src/app/shared/models/vcall/vcallParams';
import { environment } from 'src/environments/environment';
import { EndCallModalComponent } from 'src/app/shared/components/end-call-modal/end-call-modal.component';
import { UserProfile } from 'src/app/shared/models/user-profile';
import {
  voiceMailMessageFollowup,
  voiceMailMessage,
  voiceMailRecallMessage,
  voiceMailRecallMessageFollowup,
} from './voice-mail-modal';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { CallZoneApiService } from 'src/app/shared/services/api/callzone/callzone-api.service';
import { LoggerService } from 'src/app/core/logger.service';
import { Recall } from 'src/app/shared/models/recall/Recall';
import { RescheduleAppointmentComponent } from '../reschedule-appointment/reschedule-appointment.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationStringsService } from 'src/app/shared/services/applicationStrings/application-strings.service';
import { RecallType } from 'src/app/shared/models/recall/RecallType';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-voice-mail-modal',
  templateUrl: './voice-mail-modal.component.html',
  styleUrls: ['./voice-mail-modal.component.scss'],
  providers: [DatePipe],
})
export class VoiceMailModalComponent implements OnInit, OnDestroy {
  @ViewChild(EndCallModalComponent) stopCall: EndCallModalComponent;
  application: Application;
  appointment: Appointment;
  @ViewChild(OnlineApplicantsComponent)
  onlineApplicantsModal: OnlineApplicantsComponent;
  @ViewChild(RescheduleAppointmentComponent)
  rescheduleAppointmentModal: RescheduleAppointmentComponent;
  dialingInformationModel: DialingInformationModel =
    new DialingInformationModel();
  selectedAppointment: Appointment;
  callStatus: CallStatus;
  callAnswered: boolean;
  callAttendeeNm: string;
  rescheduleAppointmentUrl: string;
  subscriptions = new Array<Subscription>();
  countDown;
  count: number;
  submitInfo = false;
  comments: string;
  public openModal: boolean;
  businessName: string;
  businessOwner: string;
  businessPhone: string;
  dialingInformation;
  contactType: string;
  vm: VoiceMailModel;
  currentDttm: Date = new Date();
  timer$: Subscription;
  resourceId: string = null;
  fromDt: string = null;
  toDt: string = null;
  appId: string;
  stopCallId: number;
  profile: UserProfile;
  voiceMailMsg: string;
  voiceMailMsgFollowup: string;
  processed = false;
  agentName: string;
  openModalNoAppointment = false;
  timeZone: string;
  selectedRecall: Recall;
  voicemailResponseFirst: VoiceMailResponse = new VoiceMailResponse();
  voicemailResponseSecond: VoiceMailResponse = new VoiceMailResponse();
  callResponseType = [];
  callDetails: Appointment;
  callResponseTypeList = [
    {
      key: 'Answered',
      label: 'Answered',
      isValid: (appointment) => {
        return true;
      },
    },
    {
      key: 'WrongNumber',
      label: 'Wrong Number',
      isValid: (appointment) => {
        return true;
      },
    },
    {
      key: 'NoResponse',
      label: 'No Response',
      isValid: (appointment) => {
        return true;
      },
    },
  ];
  constructor(
    private appointmentService: AppointmentService,
    private applicationService: ApplicationService,
    private profileService: UserProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private uiService: UiServiceService,
    private callZoneApi: CallZoneApiService,
    private ChangeDetectorref: ChangeDetectorRef,
    private Logger: LoggerService,
    private modalService: NgbModal,
    private applicationStringsService: ApplicationStringsService,
    private scriptService: ScriptService
  ) {
    this.stopCallId =
      environment.config.callZoneStatusConfiguration.voicemailModelNoShow.stopcallId;
    this.agentName = this.profileService.getCallzoneUser().name;
  }

  ngOnInit() {
    // this.subscriptions.push(
    //   this.applicationService.SelectedApplication.subscribe((a) => {
    //     if (a) {
    //       this.application = a;
    //       // console.log('voicemail', a);
    //       this.process();
    //     }
    //   })
    // );
    // this.subscriptions.push(
    //   this.appointmentService.SelectedAppointment.subscribe((a) => {
    //     this.selectedAppointment = a;
    //     // console.log('voicemail', this.selectedAppointment);
    //     this.process();
    //   })
    // );
    this.subscriptions.push(
      this.profileService.userProfile.subscribe((res) => (this.profile = res))
    );
    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe(
        (a) => (this.callDetails = a)
      )
    );
    // if (this.selectedAppointment) {
    //   // console.log('voicemail if', this.selectedAppointment);
    //   this.dialingInformation = this.dialingInformationModel.getDialingInformation(
    //     this.selectedAppointment.callType,
    //     this.application,
    //     this.contactType
    //   );
    // }

    // this.canLoadTimer();
    this.subscriptions.push(
      this.appointmentService.VerificationStarted.subscribe(
        async (params: StartVerificationParameters) => {
          if (
            params === null ||
            params.Application === null ||
            params.Application.id === 0 ||
            params.Appointment === null ||
            params.Appointment.appointmentId === undefined
          ) {
            return;
          }

          if (params.Appointment.appId !== params.Application.id) {
            this.Logger.logException(
              new Error('Incorrect Application & Appointment combination')
            );
          }

          let callSuccessfullyStarted: boolean;
          const verificationStarted = !params.StopProcessing;
          this.application = params.Application;
          this.selectedAppointment = params.Appointment;
          this.callResponseType = this.callResponseTypeList.filter((c) =>
            c.isValid(this.selectedAppointment)
          );
          this.callStatus = undefined;

          console.log(
            'Verification Started for ',
            params.Application.id,
            params.Appointment.appointmentId,
            ' Stop Processing ',
            params.StopProcessing
          );
          if (verificationStarted) {
            callSuccessfullyStarted = await this.appointmentService.StartCall(
              this.application,
              this.selectedAppointment,
              this.profile
            );

            if (callSuccessfullyStarted) {
              this.openModal = true;
              this.processed = false;
              this.resetForm();
              // TODO : Move this to the recall - API
              this.applicationService.ApplicationRecalls.subscribe(
                (recalls) => {
                  if (recalls) {
                    const recall = recalls.filter(
                      (r) =>
                        r.appointmentId ===
                        this.selectedAppointment.appointmentId
                    );
                    if (recall && recall.length > 0) {
                      this.selectedRecall = recall[0];
                      this.contactType = recall[0].contactType;
                      this.dialingInformation =
                        this.dialingInformationModel.getDialingInformation(
                          this.selectedAppointment.callType,
                          this.application,
                          this.contactType
                        );
                      if (this.contactType !== RecallType.applicant) {
                        this.voiceMailMsg = this.uiService.interpolate(
                          voiceMailRecallMessage,
                          this
                        );
                        this.voiceMailMsgFollowup = this.uiService.interpolate(
                          voiceMailRecallMessageFollowup,
                          this
                        );
                      }
                    }
                  }
                  this.process();
                  this.canLoadTimer();
                }
              );
            } else if (!callSuccessfullyStarted) {
              this.openModalNoAppointment = true;
              this.ChangeDetectorref.detectChanges();
              this.appointmentService.Clear();
            }
          }
        }
      )
    );

    this.route.queryParamMap.subscribe((p) => {
      const impersonateParams = new ImpersonateParams(p);
      if (impersonateParams.start) {
        this.fromDt = impersonateParams.start;
      }
      if (impersonateParams.end) {
        this.toDt = impersonateParams.end;
      }
      if (impersonateParams.resource) {
        this.resourceId = impersonateParams.resource;
      }
      if (impersonateParams.appid) {
        this.appId = impersonateParams.appid;
      }
    });
    this.getProfileDetails();
  }

  process() {
    if (
      this.selectedAppointment &&
      this.application &&
      this.selectedAppointment.appId === this.application.id &&
      !this.processed
    ) {
      this.processed = true;
      this.vm = new VoiceMailModel(
        this.applicationService,
        this.selectedAppointment,
        this.appointmentService
      );

      this.dialingInformation =
        this.dialingInformationModel.getDialingInformation(
          this.selectedAppointment.callType,
          this.application,
          this.contactType
        );
    }
    let voicemailMessageRef;
    let voiceMailMessageFollowupRef;
    if (
      this.contactType === RecallType.applicationPayorInitial ||
      this.contactType === RecallType.applicationPayorMonthly
    ) {
      voiceMailMessageFollowupRef = voicemailMessageRef =
        this.applicationStringsService.getStringByKey(
          'Voicemail.ThirdpartyRecall',
          'Voicemail'
        );
    } else if (this.callDetails.callType === 'Recall') {
      voiceMailMessageFollowupRef = voicemailMessageRef =
        this.applicationStringsService.getStringByKey(
          'Voicemail.' + this.application.company,
          'Voicemail'
        );
    } else {
      voicemailMessageRef = voiceMailMessage;
      voiceMailMessageFollowupRef = voiceMailMessageFollowup;
    }
    this.voiceMailMsg = this.uiService.interpolate(voicemailMessageRef, this);
    this.voiceMailMsgFollowup = this.uiService.interpolate(
      voiceMailMessageFollowupRef,
      this
    );
  }

  // TODO: dialingstate should dictate the timer.
  canLoadTimer() {
    const savedAnswer = this.vm && this.vm.getSavedAnswer();
    if (savedAnswer && savedAnswer.timeDiff < 180) {
      this.count = 180 - savedAnswer.timeDiff;
      this.myTimer();
    }
  }
  /**
   * @description used to know call response type
   */
  setCallResponse(callStatus) {
    this.callStatus = callStatus;
    this.vm.setCallStatus(callStatus);
  }

  endCall() {
    this.getNotifyEvent();
  }
  /**
   * @description used to close popup
   * @param event is used to know popup status is it close or not
   */
  getNotifyEvent() {
    this.openModal = false;
    this.openModalNoAppointment = false;
  }

  proceedCallAnswered() {
    this.scriptService.SetScriptVersion();
    if (this.selectedAppointment.appId !== this.application.id) {
      this.Logger.logException(
        new Error('Incorrect Application & Appointment combination')
      );
    }
    if (this.callStatus === CallStatus.NoResponse) {
      this.vm.VoiceMailProcess(this.vm.dialingState);
    } else if (this.callStatus === CallStatus.WrongNumber) {
      this.openModal = false;
      this.stopCall.openReasonModal({
        stopCallId: 7,
        stopCallLabel: 'Wrong Number',
      });
    } else {
      this.vm.process();

      this.openModal = false;
      let userProfile;
      userProfile = this.profile;
      this.selectedAppointment.callzoneVerifier = userProfile.name;
      this.applicationService.AddAppointment(this.selectedAppointment);
      // console.log('voicemail-proceed call answer', this.selectedAppointment);
      this.onlineApplicantsModal.showModal(
        () => {
          this.appointmentService.Oncall(
            this.application,
            this.selectedAppointment,
            this.profile
          );
          // TODO : Need to add comments to why we are doing this.
          this.appointmentService.VerificationStarted.next(null);
          {
            this.router.navigate([
              `/vcall/application/${this.application.id}/appointment/${this.selectedAppointment.appointmentId}`,
              { state: ['forward'] },
            ]);
          }
        },
        this.selectedAppointment,
        this.application,
        this.selectedRecall
      );
    }
  }

  closeVoiceMail() {
    console.log(this.voicemailResponseFirst);
    this.submitNoShow(this.voicemailResponseFirst).then(() => {
      this.logNoShow(this.voicemailResponseFirst, 'First attempt').then(() => {
        // counter timer handling in the recall
        this.vm.CancelVoiceMailDialog();
        if (this.selectedAppointment.callType === 'Recall') {
          this.openModal = false;
          this.openModalNoAppointment = false;
          this.appointmentService.refreshAppointments(true);
        } else {
          this.count = 180;
          this.myTimer();
        }
      });
    });
  }

  goBack() {
    this.vm.BackToSecondVociemailScreen();
  }

  resetForm() {
    this.callStatus = null;
    if (this.voicemailResponseFirst) {
      this.voicemailResponseFirst.leftVoiceMail = null;
      this.voicemailResponseFirst.comments = null;
    }
    if (this.voicemailResponseSecond) {
      this.voicemailResponseSecond.leftVoiceMail = null;
      this.voicemailResponseSecond.comments = null;
    }
  }

  skipTimer() {
    this.count = 5;
  }

  myTimer() {
    if (this.timer$ !== undefined) {
      this.timer$.unsubscribe();
    }
    this.timer$ = timer(1000, 1000) // Initial delay half second and interval countdown 1 second
      .pipe(
        takeWhile(() => this.count > 0),
        tap(() => this.count--)
      )
      .subscribe(() => {
        if (this.count === 0) {
          this.vm.TimerElapsed();
        }
      });
  }

  finalSubmit() {
    this.submiEndCall(this.voicemailResponseSecond);
    this.getNotifyEvent();
  }

  submitNoShow(voicemailMessage: VoiceMailResponse) {
    const appointmentStatus = {
      statusId: AppointmentStatus.NoShow,
      stopCallId: this.stopCallId,
      appointmentId: this.selectedAppointment.appointmentId,
      resourceId: this.selectedAppointment.resourceId
        ? this.selectedAppointment.resourceId
        : 0,
      notes: voicemailMessage.comments,
      leftVoiceMailMessage: voicemailMessage.leftVoiceMail,
      appId: this.selectedAppointment.appId,
      state: this.application.state,
      name: this.profile['name'],
      date:
        new Date().toLocaleDateString() +
        ' ' +
        new Date().toLocaleTimeString('en-US', {
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
        }),
      smsMessage: this.applicationStringsService.getStringByKey(
        'SMSTemplate.NoResponse'
      ),
      type: 'NO_RESPONSE',
      homePhone: this.application.contact.homePhone,
      workPhone: this.application.contact.workPhone,
      mobilePhone: this.application.contact.mobilePhone,
    };

    return this.callZoneApi.noShowCall(appointmentStatus).toPromise();
  }

  logNoShow(voicemailMessage: VoiceMailResponse, r: string) {
    const log = {
      notes: voicemailMessage.comments,
      reason: r,
      name: this.profile['name'],
      date: this.uiService.formatCurrentDate(),
      id: this.application.id,
      state: this.application.state,
      utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
    };

    return this.applicationService.InsertCallLog(log, true).then((list) => {
      this.applicationService.GetCallLog(this.application.id.toString());
    });
  }

  submiEndCall(message: VoiceMailResponse) {
    let smsParameters = null;
    if (this.selectedAppointment.callType !== 'Recall') {
      smsParameters = {
        applicationId: this.selectedAppointment.appId,
        messageId: UUID.UUID(),
        message:
          this.applicationStringsService.getStringByKey('Voicemail.NoShow'),
        appointmentId: this.selectedAppointment.appointmentId,
        applicantPhoneNumber: this.application.contact.mobilePhone,
        controlNumber: this.application.policyInformation[0].controlNumber,
        featureType: 'NoShow',
        agentNumber: this.application['agent'].agentNumbers,
      };
    }
    const appointmentStatus = {
      statusId: AppointmentStatus.NoShow,
      stopCallId: this.stopCallId,
      appointmentId: this.selectedAppointment.appointmentId,
      resourceId: this.selectedAppointment.resourceId
        ? this.selectedAppointment.resourceId
        : 0,
      notes: message.comments,
      leftVoiceMailMessage: message.leftVoiceMail,
      appId: this.selectedAppointment.appId,
      state: this.application.state,
      name: this.profile['name'],
      date:
        new Date().toLocaleDateString() +
        ' ' +
        new Date().toLocaleTimeString('en-US', {
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
        }),

      type: 'NO_SHOW',
      homePhone: this.application.contact.homePhone,
      workPhone: this.application.contact.workPhone,
      mobilePhone: this.application.contact.mobilePhone,
      smsParameters: smsParameters,
    };

    const log = {
      notes: message.comments,
      reason: 'No Show',
      name: this.profile['name'],
      date: this.uiService.formatCurrentDate(),
      utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
      id: this.application.id,
      state: this.application.state,
    };
    // step1: update call zone with end call reason
    this.callZoneApi
      .noShowCall(appointmentStatus)
      .pipe(first())
      .subscribe((data) => {
        if (
          (data === null && message.comments !== null) ||
          (data && data.status === 200)
        ) {
          this.submitInfo = false;
          this.openModal = false;
          this.comments = null;
          this.vm.SubmitButtonClickedInSecondVoicemailScreen();
          // step 2: add call log calling the application api
          this.applicationService.InsertCallLog(log, true).then(
            (list) => {
              this.applicationService.GetCallLog(
                this.application.id.toString()
              );

              this.applicationService.Clear();
              this.appointmentService.Clear();

              this.router.navigate(['/'], {
                queryParams: { refresh: new Date().getTime() },
                queryParamsHandling: 'merge',
              });
            },
            (err: any) => {
              // console.log(err);
            }
          );
        }
      });
    // });
  }

  // close the modal
  cancelModal() {
    this.openModal = false;
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
  }

  getProfileDetails() {
    this.timeZone = this.profileService.getCallzoneUser()?.timeZone;
  }
}

export class VoiceMailResponse {
  leftVoiceMail: boolean;
  comments: string;
}
