import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { VerifiableModel } from 'src/app/vcall/questions/types/verifiableList/verifiable-list.component';
import { validate } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class GenericcontrolService {
  constructor() {}
  toFormGroup(model: VerifiableModel<any>[]) {
    const group: any = {};

    model.forEach((item, index) => {
      (group[item.propertyName] = item.required
        ? new UntypedFormControl(item.value || '', Validators.required)
        : new UntypedFormControl(item.value || '')),
        (group[item.propertyName] =
          item.pattern !== null && item.pattern !== ''
            ? new UntypedFormControl(
                item.value || '',
                Validators.pattern(item.pattern)
              )
            : new UntypedFormControl(item.value || '')),
        (group[item.propertyName] =
          item.maxLength !== null
            ? new UntypedFormControl(
                item.value || '',
                Validators.maxLength(item.maxLength)
              )
            : new UntypedFormControl(item.value || '')),
        (group[item.propertyName] =
          item.minLength !== null
            ? new UntypedFormControl(
                item.value || '',
                Validators.minLength(item.minLength)
              )
            : new UntypedFormControl(item.value || '')),
        (group[index] = new UntypedFormControl(
          item.verification || '',
          Validators.required
        ));
    });
    return new UntypedFormGroup(group);
  }
}
