import { Client } from './client';
import { PolicyItem } from './policyItem';
import { environment } from 'src/environments/environment';

export class Appointment {
  constructor(options) {
    Object.assign(this, options);
  }
  client: Client;
  dateTime: string;
  policy: PolicyItem[];
  product: string;
  appId: number;
  resourceId: number;
  scheduledDate: Date;
  firstName: string;
  lastName: string;
  username: string;
  callType: string;
  appointmentId: number;
  statusId: string;
  statusName: string;
  callzoneVerifier: string;
  completedDate?: Date;
  static DefaultInstance() {
    return new Appointment({ callType: '' });
  }
}

export class ScheduledAppointment {
  constructor(options) {
    Object.assign(this, options);
  }
  applicationId: number;
  agentNumber: string;
  appointmentId: number;
  applicantPhoneNumber: string;
  controlNumber: string;
  scheduledDate: Date;
  timeZone: string;
  scheduledDateUTC: Date;
  timeZoneShort: string;
}

export class AppointmentSearchCriteria {
  constructor(options) {
    Object.assign(this, options);
  }
  startDate: string;
  endDate: string;
  resourceId: number;
  canAccessFakeAppointment: string;
}

export class CallTypeDuration {
  constructor(options) {
    Object.assign(this, options);
  }
  callType: string;
  duration: number;
  durationType: string;
}

export enum AppointmentStatus {
  StopCall = environment.config.callZoneStatusConfiguration.stopCall.statusId,
  NoShow = environment.config.callZoneStatusConfiguration.voicemailModelNoShow
    .statusId,
}
