import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandledHttpClient } from '../api/handledHttpClient';

@Injectable({
  providedIn: 'root',
})
export class SecureEmailService {
  secureEmail: string;

  constructor(private http: HandledHttpClient) {
    this.secureEmail = environment.config.apis.secureEmail;
  }

  /**
   * @param applicationId , Policy Number.
   * @description send the secure email for PCI
   */
  public SendSecureEmail(secureEmailModel: SecureEmailModel) {
    return this.http
      .post(
        this.secureEmail +
          '/' +
          secureEmailModel.policyNumber +
          '/' +
          secureEmailModel.applicationId,
        {
          applicantName: secureEmailModel.applicantName,
          EmailAddress: secureEmailModel.emailAddress,
        },
        {
          errorMessage: `Error Sending Secure email for '${secureEmailModel.applicationId}'`,
          successMessage: 'Secure Email Sent',
        }
      )
      .toPromise();
  }
}

export class SecureEmailModel {
  policyNumber: string;
  applicationId: number;
  applicantName: string;
  emailAddress: string;
}
