import { Injectable } from '@angular/core';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';
import { CopyAnswerTagProcessor } from './copyAnswerTagProcessor';

@Injectable({
  providedIn: 'root',
})
export class CopyPaymentPaymentByProcessor extends CopyAnswerTagProcessor
  implements Processor {
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'CopyPaymentBy';
  customAnswers = [
    {
      type: 'MonthlyPaymentMode',
      tag: 'payment.methodOfPayment',
      value: 'BANK DRAFT',
      prompt:
        'For your <b>Recurring Payment</b>, you have indicated you would like to pay by <b> BANK DRAFT</b>. Do you agree?',
    },
    {
      type: 'MonthlyPaymentMode',
      tag: 'payment.initialPaymentBy',
      value: 'BANK DRAFT',
      prompt:
        'For your <b>Initial Payment</b>, you have indicated you would like to pay by <b> BANK DRAFT</b>. Do you agree?',
    },
    {
      type: 'RadioButtonList',
      tag: 'Payment.ApplicationPayorMonthly',
      value: 'PayorIsApplicant',
      prompt:
        'For the Recurring Payment, who is the authorized account holder for the banking information on your application?',
    },
    {
      type: 'RadioButtonList',
      tag: 'Payment.ApplicationPayorInitial',
      value: 'PayorIsApplicant',
      prompt:
        'For the Initial Payment, who is the authorized account holder for the banking information on your application?',
    },
  ];

  Process(model: ProcessorModel): Promise<ProcessingResult> {
    this.customAnswers.every((answer) => {
      return this.createCustomRegisterAnswerByTag(
        answer.tag,
        answer.prompt,
        answer.value,
        answer.type
      );
    });
    return;
  }
}
