<div *ngIf="ezappTag">
  <ng-container [ngSwitch]="questionType">
    <div *ngSwitchCase="'MedicalQualifying'">
      <ng-container>
        <div *ngIf="allAapplicantsAns?.length > 0">
          <div
            *ngIf="
              currentQuestion.answerTag !== 'MedicalHistory.GreenCard' &&
                getApplicantCount(allAapplicantsAns, 'Y') > 0;
              else isGreenCardTitle
            "
          >
            <h6 class="mb-2">Ezapp Responses</h6>
          </div>
          <ng-template #isGreenCardTitle>
            <h6
              class="mb-2"
              *ngIf="
                currentQuestion.answerTag === 'MedicalHistory.GreenCard' &&
                getApplicantCount(allAapplicantsAns, 'N') > 0
              "
            >
              Ezapp Responses
            </h6>
          </ng-template>
        </div>
        <div *ngFor="let applicant of allAapplicantsAns" class="d-inline-flex">
          <div
            *ngIf="
              currentQuestion.answerTag !== 'MedicalHistory.GreenCard';
              else isGreenCard
            "
          >
            <span
              class="btn-light ezapp-btn mr-1"
              *ngIf="applicant.healthCondition === 'Y'"
            >
              {{ applicant.firstName | titlecase }}
              {{ applicant.lastName | titlecase }}
            </span>
          </div>
          <!-- For GreenCard question the answer N is the qualifying answer in EZApp -->
          <ng-template #isGreenCard>
            <span
              class="btn-light ezapp-btn mr-1"
              *ngIf="applicant.healthCondition === 'N'"
            >
              {{ applicant.firstName | titlecase }}
              {{ applicant.lastName | titlecase }}
            </span>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <div *ngSwitchCase="'MedicalAilment'">
      <div *ngIf="medicalApplicantAns?.length > 0">
        <h6 class="mb-2">
          EzApp Responses
        </h6>
        <div
          *ngFor="let applicant of medicalApplicantAns"
          class="d-inline-flex mr-1"
        >
          <span class="btn-light ezapp-btn">
            {{ applicant.Condition }}
          </span>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'MedicalPrescriptionAilment'">
      <div *ngIf="prescriptionDrillDownAnswers?.length > 0">
        <h6 class="mb-2">
          EzApp Responses
        </h6>
        <p *ngFor="let applicant of prescriptionDrillDownAnswers">
          <span class="btn-light ezapp-btn mr-1">
            <b>Doctor Name:</b> {{ applicant.Doctor }}
          </span>
          <span class="btn-light ezapp-btn mr-1">
            <b>Drug Name:</b> {{ applicant.DrugName }}
          </span>
          <span class="btn-light ezapp-btn mr-1">
            <b>Reason:</b> {{ applicant.Reason }}
          </span>
        </p>
      </div>
    </div>
  </ng-container>
</div>
