<div class="offset-1 col-sm-10 p-0">
  <div class="row box-shadow mt-3 m-0 mb-3">
    <div class="col-lg-12 mb-4 px-5">
      <form
        [formGroup]="searchForm"
        id="searchForm"
        (submit)="searchApplications()"
      >
        <h5 class="pt-3 pb-3">Search An Appointment</h5>
        <div class="row">
          <div class="col-2">
            <label for="applicationId" class="title">Application ID</label>
            <input
              type="text"
              name="applicationId"
              id="applicationId"
              formControlName="applicationId"
              class="form-control"
              autocomplete="off"
              maxlength="9"
            />
            <div
              *ngIf="
                (searchForm?.controls)['applicationId'].invalid &&
                ((searchForm?.controls)['applicationId'].dirty ||
                  (searchForm?.controls)['applicationId'].touched)
              "
              class="alert alert-danger mt-1"
            >
              <div *ngIf="(searchForm?.controls)['applicationId'].errors">
                optional but only it allows numerics
              </div>
            </div>
          </div>

          <div class="col-2">
            <label for="firstName" class="title">First Name</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              formControlName="firstName"
              class="form-control"
              autocomplete="off"
            />
          </div>

          <div class="col-2">
            <label for="lastName" class="title">Last Name</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              formControlName="lastName"
              class="form-control"
              autocomplete="off"
            />
          </div>
          <div class="col-4 mr-0">
            <div>
              <label for="state" class="title col-5">Scheduled From</label
              ><label class="title col-5 ml-2">Scheduled To</label>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <div class="input-group">
                  <input
                    name="datepicker"
                    hidden
                    class="form-control"
                    ngbDatepicker
                    #datepicker="ngbDatepicker"
                    [autoClose]="'outside'"
                    (dateSelect)="onDateSelection($event)"
                    formControlName="date"
                    [displayMonths]="2"
                    [dayTemplate]="t"
                    outsideDays="hidden"
                    [startDate]="fromDate!"
                    tabindex="-1"
                  />
                  <ng-template #t let-date let-focused="focused">
                    <span
                      class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null"
                    >
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="d-flex">
                <div class="form-group col-6">
                  <div class="input-group">
                    <input
                      #dpFromDate
                      class="form-control"
                      placeholder="mm/dd/yyyy"
                      name="dpFromDate"
                      [value]="formatter.format(fromDate)"
                      (input)="fromDate = validateInput(dpFromDate.value)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary fa fa-calendar"
                        (click)="datepicker.toggle()"
                        type="button"
                      ></button>
                    </div>
                  </div>
                </div>
                <div class="form-group col-5 ml-2">
                  <div class="input-group">
                    <input
                      #dpToDate
                      class="form-control"
                      placeholder="mm/dd/yyyy"
                      name="dpToDate"
                      [value]="formatter.format(toDate)"
                      (input)="toDate = validateInput(dpToDate.value)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary fa fa-calendar"
                        (click)="datepicker.toggle()"
                        type="button"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="search-col pt-3 ml-0">
            <label for="submit" class="title invisible">submit</label>
            <button
              class="btn btn-primary mt-3"
              [disabled]="searchForm.invalid"
              type="submit"
              form="searchForm"
              id="submit"
            >
              Search
            </button>
          </div> -->
        </div>
        <div class="row mt-3">
          <div class="col-2">
            <label for="policyNumber" class="title">Policy Number</label>
            <input
              type="text"
              name="policyNumber"
              id="policyNumber"
              formControlName="policyNumber"
              class="form-control"
              autocomplete="off"
            />
          </div>
          <div class="col-2">
            <label for="callType" class="title">Call Type</label>

            <select
              id="callType"
              formControlName="callType"
              class="form-control"
              name="callType"
              id="callType"
              autocomplete="off"
            >
              <option value="null"></option>
              <option *ngFor="let c of callTypes" [value]="c.id">{{
                c.label
              }}</option>
            </select>
          </div>
          <div class="col-2">
            <label for="statusType" class="title">Call Status</label>
            <select
              id="statusType"
              formControlName="statusType"
              class="form-control"
              name="statusType"
              id="statusType"
              autocomplete="off"
            >
              <option *ngFor="let s of statusTypes" [value]="s.id">{{
                s.label
              }}</option>
            </select>
          </div>
          <div class="col-4 text-right">
            <label for="submit" class="title invisible">submit</label>
            <button
              class="btn btn-primary mt-3 mr-2"
              [disabled]="searchForm.invalid"
              type="submit"
              form="searchForm"
              id="submit"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="call-info" *ngIf="isSearch === false; else tableRecords">
    Please search to see records...
  </div>
  <ng-template #tableRecords>
    <table class="table table-striped box-shadow mt-3">
      <thead class="table-header">
        <tr>
          <th
            (click)="sort('applicationId', $event)"
            [ngClass]="{
              active: sortColumn === 'applicationId',
              descending: sortColumn === 'applicationId' && sortOrder === false
            }"
          >
            Application Info
          </th>
          <th
            (click)="sort('FirstName', $event)"
            [ngClass]="{
              active: sortColumn === 'FirstName',
              descending: sortColumn === 'FirstName' && sortOrder === false
            }"
          >
            Name
          </th>
          <th
            (click)="sort('applicationCreatedDate', $event)"
            [ngClass]="{
              active: sortColumn === 'applicationCreatedDate',
              descending:
                sortColumn === 'applicationCreatedDate' && sortOrder === false
            }"
          >
            Created Date
          </th>
          <th class="remove-sorting-icon">
            Verifier
          </th>
          <th
            (click)="sort('ScheduledDateUTC', $event)"
            [ngClass]="{
              active: sortColumn === 'ScheduledDateUTC',
              descending:
                sortColumn === 'ScheduledDateUTC' && sortOrder === false
            }"
          >
            Scheduled Date
          </th>
          <th
            (click)="sort('CallTypeName', $event)"
            [ngClass]="{
              active: sortColumn === 'CallTypeName',
              descending: sortColumn === 'CallTypeName' && sortOrder === false
            }"
          >
            Call Type
          </th>
          <th
            (click)="sort('BeganOnUTC', $event)"
            [ngClass]="{
              active: sortColumn === 'BeganOnUTC',
              descending: sortColumn === 'BeganOnUTC' && sortOrder === false
            }"
          >
            Start Time
          </th>
          <th
            (click)="sort('CallFinalUTC', $event)"
            [ngClass]="{
              active: sortColumn === 'CallFinalUTC',
              descending: sortColumn === 'CallFinalUTC' && sortOrder === false
            }"
          >
            Completed Date
          </th>
          <th
            (click)="sort('statusName', $event)"
            [ngClass]="{
              active: sortColumn === 'statusName',
              descending: sortColumn === 'statusName' && sortOrder === false
            }"
          >
            Status
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="appointmentList?.length > 0">
          <tr *ngFor="let item of appointmentList; index as i">
            <td>
              <div>{{ item.applicationId }}</div>
              <div class="f-12 font-weight-bold" *ngIf="item.policyNumber">
                ({{ item.policyNumber }})
              </div>
            </td>
            <td>
              <div>
                {{ item.firstName | uppercase }} {{ item.lastName | uppercase }}
              </div>
              <div class="f-12 font-weight-bold">{{ item.phoneNumber }}</div>
            </td>
            <td>{{ item.applicationCreatedDate | date: "MM/dd/yyyy" }}</td>
            <td>{{ item.username || "N/A" }}</td>
            <td>
              <div>
                {{ item.scheduledDate | datewithTimeZone: timeZone:"date" }}
              </div>
              <div class="f-12 font-weight-bold">
                {{ item.scheduledDate | datewithTimeZone: timeZone:"time" }}
              </div>
            </td>
            <td>{{ item.callType || "N/A" }}</td>

            <td>
              <ng-container
                *ngIf="
                  item.beganOnUtc === '00:00:00' ||
                    item.beganOnUtc === null ||
                    item.beganOnUtc.includes('1/1/1900');
                  else startTime
                "
                >{{ "N/A" }}</ng-container
              >
              <ng-template #startTime>{{
                item.beganOnUtc | datewithTimeZone: timeZone:"time"
              }}</ng-template>
            </td>
            <td>
              <ng-container
                *ngIf="
                  item.callFinalUtc === '00:00:00' ||
                    item.callFinalUtc === null ||
                    (item.statusName !== 'Stop Call' &&
                      item.statusName !== 'Completed');
                  else completedTime
                "
                >{{ "N/A" }}</ng-container
              >
              <ng-template #completedTime>
                <div>
                  {{ item.callFinalUtc | datewithTimeZone: timeZone:"date" }}
                </div>
                <div class="f-12 font-weight-bold">
                  {{ item.callFinalUtc | datewithTimeZone: timeZone:"time" }}
                </div>
              </ng-template>
            </td>
            <td [class]="status[item.statusName].textColor">
              {{ item.statusName || "N/A" }}
            </td>

            <td>
              <div class="dropdown show">
                <a
                  class="btn border border-primary text-primary dropdown-toggle d-flex justify-content-between align-items-center"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ status[item.statusName].dropDownData[0].header }}
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <div
                    [attr.disabled]="item.statusName === 'To Do'"
                    class="dropdown-item"
                    (click)="
                      navigate(
                        itemValue.url,
                        item.applicationId,
                        itemValue?.tabName,
                        item
                      )
                    "
                    [appPermission]="itemValue.permission"
                    *ngFor="
                      let itemValue of status[item.statusName].dropDownData
                    "
                  >
                    <div>
                      {{ itemValue.header }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        <tr class="box-shadow" *ngIf="appointmentList?.length === 0">
          <td colspan="12">
            <p class="mt-2 mb-2 text-center call-info">
              Records not found with this search criteria
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="row justify-content-between m-0 mt-4"
      *ngIf="appointmentList?.length > 0"
    >
      <div>
        <p>
          Total no of records : <b>{{ totalNoOfRecords }}</b>
        </p>
      </div>
      <ngb-pagination
        [pageSize]="pageSize"
        [collectionSize]="totalNoOfRecords"
        [(page)]="page"
        [maxSize]="10"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
        aria-label="Default pagination"
        (pageChange)="loadPage($event)"
        *ngIf="totalNoOfRecords > 10"
      ></ngb-pagination>
    </div>
  </ng-template>
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="transparent"
    size="default"
    color="#000000"
    type="ball-spin-clockwise"
    [fullScreen]="false"
  >
  </ngx-spinner>
</div>
<app-modal
  [open]="openModal"
  [modalOptions]="{ size: 'xl' }"
  [buttonsConfig]="{
    modalMode: 'msg',
    submitButtonName: 'Close'
  }"
  (actions)="getNotifyEvent()"
>
  <div title>
    Application details
  </div>
  <div body>
    <app-appointments-detail [detailOptions]="config"></app-appointments-detail>
  </div>
</app-modal>
