import { Component, Input, OnInit } from '@angular/core';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Application } from 'src/app/shared/models/application/application';
import { ApplicationService } from 'src/app/shared/services/application/application.service';

@Component({
  selector: 'app-smart-answer',
  templateUrl: './smart-answer.component.html',
  styleUrls: ['./smart-answer.component.scss'],
})
export class SmartAnswerComponent implements OnInit {
  @Input() public type: string;
  @Input() public value: any;

  readonly BOOLEAN = 'Boolean';
  readonly OBJECT = 'object';
  readonly MEDICAL_QUALIFYING = 'MedicalQualifying';
  readonly PRODUCT = 'Product';

  public application: Application;
  public medicalQualifying = [];

  constructor(private applicationService: ApplicationService) {}

  ngOnInit() {
    this.applicationService.SelectedApplication.subscribe(
      (selectedApplication: Application) => {
        this.application = selectedApplication;
      }
    );

    if (this.type === this.MEDICAL_QUALIFYING) {
      this.buildMedicalQualifying();
    }
  }

  buildMedicalQualifying() {
    this.application.applicants.forEach((applicant: Applicant) => {
      this.value.forEach((v: any) => {
        if (applicant.clientNo === v.clientNo) {
          const aliments = v.ailments ? v.ailments.join(', ') : '';
          this.medicalQualifying.push({
            aliments,
            relationship: applicant.relationship,
            applicable: v.applicable,
          });
        }
      });
    });
  }
}
