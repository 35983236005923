import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import {
  NavigationAwareQuestionType,
  NavigationArgument,
  NavigationDirection,
} from '../types/baseQuestionType';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { Question } from 'src/app/shared/models/vcall/question';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormArray,
  ValidatorFn,
} from '@angular/forms';
import {
  MedicalItemList,
  MedicalItem,
} from 'src/app/shared/models/medicalItem';
import { FilterConfiguration } from 'src/app/shared/services/filter/question-filter/question-filter.service';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-medical-ailment',
  templateUrl: './medical-ailment.component.html',
  styleUrls: ['./medical-ailment.component.scss'],
})
export class MedicalAilmentComponent
  extends NavigationAwareQuestionType<VcallQuestionTypeMedicalAilmentDetail>
  implements AfterViewInit {
  details: VcallQuestionTypeMedicalAilmentDetail = new VcallQuestionTypeMedicalAilmentDetail();
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    private formBuilder: UntypedFormBuilder
  ) {
    super(script, appService, ui);
    this.ailmentForm = this.formBuilder.group({
      ailments: new UntypedFormArray([], this.minSelectedCheckboxes(1)),
    });
  }
  get ailmentsFormArray() {
    return this.ailmentForm.controls.ailments as UntypedFormArray;
  }
  ailmentForm: UntypedFormGroup;
  promptText: string;
  selectedItem: MedicalItem;
  isFormInvalid = true;
  qualifiedList: MedicalItem[];
  applicantName: string;
  selectedIndex = 0;
  answerType = 'ApplicantQualifying';

  @Input() configType: string;

  ApplicationChanged(): void {}

  ApplicableApplicants() {
    return this.qualifiedList.map((q) => {
      return this.application.applicants.filter(
        (a) => a.clientNo === q.clientNo
      )[0].fullName;
    });
  }

  QuestionChanged(q: Question): void {
    // this is to ensure that lingering subscription don't cause any issue
    if (q.type !== this.configType) {
      return;
    }

    if (this.ailmentsFormArray.length === 0) {
      this.details.items.forEach((a) => {
        this.ailmentsFormArray.push(new AilmentFormControl(a));
      });
    }

    if (
      this.answer !== null &&
      this.answer !== undefined &&
      this.answer.value !== null
    ) {
      this.qualifiedList = this.answer.value.filter((rq) => {
        return rq.applicable;
      });

      if (this.qualifiedList.length === 0) {
        console.log(' Race condition...go next');
        alert('unexpected condition, click back !!');
      }

      this.loadApplicant();
    }
  }

  loadApplicant() {
    // When the question is transitioning to avoid transiet issues.
    if (
      this.selectedIndex >= 0 &&
      this.selectedIndex < this.qualifiedList.length
    ) {
      this.selectedItem = this.qualifiedList[this.selectedIndex];
      const applicant = this.application.applicants.filter(
        (a) => a.clientNo === this.selectedItem.clientNo
      )[0];

      this.appService.SelectApplicant(applicant);
      const ailmentsList = this.selectedItem.ailments;
      this.ailmentsFormArray.controls.forEach((c) => {
        c.patchValue(false);
      });

      if (this.selectedItem && ailmentsList) {
        ailmentsList.forEach((item) => {
          const savedAilment = this.ailmentsFormArray.controls.filter((c) => {
            return c['ailment'] === item;
          });

          if (savedAilment && savedAilment.length > 0) {
            savedAilment.forEach((sa) => {
              sa.patchValue(true);
            });
          }
        });
      } else {
        this.ailmentsFormArray.controls.forEach((c) => {
          c.patchValue(false);
        });
      }
    }
  }

  ResetView() {
    this.selectedIndex = 0;
  }

  optionChanged(ailment: string, event) {
    if (
      this.selectedItem !== null &&
      this.selectedItem.hasOwnProperty('ailments')
    ) {
      if (event.target.checked) {
        this.selectedItem.ailments.push(ailment);
      } else {
        this.selectedItem.ailments = this.selectedItem.ailments.filter(
          (item) => item !== ailment
        );
      }
    } else {
      this.selectedItem['ailments'] = [ailment];
      this.selectedItem['prompt'] = this.promptText;
    }

    this.saveAnswer(true, this.answer.value, this.answerType);
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls.filter(
        (control) => control.value
      ).length; // get a list of checkbox values (boolean)
      this.isFormInvalid = !(totalSelected >= min);

      return totalSelected >= min ? null : { required: true }; // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  CanGoNext(): boolean {
    return (
      this.ailmentsFormArray.controls.filter((control) => control.value)
        .length > 0
    ); // get a list of checkbox values (boolean)
  }

  Navigating(direction: NavigationDirection): NavigationArgument {
    const arg = new NavigationArgument();

    if (direction === NavigationDirection.Next) {
      this.selectedIndex++;
    } else if (direction === NavigationDirection.Previous) {
      this.selectedIndex--;
    }

    const remain = !(
      this.selectedIndex < 0 || this.selectedIndex >= this.qualifiedList.length
    );
    arg.stopPropagation = remain;

    if (remain) {
      this.loadApplicant(); // This will load the next or previous applicant
      this.saveAnswer(true, this.qualifiedList); // force correct the Next button.
    }

    return arg;
  }
}

export class VcallQuestionTypeMedicalAilmentDetail {
  options: string;
  AilmentName: string;

  get items() {
    if (this.options) {
      const result = JSON.parse(this.options);
      return result;
    } else {
      return [];
    }
  }
}
export class AilmentFormControl extends UntypedFormControl {
  public ailment: string;
  constructor(ailment: string) {
    super(false);
    this.ailment = ailment;
  }
}
