import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { ResponseDeciderService } from 'src/app/shared/services/responseDecider/responseDecider.service';
import { LoggerService } from '../logger.service';
import { LogDataService } from '../logData.service';
import * as _ from 'lodash';

/**
 * Auth interceptor
 */
@Injectable()
export class HttpMessageInterceptor implements HttpInterceptor {
  constructor(
    private toaster: ToastService,
    private deciderService: ResponseDeciderService,
    private loggerService: LoggerService,
    private logDataService: LogDataService
  ) {}
  /**
   * interceptor
   * @param req http request
   * @param next http handler
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let self = this;
    const success: string = req.headers.get('x-success-message');
    const error: string = req.headers.get('x-error-message');
    const deciderKey: string = req.headers.get('x-response-decider-key');
    const dontLog: string = req.headers.get('x-dont-log');
    const requestId: string = req.headers.get('x-request-id');

    return next.handle(req).pipe(
      tap((evt) => {        
        if (evt instanceof HttpResponse) {
          if (deciderKey) {            
            if (this.deciderService.IsError(deciderKey, evt)) {
              this.toaster.error(null, error);
              this.logApiFailureException(req, error, dontLog, {});
            } else if (success !== 'undefined' && success !== null) {
              this.toaster.success(null, success);
            }
          } else if (evt.ok && success) {
            this.toaster.success(null, success);
          }
        }
      }),
      catchError((err: any) => {
        if (dontLog === 'true') {
          return of(err);
        }        
        if (err instanceof HttpErrorResponse && err.status !== 200) {
          try {
            if (error !== null) {
              this.toaster.error(null, error);
            }
            this.logApiFailureException(req, error, dontLog, err);
          } catch (e) {
            this.toaster.error(null, error);
            this.logApiFailureException(req, error, dontLog, {
              exceptions: e,
              apiError: err,
            });
          }
          this.logApiFailureException(req, error, dontLog, err);
          return of(new HttpResponse({ status: 500, body: err }));
        }

        // this is for APIs that do not return graceful json
        // if (success && err.status === 200) {
        if (err.status === 200) {
          if (success) {
            this.toaster.success(null, success);
          }
          return of(new HttpResponse({ status: 200, body: err.json }));
        }
        
        // this.logApiFailureException(req, error, dontLog, err);
        return of(err);
      }),
      finalize(() => {
        self.logDataService.deleteLogData(requestId);
      })
    );
  }
  logApiFailureException(req, error, dontLog, apiError) {
    let self = this;
    if (dontLog === 'true') {
      return;
    }
    const errorData: Error = new Error(error);
    errorData['payload'] = req.body;
    errorData['url'] = req.url;
    errorData['apiError'] = apiError;

    const requestId: string = req.headers.get('x-request-id');
    
    if(_.isNil(requestId) || _.isEmpty(requestId)) {
      this.loggerService.logException(errorData);
    }
    else {
      var logData = self.logDataService.getLogData(requestId);
      this.loggerService.logException(errorData, logData);
    }    
  }
}
