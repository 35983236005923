import { Applicant } from '../application/applicant';

export class VCallApplicant {
  constructor(applicant: Applicant) {
    this.applicant = applicant;
    this.addedOn = new Date();
  }

  applicant: Applicant;
  addedOn: Date;
}
