import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Application } from 'src/app/shared/models/application/application';

@Component({
  selector: 'app-ezapp-notes',
  templateUrl: './ezapp-notes.component.html',
  styleUrls: ['./ezapp-notes.component.scss'],
})
export class EzappNotesComponent implements OnInit {
  ezappNotes = {};
  noText = false;
  constructor(private applicationService: ApplicationService) {
    this.applicationService.SelectedApplication.subscribe((a: any) => {
      if (a && a.notes && a.notes.text !== '') {
        this.ezappNotes = a.notes.text;
        this.noText = true;
      } else {
        this.noText = false;
      }
    });
  }

  ngOnInit() {}
}
