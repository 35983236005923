import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Answer } from '../../models/application/answer';
import { Applicant } from '../../models/application/applicant';
import { Application } from '../../models/application/application';
import { DialingInformationModel } from '../../models/application/dialingInformation';
import { Appointment } from '../../models/appointment';
import { Recall } from '../../models/recall/Recall';
import { RecallInfo } from '../../models/recall/RecallInfo';
import { RecallType } from '../../models/recall/RecallType';
import { ApplicationService } from '../../services/application/application.service';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { UserProfileService } from '../../services/user/user-profile.service.service';
import { EndCallModalComponent } from '../end-call-modal/end-call-modal.component';

@Component({
  selector: 'app-online-applicants',
  templateUrl: './online-applicants.component.html',
  styleUrls: ['./online-applicants.component.scss'],
})
export class OnlineApplicantsComponent implements OnInit, OnDestroy {
  stopCallReasonForReschedule =
    environment.config.callZoneStatusConfiguration.stopCallReasonForReschedule;
  isStopCallModelOpened = false;
  @ViewChild(EndCallModalComponent) stopCall: EndCallModalComponent;
  @ViewChild('content', { static: true })
  content: ElementRef;
  @Input() appointment;
  answer: Answer;
  isFormInvalid = false;
  newSuccessCallBack: any;
  subscriptions = [];
  selectedAppointment: Appointment;
  dialingInformationModel: DialingInformationModel = new DialingInformationModel();
  callType: string;
  recall: Recall;
  listOfContactTypesHavingOneApplicant = [
    'Payment.ApplicationPayorMonthly',
    'Payment.ApplicationPayorInitial',
  ];
  timeZone: string;
  constructor(
    private appService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private alertConfig: NgbAlertConfig,
    private appointmentService: AppointmentService,
    private profileService: UserProfileService
  ) {
    this.form = this.formBuilder.group({
      applicants: new UntypedFormArray([], this.minSelectedCheckboxes(1)),
    });

    this.alertConfig.dismissible = false;
    this.alertConfig.type = 'danger';
  }

  application: Application;
  applicants: Array<Applicant> = new Array<Applicant>();
  form: UntypedFormGroup;

  get applicantsFormArray() {
    return this.form.controls.applicants as UntypedFormArray;
  }

  /**
   * @description add the online checkboxes
   */
  private addCheckboxes() {
    this.applicants.forEach((a) => {
      this.applicantsFormArray.push(new OnlineApplicantFormControl(a.clientNo));
    });

    if (
      this.answer !== null &&
      this.answer !== undefined &&
      this.answer.value !== null
    ) {
      this.answer.value.forEach((a) => {
        const savedApplicant = this.applicantsFormArray.controls.filter(
          (c) => c['clientNo'] === a.applicant.clientNo
        );
        if (savedApplicant && savedApplicant.length > 0) {
          savedApplicant[0].patchValue(true);
        }
      });
    }
  }

  ngOnInit() {
    // Refator to pass
    // this.subscriptions.push(
    //   this.appService.ApplicationRecalls.subscribe((recalls) => {
    //     if (recalls) {
    //       if (this.selectedAppointment) {
    //         this.recall = recalls.find(
    //           (r) => r.appointmentId === this.selectedAppointment.appointmentId
    //         );
    //         this.process();
    //       }
    //     }
    //   })
    // );

    // this.subscriptions.push(
    //   this.appointmentService.SelectedAppointment.subscribe((a) => {
    //     this.selectedAppointment = a;
    //     this.callType = this.selectedAppointment.callType;
    //     this.process();
    //   })
    // );

    // this.subscriptions.push(
    //   this.appService.SelectedApplication.subscribe((a) => {
    //     if (a) {
    //       this.application = a;
    //       this.process();
    //     }
    //   })
    // );

    this.getProfileDetails();
  }

  setup(appointment: Appointment, application: Application, recall?: Recall) {
    this.selectedAppointment = appointment;
    this.callType = this.selectedAppointment.callType;
    this.application = application;
    this.applicants = application.applicants;

    // safeguard. only if appointment type is a recall bother.
    // Recall keyword should be a key somewhere.
    if (this.callType === 'Recall' && !this.recall) {
      // this.appService.LoadRecall(application.id);
      this.recall = recall;
    }

    this.process();
  }

  process() {
    if (
      this.selectedAppointment &&
      this.application &&
      this.selectedAppointment.appId === this.application.id
    ) {
      if (this.selectedAppointment.callType !== RecallInfo.callType) {
        this.buildApplicantList();
      }
      if (
        this.recall &&
        this.recall.appointmentId === this.selectedAppointment.appointmentId
      ) {
        if (
          this.selectedAppointment.callType === RecallInfo.callType &&
          this.recall.contactType === RecallType.applicant
        ) {
          this.buildApplicantList();
        } else if (this.selectedAppointment.callType === RecallInfo.callType) {
          this.buildRecallTypeList();
        }
      }
    } else {
      this.applicants = [];
    }
  }

  buildApplicantList() {
    const a = this.application;
    // Show adults only
    this.applicants = a.adults;
  }
  /**
   * To create the list of Applicants for the Recall
   * @returns Returns the list of Applications
   */
  buildRecallTypeList() {
    const list = new Array<Applicant>();
    const { contactType } = this.recall;
    this.recall.questionList.map((q) => {
      const a = new Applicant();
      a.firstName = q.applicantName;
      a.lastName = '';
      a.clientNo = '0';
      list.push(a);
    });
    // added condition for 3rd party payment
    this.applicants = this.listOfContactTypesHavingOneApplicant.includes(
      contactType
    )
      ? [list[0]]
      : list;
  }

  /**
   * @returns form validation and show the error message when not select at least one checkbox
   */
  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        .map((control) => control.value) // get a list of checkbox values (boolean)
        .reduce((prev, next) => (next ? prev + next : prev), 0); // total up the number of checked checkboxes
      if (totalSelected > 0) {
        this.isFormInvalid = false;
      }
      return totalSelected >= min ? null : { required: true }; // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  /**
   * @description display the online modal
   */
  // TODO: improve this function
  showModal(
    successCallback,
    appointment: Appointment,
    application: Application,
    recall?: Recall
  ) {
    this.setup(appointment, application, recall);
    this.answer = this.appService.GetOnlineApplicants(
      this.selectedAppointment.appointmentId
    );
    this.addCheckboxes();
    this.newSuccessCallBack = successCallback;
    const callback = (context) => {
      this.isFormInvalid = false;
      this.modalService
        .open(this.content, {
          ariaLabelledBy: 'modal-basic-title',
          backdrop: 'static',
          keyboard: false,
          size: 'lg',
          centered: true,
        })
        .result.then((a) => {
          const selected = context.form.value.applicants
            .map((checked, i) => (checked ? this.applicants[i] : null))
            .filter((v) => v !== null && v !== undefined);
          if (selected && selected.length > 0) {
            context.appService.AddOnlineApplicants(
              selected,
              this.selectedAppointment.appointmentId
            );
          }
          // TODO : revisit to see if redux pattern makes more sense
          if (successCallback) {
            successCallback();
          }
        })
        .catch((e) => console.log(e));
    };
    callback(this);
  }

  /**
   * @description save the online modal form
   */
  save() {
    this.isFormInvalid = false;
    if (!this.form.valid) {
      this.isFormInvalid = true;
    } else {
      const selected = this.form.value.applicants
        .map((checked, i) => (checked ? this.applicants[i] : null))
        .filter((v) => v !== null);
      if (selected && selected.length > 0) {
        this.appService.AddOnlineApplicants(
          selected,
          this.selectedAppointment.appointmentId
        );
      }
      this.modalService.dismissAll();
      // TODO : revisit to see if redux pattern makes more sense
      if (this.newSuccessCallBack) {
        this.newSuccessCallBack();
      }
    }
  }

  /**
   * @description destroy all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  getProfileDetails() {
    this.timeZone = this.profileService.getCallzoneUser()?.timeZone;
  }

  triggerStopCallModal() {
    this.modalService.dismissAll();
    this.isStopCallModelOpened = true;
    this.stopCall.openReasonModal(this.stopCallReasonForReschedule, true);
  }
}

export class OnlineApplicantFormControl extends UntypedFormControl {
  public clientNo: string;
  constructor(clientNo: string) {
    super(false);
    this.clientNo = clientNo;
  }
}
