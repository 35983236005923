import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import {
  ApplicantQualifiedBaseComponent,
  BaseApplicantQualifiedAnswer,
} from '../applicant-qualified-base-component';
import {
  DefaultApplicantQualifiedQuestionDetail,
  ApplicantQualifiedBooleanTextboxDetail,
} from '../default-applicant-qualified-question-detail';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { TextBoxAnswer } from '../textbox-answer';
import { MedicalItem } from 'src/app/shared/models/medicalItem';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { ApplicantDetailItem } from '../applicant-detail-item';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';

export enum KEY_CODE {
  YES = 'KeyY',
  NO = 'KeyN',
}
@Component({
  selector: 'app-applicant-qualified-boolean-textbox',
  templateUrl: './applicant-qualified-boolean-textbox.component.html',
  styleUrls: ['./applicant-qualified-boolean-textbox.component.scss'],
})
export class ApplicantQualifiedBooleanTextboxComponent extends ApplicantQualifiedBaseComponent<
  ApplicantQualifiedBooleanTextboxDetail,
  BooleanTextAreaAnswer
> {
  details: ApplicantQualifiedBooleanTextboxDetail =
    new ApplicantQualifiedBooleanTextboxDetail();
  selectedItem: ApplicantDetailItem<BooleanTextAreaAnswer>;
  currentAnswer: BooleanTextAreaAnswer;
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    public appointmentService: AppointmentService,
    protected applicantFilterService: ApplicantFilterService
  ) {
    super(script, appService, ui, applicantFilterService);
  }

  nextEnabled = false;
  @ViewChild('booleanyes') yesSelection: ElementRef;
  @ViewChild('booleanno') noSelection: ElementRef;

  SaveText() {
    this.currentAnswer.applicable =
      this.yesSelection.nativeElement.checked.toString();
    // console.log(this.currentAnswer);
    super.Save(this.currentAnswer);
  }

  // Next and previous buttons with keyboard navigation
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const bodyElement = document.querySelector('body');
    if (bodyElement && !bodyElement.classList.contains('modal-open')) {
      if (event.code === KEY_CODE.YES) {
        if (
          'INPUT' !== document.activeElement.tagName &&
          'TEXTAREA' !== document.activeElement.tagName
        ) {
          this.yesSelection.nativeElement.checked = true;
          this.optionChanged('true');
        }
      }
      if (event.code === KEY_CODE.NO) {
        if (
          'INPUT' !== document.activeElement.tagName &&
          'TEXTAREA' !== document.activeElement.tagName
        ) {
          this.noSelection.nativeElement.checked = true;
          this.optionChanged('false');
        }
      }
    }
  }

  optionChanged(answer: any) {
    this.currentAnswer.applicable = answer.toString();
    if (
      (this.currentAnswer.applicable === 'true' &&
        this.details.mode === 'booleanAndNotesIfFalse') ||
      this.currentAnswer.applicable === 'false'
    ) {
      this.currentAnswer.notes = null;
    }
    super.Save(this.currentAnswer);
  }

  ResetApplicant() {
    {
    }
  }

  BindApplicant() {
    console.log(this.currentAnswer);
    this.nextEnabled = false;
    if (this.yesSelection) {
      // Looks like Reset is too early
      this.yesSelection.nativeElement.checked = false;
      this.noSelection.nativeElement.checked = false;

      if (this.currentAnswer) {
        if (this.currentAnswer.notes) {
          this.currentAnswer.notes = this.currentAnswer.notes;
        }
        if (
          this.currentAnswer.applicable !== undefined &&
          this.currentAnswer.applicable !== null
        ) {
          this.yesSelection.nativeElement.checked =
            this.currentAnswer.applicable === 'true';
          this.noSelection.nativeElement.checked = !(
            this.currentAnswer.applicable === 'true'
          );
        }
      }
    }
  }

  // ResetView() {}

  // TODO: the component is limited in terms of button and notes behavior
  CanGoNext(): boolean {
    if (this.currentAnswer === null) {
      return false;
    } else if (this.currentAnswer.applicable === 'false') {
      return true;
    } else {
      if (this.details.mode === 'booleanAndNotesIfTrue') {
        return (
          this.currentAnswer.applicable === 'true' &&
          this.currentAnswer.notes !== undefined &&
          this.currentAnswer.notes !== null &&
          this.currentAnswer.notes.length > 0
        );
      } else if (this.details.mode === 'booleanAndNotesIfFalse') {
        return this.currentAnswer.applicable === 'true';
      }
    }
  }

  InitializeApplicantAnswerInstance(): BooleanTextAreaAnswer {
    return {
      applicable: null,
      notes: null,
      answerType: 'BooleanTextAreaAnswer',
    };
  }

  // To improve user experience by enabling the next button
  // But not calling Save on every change avoiding un necessary Question Change events.
  EnableNext() {
    if (!this.nextEnabled) {
      this.currentAnswer.applicable =
        this.yesSelection.nativeElement.checked.toString();
      super.Save(this.currentAnswer);
      this.nextEnabled = true;
    }
    if (this.currentAnswer.notes === '') {
      super.Save(this.currentAnswer);
      this.nextEnabled = false;
    }
  }
}

export interface BooleanTextAreaAnswer extends BaseApplicantQualifiedAnswer {
  applicable: string;
  notes: string;
  answerType: string;
}
