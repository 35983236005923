<button
  class="btn btn-primary btn-lg carousel-next carousel-next-arrow float-right"
  (click)="endCall(content)"
>
  <!-- {{
    selectedAppointment.callType === "Recall"
      ? "Complete Recall"
      : "Complete VCall"
  }} -->
  Next <i class="fa fa-caret-right" aria-hidden="true"></i>
</button>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title w-100" id="modal-basic-title">
      Complete - Confirmation
    </h4>
    <button
      type="button"
      class="close outline-0"
      aria-label="Close"
      (click)="modal.close('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    *ngIf="processing === false"
    class="modal-body"
    [innerHTML]="endCallPopupText"
  ></div>
  <div
    *ngIf="processing === true"
    class="modal-body pb-0"
    [innerHTML]="endCallWaitText"
  ></div>
  <div class="modal-body" *ngIf="processing === true">
    <div *ngFor="let item of processor.ProcessingStatus$ | async">
      <div class="d-flex align-items-center mb-2">
        <p class="m-0 text_no_wrap">{{ item.name }}</p>
        <span *ngFor="let s of item.status">
          <img
            [src]="getStatusPillImagePath(s)"
            alt="Image"
            title="Image"
            class="ml-2"
          />
        </span>
      </div>
      <p
        *ngIf="item.message && item.message !== ''"
        class="col-sm-12 alert alert-warning text-center mt-3"
        role="alert"
        [innerHTML]="item.message"
      ></p>
    </div>
    <div *ngIf="displayTryAgainButton">
      <div class="d-flex align-items-center my-3">
        <button
          type="button"
          class="btn btn-outline-primary btn-sm tryagainBtn"
          (click)="endTheCall()"
          [disabled]="ignore"
        >
          <img
            src="assets/images/refresh.svg"
            alt="refresh"
            title="refresh"
            class="mr-2"
          />
          Try Again
        </button>
      </div>
      <div class="my-1">
        <input
          type="checkbox"
          name="ignore"
          required
          id="ignore"
          [(ngModel)]="ignore"
          [value]="true"
        />
        <label for="ignore" class="pl-2 mr-5 call-status cursor-pointer">
          Click here to ignore the errors.</label
        >
      </div>
    </div>
    <div class="p-3 alert alert-secondary" role="alert" *ngIf="ignore">
      <p>
        Download the errors. Click on the 'Help Center' button to submit a
        ticket and attach the downloaded file.
      </p>
      <div>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm mr-3 tryagainBtn"
          (click)="exportErrors()"
        >
          <i class="fa fa-arrow-down download"></i> Download Errors
        </button>
        <a href="https://helpdesk.uhg.com/hc" target="_blank"
          ><img src="assets/images/ushg/Help_Center.svg"
        /></a>
      </div>
    </div>
  </div>

  <div class="modal-footer text-right">
    <button
      type="button"
      class="btn btn-primary"
      (click)="endTheCall()"
      [disabled]="processing && !ignore"
    >
      {{
        selectedAppointment.callType === "Recall"
          ? "Complete Recall"
          : "Complete VCall"
      }}
    </button>
  </div>
</ng-template>
