import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HandledHttpClient } from 'src/app/shared/services/api/handledHttpClient';
import { DoctorLookup } from 'src/app/shared/models/drLookup';
import { Application } from 'src/app/shared/models/application/application';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SearchTerms {
  healthcareProviderSearchType: number;
  searchString: string;
  applicantZipcode: string;
  areaCode: string;
  phoneNumber: string;
  radius: number;
}

@Injectable({
  providedIn: 'root',
})
export class DocLookupService {
  baseUrl: string;
  drLookup: DoctorLookup;

  constructor(
    private handledHttp: HandledHttpClient,
    private http: HttpClient,
    private applicationService: ApplicationService
  ) {
    this.baseUrl = environment.config.apis.providerLookup;
  }

  public getProviderLookup(searchTerms: SearchTerms) {
    return this.handledHttp.post(`${this.baseUrl}`, searchTerms);
  }

  public getProviderLookupByPhoneNumber(searchTerms: SearchTerms) {
    const copyOfAPIObject = { ...searchTerms };
    copyOfAPIObject.healthcareProviderSearchType = 0;
    searchTerms.healthcareProviderSearchType = 1;
    const urlsList = [
      this.handledHttp.post(`${this.baseUrl}`, copyOfAPIObject),
      this.handledHttp.post(`${this.baseUrl}`, searchTerms),
    ];
    return forkJoin(urlsList).pipe(
      map((data) => {
        return [].concat(...data);
      })
    );
  }

  AddProviders(doctorsList: DoctorLookup[], clientNo?, providerId?) {
    // union on previous and current doctors list.
    const mergedArray = [
      ...doctorsList,
      ...this.GetExistingApplicationProviders(clientNo, providerId),
    ];
    if (clientNo !== null && clientNo !== undefined) {
      const mergedArrayRef = JSON.parse(JSON.stringify(mergedArray));
      mergedArray.map((doctor) => {
        mergedArrayRef.forEach((refDoc) =>
          refDoc.id === doctor.id && refDoc.fullname === doctor.fullname
            ? doctor.client_nos.push(...refDoc.client_nos)
            : []
        );
        doctor.client_nos = [...new Set([...doctor.client_nos])];
        return doctor;
      });
    }
    const set = new Set();
    const unionArray = mergedArray.filter((item) => {
      if (!set.has(item.id)) {
        set.add(item.id);
        return true;
      }
      return false;
    }, set);
    this.applicationService.RegisterAnswerByTag(true, unionArray, 'docsList');
    // returning the merged docs list so that the list of doctors will be visible to next applicants
    return unionArray;
  }

  // fetch application providers from the answer model for delete the client Number from the Provider obejct
  GetExistingApplicationProviders(clientNo?, providerId?) {
    let existingApplicationProviders = this.GetApplicationProviders();
    if (clientNo !== null && clientNo !== undefined) {
      if (
        existingApplicationProviders.length > 0 &&
        providerId !== null &&
        providerId !== undefined
      ) {
        let matchedProviderClientNos = existingApplicationProviders.filter(
          (provider) => provider.id === providerId
        )[0].client_nos;
        let filterMatchedProviderClientNos = matchedProviderClientNos.filter(
          (providerClientNo) => providerClientNo !== clientNo
        );
        if (filterMatchedProviderClientNos.length === 0) {
          existingApplicationProviders = existingApplicationProviders.filter(
            (provider) => provider.id !== providerId
          );
        } else {
          existingApplicationProviders = existingApplicationProviders.map(
            (provider) => {
              provider.id === providerId
                ? (provider.client_nos = filterMatchedProviderClientNos)
                : provider.client_nos;
              return provider;
            }
          );
        }
      }
    }
    return existingApplicationProviders;
  }

  GetApplicationProviders() {
    return this.applicationService.GetAnswerForAnswerTags('docsList')
      ? this.applicationService.GetAnswerForAnswerTags('docsList').value
      : [];
  }
}
