import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Section } from 'src/app/shared/models/vcall/section';
import {
  ScriptService,
  VcallMode,
} from 'src/app/shared/services/script/script.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Router } from '@angular/router';
import { Script } from 'src/app/shared/models/vcall/script';
import { Question } from 'src/app/shared/models/vcall/question';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Answer } from 'src/app/shared/models/application/answer';
import { TemplateModel } from 'src/app/shared/models/vcall/templateModel';
import {
  Application,
  OtherCoverage,
} from 'src/app/shared/models/application/application';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { CallZoneUser } from 'src/app/shared/models/callzone-user';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { Appointment } from 'src/app/shared/models/appointment';
import {
  IndemnityCoverage,
  Product,
} from 'src/app/shared/models/application/product';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { MedicalItem } from 'src/app/shared/models/medicalItem';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Location } from '@angular/common';
import { STATES } from 'src/app/shared/models/states';
import { VcallParams } from 'src/app/shared/models/vcall/vcallParams';
import { OccupationDetailsAnswer } from 'src/app/vcall/questions/applicant-qualified-controls/applicant-qualified-occupation/applicant-qualified-occupation.component';

@Component({
  selector: 'app-completed-vcalls',
  templateUrl: './completed-vcalls.component.html',
  styleUrls: ['./completed-vcalls.component.scss'],
})
export class CompletedVcallsComponent implements OnInit, OnDestroy {
  constructor(
    private scriptService: ScriptService,
    private router: Router,
    private applicationService: ApplicationService,
    private uiService: UiServiceService,
    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private location: Location,
    private profileService: UserProfileService
  ) {}
  sections: Array<Section>;
  questions: Question[];
  subscriptions = Array<Subscription>();
  answers: Answer[] = [];
  application: Application;
  agent: CallZoneUser;
  appointment: Appointment;
  indemnityCoverage: Array<IndemnityCoverage>;
  product: Product;
  otherCoverage: OtherCoverage[];
  primaryApplicant: Applicant;
  family: Array<Applicant>;
  item = [];
  selectedCall: any;
  ailmentsArray: MedicalItem[];
  searchForm: UntypedFormGroup;
  states: any;
  isSearch: boolean;
  competedCallList: any = [];
  pageSize = 10;
  presentPage = 1;
  prompt = '';
  autoShowPrintView = false;
  timeZone: string;

  @ViewChild('content', { static: true })
  content: ElementRef;

  subscriptionInitialized = false;

  selectedApplicant: Applicant = new Applicant();

  ngOnInit() {
    this.timeZone = this.profileService.getCallzoneUser().timeZone;
    this.statesList();
    this.searchForm = new UntypedFormGroup(
      {
        applicationId: new UntypedFormControl('', Validators.pattern('[0-9]+')),
        firstName: new UntypedFormControl(''),
        lastName: new UntypedFormControl(''),
        state: new UntypedFormControl(''),
      },
      this.atLeastOneInputHasValue()
    );

    this.isSearch = false;

    if (Object.keys(this.route.snapshot.queryParams).length > 0) {
      const uriParams = this.route.snapshot.queryParams;
      this.searchForm.patchValue({
        applicationId: uriParams.applicationId || '',
        firstName: uriParams.firstName || '',
        lastName: uriParams.lastName || '',
        state: uriParams.state || '',
      });

      this.searchApplications();
    }

    this.route.data.subscribe((d) => {
      if (d && d['printView'] === true) {
        const applicationId = this.route.snapshot.paramMap.get('applicationId');
        this.searchForm.patchValue({
          applicationId: applicationId || '',
          firstName: '',
          lastName: '',
          state: '',
        });
        this.autoShowPrintView = true;
        this.searchApplications();
      }
    });

    //  this.initSubscriptions();
  }

  // public navigateToSection(section: string) {
  //   window.location.hash = '';
  //   window.location.hash = section;
  // }

  // viewCallResponse(applicationId: string, content, selectedCall) {
  //   this.selectedCall = selectedCall;

  //   this.applicationService.SelectApplication(applicationId);
  //   const options = new VcallParams();

  //   options.applicationId = applicationId;
  //   options.mode = VcallMode.Standard;
  //   options.stopNavigation = 'true';
  //   this.scriptService.LoadScript(options);

  //   // this.initSubscriptions();
  //   this.scriptService.ApplicationScript.subscribe((script) => {
  //     if (script && script.items !== null) {
  //       this.questions = [];
  //       script.items.forEach((s) => {
  //         this.item.push(s);
  //         s.questions.forEach((q) => {
  //           if (q !== undefined) {
  //             this.questions.push(q);
  //           }
  //         });
  //       });

  //       if (this.questions && this.questions.length > 0) {
  //         this.questions = this.questions.sort((s) => s.sequence);
  //       }
  //     }
  //   });
  // }
  /**
   * @description this method has all the subscriptions and that will give the applicant, indemnity coverage & other coverage details
   */
  initSubscriptions() {
    // this.subscriptionInitialized = true;

    this.subscriptions.push(
      this.scriptService.Sections.subscribe((sections) => {
        this.sections = sections;
      })
    );

    this.subscriptions.push(
      this.applicationService.Answers.subscribe((a) => {
        if (a && a.length > 0) {
          this.answers = a;
          const appointments: Appointment[] = this.answers.find(
            (i) => i.answerTag === 'appointment'
          ).value;
          this.appointment = appointments.find((i) => i.callType !== 'Recall');
          this.agent = new CallZoneUser();
          this.agent.name =
            this.appointment.firstName + ' ' + this.appointment.lastName;
        } else {
          this.answers = [];
        }
      })
    );

    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a: any) => {
        this.application = a;
        this.family = a.family;
        this.primaryApplicant = a.primaryApplicant;
        this.indemnityCoverage = this.application.indemnityCoverage;
        this.otherCoverage = this.application.otherCoverage;
        if (this.indemnityCoverage) {
          this.indemnityCoverage = this.indemnityCoverage.filter(
            (v, i, ele) => ele.findIndex((t) => t.planName === v.planName) === i
          );
        }

        if (this.otherCoverage) {
          this.otherCoverage = this.otherCoverage.filter(
            (v, i, ele) =>
              ele.findIndex((t) => t.otherPlanName === v.otherPlanName) === i
          );
        }
      })
    );
  }

  // getVerification(tag: string) {
  //   const answer: Answer = this.answers.find((a) => a.answerTag === tag);
  //   return answer ? answer.verification : false;
  // }

  // getAnswerforQuestion(question: Question): any {
  //   const answer: Answer = this.answers.find(
  //     (a) => a.answerTag === question.answerTag
  //   );

  //   if (!answer) {
  //     switch (question.type) {
  //       case 'MedicalAilment': {
  //         return 'None';
  //       }
  //       case 'MedicalQualifying': {
  //         return 'No ailments selected';
  //       }
  //       default: {
  //         return '';
  //       }
  //     }
  //   }
  //   if (answer.value === 'true') {
  //     return 'Yes';
  //   } else if (answer.value === 'false') {
  //     return 'No';
  //   }

  //   switch (question.type) {
  //     case 'MedicalAilment': {
  //       const val: MedicalItem[] = answer.value;
  //       if (val && val.length > 0 && val[0].ailments) {
  //         this.ailmentsArray = val;
  //         return;
  //       }
  //       return;
  //     }
  //     case 'MedicalQualifying': {
  //       const val: MedicalItem[] = answer.value;
  //       let applicants;
  //       this.application.applicants.forEach((a) => {
  //         val.forEach((v) => {
  //           if (v.clientNo === a.clientNo && applicants) {
  //             applicants = applicants + ',' + a.fullName;
  //           } else {
  //             applicants = a.fullName;
  //           }
  //         });
  //       });
  //       return applicants;
  //     }
  //     case 'QualifyingStudent': {
  //       // TODO: This needs to be corrected
  //       const val: MedicalItem[] = answer.value;
  //       let applicants;
  //       this.application.applicants.forEach((a) => {
  //         val.forEach((v) => {
  //           if (v.clientNo === a.clientNo && applicants) {
  //             applicants = applicants + ',' + a.fullName;
  //           } else {
  //             applicants = a.fullName;
  //           }
  //         });
  //       });
  //       return applicants;
  //     }
  //     case 'ApplicantQualifiedOccupation': {
  //       const tag = question.details.answerTag;
  //       const val: MedicalItem[] = answer.value;
  //       // const item = val.find(a => a.clientNo === ) ["values"].
  //     }
  //   }
  // }

  // getNameForClientNo(clientNo: string) {
  //   return this.application.applicants.find((a) => a.clientNo === clientNo)
  //     .fullName;
  // }

  /**
   * @description used to unsubscribe the all the subscriptions to avoid memory leaks
   */
  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }
  /**
   * @description this method will give the search application results based on search crieteria like appid, state etc.
   */
  searchApplications() {
    this.isSearch = true;

    if (this.searchForm.valid) {
      const formData = this.searchForm.value;
      const searchData = {
        applicationId: Number(formData.applicationId),
        firstName: formData.firstName,
        lastName: formData.lastName,
        state: formData.state,
        skip: this.presentPage * this.pageSize - this.pageSize,
        take: this.pageSize + 1,
      };

      const search = Object.keys(searchData).reduce((acc, el) => {
        // excluding empty valued parameters before passing to api
        if (searchData[el] !== '') {
          acc[el] = searchData[el];
        }
        if (acc['applicationId'] === 0) {
          delete acc['applicationId'];
        }
        return acc;
      }, {});

      const { skip, take, ...fields }: any = search;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: fields,
      });

      this.applicationService
        .SearchApplication(search)
        .then((data: ApplicationSearchResult[]) => {
          console.log(data);
          this.competedCallList = data;
          // if (data && data.length > 0 && this.autoShowPrintView) {
          //   this.viewCallResponse(
          //     data[0].applicationId.toString(),
          //     this.content,
          //     data[0]
          //   );
          // }
        });
    }
  }

  // closeModal() {
  //   this.location.back();
  // }
  /**
   * @description this method is used to get the available states list
   */
  statesList() {
    this.states = STATES;
  }
  /**
   *
   * @description not displaying message, but making form-invalid, & disable button if atleast one value available for search
   */
  atLeastOneInputHasValue = () => {
    return (group: UntypedFormGroup) => {
      if (!Object.values(group.value).find((value) => value !== '')) {
        return { message: 'Please input at least one value' };
      }
      return null;
    };
  };
  /**
   * @param step here we wil recieve the page number that user wish to navigate to see the further results
   * @description this method is called on page number getting clicked, will give the applciation results according to the search criteria
   */
  move(step) {
    this.presentPage = this.presentPage + step;
    this.searchApplications();
  }
  /**
   * @description not been used in the component
   */
  navToModule(navUrl) {
    this.router.navigate([`./${navUrl}`]);
  }
}

export class ApplicationSearchResult {
  appType: string;
  applicationId: number;
  clientNo: string;
  company: string;
  createDate: Date;
  firstName: string;
  lastName: string;
  state: string;
  submitDate: Date;
}
