<div class="mt-3 card p-4 rounded">
  <ng-container *ngIf="vm">
    <div class="row m-0 justify-content-end">
      <button
        *ngFor="let item of vm.applicableTypes"
        type="button"
        class="btn btn-primary btn-sm ml-2 px-4"
        (click)="createRecall(item)"
        [appPermission]="'CanCreateRecall'"
        [ngClass]="{ 'disable-btn': disableRecallButton }"
      >
        <ng-container *ngIf="item === 'Applicant'">
          Create Applicant Recall
        </ng-container>
        <ng-container *ngIf="item === 'Payment.ApplicationPayorInitial'">
          Create 3rd Party Recall For Initial Payment
        </ng-container>
        <ng-container *ngIf="item === 'Payment.ApplicationPayorMonthly'">
          Create 3rd Party Recall For Recurring Payment
        </ng-container>
      </button>
    </div>
    <div>
      <div
        *ngIf="
          getUniqueUsersFromCompletedRecalls().length === 0 &&
          vm.currentRecalls.length === 0
        "
        class="p-4"
      >
        <b>There are no active or completed recalls for this application.</b>
      </div>
      <div class="row mt-4" *ngIf="vm.currentRecalls.length > 0">
        <div class="col-12 mb-4">
          <nav
            ngbNav
            #nav="ngbNav"
            class="nav-tabs"
            [activeId]="
              tabsPersistenceService.getStoredOrDefault(
                this.applicationId + '-selectedRecall'
              )
            "
          >
            <ng-container
              *ngFor="let recall of vm.currentRecalls; let i = index"
            >
              <li
                [ngbNavItem]="i + 1"
                (click)="
                  saveSelection(this.applicationId + '-selectedRecall', i + 1)
                "
              >
                <a ngbNavLink *ngIf="recall.contactType === 'Applicant'"
                  >Applicant Recall</a
                >
                <a
                  ngbNavLink
                  *ngIf="
                    recall.contactType === 'Payment.ApplicationPayorInitial'
                  "
                  >3rd Party Recall For Initial Payment</a
                >
                <a
                  ngbNavLink
                  *ngIf="
                    recall.contactType === 'Payment.ApplicationPayorMonthly'
                  "
                  >3rd Party Recall For Recurring Payment</a
                >

                <ng-template ngbNavContent>
                  <div class="mt-3 card pl-4 pr-4 p-2 rounded graybg-ushg">
                    <div class="row">
                      <div class="col-9 row">
                        <div class="col-4 p-2">
                          Creator :
                          <b>{{ (recall?.questionList)[0]?.createdBy }}</b>
                        </div>
                        <div class="col-4 p-2">
                          Recall Status :
                          <b *ngIf="recall.status === '-'">Pending</b
                          ><b>{{ recall.status }}</b>
                        </div>
                        <div class="col-4 p-2">Scheduled : <b>NA</b></div>
                        <div class="col-4 p-2">
                          Created : <b>{{ recall.created }}</b>
                        </div>
                        <div class="col-4 p-2">Last Updated : <b>NA</b></div>
                      </div>
                      <div
                        class="col-3 d-flex align-items-center justify-content-end"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-danger btn-sm btn-prop"
                          (click)="deleteRecall(recall)"
                          *ngIf="
                            recall.status === '-' || recall.status === 'unsaved'
                          "
                        >
                          Delete Recall
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm ml-3 btn-prop"
                          (click)="scheduleNow(recall)"
                          [disabled]="scheduleButtonDisabled"
                          *ngIf="
                            (recall.status === '-' ||
                              recall.status === 'unsaved') &&
                            recall.questionList.length
                          "
                        >
                          Schedule Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="pl-4 pr-4 mt-3">
                    <div class="row">
                      <div class="row head col-10"></div>
                      <div
                        class="add-question col-2 d-flex align-items-center justify-content-end"
                        [appPermission]="'CanCreateRecall'"
                      >
                        <button
                          type="button"
                          class="btn btn-primary btn-sm px-4 add-question-button"
                          (click)="addQuestion(recall)"
                          [appPermission]="'CanCreateRecall'"
                        >
                          Add Question
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- user -->
                  <nav
                    ngbNav
                    #navUser="ngbNav"
                    class="nav-tabs ml-4"
                    [activeId]="
                      tabsPersistenceService.getStoredOrDefault(
                        recall.id + '-selectedUser'
                      )
                    "
                  >
                    <ng-container
                      *ngFor="let user of getUniqueUsers(recall); let i = index"
                    >
                      <li
                        [ngbNavItem]="i + 1"
                        (click)="
                          saveSelection(recall.id + '-selectedUser', i + 1)
                        "
                      >
                        <a ngbNavLink>{{
                          getApplicantNameFromClientNo(user, recall)
                        }}</a>
                        <ng-template ngbNavContent>
                          <div class="active-recalls mt-3">
                            <ng-container
                              *ngFor="
                                let question of recall.questionList;
                                let j = index
                              "
                            >
                              <ng-container *ngIf="user === question.clientNo">
                                <div class="d-flex mb-1 mt-2 ml-4">
                                  <div
                                    class="background-gray-usg rounded question-text p-3"
                                    [innerHTML]="question.questionText"
                                  ></div>
                                  <div class="d-flex flex-column border p-1">
                                    <i
                                      class="fa fa-edit edit-icon p-1 cursor-pointer text-center"
                                      (click)="
                                        editQuestion(question, j, recall)
                                      "
                                      [appPermission]="
                                        'CanAccessRecallUpdateQuestions'
                                      "
                                    ></i>
                                    <hr class="hr-usg" />
                                    <i
                                      class="far fa-trash-alt text-danger p-1 cursor-pointer text-center"
                                      [ngClass]="{
                                        disabled:
                                          recall.status === 'Scheduled' &&
                                          recall.questionList.length === 1
                                      }"
                                      (click)="
                                        deleteQuestion(question, j, recall)
                                      "
                                      [appPermission]="
                                        'CanAccessRecallDeleteQuestions'
                                      "
                                    ></i>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </ng-template>
                      </li>
                    </ng-container>
                  </nav>
                  <div [ngbNavOutlet]="navUser" class="mt-1"></div>
                </ng-template>
              </li>
            </ng-container>
          </nav>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
      <!-- call types -->
      <div
        class="d-flex p-2 flex-row align-items-center"
        *ngIf="getUniqueUsersFromCompletedRecalls().length > 0"
      >
        <hr class="hr-usg" />
        <b class="text-nowrap ml-2 mr-2">Completed Recalls</b>
        <hr class="hr-usg" />
      </div>
      <!-- Completed calls -->
      <nav
        ngbNav
        #navCompletedUser="ngbNav"
        class="nav-tabs"
        [activeId]="
          tabsPersistenceService.getStoredOrDefault(
            this.applicationId + '-selectedUserOfCompleteRecall'
          )
        "
      >
        <ng-container
          *ngFor="
            let user of getUniqueUsersFromCompletedRecalls();
            let i = index
          "
        >
          <li
            [ngbNavItem]="i + 1"
            (click)="
              saveSelection(
                this.applicationId + '-selectedUserOfCompleteRecall',
                i + 1
              )
            "
          >
            <a ngbNavLink>{{ user }}</a>
            <ng-template ngbNavContent>
              <div class="completed-recalls mt-3 background-gray-usg">
                <ng-container
                  *ngFor="
                    let completedCall of vm.completedRecalls;
                    let j = index
                  "
                >
                  <ng-container
                    *ngFor="let question of completedCall.questionList"
                  >
                    <ng-container *ngIf="user.includes(getNameKey(question))">
                      <div class="question-text p-3">
                        <b>Q) &nbsp;&nbsp;</b
                        ><b [innerHTML]="question.prompt"></b>
                        <i
                          class="fas fa-info-circle pl-2"
                          data-html="true"
                          data-toggle="popover"
                          data-trigger="hover"
                          title=""
                          [attr.data-content]="
                            '<b>Created Date : </b>' +
                            question.created +
                            '<br>' +
                            '<b>Created By : </b>' +
                            question.createdBy +
                            '<br>' +
                            '<b>Appointment Id : </b>' +
                            completedCall.appointmentId +
                            '<br>' +
                            '<b>Question Reason : </b>' +
                            question.recallReason +
                            '<br>' +
                            '<b>Resource Id : </b>' +
                            getFilteredAppointment(completedCall.appointmentId)
                              ?.resourceId +
                            '<br>' +
                            '<b>Scheduled Date : </b>' +
                            [
                              getFilteredAppointment(
                                completedCall.appointmentId
                              )?.scheduledDate
                                | datewithTimeZone : timeZone : 'date-time'
                                | uppercase
                            ]
                          "
                        ></i>

                        <br />
                        <b>A : &nbsp;</b>
                        <p
                          [ngClass]="{
                            'text-danger': question.ignored
                          }"
                          class="d-inline-flex"
                        >
                          {{
                            question.ignored
                              ? "This question does not apply"
                              : question.recallAnswer === null
                              ? "N/A"
                              : question.recallAnswer
                          }}
                        </p>
                        <br />
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-template>
          </li>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="navCompletedUser" class="mt-1"></div>
      <!-- Completed calls -->
    </div>
  </ng-container>
  <app-modal
    [open]="openModal"
    [modalOptions]="{ size: 'lg' }"
    [footer]="showFooter"
    [buttonsConfig]="{
      position: 'center',
      modalMode: 'form',
      buttonSize: 'large',
      submitButtonName:
        saveActionType === 'Save' ? 'Save' : 'Save and Schedule',
      cancelButtonName: 'Cancel'
    }"
    class="border-bottom-0"
    [formValidation]="
      recallAppointmentData?.reason &&
      recallAppointmentData?.applicantName &&
      listofAddedQuestions.length >= 1
    "
    (actions)="SubmitAndCancelHandler($event)"
  >
    <div title class="row justify-content-center ml-3">
      <ng-container *ngIf="editQuestionScript === true; else elseBlock">
        Edit Question
      </ng-container>

      <ng-template #elseBlock>
        <h6>Add Question</h6>
      </ng-template>
    </div>
    <div body class="px-3">
      <ng-container *ngIf="editQuestionScript === false; else editElseBlock">
        <app-recall-addquestion
          [recallApplicants]="recallApplicants"
          (listOfQuestions$)="saveListOfAddedQuestions($event)"
          (formData$)="saveFormData($event)"
          [clearData]="clearData"
        ></app-recall-addquestion>
      </ng-container>
      <ng-template #editElseBlock>
        <form [formGroup]="addQuestionForm">
          <div class="row">
            <div class="pt-4 col-sm-6 pl-0">
              <label class="label-txt">Recall Reason</label>
              <select
                class="form-control form-control-sm"
                formControlName="recallReason"
              >
                <option
                  *ngFor="let item of reasonsList; index as i"
                  [ngValue]="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div *ngIf="recallBeingEdited" class="pt-4 col-sm-6 pr-0">
              <label class="label-txt">Select Applicant</label>
              <select
                class="form-control form-control-sm"
                formControlName="applicant"
              >
                <option [ngValue]="null"></option>
                <option
                  class="text-uppercase"
                  *ngFor="let applicant of recallApplicants"
                  [ngValue]="applicant"
                >
                  {{ applicant.fullName }}( {{ applicant.relationship }} )
                </option>
              </select>
            </div>
          </div>

          <label class="mt-3 label-txt">Question script</label>
          <textarea
            class="form-control"
            cols="30"
            rows="7"
            formControlName="questionText"
          ></textarea>
          <div class="row justify-content-center m-0 mt-4 mb-2">
            <button
              type="button"
              class="btn btn-outline-dark btn-prop"
              (click)="cancel()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary ml-5 btn-prop"
              [ngClass]="{ 'disable-btn': addQuestionForm.invalid }"
              [disabled]="addQuestionForm.invalid"
              (click)="saveQuestion()"
            >
              {{ editQuestionScript === true ? "Update" : "Add" }}
            </button>
          </div>
        </form>
      </ng-template>
    </div>
  </app-modal>
  <!-- recall history details ends here -->
  <!-- for delete -->
  <app-modal
    [open]="openDeleteModal"
    [buttonsConfig]="{
      modalMode: 'form',
      submitButtonName: 'Confirm',
      cancelButtonName: 'Cancel'
    }"
    (actions)="confirmDelete($event)"
  >
    <div title>Confirmation</div>
    <div body>
      <div class="pl-2">Are you sure of deleting this Question ??</div>
    </div>
  </app-modal>
  <!-- for delete -->
  <!-- for recall view -->
  <app-modal
    [open]="openViewModal"
    [modalOptions]="{ size: 'lg' }"
    (actions)="getNotifyEvent()"
    [footer]="false"
    [bgClass]="true"
  >
    <div title class="text-center">Response View</div>
    <div body>
      <div class="container mb-4 bg-white p-3 rounded">
        <div class="row">
          <div class="col-sm">
            <h6>Reason</h6>
            <p>{{ recallResp?.recallReason }}</p>
          </div>
          <div class="col-sm">
            <h6>Applicant</h6>
            <p>{{ recallResp?.applicantName }}</p>
          </div>
          <div class="col-sm">
            <h6>Status</h6>
            <p>{{ recallResp?.status }}</p>
          </div>
          <div class="col-sm">
            <h6>Date</h6>
            <p>{{ recallResp?.created }}</p>
          </div>
        </div>
      </div>
      <div class="bg-white p-3 rounded">
        <div class="media mb-4">
          <h6 class="align-self-start col-2">Question</h6>
          <div class="media-body">{{ recallResp?.questionText }}</div>
        </div>
        <div class="media">
          <h6 class="align-self-start col-2">Answer</h6>
          <div class="media-body">{{ recallResp?.recallAnswer || "N/A" }}</div>
        </div>
      </div>
      <div class="row justify-content-center m-0 mt-4 mb-2">
        <button
          type="button"
          class="btn btn-outline-dark btn-prop"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </app-modal>
  <!-- for delete recall start-->
  <app-modal
    [open]="deleteRecallModal"
    [modalOptions]="{ size: 'md' }"
    [footer]="false"
    [buttonsConfig]="{
      modalMode: 'form'
    }"
  >
    <div title class="text-center p-2">Delete Recall</div>
    <div body class="text-center">
      <div class="pl-2 text-secondary">
        <p class="p-2">
          This will delete all the questions permanently from the recall
        </p>
        <p class="p-3">Could you confirm to Delete this "RECALL"?</p>
      </div>
      <div class="row justify-content-center m-0 mt-4 mb-4">
        <button
          type="button"
          class="btn btn-outline-dark btn-prop"
          (click)="cancel()"
        >
          No
        </button>
        <button
          type="button"
          class="btn btn-primary btn-prop ml-3"
          (click)="confirmDeleteRecall()"
        >
          Yes
        </button>
      </div>
    </div>
  </app-modal>
  <!-- for delete recall end-->
</div>
