import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Appointment } from 'src/app/shared/models/appointment';
import { RecallQuestion } from 'src/app/shared/models/recall/RecallQuestion';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';

@Component({
  selector: 'app-recall-questions',
  templateUrl: './recall-questions.component.html',
  styleUrls: ['./recall-questions.component.scss'],
})
export class RecallQuestionsComponent implements OnInit, OnDestroy {
  recallHisList: any;
  selectedAppointment: Appointment;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  appId: string;
  questionList: Array<RecallQuestion> = new Array<RecallQuestion>();

  constructor(
    private appService: ApplicationService,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe((a) => {
        this.selectedAppointment = a;
        this.process();
      })
    );
    this.subscriptions.push(
      this.appService.ApplicationRecalls.subscribe((res) => {
        if (res) {
          this.recallHisList = res;
          this.process();
        }
      })
    );
  }

  process() {
    if (this.recallHisList && this.selectedAppointment) {
      const filteredList = this.recallHisList
        .filter(
          (el) => el.appointmentId === this.selectedAppointment.appointmentId
        )
        .shift();
      if (filteredList) {
        this.questionList = filteredList.questionList;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
