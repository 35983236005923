import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { PermissionService } from '../../services/permission/permission.service';
import { Permission } from '../../models/vcall/permission';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective implements OnInit {
  @Input() appPermission: Permission;
  constructor(
    private el: ElementRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    // const isPermitted = this.permissionService.HasPermission(
    //   this.appPermission
    // );
    // if (!isPermitted) {
    //   this.el.nativeElement.style.display = 'none';
    // }

    /**
     * @description This service is check whether the permission is there or not
     */
    this.permissionService
      .IsPermitted(this.appPermission)
      .subscribe((permitted) => {
        if (!permitted) {
          this.el.nativeElement.style.display = 'none';
        } else {
          this.el.nativeElement.style.display = '';
        }
      });
  }
}
