import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Answer } from '../../../models/application/answer';
import { Application } from '../../../models/application/application';
import { Question } from '../../../models/vcall/question';
import { AnswerTagFilter } from './filters/answer-tag-filter';
import { ApplicationAttributeFilter } from './filters/application-attribute-filter';
import { BaseFilter } from './filters/base-filter';
import { QualifiedQuestionFilter } from './filters/qualified-question-filter';
import { PendingAnswerFilter } from './filters/pending-answer-filter';
import { AttributeComparisonFilter } from './filters/attribute-comparison-filter';
import { StudentFilter } from './filters/student-filter';
import { Script } from 'src/app/shared/models/vcall/script';
import { ExpressQuestionFilter } from './filters/express-question-filter';
import { GroupAnswerTagFilter } from './filters/group-answer-tag-filter';
import { RxProfileAllApplicants } from '../applicant-filter/filters/rx-profile-filter';
import { SameLastNameFilter } from './filters/same-last-name-filter';
import { DependentLastNameFilter } from './filters/dependent-last-name-filter';
import { ListFilter } from './filters/list-filter';
import { ApplicantQuestionGroupFilter } from './filters/applicant-group-filter';

@Injectable({
  providedIn: 'root',
})
export class QuestionFilterService {
  private filters: Array<BaseFilter> = new Array<BaseFilter>();
  private fixedFilters: Array<KeyValue<string, FilterConfiguration>>;

  constructor() {
    this.filters.push(new ApplicationAttributeFilter());
    this.filters.push(new AnswerTagFilter());
    this.filters.push(new PendingAnswerFilter());
    this.filters.push(new QualifiedQuestionFilter());
    this.filters.push(new AttributeComparisonFilter());
    this.filters.push(new StudentFilter());
    this.filters.push(new ExpressQuestionFilter());
    this.filters.push(new GroupAnswerTagFilter());
    this.filters.push(new RxProfileAllApplicants());
    this.filters.push(new SameLastNameFilter());
    this.filters.push(new DependentLastNameFilter());
    this.filters.push(new ListFilter());
    this.filters.push(new ApplicantQuestionGroupFilter());
    this.fixedFilters = this.DefaultFilters();
  }

  /**
   * @param parameters question filter parameters
   * @description question filter
   */
  public Apply(parameters: FilterParameter) {
    let filterMode = FilterMode.AND;

    let configuredFilters: FilterConfiguration[] =
      new Array<FilterConfiguration>();

    if (parameters.question.displayWhen !== null) {
      const displayWhenObject = JSON.parse(parameters.question.displayWhen);
      let displayWhen: DisplayWhen = null;

      // displayWhen could just be a list of filters.
      if (Array.isArray(displayWhenObject)) {
        displayWhen = { mode: FilterMode.AND, filters: displayWhenObject };
      } else {
        // displayWhen could be of the format {mode : <mode> , filters : <filters>}
        displayWhen = displayWhenObject;
      }

      // if mode not defined AND is the default
      if (displayWhen.mode === undefined || displayWhen.mode === null) {
        displayWhen.mode = FilterMode.AND;
      }

      configuredFilters = displayWhen.filters;
      filterMode = displayWhen.mode;
    }

    // To avoid add configuration for a question type which is always dependent on
    // certain condition. e.g. Ailment always assumes that a Qualifying Question is present
    if (this.fixedFilters.some((f) => f.key === parameters.question.type)) {
      configuredFilters = [
        ...configuredFilters,
        ...this.fixedFilters
          .filter((f) => f.key === parameters.question.type)
          .map((f) => f.value),
      ];
    }

    if (parameters.mode === 'recall') {
      configuredFilters.push({
        type: 'PendingAnswer',
        path: null,
        value: null,
      });
    }

    let result: FilterResult[];
    if (configuredFilters.length === 0) {
      return true;
    }
    result = configuredFilters.map((cf) => {
      const typeFilter = this.filters.find((ff) => ff.Type === cf.type);
      if (typeFilter) {
        parameters.config = cf;
        return typeFilter.Apply(parameters);
      } else {
        return { type: cf.type, canDisplay: true };
      }
    });

    if (
      filterMode === FilterMode.AND ||
      filterMode === FilterMode.INVERTED_AND
    ) {
      const displayWhen = result.every((r) => r.canDisplay === true);
      return filterMode === FilterMode.AND ? displayWhen : !displayWhen;
    } else if (
      filterMode === FilterMode.OR ||
      filterMode === FilterMode.INVERTED_OR
    ) {
      const displayWhen = result.some((r) => r.canDisplay === true);
      return filterMode === FilterMode.OR ? displayWhen : !displayWhen;
    }
  }

  /**
   * @description default filter
   */
  DefaultFilters(): Array<KeyValue<string, FilterConfiguration>> {
    return [
      {
        key: 'MedicalAilment',
        value: { type: 'QualifiedQuestion', path: null, value: null },
      },
      {
        key: 'MedicalAilment',
        value: { type: 'ExpressQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedTextBox',
        value: { type: 'ExpressQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedTextBox',
        value: { type: 'QualifiedQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedTextBox',
        value: { type: 'ApplicantGroupFilter', path: null, value: null },
      },
      {
        key: 'MedicalQualifying',
        value: { type: 'ExpressQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedBoolean',
        value: { type: 'QualifiedQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedInputText',
        value: { type: 'QualifiedQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedOccupation',
        value: { type: 'QualifiedQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedDoctor',
        value: { type: 'QualifiedQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedMedication',
        value: { type: 'QualifiedQuestion', path: null, value: null },
      },
      {
        key: 'ApplicantQualifiedMedication',
        value: { type: 'ExpressQuestion', path: null, value: null },
      },
    ];
  }
}

export interface FilterParameter {
  question: Question;
  application: Application;
  answers: Answer[];
  config?: FilterConfiguration;
  applicant?: Applicant;
  filterJson?: string;
  mode?: string;
  script?: Script;
}

export interface FilterResult {
  type: string;
  canDisplay: boolean;
}

export interface FilterConfiguration {
  type: string;
  path: string;
  value: string;
  comparisonValue?: string;
  params?: KeyValue<string, string>[];
}

export enum FilterMode {
  OR = 'OR',
  AND = 'AND',
  INVERTED_OR = 'INVERTED_OR',
  INVERTED_AND = 'INVERTED_AND',
}

export interface DisplayWhen {
  mode?: FilterMode;
  filters: FilterConfiguration[];
}
