import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ManageRecallComponent } from './manage-recall/manage-recall.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecallAddquestionComponent } from './recall-addquestion/recall-addquestion.component';
const routes: Routes = [
  {
    path: 'managerecall/:applicationId',
    component: ManageRecallComponent,
  },
  {
    path: 'managerecall',
    component: ManageRecallComponent,
  },
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgSelectModule,
    NgbTooltipModule,
    SharedModule,
    NgbNavModule,
  ],
  exports: [ManageRecallComponent],
  declarations: [ManageRecallComponent],
})
export class RecallModule {}
