export class DefaultApplicantQualifiedQuestionDetail {
  answerTag: string;
  applicantFilterValue: boolean;
  textboxTitle: string;
}

export class ApplicantQualifiedBooleanTextboxDetail extends DefaultApplicantQualifiedQuestionDetail {
  yesText: string;
  noText: string;
  yesValue: string;
  noValue: string;
  instructionsIfYes: boolean;
  mode: string;
  allowNextForValue: string;
}
export class ApplicantQualifiedInputGeneric extends DefaultApplicantQualifiedQuestionDetail {
  minLength: number;
  maxLength: number;
  maxValue: number;
  inputType: string;
}
export class ApplicantQualifiedDropDownGeneric extends DefaultApplicantQualifiedQuestionDetail {
  label1: string;
  label2: string;
}
