import { Component, OnInit } from '@angular/core';
import { Appointment } from '../shared/models/appointment';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent {
  pickedAppointment: Appointment;

  appointmentChanged(appt: Appointment) {
    this.pickedAppointment = appt;
  }
}
