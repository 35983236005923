import { Injectable } from '@angular/core';
import { HandledHttpClient } from '../api/handledHttpClient';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchApiService {
  private baseUrl: string;

  constructor(private http: HandledHttpClient) {
    this.baseUrl = environment.config.apis.search;
  }
  /**
   *
   * @param keyword searchtext
   * get matched questions as group by searchText From QuestionBank
   */
  getDrillDownQuestions<T>(keyword) {
    return this.http.get<T[]>(this.baseUrl + '/search/v1/sections/' + keyword, {
      errorMessage: `Error retrieving application from the service for keyword = '${keyword}'`,
    });
  }

  /**
   *
   * @param keyword searchText
   * get matched individual questions by entered keyword from question bank
   */
  getKeyWordQuestions<T>(keyword) {
    return this.http.get<T[]>(
      this.baseUrl + '/search/v1/questions/' + keyword,
      {
        errorMessage: `Error retrieving application from the service for keyword = '${keyword}'`,
      }
    );
  }
}
