import { RecallInfo } from '../recall/RecallInfo';
import { Application } from './application';

export class DialingInformationModel {
  constructor(options: {} = null) {
    Object.assign(this, options);
  }
  callType: string;
  homePhone: string;
  workPhone: string;
  mobilePhone: string;
  businessPhone: string;
  businessName: string;
  businessOwner: string;
  application: Application;

  getDialingInformation(callType, application, contactType) {
    if (callType !== RecallInfo.callType) {
      this.homePhone = application.contact.homePhone;
      this.workPhone = application.contact.workPhone;
      this.mobilePhone = application.contact.mobilePhone;
    } else if (
      callType === RecallInfo.callType &&
      contactType === RecallInfo.applicant
    ) {
      this.homePhone = application.contact.homePhone;
      this.workPhone = application.contact.workPhone;
      this.mobilePhone = application.contact.mobilePhone;
    } else if (
      callType === RecallInfo.callType &&
      contactType !== RecallInfo.applicant
    ) {
      const payment = application.payment;
      if (contactType === RecallInfo.applicationPayorMonthly) {
        this.businessName = payment.businessNameMonthlyOnline
          ? payment.businessNameMonthlyOnline
          : payment.businessNameMonthlyOffline;
        this.businessOwner = payment.businessOwnerMonthlyOnline
          ? payment.businessOwnerMonthlyOnline
          : payment.businessOwnerMonthlyOffline;
        this.businessPhone = payment.billingOfflinePhone;
      } else {
        this.businessName = payment.businessNameInitialOnline
          ? payment.businessNameInitialOnline
          : payment.businessNameInitialOffline;
        this.businessOwner = payment.businessOwnerInitialOnline
          ? payment.businessOwnerInitialOnline
          : payment.businessOwnerInitialOffline;
        this.businessPhone = payment.billingOfflinePhone;
      }
    }
    return this;
  }
}
