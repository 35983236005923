import { IName } from '../../interfaces/IName';
import { DecoratedModelConfiguration } from './decoratedModelConfiguration';
import { Discoverable } from './discoverable';
import { DiscoverableChainItem } from './discoverableChainItem';
import { PropertyDecorator } from '../../services/application/modelMapperService.service';
import moment from 'moment';

export class Applicant implements IName, Discoverable {
  chain: DiscoverableChainItem[];

  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'relationship')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'relationship')
  relationship: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'height')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'height')
  height: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'weight')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'weight')
  weight: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'ssn')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'ssn')
  ssn: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'gender')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'gender')
  gender: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'birthDate')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'birthDate')
  birthDate: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'birthState')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'birthState')
  birthState: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'birthCountry')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'birthCountry')
  birthCountry: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'employer')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'employer')
  employer: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'occupation')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'occupation')
  occupation: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'clientNo')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'clientNo')
  @PropertyDecorator(DecoratedModelConfiguration.IsKey, true)
  clientNo: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'firstName')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'firstName')
  firstName: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'middleName')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'middleName')
  middleName: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'lastName')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'lastName')
  lastName: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'businessName')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'businessName')
  businessName: string;
  isRxAuthorized: boolean;

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get age(): number {
    return Math.floor(
      (+new Date() - +new Date(this.birthDate)) / (1000 * 60 * 60 * 24 * 365.25)
    );
  }

  get ageInDays(): number {
    const today = moment();
    return today.diff(this.birthDate, 'days');
  }

  static DefaultInstance() {
    const i = new Applicant();
    i.firstName = '';
    i.lastName = '';
    i.relationship = '';
    i.clientNo = '';
    i.employer = '';
    i.gender = '';
    i.height = '';
    i.middleName = '';
    i.occupation = '';
    i.relationship = '';
    i.ssn = '';
    i.weight = '';

    return i;
  }
}
