import { Injectable } from '@angular/core';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';
import { CopyAnswerTagProcessor } from './copyAnswerTagProcessor';

@Injectable({
  providedIn: 'root',
})
export abstract class CopyPaymentPayorInformationProcessor
  extends CopyAnswerTagProcessor
  implements Processor {
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'CopyPayorInformation';
  questionTags = [
    { tagFrom: 'payment.nameOnCheck', tagTo: 'payment.initPmtBankNameOnCheck' },
    {
      tagFrom: 'payment.addressOnCheck',
      tagTo: 'payment.initPmtBankAddressOnCheck',
    },
    { tagFrom: 'payment.cityOnCheck', tagTo: 'payment.initPmtBankCityOnCheck' },
    {
      tagFrom: 'payment.stateOnCheck',
      tagTo: 'payment.initPmtBankStateOnCheck',
    },
    { tagFrom: 'payment.zipOnCheck', tagTo: 'payment.initPmtBankZipOnCheck' },
    {
      tagFrom: 'payment.phoneOnCheck',
      tagTo: 'payment.initPmtBankPhoneOnCheck',
    },
  ];

  Process(model: ProcessorModel): Promise<ProcessingResult> {
    this.questionTags.every((tag) => {
      return this.copy(tag.tagFrom, tag.tagTo);
    });
    return;
  }
}
