import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { BaseQuestionType } from '../baseQuestionType';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { AilmentDashboardModel } from 'src/app/shared/models/application/ailmentDashboard';
import { Answer } from 'src/app/shared/models/application/answer';
import { KeyValue } from '@angular/common';

export enum KEY_CODE {
  YES = 'KeyY',
  NO = 'KeyN',
}
@Component({
  selector: 'app-question-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.css'],
})
export class VcallQuestionTypeBooleanComponent extends BaseQuestionType<VcallQuestionTypeBooleanDetail> {
  question: Question = null;
  details: VcallQuestionTypeBooleanDetail =
    new VcallQuestionTypeBooleanDetail();
  subscriptions: Array<Subscription> = new Array<Subscription>();
  elementQuestionIdTrackerId: number;
  answerList: any;
  ailmentDashboardModel: AilmentDashboardModel = new AilmentDashboardModel();
  @Output() selected = new EventEmitter();
  @ViewChild('booleanyes') yesSelection: ElementRef;
  @ViewChild('booleanno') noSelection: ElementRef;

  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService
  ) {
    super(script, appService, ui);
  }

  ResetView() {
    this.yesSelection.nativeElement.checked = false;
    this.noSelection.nativeElement.checked = false;

    if (this.answer) {
      this.yesSelection.nativeElement.checked =
        this.answer.value.toString() === 'true';
      this.noSelection.nativeElement.checked = !(
        this.answer.value.toString() === 'true'
      );
    }
  }

  ApplicationChanged(): void {}

  QuestionChanged(q: Question): void {
    // this.ResetView();
  }

  CanGoNext(): boolean {
    return true;
  }

  // Next and previous buttons with keyboard navigation
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const bodyElement = document.querySelector('body');
    if (bodyElement && !bodyElement.classList.contains('modal-open')) {
      if (event.code === KEY_CODE.YES) {
        if (
          'INPUT' !== document.activeElement.tagName &&
          'TEXTAREA' !== document.activeElement.tagName
        ) {
          this.yesSelection.nativeElement.checked = true;
          this.optionChanged('true');
        }
      }
      if (event.code === KEY_CODE.NO) {
        if (
          'INPUT' !== document.activeElement.tagName &&
          'TEXTAREA' !== document.activeElement.tagName
        ) {
          this.noSelection.nativeElement.checked = true;
          this.optionChanged('false');
        }
      }
    }
  }

  optionChanged(answer: any) {
    this.selected.emit(answer);
  }

  GetText(): string {
    return this.question.text || this.appService.getQuestionText(this.question);
  }

  getDisplayText(): string {
    return this.appService.getQuestionText(this.question);
  }

  updateCheckedState() {
    if (this.noSelection.nativeElement.checked === true) {
      this.noSelection.nativeElement.checked = false;
      this.yesSelection.nativeElement.checked = true;
    } else {
      this.noSelection.nativeElement.checked = true;
      this.yesSelection.nativeElement.checked = false;
    }
  }
}

export class VcallQuestionTypeBooleanDetail {
  required: boolean;
  yesText: string;
  noText: string;
  yesValue: string;
  noValue: string;
  instructionsIfNo: string;
  instructionsIfYes: string;
  fullForm: string;
  phone: string;
}
