<div class="row" *ngIf="(addendumArray | keyvalue)?.length; else NoData">
  <div class="p-3 col-3 pl-5" *ngFor="let item of addendumArray | keyvalue">
    <div>
      <div class="p-3 font-weight-bold">{{ getNameForClientNo(item.key) }}</div>
      <div class="list-group row pl-4">
        <a
          class="list-group-item list-group-item-action flex-column align-items-start col-11 p-2"
          *ngFor="let val of item.value"
        >
          <div *ngFor="let list of val" class="condition pb-3">
            <div
              class="d-flex w-100 justify-content-between"
              *ngFor="let notes of list | keyvalue"
            >
              <p class="mb-1">
                <span class="font-weight-bold">{{ notes.key }} : </span
                ><span>{{ notes.value }}</span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<ng-template #NoData>
  <div class="p-5">
    <div class="no-data-available-info m-1">
      No Data Available
    </div>
  </div>
</ng-template>
