import { Component, OnInit } from '@angular/core';
import { Application } from '../../models/application/application';
import { ApplicationService } from '../../services/application/application.service';

@Component({
  selector: 'app-application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.scss'],
})
export class ApplicationInfoComponent implements OnInit {
  constructor(private service: ApplicationService) {}

  application: Application;

  ngOnInit() {
    this.service.SelectedApplication.subscribe((a) => (this.application = a));
  }
}
