import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AddonApiService } from 'src/app/shared/services/api/addon/addon-api.service';
import { AddOnApplication } from './addon-application';

@Injectable({
  providedIn: 'root',
})
export class AddOnsService {
  getAddOnApplicationLogs = new BehaviorSubject(null);
  addOrEditApplicant = new BehaviorSubject(null);
  constructor(private addonApiService: AddonApiService) {}

  addOnApplication(application: AddOnApplication) {
    return new Promise<any>((resolve, reject) => {
      this.addonApiService
        .insertAddOnApplication(application)
        .subscribe((res) => {
          resolve(res);
          if (res && !res.hasOwnProperty('error')) {
            this.getAddOnApplications(res['appId']);
          }
        });
    });
  }

  getAddOnApplications(applcationId: number) {
    this.addonApiService.getAddOnApplication(applcationId).subscribe((res) => {
      this.getAddOnApplicationLogs.next(res);
    });
  }

  insertApplicant(id: string, applicant: any) {
    return new Promise<any>((resolve, reject) => {
      this.addonApiService
        .insertAddOnApplicant(id, applicant)
        .subscribe((res) => {
          resolve(res);
          if (res && !res.hasOwnProperty('error')) {
            this.getAddOnApplications(res['appId']);
          }
        });
    });
  }

  updateApplicant(id: string, applicantId: string, applicant: any) {
    return new Promise<any>((resolve, reject) => {
      this.addonApiService
        .updateAddOnApplicant(id, applicantId, applicant)
        .subscribe((res) => {
          resolve(res);
          if (res && !res.hasOwnProperty('error')) {
            this.getAddOnApplications(res['appId']);
          }
        });
    });
  }

  deleteApplicant(id: string, applicantId: string) {
    return new Promise<any>((resolve, reject) => {
      this.addonApiService
        .deleteAddOnApplicant(id, applicantId)
        .subscribe((res) => {
          resolve(res);
          if (res && !res.hasOwnProperty('error')) {
            this.getAddOnApplications(res['appId']);
          }
        });
    });
  }

  formatString(value) {
    let str = value.split('').filter(el => (el != '\\')).join('');
    const newValue = str.substring(0, 2);
    const remainValue = str.substring(2);
    return `${newValue}\\\\${remainValue}`
  }
}
