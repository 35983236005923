export class PaymentValue {
  verified: boolean;
  value: string;
  option: string;
  constructor() {}
}
export class PaymentAnswers {
  bankName: PaymentValue;
  routingNumber: PaymentValue;
  accountNumber: PaymentValue;
  initialPaymentBy: PaymentValue;
  effectiveDate: PaymentValue;
  monthlyAuthorization: PaymentValue;
  initPmtBankRoutingCode: PaymentValue;
  initPmtBdAccountNumber: PaymentValue;
  initPmtBankName: PaymentValue;
  constructor() {
    this.bankName = new PaymentValue();
    this.routingNumber = new PaymentValue();
    this.accountNumber = new PaymentValue();
    this.initialPaymentBy = new PaymentValue();
    this.effectiveDate = new PaymentValue();
    this.monthlyAuthorization = new PaymentValue();
    this.initPmtBankRoutingCode = new PaymentValue();
    this.initPmtBdAccountNumber = new PaymentValue();
    this.initPmtBankName = new PaymentValue();
  }
}
