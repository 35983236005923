<form [formGroup]="uspsAddress">
  <div
    *ngIf="
      uspsAddress.errors?.isAddressValid &&
      (uspsAddress.touched || uspsAddress.dirty)
    "
    class="alert alert-warning small"
  >
    Address is invalid, please make sure you entered correct Street Address,
    Zip, City and State.
  </div>
  <div class="form-group">
    <label for="streetAddress"
      >Street Address <span class="text-danger">*</span></label
    >
    <div class="input-group">
      <input
        [formControlName]="'streetAddress'"
        required
        (change)="formCompleted()"
        class="form-control"
        autocomplete="disabled"
      />
    </div>
    <div
      *ngIf="
        streetAddress.invalid &&
        (streetAddress.dirty || streetAddress.touched || saveClicked)
      "
      class="mt-1 text-danger small"
    >
      <div *ngIf="streetAddress.errors.required">
        Street Address is required.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="zipCode">Zip Code <span class="text-danger">*</span></label>
        <div class="progress-icon input-group">
          <ng-template #rt let-r="result" let-t="term">
            <div *ngIf="r.zipCode">
              <ngb-highlight [result]="r.zipCode" [term]="t"></ngb-highlight>,
              <ngb-highlight [result]="r.city" [term]="t"></ngb-highlight>,
              <ngb-highlight [result]="r.state" [term]="t"></ngb-highlight>
            </div>
          </ng-template>
          <input
            type="text"
            minlength="5"
            maxlength="5"
            (change)="formIsValid()"
            [ngbTypeahead]="onZipChange"
            [class.is-invalid]="searchFailed"
            (selectItem)="selectedItem($event)"
            [inputFormatter]="formatter"
            [resultFormatter]="formatter"
            [resultTemplate]="rt"
            [formControlName]="'zipCode'"
            autocomplete="disabled"
            class="form-control"
          />
          <i *ngIf="searching" class="fas fa-spinner fa-spin text-primary"></i>
          <!-- 
                        pattern="\\d{5}"
                        pattern="^[0-9]*$" 
                    -->
        </div>
        <!-- <small *ngIf="searching" class="form-text text-muted"
          >searching...</small
        > -->
        <div
          class="invalid-feedback d-block"
          *ngIf="searchFailed && zipCode.valid"
        >
          Sorry, suggestions could not be loaded.
        </div>

        <div
          *ngIf="
            zipCode.invalid && (zipCode.dirty || zipCode.touched || saveClicked)
          "
          class="mt-1 text-danger small"
        >
          <div *ngIf="zipCode.errors.required">
            Zip Code is required.
          </div>
          <div *ngIf="zipCode.errors?.minlength || zipCode.errors?.maxlength">
            Zip Code must be 5 digits.
          </div>
          <div *ngIf="zipCode.errors.pattern">
            Please enter number.
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="city">City <span class="text-danger">*</span></label>
        <div class="input-group">
          <input
            class="form-control"
            [formControlName]="'city'"
            (change)="formCompleted()"
            autocomplete="disabled"
            [readonly]="!cityEditable"
          />
          <div class="input-group-append" (click)="cityEditable = true">
            <span class="input-group-text btn btn-primary" id="basic-addon2"
              ><i class="fa fa-edit"></i
            ></span>
          </div>
        </div>
        <div
          *ngIf="city.invalid && (city.dirty || city.touched || saveClicked)"
          class="mt-1 text-danger small"
        >
          <div *ngIf="city.errors.required">
            City is required.
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="state">State <span class="text-danger">*</span></label>
        <div class="input-group">
          <!-- <input
            [formControlName]="'state'"
            (change)="formCompleted()"
            class="form-control"
            placeholder="State"
            autocomplete="disabled"
          /> -->
          <select
            [formControlName]="'state'"
            (change)="formCompleted()"
            class="form-control"
            [attr.disabled]="!stateEditable ? 'disabled' : null"
            autocomplete="disabled"
          >
            <option *ngFor="let opt of states" [value]="opt.state">{{
              opt.state
            }}</option>
          </select>
          <div class="input-group-append" (click)="stateEditable = true">
            <span class="input-group-text btn btn-primary" id="basic-addon2"
              ><i class="fa fa-edit"></i
            ></span>
          </div>
        </div>
        <div
          *ngIf="state.invalid && (state.dirty || state.touched || saveClicked)"
          class="mt-1 text-danger small"
        >
          <div *ngIf="state.errors.required">
            State is required.
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
