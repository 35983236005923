import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs/internal/Subscription';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Application } from 'src/app/shared/models/application/application';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import {
  AuditNote,
  OrderRequestDetails,
  OrderStatus,
  RequestType,
} from '../additional-info/additional-info-modal';
import { AdditionalInfoService } from '../additional-info/additional-info.service';
import { orderConfig } from '../create-order/create-order-config';
import { ApplicationStatus, ApplicationTrackerType } from '../../models/enum';
import { CstPipe } from 'src/app/shared/pipes/cst.pipe';
import { NbcApiService } from 'src/app/shared/services/nbc/nbc-api.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit, OnChanges {
  @Input() applicationTrackerId: number;
  @Output() emitActiveOrdersList = new EventEmitter();
  @Input() addonApsRequests: boolean;
  application: Application;
  activeOrdersList: OrderRequestDetails[] = [];
  completedOrdersList: OrderRequestDetails[] = [];
  applicants: Applicant[];
  subscriptions = new Array<Subscription>();
  openDeleteModal = false;
  deleteOrderRequest: OrderRequestDetails;
  canShowActionIcons = true;
  openPdfDocumentsModal = false;
  orderStatusList = orderConfig.orderStatus;
  orderRequests = orderConfig.orderRequests;
  profile: UserProfile;
  pdfDocumentsList;
  pdfUrl: string;
  openAddNotesModal = false;
  order: OrderRequestDetails;
  note = '';
  allNotes: AuditNote[];
  dueDate: Date;
  timeZone: string;
  orderLevelNotes: AuditNote[];
  assignedTo: string;
  pdfFile: any;
  downloadHtml: any;
  readonly defaultZoomLevel = '#zoom=150';

  constructor(
    private service: AdditionalInfoService,
    private appService: ApplicationService,
    private toaster: ToastService,
    private profileService: UserProfileService,
    private cstPipe: CstPipe,
    private nbcApiService: NbcApiService
  ) {}

  ngOnInit() {
    this.timeZone = this.profileService.getCallzoneUser().timeZone;
    this.subscriptions.push(
      this.appService.SelectedApplication.subscribe((res) => {
        if (res) {
          this.application = res;
          this.applicants = [res.primaryApplicant, ...res.family];
        }
      })
    );
    this.profileService.userProfile.subscribe((s) => {
      this.profile = s;
    });
    this.subscriptions.push(
      this.service.notes.subscribe((response) => {
        if (response) {
          this.allNotes = response.data.auditLogByApplicationId;
          this.orderLevelAuditNotes();
        }
      })
    );
    this.canShowIcons();
  }

  ngOnChanges() {
    this.canShowIcons();
  }

  filterOrderList(application) {
    const activeOrdersStatus = [
      OrderStatus.Open,
      OrderStatus.Inprogress,
      OrderStatus.Created,
      OrderStatus.Pending,
      OrderStatus.AppointmentMet,
    ];
    const completeOrdersStatus = [
      OrderStatus.Completed,
      OrderStatus.Closed,
      OrderStatus.Cancelled,
    ];
    const ordersList = application.orderDetails.filter(
      (o) => o.applicationTrackerId === this.applicationTrackerId
    );
    this.activeOrdersList = ordersList.filter((o) =>
      activeOrdersStatus.includes(o.orderStatusId)
    );

    this.completedOrdersList = ordersList.filter((o) =>
      completeOrdersStatus.includes(o.orderStatusId)
    );

    this.orderRequests.map((o) => {
      if (o.type === 'active') {
        o.list = this.activeOrdersList;
      } else {
        o.list = this.completedOrdersList;
      }
    });
    let applicationTracker;
    if (this.addonApsRequests) {
      applicationTracker = application.applicationTracker.find(
        (a) => a.id === this.applicationTrackerId
      );
    } else {
      applicationTracker = application.applicationTracker.find(
        (a) => a.applicationTrackerType === ApplicationTrackerType.Primary
      );
    }
    const { dueDate, assignedTo } = applicationTracker;
    this.dueDate = dueDate;
    this.assignedTo = assignedTo;

    this.canShowActionIcons =
      this.service.isApplicationStatusValid(applicationTracker);
    this.emitActiveOrdersList.next(this.activeOrdersList);
  }

  typeOf(value) {
    return typeof value;
  }

  getApplicantName(clientNo) {
    return this.applicants.filter((a) => a.clientNo === clientNo)[0]?.fullName;
  }

  getOrderStatus(statusId) {
    return this.orderStatusList.filter((a) => a.id === statusId)[0]?.value;
  }

  editOrder(order, index) {
    // to patch data from one child component to another child component
    this.service.editOrder(order, index);
    this.service.cancelRequest(true);
  }

  deleteOrder(order) {
    this.openDeleteModal = true;
    this.deleteOrderRequest = order;
  }
  confirm(action: any) {
    if (action === 'Done') {
      const deleteIndex = this.activeOrdersList.findIndex(
        (a) => a.id === this.deleteOrderRequest.id
      );
      this.activeOrdersList.splice(deleteIndex, 1);
      this.service.deleteOrderRequest(
        this.deleteOrderRequest,
        this.application.id
      );
    }
    this.openDeleteModal = false;
  }

  getDownloadHtml = (fileSasUrl: string, bytesLength: number) => {
    const dynamicHTML = `
      <!DOCTYPE html>
      <html>
        <body style="margin:10px;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'">
          File is too large to preview. Please <a href="${fileSasUrl}">Download</a> (${this.getBytesToMB(
      bytesLength
    )} MB)
        </body>
      </html>
    `;
    return `data:text/html;charset=utf-8,${encodeURIComponent(dynamicHTML)}`;
  };

  getViewHtml = (bytesLength: number) => {
    const dynamicHTML = `
      <!DOCTYPE html>
      <html>
      <body style="margin:10px;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'">
      Please wait for preview to load... (${this.getBytesToMB(bytesLength)} MB)
    </body>
      </html>
    `;
    return `data:text/html;charset=utf-8,${encodeURIComponent(dynamicHTML)}`;
  };

  getBytesToMB = (bytesLength) => {
    return (bytesLength / 1024 / 1024).toFixed(2);
  };

  viewOrderDocuments(order, document?) {
    this.openPdfDocumentsModal = true;
    this.downloadHtml = null;
    this.service
      .displayOrderDocuments(order.orderDocuments)
      .then((res: any) => {
        this.pdfDocumentsList = res.data.downloadOrderFiles.documents;
        let index = this.pdfDocumentsList.findIndex(
          (pdf) => pdf.name === document?.name
        );
        index = index === -1 ? 0 : index;
        this.pdfFile = this.pdfDocumentsList[index];
        const type = this.fileType(this.pdfFile);
        if (this.pdfDocumentsList[index].isDownload) {
          // this.pdfUrl =
          //   this.getDownloadHtml(
          //     this.pdfDocumentsList[index].content,
          //     this.pdfDocumentsList[index].contentLength
          //   ) + this.defaultZoomLevel;
          this.downloadHtml = `File is too large to preview. Please <a href="${
            this.pdfDocumentsList[index].content
          }">Download</a> (${this.getBytesToMB(
            this.pdfDocumentsList[index].contentLength
          )} MB)`;
        } else {
          this.pdfUrl =
            this.getViewHtml(this.pdfDocumentsList[index].contentLength) +
            this.defaultZoomLevel;
          this.nbcApiService
            .downloadFileFromAzureFileShare(
              this.pdfDocumentsList[index].content,
              this.pdfDocumentsList[index].contentLength
            )
            .subscribe((res: any) => {
              this.pdfUrl =
                this.convertByteStreanIntoFile(res.base64String, type) +
                this.defaultZoomLevel;
            });
        }
      });
  }

  viewMedicalDocuments(order, document?) {
    const medicalOrder = JSON.parse(JSON.stringify(order));
    medicalOrder.orderDocuments.documents = this.getMedicalRecords(
      medicalOrder?.orderDocuments?.documents
    );
    this.viewOrderDocuments(medicalOrder, document);
  }

  getMedicalRecords(documents) {
    return documents?.filter(
      (doc) =>
        doc.documentType === 'APS' || doc.documentType === 'EBU_LAB_RECORDS'
    );
  }

  getNotifyEvent() {
    this.openPdfDocumentsModal = false;
    this.pdfDocumentsList = [];
    this.pdfUrl = '';
    this.openAddNotesModal = false;
  }

  displayPdf(file) {
    this.downloadHtml = null;
    const type = this.fileType(file);
    if (file.isDownload) {
      // this.pdfUrl =
      //   this.getDownloadHtml(file.content, file.contentLength) +
      //   this.defaultZoomLevel;

      this.downloadHtml = `File is too large to preview. Please <a href="${
        file.content
      }">Download</a> (${this.getBytesToMB(file.contentLength)} MB)`;
    } else {
      this.pdfUrl =
        this.getViewHtml(file.contentLength) + this.defaultZoomLevel;
      this.nbcApiService
        .downloadFileFromAzureFileShare(file.content, file.contentLength)
        .subscribe((res: any) => {
          this.pdfUrl =
            this.convertByteStreanIntoFile(res.base64String, type) +
            this.defaultZoomLevel;
        });
    }
  }

  fileType(file) {
    const fileName = file.name || file.label;
    return fileName.split('.').pop().toLowerCase();
  }

  convertByteStreanIntoFile(resultByte, type) {
    const blob = new Blob([this.stringToUint8Array(resultByte)], {
      type: this.handleFileDataType(type),
    });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  handleFileDataType = (type) => {
    switch (type.toLowerCase()) {
      case 'pdf':
        return 'application/pdf';
      case 'jpg':
        return 'image/jpg';
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'txt':
        return 'text/plain';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
  };

  stringToUint8Array(raw) {
    raw = atob(raw);
    const uint8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  }

  get requestType(): typeof RequestType {
    return RequestType;
  }

  openAddNoteModal(order) {
    this.openAddNotesModal = true;
    this.order = order;
    this.orderLevelAuditNotes();
  }

  addNote() {
    const note = {} as AuditNote;
    note.information = this.note;
    note.loggedDate = this.cstPipe.transform();
    note.orderTrackerId = this.order.orderTrackerId;
    note.applicationTrackerId = this.order.applicationTrackerId;
    note.applicationId = this.order.applicationId;
    note.loggedBy = this.profile.name;
    note.auditParameter = 'TO_APS';
    note.auditTypeId = 11;
    this.service.insertNote(note);
    this.note = '';
  }

  orderLevelAuditNotes() {
    if (
      this.order !== null &&
      this.order !== undefined &&
      this.allNotes.length > 0
    ) {
      this.orderLevelNotes = this.filterNotes(this.order);
    }
  }

  filterNotes(orderNote) {
    if (this.allNotes) {
      return this.allNotes
        .filter((order: AuditNote) => {
          return (
            order.orderTrackerId === orderNote?.orderTrackerId &&
            order.auditTypeId === AuditTypeId.TO_UWS
          );
        })
        .sort((a, b) => {
          return (
            new Date(b.loggedDate).getTime() - new Date(a.loggedDate).getTime()
          );
        });
    } else {
      return [];
    }
  }

  getNotesCount(order) {
    return this.filterNotes(order).length;
  }

  canShowIcons() {
    if (this.applicationTrackerId) {
      this.subscriptions.push(
        this.service.orderRequests.subscribe((response) => {
          if (response !== null && response.data.application !== null) {
            this.filterOrderList(response.data.application);
          }
        })
      );
    }
  }
}

export enum AuditTypeId {
  TO_UWS = 11,
}
