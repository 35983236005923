export interface ProcessingResult {
  result: any;
  stopProcessing: boolean;
  message?: string;
}

export class DemographicUpdateResponseItem {
  answerTag: string;
  clientNumber: string;
  isPrimary: boolean;
  aS400Status: string;
}
