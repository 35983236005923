<div *ngFor="let item of details.items(); let i = index" class="row">
  <div class="col">
    <div class="form-check mb-1">
      <label class="form-check-label cursor-pointer">
        <input
          class="form-check-input"
          type="radio"
          name="question.answerTag"
          [checked]="item.key === selectedValue"
          (click)="optionChanged(item.key)"
        />
        {{ item.value }}
      </label>
    </div>
  </div>
</div>
<app-modal
  [open]="openConfirmationModal"
  [modalOptions]="{ size: 'lg' }"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event); cancelPaymentInfo($event)"
>
  <div title>
    Confirmation
  </div>
  <div body>
    <div class="pl-2">
      Are you sure you want to change the payor type as this will clear some of
      the payment information?
    </div>
  </div>
</app-modal>
