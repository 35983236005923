import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-question-monthly-account-info',
  templateUrl: './monthly-account-info.component.html',
  styleUrls: ['./monthly-account-info.component.scss'],
})
export class VcallMonthlyAccountInfoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
