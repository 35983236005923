import { Inject, Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileService } from '../../shared/services/user/user-profile.service.service';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { CallZoneApiService } from 'src/app/shared/services/api/callzone/callzone-api.service';
import {
  MsalService,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { CallZoneUser } from 'src/app/shared/models/callzone-user';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { environment } from 'src/environments/environment';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';

/**
 * Authorization Guard
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  callzoneUser: CallZoneUser;
  /**
   * Authorization guard component constructor
   * @param adalSvc Adal service
   * @param router Router
   * @param userProfileService User profile service
   */
  constructor(
    // private msal: MsalService,
    private router: Router,
    private userProfileService: UserProfileService,
    private api: CallZoneApiService,
    private msalService: MsalService,
    private analyticsService: AnalyticsService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private uiService: UiServiceService
  ) {}
  /**
   * Checks if user is authorized & returns boolean
   * @param next Activated route snapshot
   * @param state Router state snapshot
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Should only be here if MSALGuard agreed, meaning user was logged in.
    // For the very first time when User is redirected active account is not set, so creating a promise for it to be set and used.
    const account = this.msalService.instance.getActiveAccount();

    if (account !== null) {
      return this.processAccount(next);
    }

    let resolve;
    const promise = new Promise<boolean>((r, rj) => {
      resolve = r;
    });
    this.msalService
      .handleRedirectObservable()
      .toPromise()
      .then((r) =>
        this.msalService.instance.setActiveAccount(
          this.msalService.instance.getAllAccounts()[0]
        )
      )
      .then((a) => {
        return this.processAccount(next);
      })
      .then(() => resolve(true));

    return promise;
  }

  processAccount(next) {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (Object.keys(account).length !== 0) {
      const newProfile = new UserProfile(
        account['name'],
        account['idTokenClaims'].groups,
        account['localAccountId'],
        account['username']
      );

      this.userProfileService.setUserProfile(newProfile);
      this.userProfileService.loadRoles(account);
      if (environment.features.clarity) {
        this.analyticsService.setIdentity(account['username']);
      }
      // Lets get the callzone user since the user is authenticated
      const callzonePromise = this.callzoneUser
        ? new Promise<CallZoneUser>((r) => {
            r(this.callzoneUser);
          })
        : this.api
            .GetCallZoneUser(
              newProfile.domainUserName,
              next.data.verifyCallZoneUser
            )
            .toPromise();

      return Promise.all([callzonePromise])
        .then((r) => {
          if (next.data.verifyCallZoneUser && r[0]) {
            if (
              r[0].status !== undefined &&
              r[0].status !== 200 &&
              !r[0].isActive
            ) {
              return;
            }
          }
          // console.log(r, 'resolved all promises');
          const u = r[0];
          if (u) {
            if (this.callzoneUser) {
              return;
            }

            this.callzoneUser = u;
            this.userProfileService.setCallzoneUser(u);
          } else {
            throw new Error(
              'Could not find Resource for the logged in user in Callzone'
            );
          }
          return true;
        })
        .then();
    }
  }
}
