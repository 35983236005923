<!-- If user Idle still 15 min starts from here -->
<app-modal
  [open]="openUserIdleModal"
  [modalOptions]="{ size: 'lg' }"
  [buttonsConfig]="{
    showModalHeader: false
  }"
  [footer]="false"
>
  <div body class="mt-1">
    <img
      src="./assets/images/cross.svg"
      alt="image"
      width="15"
      class="close_icon"
      (click)="reset()"
    />
    <div class="m-3">
      <img src="./assets/images/inactivity.svg" alt="image" width="80" />
      <h4 style="font-weight: bold; color: #212529">Inactivity Alert</h4>
      <p class="inactivity_info mb-4" [innerHTML]="idleState"></p>
      <button class="btn btn-md btn-primary" (click)="reset()">Continue</button>
    </div>
  </div>
</app-modal>
<!-- If user Idle still 15 min ends here-->
