<div class="row mt-4">
  <div class="col-12">
    <textarea
      type="text"
      class="form-control"
      rows="8"
      name="detail"
      [(ngModel)]="recallAnswer"
      (keyup)="SaveText()"
    ></textarea>
  </div>
</div>
