import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthorizationGuard } from '../core/guards/authorization.guard';
import { PermissionGuard } from '../core/guards/permission.guard';
import { VcallResolverService } from '../shared/services/api/vcall/vcall-resolver.service';
import { ApplicantQualifiedOccupationComponent } from './questions/applicant-qualified-controls/applicant-qualified-occupation/applicant-qualified-occupation.component';
import { UserVerificationComponent } from './user-verification/user-verification.component';
import { VcallComponent } from './vcall.component';
import { ViewOnlyScreenComponent } from './view-only-screen/view-only-screen.component';

export const VcallRoutes: Routes = [
  {
    path: '',
    component: VcallComponent,
  },
  {
    path:
      'application/:applicationId/section/:sectionId/question/:questionId/appointment/:appointmentId',
    component: VcallComponent,
    pathMatch: 'full',
    resolve: {
      application: VcallResolverService,
    },
  },
  // {
  //   path:
  //     'application/:applicationId/section/:sectionId/question/:questionId/appointment/:appointmentId',
  //   component: VcallComponent,
  //   pathMatch: 'full',
  //   resolve: {
  //     application: VcallResolverService,
  //   },
  // },
  {
    path: 'application/:applicationId/appointment/:appointmentId',
    component: VcallComponent,
    pathMatch: 'full',
    resolve: {
      application: VcallResolverService,
    },
  },
  // {
  //   path: 'application/:applicationId/appointment/:appointmentId',
  //   component: VcallComponent,
  //   pathMatch: 'full',
  //   resolve: {
  //     application: VcallResolverService,
  //   },
  // },
  {
    path: 'occupationWrapper',
    component: ApplicantQualifiedOccupationComponent,
  },
  {
    path: 'vcall/view/application/:applicationId',
    component: ViewOnlyScreenComponent,
  },
  {
    path: 'user-verification',
    component: UserVerificationComponent,
    canActivate: [AuthorizationGuard, MsalGuard, PermissionGuard],
  },
];
