<div class="modal-header">
  <div class="col-sm-12 row justify-content-between m-0">
    <div class="row" *ngIf="application">
      <i class="fas fa-user fa-modal-header text-black-50 mt-2"></i>
      <div class="d-block ml-3">
        <p class="label-Name m-0">Applicant Name</p>
        <p class="applicant-Name">
          {{ application.primaryApplicant | fullname }}
        </p>
      </div>
    </div>
    <div class="row" *ngIf="selectedAppointment">
      <i class="fas fa-calendar-alt fa-modal-header text-black-50 mt-2"></i>
      <div class="d-block ml-3">
        <p class="label-Name m-0">Scheduled Time</p>
        <p class="applicant-Name">
          {{
            selectedAppointment.scheduledDate
              | datewithTimeZone: timeZone:"date-time"
          }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-body">
  <iframe
    width="100%"
    height="560px"
    [src]="rescheduleAppointmentUrl | safe: 'resourceUrl'"
  ></iframe>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="closeModal()">
    Close
  </button>
</div>
