import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  RequiredValidator,
  Validators,
} from '@angular/forms';
import { ScriptService } from '../../services/script/script.service';
import { Question } from '../../models/vcall/question';
import { ApplicationService } from '../../services/application/application.service';

@Component({
  selector: 'app-question-notes',
  templateUrl: './question-notes.component.html',
  styleUrls: ['./question-notes.component.scss'],
})
export class QuestionNotesComponent implements OnInit {
  openModal: boolean;
  question: Question;
  noteForm = new UntypedFormGroup({
    notes: new UntypedFormControl('', Validators.required),
  });

  constructor(
    public service: ScriptService,
    public applicationService: ApplicationService
  ) {
    this.openModal = false;
    this.service.SelectedQuestion.subscribe((q) => {
      if (q) {
        this.question = q;
        const currentAnswer = this.applicationService.GetAnswerForAnswerTags(
          this.question.answerTag
        );
        const notes =
          currentAnswer && currentAnswer.notes !== ''
            ? currentAnswer.notes
            : null;
        this.applicationService.updateNotes(this.question, notes);
      }
    });
  }

  /**
   * @ignore ignore
   */
  ngOnInit() {}

  /**
   * @param event which evet we have to pass like elemnet or event etc.
   * @description submit the note
   */
  submitNote(event: string) {
    if (event === 'Done') {
      this.applicationService.updateNotes(
        this.question,
        this.noteForm.value.notes
      );
    }
    this.openModal = false;
  }

  /**
   * @description hit the note modal and display the modal
   */
  triggerModel() {
    const currentAnswer = this.applicationService.GetAnswerForAnswerTags(
      this.question.answerTag
    );
    this.noteForm.patchValue({
      notes: currentAnswer ? currentAnswer.notes : null,
    });
    this.openModal = true;
  }
}
