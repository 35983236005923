<button (click)="toggle = !toggle">
  toggle
</button>

<ng-container *ngIf="toggle">
  <ng-container *ngFor="let items of applicationScript.items">
    <div class="section p-3">
      <strong>{{ items.section.name }}</strong>
      <ng-container *ngFor="let question of items.questions">
        <div
          class="cursor"
          [innerHTML]="question.answerTag"
          (click)="routeToQuestion(question)"
        ></div>
        <hr class="m-0" />
      </ng-container>
      <hr />
    </div>
  </ng-container>
</ng-container>
