import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { PrescriptionLookup } from '../../prescription-lookup/prescription-lookup.component';
import {
  ApplicantQualifiedBaseComponent,
  BaseApplicantQualifiedAnswer,
} from '../applicant-qualified-controls/applicant-qualified-base-component';
import { DefaultApplicantQualifiedQuestionDetail } from '../applicant-qualified-controls/default-applicant-qualified-question-detail';

@Component({
  selector: 'app-applicant-qualified-medication-lookup',
  templateUrl: './applicant-qualified-medication-lookup.component.html',
  styleUrls: ['./applicant-qualified-medication-lookup.component.scss'],
})
export class ApplicantQualifiedMedicationLookupComponent extends ApplicantQualifiedBaseComponent<
  DefaultApplicantQualifiedQuestionDetail,
  PrescriptionLookupDetailsAnswer
> {
  details: DefaultApplicantQualifiedQuestionDetail;
  initialMedicationList = new BehaviorSubject<PrescriptionLookup[]>(
    new Array<PrescriptionLookup>()
  );

  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    protected applicantFilterService: ApplicantFilterService
  ) {
    super(script, appService, ui, applicantFilterService);
  }

  /**
   * @description not used
   */
  ResetApplicant() {
    // this.initialMedicationList = new Array<;
  }

  /**
   * @description save the answer entered on the field
   */
  BindApplicant() {
    if (this.currentAnswer && this.currentAnswer.list) {
      this.initialMedicationList.next(this.currentAnswer.list);
    } else {
      this.initialMedicationList.next(new Array<PrescriptionLookup>());
    }
    super.Save(this.currentAnswer);
  }

  InitializeApplicantAnswerInstance(): PrescriptionLookupDetailsAnswer {
    return new PrescriptionLookupDetailsAnswer();
  }

  /**
   * @returns true or false based the answer exists or not
   */
  CanGoNext(): boolean {
    if (this.currentAnswer.list && this.currentAnswer.list.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description it will give the added medication and save it in the answer model
   */
  getMedicationList(e: PrescriptionLookup[]) {
    this.currentAnswer.list = e;
    super.Save(this.currentAnswer);
  }
}

export class PrescriptionLookupDetailsAnswer extends BaseApplicantQualifiedAnswer {
  list: PrescriptionLookup[] = [];
  answerType = 'MedicationDetailsAnswer';
}
