import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { DoctorLookup } from 'src/app/shared/models/drLookup';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { DocLookupService } from 'src/app/vcall/doctor-lookup/doc-lookup.service';

@Component({
  selector: 'app-basic-doctor-form',
  templateUrl: './basic-doctor-form.component.html',
  styleUrls: ['./basic-doctor-form.component.scss'],
})
export class BasicDoctorFormComponent implements OnInit {
  @ViewChild('basicForm', { static: false }) basicFormGroup: NgForm;
  @Input() resetForm$: Observable<boolean>;
  public basicDoctorForm: BasicDoctor = {} as BasicDoctor;
  doctorsList = new Array<DoctorLookup>();
  isDoctorUpdated = false;
  updatedDoctor: DoctorLookup;
  openDeleteModal = false;

  constructor(
    private docLookupService: DocLookupService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit() {
    if (this.resetForm$) {
      this.resetForm$.subscribe((res) => (this.doctorsList = []));
    }
  }

  resetForm(form: NgForm) {
    form.reset();
    this.isDoctorUpdated = false;
  }

  editDoctor(item) {
    this.updatedDoctor = item;
    this.basicFormGroup.setValue({
      fullname: item.fullname,
      corporateOwnerLastName: item.corporateOwnerLastName,
    });
    this.isDoctorUpdated = true;
  }

  deleteDoctor(item) {
    this.updatedDoctor = item;
    this.openDeleteModal = true;
  }

  submitForm(form: NgForm) {
    const formData = form.value;
    if (this.isDoctorUpdated) {
      const index = this.getFormId();
      this.doctorsList[index].fullname = formData.fullname;
      this.doctorsList[index].additionalInfo.corporateOwnerLastName =
        formData.corporateOwnerLastName;
      this.doctorsList[index]['corporateOwnerLastName'] =
        formData.corporateOwnerLastName;
    } else {
      formData.id = UUID.UUID();
      formData.additionalInfo = {};
      formData.additionalInfo.corporateOwnerLastName =
        formData.corporateOwnerLastName;
      formData.is_basic = true;
      this.doctorsList.push(formData);
    }
    this.docLookupService.AddProviders(this.doctorsList);
    form.reset();
    this.isDoctorUpdated = false;
  }

  getFormId() {
    return this.doctorsList.findIndex((e) => e.id === this.updatedDoctor.id);
  }

  titleCase(name, value) {
    this.basicDoctorForm[name] = value && value.toUpperCase();
  }

  confirm($event) {
    const index = this.getFormId();
    this.doctorsList.splice(index, 1);
    let existingDocList = this.docLookupService.GetApplicationProviders();
    existingDocList = existingDocList.filter(
      (doc) => doc.fullname !== this.updatedDoctor.fullname
    );
    this.applicationService.RegisterAnswerByTag(
      true,
      existingDocList,
      'docsList'
    );
    this.openDeleteModal = false;
    if (!this.doctorsList.length) {
      this.basicFormGroup.reset();
      this.isDoctorUpdated = false;
    }
  }
}

interface BasicDoctor {
  fullname: string;
  corporateOwnerLastName: string;
}
