<form form="NgForm">
  <div class="row">
    <div class="col-6">
      <span [innerHTML]="promptText"></span>
    </div>
    <div class="col-2 text-right">
      <label
        class="cursor-pointer btn border border-success"
        [ngClass]="
          answer?.verification === true || isVerifiedButton === true
            ? 'btn-success'
            : 'btn-outline-success'
        "
        ><input
          name="question.answerTag"
          type="radio"
          (change)="donotagree = false; optionChanged(true)"
          [checked]="
            answer !== null &&
            answer !== undefined &&
            answer.verification === true
          "
          class="hide-radio-inputs"
        />
        {{ paymentDetails.verifiedText }}
      </label>
    </div>
    <div class="col-2">
      <label
        class="cursor-pointer btn border border-danger"
        [ngClass]="
          answer?.verification === false || isVerifiedButton === false
            ? 'btn-danger'
            : 'btn-outline-danger'
        "
      >
        <input
          name="question.answerTag"
          #paymentno
          (change)="donotagree = true; optionChanged(false)"
          [checked]="
            answer !== undefined &&
            answer !== null &&
            answer.verification === false
          "
          type="radio"
          class="hide-radio-inputs"
        />
        {{ paymentDetails.notVerifiedText }}
      </label>
    </div>
  </div>
  <br />
  <div class="row" *ngIf="donotagree">
    <div class="col-8">{{ paymentDetails.methodOfPaymentChangePrompt }}</div>
    <div class="col-4">
      <button
        class="btn btn-primary btn-lg carousel-next"
        (click)="openModal = true"
      >
        Change
      </button>
    </div>
    <app-modal
      [open]="openModal"
      [modalOptions]="{ size: 'lg' }"
      [buttonsConfig]="{
        modalMode: 'form',
        submitButtonName: 'Change',
        cancelButtonName: 'Cancel'
      }"
      (actions)="editQuestion($event); cancel($event)"
      [formValidation]="this.selectedValue !== undefined"
    >
      <div title>
        <h4 class="modal-title" id="modal-basic-title">
          <span>
            Change Payment Type from {{ originalValue | titlecase }}
          </span>
        </h4>
      </div>
      <div body>
        <form #monthlyModeEdit="ngForm">
          <div
            *ngFor="let item of paymentDetails.items; let i = index"
            class="row mt-2"
          >
            <div class="col-6 text-right">
              <b *ngIf="item.key === originalValue"
                >Currently selected option :
              </b>
              <b *ngIf="item.key !== originalValue">Other option : </b>
            </div>
            <div class="col-6">
              <div class="form-check mb-4">
                <label
                  class="btn"
                  [ngClass]="
                    item.key === selectedValue
                      ? 'btn-success'
                      : 'btn-outline-success'
                  "
                >
                  <input
                    type="radio"
                    name="question.answerTag"
                    [checked]="item.key === selectedValue"
                    (change)="selectedValue = item.key"
                    class="hide-radio-inputs"
                  />
                  {{ item.value | titlecase }}
                </label>
              </div>
            </div>
          </div>
          <div class="p-2" *ngIf="selectedValue === 'DIRECT BILLING'">
            {{ this.paymentDetails.directBillNote }}
          </div>
          <div *ngIf="paymentQuestionAnswered">
            {{ this.paymentDetails.AnsweredQuestionNote }}
          </div>
        </form>
      </div>
    </app-modal>
  </div>
</form>
<app-modal
  [open]="openConfirmationModal"
  [modalOptions]="{ size: 'lg' }"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event); cancelPaymentInfo($event)"
>
  <div title>
    Confirmation
  </div>
  <div body>
    <div class="pl-2">
      Are you sure of removing Bank information for {{ selectedValue }}
    </div>
  </div>
</app-modal>
<div
  *ngIf="question.instructions"
  class="col-sm-12 alert alert-warning text-center mt-3"
  role="alert"
>
  {{ question.instructions }}
</div>

<div
  *ngIf="question?.details?.information"
  class="col-sm-12 alert alert-info text-center mt-3"
  role="alert"
>
  {{ question.details.information }}
</div>
