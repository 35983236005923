import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { AddOnsService } from '../add-on/addon.service';
import {
  AddOnApplication,
  ApplicantActionType,
  ApplicantType,
} from '../add-on/addon-application';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Application } from 'src/app/shared/models/application/application';
import { AdditionalInfoService } from '../additional-info/additional-info.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { ApplicationTracker } from '../additional-info/additional-info-modal';
import { ApplicationStatus } from '../../models/enum';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { AddonApplicantComponent } from '../addon-applicant/addon-applicant.component';

@Component({
  selector: 'app-addon-logs',
  templateUrl: './addon-logs.component.html',
  styleUrls: ['./addon-logs.component.scss'],
  providers: [AddonApplicantComponent],
})
export class AddonLogsComponent implements OnInit, OnChanges {
  @Input() applicationLogs: AddOnApplication[] = [];
  @ViewChild(OrderDetailsComponent) orderDetailsComp: OrderDetailsComponent;
  @ViewChild(AddonApplicantComponent)
  AddonApplicantComponent: AddonApplicantComponent;
  @Output() deleteAddonApplicant = new EventEmitter();
  openDeleteApplicantModal: boolean;
  applicantDelete: AddOnApplication;
  selectedIndex = 0;
  openCreateOrderModal = false;
  subscriptions = new Array<Subscription>();
  applicantsList: Applicant[];
  application: Application;
  applicationTracker: ApplicationTracker;
  profile: UserProfile;
  addonApplication: any;
  canCreateOrder = false;
  canRouteTo = false;
  constructor(
    private addonService: AddOnsService,
    private toaster: ToastService,
    private appService: ApplicationService,
    private service: AdditionalInfoService,
    private profileService: UserProfileService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.appService.SelectedApplication.subscribe((res) => {
        if (res) {
          this.application = res;
          this.applicantsList = [res.primaryApplicant, ...res.family].filter(
            (a) => a['applicantType'] === ApplicantType.AddOn
          );
          this.service.fetchOrderRequests(this.application.id);
          this.service.fetchNotes(this.application.id);
        }
      })
    );

    this.service.openOrderRequest.subscribe(
      (res) => (this.openCreateOrderModal = res)
    );

    this.subscriptions.push(
      this.profileService.userProfile.subscribe((s) => {
        this.profile = s;
      })
    );

    this.subscriptions.push(
      this.service.orderRequests.subscribe((response) => {
        if (response !== null && response.data.application !== null) {
          this.addonApplication = response.data.application;
          this.applicationTracker = response.data.application.applicationTracker.find(
            (a) => a.addOnApplicationId === this.addOnAppId
          );

          this.canCreateOrder =
            this.applicationTracker &&
            this.profile.IsSame(this.applicationTracker.assignedTo) &&
            this.service.isApplicationStatusValid(this.applicationTracker);

          this.canRouteTo =
            this.applicationTracker &&
            this.profile.IsSame(this.applicationTracker.assignedTo) &&
            this.applicationTracker.applicationStatusId ===
              ApplicationStatus['With UnderWriting Team'] &&
            this.orderDetailsComp &&
            this.orderDetailsComp.activeOrdersList.length > 0;
        }
      })
    );
    this.getApplicantLogs(this.applicationLogs[this.selectedIndex]);
    this.calcApplicantCountForLogs();
    this.configApplicantNamesForLogs();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  getActiveOrders(orders) {
    this.canRouteTo =
      this.applicationTracker &&
      this.profile.IsSame(this.applicationTracker.assignedTo) &&
      this.applicationTracker.applicationStatusId ===
        ApplicationStatus['With UnderWriting Team'] &&
      orders &&
      orders.length > 0;
  }

  calcApplicantCountForLogs() {
    this.applicationLogs.map((a) => {
      a.applicantCount = this.getApplicantLogs(a).length;
    });
  }

  configApplicantNamesForLogs() {
    this.applicationLogs.map((a) => {
      const applicants = [];
      this.getApplicantLogs(a).forEach((applicant) => {
        if (a.id === applicant.addOnAppId) {
          applicants.push(applicant.firstName + ' ' + applicant.lastName);
          a.applicantNames = [...new Set(applicants)];
        }
      });
    });
  }

  pad(n) {
    return n < 10 ? '0' + n : n;
  }

  getApplicantLogs(applicants) {
    const applicantList = [];
    for (const key in applicants['family']) {
      if (applicants['family'].hasOwnProperty(key)) {
        applicantList.push(applicants['family'][key]);
        applicantList.map((a) => (a.addOnAppId = applicants.id));
      }
    }
    return applicantList;
  }

  get addOnAppId() {
    return this.applicationLogs[this.selectedIndex]?.id;
  }

  get canDisplayAddAppplicant() {
    return this.addonApplication?.applicationTracker.filter(
      (tracker) => tracker.addOnApplicationId === this.addOnAppId
    )[0]?.applicationStatusId === ApplicationStatus['With UnderWriting Team']
      ? true
      : false;
  }

  get ApplicationStatus(): typeof ApplicationStatus {
    return ApplicationStatus;
  }

  addApplicant(a) {
    this.addonService.addOrEditApplicant.next({
      applicant: a,
      type: ApplicantActionType.addApplicant,
    });
  }

  editApplicant(a) {
    this.addonService.addOrEditApplicant.next({
      applicant: a,
      type: ApplicantActionType.editApplicant,
    });
  }

  deleteApplicant(applicant) {
    this.openDeleteApplicantModal = true;
    this.applicantDelete = applicant;
  }

  confirm(action: any) {
    const { addOnAppId, id } = this.applicantDelete;
    if (action === 'Done') {
      this.addonService.deleteApplicant(addOnAppId, id).then((res) => {
        this.deleteAddonApplicant.emit();
      });
    }
    this.openDeleteApplicantModal = false;
  }

  onChangeApplication(index) {
    const addOnId = this.getApplicantLogs(this.applicationLogs[index])[0]
      .addOnAppId;
    this.applicationTracker = this.addonApplication?.applicationTracker.find(
      (a) => a.addOnApplicationId === addOnId
    );
  }

  routeToAPS() {
    const order = this.service.constructRouteOrdersToAPS(
      this.application.id,
      this.profile.name,
      this.applicationTracker
    );
    this.service
      .routeOrdersToAPS(order, this.application.id)
      .then((response) => {
        if (response) {
          this.toaster.success(null, 'Orders successfully routed to APS Team');
        }
      });
  }

  createRequest() {
    this.openCreateOrderModal = true;
  }
}
