<div class="row">
  <div class="col-12">
    <div class="p-3 col-12">{{ details.textboxTitle }}</div>
    <textarea
      type="text"
      class="form-control detail"
      name="detail"
      (blur)="SaveText()"
      (keyup)="EnableNext()"
      [(ngModel)]="currentAnswer.notes"
    >
    </textarea>
  </div>
  <div
    *ngIf="question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>
</div>
