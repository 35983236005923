import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Type,
  OnDestroy,
} from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { ModelMapperService } from 'src/app/shared/services/application/modelMapperService.service';
import { DecoratedModelConfiguration } from 'src/app/shared/models/application/decoratedModelConfiguration';
import { GenericcontrolService } from 'src/app/shared/services/genericcontrol.service';
import { EncryptedValues } from 'src/app/shared/models/vcall/encryptedValues';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Payment } from 'src/app/shared/models/application/payment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-verifiable-list',
  templateUrl: './verifiable-list.component.html',
  styleUrls: ['./verifiable-list.component.scss'],
})
export class VerifiableListComponent implements OnInit, OnDestroy {
  constructor(
    private gcs: GenericcontrolService,
    private applicationService: ApplicationService,
    private scriptService: ScriptService
  ) {}

  @Input()
  model: VerifiableModel<any>[];

  @Input()
  isCustomEdit: boolean;

  @Output()
  formComplete = new EventEmitter();

  @ViewChild('dynamicForm')
  private form: NgForm;

  verifiableform: UntypedFormGroup;
  private formStatusSub: Subscription;
  stringToEncrypt = {} as StringToEncrypt;
  controlValidityStates = {};

  show = { 0: true };
  isEdit = { 0: false };
  formValidity: boolean;
  ngOnInit() {
    this.verifiableform = this.gcs.toFormGroup(this.model);
    this.subscribeToFormControlsStatus();

    this.model.map((m, index) => {
      if (m.verification !== null) {
        this.verifiableform.controls[index].patchValue(m.verification);
        this.show[index.toString()] = true;
        this.show[(index + 1).toString()] = true;
        // this is to ensure the next row is visible if it is already verified. To resolve the issue where the last row was hidden.
      }
    });

    const verifiedList = this.model.filter((m) => {
      return m.verification === null;
    });

    if (verifiedList.length === 0) {
      this.formCompleted(true);
    }
  }

  ngOnDestroy() {
    if (this.formStatusSub) {
      this.formStatusSub.unsubscribe();
    }
  }

  // In this Angular form, the keys 0, 1, and 2 are used instead of the anticipated names for form controls. This occurs due to the way the form group is set up with this.gcs.toFormGroup(this.model);,
  // where numeric controls are created alongside named controls.
  private subscribeToFormControlsStatus(): void {
    const controls = this.verifiableform.controls;
    this.controlValidityStates = {};

    Object.keys(controls).forEach((key) => {
      // Ensure the key is not a number (to filter out array-based controls).
      if (isNaN(Number(key))) {
        const control = controls[key];
        // Initialize the validity state of the control.
        this.controlValidityStates[key] = control.valid;
        // Subscribe to the statusChanges observable of the control.
        control.statusChanges.subscribe((status) => {
          // Update the validity state of the control
          this.controlValidityStates[key] = control.valid;

          if (this.model.some((m) => m.propertyName === key)) {
            // The form is valid if every control in controlValidityStates is valid.
            this.formValidity = Object.values(this.controlValidityStates).every(
              (v) => v
            );
          }
        });
      }
    });
  }

  copyDetails(item: any) {
    let payment: Payment;
    this.applicationService.SelectedApplication.subscribe((a) => {
      payment = a.payment;
    });

    this.model.forEach((element, index) => {
      if (item.propertyName === element.propertyName) {
        if (
          element.value !== null &&
          element.value !== '' &&
          element.value !== payment[element.copyMapper.toString()].trim()
        ) {
          if (element.needsEncryption) {
            const encryptedToken =
              this.applicationService.GetAnswerForAnswerTag(
                element.copyMapper + 'Encrypted'
              );
            this.addEncryptedAnswer(element, encryptedToken.value);
          }
          item.verification = null;
          element.changedValue = payment[element.copyMapper.toString()];
          this.verifiableform.controls[index].patchValue(null);
        }
      }
    });
    const valueChanged = this.model.filter((m) => {
      return m.verification === false || m.verification === null;
    });
    if (valueChanged.length > 0) {
      this.scriptService.UpdateAnswer(false, this.verifiableform);
    } else {
      this.scriptService.UpdateAnswer(true, this.verifiableform);
    }
  }

  resetDetails(item: any) {
    let count = 0;
    this.model.forEach((element, index) => {
      if (item.propertyName === element.propertyName) {
        if (element.value !== null && element.value !== '') {
          this.applicationService.DeleteAnswerForAnswerTag(element.answerTag);
          count++;
          this.verifiableform.controls[index].patchValue(null);
        }
      }
      if (count > 0) {
        this.show[(index + 1).toString()] = undefined;
        element.verification = null;
      }
    });
  }

  save(item: any) {
    let count = 0;
    this.model.forEach((element, index) => {
      if (item.propertyName === element.propertyName) {
        if (
          element.originalValue === '' ||
          element.originalValue === null ||
          this.verifiableform.controls[item.propertyName].value !==
            element.originalValue
        ) {
          element.changedValue =
            this.verifiableform.controls[item.propertyName].value;
        }
        if (element.needsEncryption) {
          this.stringToEncrypt.ActualValue = element.changedValue;
          this.applicationService
            .EncryptValue(this.stringToEncrypt)
            .subscribe((res: EncryptedValues) => {
              this.verifiableform.controls[index].patchValue(null);
              if (res && res.encryptedToken) {
                element.changedValue = res.maskedValue;
                this.addEncryptedAnswer(element, res.encryptedToken);
              }
            });
        } else {
          this.verifiableform.controls[index].patchValue(null);
        }
        this.scriptService.UpdateAnswer(false, this.verifiableform);
        count++;
      }
      if (count > 0) {
        this.show[(index + 1).toString()] = undefined;
        element.verification = null;
        this.verifiableform.controls[index] === undefined
          ? null
          : this.verifiableform.controls[index].patchValue(null);
      }
    });
  }

  addEncryptedAnswer(element, encryptedToken: string) {
    this.model.push({
      label: null,
      name: null,
      order: null,
      answerTag: element.propertyName + 'Encrypted',
      changedValue: encryptedToken,
      propertyName: null,
      originalValue: null,
      required: null,
      type: null,
      controlType: null,
      pattern: null,
      maxLength: null,
      minLength: null,
      needsEncryption: null,
      verification: true,
      value: encryptedToken,
      copyMapper: null,
    });
  }

  cancel(item: any) {
    this.model.forEach((element) => {
      if (item.propertyName === element.propertyName) {
        if (element.value !== null && element.value !== '') {
          this.verifiableform.controls[item.propertyName].patchValue(
            element.value
          );
        }
      }
    });
  }

  formCompleted(isValid: boolean) {
    if (isValid) {
      this.formComplete.emit(this.model);
    } else {
      this.formComplete.emit(null);
    }
  }

  optionChanged(item: any, isValid: boolean) {
    if (!this.isCustomEdit) {
      this.model.forEach((element, index) => {
        if (item.propertyName === element.propertyName) {
          element.verification = this.verifiableform.controls[index].value;
        }
      });

      this.formCompleted(true);
    } else {
      this.formCompleted(isValid);
    }
  }

  inputChanged(isFormValid: any) {
    this.formCompleted(isFormValid);
  }

  isValid(item: any) {
    return this.verifiableform.controls[item].errors;
  }
}

export class VerifiableModel<T> {
  constructor(options: {} = null) {
    Object.assign(this, options);
  }

  verification: boolean;
  get value() {
    return this.changedValue
      ? this.changedValue
      : this.originalValue === null
      ? ''
      : this.originalValue.toString().trim();
  }

  label: string;
  name: string;
  order: number;
  answerTag: string;
  changedValue: T;
  propertyName: string;
  originalValue: T;
  required: boolean;
  type: string;
  controlType: string;
  pattern: string;
  maxLength: number;
  minLength: number;
  needsEncryption: boolean;
  copyMapper: string;

  static GetList<T>(item: any, baseTag?: string, subGroup?: string) {
    const mapper = new ModelMapperService();
    let sgArray = [];
    if (subGroup && subGroup.includes(',')) {
      sgArray = subGroup.split(',');
    } else {
      sgArray.push(subGroup);
    }
    if (sgArray) {
      return sgArray
        .map((s) => {
          return Object.keys(item)
            .map((k) => {
              const l: string = mapper.GetDecoratorValueForObjectPropertyName(
                item,
                k,
                DecoratedModelConfiguration.Label
              );
              const tag: string = mapper.GetDecoratorValueForObjectPropertyName(
                item,
                k,
                DecoratedModelConfiguration.AnswerTag
              );
              const pn: string = mapper.GetDecoratorValueForObjectPropertyName(
                item,
                k,
                DecoratedModelConfiguration.MetaKeyName
              );
              const sg: string = mapper.GetDecoratorValueForObjectPropertyName(
                item,
                k,
                DecoratedModelConfiguration.SubGroup
              );
              const req: boolean =
                mapper.GetDecoratorValueForObjectPropertyName(
                  item,
                  k,
                  DecoratedModelConfiguration.Required
                );
              const ctrlType: string =
                mapper.GetDecoratorValueForObjectPropertyName(
                  item,
                  k,
                  DecoratedModelConfiguration.ControlType
                );
              const typ: string = mapper.GetDecoratorValueForObjectPropertyName(
                item,
                k,
                DecoratedModelConfiguration.Type
              );
              const patrn: string =
                mapper.GetDecoratorValueForObjectPropertyName(
                  item,
                  k,
                  DecoratedModelConfiguration.Pattern
                );
              const maxLgth: number =
                mapper.GetDecoratorValueForObjectPropertyName(
                  item,
                  k,
                  DecoratedModelConfiguration.MaxLength
                );

              const minLgth: number =
                mapper.GetDecoratorValueForObjectPropertyName(
                  item,
                  k,
                  DecoratedModelConfiguration.MinLength
                );
              const needEncryption: boolean =
                mapper.GetDecoratorValueForObjectPropertyName(
                  item,
                  k,
                  DecoratedModelConfiguration.NeedsEncryption
                );
              const cd: string = mapper.GetDecoratorValueForObjectPropertyName(
                item,
                k,
                DecoratedModelConfiguration.CopyMapper
              );
              if (l && (subGroup === null || s === sg)) {
                const o = mapper.GetDecoratorValueForObjectPropertyName(
                  item,
                  k,
                  DecoratedModelConfiguration.Order
                );
                return new VerifiableModel<T>({
                  verification: null,
                  originalValue: item[k],
                  label: l,
                  order: o,
                  propertyName: pn,
                  answerTag: baseTag ? baseTag + '.' + tag : tag,
                  required: req,
                  controlType: ctrlType,
                  type: typ,
                  pattern: patrn,
                  maxLength: maxLgth,
                  minLength: minLgth,
                  needsEncryption: needEncryption,
                  copyMapper: cd,
                });
              }
            })
            .filter((i) => i !== undefined)
            .sort((a, b) => a.order - b.order);
        })
        .reduce((accumulator, value) => accumulator.concat(value), []);
    }
  }
}

export interface StringToEncrypt {
  ActualValue: string;
}
