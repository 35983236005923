<ng-container *ngFor="let file of documents; index as i">
  <div
    class="pdf_document"
    (click)="viewPdf(file, i)"
    [ngClass]="{ 'border border-primary bg-white': selectedDocIndex === i }"
  >
    <div class="d-flex justify-content-end f-10">
      {{ ((file.updatedDate || file.createdDate) | datewithTimeZone: timeZone:"date-time") }}
    </div>
    <div class="d-flex">
      <img src="../../../../assets/images/ushg/pdf_icon.svg" alt="image" />
      <div class="pl-2">
        <p class="font-weight-bold pb-1 m-0 f-14">{{ file.documentType }}</p>
        <p class="f-12">{{ file.name }}</p>
      </div>
    </div>
  </div>
</ng-container>
