<div class="row box-shadow mt-3 m-0 mb-3">
  <div class="col-lg-12 mb-4 px-5">
    <form
      [formGroup]="searchForm"
      id="searchForm"
      (submit)="searchApplications()"
    >
      <h5 class="pt-3 pb-3">Search An Application</h5>
      <div class="search-row">
        <div class="search-col">
          <label for="applicationId" class="title">Application Number</label>
          <input
            type="text"
            name="applicationId"
            id="applicationId"
            formControlName="applicationId"
            class="form-control"
            autocomplete="off"
            maxlength="9"
          />
          <div
            *ngIf="
              searchForm.controls['applicationId'].invalid &&
              (searchForm.controls['applicationId'].dirty ||
                searchForm.controls['applicationId'].touched)
            "
            class="alert alert-danger mt-1"
          >
            <div *ngIf="searchForm.controls['applicationId'].errors">
              optional but only it allows numerics
            </div>
          </div>
        </div>

        <div class="search-col">
          <label for="firstName" class="title">First Name</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            formControlName="firstName"
            class="form-control"
            autocomplete="off"
          />
        </div>

        <div class="search-col">
          <label for="lastName" class="title">Last Name</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            formControlName="lastName"
            class="form-control"
            autocomplete="off"
          />
        </div>

        <div class="search-col">
          <label for="state" class="title">State</label>
          <select
            id="state"
            name="state"
            formControlName="state"
            class="form-control"
          >
            <option value="" selected>Select State</option>
            <option *ngFor="let opt of states" [value]="opt.state">{{
              opt.state
            }}</option>
          </select>
        </div>

        <div class="search-col-submit">
          <label for="submit" class="title invisible">submit</label>
          <button
            class="btn btn-primary"
            [disabled]="searchForm.invalid"
            type="submit"
            form="searchForm"
            id="submit"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="call-info" *ngIf="isSearch === false; else tableRecords">
  Please search to see records
</div>

<ng-template #tableRecords>
  <table class="table table-list box-shadow table-striped mt-3">
    <thead class="table-header">
      <tr>
        <th>#</th>
        <th>Applicant</th>
        <!-- <th>Verifier</th> -->
        <th>Company</th>
        <th>State</th>
        <th>
          EzApp <br />
          Create Date
        </th>
        <th>
          EzApp <br />
          Submit Date
        </th>
        <!-- <th>Status</th> -->
        <!-- <th>Actions</th> -->
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="competedCallList?.length > 0">
        <tr
          *ngFor="let item of competedCallList | slice: 0:pageSize; index as i"
        >
          <td>{{ i + 1 }}</td>
          <td>
            <div>
              {{ item.firstName | uppercase }} {{ item.lastName | uppercase }}
            </div>
            <div>({{ item.applicationId }})</div>
          </td>
          <!-- <td>Verifier</td> -->
          <td>{{ item.company }}</td>
          <td>{{ item.state }}</td>
          <td>
            <div>{{ item.createDate | datewithTimeZone: timeZone:"date" }}</div>
            <div class="f-12 font-weight-bold">
              {{ item.createDate | datewithTimeZone: timeZone:"time" }}
            </div>
          </td>
          <td>
            <div>{{ item.submitDate | datewithTimeZone: timeZone:"date" }}</div>
            <div class="f-12 font-weight-bold">
              {{ item.submitDate | datewithTimeZone: timeZone:"time" }}
            </div>
          </td>
          <!-- <td>Status</td> -->
          <!-- <td>
            <button
              class="btn btn-outline-primary btn-sm mr-2"
              (click)="viewCallResponse(item.applicationId, content, item)"
            >
              View
            </button>

            <button
              class="btn btn-outline-success btn-sm"
              (click)="navToModule('recall/managerecall/' + item.applicationId)"
            >
              Recall
            </button>
          </td> -->
        </tr>
      </ng-container>

      <tr class="box-shadow" *ngIf="competedCallList?.length === 0">
        <td colspan="7">
          <p class="mt-2 mb-2">Records not found with this search criteria</p>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<div *ngIf="competedCallList?.length > 0" class="mt-2 mb-2 float-right">
  <div>
    <button
      [ngClass]="{
        'btn btn-outline-primary btn-md': presentPage !== 1,
        'no-button': presentPage === 1
      }"
      (click)="move(-1)"
    >
      Prev
    </button>

    <button
      class="btn btn-outline-primary btn-md ml-2"
      *ngIf="competedCallList?.length > pageSize"
      (click)="move(1)"
    >
      Next
    </button>
  </div>
</div>
