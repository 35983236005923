const voiceMailMessage =
  'This message is for {{ application.primaryApplicant.firstName }} {{application.primaryApplicant.lastName}}. This is {{agentName}} calling with "{{application.company| translate:fullForm}}" for your scheduled verification call. I will try to reach you one moretime in the next few minutes. If I do not reach you, I will notify your agent to have your appointment rescheduled. If you need to contact us please call us back at {{application.company | translate:phone}} and select option \n 1. Our regular business hours are Monday - Friday 8:00AM - 5:00PM Central Standard Time.';

const voiceMailMessageFollowup =
  'This message is for {{ application.primaryApplicant.firstName }} {{application.primaryApplicant.lastName}}. This is {{agentName}} calling with "{{application.company| translate:fullForm}}" for your scheduled verification call. I will notify your agent to have your appointment rescheduled. If you need to contact us please call us back at {{application.company | translate:phone}} and select option \n 1. Our regular business hours are Monday - Friday 8:00AM - 5:00PM Central Standard Time.';

const voiceMailRecallMessage =
  'This message is for {{ dialingInformationModel.businessName }}. This is {{agentName}} calling with "{{application.company| translate:fullForm}}" for your scheduled verification call. I will try to reach you one moretime in the next few minutes. If I do not reach you, I will notify your agent to have your appointment rescheduled. If you need to contact us please call us back at {{application.company | translate:phone}} and select option \n 1. Our regular business hours are Monday - Friday 8:00AM - 5:00PM Central Standard Time.';

const voiceMailRecallMessageFollowup =
  'This message is for {{ dialingInformationModel.businessName }}. This is {{agentName}} calling with "{{application.company| translate:fullForm}}" for your scheduled verification call. I will notify your agent to have your appointment rescheduled. If you need to contact us please call us back at {{application.company | translate:phone}} and select option \n 1. Our regular business hours are Monday - Friday 8:00AM - 5:00PM Central Standard Time.';
const thirdPartyIntroductionMessage =
  'Hello – this is {{ agent.name}} calling from "{{application.company| translate:fullForm}}". You were listed as a Payer for an insurance application submitted by {{ application.primaryApplicant.firstName }} {{application.primaryApplicant.lastName}}. We need to speak with you to obtain your authorization for payment before we can process this application.';
const thirdPartyVoicemailMessage =
  'Hello – this is {{ agent.name}} calling from "{{application.company| translate:fullForm}}". You were listed as a Payer for an insurance application submitted by {{ application.primaryApplicant.firstName }} {{application.primaryApplicant.lastName}}. We need to speak with you to obtain your authorization for payment before we can process this application.<p class="mt-3"> Please call us back at {{application.company | translate:phone}} and select option 3. Our regular business hours are Monday-Friday 8:00am – 5:00pm central standard time.</p>';
export {
  voiceMailMessageFollowup,
  voiceMailMessage,
  thirdPartyIntroductionMessage,
  thirdPartyVoicemailMessage,
  voiceMailRecallMessage,
  voiceMailRecallMessageFollowup,
};
