/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopInterceptor } from '../http-interceptors/noop-interceptor';
import { AuthInterceptor } from './auth-interceptor';
import { DataMockingInterceptor } from './data-mocking-interceptor';
import { HttpLoggerInterceptor } from './http-logger-interceptor';
import { HttpMessageInterceptor } from './http-message-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NoopInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DataMockingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpMessageInterceptor,
    multi: true,
  },
];
