import { Component, OnInit } from '@angular/core';
/**
 * Page not found component
 */
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  /**
   * Page not found constructor
   * @ignore ignore
   */
  constructor() {}

  /**
   * Component initialization
   * @ignore ignore
   */
  ngOnInit() {}
}
