import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { Subscription } from 'rxjs';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { VCallApplicant } from 'src/app/shared/models/vcall/vcallApplicants';
import { Application } from 'src/app/shared/models/application/application';
import { Appointment } from 'src/app/shared/models/appointment';
import { DetailOptions } from 'src/app/shared/models/detail-options';

@Component({
  selector: 'app-vcall-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class VcallNavHeaderComponent implements OnInit, OnDestroy {
  constructor(
    private profileService: UserProfileService,
    private applicationService: ApplicationService,
    private appointmentService: AppointmentService
  ) {}
  today: string = new Date().toLocaleDateString();
  onlineApplicants: VCallApplicant[] = new Array<VCallApplicant>();
  profile: UserProfile;
  subscriptions: Subscription[] = [];
  application: Application;
  appointment: Appointment;
  onStopCallReason = false;
  openModal = false;
  dropdownMenu = 'dropdownMenuButton-2';
  config: DetailOptions = {
    canStartVerification: false,
    canAddNotes: true,
  };
  ngOnInit() {
    this.subscriptions.push(
      this.profileService.userProfile.subscribe((s) => {
        this.profile = s;
      })
    );
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a !== null) {
          this.application = a;
        }
      })
    );
    this.applicationService.OnlineApplicants.subscribe((applicants) => {
      this.onlineApplicants = applicants;
    });
    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe((p) => {
        if (p !== null) {
          this.appointment = p;
        }
      })
    );
  }

  getNotifyEvent() {
    this.openModal = false;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
