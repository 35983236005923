import { Component, HostListener, OnDestroy } from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { Subject, Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { BaseQuestionType } from '../baseQuestionType';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { TemplateModel } from 'src/app/shared/models/vcall/templateModel';
import { QuestionModal } from 'src/app/recall/recall-addquestion/recall-addquestion-modal';
import { RecallQuestion } from 'src/app/shared/models/recall/RecallQuestion';
import { Recall } from 'src/app/shared/models/recall/Recall';
import { VcallApiService } from 'src/app/shared/services/api/vcall/vcall-api.service';
import { ActivatedRoute } from '@angular/router';
import { RecallInfo } from 'src/app/shared/models/recall/RecallInfo';
import { DatePipe } from '@angular/common';
import { RecallStatus } from 'src/app/shared/models/recall/RecallStatus';
import { VcallParams } from 'src/app/shared/models/vcall/vcallParams';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-applicant-recall-question',
  templateUrl: './applicant-recall-question.component.html',
  styleUrls: ['./applicant-recall-question.component.scss'],
})
export class ApplicantRecallQuestionComponent
  extends BaseQuestionType<VcallQuestionTypeRecallDetail>
  implements OnDestroy
{
  question: Question = null;
  details: VcallQuestionTypeRecallDetail = new VcallQuestionTypeRecallDetail();
  subscriptions: Array<Subscription> = new Array<Subscription>();
  elementQuestionIdTrackerId: number;
  answerList: any;
  recallAnswer;
  model: TemplateModel = new TemplateModel();
  recallAppointmentData: QuestionModal;
  showFooter = true;
  openModal = false;
  clearData: RecallQuestion[];
  listofAddedQuestions: RecallQuestion[] = [];
  recallBeingEdited: Recall;
  applicationId: string;
  underWriter: string;
  existingQuestions = [];
  getNameKey = RecallQuestion.GetNameKey;
  private debouncePersistAnswersSubject = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    private userProfileService: UserProfileService,
    private vcallApiService: VcallApiService,
    private route: ActivatedRoute,
    private date: DatePipe
  ) {
    super(script, appService, ui);
    this.applicationId = this.route.snapshot.params['applicationId'];
    this.getUserProfileData();
    this.debouncePersistAnswersSubject
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe(() => this.applicationService.PersistAnswers(false));
  }

  getUserProfileData() {
    this.subscriptions.push(
      this.userProfileService.userProfile.subscribe((user) => {
        this.underWriter = user.name;
      })
    );
  }

  constructRecall(contactType): Recall {
    return {
      appId: Number(this.applicationId),
      status: RecallStatus.scheduled,
      created: this.date.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
      lastUpdated: null,
      state: this.application.state,
      appointmentId: Number(this.script['appointmentId']),
      contactType,
      questionList: [],
    };
  }

  async handleFocusOut(question, event) {
    this.saveAnswerByAnswerTag(
      true,
      event.target.value,
      question.answerTag,
      question.questionText
    );
    await this.applicationService.PersistAnswers(false); // Added the hack for to fix the missing saving answers
  }

  @HostListener('document:keydown.tab', ['$event'])
  async onKeyDownTab(event: KeyboardEvent) {
    const activeElement = document.activeElement;
    if (
      event.key === 'Tab' &&
      (activeElement.tagName.toLowerCase() === 'textarea' ||
        (activeElement.tagName.toLowerCase() === 'input' &&
          activeElement.getAttribute('type') === 'checkbox'))
    ) {
      let questionData = activeElement
        .closest('.question-container')
        .querySelector('p')
        .getAttribute('data-question');
      let parsedQuestionData = questionData && JSON.parse(questionData);
      let element = activeElement as HTMLInputElement;
      if (activeElement.tagName.toLowerCase() === 'textarea') {
        this.saveAnswerByAnswerTag(
          true,
          element.value,
          parsedQuestionData.answerTag,
          parsedQuestionData.questionText
        );
      } else {
        this.saveAnswerByAnswerTag(
          true,
          this.getAnswerForAnswerTag(parsedQuestionData.answerTag),
          parsedQuestionData.answerTag,
          parsedQuestionData.questionText,
          null,
          element.checked
        );
      }
      this.debouncePersistAnswersSubject.next();
    }
  }

  SaveText(question, ev) {
    this.saveAnswerByAnswerTag(
      true,
      ev.target.value,
      question.answerTag,
      question.questionText
    );
  }

  SavePastedText(question, ev) {
    if (ev?.clipboardData && ev.clipboardData.getData('text')) {
      this.saveAnswerByAnswerTag(
        true,
        this.pastedText(ev),
        question.answerTag,
        question.questionText
      );
    }
    ev.preventDefault();
  }
  /**
   *
   *
   * @returns string with pasted text at CursorPoint Placed
   */
  pastedText(ev) {
    const cursorStartIndex = ev.target.selectionStart;
    const cursorEndIndex = ev.target.selectionEnd;
    const text = ev.target.value;
    const value = ev?.clipboardData?.getData('text');

    if (cursorStartIndex === cursorEndIndex) {
      return (
        text.substr(0, cursorStartIndex) + value + text.substr(cursorStartIndex)
      );
    }
    return (
      text.substr(0, cursorStartIndex) + value + text.substr(cursorEndIndex)
    );
  }

  ResetView() {}

  getExistingQuestions() {
    this.script['script'].items.filter((q) => {
      if (q.section.name === 'Recall') {
        q.questions.forEach((question) => {
          this.existingQuestions.push(question);
        });
      }
      return q;
    });
  }

  openAddQuestionModal() {
    this.openModal = true;
    this.appService.PersistAnswers(false);
  }

  SubmitAndCancelHandler(ev) {
    if (ev?.toLowerCase() === 'done') {
      this.saveCustomAddedQuestions();
    } else {
      this.openModal = false;
    }
    this.clearData = this.listofAddedQuestions;
  }

  saveCustomAddedQuestions() {
    this.getExistingQuestions();
    const currentApplicantName = this.details['questionList'][0].applicantName;
    const contactType = this.script['script']['contactType'];
    this.recallBeingEdited = this.constructRecall(contactType);
    this.listofAddedQuestions.map((eachQuestion: any, index) => {
      eachQuestion.recallReason =
        this.recallAppointmentData[index]?.reason ||
        this.recallAppointmentData?.reason;
      eachQuestion.reason =
        this.recallAppointmentData[index]?.reason ||
        this.recallAppointmentData?.reason;
      eachQuestion.applicantName = currentApplicantName;
      eachQuestion.relationship =
        this.recallAppointmentData[index]?.relationship ||
        this.recallAppointmentData?.relationship;
      eachQuestion.clientNo =
        this.recallAppointmentData[index]?.clientNo ||
        this.recallAppointmentData?.clientNo;
      eachQuestion.questionText = eachQuestion.text;
      eachQuestion.type = eachQuestion.type;
      eachQuestion.sequence = index + 1;
      eachQuestion.created = this.date.transform(
        new Date(),
        'MM/dd/yyyy hh:mm a'
      );
      (eachQuestion.createdBy = this.underWriter),
        (eachQuestion.lastUpdated = this.date.transform(
          new Date(),
          'MM/dd/yyyy hh:mm a'
        ));
      return eachQuestion;
    });
    const totalQuestions = [];
    this.existingQuestions.forEach((q) => {
      q['details']['questionList'].find((question) => {
        if (question.applicantName === currentApplicantName) {
          q['details']['questionList'].push(...this.listofAddedQuestions);
        }
        return question.applicantName === currentApplicantName;
      });
    });
    this.existingQuestions.forEach((q) => {
      const questionList: Array<any> = q['details']['questionList'];
      totalQuestions.push(...questionList);
    });
    const recall = this.recallBeingEdited;
    recall.questionList = totalQuestions;
    recall.id = this.script['script']['id'];
    this.constructAnswerTagForRecall(recall, recall.appointmentId);
    this.saveRecall(recall);
    this.openModal = false;
  }

  constructAnswerTagForRecall(recall, appointmentId) {
    recall.questionList.map((q, index) => {
      q.status = recall.status;
      q.answerTag = q.answerTag
        ? q.answerTag
        : (
            RecallInfo.callType +
            String(appointmentId) +
            '-' +
            Number(recall.questionList.length) +
            index
          ).toString();
    });
    return recall;
  }

  saveListOfAddedQuestions(ev) {
    this.listofAddedQuestions = ev;
  }

  saveFormData(ev) {
    this.recallAppointmentData = ev;
  }

  saveRecall(recall: Recall) {
    const message = recall;
    this.vcallApiService
      .insertRecallQuestions(message, this.applicationId)
      .subscribe((res: any) => {
        window.location.reload();
      });
  }

  saveIgnoredFlag(question, ev) {
    this.saveAnswerByAnswerTag(
      true,
      this.getAnswerForAnswerTag(question.answerTag),
      question.answerTag,
      question.questionText,
      null,
      ev.target.checked
    );
    this.debouncePersistAnswersSubject.next();
  }

  saveAnswerByAnswerTag(
    verification: boolean,
    answer: any,
    answerTag,
    questionText,
    answerType?: string,
    ignored?: boolean
  ) {
    this.applicationService.RegisterAnswerByTag(
      verification,
      answer,
      answerTag,
      null,
      null,
      this.interpolateQuestionText(questionText),
      this.question?.type,
      null,
      ignored
    );
    this.script.UpdateAnswer(this.CanGoNext(), answer); //
  }

  ApplicationChanged(): void {}

  interpolateQuestionText(text) {
    return this.safeInterpolate(text);
  }

  getAnswerForAnswerTag(answerTag) {
    const answer = this.appService.GetAnswerForAnswerTag(answerTag);
    return answer ? answer?.value : '';
  }

  getIgnoredFlagForAnswerTag(answerTag) {
    const answer = this.appService.GetAnswerForAnswerTag(answerTag);
    return answer?.ignored;
  }

  QuestionChanged(q: Question): void {
    const canNavigateNext = this.checkForNavigation();
    if (canNavigateNext) {
      this.script.UpdateAnswer(canNavigateNext, true); //
    }
  }

  CanGoNext(): boolean {
    return this.checkForNavigation();
  }

  checkForNavigation() {
    return this.details.questionList?.every((question) => {
      return (
        this.getAnswerForAnswerTag(question.answerTag) ||
        this.getIgnoredFlagForAnswerTag(question.answerTag)
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((o) => o.unsubscribe());
    this.destroy$.unsubscribe();
  }
}

export class VcallQuestionTypeRecallDetail {
  createdBy: string;
  recallReason: string;
  applicantName: string;
  questionText: string;
  status: string;
  created: string;
  questionList: ApplicantRecallTypeQuestion[];
  relationShip?: string;
}

export class ApplicantRecallTypeQuestion {
  text: string;
  applicantName: string;
  reason: string;
  recallReason: string;
  questionText: string;
  sequence: number;
  createdBy: string;
  createdDate: string;
  lastUpdated: string;
  status: RecallStatus;
  answerTag: string;
  appointmentId: number;
  relationship: string;
  clientNo: string;
}
