import { Component, OnInit, ViewChild } from '@angular/core';
import { DoctorLookupComponent } from '../../vcall/doctor-lookup/doc-lookup.component';
import { DoctorLookup } from 'src/app/shared/models/drLookup';
import { of, BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-providerlookup',
  templateUrl: './providerLookup.component.html',
  styleUrls: ['./providerLookup.component.scss'],
})
export class ProviderLookupComponent implements OnInit {
  @ViewChild(DoctorLookupComponent) drLookupComp: DoctorLookupComponent;
  updatedDoctorList = [];
  doctorInitialList: any[] = [
    {
      id: '1450905757',
      city: 'Odessa',
      first_name: 'Othelia',
      distance: 'Female',
      last_name: 'Buckoke',
      phone_numbers: 432 - 831 - 2453,
      specialties: '4308',
      state: 'TX',
      street_address: '31 Barby Trail',
      zipcode: '79769',
    },
    {
      id: '2929217537',
      city: 'Round Rock',
      first_name: 'Jock',
      distance: 'Male',
      last_name: 'Goldberg',
      phone_numbers: 512 - 719 - 6767,
      specialties: '4127',
      state: 'TX',
      street_address: '87671 Northfield Hill',
      zipcode: '78682',
    },
  ];

  private dataSource: Observable<any>;
  constructor() {}

  ngOnInit() {
    this.updatedDoctorList = [...this.doctorInitialList];
  }

  getUpdatedDrList(x) {
    console.log(x);
    this.dataSource = of(x);
    this.dataSource.subscribe((m) => {
      this.updatedDoctorList = m;
    });
  }
}
