<div [formGroup]="verifiableform" *ngIf="!isCustomEdit">
  <div *ngIf="model">
    <div *ngFor="let item of model; let i = index">
      <div
        *ngIf="item.label !== null"
        class="row pt-3 pb-2 border-bottom"
        [ngClass]="{
          invisible: show[i] === undefined,
          'bg-light': i % 2 === 0 && !item.changedValue,
          'bg-changed': item.changedValue
        }"
      >
        <div class="col-2">
          <b>{{ item.label }} </b>
        </div>
        <div
          class="cursor-pointer"
          *ngIf="
            !isEdit[i] &&
            item.required &&
            item.value !== '' &&
            item.value !== null
          "
          (click)="isEdit[i] = true"
        >
          <i class="fa fa-edit"></i>
        </div>
        <div
          class="col-2"
          *ngIf="!isEdit[i] && item.value !== '' && item.value !== null"
        >
          <span
            [ngClass]="{
              'text-uppercase':
                item.propertyName === 'bankName' ||
                item.propertyName === 'initPmtBankName'
            }"
          >
            {{ item.value }}
          </span>
        </div>
        <div
          class="col-2"
          *ngIf="
            isEdit[i] ||
            (item.required && (item.value === '' || item.value === null))
          "
        >
          <div [ngSwitch]="item.controlType">
            <div class="input-group">
              <input
                class="form-control col-8"
                *ngSwitchCase="'textbox'"
                [formControlName]="item.propertyName"
                [required]="item.required"
                [pattern]="item.pattern"
                [maxlength]="item.maxLength"
                [minlength]="item.minLength"
                [id]="item.propertyName"
                [type]="item.type"
                oninput="this.value = this.value.toUpperCase()"
                [ngClass]="{
                  'text-uppercase':
                    item.propertyName === 'bankName' ||
                    item.propertyName === 'initPmtBankName'
                }"
              />
              <div class="input-group-append" id="button-addon4">
                <button
                  class="btn border border-danger hover-danger"
                  (click)="cancel(item); isEdit[i] = false"
                  [disabled]="item.value === null || item.value === ''"
                >
                  <i class="fas fa-times text-danger hover-white"></i>
                </button>
                <button
                  class="btn border border-primary hover-success"
                  (click)="save(item); isEdit[i] = false"
                  [disabled]="!formValidity"
                >
                  <i class="fas fa-save text-primary hover-white"></i>
                </button>
              </div>
            </div>

            <div *ngIf="!formValidity" class="mt-1 text-danger small">
              <span
                *ngIf="
                  isValid(item.propertyName) !== null &&
                  isValid(item.propertyName).required
                "
              >
                {{ item.label }} is Required
              </span>
            </div>

            <div *ngIf="!formValidity" class="mt-1 text-danger small">
              <span
                *ngIf="
                  isValid(item.propertyName) !== null &&
                  (isValid(item.propertyName).pattern ||
                    isValid(item.propertyName).minlength)
                "
              >
                Please enter valid {{ item.label }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="btn-group"
          role="group"
          class="col-3"
          [style.visibility]="
            show[i] && !isEdit[i] && item.value !== '' && item.value !== null
              ? 'visible'
              : 'hidden'
          "
        >
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm mr-2"
            *ngIf="item.copyMapper !== undefined"
            (click)="resetDetails(item)"
          >
            <img
              src="./assets/images/ushg/reset.svg"
              alt="image"
              width="20"
              height="20"
              title="Reset to EzApp values"
            />
          </button>
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary"
            *ngIf="item.copyMapper !== undefined"
            (click)="copyDetails(item)"
          >
            <img
              src="./assets/images/ushg/copy.svg"
              alt="image"
              width="20"
              height="20"
              title="Copy from Recurring Payment"
            />
          </button>
        </div>

        <div
          class="col-3"
          [style.visibility]="
            show[i] && !isEdit[i] && item.value !== '' && item.value !== null
              ? 'visible'
              : 'hidden'
          "
        >
          &nbsp; &nbsp; &nbsp; &nbsp;
          <label
            class="cursor-pointer btn border border-success"
            [ngClass]="
              item.verification === true ? 'btn-success' : 'btn-outline-success'
            "
          >
            <input
              type="radio"
              [formControlName]="i"
              required
              [value]="true"
              (change)="
                optionChanged(item, verifiableform.valid);
                show[(i + 1).toString()] = true
              "
              id="{{ 'verification' + i }}"
              class="hide-radio-inputs"
            />
            Verified
          </label>
          &nbsp;
          <label
            class="cursor-pointer btn border border-danger"
            [ngClass]="
              item.verification === false ? 'btn-danger' : 'btn-outline-danger'
            "
          >
            <input
              type="radio"
              [formControlName]="i"
              required
              id="{{ 'verification' + i }}"
              [value]="false"
              (change)="
                optionChanged(item, verifiableform.valid);
                show[(i + 1).toString()] = true
              "
              class="hide-radio-inputs"
            />
            Not Verified
          </label>
        </div>

        <div
          class="col-1 p-0"
          [style.visibility]="item.changedValue ? 'visible' : 'hidden'"
        >
          <span>{{ item.originalValue }}</span>
        </div>

        <div></div>
      </div>
    </div>
  </div>
</div>

<form #dynamicForm="ngForm" *ngIf="isCustomEdit">
  <div *ngIf="model">
    <div *ngFor="let item of model; let i = index">
      <div
        class="row pt-3 pb-2 border-bottom"
        [ngClass]="{
          invisible: show[i] === undefined,
          'bg-light': i % 2 === 0 && !item.changedValue,
          'bg-changed': item.changedValue
        }"
      >
        <div class="col-2">
          <b>{{ item.label }} </b>
        </div>
        <div class="col-4">
          <span> {{ item.value }} </span>
        </div>
        <div class="col-3">
          &nbsp; &nbsp; &nbsp; &nbsp;
          <label
            class="cursor-pointer btn border border-success"
            [ngClass]="
              item.verification === true ? 'btn-success' : 'btn-outline-success'
            "
          >
            <input
              type="radio"
              [(ngModel)]="item.verification"
              required
              [value]="true"
              (change)="
                optionChanged(item, dynamicForm.valid);
                show[(i + 1).toString()] = true
              "
              name="{{ 'verification' + i }}"
              class="hide-radio-inputs"
            />
            Verified
          </label>
          &nbsp;
          <label
            class="cursor-pointer btn border border-danger"
            [ngClass]="
              item.verification === false ? 'btn-danger' : 'btn-outline-danger'
            "
          >
            <input
              type="radio"
              [(ngModel)]="item.verification"
              required
              name="{{ 'verification' + i }}"
              [value]="false"
              (change)="
                optionChanged(item, dynamicForm.valid);
                show[(i + 1).toString()] = true
              "
              class="hide-radio-inputs"
            />
            Not Verified
          </label>
        </div>
        <div *ngIf="item.changedValue" class="col-2">
          <span>{{ item.originalValue }}</span>
        </div>
      </div>
    </div>
  </div>
</form>
