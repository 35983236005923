<div class="container error-page-container">
  <div class="row">
    <div class="col text-center">
      <i class="not-authorized-lock material-icons">location_off</i>
    </div>
  </div>
  <h1 class="align-center">Oops!</h1>
  <p class="align-center">We can't seem to find the page you're looking for.</p>
  <div class="row">
    <div class="col text-center">
      <a href="/" class="btn btn-primary mobile-stacked-btn">Start Over</a>
      <!-- // TODO: <a [routerLink]="['/']" class="btn btn-primary mobile-stacked-btn"
        >Start Over</a
      > -->
    </div>
  </div>
</div>
