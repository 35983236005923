export enum ApplicationStatus {
  'Initial State' = 10,
  'VCall Scheduled' = 100,
  'VCall Started' = 110,
  'VCall Completed' = 120,
  'Recall Created' = 130,
  'Recall Completed' = 140,
  'With UnderWriting Team' = 200,
  'With APS Team' = 300,
  'APS OrderQueue' = 310,
  'APS Pending' = 320,
  'FollowUp Queue' = 330,
  'APS Returned' = 210,
  'Routed To Issue' = 400,
}

export enum ApplicationTrackerType {
  Primary = 'Primary',
  AddOnApplication = 'AddOnApplication',
}
