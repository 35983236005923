import { gql } from 'apollo-angular';

export function getOrderRequest(appId) {
  return gql`
  query
  {
    application(appId:${appId})
    {
      id
      primaryApplicant
      {
        firstName
        middleName
        lastName
        birthDate
        gender
        clientNo
      }
      currentUnderWriter
      currentUnderWriterAssignedOn
      aPSReturnedDate:apsReturnedDate
      applicationTracker {
        dueDate
        assignedTo
        assignedOn
        id
        applicationStatusId
        applicationTrackerType
        addOnApplicationId
        convertedVersion
        applicationStatusHistories {
          applicationStatusId
          id
          createdDate
          applicationTrackerId
        }
      }
      state    
      orderDetails
      {
        clientNumber
        requestType
        orderTrackerId
        orderStatusId
        applicationTrackerId
        id
        state
        applicationId
        createdBy
        updatedBy
        createdDate
        orderDocuments {
          appId
          orderId
          clientNumber
          documents
            {
              name
              content
              documentType
              createdDate
              updatedDate
            }
          }
        labs {
          name
          notes
          aPSNotes:apsNotes
        }
        aps {
          aPSNotes:apsNotes
          uWSNotes:uwsNotes
          reason
          provider {
          fullname
          streetAddress
          city
          state
          phone
          is_basic
          }
        }
      }
    }
  }
  `;
}

export const CREATE_ORDER = gql`
  mutation CreateOrderRequest($request: String) {
    createOrderRequest(request: $request) {
      applicationDetails {
        id
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrderRequestById($request: String) {
    updateOrderRequestById(request: $request) {
      applicationDetails {
        id
      }
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($request: String) {
    deleteOrder(request: $request) {
      applicationDetails {
        id
      }
    }
  }
`;

export const ROUTETO_APS = gql`
  mutation changeTeam($request: String) {
    changeTeam(request: $request) {
      applicationDetails {
        id
      }
    }
  }
`;

export const ORDER_DOCUMENTS_REQUEST = gql`
  query downloadOrderFiles($request: String) {
    downloadOrderFiles(orderDocuments: $request) {
      documents {
        name
        content
        documentType
        createdDate
        updatedDate
      }
    }
  }
`;

export const GET_AUDIT_NOTES = gql`
  query auditLogByApplicationId($applicationId: Int!) {
    auditLogByApplicationId(applicationId: $applicationId) {
      id
      information
      loggedBy
      loggedDate
      auditParameter
      orderTrackerId
      auditTypeId
    }
  }
`;

export const INSERT_AUDIT_NOTE = gql`
  mutation insertAuditRecord($auditLogRecord: AuditInput) {
    insertAuditRecord(auditLogRecord: $auditLogRecord) {
      id
    }
  }
`;

export const CREATE_APPLICATION_TRACKER_RECORD = gql`
  mutation createApplicationTrackerRecord($request: String) {
    createApplicationTrackerRecord(request: $request) {
      applicationId
    }
  }
`;

export function GET_APPLICATION_TRACKER_RECORDS(appId) {
  return gql` 
  query {
  application(appId:${appId})
  {
    applicationTracker {
      applicationStatusId
      applicationTrackerType
    }
  }
}
`;
}
