import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from 'src/app/core/logger.service';

@Component({
  selector: 'app-errors-list',
  templateUrl: './errors-list.component.html',
  styleUrls: ['./errors-list.component.scss'],
})
export class ErrorsListComponent implements OnInit {
  errorsCaptured: Error[] = [];
  constructor(
    private modalService: NgbModal,
    private loggerService: LoggerService
  ) {
    this.loggerService.ErrorsList.subscribe((data) => {
      if (data.length) {
        this.errorsCaptured = [...this.errorsCaptured, data];
      }
    });
  }

  /**
   * @ignore ignore
   */
  ngOnInit() {}

  /**
   * @param content form content data
   * @description open the error details dialog
   */
  opoenErrorDetailsDialog(content: any): void {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }
}
