import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { tap } from 'rxjs/internal/operators/tap';
import {
  SmartDateAnswerType,
  SmartDateComponent,
} from 'src/app/shared/components/smart-date/smart-date.component';
import { PrescriptionService } from './prescription.service';

@Component({
  selector: 'app-prescription-lookup',
  templateUrl: './prescription-lookup.component.html',
  styleUrls: ['./prescription-lookup.component.scss'],
})
export class PrescriptionLookupComponent implements OnInit {
  prescriptionLookup: UntypedFormGroup;
  prescription: string;
  isDrugExist: boolean;
  maxDate: { year: number; month: number; day: number };
  updatedMedicationList = new Array<PrescriptionLookup>();
  @Output()
  notifyMedicationList = new EventEmitter();
  @Input() medicationList: PrescriptionLookup[] = [];
  @Input() initialMedicationList$: Observable<PrescriptionLookup[]>;
  @Input() medicationDrilldownList: PrescriptionLookup[] = [];
  @ViewChild(SmartDateComponent) smartDateComponent: SmartDateComponent;
  searching = false;
  searchFailed = false;
  isMedicationUpdated = false;
  openModal = false;
  hideNotes = true;
  selectedIndex: number;
  ngbFormatDate: any;
  smartDate: string | Date;

  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionService
  ) {
    const current = new Date();
    this.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  ngOnInit() {
    this.initForm();
    if (this.initialMedicationList$) {
      this.initialMedicationList$.subscribe((list) => {
        this.updatedMedicationList = [...list];
      });
    } else {
      this.updatedMedicationList = this.medicationDrilldownList
        ? this.medicationDrilldownList
        : [];
    }
  }

  /**
   * @description it will have all the control in the form
   */
  initForm() {
    this.prescriptionLookup = this.fb.group({
      medication: [null, Validators.required],
      dosage: [null],
      lastDate: [null],
      doctorName: [null],
      reason: [null],
    });
  }

  /**
   * @param text$ search paramenter
   * @returns will give the results based on the medication search
   */
  onSearchMedication = (text$: Observable<string>) =>
    text$.pipe(
      tap(() => ((this.searching = true), (this.isDrugExist = false))),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((drug) =>
        drug === '' || drug.length < 3
          ? ((this.searching = false), [])
          : this.prescriptionService
              .SearchMedicationOrDrug(this.prescription)
              .pipe(
                map((results) => {
                  // this.prescription = null;
                  if (results[0] == null) {
                    this.searchFailed = true;
                  } else {
                    this.searchFailed = false;
                    return results;
                  }
                }),
                catchError((err) => {
                  if (err) {
                    this.searchFailed = true;
                  }
                  return of([]);
                })
              )
      ),
      tap(() => (this.searching = false))
    );

  /**
   * @description Used to reset the form data
   */
  resetForm() {
    this.prescriptionLookup.reset();
  }

  getSmartDateDetails(event) {
    this.smartDate =
      event.value.dateOption === SmartDateAnswerType.ExactDate
        ? event.value.date
        : event.value.period;
  }
  /**
   * @param e search results object
   * @description used to reset the search field after search & will patch the search medication on medication
   */
  selectedItem(e: NgbTypeaheadSelectItemEvent) {
    e.preventDefault();
    this.prescription = null;
    this.prescriptionLookup.patchValue({ medication: e.item });
  }

  /**
   * @param isUpdate key is used to know that it's new add or an update
   * @description used to add new medication or to update the existing one
   */
  addMedication(isUpdate) {
    this.prescriptionLookup.patchValue({ lastDate: this.smartDate });
    if (this.updatedMedicationList?.length > 0) {
      this.isDrugExist =
        this.updatedMedicationList.findIndex(
          (el) => el.medication === this.prescriptionLookup.value.medication
        ) !== -1;
    }
    if (isUpdate) {
      this.updatedMedicationList[
        this.selectedIndex
      ] = this.prescriptionLookup.value;
    } else if (!this.isDrugExist) {
      this.updatedMedicationList.push(this.prescriptionLookup.value);
    }
    this.notifyMedicationList.emit(this.updatedMedicationList);
    this.resetForm();
    this.isMedicationUpdated = false;
    this.smartDateComponent.resetForm();
    this.smartDate = '';
  }

  /**
   * @param data selected object data
   * @param index selected index
   * @description used to patch the data to the form once we click on edit
   */
  editMedication(data, index) {
    this.openModal = false;
    this.selectedIndex = index;
    this.prescriptionLookup.patchValue({
      medication: data.medication,
      dosage: data.dosage,
      lastDate: data.lastDate,
      doctorName: data.doctorName,
      reason: data.reason,
    });
    this.isMedicationUpdated = true;
    this.smartDateComponent.updateSmartDate(data);
  }

  /**
   * @param index selected index
   * @description used to delete the medication
   */
  deleteMedication(index) {
    this.openModal = true;
    this.selectedIndex = index;
  }

  /**
   * @param action key Done
   * @description after confirm delete the selected drug
   */
  confirm(action: any) {
    if (action === 'Done') {
      this.updatedMedicationList.splice(this.selectedIndex, 1);
    }
    this.notifyMedicationList.emit(this.updatedMedicationList);
    this.openModal = false;
  }
}

export class PrescriptionLookup {
  medication: string;
  dosage: string;
  lastDate: Date | string;
  doctorName: string;
  reason: string;
}

interface Date {
  day: number;
  month: number;
  year: number;
}
