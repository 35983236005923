import { Injectable, Injector } from '@angular/core';
import { RecallInfo } from 'src/app/shared/models/recall/RecallInfo';
import { UiServiceService } from '../../ui-service.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';
import { LoggerService } from 'src/app/core/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ValidateDataBeforeCompleteProcessor implements Processor {
  name = 'Validating Data before complete the call';
  mustWaitForFinish: boolean;
  stopIfError: any;
  tags: string[] = [
    'Demographic.ContactInformation',
    'Medical.Tobacco',
    'Demographic.Applicants',
    'Medical.UnderstandProcessOfAdoption',
    'MedicalHistory.GreenCard',
    'MedicalHistory.AddressOfDoctor',
  ];
  stopProcessingResult: boolean = false;

  constructor(
    private uiService: UiServiceService,
    private injector: Injector
  ) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    if (model.appointment.callType !== RecallInfo.callType) {
      let allQuestions = [];
      model.script.items.forEach((a) => {
        allQuestions = [...allQuestions, ...a.questions];
      });
      let existsTagsQuestions = allQuestions.filter((q) =>
        this.tags.includes(q.answerTag)
      );
      let requiredTagAnswers: string[] = [];
      existsTagsQuestions.forEach((a) => {
        requiredTagAnswers = [...requiredTagAnswers, ...[a.answerTag]];
      });
      this.stopProcessingResult = requiredTagAnswers.every((tag) =>
        model.answers.some((a) => a.answerTag === tag)
      );
      if (!this.stopProcessingResult) {
        let error = {
          message: 'System Identified that several answers are missing.',
          stack: 'Unexpected System Behavior',
        };
        this.injector.get(LoggerService).ErrorsList.next(error);
      }
    } else {
      this.stopProcessingResult = true;
    }
    return Promise.resolve({
      result: true,
      stopProcessing: !this.stopProcessingResult,
    });
  }
}
