import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { Address } from 'src/app/shared/models/address';
import { UspsService } from '../../services/usps.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  switchMap,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Subscription } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { STATES } from 'src/app/shared/models/states';

@Component({
  selector: 'app-usps-address',
  templateUrl: './usps-address.component.html',
  styleUrls: ['./usps-address.component.scss'],
})
export class UspsAddressComponent implements OnInit, OnDestroy, OnChanges {
  @Input() address: Address;
  @Input() field: any = {};
  @Input() form: any;
  @Input() saveClicked: boolean;

  searching = false;
  searchFailed = false;
  isAddressValid = true;
  cityEditable = false;
  stateEditable = false;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  uspsAddress: UntypedFormGroup;
  @Output()
  formComplete = new EventEmitter();
  states: any;

  @Output()
  formValidity = new EventEmitter();

  constructor(private uspsService: UspsService) {}

  ngOnInit(): void {
    this.updateAddress();
  }

  /**
   * this method is used to trigger if parent component values are changed
   * @param change change
   */
  ngOnChanges(change: SimpleChanges) {
    this.updateAddress();
  }

  /**
   * @description check whether form is completed or not
   */
  formCompleted() {
    console.log(this.uspsAddress.value, '90');
    this.formComplete.emit(this.uspsAddress.value);
    this.formIsValid();
  }
  updateAddress() {
    if (this.address.zipCode !== null) {
      // This is for the remove the white space of the Zip Code element
      this.address.zipCode = this.address.zipCode?.replace(/[\s]/g, '');
    }
    this.uspsAddress = new UntypedFormGroup(
      {
        streetAddress: new UntypedFormControl(this.address.streetAddress, [
          Validators.required,
        ]),
        city: new UntypedFormControl(this.address.city, Validators.required),
        state: new UntypedFormControl(this.address.state, Validators.required),
        zipCode: new UntypedFormControl(this.address.zipCode, [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern('\\d{5}'),
        ]),
      },
      { validators: this.validateAddress }
    );

    // to fetch and add state codes
    this.statesList();
    this.formCompleted();
  }

  /**
   * @description check whether form is valid or not
   */
  formIsValid() {
    let allItemsValid = true;
    console.log(this.uspsAddress.controls, '98');
    Object.keys(this.uspsAddress.controls).map(
      (c) =>
        (allItemsValid =
          allItemsValid && this.uspsAddress.controls[c]['status'] === 'VALID')
    );
    this.formValidity.emit(allItemsValid);
    return allItemsValid;
  }

  /**
   * @description validate address of all the form fields
   */
  validateAddress = (
    control: UntypedFormGroup
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    const address = {
      streetAddress: control.get('streetAddress').value,
      zipCode: control.get('zipCode').value,
      city: control.get('city').value,
      state: control.get('state').value,
    };
    return this.uspsService.isAddressValid(address).pipe(
      map((isValid) => (isValid ? isValid : null)),
      catchError(() => of([]))
    );
  };

  get streetAddress() {
    return this.uspsAddress.get('streetAddress');
  }
  get zipCode() {
    return this.uspsAddress.get('zipCode');
  }
  get city() {
    return this.uspsAddress.get('city');
  }
  get state() {
    return this.uspsAddress.get('state');
  }

  /**
   * @description set the state from the state lists
   */
  statesList() {
    this.states = STATES;
  }

  /**
   * @param address address of the USPS
   * @description when change the address check whether address is valid or not
   */
  onAddressChange(address: Address) {
    this.subscriptions.push(
      this.uspsService.isAddressValid(address).subscribe((isValid: boolean) => {
        this.isAddressValid = isValid;
      })
    );
  }

  /**
   * @param text$ text for the search the zipcode
   * @description fetch the zip codes
   * @returns zip code lists
   */
  onZipChange = (text$: Observable<string>) =>
    text$.pipe(
      tap(() => (this.searching = true)),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((zip) =>
        zip.length < 5
          ? ((this.searching = false), [])
          : this.uspsService.getUsps(zip).pipe(
              map((results: Address) => {
                if (results[0].zipCode == null) {
                  this.searchFailed = true;
                } else {
                  this.searchFailed = false;
                  return results;
                }
              }),
              catchError((err) => {
                if (err) {
                  this.searchFailed = true;
                }

                return of([]);
              })
            )
      ),
      tap(() => (this.searching = false))
    );

  /**
   * @param e pass the selected item from the zip code list
   * @description selected the zip code
   */
  selectedItem(e: NgbTypeaheadSelectItemEvent) {
    e.preventDefault();
    this.uspsAddress.patchValue({
      zipCode: e.item.zipCode,
      city: e.item.city,
      state: e.item.state,
    });
    // const index = this.field.options.findIndex(
    //   (ele: any) => ele.state === e.item.state
    // );
    // if (index > -1) {
    //   this.field.options.splice(index, 1);
    //   this.field.options.push({ state: this.field.options[0].state });
    //   this.field.options[0].state = e.item.state;
    // }
    this.formCompleted();
  }

  /**
   * @param x zip code
   * @description format the selected zip code
   * @returns zip code
   */
  formatter = (x: any) => {
    if (typeof x === 'object') {
      console.log('formatter', x);
      this.uspsAddress.patchValue({ zipCode: x.zipCode });
      //  return x.zipCode;
    } else {
      //  this.uspsAddress.patchValue({zipCode: x});
      return x;
    }
  };

  /**
   * @description destroy all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
