import { Component } from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { BaseQuestionType } from '../baseQuestionType';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';

@Component({
  selector: 'app-question-recall',
  templateUrl: './recall.component.html',
  styleUrls: ['./recall.component.css'],
})
export class VcallQuestionTypeRecallComponent extends BaseQuestionType<
  VcallQuestionTypeRecallDetail
> {
  question: Question = null;
  details: VcallQuestionTypeRecallDetail = new VcallQuestionTypeRecallDetail();
  subscriptions: Array<Subscription> = new Array<Subscription>();
  elementQuestionIdTrackerId: number;
  answerList: any;
  recallAnswer;

  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService
  ) {
    super(script, appService, ui);
  }

  SaveText() {
    this.saveAnswer(true, this.recallAnswer);
  }

  ResetView() {
    this.recallAnswer = '';
  }

  ApplicationChanged(): void {}

  QuestionChanged(q: Question): void {
    this.recallAnswer = this.answer ? this.answer.value : '';
  }

  CanGoNext(): boolean {
    return this.recallAnswer !== '';
  }
}

export class VcallQuestionTypeRecallDetail {
  createdBy: string;
  recallReason: string;
  applicantName: string;
  questionText: string;
  status: string;
  created: string;
  relationship?: string;
}
