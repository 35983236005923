import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs/internal/Subscription';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Application } from 'src/app/shared/models/application/application';
import { DoctorLookup } from 'src/app/shared/models/drLookup';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { CstPipe } from 'src/app/shared/pipes/cst.pipe';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ApplicationStringsService } from 'src/app/shared/services/applicationStrings/application-strings.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { DocLookupService } from 'src/app/vcall/doctor-lookup/doc-lookup.service';
import { AddOnsService } from '../add-on/addon.service';
import {
  APSRequestDetail,
  Lab,
  OrderRequestDetails,
  Provider,
} from '../additional-info/additional-info-modal';
import { AdditionalInfoService } from '../additional-info/additional-info.service';
import { orderConfig } from './create-order-config';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss'],
})
export class CreateOrderComponent implements OnInit {
  @Input() openCreateOrderModal$: boolean;
  @Input() applicationTrackerId: number;
  createOrderForm: UntypedFormGroup;
  application: Application;
  @Input() applicants: Applicant[] = [];
  subscriptions = new Array<Subscription>();
  providersList: DoctorLookup[];
  orderRequestList = [];
  rxReasons: string[];
  selectedDoctor: DoctorLookup;
  readonly labAPSRequest = 'APS';
  readonly labAERequest = 'LAB_AE';
  readonly labOERequest = 'LAB_OE';
  readonly labAE_HTWTBP = 'LAB_AE_HTWTBP';
  readonly labOE_HTWTBP = 'LAB_OE_HTWTBP';
  labRequestType = this.labAPSRequest;
  labs = orderConfig.labs;
  requestsList = orderConfig.requestsList;
  editOrder = false;
  duplicateOrderRequest = false;
  selectedEditOrderIndex: number;
  profile: UserProfile;
  updateOrder: OrderRequestDetails;
  groupName: string;

  constructor(
    private fb: UntypedFormBuilder,
    private appService: ApplicationService,
    private applicationStringsService: ApplicationStringsService,
    private docLookUpService: DocLookupService,
    private toaster: ToastService,
    private additionalInfoService: AdditionalInfoService,
    private profileService: UserProfileService,
    private addonService: AddOnsService,
    private cstPipe: CstPipe
  ) {
    this.rxReasons = this.applicationStringsService
      .getStringByKey('APS2.OrderRequestReason1')
      .split(',');
  }

  ngOnInit() {
    this.initForm();
    this.subscriptions.push(
      this.appService.SelectedApplication.subscribe((res) => {
        if (res) {
          this.application = res;
        }
      })
    );
    this.subscriptions.push(
      this.additionalInfoService.orderRequests.subscribe((response) => {
        if (response !== null && response.data.application !== null) {
          this.orderRequestList = response.data.application.orderDetails;
        }
      })
    );

    this.subscriptions.push(
      this.additionalInfoService.EditOrderRequest.subscribe((response) => {
        if (response) {
          this.editOrder = true;
          this.updateOrder = response;
          this.labRequestType = response.requestType;
          this.patchFormGroupData(response);
          this.selectedEditOrderIndex = response['index'];
        }
      })
    );

    this.controlCreateOrderForm();
    this.profileService.userProfile.subscribe((s) => {
      this.profile = s;
    });
  }

  get providers() {
    return this.docLookUpService
      .GetApplicationProviders()
      .sort((a, b) =>
        a.fullname > b.fullname ? 1 : b.fullname > a.fullname ? -1 : 0
      );
  }

  get disableActionsForGroup() {
    return this.labTests().controls.find((control) => control.value.checked);
  }

  getApplicantName(clientNo) {
    return this.applicants.filter((a) => a.clientNo === clientNo)[0]?.fullName;
  }

  getApplicantFirstName(clientNo) {
    return this.applicants.filter((a) => a.clientNo === clientNo)[0]?.firstName;
  }

  initForm() {
    this.createOrderForm = this.fb.group({
      applicant: [null, Validators.required],
      requestType: [null, Validators.required],
      aps: this.fb.group({
        physician: null,
        uWSNotes: null,
        reason: 'Other',
      }),
      tests: this.fb.array([this.createFormGroup()]),
    });
  }

  createFormGroup(): UntypedFormGroup {
    return this.fb.group({
      displayLabel: null,
      notes: null,
      checked: false,
      groupName: null,
      types: null,
    });
  }

  labTests(): UntypedFormArray {
    return this.createOrderForm.controls.tests as UntypedFormArray;
  }

  get apsControl(): UntypedFormGroup {
    return this.createOrderForm.controls.aps as UntypedFormGroup;
  }

  get uwApsNotes() {
    return (this.createOrderForm.controls.aps as UntypedFormGroup).controls
      .uWSNotes;
  }

  controlCreateOrderForm() {
    // push labtest to the formarray controls and patch the value
    while (this.labTests().length) {
      this.labTests().removeAt(0);
    }
    this.labs.forEach((labControl, index) => {
      this.labTests().controls.push(this.createFormGroup());
    });
    this.bindDisplayLableValue();
  }

  bindDisplayLableValue() {
    this.labs.forEach((labControl, index) => {
      if (this.labTests().at(index) as UntypedFormGroup) {
        (
          this.labTests().at(index) as UntypedFormGroup
        ).controls.displayLabel.setValue(labControl.displayLabel);
        (
          this.labTests().at(index) as UntypedFormGroup
        ).controls.groupName.setValue(labControl.groupName);
        (this.labTests().at(index) as UntypedFormGroup).controls.types.setValue(
          labControl.types
        );
      }
    });

    // default disable all textarea form control in form
    this.labTests().controls.forEach((control) => {
      control['controls'].notes.disable();
    });
    this.apsControl['controls'].reason.setValue('Other');
  }

  changeRequestType(value) {
    this.labRequestType = value;
    this.filterLabTestControls(value);
    this.conditionalFormValidation(value);
  }

  filterLabTestControls(val) {
    this.controlCreateOrderForm();
    this.labTests().controls = this.labTests().controls.filter(
      (control) =>
        control['controls'].types.value &&
        control['controls'].types.value.includes(val)
    );
  }

  conditionalFormValidation(requestType) {
    if (requestType === this.labAPSRequest) {
      this.setValidatorForAPS();
      this.createOrderForm.controls['tests'].clearValidators();
      this.createOrderForm.controls['tests'].updateValueAndValidity();
    } else {
      this.createOrderForm.controls['tests'].setValidators(
        this.minSelectedCheckboxes()
      );
      this.labTests().controls.filter(
        (control) =>
          control['controls'].notes.setValidators([
            Validators.required,
            Validators.pattern(`^[^'"]+$`),
          ]) && control['controls'].notes.updateValueAndValidity()
      );
      this.createOrderForm.controls['tests'].updateValueAndValidity();
      this.clearValidatorForAPS();
    }
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls.filter(
        (control) => control.value.checked === true
      ).length;
      if (totalSelected > 0) {
        return null;
      }
      return totalSelected >= min ? null : { required: true }; // if the total is not greater than the minimum, return the error message
    };
    return validator;
  }

  setValidatorForAPS() {
    this.apsControl['controls'].physician.setValidators([Validators.required]);
    this.apsControl['controls'].physician.updateValueAndValidity();
    this.apsControl['controls'].uWSNotes.setValidators([
      Validators.required,
      Validators.pattern(`^[^'"]+$`),
    ]);
    this.apsControl['controls'].uWSNotes.updateValueAndValidity();
    this.apsControl['controls'].reason.setValidators([Validators.required]);
    this.apsControl['controls'].reason.updateValueAndValidity();
  }

  clearValidatorForAPS() {
    this.apsControl['controls'].physician.clearValidators();
    this.apsControl['controls'].physician.updateValueAndValidity();
    this.apsControl['controls'].reason.clearValidators();
    this.apsControl['controls'].reason.updateValueAndValidity();
    this.apsControl['controls'].uWSNotes.clearValidators();
    this.apsControl['controls'].uWSNotes.updateValueAndValidity();
  }

  getPhysicianInfo(doctorName) {
    const doc = this.providers.filter((p) => p.fullname === doctorName)[0];
    this.selectedDoctor = doc;
  }

  getNotesChange(control, index) {
    this.labTests().setControl(index, control);
  }

  enableTestNotes(controlCheck, index) {
    this.groupName =
      controlCheck.value.checked === false
        ? controlCheck.value.groupName
        : null; // to disable other group controls
    // to enable selected checkbox text notes
    this.labTests().controls.forEach((control) => {
      if (
        controlCheck.value.displayLabel ===
        control['controls'].displayLabel.value
      ) {
        controlCheck.value.checked === false
          ? control['controls'].notes.enable()
          : control['controls'].notes.disable();
      }
    });
    this.labTests().setControl(index, controlCheck);
  }

  patchFormGroupData(order) {
    this.createOrderForm.controls.applicant.setValue(order.clientNumber);
    this.createOrderForm.controls.requestType.setValue(order.requestType);
    this.createOrderForm.controls.applicant.disable({ onlySelf: true });
    this.createOrderForm.controls.requestType.disable({ onlySelf: true });
    if (order.requestType === 'APS') {
      this.createOrderForm.controls.aps['controls'].reason.setValue(
        order.aps.reason
      );
      this.createOrderForm.controls.aps['controls'].physician.setValue(
        order.aps.provider.fullname
      );
      this.createOrderForm.controls.aps['controls'].uWSNotes.setValue(
        order.aps.uWSNotes
      );
      this.createOrderForm.controls.aps['controls'].physician.disable({
        onlySelf: true,
      });
    } else {
      order.labs.forEach((lab) => {
        this.labTests().controls.forEach((control) => {
          if (lab.name === control['controls'].displayLabel.value) {
            control['controls'].notes.enable();
            control['controls'].checked.setValue(true);
            control['controls'].notes.setValue(lab.notes);
          }
        });
      });
    }
  }

  formReset() {
    this.createOrderForm.reset();
    this.labRequestType = this.labAPSRequest;
    this.updateOrder = null;
    this.bindDisplayLableValue();
    this.labTests().controls.forEach((control) => {
      control['controls'].checked.setValue(false);
    });
    // To enable form controls
    this.createOrderForm.controls.applicant.enable();
    this.createOrderForm.controls.requestType.enable();
    this.createOrderForm.controls.aps['controls'].physician.enable();
  }

  submit() {
    const formData = this.createOrderForm.value;
    this.examineWhetherOrderExist(formData);
    if (!this.duplicateOrderRequest) {
      this.cancelOrderRequest();
    }
    this.formReset();
    this.editOrder = false;
  }

  examineWhetherOrderExist(formData) {
    const activeOrders = this.orderRequestList.filter(
      (order) =>
        order.orderStatusId !== 4 &&
        order.orderStatusId !== 5 &&
        order.orderStatusId !== 6
    );
    const apsOrderExist = activeOrders.some((order) => {
      return (
        order.aps !== null &&
        order.clientNumber === formData.applicant &&
        order.aps.provider.fullname === formData.aps.physician &&
        this.editOrder === false &&
        order.requestType === this.labAPSRequest
      );
    });
    const labOrderExist = activeOrders.some(
      (order) =>
        order.clientNumber === formData.applicant &&
        this.editOrder === false &&
        (order.requestType === this.labAERequest ||
          order.requestType === this.labOERequest ||
          order.requestType === this.labAE_HTWTBP)
    );
    if (formData.requestType === this.labAPSRequest && apsOrderExist) {
      this.toaster.error(
        null,
        'APS request is already created using the same Provider for this User'
      );
      this.duplicateOrderRequest = true;
    } else if (formData.requestType !== this.labAPSRequest && labOrderExist) {
      this.toaster.error(null, 'Lab request is already created for this User');
      this.duplicateOrderRequest = true;
    } else {
      this.editOrder === true
        ? this.additionalInfoService.updateOrderRequest(
            this.constructOrderList(formData),
            this.application.id
          )
        : this.additionalInfoService.createOrderRequest(
            this.constructOrderList(formData),
            this.application.id
          );
      this.duplicateOrderRequest = false;
    }
  }

  constructOrderList(formData): OrderRequestDetails {
    const clientNo =
      formData.applicant || this.updateOrder.clientNumber || UUID.UUID();
    const a = this.applicants.find(
      (a) => a.clientNo === clientNo || a.fullName === clientNo
    );
    return {
      applicationId: Number(this.application.id),
      clientNumber: clientNo,
      requestType: formData.requestType || this.updateOrder.requestType,
      createdBy: this.addonService.formatString(this.profile.domainUserName),
      assignedTo: null,
      firstName: a.firstName,
      middleName: a.middleName,
      lastName: a.lastName,
      createdDate: this.cstPipe.transform(),
      id: this.updateOrder?.id || null,
      orderTrackerId: this.updateOrder?.orderTrackerId || null,
      applicationTrackerId: this.applicationTrackerId,
      state: this.application.state,
      labs: this.constructLab(formData),
      aps: this.constructAPS(formData),
    };
  }

  constructLab(formData) {
    let labsList = null;
    formData.tests = formData.tests.filter((test) =>
      test.hasOwnProperty('notes')
    );
    if (
      formData.requestType !== this.labAPSRequest ||
      (this.updateOrder && this.updateOrder.requestType !== this.labAPSRequest)
    ) {
      labsList = new Array<Lab>();
      formData.tests.map((test) => {
        const labObj = {} as Lab;
        labObj.notes = test.notes;
        labObj.name = test.displayLabel;
        labObj.status = test.checked;
        labObj.createdBy = this.addonService.formatString(
          this.profile.domainUserName
        );
        labObj.updatedBy = this.addonService.formatString(
          this.profile.domainUserName
        );
        labObj.createdDate = new Date();
        labsList.push(labObj);
      });

      return labsList;
    }
    return null;
  }

  constructAPS(formData) {
    if (
      formData.requestType === this.labAPSRequest ||
      (this.updateOrder && this.updateOrder.requestType === this.labAPSRequest)
    ) {
      const apsObj = {} as APSRequestDetail;
      apsObj.uWSNotes = formData.aps.uWSNotes;
      apsObj.reason = formData.aps.reason;
      apsObj.Team = 0;
      const {
        state,
        city,
        street_address,
        first_name,
        id,
        last_name,
        zipcode,
        fullname,
        tax_id,
        additionalInfo,
        phone_numbers,
      } = this.selectedDoctor;
      const providerObj = {} as Provider;
      providerObj.streetAddress = street_address;
      providerObj.city = city;
      providerObj.state = state;
      providerObj.firstName = first_name;
      providerObj.lastName = last_name;
      providerObj.id = id;
      providerObj.zipcode = zipcode;
      providerObj.taxId = tax_id;
      providerObj.fullName = fullname;
      providerObj.facilityName = additionalInfo?.corporateOwnerLastName;
      providerObj.fax = additionalInfo?.FaxNumber;
      providerObj.additionalInfo = additionalInfo;
      providerObj.phone = phone_numbers;
      providerObj.is_basic = this.selectedDoctor.is_basic || false;
      apsObj.provider = providerObj;

      return apsObj;
    }
    return null;
  }

  cancelOrderRequest() {
    this.editOrder = false;
    this.openCreateOrderModal$ = false;
    this.additionalInfoService.cancelRequest(false);
    this.formReset();
  }
}
