import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Application } from 'src/app/shared/models/application/application';
import { ApplicationService } from 'src/app/shared/services/application/application.service';

@Component({
  selector: 'app-addendum-notes',
  templateUrl: './addendum-notes.component.html',
  styleUrls: ['./addendum-notes.component.scss'],
})
export class AddendumNotesComponent implements OnInit, OnDestroy {
  constructor(private applicationService: ApplicationService) {}
  subscriptions = new Array<Subscription>();
  addendumArray = [];
  application: Application;

  ngOnInit() {
    let filteredDrilldowns = [];
    this.applicationService.SelectedApplication.subscribe((a: any) => {
      this.addendumArray = [];
      const addendumNotes = [];
      if (a && a.drillDownQuestions) {
        this.application = a;
        filteredDrilldowns = a.drillDownQuestions.filter((d) => {
          return (
            d.type === 'MedicalAilment' ||
            d.type === 'MedicalPrescriptionAilment'
          );
        });
      }
      filteredDrilldowns.forEach((f) => {
        Object.keys(f.answer).map((key) => {
          if (JSON.parse(f.answer[key]).length > 0) {
            addendumNotes.push({ clientNumber: [key], value: f.answer[key] });
          }
        });
      });

      if (this.addendumArray.length === 0) {
        this.addendumArray = this.groupByClientNumber(addendumNotes);
      }
    });
  }
  getNameForClientNo(clientNo: string) {
    return this.application.applicants.find((a) => a.clientNo === clientNo)
      .fullName;
  }
  groupByClientNumber(value) {
    return value.reduce((r, a) => {
      r[a.clientNumber] = r[a.clientNumber] || [];
      r[a.clientNumber].push(JSON.parse(a.value));
      return r;
    }, Object.create(null));
  }
  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }
}
