import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  /**
   *
   * @param value value is date
   * @description convert the date as required format
   * @returns retruns the date html
   */
  transform(value: Date): string {
    const today = moment();
    const birthdate = moment(value);
    const years = today.diff(birthdate, 'years');
    const html: string = years + ' yrs';

    // html += today.subtract(years, 'years').diff(birthdate, 'months') + ' month';

    return html;
  }
}
