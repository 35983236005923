import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VcallApiService } from 'src/app/shared/services/api/vcall/vcall-api.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';

@Component({
  selector: 'app-api-data',
  templateUrl: './api-data.component.html',
  styleUrls: ['./api-data.component.scss'],
})
export class ApiDataComponent implements OnInit {
  constructor(private api: VcallApiService) {}

  applicationId: any = '4003571';
  application = {};
  script = {};
  answers = {};
  calllogs = {};
  notes = {};

  dapp = true;
  dscript = true;
  danswers = true;
  dlogs = true;

  ngOnInit() {}

  getdata() {
    const params = {
      applicationId: this.applicationId,
      appointmentId: null,
      sectionId: null,
      questionId: null,
      mode: null,
      stopNavigation: null,
    };
    this.api
      .GetApplication(this.applicationId)
      .subscribe((a) => (this.application = JSON.parse(JSON.stringify(a))));
    this.api.GetScript(params).subscribe((s) => (this.script = s));
    this.api
      .GetAnswers(this.applicationId)
      .subscribe((s) => (this.answers = JSON.parse(JSON.stringify(s))));
    this.api
      .GetCallLog(this.applicationId)
      .subscribe((s) => (this.calllogs = JSON.parse(JSON.stringify(s))));
  }
}
