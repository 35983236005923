import { BaseFilter } from './base-filter';
import { Answer } from 'src/app/shared/models/application/answer';
import { MedicalItem } from 'src/app/shared/models/medicalItem';
import { FilterParameter } from '../question-filter.service';

export class QualifiedQuestionFilter extends BaseFilter {
  Type = 'QualifiedQuestion';

  protected Filter(parameter: FilterParameter) {
    // absent of path means the filter configuration was a  default one;
    const path =
      parameter.config.path === null || parameter.config.path === ''
        ? parameter.question.answerTag
        : parameter.config.path;

    let canDisplay = false;
    const answer = parameter.answers.find((a) => {
      if (!(a instanceof Answer)) {
        let item = new Answer();
        item = Object.assign(item, a);
        return item.answerTag === path;
      } else {
        return a.answerTag === path;
      }
    });

    if (answer) {
      const list: MedicalItem[] = answer.value;
      let item;
      if (
        parameter.question &&
        parameter.question.details.applicantFilterValue
      ) {
        item = list.find(
          (i) =>
            i.applicable.toString() === 'true' &&
            i.value !== null &&
            i.value.toString() ===
              parameter.question.details.applicantFilterValue
        );
      } else {
        item = list.find(
          (i) =>
            i.applicable !== undefined && i.applicable.toString() === 'true'
        );
      }

      canDisplay = item !== undefined;
    }

    return canDisplay;
  }
}
