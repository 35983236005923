export const CallStatusData = {
  Completed: {
    textColor: 'dark-green',
    dropDownData: [
      {
        header: 'View VCall',
        value: 'View Vcall',
        url: '/vcall/view/application',
        permission: 'CanAccessViewVcall',
      },
      {
        header: 'View Recall',
        value: 'View Recall',
        url: '/worksheet/application',
        tabName: 'Recalls',
        permission: 'CanAccessViewRecall',
      },
      {
        header: 'View CallLogs',
        value: 'View CallLogs',
        permission: 'CanAccessCallLog',
      },
    ],
  },
  'To Do': {
    textColor: 'text-secondary',
    dropDownData: [
      {
        header: 'View CallLogs',
        value: 'View CallLogs',
        permission: 'CanAccessCallLog',
      },
    ],
  },
  Incomplete: {
    textColor: 'text-primary',
    dropDownData: [
      {
        header: 'View CallLogs',
        value: 'View CallLogs',
        permission: 'CanAccessCallLog',
      },
    ],
  },
  'No Show': {
    textColor: 'text-warning',
    dropDownData: [
      {
        header: 'View CallLogs',
        value: 'View CallLogs',
        permission: 'CanAccessCallLog',
      },
    ],
  },
  'Stop Call': {
    text: 'Re-Schedule',
    textColor: 'text-danger',
    dropDownData: [
      {
        header: 'View CallLogs',
        value: 'View CallLogs',
        permission: 'CanAccessCallLog',
      },
    ],
  },
  Canceled: {
    textColor: 'text-info',
    dropDownData: [
      {
        header: 'View CallLogs',
        value: 'View CallLogs',
        permission: 'CanAccessCallLog',
      },
    ],
  },
  'In Progress': {
    textColor: 'text-success',
    dropDownData: [
      {
        header: 'View CallLogs',
        value: 'View CallLogs',
        permission: 'CanAccessCallLog',
      },
    ],
  },
};
