import { Injectable } from '@angular/core';
import { Answer } from '../../../models/application/answer';
import { Question } from '../../../models/vcall/question';
import { ApplicationService } from '../../application/application.service';
import { Expressmode } from '../../filter/question-filter/filters/express-question-filter';
import { QualifiedQuestionFilter } from '../../filter/question-filter/filters/qualified-question-filter';
import {
  FilterConfiguration,
  FilterParameter,
} from '../../filter/question-filter/question-filter.service';
import { ProcessorModel } from '../processor-model';
import { Processor } from '../processor';
import { ProcessingResult } from '../processing-result';

@Injectable({
  providedIn: 'root',
})
export class MarkApplicationExtendedFlagProcessor implements Processor {
  name = 'Mark Application Extended Flag';
  private EXPRESS_MODE = 'expressmode';

  mustWaitForFinish: boolean;
  stopIfError: any;
  hasStopProcessing: boolean = false;

  constructor(private service: ApplicationService) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    let all = [];
    model.script?.items.map((si) => {
      all = all.concat(si.questions);
    });

    let isExtended = false;

    if (model.appointment?.callType !== 'Recall') {
      // if any of the answer found which was dormant, it means it is an extended call
      // does not matter whether the value was true or false, we are checking to see if the questions were asked or not.
      const tags = this.GetDormantQuestions(all);
      const results = tags.filter(
        (t) => model.answers.filter((a) => a.answerTag === t).length > 0
      );
      isExtended = results.length > 0;
    }

    this.service.RegisterAnswerByTag(
      true,
      isExtended,
      'ExtendedVerificationCall',
      null,
      null,
      'Whether the verfication call was an extended version of the call',
      null,
      model.appointment?.appointmentId
    );
    let persistAnswersResult = await this.service.PersistAnswers();
    this.hasStopProcessing = persistAnswersResult === true ? false : true;

    return Promise.resolve({
      result: true,
      stopProcessing: this.hasStopProcessing,
    });
  }

  private GetDormantQuestions(questions: Question[]) {
    return questions
      .filter(
        (q) =>
          q.type === 'MedicalQualifying' &&
          q.details &&
          q.details[this.EXPRESS_MODE] === Expressmode.dormant.toString()
      )
      .map((q) => q.answerTag);
  }
}
