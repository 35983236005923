<div class="container error-page-container">
  <img
    src="/assets/images/ushg/loading.gif"
    class="d-block mx-auto"
    width="70"
    alt="Loading"
  />
  <h3 class="text-center">Loading...</h3>
  <p class="text-center">
    Application could not find the authentication token,<br />
    redirecting to the US Health Group's Directory Services.
  </p>
</div>
