import { Component, OnInit } from '@angular/core';
import { ApplicantQualifiedBaseComponent } from '../applicant-qualified-base-component';
import {
  DefaultApplicantQualifiedQuestionDetail,
  ApplicantQualifiedInputGeneric,
} from '../default-applicant-qualified-question-detail';
import { TextBoxAnswer } from '../textbox-answer';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';

@Component({
  selector: 'app-applicant-qualified-input-text',
  templateUrl: './applicant-qualified-input-text.component.html',
  styleUrls: ['./applicant-qualified-input-text.component.scss'],
})
export class ApplicantQualifiedInputTextComponent extends ApplicantQualifiedBaseComponent<
  ApplicantQualifiedInputGeneric,
  TextBoxAnswer
> {
  details: ApplicantQualifiedInputGeneric =
    new ApplicantQualifiedInputGeneric();
  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    protected applicantFilterService: ApplicantFilterService
  ) {
    super(script, appService, ui, applicantFilterService);
  }

  nextEnabled = false;
  inputGeneric: UntypedFormGroup;

  SaveText() {
    super.Save(this.currentAnswer);
  }
  ResetApplicant() {}
  BindApplicant() {
    const group: any = {};
    group[this.details.answerTag] = this.details.answerTag
      ? new UntypedFormControl(this.currentAnswer || '', Validators.required)
      : new UntypedFormControl(this.currentAnswer || '');

    this.inputGeneric = new UntypedFormGroup({
      [this.details.answerTag]: new UntypedFormControl(
        this.currentAnswer.notes,
        [Validators.required]
      ),
      [this.details.answerTag]: this.details.maxLength
        ? new UntypedFormControl(this.currentAnswer.notes, [
            Validators.maxLength(this.details.maxLength),
          ])
        : new UntypedFormControl(this.currentAnswer.notes || ''),
      [this.details.answerTag]: this.details.minLength
        ? new UntypedFormControl(this.currentAnswer.notes, [
            Validators.maxLength(this.details.minLength),
          ])
        : new UntypedFormControl(this.currentAnswer.notes || ''),
      [this.details.answerTag]: this.details.maxValue
        ? new UntypedFormControl(this.currentAnswer.notes, [
            Validators.max(this.details.maxValue),
          ])
        : new UntypedFormControl(this.currentAnswer.notes || ''),
    });

    if (this.currentAnswer) {
      this.currentAnswer.notes = this.currentAnswer.notes;
    }
  }
  isValid(item: any) {
    console.log(this.inputGeneric.controls[item].errors);
    return this.inputGeneric.controls[item].errors;
  }
  CanGoNext(): boolean {
    if (
      !this.currentAnswer.notes ||
      this.currentAnswer.notes === '' ||
      this.currentAnswer.notes.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  // To improve user experience by enabling the next button
  // But not calling Save on every change avoiding un necessary Question Change events.
  EnableNext() {
    if (this.currentAnswer.notes === '') {
      super.Save(this.currentAnswer);
      this.nextEnabled = false;
    } else {
      super.Save(this.currentAnswer);
      this.nextEnabled = true;
    }
  }

  InitializeApplicantAnswerInstance(): TextBoxAnswer {
    return new TextBoxAnswer();
  }
}
