import { BaseApplicantFilter } from './applicant-attribute-filter';
import { FilterParameter } from '../../question-filter/question-filter.service';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { BaseFilter } from '../../question-filter/filters/base-filter';
import { KeyValue } from '@angular/common';
import { Answer } from 'src/app/shared/models/application/answer';

export class RXProfileFilter extends BaseApplicantFilter {
  Type = 'RXProfileFilter';

  /**
   * @param parameter filter with rx-profile parameter
   * @description filter the rxprofile data
   */
  public Filter(parameter: FilterParameter) {
    const employment = parameter.answers.filter((a) => {
      return a.answerTag === 'Demographic.Employment';
    });
    const applicant = parameter.applicant;
    const params = parameter.config.params;

    const ageParam = params.filter((p) => {
      return p.key === FilterParam.age;
    })[0];
    const occupationParam = params.filter((p) => {
      return p.key === FilterParam.occupation;
    })[0];
    const relationshipParam = params.filter((p) => {
      return p.key === FilterParam.relationship;
    });
    const rxprofileParam = params.filter((p) => {
      return p.key === FilterParam.isRxAuthorized;
    })[0];
    if (
      // applicant[rxprofileParam.key] === JSON.parse(rxprofileParam.value) &&
      (applicant[ageParam.key] >= ageParam.value &&
        this.checkIfNotDependents(applicant, relationshipParam)) ||
      this.checkOccupation(applicant, occupationParam, employment)
    ) {
      return true;
    } else {
      return false;
    }
  }
  private checkIfNotDependents(
    applicant: Applicant,
    relationshipParam: KeyValue<string, string>[]
  ) {
    const data = relationshipParam.filter((r) => {
      return applicant[r.key] === r.value;
    });
    if (data.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private checkOccupation(
    applicant: Applicant,
    params: KeyValue<string, string>,
    employment: Answer[]
  ) {
    let UpdatedOccupation = null;
    if (employment.length > 0 && employment[0].value.length > 0) {
      employment[0].value.forEach((e) => {
        if (e.clientNo === applicant.clientNo && e.applicable) {
          UpdatedOccupation = e?.values[0]?.value?.occupationCode;
        }
      });
    }
    const healthList = JSON.parse(params.value);
    const isHealthworker = healthList.filter((h) => {
      return (
        UpdatedOccupation && UpdatedOccupation.split('-')[0] === h.toString()
      );
    });
    if (isHealthworker.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}

export enum FilterParam {
  age = 'age',
  occupation = 'occupation',
  relationship = 'relationship',
  isRxAuthorized = 'isRxAuthorized',
}

export class RxProfileAllApplicants extends BaseFilter {
  Type = 'RxProfileAllApplicants';
  RxFilter = new RXProfileFilter();
  showQuestion = false;
  Filter(parameter: FilterParameter) {
    parameter.application.applicants.forEach((a) => {
      parameter.applicant = a;
      if (this.RxFilter.Filter(parameter)) {
        this.showQuestion = true;
      }
    });
    return this.showQuestion;
  }
}
