import { MedicalItem } from '../medicalItem';
import { Answer } from './answer';
import { Question } from '../vcall/question';
import { DrilldownStatus } from './../../../shared/models/vcall/detailQuestion';

export class AilmentDashboardModel {
  constructor(options: {} = null) {
    Object.assign(this, options);
  }
  clientNo: string;
  ailment: string;
  ailmentDescription: string;
  status: string;
  answerTag: string;
  getDashboardModel(
    answerList: Answer[],
    stats: string,
    questions: Question[][]
  ): AilmentDashboardModel[][] {
    return answerList.map((k) => {
      return k.value
        .map((a) => {
          if (a.ailments) {
            return a.ailments.map((b) => {
              return new AilmentDashboardModel({
                clientNo: a.clientNo,
                ailment: b,
                ailmentDescription: this.getAilmentDescription(
                  questions,
                  k.answerTag
                ),
                status: this.ailmentQuestionStatus(a, b),
                answerTag: k.answerTag,
              });
            });
          }
        })
        .flat(3);
    });
  }
  getDrugPrescriptionDashboardModel(
    answerList: Answer[]
  ): AilmentDashboardModel[][] {
    console.log(answerList);
    const answers = [];
    answerList.forEach((answer) => {
      answer.value.forEach((item) => {
        item.values?.forEach((first) => {
          first.value.list.forEach((prescription) => {
            answers.push(
              new AilmentDashboardModel({
                clientNo: item.clientNo,
                ailment: prescription.medication,
                ailmentDescription: null,
                answerTag: 'MedicalHistory.PrescriptionDrugs',
                status: this.ailmentQuestionStatus(
                  item,
                  prescription.medication
                ),
              })
            );
          });
        });
      });
    });
    return [answers];
  }
  processAnswerList(medicalItemList: MedicalItem[]): MedicalItem[] {
    return medicalItemList.filter((a) => {
      return a.ailments !== undefined && a.applicable;
    });
  }
  getAilmentDescription(questions: Question[][], answerTag: string): string {
    let ailmentDescription: string;
    questions.forEach((q: Question[]) => {
      q.forEach((ques) => {
        if (ques.answerTag === answerTag && ques.type === 'MedicalAilment') {
          ailmentDescription = ques.details.AilmentName;
        }
      });
    });
    return ailmentDescription;
  }
  ailmentQuestionStatus(ailmentObject, ailmentName) {
    if (ailmentObject.details === undefined) {
      return DrilldownStatus.Pending; // not found
    } else {
      const stat = ailmentObject.details.find((e) => e.ailment === ailmentName)
        ?.status;
      if (stat) {
        return stat; // from api
      } else {
        return DrilldownStatus.Pending; // details found but no status
      }
    }
  }
}
