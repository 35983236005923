<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="BOOLEAN">
    <div class="boolean" [ngClass]="value === 'true' ? 'yes' : 'no'">
      {{ value === "true" ? "Yes" : "No" }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="PRODUCT">
    <div class="boolean" [ngClass]="value === 'true' ? 'yes' : 'no'">
      {{ value === "true" ? "Yes" : "No" }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="MEDICAL_QUALIFYING">
    <ng-container *ngIf="medicalQualifying">
      <div class="ml-2" [ngClass]="medicalQualifying.length > 4 ? 'mt-2' : ''">
        <ng-container *ngFor="let applicant of medicalQualifying">
          <div
            class="progress-circle ml-1"
            [title]="applicant.aliments"
            [ngClass]="applicant.applicable ? 'applicable' : 'not-applicable'"
          >
            <div class="flex-row">
              <div class="circle-element">
                {{ applicant.relationship }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="progress-circle default-circle ml-2">
      <div class="circle-element-default">
        <i class="fas fa-check"></i>
      </div>
    </div>
  </ng-container>
</ng-container>
