import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {
  Appointment,
  AppointmentSearchCriteria,
  AppointmentStatus,
} from '../../models/appointment';
import {
  CallZoneApiService,
  CallZoneStopCallRequest,
} from '../api/callzone/callzone-api.service';
import { VcallParams } from '../../models/vcall/vcallParams';
import { Application } from '../../models/application/application';
import { Log } from '../../models/vcall/applicationNote';
import { UserProfile } from '../../models/user-profile';
import { UiServiceService } from '../ui-service.service';
import { Question } from '../../models/vcall/question';
import { ApplicationService } from '../application/application.service';
import { RecallQuestion } from '../../models/recall/RecallQuestion';
import { RecallInfo } from '../../models/recall/RecallInfo';
import { VcallApiService } from '../api/vcall/vcall-api.service';
import { RecallService } from '../recall/recall.service';
import {
  AppointmentSearchRequest,
  AppointmentSearchResult,
} from '../../models/callzone-appointment';
import { VcallResolverService } from '../api/vcall/vcall-resolver.service';
import { LogStatusService } from '../log-status/log-status.service';
import { ScriptService } from '../script/script.service';
import { Router } from '@angular/router';
import { share, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  public selectedAppointment: Appointment;

  public Appointments: BehaviorSubject<Appointment[]> = new BehaviorSubject<
    Appointment[]
  >([]);
  public SelectedAppointment: BehaviorSubject<Appointment> =
    new BehaviorSubject<Appointment>(Appointment.DefaultInstance());

  public CallLogResult: BehaviorSubject<Log[]> = new BehaviorSubject<Log[]>([]);
  public VerificationStarted: Subject<StartVerificationParameters> =
    new Subject();
  public StopCallPopup: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public ReloadAppointment: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private subscriptions: Subscription[] = [];

  callTypeDescription = {
    startcall: 'Start Call',
    stopcall: 'Stop Call',
    completeCall: 'Call Completed',
    notes: '[System Generated]',
    onCall: 'On Call',
    reSchedule: 'Reschedule Attempted',
    completeVcall: 'VCall Completed',
    completeRecall: 'Recall Completed',
  };
  currentCriteria: AppointmentSearchCriteria;

  public currentAppointmentId: number = 0;
  public currentResourceId: number = 0;

  constructor(
    private api: CallZoneApiService,
    private vcallApi: VcallApiService,
    private appService: ApplicationService,
    private uiService: UiServiceService,
    private recallService: RecallService,
    private logStatusService: LogStatusService,
    private scriptService: ScriptService,
    private router: Router,
    private injector: Injector
  ) {
    let self = this;
    self.SelectedAppointment
        .subscribe((a) => {
          self.currentAppointmentId = a.appointmentId ?? 0;
          self.currentResourceId = a.resourceId ?? 0;
        });
  }

  /**
   * @param params change the main url
   * @description if the route changed this method is called
   */
  public RouteChanged(params: VcallParams) {
    // TODO : Create a callzone API to get the actual appointment
    // This is used when a page refresh happens.
    if (
      this.selectedAppointment === undefined ||
      this.selectedAppointment === null ||
      this.selectedAppointment.appointmentId === undefined ||
      this.selectedAppointment.appointmentId.toString() !== params.appointmentId
    ) {
      // TODO : Revisit this may be the better approch is to call callzone to the appointment
      // and do some comparison and kick an invalid url out.
      this.appService.ApplicationAppointment.subscribe((appointments) => {
        const appointment =
          appointments && Array.isArray(appointments)
            ? appointments.find(
                (a) =>
                  a.appointmentId &&
                  a.appointmentId.toString() === params.appointmentId
              )
            : null;
        if (appointment && appointment !== null) {
          this.SelectAppointment(appointment);
        }
      });
    }
  }

  /**
   * @param isStopCall isStopCall as boolean
   * @description open the stop call popup
   */
  public OpenStopCallPopup(isStopCall: boolean) {
    this.StopCallPopup.next(isStopCall);
  }

  /**
   * @param options startDate,endDate, and resource Id
   * @description load the appointments
   */
  public LoadAppointments(options: AppointmentSearchCriteria) {
    if (options) {
      this.currentCriteria = options;
      this.api.GetAppointments(options).subscribe((list) => {
        // HACK suddenly callzone stopped passing resourceId in the response.
        if (Array.isArray(list)) {
          list.map((a) => (a.resourceId = Number(options.resourceId)));
        } else {
          list = [];
        }
        Object.freeze(list);
        this.Appointments.next(list);
      });
    }
  }

  /**
   * @param appId applicationId
   * @description to get the applciation hascode for the iframe url
   */
  public applicationHashCode(appId) {
    return new Promise<string>((resolve, reject) => {
      this.api.GetApplicationHashCode(appId).subscribe(
        (data) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  /**
   * @param appointment send whole selected appointment Data
   * @description select appointment
   */
  public SelectAppointment(appointment: Appointment) {
    // Object.freeze(appointment);
    this.selectedAppointment = appointment;
    this.SelectedAppointment.next(appointment);
    this.scriptService.CurrentAppointment = appointment;
  }

  /**
   * @description start the verification call
   */
  public StartVerification(params: StartVerificationParameters) {
    this.VerificationStarted.next(params);
  }

  /**
   * @param appointment send whole selected appointment Data
   * @param application appication
   * @param userProfile  user Profile
   * @description start the call
   */
  public StartCall(
    application: Application,
    appointment: Appointment,
    userProfile: UserProfile
  ): Promise<boolean> {
    const log = {
      name: userProfile.name,
      date: this.uiService.formatCurrentDate(),
      id: application.id,
      state: application.state,
      reason: this.callTypeDescription.startcall,
      notes: this.callTypeDescription.notes,
      utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
      appointmentId: this.selectedAppointment.appointmentId,
    };

    let reject: any;
    let resolve: any;
    const apiStatus = new Promise<boolean>((r, j) => {
      resolve = r;
      reject = j;
    });

    this.api.BeginCall(appointment).subscribe((res) => {
      if (res === null || (res && res.status === 200)) {
        this.appService.InsertCallLog(log, false).then((list: any) => {
          this.appService.GetCallLog(application.id.toString());
          this.CallLogResult.next(list);
        });
        resolve(true);
      } else {
        resolve(false);
      }
    });

    return apiStatus;
  }

  /**
   * @param appointment send whole selected appointment Data
   * @param application appication
   * @param userProfile  user Profile
   * @description start the on call
   */
  public Oncall(
    application: Application,
    appointment: Appointment,
    userProfile: UserProfile
  ) {
    const log = {
      name: userProfile.name,
      date: this.uiService.formatCurrentDate(),
      id: application.id,
      state: application.state,
      reason: this.callTypeDescription.onCall,
      notes: this.callTypeDescription.notes,
      utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
      appointmentId: this.selectedAppointment.appointmentId,
    };

    this.api.onCall(appointment).subscribe((res) => {
      if (res === null || (res && res.status === 200)) {
        this.appService.InsertCallLog(log, false).then((list: any) => {
          this.appService.GetCallLog(application.id.toString());
          this.CallLogResult.next(list);
        });
      }
    });
  }

  /**
   * @param appointment send whole selected appointment Data
   * @param application appication
   * @param userProfile  user Profile
   * @param completeCallType complete call type number
   * @param recallGuid recall GUID string
   * @description call complete
   */
  async EndCallAsCompleted(
    application: Application,
    appointment: Appointment,
    userProfile: UserProfile,
    completeCallType: number,
    recallGuid: string
  ) {
    let result = false;
    const log = {
      name: userProfile.name,
      date: this.uiService.formatCurrentDate(),
      id: application.id,
      state: application.state,
      reason: this.callTypeDescription.completeCall,
      notes: this.callTypeDescription.notes,
      utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
      appointmentId: this.selectedAppointment.appointmentId,
    };
    if (appointment.callType === RecallInfo.callType) {
      log.reason = this.callTypeDescription.completeRecall;
    } else {
      log.reason = this.callTypeDescription.completeVcall;
    }
    if (this.selectedAppointment && this.selectedAppointment.appointmentId) {
      let res = await this.api
        .EndCallAsCompleted(
          application,
          appointment,
          completeCallType,
          recallGuid
        )
        .toPromise();
      if (res === null || (res && res.status === 200)) {
        await this.appService.InsertCallLog(log, true).then((list: any) => {
          this.appService.GetCallLog(application.id.toString());
          this.CallLogResult.next(list);
          if (appointment.callType === RecallInfo.callType) {
            this.recallService.recallCallComplete(appointment);
          }
          result = true;
        });
      } else {
        result = false;
      }
    } else {
      result = false;
      alert('Could not find Appointment, Please refresh the screen');
    }
    return result;
  }

  public GetStopCallReasons() {
    return this.api.GetStopCallReasons();
  }

  /**
   * @param appointment send whole selected appointment Data
   * @param application appication
   * @param userProfile  user Profile
   * @param question current question
   * @param stopCall stop call
   * @description end call completed
   */
  public StopCall(
    stopCall: any,
    question: Question,
    application: Application,
    appointment: Appointment,
    userProfile: UserProfile
  ): Promise<void> {
    const appointmentStatus = {
      statusId: AppointmentStatus.StopCall,
      stopCallId: stopCall.id,
      appointmentId: appointment.appointmentId,
      resourceId: appointment.resourceId ? appointment.resourceId : 0,
      comments: stopCall.notes,
      applicationId: application.id,
    };
    const log = {
      notes: stopCall.notes,
      section: question && question.section ? question.section.name : null,
      answerTag: question && question.answer ? question.answerTag : null,
      reason: this.callTypeDescription.stopcall,
      name: userProfile.name,
      date: this.uiService.formatCurrentDate(),
      id: application.id,
      state: application.state,
      utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
      appointmentId: this.selectedAppointment.appointmentId,
    };

    /**
     * @param appointmentId appointment Id as a number
     * @param resourceId resourse Id as number
     * @param statusId  status Id
     * @param stopCallId stop call Id
     * @description save the status code reasons
     */
    return new Promise((resolve, reject) => {
      this.api.stopCall(appointmentStatus).subscribe((res) => {
        if (res === null || (res && res.status === 200)) {
          this.appService.InsertCallLog(log, true).then((list: any) => {
            this.appService.GetCallLog(application.id.toString());
            this.CallLogResult.next(list);
          });
        }
        resolve();
      });
    });
  }

  /**
   * @param search search
   * @description search appointments
   */
  public SearchAppointments(search: AppointmentSearchRequest): any {
    return new Promise<Array<AppointmentSearchResult>>((resolve, reject) => {
      this.api.searchAppointments<AppointmentSearchResult>(search).subscribe(
        (data) => resolve(data),
        (error) => reject(error)
      );
    });
  }

  public Clear() {
    this.Reset(
      this.SelectedAppointment,
      'selectedAppointment',
      Appointment.DefaultInstance()
    );
    this.LoadAppointments(this.currentCriteria);
    this.Reset(this.Appointments);
    // this.Reset(this.CallLogResult);
    this.Reset(this.VerificationStarted);

    this.subscriptions.map((s) => s.unsubscribe());
  }

  public AddSubscriptions(subscriptions: Subscription[]) {
    this.subscriptions = this.subscriptions.concat(subscriptions);
  }
  private Reset(subject: Subject<any>, property?: any, defaultValue?: any) {
    if (property) {
      this[property] = defaultValue ? defaultValue : null;
    }

    // subject.next(null);
    subject.next(defaultValue ? defaultValue : null);
  }
  /**
   * @param appointment send whole selected appointment Data
   * @param application appication
   * @param userProfile  user Profile
   * @description reschedule the appointment
   */
  public logRescheduleAppointment(
    application: Application,
    appointment: Appointment,
    userProfile: UserProfile
  ) {
    const log = {
      name: userProfile.name,
      date: this.uiService.formatCurrentDate(),
      id: application.id,
      state: application.state,
      reason: this.callTypeDescription.reSchedule,
      notes: this.callTypeDescription.notes,
      utcDate: this.uiService.formatCurrentDateandTimeforUtcDate(),
      appointmentId: this.selectedAppointment.appointmentId,
    };
    this.appService.InsertCallLog(log, false).then((list: any) => {
      this.appService.GetCallLog(application.id.toString());
      this.CallLogResult.next(list);
    });
  }

  // refresh appointments
  public refreshAppointments(loadAppointments: boolean) {
    this.ReloadAppointment.next(loadAppointments);
  }

  // Checking wheather the call is completed or not
  public async callIsCompletedOrNot(appId: string) {
    let list = await this.vcallApi.GetCallLog(appId).toPromise();
    if (
      Array.isArray(list) &&
      list.find(
        (a) =>
          a.appointmentId === this.selectedAppointment.appointmentId &&
          (a.reason === 'VCall Completed' || a.reason === 'Recall Completed')
      ) !== undefined
    ) {
      this.uiService.PermissionDenied(
        `${this.selectedAppointment.appointmentId} since appointment is completed`
      );
      return true;
    } else {
      return false;
    }
  }
}

export class StartVerificationParameters {
  Application: Application;
  Appointment: Appointment;
  StopProcessing: boolean;

  constructor(
    application: Application,
    appointment: Appointment,
    stopProcess: boolean = false
  ) {
    // this.Application = JSON.parse(JSON.stringify(application));
    this.Application = application ? Application.Copy(application) : null;
    this.Appointment = appointment
      ? JSON.parse(JSON.stringify(appointment))
      : null;
    this.StopProcessing = stopProcess;
  }
}
