import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { NbcApiService } from 'src/app/shared/services/nbc/nbc-api.service';
import { ApplicationStatus } from '../../models/enum';
import { CreateApplicationTrackerRecord } from '../add-on/addon-application';
import {
  GetOrderDocuments,
  GetOrders,
  AuditNote,
  OrderRequestDetails,
  RouteToAPS,
  ApplicationTracker,
} from './additional-info-modal';

@Injectable({
  providedIn: 'root',
})
export class AdditionalInfoService {
  EditOrderRequest = new BehaviorSubject<OrderRequestDetails>(null);
  orderRequests = new BehaviorSubject<GetOrders>(null);
  openOrderRequest = new BehaviorSubject<boolean>(false);
  canDisplayDocLookupBtn = new BehaviorSubject<boolean>(null);
  notes = new BehaviorSubject(null);
  applicationTrackerRecords = new BehaviorSubject<Array<ApplicationTracker>>(
    null
  );

  constructor(private nbcService: NbcApiService) {}

  fetchOrderRequests(appId: number) {
    this.nbcService.getOrders(appId).subscribe((res) => {
      if (res) {
        this.orderRequests.next(res);
      }
    });
  }

  deleteOrderRequest(order: OrderRequestDetails, appId: number) {
    this.nbcService.deleteOrder(order).subscribe((res) => {
      if (res) {
        this.fetchOrderRequests(appId);
      }
    });
  }

  createOrderRequest(order: OrderRequestDetails, appId: number) {
    this.nbcService.createOrder(order).subscribe((res) => {
      if (res) {
        this.fetchOrderRequests(appId);
      }
    });
  }

  updateOrderRequest(order: OrderRequestDetails, appId: number) {
    this.nbcService.updateOrder(order).subscribe((res) => {
      if (res) {
        this.fetchOrderRequests(appId);
      }
    });
  }

  routeOrdersToAPS(order: RouteToAPS, appId: number) {
    return new Promise((resolve, reject) => {
      resolve(
        this.nbcService.routeToAPS(order).subscribe((res) => {
          if (res) {
            this.fetchOrderRequests(appId);
          }
        })
      );
    });
  }

  applicationTrackerRecord(tracker: CreateApplicationTrackerRecord) {
    this.nbcService.createApplicationTrackerRecord(tracker).subscribe();
  }

  async displayOrderDocuments(order: GetOrderDocuments) {
    return await this.nbcService.getOrderDocuments(order).toPromise();
  }

  editOrder(order: OrderRequestDetails, index: number) {
    this.EditOrderRequest.next({ ...order, index });
  }

  cancelRequest(event: boolean) {
    this.openOrderRequest.next(event);
  }

  insertNote(data: AuditNote) {
    this.nbcService.insertAuditNote(data).subscribe((res) => {
      if (res) {
        this.fetchNotes(data.applicationId);
      }
    });
  }

  fetchNotes(appId: number) {
    this.nbcService.getAuditNotes(appId).subscribe((res) => {
      if (res) {
        this.notes.next(res);
      }
    });
  }

  constructRouteOrdersToAPS(appId, profileName, applicationTracker) {
    const routeAPS = {} as RouteToAPS;
    routeAPS.applicationId = appId;
    routeAPS.assignedTo = null;
    routeAPS.createdDate = new Date().toDateString();
    routeAPS.createdBy = profileName;
    routeAPS.team = 'APS';
    routeAPS.id = applicationTracker.id;
    routeAPS.convertedVersion = applicationTracker.convertedVersion;

    return routeAPS;
  }

  isApplicationStatusValid(appTracker: ApplicationTracker) {
    const inValidAppStatus = [300, 310, 320, 330];
    return !inValidAppStatus.includes(appTracker.applicationStatusId);
  }

  getApplicantHeight() {
    return [
      {
        value: "2' 0''",
      },
      {
        value: "2' 1''",
      },
      {
        value: "2' 2''",
      },
      {
        value: "2' 3''",
      },
      {
        value: "2' 4''",
      },
      {
        value: "2' 5''",
      },
      {
        value: "2' 6''",
      },
      {
        value: "2' 7''",
      },
      {
        value: "2' 8''",
      },
      {
        value: "2' 9''",
      },
      {
        value: "2' 10''",
      },
      {
        value: "2' 11''",
      },
      {
        value: "3' 0''",
      },
      {
        value: "3' 1''",
      },
      {
        value: "3' 2''",
      },
      {
        value: "3' 3''",
      },
      {
        value: "3' 4''",
      },
      {
        value: "3' 5''",
      },
      {
        value: "3' 6''",
      },
      {
        value: "3' 7''",
      },
      {
        value: "3' 8''",
      },
      {
        value: "3' 9''",
      },
      {
        value: "3' 10''",
      },
      {
        value: "3' 11''",
      },
      {
        value: "4' 0''",
      },
      {
        value: "4' 1''",
      },
      {
        value: "4' 2''",
      },
      {
        value: "4' 3''",
      },
      {
        value: "4' 4''",
      },
      {
        value: "4' 5''",
      },
      {
        value: "4' 6''",
      },
      {
        value: "4' 7''",
      },
      {
        value: "4' 8''",
      },
      {
        value: "4' 9''",
      },
      {
        value: "4' 10''",
      },
      {
        value: "4' 11''",
      },
      {
        value: "5' 0''",
      },
      {
        value: "5' 1''",
      },
      {
        value: "5' 2''",
      },
      {
        value: "5' 3''",
      },
      {
        value: "5' 4''",
      },
      {
        value: "5' 5''",
      },
      {
        value: "5' 6''",
      },
      {
        value: "5' 7''",
      },
      {
        value: "5' 8''",
      },
      {
        value: "5' 9''",
      },
      {
        value: "5' 10''",
      },
      {
        value: "5' 11''",
      },
      {
        value: "6' 0''",
      },
      {
        value: "6' 1''",
      },
      {
        value: "6' 2''",
      },
      {
        value: "6' 3''",
      },
      {
        value: "6' 4''",
      },
      {
        value: "6' 5''",
      },
      {
        value: "6' 6''",
      },
      {
        value: "6' 7''",
      },
      {
        value: "6' 8''",
      },
      {
        value: "6' 9''",
      },
      {
        value: "6' 10''",
      },
      {
        value: "6' 11''",
      },
      {
        value: "7' 0''",
      },
    ];
  }

  fetchApplicationTrackerRecords(appId: number) {
    this.nbcService.getApplicationTrackerRecords(appId).subscribe((res) => {
      if (
        res &&
        res['data'] &&
        res['data']['application'] &&
        res['data']['application'].applicationTracker.length > 0
      ) {
        this.applicationTrackerRecords.next(
          res['data']['application'].applicationTracker
        );
      } else {
        this.applicationTrackerRecords.next([]);
      }
    });
  }
}
