import { FilterParameter } from '../question-filter.service';
import { BaseFilter } from './base-filter';

export class GroupAnswerTagFilter extends BaseFilter {
  Type = 'GroupAnswerTag';

  Filter(parameter: FilterParameter) {
    let canDisplay = false;

    let path = parameter.config.path;
    if (parameter.question.details !== undefined) {
      if (parameter.question.details.groupTag !== undefined) {
        // prefixed with groupTag if existed in details
        path = `${parameter.question.details.groupTag}.${parameter.config.path}`;
      }
    }

    const val = parameter.answers.find((que) => que.answerTag === path)?.value;
    if (val === parameter.config.value) {
      canDisplay = true;
    } else {
      canDisplay = false;
    }
    return canDisplay;
  }
}
