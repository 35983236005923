<!-- <div class="row">
  <div *ngIf="question">
    {{ this.paymentDetails.modeOfPaymentQuestion }}
    {{ this.payment.initialPaymentBy }}. &nbsp;
  </div>
  <div>{{ this.paymentDetails.changeModeOfPaymentQuestion }}</div>
  <div class="col-1">
    <button
      class="btn btn-primary carousel-next"
      (click)="editQuestion(content)"
    >
      Change
    </button>
  </div>
</div> -->

<div class="row">
  <div class="col-6">
    Do you agree that you will be paying the initial payment with
    <b>{{ payment.initialPaymentBy | titlecase }} </b>?
  </div>
  <div class="col-1">
    <label
      ><input
        name="paymentconfirmation"
        type="radio"
        (click)="optionChanged(true)"
      />
      I agree
    </label>
  </div>
  <div class="col-2">
    <label
      ><input
        name="paymentconfirmation"
        #paymentno
        (click)="donotagree = true; optionChanged(false)"
        type="radio"
      />
      I do not agree
    </label>
  </div>
</div>
<br />
<div class="row" *ngIf="donotagree">
  <div class="col-5">Let's get it changed then</div>
  <div class="col-1">
    <button
      class="btn btn-primary carousel-next"
      (click)="editQuestion(content)"
    >
      Change
    </button>
  </div>

  <ng-template #content let-modal>
    <div class="modal-body">
      <form #initialModeEdit="ngForm">
        <div class="row">
          <div class="col-sm-4">Payment type</div>
          <div class="col-sm-3">
            <input
              type="radio"
              name="initialModeOfPayment"
              id="creditCard"
              [value]="paymentDetails.creditCardValue"
              #creditCardValue="ngModel"
              [(ngModel)]="editPayment.initialPaymentBy"
            />
            <label class="form-check-label ml-2" for="creditCard">
              {{ paymentDetails.creditCardText }}
            </label>
          </div>
          <div class="col-sm-2">
            <input
              type="radio"
              name="initialModeOfPayment"
              id="bankDraft"
              [value]="paymentDetails.bankDraftValue"
              #bankDraftValue="ngModel"
              [(ngModel)]="editPayment.initialPaymentBy"
            />
            <label class="form-check-label ml-2" for="bankDraft">
              {{ paymentDetails.bankDraftText }}
            </label>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.dismiss('Cancel')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="initialModeEdit.invalid"
        (click)="modal.close('Save')"
      >
        Save
      </button>
    </div>
  </ng-template>
</div>
