import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { VcallComponent } from './vcall.component';
import { VcallNavHeaderComponent } from './nav-header/nav-header.component';
import { VcallSectionsComponent } from './sections/sections.component';
import { VcallQuestionComponent } from './questions/question/question.component';
import { VcallQuestionTypeBooleanComponent } from './questions/types/boolean/boolean.component';
import { RouterModule } from '@angular/router';
import { VcallCommonBankViewComponent } from './questions/payments/common-bank-view/common-bank-view.component';
import { VcallMonthlyAccountInfoComponent } from './questions/payments/types/monthly-account-info/monthly-account-info.component';
import { VcallMonthlyEffectiveDateComponent } from './questions/payments/types/monthly-effective-date/monthly-effective-date.component';
import { VcallInitialBankViewComponent } from './questions/payments/types/initial-bank-view/initial-bank-view.component';
import { DemographicsComponent } from './questions/demographics/demographics.component';
import { DemographicApplicantsComponent } from './questions/demographic-applicants/demographic-applicants.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsComponent } from './questions/products/products.component';
import { EndcallComponent } from './questions/endcall/endcall.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClosingComponent } from './questions/closing/closing.component';
import { ProductComponent } from './questions/types/product/product.component';
import { SharedModule } from '../shared/shared.module';
import { MonthlyPaymentModeComponent } from './questions/payments/types/monthly-payment-mode/monthly-payment-mode.component';
import { InitialPaymentModeComponent } from './questions/payments/types/initial-payment-mode/initial-payment-mode.component';
import { RadiobuttonListComponent } from './questions/types/radiobuttonList/radiobutton-list.component';
import { PayorInformationComponent } from './questions/payments/payor-information/payor-information.component';
import { VerifiableListComponent } from './questions/types/verifiableList/verifiable-list.component';
import { DropdownComponent } from './questions/types/dropdown/dropdown.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MedicalAilmentComponent } from './questions/medical-ailment/medical-ailment.component';
import { MedicalQualifyingComponent } from './questions/medical-qualifying/medical-qualifying.component';
import { MedicalAilmentDashboardComponent } from './questions/medical-ailment-dashboard/medical-ailment-dashboard.component';
import { ThirdPartyPayorVerificationComponent } from './questions/payments/third-party-payor/third-party-payor-verification/third-party-payor-verification.component';
import { DynamicDetailsComponent } from './questions/dynamics/dynamic-details/dynamic-details.component';
import { EzappAnswersDisplayComponent } from './components/ezapp-answers-display/ezapp-answers-display.component';
import { QualifyingQuestionGenericComponent } from './questions/qualifying-question-generic/qualifying-question-generic.component';
import { ApplicantQualifiedTextBoxComponent } from './questions/applicant-qualified-controls/applicant-qualified-textbox/applicant-qualified-textbox.component';
import { ApplicantQualifiedBooleanTextboxComponent } from './questions/applicant-qualified-controls/applicant-qualified-boolean-textbox/applicant-qualified-boolean-textbox.component';
import { ApplicantQualifiedInputTextComponent } from './questions/applicant-qualified-controls/applicant-qualified-input-text/applicant-qualified-input-text.component';
import { ApplicantQualifiedOccupationComponent } from './questions/applicant-qualified-controls/applicant-qualified-occupation/applicant-qualified-occupation.component';
import { CascadedDropDownComponent } from './components/cascaded-dropdown/cascaded-dropdown.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { ViewOnlyScreenComponent } from './view-only-screen/view-only-screen.component';
import { VcallQuestionTypeRecallComponent } from './questions/types/recall/recall.component';
import { ApplicantQualifiedDoctorLookupComponent } from './questions/applicant-qualified-doctor-lookup/applicant-qualified-doctor-lookup.component';
import { DrillDownValuesViewComponent } from './components/drill-down-values-view/drill-down-values-view.component';
import { AppointmentsModule } from '../appointments/appointments.module';
import { ApplicantQualifiedMedicationLookupComponent } from './questions/applicant-qualified-medication-lookup/applicant-qualified-medication-lookup.component';
import { TextareaComponent } from './questions/types/textarea/textarea.component';
import { UserVerificationComponent } from './user-verification/user-verification.component';
import { ApplicantRecallQuestionComponent } from './questions/types/applicant-recall-question/applicant-recall-question.component';
import { SmartNavigationComponent } from './components/smart-navigation/smart-navigation.component';
import { SmartAnswerComponent } from './sections/smart-answer/smart-answer.component';
import { TimerService } from '../shared/services/utility/timer.service';
import { VcallRoutes } from './vcall-routing';

@NgModule({
  imports: [
    CommonModule,
    // TODO - Remove forms module once there is no dependency
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    NgxSpinnerModule,
    AppointmentsModule,
    RouterModule.forChild(VcallRoutes),
  ],
  declarations: [
    VcallComponent,
    VcallNavHeaderComponent,
    VcallSectionsComponent,
    VcallQuestionComponent,
    VcallQuestionTypeBooleanComponent,
    VcallCommonBankViewComponent,
    VcallMonthlyAccountInfoComponent,
    VcallMonthlyEffectiveDateComponent,
    VcallInitialBankViewComponent,
    DemographicsComponent,
    DemographicApplicantsComponent,
    ProductsComponent,
    EndcallComponent,
    ClosingComponent,
    ProductComponent,
    MonthlyPaymentModeComponent,
    InitialPaymentModeComponent,
    RadiobuttonListComponent,
    PayorInformationComponent,
    VerifiableListComponent,
    DropdownComponent,
    SmartNavigationComponent,
    MedicalAilmentComponent,
    MedicalQualifyingComponent,
    MedicalAilmentDashboardComponent,
    ThirdPartyPayorVerificationComponent,
    DynamicDetailsComponent,
    EzappAnswersDisplayComponent,
    QualifyingQuestionGenericComponent,
    ApplicantQualifiedTextBoxComponent,
    ApplicantQualifiedBooleanTextboxComponent,
    ApplicantQualifiedInputTextComponent,
    CascadedDropDownComponent,
    ApplicantQualifiedOccupationComponent,
    PrintViewComponent,
    ViewOnlyScreenComponent,
    VcallQuestionTypeRecallComponent,
    ApplicantRecallQuestionComponent,
    ApplicantQualifiedDoctorLookupComponent,
    ApplicantQualifiedMedicationLookupComponent,
    DrillDownValuesViewComponent,
    TextareaComponent,
    UserVerificationComponent,
    SmartAnswerComponent,
  ],
  exports: [PrintViewComponent, NgbModule],
  providers: [DatePipe, TimerService],
})
export class VcallModule {}
