export class Section {
  name: string;
  description: string;
  sequence: number;
  status: SectionStatus;
  totalQuestions: number;
  questionsDone: number;
  toggle?: boolean;
}

export enum SectionStatus {
  Completed = 0,
  Current = 1,
  Pending = 2,
}
