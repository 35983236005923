import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  ScriptService,
  VcallMode,
} from 'src/app/shared/services/script/script.service';
import { Section } from 'src/app/shared/models/vcall/section';
import { Question } from 'src/app/shared/models/vcall/question';
import { Script } from 'src/app/shared/models/vcall/script';
import { Answer } from 'src/app/shared/models/application/answer';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Appointment } from 'src/app/shared/models/appointment';
import { CallZoneUser } from 'src/app/shared/models/callzone-user';
import { Application } from 'src/app/shared/models/application/application';
import { PrintViewData } from '../print-view/print-view.component';
import { VcallParams } from 'src/app/shared/models/vcall/vcallParams';
import { ApplicationSearchResult } from 'src/app/admin/completed-vcalls/completed-vcalls.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-only-screen',
  templateUrl: './view-only-screen.component.html',
  styleUrls: ['./view-only-screen.component.scss'],
})
export class ViewOnlyScreenComponent implements OnInit, OnDestroy {
  subscriptions = Array<Subscription>();
  sections: Array<Section>;
  questions: Question[];
  script: Script;
  answers: Answer[];
  agent: CallZoneUser;
  appointment: Appointment;
  application: Application;
  selectedCall: ApplicationSearchResult;
  applicationId: string;
  item = [];
  constructor(
    private scriptService: ScriptService,
    private applicationService: ApplicationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const uriParams = this.activatedRoute.snapshot.params;

    const options = new VcallParams();
    this.applicationId = uriParams.applicationId;
    this.applicationService.SelectApplication(uriParams.applicationId);
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }
}
