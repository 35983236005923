<button
  class="btn float-left border bg-white carousel-next btn-check-next p-10 ml-1"
  (click)="triggerModel()"
  *ngIf="question.details.showNotes && question.details.showNotes === 'true'"
  ngbTooltip="Add/Edit note"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    class="bi bi-journal-plus"
    viewBox="0 0 20 20"
  >
    <path
      fill-rule="evenodd"
      d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z"
    />
    <path
      d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"
    />
    <path
      d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"
    />
  </svg>
  <!-- <i class="fa fa-plus" aria-hidden="true"></i> Note -->
</button>
<app-modal
  [open]="openModal"
  [modalOptions]="{ size: 'lg' }"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Add',
    cancelButtonName: 'Cancel'
  }"
  (actions)="submitNote($event)"
  [formValidation]="noteForm.valid"
>
  <div title>
    <h4 class="modal-title" id="modal-basic-title">
      <span> Add Note </span>
    </h4>
  </div>
  <div body>
    <form [formGroup]="noteForm" id="addNoteForm">
      <textarea
        name="note"
        class="form-control"
        rows="7"
        cols="30"
        autocomplete="off"
        formControlName="notes"
      ></textarea>
    </form>
  </div>
</app-modal>
