import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Application } from 'src/app/shared/models/application/application';
import { ApplicationService } from 'src/app/shared/services/application/application.service';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyDetailComponent implements OnDestroy {
  application: Application;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  policyDetails: any[] = [];

  constructor(private applicationService: ApplicationService) {
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a) {
          this.application = a;
          this.policyDetails =
            this.application.policyInformation != null
              ? [...this.application.policyInformation].sort((m, n) => {
                  return m.policyNumber === n.policyNumber
                    ? 0
                    : +(m.policyNumber > n.policyNumber) || -1;
                })
              : this.application.policyInformation;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
