import { KeyValue } from '@angular/common';
import { Applicant } from 'src/app/shared/models/application/applicant';
import { Application } from 'src/app/shared/models/application/application';
import { Question } from 'src/app/shared/models/vcall/question';
import { Script } from 'src/app/shared/models/vcall/script';
import { VCallApplicant } from 'src/app/shared/models/vcall/vcallApplicants';
import { VcallApiService } from 'src/app/shared/services/api/vcall/vcall-api.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { DetailAnswers } from 'src/app/vcall/print-view/print-view.component';
import {
  ApplicationReviewService,
  DrillDownApplicationReviewItem,
} from '../services/application-review.service';
import { MaskPipe } from 'src/app/shared/pipes/mask.pipe';

export enum ApplicationReviewItemType {
  MedicalHistoryApplicability = 'MedicalHistoryApplicability',
  MedicalHistoryAilments = 'MedicalHistoryAilments',
  MedicalHistoryDetails = 'MedicalHistoryDetails',
  AllItems = 'AllItems',
  Discrepancies = 'Discrepancies',
  MedicalApplicability = 'MedicalApplicability',
  Demographic = 'Demographic',
  Occupation = 'Occupation',
  RxProfile = 'RxProfile',
  RxProfileGlobal = 'RxProfileGlobal',
  Notes = 'Notes',
  Payment = 'Payment',
  GeneralDetails = 'GeneralDetails',
  CriticalQuestion = 'CriticalQuestions',
}

export enum OccupationAnswerTag {
  DemographicFieldName = 'DEMOGRAPHIC.EMPLOYMENT.EMPLOYED.FIELDNAME',
  DemographicFieldCode = 'DEMOGRAPHIC.EMPLOYMENT.EMPLOYED.FIELDCODE',
  DemographicOccupationName = 'DEMOGRAPHIC.EMPLOYMENT.EMPLOYED.OCCUPATIONNAME',
  DemographicOccupationCode = 'DEMOGRAPHIC.EMPLOYMENT.EMPLOYED.OCCUPATIONCODE',
  DemographicDuties = 'DEMOGRAPHIC.EMPLOYMENT.EMPLOYED.DUTIES',
  DemographicNotes = 'DEMOGRAPHIC.EMPLOYMENT.UNEMPLOYED.NOTES',
  DemographicStudent = 'DEMOGRAPHIC.STUDENT.CREDITHOURS.NOTES',
  DemographicStudentFieldName = 'DEMOGRAPHIC.STUDENT.EMPLOYED.FIELDNAME',
  DemographicStudentFieldCode = 'DEMOGRAPHIC.STUDENT.EMPLOYED.FIELDCODE',
  DemographicStudentOccupationName = 'DEMOGRAPHIC.STUDENT.EMPLOYED.OCCUPATIONNAME',
  DemographicStudentOccupationCode = 'DEMOGRAPHIC.STUDENT.EMPLOYED.OCCUPATIONCODE',
  DemographicStudentDuties = 'DEMOGRAPHIC.STUDENT.EMPLOYED.DUTIES',
  DemographicEmployedNotes = 'DEMOGRAPHIC.EMPLOYMENT.ISAPPLICANTFULLTIMESTUDENT.NOTES',
}

export class ApplicationReviewItem {
  tag: string;
  clientNo: string;
  responses: ApplicationProcessResponse[];
  displayGroup: string;
  review: boolean;
  value: string;
  prompt: string;

  constructor(options: object) {
    if (options) {
      Object.assign(this, options);
    }
  }

  get vCall() {
    return this.responses?.find((r) => r && r.type && r.type === 'vcall');
  }

  get ezApp() {
    return this.responses?.find((r) => r && r.type && r.type === 'ezapp');
  }

  get IsDifferent() {
    const result =
      this.ezApp &&
      this.ezApp.value &&
      !this.areSame(this.vCall?.value, this.ezApp?.value);
    return result;
  }

  areSame(val1, val2) {
    return (
      this.standardize(val1).toString() === this.standardize(val2).toString()
    );
  }

  contains(query: string) {
    return JSON.stringify(this).toLowerCase().indexOf(query.toLowerCase()) > -1;
  }

  standardize(value) {
    if (value === 'Y') {
      return 'true';
    }
    if (value === 'N') {
      return 'false';
    }
    return value ? value : '';
  }
}

export class ApplicationProcessResponse {
  type: string;
  prompt: string;
  value: boolean | string;
  verification: boolean;
}

export abstract class ApplicationReviewItemViewModel {
  configuration: ApplicationReviewItemConfiguration;
  all: ApplicationReviewItem[]; // find a better home for this
  items: ApplicationReviewItem[] = [];
  service: ApplicationReviewService;
  filteredItemsCount: number;
  tobaccoUsage: boolean | string;
  rxAuthorizationApplicable = false;
  statistic: Array<KeyValue<Applicant, number>> = [];
  application: Application;
  script: Script;

  constructor(service: ApplicationReviewService) {
    this.service = service;
  }

  static Create(
    type: ApplicationReviewItemType,
    service: ApplicationReviewService
  ) {
    switch (type) {
      case ApplicationReviewItemType.Discrepancies:
        return new DiscrepancyApplicationReviewItemViewModel(
          service,
          false,
          true
        );
      case ApplicationReviewItemType.MedicalHistoryApplicability:
        return new DropDrownApplicationReviewItemViewModel(service, true, true);
      case ApplicationReviewItemType.Demographic:
        return new DropDrownApplicationReviewItemViewModel(
          service,
          false,
          true
        );
      case ApplicationReviewItemType.Occupation:
        return new OccupationApplicationReviewItemViewModel(
          service,
          false,
          true
        );
      case ApplicationReviewItemType.RxProfile:
        return new RxProfileApplicationReviewItemViewModel(
          service,
          false,
          true
        );
      case ApplicationReviewItemType.RxProfileGlobal:
        return new RxProfileGlobalApplicationReviewItemViewModel(service);
      case ApplicationReviewItemType.Notes:
        return new QuestionNotesApplicationReviewItemViewModel(
          service,
          false,
          false
        );
      case ApplicationReviewItemType.MedicalApplicability:
        return new DropDrownApplicationReviewItemViewModel(
          service,
          false,
          true
        );
      case ApplicationReviewItemType.GeneralDetails:
        return new GeneralDetailsApplicationReviewItemViewModel(service);
      case ApplicationReviewItemType.Payment:
        return new PaymentApplicationReviewItemViewModel(service);
      case ApplicationReviewItemType.CriticalQuestion:
        return new CriticalQuestionReviewItemViewModel(service);
      default:
        return null;
    }
  }

  public abstract get allPositives();
  public abstract get showHighRiskItemFilter();
  public abstract get showApplicantDropdown();
  public abstract Process(application: Application);

  public get stats() {
    return this.statistic;
  }

  public abstract Filter(filter: ApplicationReviewItemFilter | any);
}

export class DropDrownApplicationReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  constructor(
    service: ApplicationReviewService,
    canShowHighRiskFilter: boolean,
    canShowApplicantDropdown: boolean
  ) {
    super(service);
    this.canShowHighRiskFilter = canShowHighRiskFilter;
    this.canShowApplicantDropdown = canShowApplicantDropdown;
  }
  // making it nullable to satisfy the polymorphism assignment VS code complain in the review.component
  drillDownitems?: DrillDownApplicationReviewItem[] = [];
  conditionDrilldownItems?: ConditionReviewItem[] = [];
  public drillDownMaster?: DrillDownApplicationReviewItem[];
  personalDetailsItems?: any = [];
  applicantContactList?: any = [];
  applicantInfoList?: any = [];
  ezAppApplciation?;

  get allPositives() {
    return this.items.filter((i) => i.vCall.value.toString() === 'true').length;
  }
  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Filter(filter: ApplicationReviewItemFilter) {
    this.drillDownitems = this.drillDownMaster.filter((m) => {
      let include = true;
      if (filter.applicant) {
        include = m.applicableItem.clientNo === filter.applicant.clientNo;
        this.FilterData(filter.applicant.clientNo);
      }
      if (filter.searchString) {
        include = include && m.contains(filter.searchString);
      }
      if (filter.showYesOnly !== true) {
        include =
          include &&
          (m.applicableItem.vCall?.value?.toString() === 'true' ||
            m.applicableItem.tag ===
              'MEDICALHISTORY.PRESCRIPTIONDRUGS.PRESCRIPTIONDRUGS' ||
            m.applicableItem.tag ===
              'MEDICALHISTORY.ADDRESSOFDOCTOR.ADDRESSOFDOCTOR' ||
            (m.applicableItem.ezApp &&
              m.applicableItem.ezApp?.value?.toString() === 'Y') ||
            (m.applicableItem.ezApp &&
              m.applicableItem.tag === 'MedicalHistory.GreenCard' &&
              m.applicableItem.ezApp?.value.toString() === 'N'));
      }
      return include;
    });
    this.filteredItemsCount = this.drillDownitems.length;
    if (
      this.configuration.displayLabel === 'Medical History' ||
      this.configuration.displayLabel === 'Demographic' ||
      this.configuration.displayLabel === 'Medical'
    ) {
      this.drillDownitems.map((d) => {
        // to avoid unformatted data when the doctor details not available
        if (d?.applicableItem.tag?.endsWith('.PRESCRIPTIONDRUGS')) {
          const data = JSON.parse(
            d?.applicableItem.vCall?.value?.toString()
          ).map((p) => {
            if (p?.lastDate?.hasOwnProperty('year')) {
              p.lastDate =
                p.lastDate.month + '/' + p.lastDate.day + '/' + p.lastDate.year;
            }
            return p;
          });
          d.applicableItem.vCall.value = JSON.stringify(data);
        }
        // US citizen prompt change & flipping the original value to ppposit
        if (d.applicableItem.tag === 'MEDICALHISTORY.GREENCARD.APPLICABLE') {
          d.applicableItem.vCall.prompt =
            'Is the applicant a US Citizen or have Permanent Residence status (Green Card)?';
        }
        if (
          d.applicableItem.tag ===
            'MEDICALHISTORY.PRESCRIPTIONDRUGS.PRESCRIPTIONDRUGS' &&
          this.IsJsonString(d?.applicableItem?.ezApp?.value)
        ) {
          d.applicableItem.ezApp.value = JSON.parse(
            d.applicableItem?.ezApp?.value?.toString()
          );
        }
        d?.items?.map((item) => {
          item.value = item.value.filter(
            (v) =>
              v.vCall.value !== 'DateRange' &&
              v.vCall.value !== 'ExactDate' &&
              v.vCall.value !== 'Completed' &&
              v.vCall.value !== '[]'
          );
          item.value.map((detail, index) => {
            if (
              detail?.tag?.endsWith('.DOCTORNAMEADDRESS') &&
              this.IsJsonString(detail?.vCall?.value?.toString())
            ) {
              const doctorInfo = JSON.parse(detail?.vCall?.value?.toString());
              if (doctorInfo.isProviderAvailable === false) {
                delete detail.vCall.value;
                detail.vCall.value = 'N/A';
              }
            }
            if (
              detail.tag?.endsWith('.DATE') &&
              detail.vCall?.value?.toString().includes('-')
            ) {
              const date = detail.vCall.value.toString().split('-');
              detail.vCall.value = date[1] + '/' + date[2] + '/' + date[0];
            }
            if (detail?.tag?.endsWith('.NOTES')) {
              detail.vCall.prompt = 'Notes';
            }
            if (
              detail &&
              detail.tag.endsWith('.ISPROVIDERAVAILABLE') &&
              detail.vCall.value === false
            ) {
              detail.vCall.value = 'N/A';
            }
            // To change date format for medication data
            if (
              detail?.tag?.endsWith('.NAMEOFMEDICATION') ||
              detail?.tag?.endsWith('.NAMEOFMEDICATIONTAKING') ||
              detail?.tag?.endsWith('.NAMEOFNEWMEDICATION')
            ) {
              const data =
                detail?.vCall?.value !== undefined &&
                detail?.vCall?.value !== null
                  ? Array.isArray(JSON.parse(detail?.vCall?.value?.toString()))
                    ? JSON.parse(detail?.vCall?.value?.toString()).map((p) => {
                        if (p?.lastDate?.hasOwnProperty('year')) {
                          p.lastDate =
                            p.lastDate.month +
                            '/' +
                            p.lastDate.day +
                            '/' +
                            p.lastDate.year;
                        }
                        return p;
                      })
                    : []
                  : '';
              detail.vCall.value = JSON.stringify(data);
            }
          });
          if (
            d?.conditions?.ezApp !== undefined &&
            this.IsJsonString(d?.conditions?.ezApp?.value)
          ) {
            d.conditions.ezApp.value = JSON.parse(
              d.conditions?.ezApp?.value?.toString()
            );
          }
        });
        // changing the prompt text for follow up questions
        d?.followupItems?.map((followup) => {
          followup?.value?.map((q) => {
            if (q?.tag?.endsWith('.NOTES')) {
              q.vCall.prompt = 'Notes';
            }
          });
        });
      });
    }
  }
  IsJsonString?(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  Process(application: Application) {
    this.statistic = [];
    this.ezAppApplciation = application;
    this.drillDownitems = [];
    this.items.map((i) => {
      this.drillDownitems.push(
        this.service.GetMedicalHistoryDrillDownReviewItem(i, this.all)
      );
    });
    this.drillDownMaster = [...this.drillDownitems];
    this.all.filter((d) => {
      if (d.tag.startsWith('APPLICATION.CONTACT')) {
        this.applicantContactList.push(d);
      }
      if (
        d.tag.startsWith('APPLICATION.PRIMARYAPPLICANT') ||
        d.tag.startsWith('APPLICATION.FAMILY')
      ) {
        this.applicantInfoList.push(d);
      }
    });
    this.filteredItemsCount = this.drillDownMaster.length;
  }
  FilterData?(clientNo) {
    this.personalDetailsItems = [];
    const ezzAppApplicantList = [
      this.ezAppApplciation.primaryApplicant,
      ...this.ezAppApplciation.family,
    ];
    const firstName = new personalDetailsReviewItem(null);
    firstName.displayLabel = 'First Name';
    firstName.ezAppValue = ezzAppApplicantList
      .filter((v) => v.clientNo === clientNo)
      ?.pop()?.firstName;
    firstName.vCallValue = this.GetValueFromTag(
      clientNo,
      '.FIRSTNAME'
    )?.vCall?.value;
    this.personalDetailsItems.push(firstName);
    const lastName = new personalDetailsReviewItem(null);
    lastName.displayLabel = 'Last Name';
    lastName.ezAppValue = ezzAppApplicantList
      .filter((v) => v.clientNo === clientNo)
      ?.pop()?.lastName;
    lastName.vCallValue = this.GetValueFromTag(
      clientNo,
      '.LASTNAME'
    )?.vCall?.value;
    this.personalDetailsItems.push(lastName);
    const middleName = new personalDetailsReviewItem(null);
    middleName.displayLabel = 'Middle Name';
    middleName.ezAppValue = ezzAppApplicantList
      .filter((v) => v.clientNo === clientNo)
      ?.pop()?.middleName;
    middleName.vCallValue = this.GetValueFromTag(
      clientNo,
      '.MIDDLENAME'
    )?.vCall?.value;
    this.personalDetailsItems.push(middleName);
    const birthDate = new personalDetailsReviewItem(null);
    birthDate.displayLabel = 'DOB';
    birthDate.ezAppValue = ezzAppApplicantList
      .filter((v) => v.clientNo === clientNo)
      ?.pop()?.birthDate;
    birthDate.vCallValue = this.GetValueFromTag(
      clientNo,
      '.BIRTHDATE'
    )?.vCall?.value;
    this.personalDetailsItems.push(birthDate);
    const gender = new personalDetailsReviewItem(null);
    gender.displayLabel = 'Gender';
    gender.ezAppValue = ezzAppApplicantList
      .filter((v) => v.clientNo === clientNo)
      ?.pop()?.gender;
    gender.vCallValue = this.GetValueFromTag(clientNo, '.GENDER')?.vCall?.value;
    this.personalDetailsItems.push(gender);
    const height = new personalDetailsReviewItem(null);
    height.displayLabel = 'Height';
    height.ezAppValue = ezzAppApplicantList
      .filter((v) => v.clientNo === clientNo)
      ?.pop()?.height;
    height.vCallValue = this.GetValueFromTag(clientNo, '.HEIGHT')?.vCall?.value;
    this.personalDetailsItems.push(height);
    const weight = new personalDetailsReviewItem(null);
    weight.displayLabel = 'Weight';
    weight.ezAppValue = ezzAppApplicantList
      .filter((v) => v.clientNo === clientNo)
      ?.pop()?.weight;
    weight.vCallValue = this.GetValueFromTag(clientNo, '.WEIGHT')?.vCall?.value;
    this.personalDetailsItems.push(weight);
    const ssn = new personalDetailsReviewItem(null);
    ssn.displayLabel = 'SSN';
    ssn.ezAppValue = new MaskPipe().transform(
      ezzAppApplicantList.filter((v) => v.clientNo === clientNo)?.pop()?.ssn,
      4
    );
    ssn.vCallValue = new MaskPipe().transform(
      this.GetValueFromTag(clientNo, '.SSN')?.vCall?.value,
      4
    );
    this.personalDetailsItems.push(ssn);
  }

  GetValueFromTag?(clientNo, tag?) {
    return this.applicantInfoList
      ?.filter((val) => val.clientNo === clientNo && val.tag.endsWith(tag))
      .pop();
  }
}

export class DiscrepancyApplicationReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  constructor(
    service: ApplicationReviewService,
    canShowHighRiskFilter: boolean,
    canShowApplicantDropdown: boolean
  ) {
    super(service);
    this.canShowHighRiskFilter = canShowHighRiskFilter;
    this.canShowApplicantDropdown = canShowApplicantDropdown;
  }

  itemsMaster?: ApplicationReviewItem[];

  get allPositives() {
    return this.filteredItemsCount;
  }

  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Process(application: Application) {
    this.statistic = [];
    this.items = this.all.filter((i) => i.IsDifferent === true);
    this.itemsMaster = [...this.items];

    if (application) {
      application.applicants.map((a) =>
        // TODO: Gopi For Discrepancy group the below calculation needs to be changed.
        this.statistic.push({
          key: a,
          value: this.items.filter((i) => i.clientNo === a.clientNo).length,
        })
      );
    }
    this.filteredItemsCount = this.statistic
      .map((item) => item.value)
      .reduce((prev, next) => prev + next);
  }

  Filter(filter: ApplicationReviewItemFilter) {
    this.items = this.itemsMaster.filter((m) => {
      let include = true;

      if (filter.applicant) {
        include = m.clientNo === filter.applicant.clientNo;
      }

      if (filter.searchString && filter.searchString.trim().length > 0) {
        include = include && m.contains(filter.searchString);
      }

      if (filter.showYesOnly) {
        console.log('Not Supported');
      }

      return include;
    });
  }
}

export class OccupationApplicationReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  constructor(
    service: ApplicationReviewService,
    canShowHighRiskFilter: boolean,
    canShowApplicantDropdown: boolean
  ) {
    super(service);
    this.canShowHighRiskFilter = canShowHighRiskFilter;
    this.canShowApplicantDropdown = canShowApplicantDropdown;
  }

  occupationMaster?: ApplicationReviewItem[];
  occupationList?: OccupationReviewItem[] = [];
  occupationName?: string;
  clientNo?: string;

  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Process(application: Application) {
    this.statistic = [];
    this.items = this.all.filter(
      (i) =>
        i.tag.startsWith('DEMOGRAPHIC.EMPLOYMENT') ||
        i.tag.startsWith('DEMOGRAPHIC.STUDENT.EMPLOYED') ||
        i.tag.startsWith('DEMOGRAPHIC.STUDENT.CREDITHOURS')
    );
    this.occupationMaster = [...this.items];
    if (application) {
      application.applicants.map((a) => {
        this.FilterData(a.clientNo);
        this.statistic.push({
          key: a,
          value: this.occupationList.filter((i) => this.FilterData(i.clientNo))
            .length,
        });
      });
    }
    this.filteredItemsCount = this.statistic
      .map((item) => item.value)
      .reduce((prev, next) => prev + next);
  }

  get allPositives() {
    return this.filteredItemsCount;
  }

  Filter(filter: ApplicationReviewItemFilter) {
    if (filter?.applicant?.clientNo) {
      this.FilterData(filter?.applicant?.clientNo);
    }
    this.items = this.occupationList;
    if (filter.searchString && filter.searchString.trim().length > 0) {
      this.items = this.occupationList.filter((m) =>
        m.contains(filter.searchString)
      );
    }
    this.occupationName = this.occupationList
      .filter((o) => {
        if (o.displayLabel === 'Occupation') {
          return o;
        }
      })
      .pop().vCallValue;
  }

  FilterData?(clientNo) {
    this.occupationList = [];
    const field = new OccupationReviewItem(null);
    field.displayLabel = 'Domain';
    field.vCallValue =
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicFieldCode
      )?.vCall?.value?.toString() +
      ' ' +
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicFieldName
      )?.vCall?.value?.toString();
    field.ezAppValue =
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicFieldCode
      )?.ezApp?.value?.toString() +
      ' ' +
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicFieldName
      )?.ezApp?.value?.toString();
    field.clientNo = this.GetClientNumber(
      clientNo,
      field.vCallValue,
      OccupationAnswerTag.DemographicFieldCode
    );
    field.type = 'Employed';
    this.occupationList.push(field);
    const occupation = new OccupationReviewItem(null);
    occupation.displayLabel = 'Occupation';
    occupation.vCallValue =
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicOccupationCode
      )?.vCall?.value?.toString() +
      ' ' +
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicOccupationName
      )?.vCall?.value?.toString();
    occupation.ezAppValue =
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicOccupationCode
      )?.ezApp?.value?.toString() +
      ' ' +
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicOccupationName
      )?.ezApp?.value?.toString();
    occupation.clientNo = this.GetClientNumber(
      clientNo,
      occupation.vCallValue,
      OccupationAnswerTag.DemographicOccupationCode
    );
    occupation.type = 'Employed';
    this.occupationList.push(occupation);
    const duties = new OccupationReviewItem(null);
    duties.displayLabel = 'Duties';
    duties.vCallValue = this.GetValueFromTag(
      clientNo,
      OccupationAnswerTag.DemographicDuties
    )?.vCall?.value?.toString();
    duties.ezAppValue = this.GetValueFromTag(
      clientNo,
      OccupationAnswerTag.DemographicDuties
    )?.ezApp?.value?.toString();
    duties.clientNo = this.GetClientNumber(
      clientNo,
      duties.vCallValue,
      OccupationAnswerTag.DemographicDuties
    );
    duties.type = 'Employed';
    this.occupationList.push(duties);

    // for employed credit hours start
    const hours = new OccupationReviewItem(null);
    hours.displayLabel = 'Hours ( Employment )';
    hours.vCallValue = this.GetValueFromTag(
      clientNo,
      OccupationAnswerTag.DemographicEmployedNotes
    )?.vCall?.value?.toString();
    hours.ezAppValue = this.GetValueFromTag(
      clientNo,
      OccupationAnswerTag.DemographicEmployedNotes
    )?.ezApp?.value?.toString();
    hours.clientNo = this.GetClientNumber(
      clientNo,
      hours.vCallValue,
      OccupationAnswerTag.DemographicEmployedNotes
    );
    hours.type = 'Employed';
    this.occupationList.push(hours);
    // for employed credit hours end

    const studentField = new OccupationReviewItem(null);
    studentField.displayLabel = 'Domain';
    studentField.vCallValue =
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicStudentFieldCode
      )?.vCall?.value?.toString() +
      ' ' +
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicStudentFieldName
      )?.vCall?.value?.toString();
    studentField.ezAppValue =
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicStudentFieldCode
      )?.ezApp?.value?.toString() +
      ' ' +
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicStudentFieldName
      )?.ezApp?.value?.toString();
    studentField.clientNo = this.GetClientNumber(
      clientNo,
      studentField.vCallValue,
      OccupationAnswerTag.DemographicStudentFieldCode
    );
    studentField.type = 'Employed';
    this.occupationList.push(studentField);
    const studentOccupation = new OccupationReviewItem(null);
    studentOccupation.displayLabel = 'Occupation';
    studentOccupation.vCallValue =
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicStudentOccupationCode
      )?.vCall?.value?.toString() +
      ' ' +
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicStudentOccupationName
      )?.vCall?.value?.toString();
    studentOccupation.ezAppValue =
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicStudentOccupationCode
      )?.ezApp?.value?.toString() +
      ' ' +
      this.GetValueFromTag(
        clientNo,
        OccupationAnswerTag.DemographicStudentOccupationName
      )?.ezApp?.value?.toString();
    studentOccupation.clientNo = this.GetClientNumber(
      clientNo,
      studentOccupation.vCallValue,
      OccupationAnswerTag.DemographicStudentOccupationCode
    );
    studentOccupation.type = 'Employed';
    this.occupationList.push(studentOccupation);
    const studentDuties = new OccupationReviewItem(null);
    studentDuties.displayLabel = 'Duties';
    studentDuties.vCallValue = this.GetValueFromTag(
      clientNo,
      OccupationAnswerTag.DemographicStudentDuties
    )?.vCall?.value?.toString();
    studentDuties.ezAppValue = this.GetValueFromTag(
      clientNo,
      OccupationAnswerTag.DemographicStudentDuties
    )?.ezApp?.value?.toString();
    studentDuties.clientNo = this.GetClientNumber(
      clientNo,
      studentDuties.vCallValue,
      OccupationAnswerTag.DemographicStudentDuties
    );
    studentDuties.type = 'Employed';
    this.occupationList.push(studentDuties);

    const notes = new OccupationReviewItem(null);
    notes.displayLabel = 'Notes';
    notes.vCallValue = this.GetValueFromTag(
      clientNo,
      OccupationAnswerTag.DemographicNotes
    )?.vCall?.value?.toString();
    notes.clientNo = this.GetClientNumber(
      clientNo,
      notes.vCallValue,
      OccupationAnswerTag.DemographicNotes
    );
    notes.type = 'Unemployed';
    this.occupationList.push(notes);
    const creditHours = new OccupationReviewItem(null);
    creditHours.displayLabel = 'Credit Hours ( FullTime Student )';
    creditHours.vCallValue = this.GetValueFromTag(
      clientNo,
      OccupationAnswerTag.DemographicStudent
    )?.vCall?.value?.toString();
    creditHours.clientNo = this.GetClientNumber(
      clientNo,
      creditHours.vCallValue,
      OccupationAnswerTag.DemographicStudent
    );
    creditHours.type = 'FullTime Student';
    this.occupationList.push(creditHours);
    this.occupationList.map((val) => {
      if (val?.vCallValue?.includes('undefined')) {
        val.vCallValue = null;
      }
      if (val?.ezAppValue?.includes('undefined')) {
        val.ezAppValue = null;
      }
    });
    return this.occupationList;
  }

  GetValueFromTag?(clientNo, tag?) {
    return this.occupationMaster
      ?.filter((val) => val.clientNo === clientNo && val.tag === tag)
      .pop();
  }

  GetClientNumber?(clientNo, val, tag) {
    let value = null;
    this.occupationMaster.forEach((o) => {
      if (o.clientNo === clientNo && o.tag === tag) {
        value = o.clientNo;
      }
    });
    return value;
  }
}

/* eslint-disable */
export class RxProfileApplicationReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  readonly RX_AUTH_QUALIFYING_APPLICABLE? =
    'DEMOGRAPHIC.RXAUTHORIZATION.APPLICABLE';
  readonly RX_AUTH_DETAILED_APPLICABLE? =
    'DEMOGRAPHIC.RXAUTHORIZATION.RXAUTH.APPLICABLE';
  readonly RX_AUTH_DETAILED_NOTES? = 'DEMOGRAPHIC.RXAUTHORIZATION.RXAUTH.NOTES';
  readonly RX_AUTH_QUALIFYING_YES? = 'Applicant was available';
  readonly RX_AUTH_QUALIFYING_NO? = 'Applicant not available';
  readonly RX_AUTH_DETAILED_YES? = 'Rx profile authorization was obtained';
  readonly RX_AUTH_DETAILED_NO? = 'Applicant declined';

  constructor(
    service: ApplicationReviewService,
    canShowHighRiskFilter: boolean,
    canShowApplicantDropdown: boolean
  ) {
    super(service);
    this.canShowHighRiskFilter = canShowHighRiskFilter;
    this.canShowApplicantDropdown = canShowApplicantDropdown;
  }

  itemsMaster?: ApplicationReviewItem[];
  rxProfileList?: RxprofileReviewItem[] = [];

  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Process(application: Application) {
    this.statistic = [];
    this.items = this.all.filter((i) =>
      i.tag.startsWith('DEMOGRAPHIC.RXAUTHORIZATION')
    );
    this.itemsMaster = [...this.items];
    if (application) {
      application.applicants.map((a) => {
        this.FilterData(a.clientNo);
        this.statistic.push({
          key: a,
          value: this.GetRxAuthStatus(a.clientNo),
        });
      });
    }
    this.filteredItemsCount = this.itemsMaster.filter(
      (i) =>
        (i.tag === this.RX_AUTH_QUALIFYING_APPLICABLE &&
          i?.vCall?.value === false) ||
        (i.tag === this.RX_AUTH_DETAILED_APPLICABLE &&
          i?.vCall?.value === 'false')
    ).length;
  }

  GetRxAuthStatus?(clientNo) {
    const rxDetailedApplicable = this.GetValueFromTag(
      clientNo,
      this.RX_AUTH_DETAILED_APPLICABLE
    )?.vCall?.value;
    if (rxDetailedApplicable === 'false') {
      return RxProfileStatus.RX_AUTH_DECLINED;
    } else if (rxDetailedApplicable === 'true') {
      return RxProfileStatus.RX_AUTH_APPLICABLE;
    } else {
      return RxProfileStatus.RX_AUTH_APPLICANT_NOT_ONLINE;
    }
  }

  Filter(filter: ApplicationReviewItemFilter) {
    if (filter?.applicant) {
      this.items = filter?.applicant?.clientNo
        ? this.FilterData(filter?.applicant?.clientNo)
        : [];
      this.items = filter?.applicant?.clientNo ? this.rxProfileList : [];
      this.rxAuthorizationApplicable = this.items.some(
        (i) => i.value === this.RX_AUTH_QUALIFYING_YES
      );
    }
    if (filter.searchString && filter.searchString.trim().length > 0) {
      this.items = this.rxProfileList.filter((m) =>
        m.contains(filter.searchString)
      );
    }
  }
  get allPositives() {
    return this.filteredItemsCount;
  }

  FilterData?(clientNo) {
    this.rxProfileList = [];
    const status = new RxprofileReviewItem(null);
    status.prompt = this.GetValueFromTag(
      clientNo,
      this.RX_AUTH_QUALIFYING_APPLICABLE
    )?.vCall?.prompt?.toString();
    status.value =
      this.GetValueFromTag(
        clientNo,
        this.RX_AUTH_QUALIFYING_APPLICABLE
      )?.vCall?.value?.toString() === 'true'
        ? this.RX_AUTH_QUALIFYING_YES
        : this.RX_AUTH_QUALIFYING_NO;
    status.clientNo = clientNo;
    this.rxProfileList.push(status);
    if (status.value === this.RX_AUTH_QUALIFYING_YES) {
      const detailedQuestion = new RxprofileReviewItem(null);
      detailedQuestion.prompt = this.GetValueFromTag(
        clientNo,
        this.RX_AUTH_DETAILED_APPLICABLE
      )?.vCall?.prompt?.toString();
      detailedQuestion.value =
        this.GetValueFromTag(
          clientNo,
          this.RX_AUTH_DETAILED_APPLICABLE
        )?.vCall?.value?.toString() === 'true'
          ? this.RX_AUTH_DETAILED_YES
          : this.RX_AUTH_DETAILED_NO;
      this.rxProfileList.push(detailedQuestion);

      const detailedNotes = new RxprofileReviewItem(null);
      detailedNotes.prompt = this.GetValueFromTag(
        clientNo,
        this.RX_AUTH_DETAILED_NOTES
      )?.vCall?.prompt?.toString();
      detailedNotes.value = this.GetValueFromTag(
        clientNo,
        this.RX_AUTH_DETAILED_NOTES
      )?.vCall?.value?.toString();
      this.rxProfileList.push(detailedNotes);
      return this.rxProfileList;
    }
  }

  GetValueFromTag?(clientNo, tag?) {
    return this.itemsMaster
      ?.filter((val) => val.clientNo === clientNo && val.tag === tag)
      .pop();
  }
}

export class QuestionNotesApplicationReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  constructor(
    service: ApplicationReviewService,
    canShowHighRiskFilter: boolean,
    canShowApplicantDropdown: boolean
  ) {
    super(service);
    this.canShowHighRiskFilter = canShowHighRiskFilter;
    this.canShowApplicantDropdown = canShowApplicantDropdown;
  }
  questionNotesList?: RxprofileReviewItem[] = [];

  get allPositives() {
    return this.questionNotesList.length;
  }

  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Process(application: Application) {
    const notesList = new Array<NotesReviewItem>();
    this.all.map((i) => {
      if (
        i.vCall.hasOwnProperty('notes') &&
        i.tag !== 'DEMOGRAPHIC.BENEFICIARY.AILMENTS' &&
        !notesList.some((i) => i.tag === 'DEMOGRAPHIC.BENEFICIARY.APPLICABLE')
      ) {
        notesList.push(i);
      }
    });
    this.items = notesList;
    this.questionNotesList = [...notesList];
  }

  Filter(filter: ApplicationReviewItemFilter) {
    if (filter.searchString && filter.searchString.trim().length > 0) {
      this.items = this.questionNotesList?.filter((m) =>
        m.contains(filter.searchString)
      );
    } else {
      this.items = this.questionNotesList;
    }
    this.filteredItemsCount = this.items.length;
  }
}

export class PaymentApplicationReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  constructor(service: ApplicationReviewService) {
    super(service);
  }
  itemsMaster?: ApplicationReviewItem[];
  InitialPaymentList?: PaymentReviewItem[] = [];
  MonthlyPaymentList?: PaymentReviewItem[] = [];

  get allPositives() {
    return;
  }

  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Process(application: Application) {
    this.itemsMaster = this.all.filter((i) =>
      i.tag.startsWith('APPLICATION.PAYMENT')
    );
    this.items = [...this.itemsMaster];
  }

  Filter(filter: ApplicationReviewItemFilter) {
    this.items = this.itemsMaster.filter((m) => {
      let include = true;

      if (filter.applicant) {
        include = m.clientNo === filter.applicant.clientNo;
        if (m.tag.startsWith('APPLICATION.PAYMENT.INIT')) {
          this.InitialPaymentList.push(m);
        } else {
          this.MonthlyPaymentList.push(m);
        }
      }

      if (filter.searchString && filter.searchString.trim().length > 0) {
        include = include && m.contains(filter.searchString);
      }

      if (filter.showYesOnly) {
        console.log('Not Supported');
      }

      return include;
    });
  }
}
export class RxProfileGlobalApplicationReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  readonly RX_AUTH_QUALIFYING_APPLICABLE? = 'DEMOGRAPHIC.AUTHORIZATION';
  readonly RX_AUTH_QUALIFYING_YES? = 'Rx profile authorization was obtained';
  readonly RX_AUTH_QUALIFYING_NO? = 'Applicant declined';

  constructor(service: ApplicationReviewService) {
    super(service);
  }

  itemsMaster?: ApplicationReviewItem[];
  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Process(application: Application) {
    this.statistic = [];
    this.items = this.all.filter((i) =>
      i.tag.startsWith('DEMOGRAPHIC.AUTHORIZATION')
    );
    this.itemsMaster = [...this.items];
  }

  Filter(filter: ApplicationReviewItemFilter) {
    console.log('not applicable');
  }
  get allPositives() {
    return;
  }
}
export class GeneralDetailsApplicationReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  constructor(service: ApplicationReviewService) {
    super(service);
  }
  itemsMaster?: ApplicationReviewItem[];
  demographicList?: ApplicationReviewItem[];
  medicalList?: ApplicationReviewItem[];
  medicalHistoryList?: ApplicationReviewItem[];

  get allPositives() {
    return;
  }

  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Process(application: Application) {
    this.itemsMaster = this.all.filter(
      (i) =>
        (i.tag.startsWith('DEMOGRAPHIC.') ||
          i.tag.startsWith('MEDICAL.') ||
          i.tag.startsWith('MEDICALHISTORY.')) &&
        !i.tag.startsWith('DEMOGRAPHIC.AUTHORIZATION')
    );
    this.items = [...this.itemsMaster];
  }

  Filter(filter: ApplicationReviewItemFilter) {
    this.items = this.itemsMaster.filter((m) => {
      let include = true;

      if (filter.applicant) {
        include = m.clientNo === null && !m.tag.endsWith('.DASHBOARD');
      }

      if (filter.searchString && filter.searchString.trim().length > 0) {
        include = include && m.contains(filter.searchString);
      }

      if (filter.showYesOnly) {
        console.log('Not Supported');
      }

      return include;
    });
    this.items.map((i) => {
      if (
        i.vCall?.value?.toString() !== 'true' &&
        i.vCall?.value?.toString() !== 'false'
      ) {
        i.vCall.prompt = 'Notes';
      }
    });
    this.demographicList = this.items.filter((i) =>
      i.tag.startsWith('DEMOGRAPHIC.')
    );
    this.medicalList = this.items.filter((i) => i.tag.startsWith('MEDICAL.'));
    this.medicalHistoryList = this.items.filter((i) =>
      i.tag.startsWith('MEDICALHISTORY.')
    );
  }
}

export class CriticalQuestionReviewItemViewModel extends ApplicationReviewItemViewModel {
  canShowHighRiskFilter?: boolean;
  canShowApplicantDropdown?: boolean;
  criticalQuestions = [];
  constructor(service: any) {
    super(service);
  }

  get allPositives() {
    return;
  }

  get showHighRiskItemFilter() {
    return this.canShowHighRiskFilter;
  }

  get showApplicantDropdown() {
    return this.canShowApplicantDropdown;
  }

  Process(application: Application): any {
    this.items = [];
    this.criticalQuestions = [];
    const list = new Array<Question[]>();

    this.script.items.map((q) => {
      list.push(q.questions);
    });

    // Prepare & Process your item here.
    list.forEach((section) => {
      section.forEach((question) => {
        if (question.details?.Critical === 'true') {
          this.criticalQuestions.push(question);
          const answeredQuestions = this.all.filter((i) => {
            const canDisplay =
              ((question.answerTag + '.APPLICABLE').toUpperCase() === i.tag ||
                question.answerTag.toUpperCase() === i.tag) &&
              (i.vCall.value === null ||
                i.vCall.value.toString().toLowerCase() === 'true');
            return canDisplay;
          });

          answeredQuestions.map((a) => {
            const item = new ApplicationReviewItem(null);
            item.tag = a.tag;
            item.value = a.vCall.value !== null ? a.vCall.value.toString() : '';
            item.clientNo = a.clientNo;
            item.prompt = a.vCall.prompt;
            this.items.push(item);
          });
        }
      });
    });
  }

  Filter(filter: ApplicationReviewItemFilter) {
    // Just apply the applicant filter.
    this.items = this.items.filter(
      (c) => c.clientNo == filter.applicant.clientNo
    );
  }
}

export interface ApplicationReviewItemConfiguration {
  tagExpression: any;
  displayLabel: string;
  type: ApplicationReviewItemType;
}

export interface ApplicationReviewItemFilter {
  applicant?: Applicant;
  searchString?: string;
  showYesOnly?: boolean;
}

export class OccupationReviewItem extends ApplicationReviewItem {
  prompt: string;
  value: string;
  displayLabel?: string;
  vCallValue?: string;
  ezAppValue?: string;
  type?: string;
}
export class RxprofileReviewItem extends ApplicationReviewItem {
  notes?: string;
  prompt: string;
  value: string;
}

export class NotesReviewItem extends ApplicationReviewItem {
  notes?: string;
  prompt: string;
  value: string;
}
export class ConditionReviewItem extends ApplicationReviewItem {
  eZappcondition?: string;
  ailmentTag?: string;
}

export class PaymentReviewItem extends ApplicationReviewItem {
  prompt: string;
  value: string;
}

export class personalDetailsReviewItem extends ApplicationReviewItem {
  displayLabel?: string;
  vCallValue?: string;
  ezAppValue?: string;
}

export enum RxProfileStatus {
  RX_AUTH_APPLICANT_NOT_ONLINE = 1,
  RX_AUTH_APPLICABLE = 2,
  RX_AUTH_DECLINED = 3,
}
