const valuesOfCompaniesData = {
  fullForm: [
    { key: 'FLICA', value: 'Freedom Life Insurance Company of America' },
    { key: 'NFL', value: 'National Foundation Life Insurance Company' },
    { key: 'ELIC', value: 'Enterprise Life Insurance Company' },
    {
      key: 'SCA',
      value: 'Southern Consumers Alliance Enrollment Verification',
    },
    { key: 'AIBC', value: 'American Independent Business Coalition' },
    { key: 'ABC', value: 'American Business Coalition' },
  ],
  phone: [
    { key: 'SCA', value: '800-992-8044' },
    { key: 'AIBC', value: '800-992-8044' },
    { key: 'NFL', value: '800-221-9039' },
    { key: 'ELIC', value: '800-606-4482' },
    { key: 'FLICA', value: '800-387-9027' },
  ],
};

export default valuesOfCompaniesData;
