<div class="call-list pl-1">
  <div class="mt-4 mb-3 pl-1 date-scroller">
    <span>
      <button
        class="btn btn-sm bg-white p-1 m-2 shadow"
        (click)="getAppointmentsByDate()"
        [disabled]="day === 'Today\'s'"
      >
        <i class="fa fa-home f-17 p-0 text-primary"></i>
      </button>
    </span>
    <span>
      <button
        class="btn btn-sm bg-white pt-0 pb-0 pl-1 shadow"
        (click)="getAppointmentsByDate('decrement')"
      >
        <i class="fa fa-caret-left f-26 p-0 text-primary"></i>
      </button>
    </span>

    <h6 class="m-2 d-inline-block text-center w-65 date-content">
      <span (click)="d.toggle()" class="cursor-pointer">
        {{ day }}
      </span>
      <input
        #d="ngbDatepicker"
        class="form-control d-none"
        placeholder="Date last taken"
        [(ngModel)]="datepicker"
        ngbDatepicker
        readonly
        (dateSelect)="saveSelectedDate($event)"
      />
      <span class="font-weight-bold" *ngIf="listOfAppointments?.length">
        ({{ listOfAppointments?.length }})
      </span>
    </h6>
    <span class="float-right m-2 right-arrow">
      <button
        class="btn btn-sm bg-white pt-0 pb-0 pl-1 shadow"
        (click)="getAppointmentsByDate('increment')"
      >
        <i class="fa fa-caret-right f-26 p-0 ml-1 text-primary"></i>
      </button>
    </span>
  </div>

  <!-- TODO:// this needs to go inside the component -->
  <span
    (click)="selectAppointment(item)"
    class="badge badge-light w-100"
    [ngClass]="{
      'bg-primary text-white':
        selectedAppointment?.appointmentId === item.appointmentId,
      'bg-white text-dark shadow':
        selectedAppointment?.appointmentId !== item.appointmentId
    }"
    *ngFor="let item of appointments | async"
  >
    <div class="row">
      <div class="col-sm-6">
        {{ item.firstName + " " + item.lastName }}<br />
        <span class="small"
          >{{ item.callType }}
          <span *ngIf="item.callType !== 'Group'"
            >({{ item.callType | cbduration }})</span
          >
        </span>
      </div>
      <div class="col-sm-6 text-right">
        <span class="small text-uppercase">
          {{ item.scheduledDate | datewithTimeZone: timeZone:"time" }}
        </span>
        &nbsp;
        <i class="fas fa-chevron-circle-right text-primary circle"></i>
        <div class="ml-auto">
          <span
            *ngIf="item.statusName === 'TODO'"
            class="badge badge-pill badge-secondary p-1 mb-0 w-auto font-12 mt-1"
            >To Do</span
          >
          <span
            *ngIf="item.statusName === 'Completed'"
            class="badge badge-pill badge-success p-1 mb-0 w-auto font-12 mt-1"
            >Completed</span
          >
          <span
            *ngIf="item.statusName === 'No Show'"
            class="badge badge-pill badge-warning p-1 mb-0 w-auto font-12 mt-1"
            >No Show</span
          >
          <span
            *ngIf="item.statusName === 'Stop Call'"
            class="badge badge-pill badge-danger p-1 mb-0 w-auto font-12 mt-1"
            >Stop Call</span
          >
          <span
            *ngIf="item.statusName === 'Incomplete'"
            class="badge badge-pill badge-primary p-1 mb-0 w-auto font-12 mt-1"
            >Incomplete
          </span>
          <span
            *ngIf="item.statusName === 'Canceled'"
            class="badge badge-pill badge-info p-1 mb-0 w-auto font-12 mt-1"
            >Canceled
          </span>
          <span
            *ngIf="item.statusName === 'In Progress'"
            class="badge badge-pill badge-dark p-1 mb-0 w-auto font-12 mt-1"
            >In Progress
          </span>
        </div>
      </div>
    </div>
  </span>
</div>

<hr class="mx-3 mt-2" />
