import { Injectable } from '@angular/core';
import {
  getReleaseNumberFromBuild,
  parseReleasedVersion,
} from '../shared/models/vcall/release-details';
import { ToastService } from '../shared/services/toast/toast.service';
import { versionNo } from '../../app/version/version-number';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private toastr: ToastService) {}
  versionsCheck() {
    getReleaseNumberFromBuild().then((res) => {
      const version = parseReleasedVersion(res);
      this.verifyCurrentRenderedVersionAndReleaseVersion(res);
    });
  }

  verifyCurrentRenderedVersionAndReleaseVersion(releaseversion) {
    const currentRenderedVersion = versionNo;
    if (!releaseversion.includes(currentRenderedVersion)) {
      this.toastr.error(
        null,
        'Please update Vcall application By hitting ctrl+shift+r'
      );
    }
  }
}
