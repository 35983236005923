export class MedicalItem {
  constructor(options) {
    Object.assign(this, options);
  }
  clientNo: string;
  applicable: boolean;
  ailments?: string[];
  value: any;
  ezappValue?: string;
  ezappAilments?: string;
  verification: boolean;
}

export interface MedicalItemList {
  medicalList: Array<MedicalItem>;
}

export class ApplicantMedicalItem extends MedicalItem {
  firstName: string;
  lastName: string;
}
