import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  AfterContentInit,
} from '@angular/core';
import { BaseVerifiableListQuestionType } from '../../types/verifiableList/baseVerifiableListQuestionType';
import { Subscription } from 'rxjs';
import {
  NgbModalRef,
  NgbModal,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UspsService } from 'src/app/shared/services/usps.service';
import { Address } from 'src/app/shared/models/address';
import { Payment } from 'src/app/shared/models/application/payment';
import { VcallQuestionTypePaymentDetail } from 'src/app/shared/models/vcall/paymentDetail';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-payor-information',
  templateUrl: './payor-information.component.html',
  styleUrls: ['./payor-information.component.css'],
})
export class PayorInformationComponent
  extends BaseVerifiableListQuestionType<VcallQuestionTypePaymentDetail, string>
  implements AfterViewInit, OnDestroy, AfterContentInit
{
  billingInfo = {
    city: null,
    state: null,
    zipCode: null,
    streetAddress: null,
    phone: null,
    name: null,
    businessName: null,
    businessOwner: null,
    notes: null,
    authorizedName: null,
    authorizedPhone: null,
    bankName: null,
    routingNumber: null,
    accountNumber: null,
    email: null,
  };

  editPayment: BillingInformation = new BillingInformation(this.billingInfo);
  subscriptions: Array<Subscription> = new Array<Subscription>();
  isAddressInValid = false;
  options: string;
  payorType = '';
  methodofPaymentType = '';
  private modalRef: NgbModalRef;
  showList = true;
  addressFormIsValid;
  isPatternValid = true;
  payment: Payment;
  stepText = '';
  @ViewChild('content', { static: true }) content: TemplateRef<any>;
  @ViewChild('uspsAddress') private form: NgForm;
  saveClicked = false;
  displayEmailNotification: boolean = false;
  isEmailRequired: boolean = false;

  constructor(
    private modalService: NgbModal,
    public scriptService: ScriptService,
    applicationService: ApplicationService,
    private uspsService: UspsService,
    private SpinnerService: NgxSpinnerService
  ) {
    super(scriptService, applicationService);
  }

  ngAfterViewInit() {
    // this.isEmailRequired =
    //   this.applicationService.GetAnswerForAnswerTag(
    //     'payment.initialPaymentBy'
    //   ) !== undefined
    //     ? this.applicationService.GetAnswerForAnswerTag(
    //         'payment.initialPaymentBy'
    //       ).value === 'CREDIT CARD'
    //       ? true
    //       : false
    //     : false;
    console.log();
  }

  ngAfterContentInit() {
    let empty = this.verifiableList.filter((v) => {
      return v.originalValue === null || v.originalValue === '';
    });
    if (empty && empty.length > 0) {
      this.open(this.content);
      this.showList = false;
    } else {
      this.showList = true;
    }
  }

  Save(payerForm) {
    this.saveClicked = true;
    if (payerForm.valid) {
      this.closeModal(payerForm);
      if (this.addressFormIsValid) {
        if (this.isAddressInValid) {
          this.showList = true;
          this.editCompleted();
        } else {
          const address = {
            city: this.editPayment.city,
            state: this.editPayment.state,
            streetAddress: this.editPayment.streetAddress,
            zipCode: this.editPayment.zipCode,
          };
          this.SpinnerService.show();
          this.subscriptions.push(
            this.uspsService
              .isAddressValid(address)
              .subscribe((isValid: boolean) => {
                this.SpinnerService.hide();
                if (!isValid) {
                  this.isAddressInValid = true;
                } else {
                  this.showList = true;
                  this.editCompleted();
                }
              })
          );
        }
      }
    }
  }

  closeModal(payerForm) {
    if (this.isEmailRequired) {
      this.displayEmailNotification =
        payerForm.form.controls.email !== undefined
          ? payerForm.form.controls.email.value === null ||
            payerForm.form.controls.email.value === ''
          : false;
    }
  }

  resetDetails() {
    const answerTags = [];
    this.verifiableList.forEach((v) => {
      answerTags.push(v.answerTag);
    });
    this.applicationService.DeleteAnswerForAnswerTag(answerTags);
    this.ResetCanGoNext();
  }

  copyDetailsPopup() {
    let payment: Payment;
    this.applicationService.SelectedApplication.subscribe((a) => {
      payment = a?.payment;
    });
    Object.keys(this.editPayment).map((k) => {
      const verifiableListPropertyNames = JSON.parse(
        this.details.options
      ).filter((m) => m.key === k);
      if (verifiableListPropertyNames) {
        verifiableListPropertyNames.forEach((element) => {
          this.verifiableList.forEach((v) => {
            if (
              element.value === v.propertyName &&
              v.copyMapper !== undefined &&
              this.editPayment[k] !== payment[v.copyMapper]
            ) {
              this.editPayment[k] = payment[v.copyMapper.toString()].trim();
            }
          });
        });
      }
    });
  }

  copyDetails() {
    let payment: Payment;
    this.applicationService.SelectedApplication.subscribe((a) => {
      payment = a.payment;
    });
    this.verifiableList.forEach((v) => {
      if (v.copyMapper !== undefined && v.value !== payment[v.copyMapper]) {
        v.changedValue = payment[v.copyMapper.toString()].trim();
        v.verification = null;
      }
    });
    this.ResetCanGoNext();
  }

  editCompleted() {
    Object.keys(this.editPayment).map((k) => {
      const verifiableListPropertyNames = JSON.parse(
        this.details.options
      ).filter((m) => m.key === k);
      if (verifiableListPropertyNames) {
        verifiableListPropertyNames.forEach((element) => {
          this.verifiableList.forEach((v) => {
            if (element.value === v.propertyName) {
              if (
                v.originalValue === '' ||
                v.originalValue === null ||
                (v.originalValue &&
                  this.editPayment[k] &&
                  v.originalValue.trim() !== this.editPayment[k].trim())
              ) {
                v.changedValue = this.editPayment[k];
                v.verification = null;
              }
            }
          });
        });
      }
    });
    this.ResetCanGoNext();
    this.modalRef.close();
  }

  open(content) {
    Object.keys(this.editPayment).map((k) => {
      const verifiableListPropertyNames = JSON.parse(
        this.details.options
      ).filter((m) => m.key === k);
      if (verifiableListPropertyNames) {
        verifiableListPropertyNames.forEach((element) => {
          this.verifiableList.forEach((v) => {
            if (element.value === v.propertyName) {
              if (v.originalValue !== this.editPayment[k]) {
                this.editPayment[k] =
                  v.originalValue != null
                    ? v.originalValue.trim()
                    : v.originalValue;
              }
              if (v.changedValue !== null && v.changedValue !== undefined) {
                this.editPayment[k] =
                  v.changedValue != null
                    ? v.changedValue.trim()
                    : v.changedValue;
              }
            }
          });
        });
      }
    });

    // HACK : quick fix.
    this.editPayment.phone = this.editPayment.phone
      ? this.editPayment.phone.trim()
      : this.editPayment.phone;

    this.isAddressInValid = false;
    const options: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalRef = this.modalService.open(content, options);
  }
  OnformCompleted(result: Address) {
    this.editPayment.streetAddress = result.streetAddress;
    this.editPayment.city = result.city;
    this.editPayment.state = result.state;
    this.editPayment.zipCode = result.zipCode;
  }

  OnAddressFormValidity(valid: boolean) {
    this.addressFormIsValid = valid;
  }

  // REFACTOR : this needs to be refactored
  GetModel(): Payment {
    if (this.details == null) {
      return null;
    }

    if (this.details.PayorSelectorTag === 'Payment.ApplicationPayorMonthly') {
      if (this.application.payment.billingPhone === (null || undefined)) {
        this.application.payment.billingPhone = null;
      }
      if (
        this.application.payment.businessNameMonthlyOnline ===
        (null || undefined)
      ) {
        this.application.payment.businessNameMonthlyOnline = null;
      }
      if (
        this.application.payment.businessNameMonthlyOffline ===
        (null || undefined)
      ) {
        this.application.payment.businessNameMonthlyOffline = null;
      }
      if (
        this.application.payment.businessOwnerMonthlyOnline ===
        (null || undefined)
      ) {
        this.application.payment.businessOwnerMonthlyOnline = null;
      }
      if (
        this.application.payment.businessOwnerMonthlyOffline ===
        (null || undefined)
      ) {
        this.application.payment.businessOwnerMonthlyOffline = null;
      }
      if (
        this.application.payment.authorizedNameMonthly === (null || undefined)
      ) {
        this.application.payment.authorizedNameMonthly = null;
      }
      if (
        this.application.payment.authorizedPhoneMonthly === (null || undefined)
      ) {
        this.application.payment.authorizedPhoneMonthly = null;
      }

      if (
        this.application.payment.notesMonthlyOffline === (null || undefined)
      ) {
        this.application.payment.notesMonthlyOffline = null;
      }
      if (
        this.application.payment.emailMonthlyOffline === (null || undefined)
      ) {
        this.application.payment.emailMonthlyOffline = null;
      }
      if (this.application.payment.emailMonthlyOnline === (null || undefined))
        {
        this.application.payment.emailMonthlyOnline = null;
      }
    } else if (
      this.details.PayorSelectorTag === 'Payment.ApplicationPayorInitial'
    ) {
      if (
        this.application.payment.iniPmtCCBillingPhone === (null || undefined)
      ) {
        this.application.payment.iniPmtCCBillingPhone = null;
      }
      if (
        this.application.payment.businessNameInitialOnline ===
        (null || undefined)
      ) {
        this.application.payment.businessNameInitialOnline = null;
      }
      if (
        this.application.payment.businessNameInitialOffline ===
        (null || undefined)
      ) {
        this.application.payment.businessNameInitialOffline = null;
      }
      if (
        this.application.payment.businessOwnerInitialOnline ===
        (null || undefined)
      ) {
        this.application.payment.businessOwnerInitialOnline = null;
      }
      if (
        this.application.payment.businessOwnerInitialOffline ===
        (null || undefined)
      ) {
        this.application.payment.businessOwnerInitialOffline = null;
      }
      if (
        this.application.payment.authorizedNameInitial === (null || undefined)
      ) {
        this.application.payment.authorizedNameInitial = null;
      }
      if (
        this.application.payment.authorizedPhoneInitial === (null || undefined)
      ) {
        this.application.payment.authorizedPhoneInitial = null;
      }

      if (
        this.application.payment.notesInitialOffline === (null || undefined)
      ) {
        this.application.payment.notesInitialOffline = null;
      }
      if (this.application.payment.emailInitialOnline === (null || undefined)) {
        this.application.payment.emailInitialOnline = null;
      }
      if (
        this.application.payment.emailInitialOffline === (null || undefined)
      ) {
        this.application.payment.emailInitialOffline = null;
      }
    }
    return this.application.payment;
  }

  GetBaseAnswerTag = () => {
    return 'payment';
  };
  GetText(): string {
    return this.details[this.payorType];
  }

  GetPayorType() {
    const methodOfPayment = this.application.payment.methodOfPayment;

    const item = this.applicationService.GetAnswerForAnswerTag(
      this.details.PayorSelectorTag
    );

    // For select the genericMode question text
    if (this.applicationService.isQuestionGenericMode()) {
      if (this.question.details['textExpressPaymentMode'] !== undefined) {
        this.payorType = 'textExpressPaymentMode';
      }
    } else {
      if (item) {
        this.payorType = item.value;
      }
    }

    if (
      this.question.answerTag === 'Payment.PayorInformation' &&
      this.application.payment.methodOfPayment === PaymentType.DIRECTBILLING_KEY
    ) {
      this.methodofPaymentType = PaymentType.DIRECTBILLING_VALUE;
    } else if (
      this.question.answerTag === 'Payment.PayorInformation' &&
      this.application.payment.methodOfPayment === PaymentType.BANKDRAFT_KEY
    ) {
      this.methodofPaymentType = PaymentType.BANKDRAFT_VALUE;
    } else if (
      this.question.answerTag === 'Payment.InitialPayorInformation' &&
      this.application.payment.initialPaymentBy === PaymentType.CREDITCARD_KEY
    ) {
      this.methodofPaymentType = PaymentType.CREDITCARD_VALUE;
    } else if (
      this.question.answerTag === 'Payment.InitialPayorInformation' &&
      this.application.payment.initialPaymentBy === PaymentType.BANKDRAFT_KEY
    ) {
      this.methodofPaymentType = PaymentType.BANKDRAFT_VALUE;
    }
  }
  GetSubGroup() {
    if (this.question && this.details.PayorSelectorTag) {
      this.GetPayorType();
      return (
        this.details.PayorSelectorTag.split('.')[1] +
        this.methodofPaymentType +
        ',' +
        this.details.PayorSelectorTag.split('.')[1] +
        this.payorType
      );
    }
  }

  ApplicationModified() {}

  QuestionModified() {}

  CanProceedNext() {
    return true;
  }

  ResetView(): void {}

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }

  validateAccountNumber(event: any) {
    const regex = new RegExp(
      '^(?!(\\d)\\1+$)(?!0123456789$)(?!1234567890$)(?!123456789$)(?!123456$)(?!1234567$)(?!12345678$)\\d{6,13}$'
    );
    if (regex.test(event.target.value)) {
      this.isPatternValid = true;
    } else {
      this.isPatternValid = false;
    }
  }
}

export class BillingInformation extends Address {
  constructor(options) {
    super(options);
  }
  phone: string;
  name: string;
  billingOfflinePhone: string;
  businessName: string;
  businessOwner: string;
  notes: string;
  authorizedName: string;
  authorizedPhone: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  email: string;
}
export enum PaymentType {
  DIRECTBILLING_KEY = 'DIRECT BILLING',
  BANKDRAFT_KEY = 'BANK DRAFT',
  CREDITCARD_KEY = 'CREDIT CARD',
  DIRECTBILLING_VALUE = 'DirectBill',
  BANKDRAFT_VALUE = 'BankDraft',
  CREDITCARD_VALUE = 'CreditCard',
}
