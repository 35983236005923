import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  Appointment,
  AppointmentSearchCriteria,
} from 'src/app/shared/models/appointment';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import {
  AppointmentService,
  StartVerificationParameters,
} from 'src/app/shared/services/appointment/appointment.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  VcallParams,
  ImpersonateParams,
} from 'src/app/shared/models/vcall/vcallParams';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import {
  CallZoneUser,
  NgbDateModel,
} from 'src/app/shared/models/callzone-user';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { AppointmentAnswerService } from 'src/app/shared/services/appointmentAnswer/appointmentAnswer.service';

@Component({
  selector: 'app-appointments-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class ListComponent implements OnInit, OnDestroy {
  appointments: BehaviorSubject<Appointment[]>;
  selected: string;
  datepicker: NgbDateModel;
  counter = 0;
  selectedAppointment: Appointment = null;
  datesChanged = true;
  resourceId: string = null; // = '5212';
  from: string = null; // = '2020-07-14';
  fromDate: string = null;
  to: string = null; // = '';
  appId: string; // = '';
  subscriptions: Array<Subscription> = new Array<Subscription>();
  callZoneUser: CallZoneUser;
  stopIntervel;
  timeZone: string;
  canAccessFakeAppointment = null;
  day = `Today's`;
  listOfAppointments: Array<Appointment>;
  // alreadyExecuted: boolean = false;

  constructor(
    private service: AppointmentService,
    private application: ApplicationService,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private userService: UserProfileService,
    private toastService: ToastService,
    private uiService: UiServiceService,
    private profileService: UserProfileService,
    private router: Router,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal
  ) {
    this.getCallZoneUser();
    this.getQueryParams();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((a) => a.unsubscribe());
    clearInterval(this.stopIntervel);
  }

  ngOnInit() {
    // this.from = '2017-07-17' ;// this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.appointments = this.service.Appointments;
    let isAppointmentStillAvailable;
    // select the first one by default
    this.subscriptions.push(
      this.appointments.subscribe((a) => {
        this.listOfAppointments = a;
        if (a && a.length > 0) {
          if (this.selectedAppointment === null) {
            this.selectAppointment(a[0]);
          } else {
            isAppointmentStillAvailable = a.some(
              (appt) =>
                appt.appointmentId === this.selectedAppointment.appointmentId
            )
              ? true
              : false;
            if (
              isAppointmentStillAvailable ||
              this.canAccessFakeAppointment === 'true'
            ) {
              const selected = a.find(
                (appt) =>
                  appt.appointmentId === this.selectedAppointment.appointmentId
              );
              if (selected) {
                this.selectAppointment(selected);
              }
            } else {
              if (!this.datesChanged && !isAppointmentStillAvailable) {
                this.showModal();
              }
              this.selectAppointment(a[0]);
            }
          }
        } else {
          if (this.selectedAppointment !== null && a && a?.length === 0) {
            this.showModal();
          }
          this.application.SelectedApplication.next(null);
        }
      })
    );
    this.service.ReloadAppointment.subscribe((res: boolean) => {
      if (res === true) {
        this.loadAppointments();
        this.datesChanged = false;
      }
    });
    this.autoRefreshAppointments();
  }

  // Display re-assign modal
  showModal() {
    this.toastService.info(
      '',
      'The selected appointment is no longer assigned to you.'
    );
    this.uiService.ModalMessage(
      'The selected appointment is no longer assigned to you.',
      'Appointment Re-assigned',
      2
    );
  }

  // call zone user information
  getCallZoneUser() {
    this.subscriptions.push(
      this.userService.callZoneUser.subscribe((u) => {
        if (u === undefined || u === null || u.hasOwnProperty('status')) {
          return;
        }
        this.callZoneUser = u;
        this.loadAppointments();
      })
    );
  }

  // Query params information
  getQueryParams() {
    this.route.queryParamMap.subscribe((p) => {
      const impersonateParams = new ImpersonateParams(p);

      if (impersonateParams.start) {
        this.from = impersonateParams.start;
        const date = new Date(this.from);
        this.datepicker = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
      }
      if (impersonateParams.end) {
        this.to = impersonateParams.end;
      }
      if (impersonateParams.resource) {
        this.resourceId = impersonateParams.resource;
      }
      if (impersonateParams.appid) {
        this.appId = impersonateParams.appid;
      }
      if (impersonateParams.canAccessFakeAppointment) {
        this.canAccessFakeAppointment =
          impersonateParams.canAccessFakeAppointment;
      }

      this.loadAppointments();
      this.getProfileDetails();
    });
  }

  getProfileDetails() {
    this.timeZone = this.profileService.getCallzoneUser()?.timeZone;
  }
  /**
   *
   * @param condition increment and decrement the Current Date by Day
   */
  getAppointmentsByDate(condition = null) {
    if (!this.uiService.IsAValidCallzoneUser()) {
      this.uiService.HandleInValidCallzoneUser();
      return;
    }
    this.service.VerificationStarted.next(
      new StartVerificationParameters(null, null, true)
    );
    // if condition is null setting counter to 0 which makes from and todate current Date
    this.counter = condition
      ? condition === 'increment'
        ? 1
        : -1
      : (this.counter = 0);
    const date = this.from && this.counter ? new Date(this.from) : new Date();
    date.setDate(date.getDate() + this.counter);
    this.setDatesToParam(date);
    this.loadAppointments();
  }
  setDatesToParam(date) {
    this.from = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.to = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.datepicker = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    this.router.navigateByUrl(
      `appointments?resource=${this.resourceId}&start=${this.from}&end=${this.to}`
    );
    this.datesChanged = true;
    this.selectedAppointment = null;
  }
  // Auto refresh appointments list
  autoRefreshAppointments() {
    this.stopIntervel = setInterval(() => {
      this.loadAppointments();
      this.datesChanged = false;
    }, 30000);
  }

  loadAppointments() {
    if (
      this.resourceId === null &&
      this.callZoneUser &&
      this.callZoneUser.resourceId
    ) {
      this.resourceId = this.callZoneUser.resourceId.toString();
    }

    const criteria = new AppointmentSearchCriteria({
      resourceId: this.resourceId,
      canAccessFakeAppointment: this.canAccessFakeAppointment,
    });

    if (this.from !== null) {
      criteria.startDate = this.from; // this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    }

    if (this.to !== null) {
      criteria.endDate = this.to; // this.datepipe.transform(date, 'yyyy-MM-dd');
    }

    if (this.resourceId === null) {
      return;
    }
    this.day = this.application.SetDayFromDate(this.from || new Date());
    this.service.LoadAppointments(criteria);
  }

  selectAppointment(appt: Appointment) {
    this.selected = appt.appId.toString();
    if (appt.callType === 'Recall') {
      this.application.LoadRecall(this.selected);
    }
    this.selectedAppointment = appt;
    if (this.appId != null && this.appId !== '') {
      appt.appId = Number(this.appId);
      this.service.SelectAppointment(appt);
      // from impersonate
      console.log('app id not null');
      this.application.SelectApplication(this.appId);
    } else {
      this.service.SelectAppointment(appt);
      // DIRECT, not from impersonate
      this.application.SelectApplication(this.selected);
    }
  }
  saveSelectedDate(ngbDate) {
    const dateSelected = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    this.setDatesToParam(dateSelected);
    this.loadAppointments();
  }

  /**
   * this is not the best user experience but implemented to avoid breaking things and clumpsiness
   */
  closeOpenedModals() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
      this.service.Clear();
    }
  }
}
