import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { Observable, Subscription } from 'rxjs';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Application } from 'src/app/shared/models/application/application';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { Appointment, ScheduledAppointment } from 'src/app/shared/models/appointment';
import { Router } from '@angular/router';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { RescheduleAppointmentComponent } from 'src/app/appointments/reschedule-appointment/reschedule-appointment.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../../models/user-profile';
import { CallZoneApiService } from '../../services/api/callzone/callzone-api.service';

@Component({
  selector: 'app-end-call-modal',
  templateUrl: './end-call-modal.component.html',
  styleUrls: ['./end-call-modal.component.scss'],
})
export class EndCallModalComponent implements OnInit, OnDestroy {
  stopCallReasonForReschedule =
    environment.config.callZoneStatusConfiguration.stopCallReasonForReschedule
      .stopCallLabel;
  stopCallForm: UntypedFormGroup;
  openModal = false;
  reasons: any[] = [];
  appointment: Appointment;
  @Input() menu: any;
  isRescheduleEnabled = false;
  timeZone: string;
  profile: UserProfile;

  constructor(
    private applicationService: ApplicationService,
    private appointmentService: AppointmentService,
    private userProfileService: UserProfileService,
    private scriptService: ScriptService,
    private callzoneApiService: CallZoneApiService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.stopCallForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      reason: new UntypedFormControl('', Validators.required),
      notes: new UntypedFormControl('', Validators.required),
    });
  }
  today: string = new Date().toLocaleDateString();
  subscriptions: Subscription[] = [];
  application: Application;
  question: Question = null;
  menuVisible = false;
  ngOnInit() {
    this.subscriptions.push(
      this.scriptService.SelectedQuestion.subscribe((q) => {
        this.question = q;
      })
    );

    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a !== null) {
          this.application = a;
        }
      })
    );
    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe((p) => {
        if (p !== null) {
          this.appointment = p;
        }
      })
    );
    this.subscriptions.push(
      this.userProfileService.userProfile.subscribe(
        (res) => (this.profile = res)
      )
    );
    this.appointmentService.GetStopCallReasons().subscribe((reasons: any) => {
      if (reasons) {
        this.reasons = reasons;
      }
    });

    if (this.menu === 'dropdownMenuButton-2') {
      this.menuVisible = true;
    } else {
      this.menuVisible = false;
    }
    this.appointmentService.StopCallPopup.subscribe((s) => {
      if (s) {
        this.openReasonModal(null);
      }
    });
  }

  /**
   * @param e element
   * @description open the reason modal
   */
  openReasonModal(e: any, isRescheduleEnabled = false) {
    this.openModal = true;
    if (e !== null) {
      this.isRescheduleEnabled =
        e.stopCallLabel === this.stopCallReasonForReschedule
          ? true
          : isRescheduleEnabled;
      this.stopCallForm.patchValue({
        reason: e.stopCallLabel,
        id: e.stopCallId,
      });
    }
    if (this.isRescheduleEnabled) {
      this.appointmentService.logRescheduleAppointment(
        this.application,
        this.appointment,
        this.profile
      );
    }
  }

  /**
   * @param action form actions
   * @description submit the end call verification(complete or not)
   */
  async submiStopCall(action: any) {
    let self = this;
    if (action === 'Done') {
      
      await this.callzoneApiService
                .GetScheduledAppointment(this.appointment.appointmentId)
                .toPromise()
                .then(x => {
                  // This is using the Standard Name of Timezone. Some Callzone timezone does not have abbreviation.
                  self.timeZone = x.timeZone                      
                });
   
      if (!this.menuVisible) {
        this.reasons.forEach((e: any) => {
          if (e.stopCallLabel === this.stopCallForm.get('reason').value) {
            this.stopCallForm.patchValue({
              id: e.stopCallId,
            });
          }
        });
      }
      this.userProfileService.userProfile
        .pipe(first())
        .subscribe((userProfile) => {
          if (this.stopCallForm.valid) {
            const stopCall = {
              id: this.stopCallForm.get('id').value,
              notes: this.stopCallForm.get('notes').value,
              reason: this.stopCallForm.get('reason').value,
            };
            this.appointmentService
              .StopCall(
                stopCall,
                this.question,
                this.application,
                this.appointment,
                userProfile
              )
              .then(() => {
                // trigger reschedule popup here
                if (this.isRescheduleEnabled) {
                  const modelRef = this.modalService.open(
                    RescheduleAppointmentComponent,
                    {
                      size: 'xl',
                    }
                  );
                  modelRef.componentInstance.application = this.application;
                  modelRef.componentInstance.selectedAppointment = this.appointment;
                  modelRef.componentInstance.timeZone = this.timeZone;
                }
              });
            this.stopCallForm.reset();
            this.openModal = false;
            if (!this.isRescheduleEnabled) {
              this.router.navigate(['/'], {
                queryParams: { refresh: new Date().getTime() },
                queryParamsHandling: 'merge',
              });
              this.applicationService.Clear();
              this.appointmentService.Clear();
              this.scriptService.Clear();
            }
          } else {
            this.stopCallForm.reset();
            this.openModal = false;
          }
        });
    } else {
      this.stopCallForm.reset();
      this.openModal = false;
    }
  }

  /**
   * @description destroy the all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
