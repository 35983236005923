<div class="container">
  <ng-container *ngFor="let item of doctorsList; index as i">
    <div class="doctor-card container-item py-3 px-3 pointer">
      <div class="text-primary pt-2">
        {{ item.fullname }}
      </div>
      <div class="text-primary pt-2">
        {{ item.additionalInfo.corporateOwnerLastName }}
      </div>
      <div class="row">
        <div class="col d-flex justify-content-end">
          <i
            class="fa fa-edit text-primary mr-1"
            (click)="editDoctor(item)"
          ></i>
          <i
            class="far fa-trash-alt text-danger pl-3"
            (click)="deleteDoctor(item)"
          ></i>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<form #basicForm="ngForm" (ngSubmit)="submitForm(basicForm)">
  <div class="col-12 mt-2 d-flex p-0 ml-1">
    <div class="form-group col">
      <label for="fullname">Doctor's Name</label>
      <input
        type="text"
        class="form-control"
        id="fullname"
        name="fullname"
        pattern="[A-Za-z ]+"
        required
        [(ngModel)]="basicDoctorForm.fullname"
        #doctorName="ngModel"
        (change)="titleCase('fullname', doctorName.model)"
      />
      <div
        class="mt-1 text-danger small"
        *ngIf="doctorName.invalid && (doctorName.dirty || doctorName.touched)"
      >
        Please enter text only.
      </div>
    </div>
    <div class="form-group col">
      <label for="corporateOwnerLastName">Facility Name</label>
      <input
        type="text"
        class="form-control"
        id="corporateOwnerLastName"
        name="corporateOwnerLastName"
        pattern="[A-Za-z ]+"
        required
        [(ngModel)]="basicDoctorForm.corporateOwnerLastName"
        #facilityName="ngModel"
        (change)="titleCase('corporateOwnerLastName', facilityName.model)"
      />
      <div
        class="mt-1 text-danger small"
        *ngIf="
          facilityName.invalid && (facilityName.dirty || facilityName.touched)
        "
      >
        Please enter text only.
      </div>
    </div>
  </div>
  <div class="col text-right">
    <input
      type="button"
      class="btn btn-secondary"
      value="Clear"
      (click)="resetForm(basicForm)"
    />
    <input
      type="submit"
      class="btn btn-primary ml-2"
      [disabled]="basicForm.invalid"
      [value]="isDoctorUpdated ? 'Update' : 'Add'"
    />
  </div>
</form>

<app-modal
  [open]="openDeleteModal"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event)"
>
  <div title>
    Confirmation
  </div>
  <div body>
    <div class="pl-2">
      Are you sure of deleting this doctor Detail?
    </div>
  </div>
</app-modal>
