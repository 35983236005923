<div *ngIf="configurator" class="row">
  <div class="col-12" *ngIf="editOccupation">
    <label class="font-weight-bold">{{ configurator.label1 }}</label
    ><label class="text-danger">*</label>
    <div class="input-group">
      <select
        class="form-control"
        name="{{ configurator.label1 }}"
        (ngModelChange)="selectItem($event)"
        autocomplete="disabled"
        [(ngModel)]="item1"
      >
        <option [ngValue]="null">Select {{ configurator.label1 }}</option>
        <option *ngFor="let f of firstList" [ngValue]="f">
          {{ f.Key }} - {{ f.Value }}</option
        >
      </select>
    </div>
  </div>
  <div class="col-12 mt-3">
    <label class="font-weight-bold">{{ configurator.label2 }}</label
    ><label class="text-danger">*</label>
    <div class="input-group">
      <select
        class="form-control"
        name="{{ configurator.label2 }}"
        autocomplete="disabled"
        [disabled]="secondList.length === 0 && editOccupation"
        (ngModelChange)="secondListSelected($event)"
        [(ngModel)]="item2"
      >
        <option [ngValue]="null">Select {{ configurator.label2 }}</option>
        <option *ngFor="let s of secondList" [ngValue]="s"
          >{{ s.Key }} - {{ s.Value }}</option
        >
      </select>
    </div>
    <!-- <div *ngIf="secondList.length == 0" class="text-danger mt-2 small">
      Select {{ configurator.label1 }} to enable {{ configurator.label2 }}
    </div> -->
  </div>
</div>
