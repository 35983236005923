import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { ApplicationService } from '../../application/application.service';
import { Observable, Subscription } from 'rxjs';
import { VcallParams } from 'src/app/shared/models/vcall/vcallParams';
import { ScriptService, VcallMode } from '../../script/script.service';
import { AppointmentService } from '../../appointment/appointment.service';

@Injectable({
  providedIn: 'root',
})
export class VcallResolverService implements Resolve<any> {
  browserRefresh = false;
  subscriptions: Subscription[] = [];
  constructor(
    private applicationService: ApplicationService,
    private service: ScriptService,
    private appointmentService: AppointmentService
  ) {}

  /**
   * @param route route
   * @param state state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const vCall = new VcallParams(route.paramMap);
    this.applicationService.SelectApplication(vCall.applicationId);
    // console.log('resolver', vCall);
    // console.log('resolver', route.url);
    // this.service.RouteChanged(vCall);
    this.appointmentService.RouteChanged(vCall);
    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe((a) => {
        if (
          a &&
          a.appointmentId &&
          a.appointmentId.toString() === vCall.appointmentId
        ) {
          const mode =
            a.callType === 'Recall' ? VcallMode.Recall : VcallMode.Standard;
          vCall.mode = mode;
          // console.log('resolver', vCall);
          this.service.LoadRoute(vCall);
        }
      })
    );

    let resolve;
    const promise = new Promise((r) => {
      resolve = r;
    });

    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a && a.id !== 0) {
          resolve(true);
        }
      })
    );

    this.appointmentService.AddSubscriptions(this.subscriptions);
    return promise;
  }
  // ngOnDestroy(){
  //   this.subscriptions.map((subscribe) => subscribe.unsubscribe());
  // }
}
