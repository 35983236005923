import { Injectable } from '@angular/core';
import { AppointmentProgress } from '../../models/appointmentProgress';
import { AppointmentService } from '../appointment/appointment.service';
import {
  ApplicationService,
  ApiAnswer,
} from '../application/application.service';
import { Question } from '../../models/vcall/question';
import { Router } from '@angular/router';
import { UserProfileService } from '../user/user-profile.service.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentAnswerService {
  constructor(
    private appointmentService: AppointmentService,
    private appService: ApplicationService,
    private router: Router,
    private userProfileServ: UserProfileService
  ) {}
  readonly appointmentProgress = 'AppointmentProgress';


  /**
   * @param status status as a string
   * @description used to update appointment status
   * @returns true
   */
  startAppointmentQuestion(status: string) {
    const progressObject: AppointmentProgress = {};

    progressObject.section = null;
    progressObject.question = null;
    progressObject.prompt = null;
    progressObject.date = new Date();
    progressObject.url = this.router.url;
    progressObject.status = status;
    progressObject.callType =
      this.appointmentService.selectedAppointment.callType;

    this.appService.RegisterAnswerByTag(
      true,
      progressObject,
      'AppointmentProgress',
      null,
      null,
      'Appointment Progress for the vcall with appointmentId = ' +
        this.appointmentService.selectedAppointment.appointmentId,
      null,
      this.appointmentService.selectedAppointment.appointmentId
    );

    return true;
  }

  /**
   * @param question current question
   * @param status status as a string
   * @description used to update appointment status
   * @returns true
   */
  updateAppointmentStatus(question: Question, status: string) {
    const progressObject: AppointmentProgress = {};

    progressObject.section = question.section.name;
    progressObject.question = question.text;
    progressObject.prompt = question.promptText;
    progressObject.date = new Date();
    progressObject.url = this.router.url;
    progressObject.status = status;
    progressObject.callType =
      this.appointmentService.selectedAppointment.callType;

    this.appService.RegisterAnswerByTag(
      true,
      progressObject,
      'AppointmentProgress',
      null,
      null,
      'Appointment Progress for the vcall with appointmentId = ' +
        this.appointmentService.selectedAppointment.appointmentId,
      null,
      this.appointmentService.selectedAppointment.appointmentId
    );

    return true;
  }

  // For set appontment status
  setAppointmentStatusToComplete(question: Question, appointment) {
    appointment['completedDate'] = new Date().toLocaleString();
    appointment.callzoneVerifier = this.userProfileServ.getCallzoneUser().name;
    this.appService.AddAppointment(appointment);
    this.updateAppointmentStatus(question, 'complete');
  }

  getAppointmentStatus() {
    const answer = this.appService.GetAnswerForAnswerTags(
      this.appointmentProgress,
      null,
      this.appointmentService.selectedAppointment.appointmentId
    );
    if (answer) {
      return answer.value.status;
    }
  }
}
