<div class="hdr-cnt bg-light">
  <div class="row pt-1 d-flex justify-content-between">
    <div class="pl-4 menu-size d-flex pt-2 dropdown position-relative">
      <div class="cursor">
        <ng-container [ngSwitch]="logo">
          <div *ngSwitchCase="'UW'">
            <h4>
              <img
                src="./assets/images/ushg/UWS_Logo.svg"
                alt="image"
                width="100"
                height="40"
              />
            </h4>
          </div>
          <div *ngSwitchDefault>
            <h4 (click)="navToModule('', 'home')">
              <img
                src="./assets/images/ushg/vcall-logo.png"
                alt="image"
                width="100"
                height="40"
              />
            </h4>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-5 d-flex justify-content-end align-items-center">
      <div class="col-xs-5 pt-2 text-truncate text-right font-weight-light">
        <b title="{{ today }}">{{ today }}</b>
      </div>
      <div class="col-1 pt-2">
        <div class="circle-log rounded-circle bg-danger m-auto">
          <ng-template [ngIf]="profile">
            <span title="{{ profile | json }}">{{ profile.initial }}</span>
          </ng-template>
        </div>
      </div>
      <div class="text-truncate pl-4 pt-2">
        <ng-template [ngIf]="profile">
          <b title="{{ profile.name }}">{{ profile.name }}</b>
        </ng-template>
      </div>
      <div class="mx-3 pt-2" [appPermission]="'CanAccessToITHelpCenter'">
        <a href="https://helpdesk.uhg.com/hc" target="_blank"
          ><img src="assets/images/ushg/Help_Center.svg"
        /></a>
      </div>
      <div class="col-1 standard-hdr-text">
        <a
          href="userguide"
          target="_blank"
          data-toggle="tooltip"
          data-placement="left"
          title="User Guide"
        >
          <img
            src="./assets/images/ushg/Training_Video.svg"
            alt="image"
            width="60"
            height="30"
          />
        </a>
      </div>
    </div>
  </div>
</div>

<ng-container [ngSwitch]="logo">
  <div *ngSwitchCase="'UW'"></div>
  <ng-container *ngSwitchDefault>
    <div class="nav-header">
      <div class="cursor">
        <i
          class="fa fa-home f-17 mr-2"
          (click)="navToModule('', 'home')"
          [ngClass]="{ activeColor: tab === 'home' }"
        ></i>
        <span class="vertical-rule"></span>
      </div>
      <div
        [appPermission]="'CanAccessApplicationSearchCallZoneData'"
        (click)="navToModule('admin/search', 'applicationSearch')"
        class="cursor font-weight-bold mr-5"
        [ngClass]="{ activeColor: tab === 'applicationSearch' }"
      >
        Application Search
      </div>
      <div
        [appPermission]="'CanAccessAppointmentSearchCallZoneData'"
        [ngClass]="{ activeColor: tab === 'appointmentSearch' }"
        (click)="navToModule('appointments/search', 'appointmentSearch')"
        class="cursor font-weight-bold"
      >
        Appointments Search
      </div>
    </div>
  </ng-container>
</ng-container>
