import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  gendersArray = [
    {
      shortname: 'M',
      gender: 'Male',
    },
    {
      shortname: 'F',
      gender: 'Female',
    },
  ];
  /**
   *
   * @param value value as a string
   * @description for check the gender
   * @returns returns gender
   */

  transform(value: string): any {
    const index = this.gendersArray.findIndex(
      (x: any) => x.shortname === value.toUpperCase()
    );
    return index > -1 && this.gendersArray[index].gender
      ? this.gendersArray[index].gender
      : value.toUpperCase();
  }
}
