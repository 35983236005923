<div class="container">
  <div class="row mt-4">
    <div class="col-8">
      <div *ngIf="ezappValue" class="row mt-4">
        <div class="row">
          <div class="d-flex" (click)="canEditOccupation()" *ngIf="!isEdit">
            <div class="mr-2 ml-4"><i class="fa fa-edit"></i></div>
            <div>{{ ezappValue.key }} - {{ ezappValue.value }}</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <app-cascaded-dropdown
          [configurator$]="configurator$"
          (Save)="CascadeResult($event)"
          [editOccupation]="isEdit"
        >
        </app-cascaded-dropdown>
      </div>
    </div>
    <div class="col-8">
      <div class="form-group">
        <label class="font-weight-bold"
          ><span>Duties/Details</span> <span class="text-danger">*</span></label
        >
        <textarea
          name="duties"
          id="duties"
          [(ngModel)]="duties"
          required
          class="form-control"
          rows="7"
          maxlength="160"
          autocomplete="off"
          (blur)="Save()"
          (keyup)="EnableNext()"
        ></textarea>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="ezappValue" class="row mt-4">
    <div class="col-8">
      <div class="alert alert-info" role="alert">
        {{ ezappValue.key }} - {{ ezappValue.value }}
      </div>
    </div>
  </div> -->
</div>
<div class="row justify-content-end">
  <div
    *ngIf="question.instructions"
    class="col-sm-12 alert alert-warning text-center mt-3"
    role="alert"
  >
    {{ question.instructions }}
  </div>
</div>
