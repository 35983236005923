import { Injectable } from '@angular/core';
import { LoggerService } from 'src/app/core/logger.service';
import { Contact } from 'src/app/shared/models/application/contact';
import { EFullfillmentService } from '../../e-fullfillment/e-fullfillment.service';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';

@Injectable({
  providedIn: 'root',
})
export class SendEfullmentEmailProcessor implements Processor {
  name = 'Sending Efullment Email';
  mustWaitForFinish: boolean;
  stopIfError: any;
  hasStopProcessing: boolean = false;

  constructor(private efulfillment: EFullfillmentService) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    const answer = model.answers.find(
      (a) => a.answerTag === 'contact.eFulfillment'
    );
    let sendEfulfillmentEmailApiResp;
    if (
      model.application.contact.eFulfillment === false &&
      answer &&
      answer.verification
    ) {
      model.logger.logEvent(
        `Sending efulfillment email to ${model.application.contact.email} `
      );
      sendEfulfillmentEmailApiResp =
        await this.efulfillment.SendEfulfillmentEmail(
          model.application.id,
          model.application.contact.email
        );
    }
    this.hasStopProcessing =
      sendEfulfillmentEmailApiResp === null ||
      sendEfulfillmentEmailApiResp === undefined
        ? false
        : true;

    return Promise.resolve({
      result: true,
      stopProcessing: this.hasStopProcessing,
    });
  }
}
