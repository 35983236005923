import { RecallStatus } from './RecallStatus';

export class RecallQuestion {
  createdBy: string;
  applicantName: string;
  status: RecallStatus;
  questionText: string;
  created?: string;
  lastUpdated?: string;
  recallReason: string;
  appointmentId: number;
  answerTag?: string;
  recallAnswer?: string;
  ignored?: boolean;
  prompt?: string;
  clientNo?: string;
  relationship?: string;
  // get applcantName with relationship
  public static GetNameKey(question: RecallQuestion) {
    return question.relationship !== undefined && question.relationship !== null
      ? question.applicantName + ' (' + question.relationship + ')'
      : question.applicantName;
  }
}
