import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmptyValuePipe } from './pipes/empty-value.pipe';
import { FullnamePipe } from './pipes/fullname.pipe';
import { OnlineApplicantsComponent } from './components/online-applicants/online-applicants.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UspsAddressComponent } from './components/usps-address/usps-address.component';
import { NavHeaderComponent } from './components/nav-header/nav-header.component';
import { RelationshipPipe } from './pipes/relationship.pipe';
import { CallBackDurationPipe } from './pipes/callbackduration.pipe';
import { AgePipe } from './pipes/age.pipe';
import { NgbDateCustomParserFormatter } from './services/bootstrap/date-formatter.service';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ModalComponent } from './components/modal/modal.component';
import { DatedisplayPipe } from './pipes/datedisplay.pipe';
import { DateobjPipe } from './pipes/dateobj.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { DoctorLookupComponent } from '../vcall/doctor-lookup/doc-lookup.component';
import { PermissionDirective } from './components/permission-directive/permission.directive';
import { ErrorsListComponent } from './components/errors-list/errors-list.component';
import { SmartDateComponent } from './components/smart-date/smart-date.component';
import { ApplicationInfoComponent } from './components/application-info/application-info.component';
import { EndCallModalComponent } from './components/end-call-modal/end-call-modal.component';
import { ProcessingService } from './services/processors/processing.service';
import { QuestionNotesComponent } from './components/question-notes/question-notes.component';
import { DatewithTimeZonePipe } from './pipes/datewith-time-zone.pipe';
import { PrescriptionLookupComponent } from '../vcall/prescription-lookup/prescription-lookup.component';
import { RecallAddquestionComponent } from '../recall/recall-addquestion/recall-addquestion.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { UserIdleTrackerComponent } from './components/user-idle-tracker/user-idle-tracker.component';
import { LoggedOutComponent } from './components/logged-out/logged-out.component';
import { SmsNotificationsComponent } from './components/sms-notifications/sms-notifications.component';
import { MapValuePipe } from './pipes/map-value.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { MaskPipe } from './pipes/mask.pipe';
import { MaskAccountNumberPipe } from './pipes/mask-account-number.pipe';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgSelectModule,
  ],
  exports: [
    AgePipe,
    CallBackDurationPipe,
    RelationshipPipe,
    EmptyValuePipe,
    FullnamePipe,
    MapValuePipe,
    OnlineApplicantsComponent,
    FormsModule,
    UspsAddressComponent,
    NavHeaderComponent,
    NgxMaskModule,
    ModalComponent,
    DatedisplayPipe,
    DateobjPipe,
    GenderPipe,
    DatewithTimeZonePipe,
    DoctorLookupComponent,
    PermissionDirective,
    ErrorsListComponent,
    SmartDateComponent,
    ApplicationInfoComponent,
    EndCallModalComponent,
    QuestionNotesComponent,
    PrescriptionLookupComponent,
    RecallAddquestionComponent,
    UserIdleTrackerComponent,
    LoggedOutComponent,
    SmsNotificationsComponent,
    SanitizeUrlPipe,
    MaskPipe,
    MaskAccountNumberPipe
  ],
  declarations: [
    AgePipe,
    CallBackDurationPipe,
    RelationshipPipe,
    EmptyValuePipe,
    FullnamePipe,
    OnlineApplicantsComponent,
    UspsAddressComponent,
    NavHeaderComponent,
    ModalComponent,
    DatedisplayPipe,
    DateobjPipe,
    GenderPipe,
    MapValuePipe,
    DatewithTimeZonePipe,
    DoctorLookupComponent,
    PermissionDirective,
    ErrorsListComponent,
    SmartDateComponent,
    ApplicationInfoComponent,
    EndCallModalComponent,
    QuestionNotesComponent,
    PrescriptionLookupComponent,
    RecallAddquestionComponent,
    UserIdleTrackerComponent,
    LoggedOutComponent,
    SmsNotificationsComponent,
    SanitizeUrlPipe,
    MaskPipe,
    MaskAccountNumberPipe
  ],
  providers: [
    NgbDateCustomParserFormatter,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class SharedModule {}
