<!-- <div id="backdrop"  style="position: fixed;width: 100%;height: 100%; z-index: 10000;
background: rgba(0,0,0,0.7);"></div> -->
<div class="results-cnt mx-4 pb-0 pt-3">
  <div class="verication-cnt carousel-steps">
    <div class="row flex-nowrap mb-5 mt-4 carousel-cnt-items">
      <div
        class="col d-flex"
        *ngFor="
          let section of sections;
          first as isFirst;
          let i = index;
          let last = last
        "
        [ngClass]="{
          'item-first': isFirst,
          'item-last': last,
          'item-active': section.status === 1,
          'item-complete': section.status === 0
        }"
        title="{{ section.name }}"
        (click)="viewPreviousQuestions(section)"
      >
        <div
          class="section-dropdown p-2"
          *ngIf="section.toggle && previousAnsweredQuestions[i].length"
        >
          <ng-container
            *ngFor="
              let question of previousAnsweredQuestions[i];
              let last = last
            "
          >
            <div class="row" (click)="routeToQuestion(question)">
              <div class="col-7">
                {{ question.name ? question.name : question.answerTag }}
              </div>
              <div class="align-center">
                <app-smart-answer
                  [type]="question.type"
                  [value]="question.value"
                ></app-smart-answer>
              </div>
            </div>
            <hr *ngIf="!last" class="my-1" />
          </ng-container>
        </div>

        <div class="row flex-nowrap align-items-center">
          <div
            [ngClass]="[
              section.questionsDone > 0
                ? 'progress-' + section.questionsDone
                : ''
            ]"
            class="progress-circle"
          >
            <i
              *ngIf="section.status === 0"
              class="fas fa-check check-complete"
              aria-hidden="true"
            ></i>
          </div>
          <div class="col d-none d-lg-flex">{{ section.name }}</div>
          <div *ngIf="!section.toggle"><i class="fa fa-caret-down"></i></div>
          <div *ngIf="section.toggle"><i class="fa fa-caret-up"></i></div>
          <div
            class="new-diagonal"
            [ngClass]="{
              'hide-diagonal': isFirst
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
