import { Injectable } from '@angular/core';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';
import { CopyAnswerTagProcessor } from './copyAnswerTagProcessor';

@Injectable({
  providedIn: 'root',
})
export abstract class CopyPaymentBankInformationProcessor
  extends CopyAnswerTagProcessor
  implements Processor
{
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'CopyBankInformation';
  questionTags = [
    { tagFrom: 'payment.bankName', tagTo: 'payment.initPmtBankName' },
    {
      tagFrom: 'payment.bankRoutingCode',
      tagTo: 'payment.initPmtBankRoutingCode',
    },
    {
      tagFrom: 'payment.bdAccountNumber',
      tagTo: 'payment.initPmtBDAccountNumber',
    },
    {
      tagFrom: 'payment.bdAccountNumberEncrypted',
      tagTo: 'payment.initPmtBDAccountNumberEncrypted',
    },
    {
      tagFrom: 'bdAccountNumberEncrypted',
      tagTo: 'initPmtBDAccountNumberEncrypted',
    },
  ];

  Process(model: ProcessorModel): Promise<ProcessingResult> {
    this.questionTags.every((tag) => {
      return this.copy(tag.tagFrom, tag.tagTo);
    });
    return;
  }
}
