import { Component, OnInit } from '@angular/core';
import { HandledHttpClient } from 'src/app/shared/services/api/handledHttpClient';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-business-release-notes',
  templateUrl: './business-release-notes.component.html',
  styleUrls: ['./business-release-notes.component.scss'],
})
export class BusinessReleaseNotesComponent implements OnInit {
  businessNotes: ReleaseNotesDocuments[] = [];
  selectedDocument;
  constructor(private http: HandledHttpClient) {}

  ngOnInit() {
    this.http
      .get(environment.businessReleaseNotesConfigUrl)
      .subscribe((resp: ReleaseNotesDocuments[]) => {
        if (resp) {
          this.businessNotes = resp;
          console.log(this.businessNotes);
          this.eachDocument(this.businessNotes[0]);
        }
      });
  }

  eachDocument(note) {
    this.selectedDocument = note;
  }
}

export class ReleaseNotesDocuments {
  url: string;
  type: string;
  title: string;
}
