import { BaseFilter } from './base-filter';
import { FilterParameter } from '../question-filter.service';

export class StudentFilter extends BaseFilter {
  Type = 'StudentFilter';
  parse(value1, comparator, value2) {
    switch (comparator) {
      case '>':
        return value1 > value2;
      case '<':
        return value1 < value2;
      case '===':
        return value1 === value2;
      case '!==':
        return value1 !== value2;
    }
  }
  Filter(parameter: FilterParameter) {
    let canDisplay = false;
    if (parameter.config.comparisonValue) {
      parameter.config.value = parameter.application[
        parameter.config.comparisonValue.split('.')[0]
      ][parameter.config.comparisonValue.split('.')[1]].toString();
    }
    if (parameter.application.dependents.length > 0) {
      if (parameter.config.params) {
        const comparisonOperator = parameter.config.params.filter((a) => {
          if (a.key === 'comparisonFilter') {
            return a.value;
          }
        });
        const parentProperty = parameter.config.path.split('.')[0];
        const childProperty = parameter.config.path.split('.')[1];
        // let canDisplay = false;
        if (
          !Array.isArray(parameter.application[parentProperty]) &&
          this.parse(
            parameter.application[parentProperty][childProperty],
            comparisonOperator[0],
            parameter.config.value
          )
        ) {
          canDisplay = true;
        } else if (Array.isArray(parameter.application[parentProperty])) {
          const value = parameter.application.dependents.filter((obj) =>
            this.parse(
              obj[childProperty],

              comparisonOperator[0].value,

              parameter.config.value
            )
          );
          if (value.length > 0) {
            canDisplay = true;
          }
        }
        return canDisplay;
      }
    } else {
      return (canDisplay = false);
    }
  }
}
