import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ParamMap,
  Router,
  NavigationEnd,
  NavigationStart,
} from '@angular/router';
import { VcallParams } from '../shared/models/vcall/vcallParams';
import { ScriptService } from '../shared/services/script/script.service';
import { ApplicationService } from '../shared/services/application/application.service';
import { AppointmentService } from '../shared/services/appointment/appointment.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-vcall',
  templateUrl: './vcall.component.html',
  styleUrls: ['./vcall.component.scss'],
})
export class VcallComponent implements OnInit {
  ngOnInit() {}
}
