<table class="table table-striped mt-3">
  <thead class="border bg-secondary bg-gradient">
    <tr>
      <th>#</th>
      <th>Created Date</th>
      <th>Type</th>
      <th>No of Applicants</th>
      <th>Applicant Name(s)</th>
      <th>Created By</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="applicationLogs.length">
      <tr *ngFor="let application of applicationLogs; index as i">
        <td>
          <div class="form-check">
            <input
              type="radio"
              id="logs"
              name="applicationFilter"
              class="form-check-input input-radio"
              [value]="i"
              [(ngModel)]="selectedIndex"
              (change)="onChangeApplication(i)"
            />
          </div>
        </td>
        <td>{{ application.createdOn | date: "MM/dd/yyyy" }}</td>
        <td>{{ application.type }}</td>
        <td class="pl-3">{{ pad(application.applicantCount) }}</td>
        <td>
          <div *ngFor="let val of application.applicantNames">
            {{ val }}
          </div>
        </td>
        <td>{{ application.createdBy }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div class="d-flex pt-2">
  <div class="col-5 p-0"><hr /></div>
  <div class="col-2 text-center font-weight-bold">
    AddOn Requests
  </div>
  <div class="col-5 p-0"><hr /></div>
</div>
<div *ngIf="applicationLogs?.length">
  <div class="row m-0 justify-content-end mt-2">
    <button
      type="button"
      class="btn btn-primary btn-sm ml-2 px-4"
      (click)="createRequest()"
      [disabled]="!canCreateOrder"
    >
      Create APS/Lab Request
    </button>
    <button
      type="button"
      class="btn btn-primary btn-sm ml-2 px-4"
      [disabled]="!canRouteTo"
      (click)="routeToAPS()"
    >
      Route to APS
    </button>
    <button
      type="button"
      class="btn btn-outline-primary btn-sm ml-2 px-4"
      (click)="addApplicant(applicationLogs[selectedIndex])"
      *ngIf="canDisplayAddAppplicant"
    >
      Add Applicant
    </button>
  </div>
  <!-- Added applicant details starts from here -->
  <div
    class="mt-2"
    *ngIf="getApplicantLogs(applicationLogs[selectedIndex])?.length > 0"
  >
    <ng-container
      *ngFor="
        let applicant of getApplicantLogs(applicationLogs[selectedIndex]);
        index as i
      "
    >
      <div class="row m-0 pt-2 mx-2 justify-content-between">
        <h5>{{ applicant.firstName }}&nbsp;&nbsp;{{ applicant.lastName }}</h5>
        <div *ngIf="canDisplayAddAppplicant">
          <i
            class="fa fa-edit edit-icon p-1 cursor-pointer text-center"
            (click)="editApplicant(applicant)"
          ></i>
          <i
            class="far fa-trash-alt text-danger p-1 pl-3 cursor-pointer text-center"
            (click)="deleteApplicant(applicant)"
          ></i>
        </div>
      </div>
      <div class="card custom-card-bg px-3 pt-3">
        <div class="row">
          <div class="col-4">
            <div class="d-flex">
              <label>First Name:</label>
              <b>{{ applicant.firstName }}</b>
            </div>
            <div class="d-flex">
              <label>Middle Name:</label>
              <b>{{ applicant.middleName }}</b>
            </div>
            <div class="d-flex">
              <label>Last Name:</label>
              <b>{{ applicant.lastName }}</b>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex">
              <label>Height:</label>
              <b>{{ applicant.height }}</b>
            </div>
            <div class="d-flex">
              <label>Weight:</label>
              <b>{{ applicant.weight }}</b>
            </div>
            <div class="d-flex">
              <label>Gender:</label>
              <b>{{ applicant.gender }}</b>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex">
              <label>DOB:</label>
              <b>{{ applicant.birthDate | date: "MM/dd/yyyy" }}</b>
            </div>
            <div class="d-flex">
              <label>SSN:</label>
              <b>{{ applicant.ssn }}</b>
            </div>
            <div class="d-flex">
              <label>Occupation/Duties:</label>
              <b>{{ applicant.occupation }}</b>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="d-flex col-4">
            <label>Street Address:</label>
            <b>{{ applicant.contact.streetAddress }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="d-flex">
              <label>City:</label>
              <b>{{ applicant.contact.city }}</b>
            </div>
            <div class="d-flex">
              <label>Home Phone:</label>
              <b>{{ applicant.contact.homePhone }}</b>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex">
              <label>State:</label>
              <b>{{ applicant.contact.state }}</b>
            </div>
            <div class="d-flex">
              <label>Mobile Phone:</label>
              <b>{{ applicant.contact.mobilePhone }}</b>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex">
              <label>ZIP Code:</label>
              <b>{{ applicant.contact.zipCode }}</b>
            </div>
            <div class="d-flex">
              <label>Work Phone:</label>
              <b>{{ applicant.contact.workPhone }}</b>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- Added applicant details ends here -->
</div>
<div class="mt-2" *ngIf="applicationTracker">
  <app-order-details
    [applicationTrackerId]="applicationTracker.id"
    [addonApsRequests]="true"
    (emitActiveOrdersList)="getActiveOrders($event)"
  ></app-order-details>
</div>

<!-- create order popup starts from here -->
<app-create-order
  [openCreateOrderModal$]="openCreateOrderModal"
  [applicants]="applicantsList"
  [applicationTrackerId]="applicationTracker?.id"
></app-create-order>
<!-- create order ends here -->

<app-modal
  [open]="openDeleteApplicantModal"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Confirm',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirm($event)"
>
  <div title>
    Confirmation
  </div>
  <div body>
    <div class="pl-2">
      Are you sure you want to delete this Applicant?
    </div>
  </div>
</app-modal>
