<div
  class="col-12 question"
  *ngIf="detailQuestions === null || (detailQuestions | json) === ({} | json)"
>
  <!-- <label>No Records</label> -->
</div>
<div [formGroup]="form" class="col-12 question" *ngIf="detailQuestions">
  <ng-container *ngIf="question">
    <ng-container *ngIf="isCustom === false">
      <div [ngSwitch]="question.type" class="form-group">
        <ng-container
          *ngTemplateOutlet="questionText; context: { data: question }"
        ></ng-container>
        <ng-container *ngSwitchCase="'TextBox'">
          <textarea
            [formControl]="form.controls[question.id]"
            [id]="question.id"
            type="text"
            class="form-control col-md-6 col-sm-12"
            autocomplete="off"
            rows="5"
          ></textarea>
        </ng-container>
        <ng-container *ngSwitchCase="'Boolean'">
          <br />
          <div class="text-right">
            <ng-container *ngFor="let opt of sortTheBooleanOptions(question)">
              <label
                class="mr-3"
                [ngClass]="{
                  'hover-danger': (opt.value | lowercase) === 'n',
                  'hover-success': (opt.value | lowercase) === 'y'
                }"
              >
                <div
                  class="btn border"
                  [ngClass]="{
                    'border-danger': (opt.value | lowercase) === 'n',
                    'border-success': (opt.value | lowercase) === 'y',
                    'btn-danger':
                      (opt.value | lowercase) ===
                        (form.controls[question.id].value | lowercase) &&
                      (opt.value | lowercase) === 'n',
                    'btn-success':
                      (opt.value | lowercase) ===
                        (form.controls[question.id].value | lowercase) &&
                      (opt.value | lowercase) === 'y'
                  }"
                >
                  <input
                    class="radio"
                    type="radio"
                    [formControl]="form.controls[question.id]"
                    [value]="opt.value"
                    [id]="opt.value"
                  />
                  <label class="pl-2 pr-4 m-0" [for]="opt.value">{{
                    opt.key | titlecase
                  }}</label>
                </div>
              </label>
            </ng-container>
          </div>
        </ng-container>

        <select
          *ngSwitchCase="'Dropdown'"
          [formControl]="form.controls[question.id]"
          [id]="question.id"
          class="form-control col-md-6 col-sm-12"
        >
          <option value="" selected disabled>Please Select</option>
          <option
            *ngFor="let opt of question.details.options"
            [value]="opt.value"
          >
            {{ opt.key }}
          </option>
        </select>

        <ng-container *ngSwitchCase="'Date'">
          <div class="input-group col-md-6 col-sm-12 p-0">
            <input
              #d="ngbDatepicker"
              class="form-control"
              [formControl]="form.controls[question.id]"
              ngbDatepicker
              (click)="d.toggle()"
              readonly
              [maxDate]="maxDate"
              [minDate]="minDate"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary fa fa-calendar"
                type="button"
                (click)="d.toggle()"
              ></button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <label
            >Configured control type <b>{{ question.type }}</b> not implemented
            yet (or) wrongly configured</label
          >
        </ng-container>
      </div>
      <div *ngIf="question.details.stopAtValue" class="alert alert-warning m-5">
        Note : No further question will be asked for answer
        <b>{{ question.details.stopAtValue }} </b>
      </div>
      <div
        *ngIf="question.details.instructions"
        class="alert alert-warning m-5"
      >
        {{ question.details.instructions }}
      </div>
    </ng-container>

    <!-- custom controls -->
    <ng-container *ngIf="isCustom === true">
      <ng-container
        *ngTemplateOutlet="questionText; context: { data: question }"
      ></ng-container>
      <div [ngSwitch]="question.type" class="form-group">
        <app-vcall-doc-lookup
          *ngSwitchCase="'Doctor'"
          [initialDoctorList]="question.value"
          [questionDetails]="question.details"
          [clientNumber]="AlimentData.clientNo"
          (notifyProviderLookup)="getUpdatedDrList($event)"
        >
        </app-vcall-doc-lookup>

        <app-prescription-lookup
          *ngSwitchCase="'Medication'"
          [medicationDrilldownList]="question.value"
          (notifyMedicationList)="getMedicationList($event)"
        >
        </app-prescription-lookup>

        <app-smart-date
          *ngSwitchCase="'SmartDate'"
          [answerCollection]="answerCollection"
          [question]="question"
          (notifyDate)="getUpdatedSmartDate($event)"
        >
        </app-smart-date>
      </div>
      <div *ngIf="question.details.stopAtValue" class="alert alert-warning">
        Note : No further question will be asked for answer<b
          >{{ question.details.stopAtValue }}
        </b>
      </div>
      <div
        *ngIf="question.details.instructions"
        class="alert alert-warning m-5"
      >
        {{ question.details.instructions }}
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="question === null">
    <div class="col-12 all-done">
      <h3>Questions are completed in this section</h3>
    </div>
    <div class="row justify-content-center">
      <button class="btn btn-primary" (click)="closeFromDynamic()">
        Close
      </button>
    </div>
  </ng-container>
</div>

<div *ngIf="detailQuestions" class="col-12 pt-3">
  <div class="mt-2 mb-2 text-right">
    <button
      class="btn float-left bg-white carousel-next btn-check-next p-0 m-0"
      data-html="true"
      data-toggle="popover"
      data-trigger="hover"
      title="<i class='fas fa-keyboard keyboard-btn' aria-hidden='true'></i> Hot Keys"
      data-content="
        <p> Use <span class='btn btn-outline-secondary border btn-sm'>
          <b>Y</b>
        </span>
          <span class='btn btn-outline-secondary border btn-sm'><b>N</b></span> Keys for <b>Yes/No</b> responses
        </p>
        <p> Use <span class='btn btn-outline-secondary border btn-sm'>
          <i class='fa fa-arrow-left' aria-hidden='true'></i>
        </span> 
        <span class='btn btn-outline-secondary border btn-sm'>
          <i class='fa fa-arrow-right' aria-hidden='true'></i>
        </span> Keys for navigating <b>Back/Next</b>
      </p>
      <p><b>Note :</b> Click outside the text field to use the keyboard shortcuts</p>
      "
    >
      <i
        class="fa fa-keyboard keyboard-btn border rounded"
        [ngClass]="{ red: ifInputFocus }"
        aria-hidden="true"
      ></i>
    </button>
    <ng-container *ngIf="ifInputFocus">
      <div class="mt-2 ml-2 float-left d-flex text-left red">
        <b>Note</b>: Click outside the text field to use the keyboard shortcuts.
      </div>
    </ng-container>
    <button
      [ngClass]="{
        'btn btn-secondary btn-md carousel-next mr-2': question?.sequence !== 1,
        'no-button': question === null || this.questionIndex === 0
      }"
      (click)="goBack()"
    >
      <i class="fa fa-caret-left" aria-hidden="true"></i> Back
    </button>

    <button
      *ngIf="question"
      #drillDownNext
      class="btn btn-primary carousel-next btn-md"
      [disabled]="isCustom === false ? form.invalid : customValidation()"
      (click)="goNext()"
    >
      Next <i class="fa fa-caret-right" aria-hidden="true"></i>
    </button>
    <app-end-call-modal></app-end-call-modal>
  </div>
</div>

<!-- reusable template within this component only -->
<ng-template #questionText let-question="data">
  <ng-container *ngIf="question">
    <label
      class="light-bold"
      [attr.for]="question.id"
      [innerHTML]="question.text"
    >
    </label>
  </ng-container>
</ng-template>

<app-modal
  [open]="openConfirmationModal"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: 'Proceed',
    cancelButtonName: 'Cancel'
  }"
  (actions)="confirmation($event)"
>
  <div title>Confirmation</div>
  <div body>
    <div class="pl-2">
      <label class="light-bold">
        Changing answer may effect child question's answers. Do you want to
        change anyway?
      </label>
    </div>
  </div>
</app-modal>
