/*Authentication*/
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppointmentsModule } from './appointments/appointments.module';
import { ApplicationStringsInit } from './APP_INITIALIZERS/application-strings.init';
import { CoreModule } from './core/core.module';
import { ErrorHandlerService } from './core/error-handler.service';
import { DeveloperModule } from './developer/developer.module';
import { AngularPluginService } from './external/applicationinsights-angularplugin-js/applicationinsights-angularplugin-js';
import { ImpersonationModule } from './impersonation/impersonation.module';
import { RecallModule } from './recall/recall.module';
import { VcallResolverService } from './shared/services/api/vcall/vcall-resolver.service';
import { MSALConfigurationServiceService } from './shared/services/msa-configuration-service.service';
import { SharedModule } from './shared/shared.module';
import { ToastComponent } from './shared/views/toast/toast.component';
import { UnderwritingModule } from './underwriting/underwriting.module';
import { UserGuideModule } from './user-guide/user-guide.module';
import { VcallModule } from './vcall/vcall.module';
import { BrowserCompatibilityCheckComponent } from './vcall/browser-compatability-check/browser-compatability-check.component';

export function initializeApplicationStrings(
  applicationStringsInit: ApplicationStringsInit
) {
  return (): Promise<any> => {
    return applicationStringsInit.Init();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    BrowserCompatibilityCheckComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    DeveloperModule,
    AppRoutingModule,
    AppointmentsModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    VcallModule,
    RecallModule,
    SharedModule,
    ImpersonationModule,
    UnderwritingModule,
    MsalModule,
    UserGuideModule,
    NgIdleModule.forRoot(),
    MsalModule,
  ],
  providers: [
    ApplicationStringsInit,
    VcallResolverService,
    AngularPluginService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALConfigurationServiceService.MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALConfigurationServiceService.MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALConfigurationServiceService.MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
