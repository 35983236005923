import { Component, OnInit, Input } from '@angular/core';
import { EzAppAnswerMapperService } from '../../../shared/services/ezappAnswers/ez-app-answer-mapper.service';
import { ApplicationService } from '../../../shared/services/application/application.service';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { BehaviorSubject } from 'rxjs';
import { Question } from 'src/app/shared/models/vcall/question';
import { DrillDownQuestionItem } from 'src/app/shared/models/application/application';
import { MedicalPrescriptionAilment } from 'src/app/shared/models/ezapp/medicalPrescriptionAilment';
import { DrillDownEzAppAnswers } from 'src/app/shared/models/ezapp/drillDownEzAppAnswers';

@Component({
  selector: 'app-ezapp-answers-display',
  templateUrl: './ezapp-answers-display.component.html',
  styleUrls: ['./ezapp-answers-display.component.css'],
})
export class EzappAnswersDisplayComponent implements OnInit {
  allAapplicantsAns: Array<DrillDownEzAppAnswers>;
  medicalApplicantAns: Array<MedicalPrescriptionAilment>;
  selectedApplicantFiter: Array<DrillDownEzAppAnswers>;
  ezappTag: boolean;
  questionType: string;
  drillDownQuestions: DrillDownQuestionItem[];
  selectedApplicant: any;
  currentQuestion: Question;
  @Input() question: BehaviorSubject<Question>;
  prescriptionAilmentDetails: Array<DrillDownEzAppAnswers>;
  prescriptionDrillDownAnswers: MedicalPrescriptionAilment[];
  constructor(
    private ezAppAnswerMapperService: EzAppAnswerMapperService,
    public appService: ApplicationService,
    private service: ScriptService
  ) {
    this.appService.SelectedApplicant.subscribe((applicant) => {
      this.selectedApplicant = applicant;
      if (this.selectedApplicant !== null) {
        this.checkEzappAnswers(this.currentQuestion);
      }
    });
    this.appService.SelectedApplication.subscribe((a) => {
      if (a !== null) {
        this.allAapplicantsAns = a.applicants;
        this.drillDownQuestions = a.drillDownQuestions;
      }
    });
  }

  ngOnInit() {
    this.medicalApplicantAns = [];
    this.ezappTag = false;
    this.question.subscribe((question) => {
      this.currentQuestion = question;
      this.checkEzappAnswers(question);
    });
  }

  checkEzappAnswers(question) {
    this.medicalApplicantAns = [];
    if (question && Object.keys(question).length > 0) {
      // Resetting flags before every bind to ensure data is not carried over from previous question
      this.allAapplicantsAns.map((applicant) => {
        applicant.healthCondition = null;
        this.ezappTag = false;
      });
      // drillDown questions filter service with 'answerTag'
      const currentQuestionAns = this.ezAppAnswerMapperService.drillDownMapper(
        question,
        this.drillDownQuestions
      );

      if (currentQuestionAns && currentQuestionAns.length > 0) {
        this.ezappTag = true;
        switch (currentQuestionAns[0].type) {
          case 'MedicalQualifying': {
            this.questionType = question.type;
            this.medicalQualifyingAnswerMapToApplicants(currentQuestionAns);
            break;
          }
          case 'MedicalAilment': {
            this.questionType = question.type;
            this.medicalAilmentAnswerMapToApplicants(currentQuestionAns);
            break;
          }
          case 'MedicalPrescriptionAilment': {
            this.questionType = currentQuestionAns[0].type;
            this.medicalPrescriptionAilmentDetails(currentQuestionAns);
            break;
          }
        }
      } else {
        this.ezappTag = this.ezappTag || false;
      }

      // this needs to be after the above (drilldown mapping) routine else the dataset by it will be wiped out
      this.allAapplicantsAns.map((applicant) => {
        // if attribute is true or Y just take it
        // don't worry about the other flag.
        const ezAppAttributeMappedValue =
          this.ezAppAnswerMapperService.attributeMapper(question, applicant);
        if (
          ezAppAttributeMappedValue &&
          (ezAppAttributeMappedValue === true ||
            ezAppAttributeMappedValue === 'Y')
        ) {
          applicant.healthCondition = 'Y';
          this.ezappTag = true;
          this.questionType = question.type;
        }
      });
    }
  }

  // medical qualifying answers to map the applicants
  medicalQualifyingAnswerMapToApplicants(answers) {
    if (Object.keys(answers).length !== 0) {
      this.allAapplicantsAns.map((item) => {
        for (const answer of answers) {
          for (const prop in answer.answer) {
            if (item.clientNo === prop) {
              if (answer.answer[prop] === 'Y') {
                item.healthCondition = 'Y';
              } else if (
                answer.answer[prop] === 'N' &&
                item.healthCondition !== 'Y'
              ) {
                item.healthCondition = 'N';
              } else if (
                item.healthCondition !== 'Y' &&
                item.healthCondition !== 'N'
              ) {
                item.healthCondition = '';
              }
            }
          }
        }
      });
    }
  }

  getApplicantCount(allAapplicantsAns, flag) {
    const count = allAapplicantsAns.filter(
      (x) => x.healthCondition === flag || x.healthCondition === null
    ).length;
    return count;
  }

  // medical ailment answers to map the applicants
  medicalAilmentAnswerMapToApplicants(answers) {
    if (Object.keys(answers).length !== 0) {
      this.allAapplicantsAns.map((item) => {
        item.drilldownAnswer = [];
        for (const answer of answers) {
          if (answer.answer[item.clientNo] !== undefined) {
            const ans = JSON.parse(answer.answer[item.clientNo].substring());
            item.drilldownAnswer = [...item.drilldownAnswer, ...ans];
          }
        }
      });
      // console.log('all applicant ans', this.allAapplicantsAns);
      this.selectedApplicantFiter =
        this.selectedApplicant !== null && this.selectedApplicant !== undefined
          ? this.allAapplicantsAns.filter(
              (item) => item.clientNo === this.selectedApplicant.clientNo
            )
          : [];
      if (this.selectedApplicantFiter.length > 0) {
        this.medicalApplicantAns =
          this.selectedApplicantFiter[0].drilldownAnswer;
      }
    }
  }

  medicalPrescriptionAilmentDetails(answers) {
    if (Object.keys(answers).length !== 0) {
      this.allAapplicantsAns.map((item) => {
        item.drilldownAnswer = [];
        for (const answer of answers) {
          if (answer.answer[item.clientNo] !== undefined) {
            const ans = JSON.parse(answer.answer[item.clientNo].substring());
            item.drilldownAnswer = [...item.drilldownAnswer, ...ans];
          }
        }
      });
      this.prescriptionAilmentDetails = this.allAapplicantsAns.filter(
        (item) => item.clientNo === this.selectedApplicant.clientNo
      );
      if (this.prescriptionAilmentDetails.length > 0) {
        this.prescriptionDrillDownAnswers =
          this.prescriptionAilmentDetails[0].drilldownAnswer;
      }
    }
  }
}
