export class Product {
  appType: string;
  description: string;
  function: string;
}

export class IndemnityCoverage {
  planName: string;
  riderDescription: string;
}
export class PolicyInformation {
  product: string;
}
