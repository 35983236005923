import { Component, OnInit } from '@angular/core';
import { BaseQuestionType } from '../baseQuestionType';
import { VcallQuestionTypeBooleanDetail } from '../boolean/boolean.component';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { Question } from 'src/app/shared/models/vcall/question';

@Component({
  selector: 'app-question-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent extends BaseQuestionType<
  VcallQuestionTypeBooleanDetail
> {
  question: Question = null;
  details: VcallQuestionTypeBooleanDetail = new VcallQuestionTypeBooleanDetail();
  nextEnabled = false;
  notes = null;

  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService
  ) {
    super(script, appService, ui);
  }
  ResetView() {}
  ApplicationChanged(): void {}

  QuestionChanged(q: Question): void {
    this.notes = this.answer?.value;
  }

  CanGoNext(): boolean {
    if (this.notes) {
      return this.notes && this.notes.trim().length > 0;
    }
  }
  SaveText(notes) {
    this.notes = notes;
    this.saveAnswer(true, notes);
  }
  EnableNext(notes) {
    if (!this.nextEnabled) {
      this.saveAnswer(true, notes);
      this.nextEnabled = true;
    }

    if (notes === '') {
      this.saveAnswer(true, notes);
      this.nextEnabled = false;
    }
  }
}
