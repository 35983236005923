<div class="p-3">
  <div class="row">
    <div class="col">
      <table class="table table-hover table-striped tbl-log mt-3">
        <thead>
          <tr class="table-dark table_header_fixed">
            <!-- <th scope="col" class="text-center">S. No</th> -->
            <th scope="col" class="text-center">Policy</th>
            <th scope="col" class="text-center">Type</th>
            <th scope="col" class="text-center">Product</th>
          </tr>
        </thead>
        <tbody
          *ngFor="let policy of policyDetails; let i = index; let odd = odd"
        >
          <tr [ngClass]="{ 'bg-white': odd }">
            <!-- <th scope="row" class="text-center text-nowrap">
              {{ i > 9 ? i + 1 : "0" + (i + 1) }}
            </th> -->
            <td class="text-center">{{ policy.policyNumber }}</td>
            <td class="text-center">
              <span>{{
                policy.policyNumber
                  ? (policy.policyNumber | slice: -1)
                  : policy.policyNumber
              }}</span>
            </td>
            <td class="text-center">{{ policy.product }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
