<div class="container">
  <br />
  <br />
  <br />
  <div class="row offset-2">
    <button
      class="ml-2 btn btn-secondary"
      (click)="devlookup('providerWrapper')"
    >
      Developer Lookup
    </button>
    <button
      class="ml-2 btn btn-secondary"
      (click)="devlookup('occupationWrapper')"
    >
      Occupation Lookup
    </button>
    <button
      class="ml-2 btn btn-secondary"
      (click)="devlookup('prescriptionWrapper')"
    >
      Prescription Lookup
    </button>
  </div>
</div>
