import { Component, OnInit, OnChanges } from '@angular/core';
import {
  ApplicantQualifiedBaseComponent,
  BaseApplicantQualifiedAnswer,
} from '../applicant-qualified-controls/applicant-qualified-base-component';
import {
  ApplicantQualifiedDropDownGeneric,
  DefaultApplicantQualifiedQuestionDetail,
} from '../applicant-qualified-controls/default-applicant-qualified-question-detail';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import {
  DoctorLookup,
  DoctorLookupAnswer,
} from 'src/app/shared/models/drLookup';
import { BehaviorSubject } from 'rxjs';
import { ApplicantFilterService } from 'src/app/shared/services/filter/applicant-filter/applicant-filter.service';

@Component({
  selector: 'app-applicant-qualified-doctor-lookup',
  templateUrl: './applicant-qualified-doctor-lookup.component.html',
  styleUrls: ['./applicant-qualified-doctor-lookup.component.scss'],
})
export class ApplicantQualifiedDoctorLookupComponent extends ApplicantQualifiedBaseComponent<
  DefaultApplicantQualifiedQuestionDetail,
  DoctorlookupDetailsAnswer
> {
  details: DefaultApplicantQualifiedQuestionDetail;
  initialList = new BehaviorSubject(new DoctorlookupDetailsAnswer());

  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    protected applicantFilterService: ApplicantFilterService
  ) {
    super(script, appService, ui, applicantFilterService);
  }
  ResetApplicant() {
    // this.initialList = new Array<;
  }

  BindApplicant() {
    if (this.currentAnswer && this.currentAnswer.list) {
      this.initialList.next(this.currentAnswer);
    } else {
      this.initialList.next(new DoctorlookupDetailsAnswer());
    }
    super.Save(this.currentAnswer);
  }

  InitializeApplicantAnswerInstance(): DoctorlookupDetailsAnswer {
    return new DoctorlookupDetailsAnswer();
  }

  CanGoNext(): boolean {
    if (
      !this.currentAnswer.isProviderAvailable ||
      (this.currentAnswer.list && this.currentAnswer.list.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getUpdatedDrList(e: DoctorlookupDetailsAnswer) {
    this.currentAnswer = e;
    super.Save(this.currentAnswer);
  }
}

export class DoctorlookupDetailsAnswer extends BaseApplicantQualifiedAnswer {
  list: DoctorLookup[] = [];
  isProviderAvailable = false;
  answerType = 'DoctorDetailsAnswer';
}
