import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap, take, tap } from 'rxjs/operators';
import { Question } from 'src/app/shared/models/vcall/question';
import { Script } from 'src/app/shared/models/vcall/script';
import { ScriptService } from 'src/app/shared/services/script/script.service';

@Component({
  selector: 'app-smart-navigation',
  templateUrl: './smart-navigation.component.html',
  styleUrls: ['./smart-navigation.component.css'],
})
export class SmartNavigationComponent implements OnInit {
  constructor(private service: ScriptService, private router: Router) {}

  public showQuestions = false;
  public applicationScript: Script;
  public toggle = false;

  public ngOnInit(): void {
    this.service.ApplicationScript.pipe(
      tap((script: Script) => {
        this.applicationScript = script;
      }),
      take(1)
    ).subscribe();

    this.router.events
      .pipe(
        concatMap(() => {
          return this.service.ApplicationScript.pipe(
            tap((script: Script) => {
              this.applicationScript = script;
            })
          );
        })
      )
      .subscribe();
  }

  public routeToQuestion(question: Question) {
    this.service.LoadQuestion(
      question,
      question.section,
      this.applicationScript.applicationId
    );
    window.scroll(0, 0);
  }
}
