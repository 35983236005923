export const errorMessage = {
  getOrder: 'Unable to retrieve the Orders for ApplicationId',
  createOrder: 'Unable to Create the Order Request',
  updateOrder: 'Unable to Update the Order Request',
  deleteOrder: 'Unable to Delete the Order Order Request',
  routeToAps: 'Unable to Route the Application to APS Team',
  orderDocuments: 'Unable to retrieve the Documents for the Order',
  addNote: 'Unable to add the note',
  applicationTrackerRecord: 'Unable to Create ApplicationTracker Record',
  getApplicationTrackerRecords:
    'Unable to retrieve the ApplicationTracker Records for ApplicationId',
};
