import { Injectable } from '@angular/core';
import { Appointment } from '../models/appointment';
import { PolicyItem } from '../models/policyItem';
import { Address } from '../models/address';
import { Client } from '../models/client';
import { pipe } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { JsonPipe } from '@angular/common';
import valuesOfCompaniesData from '../models/valuesOfCompanies';
import { UserProfileService } from './user/user-profile.service.service';
import listOfTimeZonesWithUtc from '../../shared/models/timezones-utc';
import { ToastService } from './toast/toast.service';

/**
 * service for common methods used to conversions
 */

@Injectable({
  providedIn: 'root',
})
export class UiServiceService {
  constructor(
    public userProfileService: UserProfileService,
    private toaster: ToastService
  ) {}
  ApplicationModalTrigger = new BehaviorSubject<UIModalParameter>(null);
  /**
   *
   * @param stringToInterpolate text to interpolate
   * @param scope current scope
   * @param props props
   * @param question current question
   * @returns the string after converting the interpolated string to respective values
   */
  interpolate(
    stringToInterpolate: any,
    scope: object,
    props: object = null,
    question?: object
  ) {
    if (!stringToInterpolate) {
      return '';
    }
    const LOCAL = {
      oSource: scope, // should be = this
      value: null,
      foundValue: true,
    };

    return stringToInterpolate.replace(
      /{{(.*?)\}\}/g,
      (match, expr: string) => {
        if (expr === undefined || expr === null || expr.length === 0) {
          return '';
        }

        expr = expr.trim();
        // try props for the value
        LOCAL.value = props || {};
        expr.split('.').some((currentExpr) => {
          if (match.includes('|')) {
            const pipeName = match.split('|')[1].trim().replaceAll(/"|}/gi, '');
            const curExp = currentExpr.split('|')[0].trim();
            if (curExp in LOCAL.value) {
              LOCAL.value = LOCAL.value[curExp];
              if (typeof LOCAL.value === 'string') {
                LOCAL.value = this.applyPipeTransform(
                  LOCAL.value,
                  pipeName,
                  question
                );
              }
              return false;
            } else {
              LOCAL.foundValue = false;
              LOCAL.value = '';
              return true; // stop execution of array with some()
            }
          }
          if (LOCAL.value && currentExpr in LOCAL.value) {
            LOCAL.value = LOCAL.value[currentExpr];
            return false;
          } else {
            LOCAL.foundValue = false;
            LOCAL.value = '';
            return true; // stop execution of array with some()
          }
        });
        // if we did not found a value in the given props, find it in the this scope
        if (!LOCAL.foundValue && expr) {
          LOCAL.value = LOCAL.oSource;
          expr.split('.').some((currentExpr) => {
            if (match.includes('|')) {
              const pipeName = match
                .split('|')[1]
                .trim()
                .replaceAll(/"|}/gi, '');
              const curExp = currentExpr.split('|')[0].trim();
              if (curExp in LOCAL.value) {
                LOCAL.value = LOCAL.value[curExp];
                if (typeof LOCAL.value === 'string') {
                  LOCAL.value = this.applyPipeTransform(LOCAL.value, pipeName);
                }
                return false;
              } else {
                LOCAL.foundValue = false;
                LOCAL.value = '';
                return true; // stop execution of array with some()
              }
            }
            if (LOCAL.value && currentExpr in LOCAL.value) {
              LOCAL.value = LOCAL.value[currentExpr];
              return false;
            } else {
              LOCAL.foundValue = false;
              LOCAL.value = '';
              return true; // stop execution of array with some()
            }
          });
        }
        return LOCAL.value;
      }
    );
  }
  /**
   *
   * @param value value
   * @param pipeName filter service name
   * @param question current queston
   */

  applyPipeTransform(value, pipeName, question?: object) {
    const pipeArray = pipeName.split(':');
    switch (pipeArray[0]) {
      case 'titleCase':
        return this.textToTitleCaseFormat(value);
      case 'translate':
        return this.fullFormText(value, pipeArray[1], question);

      default:
        return `Could not translate ${value}(${pipeName}) `;
    }
  }
  /**
   *
   * @param key string that has to be converted into full form
   * @param pipeName which pipe to use for Converting
   * @param question current question
   * @returns the string with FullForm
   */
  fullFormText(key, pipeName, question) {
    let listofQuestions: {};
    const listOfvaluesFromFile = valuesOfCompaniesData;

    if (question && question.details && question.details[pipeName]) {
      listofQuestions = JSON.parse(question.details[pipeName]);
    }

    return listofQuestions
      ? this.getFullFormObj(listofQuestions, key)
      : this.getFullFormObj(listOfvaluesFromFile[pipeName], key);
  }

  getFullFormObj(listofDetails, key) {
    const fullformObj = listofDetails.filter((d) => d.key === key);
    return fullformObj ? fullformObj[0].value : null;
  }

  /***
   * @returns the formatted of String tot titlecase
   */
  textToTitleCaseFormat(value) {
    const val = value
      .toLowerCase()
      .replace(/\b\w/g, function textToTitleProperCase(m) {
        return m.toUpperCase();
      });
    return val;
  }

  private convertTexttoProperCase(text: string) {
    if (text) {
      const sentence = text.toLowerCase().split(' ');
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(' ');
    }
  }
  /**
   * @returns Formatted current Date
   */

  public formatCurrentDate() {
    return (
      new Date().toLocaleDateString() +
      ' ' +
      new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
        timeZone: this.filterSelectedTimeZone()?.utc,
      }) +
      ' ' +
      '(' +
      this.filterSelectedTimeZone()?.shortFormTimeZone +
      ')'
    );
  }

  /**
   * @returns Formatted current Date with timeZone
   */
  public formatCurrentDateandTimeforUtcDate() {
    const dt = new Date(); // Date constructor
    return (
      dt.getUTCMonth() +
      1 +
      '/' +
      dt.getUTCDate() +
      '/' +
      dt.getUTCFullYear() +
      ' ' +
      dt.getUTCHours().toFixed() +
      ':' +
      dt.getUTCMinutes() +
      ':' +
      dt.getUTCSeconds()
    );
  }

  public filterSelectedTimeZone() {
    const filteredListOfTimeZonesWithUtc = listOfTimeZonesWithUtc.find(
      (eachTimeZone) =>
        eachTimeZone.timezone.toLowerCase() ===
        this.userProfileService.getCallzoneUser()?.timeZone?.toLowerCase()
    );
    return filteredListOfTimeZonesWithUtc;
  }
  /**
   *
   * @param reason reason information
   * returning the Permission denied message
   */
  public PermissionDenied(reason: string) {
    const message = new UIModalParameter();
    message.body = 'You are not authorized to perform this action. ';
    message.header = 'Access Denied for : ' + reason;
    message.modalBehavior = UIModalBehavior.RedirectToDashboard;

    this.ApplicationModalTrigger.next(message);
  }

  public ModalMessage(
    reason: string,
    header?: string,
    modalBehavior = UIModalBehavior.RedirectToDashboard
  ) {
    const message = new UIModalParameter();
    message.body = reason;
    message.header = header ? header : '';
    message.modalBehavior = modalBehavior;
    this.ApplicationModalTrigger.next(message);
  }

  /**
   * If not part of callZone user then display the modal
   */
  public HandleInValidCallzoneUser() {
    this.toaster.error(null, 'You are not part of Callzone User');
    return;
  }

  /**
   * Check part of callZone user is or not
   */
  public IsAValidCallzoneUser() {
    let isCallZoneUser = true;
    if (
      this.userProfileService.getCallzoneUser() === undefined ||
      this.userProfileService.getCallzoneUser() === null ||
      this.userProfileService.getCallzoneUser().hasOwnProperty('status') ||
      !this.userProfileService.getCallzoneUser().isActive
    ) {
      isCallZoneUser = false;
    } else {
      isCallZoneUser = true;
    }
    return isCallZoneUser;
  }
}

export class UIModalParameter {
  header: string;
  body: string;
  modalBehavior: UIModalBehavior;
}

export enum UIModalBehavior {
  RedirectToDashboard = 0,
  NoRedirect = 1,
  Refresh = 2,
}
