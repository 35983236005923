<div *ngIf="application">
  <div class="row box-shadow mt-2 m-0 p-2">
    <div class="col-sm-4">
      <p>
        <label class="f-w-500"
          >Applicant Name:
          <b class="pl-2">{{
            application.primaryApplicant.fullName || "N/A"
          }}</b></label
        >
      </p>
      <p>
        <label class="f-w-500"
          >Applicant Phone:
          <b class="pl-2">{{
            application.contact.homePhone || "N/A"
          }}</b></label
        >
      </p>
      <p>
        <label class="f-w-500"
          >Application ID:<b class="pl-2">{{
            application.id || "N/A"
          }}</b></label
        >
      </p>
    </div>
    <div class="d-flex col-sm-7">
      <div class="col-sm-11 p-0 d-flex flex-wrap">
        <div
          class="pr-3 pb-3 policy"
          *ngFor="let item of application.policyInformation"
        >
          <span class="font-weight-bold">{{ item.product || "N/A" }}</span
          ><span class="pl-2">({{ item.policyNumber }})</span>
        </div>
      </div>
    </div>
  </div>
</div>
