import { Component, OnDestroy, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OnlineApplicantsComponent } from 'src/app/shared/components/online-applicants/online-applicants.component';
import { Application } from 'src/app/shared/models/application/application';
import { Appointment } from 'src/app/shared/models/appointment';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import {
  AppointmentService,
  StartVerificationParameters,
} from 'src/app/shared/services/appointment/appointment.service';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { DetailOptions } from 'src/app/shared/models/detail-options';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandledHttpClient } from 'src/app/shared/services/api/handledHttpClient';
import { AppointmentAnswerService } from 'src/app/shared/services/appointmentAnswer/appointmentAnswer.service';

@Component({
  selector: 'app-appointments-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnDestroy {
  enableNotifications: boolean = environment.features.sms;
  appointment: Appointment;
  application: Application;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  @ViewChild(OnlineApplicantsComponent)
  onlineApplicantsModal: OnlineApplicantsComponent;
  baseUrl: string;
  timeZone: string;
  callZoneAppointmentUrl: string;
  @Input() detailOptions: DetailOptions;
  listOfAppointments: boolean;

  constructor(
    private appointmentAnswerService: AppointmentAnswerService,
    private appointmentService: AppointmentService,
    private applicationService: ApplicationService,
    private userProfileService: UserProfileService,
    private router: Router,
    private http: HttpClient,
    private hhttp: HandledHttpClient
  ) {
    this.baseUrl = environment.baseApplicationDocumentUrl;
    this.callZoneAppointmentUrl = environment.callzoneAppointmentUrl;
    this.appointmentService.Appointments.subscribe((a) => {
      if(a && a.length > 0) {
        this.listOfAppointments = true;
      } else {
        this.listOfAppointments = false;
      }
    })
    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe(
        (a) => (this.appointment = a)
      )
    );
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe(
        (a) => {
          if (a) {
            this.application = a;
          } else {
            this.application = null;
          }
        },
        (e) => {
          console.log('application not available ');
        }
      )
    );
    this.getProfileDetails();
  }

  downloadPdf() {
    const headers = new HttpHeaders();
    this.http
      .get(
        this.baseUrl +
          '/Applications/' +
          this.application.id +
          '/ApplicationPdf',
        {
          responseType: 'blob',
          // headers: this.hhttp.addMatchedRole(headers),
        }
      )
      .subscribe((data) => {
        this.downloadThePdf(data);
      });
  }

  downloadThePdf(data) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  startVerification() {
    this.appointmentAnswerService.startAppointmentQuestion('active');
    this.appointmentService.StartVerification(
      new StartVerificationParameters(this.application, this.appointment)
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }

  getProfileDetails() {
    this.timeZone = this.userProfileService.getCallzoneUser()?.timeZone;
  }
}
