import { Component, OnInit, OnDestroy } from '@angular/core';
import { Question } from 'src/app/shared/models/vcall/question';
import { VcallQuestionTypeBooleanDetail } from 'src/app/vcall/questions/types/boolean/boolean.component';
import { Payment } from 'src/app/shared/models/application/payment';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { PaymentAnswers } from 'src/app/shared/models/vcall/paymentAnswers';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Application } from 'src/app/shared/models/application/application';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { VcallQuestionTypePaymentDetail } from 'src/app/shared/models/vcall/paymentDetail';
import {
  EffectiveDateRules,
  EffectiveDateRulesMode,
} from 'src/app/shared/models/vcall/effectiveDateRules';
import { VerifiableModel } from '../../../types/verifiableList/verifiable-list.component';

@Component({
  selector: 'app-question-monthly-effective-date',
  templateUrl: './monthly-effective-date.component.html',
  styleUrls: ['./monthly-effective-date.component.scss'],
})
export class VcallMonthlyEffectiveDateComponent implements OnInit, OnDestroy {
  constructor(
    private script: ScriptService,
    private applicationService: ApplicationService,
    private modalService: NgbModal,
    private uiServiceService: UiServiceService
  ) {
    this.paymentAnswers = new PaymentAnswers();
  }
  question: Question = null;
  details = new VcallQuestionTypePaymentDetail();
  payment: Payment = null;
  editPayment: Payment;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  paymentAnswers: any;
  questionText = null;
  application: Application;
  maxDate: any;
  minDate: any;
  selected: any;
  showSpecificDate: boolean;
  questionText$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  questionTextO$ = this.questionText$.asObservable();
  submitDate: any;
  frequencies: any;
  effectiveDateRules: EffectiveDateRules;
  nextDateList = [...Array(28).keys()];
  samePaymentTypeConfirmation: any;

  isDisabled = (date: NgbDate, current: { month: number }) => date.day > 28;

  ngOnInit(): void {
    this.subscriptions.push(
      this.script.SelectedQuestion.subscribe((q) => {
        this.question = q;
        Object.assign(this.details, q.details);
        this.questionText = this.details.specificDateQuestion;
        this.frequencies = JSON.parse(this.details.options);
        this.effectiveDateRules = JSON.parse(this.details.effectiveDateRules);

        const answer = this.applicationService.GetAnswerForAnswerTag(
          'payment.requestedEffectiveDate'
        );
        this.samePaymentTypeConfirmation =
          this.applicationService.isQuestionGenericMode();
        if (answer && answer.verification !== undefined) {
          this.paymentAnswers.effectiveDate.verified = answer.verification.toString();
          // TODO: This control needs to be moved to use BaseQuestion and Verifiable List for better handling.
          // as soon as an answer is picked user can go next.
          this.script.UpdateAnswer(true, true);
        }
      })
    );

    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a) => {
        if (a !== null) {
          this.application = a;
          this.payment = a.payment;
          const text = this.uiServiceService.interpolate(
            this.details.frequencyQuestion,
            this,
            this.application
          );
          this.questionText$.next(text);
          this.interpolation();
          if (this.payment.nextOrSpecificDate) {
            this.showSpecificDate = true;
          }
        }
      })
    );
  }

  interpolation() {
    const text = this.uiServiceService.interpolate(
      this.details['customQuestionText'],
      this,
      this.payment
    );
    this.questionText$.next(text);
    this.question.details.onTheSpecificDate = this.uiServiceService.interpolate(
      this.details['onTheSpecificDate'],
      this,
      this.payment
    );
  }

  optionChanged(answer: any) {
    // TODO: Add check to see if it has changed then only record the answer
    const models = new Array<VerifiableModel<string>>();
    models.push(
      new VerifiableModel({
        answerTag: 'payment.requestedEffectiveDate',
        changedValue: this.payment.requestedEffectiveDate,
        verification: answer,
      })
    );
    models.push(
      new VerifiableModel({
        answerTag: 'payment.modeOfPayment',
        changedValue: this.payment.modeOfPayment,
        verification: answer,
      })
    );
    models.push(
      new VerifiableModel({
        answerTag: 'payment.nextOrSpecificDate',
        changedValue: this.payment.nextOrSpecificDate,
        verification: answer,
      })
    );
    models.push(
      new VerifiableModel({
        answerTag: 'payment.onTheNextDateDay',
        changedValue: this.payment.onTheNextDateDay,
        verification: answer,
      })
    );
    this.paymentAnswers.effectiveDate.verified = answer;
    this.applicationService.RegisterAnswersByTag(models);
    this.script.RegisterAnswer(true, this.question);
    // as soon as an answer is picked user can go next.
    this.script.UpdateAnswer(true, true);
  }

  calculateMinMaxDate() {
    const date = new Date(this.application.submitDate);
    date.setHours(0, 0, 0, 0);
    // minimum date = application submitted date
    this.minDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    const effectiveRule = this.effectiveDateRules.rules.filter((rules) => {
      const startDate = new Date(
        date.getFullYear(),
        parseInt(rules.startMonth, 10) - 1,
        parseInt(rules.startDate, 10)
      );
      const endDate = new Date(
        date.getFullYear(),
        parseInt(rules.endMonth, 10) - 1,
        parseInt(rules.endDate, 10)
      );

      const applies = date >= startDate && endDate >= date;
      // Leaving this console for quick debuggin in higher environment.
      console.log(
        rules,
        applies,
        startDate.toDateString(),
        endDate.toDateString()
      );
      return applies;
    });

    // either stored value or application submit date
    const selectedDate = new Date(
      this.payment.nextOrSpecificDate ||
        this.application.payment.nextOrSpecificDate ||
        this.application.submitDate
    );
    this.paymentAnswers.effectiveDate.value = {
      year: selectedDate.getFullYear(),
      month: selectedDate.getMonth() + 1,
      day: selectedDate.getDate(),
    };
    // this.minDate = this.selected;

    // in case of more than 1 rule, using the first rule.
    // this behavior is already in place so not changing it.

    let dateRule = null;
    if (effectiveRule.length > 0) {
      const rule = effectiveRule[0];
      const mode = rule.mode ? rule.mode : EffectiveDateRulesMode.sliding;

      switch (mode) {
        case EffectiveDateRulesMode.sliding: {
          dateRule = new Date(
            date.setDate(date.getDate() + parseInt(rule.value, 10))
          );
          break;
        }
        case EffectiveDateRulesMode.fixed: {
          dateRule = new Date(
            rule.value +
              (new Date().getFullYear() +
                (rule['addonYear'] &&
                rule['addonYear'] !== null &&
                rule['addonYear'] !== undefined
                  ? rule['addonYear']
                  : 0))
          );
          break;
        }
      }
    } else {
      dateRule = new Date(
        date.setDate(date.getDate() + this.effectiveDateRules.defaultValue)
      );
    }

    this.maxDate = {
      year: dateRule.getFullYear(),
      month: dateRule.getMonth() + 1,
      day: dateRule.getDate(),
    };
  }
  editQuestion(content: any): void {
    this.editPayment = Object.assign({}, this.payment);
    this.calculateMinMaxDate();
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      })
      .result.then(
        (result) => {
          if (result === 'Done') {
            if (
              this.paymentAnswers.effectiveDate &&
              this.paymentAnswers.effectiveDate.value !== null
            ) {
              this.editPayment.nextOrSpecificDate = `
              ${this.paymentAnswers.effectiveDate.value.month < 10 ? '0' : ''}${
                this.paymentAnswers.effectiveDate.value.month
              }/
              ${this.paymentAnswers.effectiveDate.value.day < 10 ? '0' : ''}${
                this.paymentAnswers.effectiveDate.value.day
              }/
              ${this.paymentAnswers.effectiveDate.value.year}`.replace(
                /\s/g,
                ''
              );
            }
            this.payment = Object.assign({}, this.editPayment);
            this.interpolation();
            // HACKS : need better handling
            // forcing the verfication;
            this.paymentAnswers.effectiveDate.verified = null;
            this.script.UpdateAnswer(false, null);
          }
        },
        (reason) => {}
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
