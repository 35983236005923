import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CallZoneUser } from 'src/app/shared/models/callzone-user';
import { CallZoneApiService } from 'src/app/shared/services/api/callzone/callzone-api.service';
import { PermissionService } from 'src/app/shared/services/permission/permission.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.scss'],
})
export class UserVerificationComponent implements OnInit, OnDestroy {
  subscriptions = new Array<Subscription>();
  userName: string;
  roles = [];
  permissions = [];
  user: CallZoneUser;
  errorMessage: string;
  environment = environment.config.environment;

  constructor(
    private profileService: UserProfileService,
    public permissionService: PermissionService,
    private api: CallZoneApiService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.permissionService.PermissionSnapShot().subscribe((ps) => {
        this.permissions = ps;
      })
    );
    this.subscriptions.push(
      this.profileService.userProfile.subscribe((resp) => {
        if (resp) {
          this.userName = resp.domainUserName;
        }
      })
    );
    this.subscriptions.push(
      this.profileService.Roles.subscribe((rs) => {
        this.roles = rs;
        this.roles = this.roles.filter(
          (role) => role === this.permissions[0].key
        );
      })
    );
    this.subscriptions.push(
      this.api.GetCallZoneUser(this.userName).subscribe((u) => {
        if (u && u.status !== 404) {
          this.user = u;
        } else {
          this.errorMessage = u?.error;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.map((s) => s.unsubscribe());
  }
}
