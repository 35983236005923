import { DiscoverableChainItem } from './discoverableChainItem';
import { DecoratedModelConfiguration } from './decoratedModelConfiguration';
import { Discoverable } from './discoverable';
import { PropertyDecorator } from '../../services/application/modelMapperService.service';

export class Payment implements Discoverable {
  chain: DiscoverableChainItem[];
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'methodOfPayment')
  methodOfPayment: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'modeOfPayment')
  modeOfPayment: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'MonthlyBankInformation'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'bankName')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Bank Name')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'bankName')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  @PropertyDecorator(DecoratedModelConfiguration.Required, true)
  @PropertyDecorator(DecoratedModelConfiguration.ControlType, 'textbox')
  @PropertyDecorator(DecoratedModelConfiguration.Type, 'text')
  bankName: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'MonthlyBankInformation'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'bankRoutingCode')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Routing #')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'bankRoutingCode')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  @PropertyDecorator(DecoratedModelConfiguration.Required, true)
  @PropertyDecorator(DecoratedModelConfiguration.ControlType, 'textbox')
  @PropertyDecorator(DecoratedModelConfiguration.Type, 'text')
  @PropertyDecorator(DecoratedModelConfiguration.Pattern, '^[0-9]*$')
  @PropertyDecorator(DecoratedModelConfiguration.MinLength, 9)
  @PropertyDecorator(DecoratedModelConfiguration.MaxLength, 9)
  bankRoutingCode: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'MonthlyBankInformation'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'bdAccountNumber')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Account #')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'bdAccountNumber')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 30)
  @PropertyDecorator(DecoratedModelConfiguration.Required, true)
  @PropertyDecorator(DecoratedModelConfiguration.ControlType, 'textbox')
  @PropertyDecorator(DecoratedModelConfiguration.Type, 'text')
  @PropertyDecorator(DecoratedModelConfiguration.NeedsEncryption, true)
  @PropertyDecorator(
    DecoratedModelConfiguration.Pattern,
    '^(?!(\\d)\\1+$)(?!0123456789$)(?!1234567890$)(?!123456789$)(?!123456$)(?!1234567$)(?!12345678$)\\d{6,13}$'
  )
  bdAccountNumber: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'requestedEffectiveDate'
  )
  requestedEffectiveDate: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'nextOrSpecificDate'
  )
  nextOrSpecificDate: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'onTheNextDateDay')
  onTheNextDateDay: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'initialPaymentBy')
  initialPaymentBy: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'InitialBankInformation'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'initPmtBankName')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Bank Name')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'initPmtBankName')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  @PropertyDecorator(DecoratedModelConfiguration.Required, true)
  @PropertyDecorator(DecoratedModelConfiguration.ControlType, 'textbox')
  @PropertyDecorator(DecoratedModelConfiguration.Type, 'text')
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'bankName')
  initPmtBankName: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'InitialBankInformation'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBankRoutingCode'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Routing #')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBankRoutingCode'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  @PropertyDecorator(DecoratedModelConfiguration.Required, true)
  @PropertyDecorator(DecoratedModelConfiguration.ControlType, 'textbox')
  @PropertyDecorator(DecoratedModelConfiguration.Type, 'text')
  @PropertyDecorator(DecoratedModelConfiguration.Pattern, '^[0-9]*$')
  @PropertyDecorator(DecoratedModelConfiguration.MinLength, 9)
  @PropertyDecorator(DecoratedModelConfiguration.MaxLength, 9)
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'bankRoutingCode')
  initPmtBankRoutingCode: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'InitialBankInformation'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBDAccountNumber'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Account #')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBDAccountNumber'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 30)
  @PropertyDecorator(DecoratedModelConfiguration.Required, true)
  @PropertyDecorator(DecoratedModelConfiguration.ControlType, 'textbox')
  @PropertyDecorator(DecoratedModelConfiguration.Type, 'text')
  @PropertyDecorator(DecoratedModelConfiguration.NeedsEncryption, true)
  @PropertyDecorator(
    DecoratedModelConfiguration.Pattern,
    '^(?!(\\d)\\1+$)(?!0123456789$)(?!1234567890$)(?!123456789$)(?!123456$)(?!1234567$)(?!12345678$)\\d{6,13}$'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'bdAccountNumber')
  initPmtBDAccountNumber: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'creditCardType')
  creditCardType: string;
  // @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'BillingName')
  // billingName: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtCcAccountNumber'
  )
  initPmtCcAccountNumber: string;
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'expirationDate')
  expirationDate: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyDirectBill'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'billingName')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Billing Name')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'billingName')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  billingName: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyDirectBill'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'billingAddress')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Street Address')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'billingAddress')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  billingAddress: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyDirectBill'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'billingCity')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'City')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'billingCity')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 30)
  billingCity: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyDirectBill'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'billingState')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'State')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'billingState')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 40)
  billingState: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyDirectBill'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'billingZip')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Zip Code')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'billingZip')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 50)
  billingZip: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyDirectBill'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'billingPhone')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Phone')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'billingPhone')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 60)
  billingPhone: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyBankDraft'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'nameOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Billing Name')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'nameOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  nameOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyBankDraft'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'addressOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Street Address')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'addressOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  addressOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyBankDraft'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'cityOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'City')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'cityOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 30)
  cityOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyBankDraft'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'stateOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'State')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'stateOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 40)
  stateOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyBankDraft'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'zipOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Zip Code')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'zipOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 60)
  zipOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyBankDraft'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'phoneOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Phone')
  @PropertyDecorator(DecoratedModelConfiguration.MetaKeyName, 'phoneOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 70)
  phoneOnCheck: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialBankDraft'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBankNameOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Billing Name')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBankNameOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'nameOnCheck')
  initPmtBankNameOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialBankDraft'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBankAddressOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Street Address')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBankAddressOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'addressOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  initPmtBankAddressOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialBankDraft'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBankCityOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'City')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBankCityOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'cityOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 30)
  initPmtBankCityOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialBankDraft'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBankStateOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'State')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBankStateOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'stateOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 40)
  initPmtBankStateOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialBankDraft'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBankZipOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Zip Code')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBankZipOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'zipOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 60)
  initPmtBankZipOnCheck: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialBankDraft'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBankPhoneOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Phone')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBankPhoneOnCheck'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'phoneOnCheck')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 70)
  initPmtBankPhoneOnCheck: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialCreditCard'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBillingName'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Billing Name')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBillingName'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'billingName')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  initPmtBillingName: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialCreditCard'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBillingAddress'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Street Address')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBillingAddress'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'billingAddress')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  initPmtBillingAddress: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialCreditCard'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBillingCity'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'City')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBillingCity'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 30)
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'billingCity')
  initPmtBillingCity: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialCreditCard'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'initPmtBillingState'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'State')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBillingState'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'billingState')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 40)
  initPmtBillingState: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialCreditCard'
  )
  @PropertyDecorator(DecoratedModelConfiguration.AnswerTag, 'initPmtBillingZip')
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Zip Code')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'initPmtBillingZip'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'billingZip')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 60)
  initPmtBillingZip: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialCreditCard'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'iniPmtCCBillingPhone'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Phone')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'iniPmtCCBillingPhone'
  )
  @PropertyDecorator(DecoratedModelConfiguration.CopyMapper, 'billingPhone')
  @PropertyDecorator(DecoratedModelConfiguration.Order, 70)
  iniPmtCCBillingPhone: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'authorizedNameMonthly'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Authorized Name')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'authorizedNameMonthly'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 80)
  authorizedNameMonthly: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'businessNameMonthlyOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Person/Business Name')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'businessNameMonthlyOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  businessNameMonthlyOnline: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'businessOwnerMonthlyOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.Label,
    'Business Owner/Manager'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'businessOwnerMonthlyOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  businessOwnerMonthlyOnline: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'businessNameMonthlyOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Person/Business Name')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'businessNameMonthlyOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  businessNameMonthlyOffline: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'businessOwnerMonthlyOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.Label,
    'Business Owner/Manager'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'businessOwnerMonthlyOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  businessOwnerMonthlyOffline: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'authorizedPhoneMonthly'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Authorized Phone')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'authorizedPhoneMonthly'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 90)
  authorizedPhoneMonthly: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'notesMonthlyOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Notes')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'notesMonthlyOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 80)
  notesMonthlyOffline: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'authorizedNameInitial'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Authorized Name')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'authorizedNameInitial'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'authorizedNameMonthly'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 80)
  authorizedNameInitial: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'businessNameInitialOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Person/Business Name')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'businessNameInitialOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'businessNameMonthlyOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  businessNameInitialOnline: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'businessOwnerInitialOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.Label,
    'Business Owner/Manager'
  )
  @PropertyDecorator(DecoratedModelConfiguration, 'businessOwnerMonthlyOnline')
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'businessOwnerMonthlyOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'businessOwnerInitialOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  businessOwnerInitialOnline: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'authorizedPhoneInitial'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Authorized Phone')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'authorizedPhoneInitial'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'authorizedPhoneMonthly'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 90)
  authorizedPhoneInitial: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'notesInitialOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Notes')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'notesInitialOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'notesMonthlyOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 80)
  notesInitialOffline: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'businessNameInitialOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Person/Business Name')
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'businessNameInitialOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'businessNameMonthlyOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 10)
  businessNameInitialOffline: string;
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'businessOwnerInitialOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.Label,
    'Business Owner/Manager'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'businessOwnerMonthlyOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'businessOwnerInitialOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 20)
  businessOwnerInitialOffline: string;

  // added email for PCI
  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Email')
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'emailInitialOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'emailInitialOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'emailInitialOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 301)
  emailInitialOnline: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorInitialPayorIsSomeoneElseAndOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Email')
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'emailInitialOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'emailInitialOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'emailInitialOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 301)
  emailInitialOffline: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Email')
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'emailMonthlyOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'emailMonthlyOffline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'emailMonthlyOffline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 300)
  emailMonthlyOffline: string;

  @PropertyDecorator(
    DecoratedModelConfiguration.SubGroup,
    'ApplicationPayorMonthlyPayorIsSomeoneElseAndOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Label, 'Email')
  @PropertyDecorator(
    DecoratedModelConfiguration.AnswerTag,
    'emailMonthlyOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.MetaKeyName,
    'emailMonthlyOnline'
  )
  @PropertyDecorator(
    DecoratedModelConfiguration.CopyMapper,
    'emailMonthlyOnline'
  )
  @PropertyDecorator(DecoratedModelConfiguration.Order, 300)
  emailMonthlyOnline: string;

  get billingOfflinePhone(): string {
    let billingOfflinePhone;
    if (this.businessNameMonthlyOffline) {
      switch (this.methodOfPayment) {
        case 'BANK DRAFT':
          billingOfflinePhone = this.phoneOnCheck;
          break;
        case 'DIRECT BILLING':
          billingOfflinePhone = this.billingPhone;
          break;
      }
    } else if (this.businessNameMonthlyOnline) {
      billingOfflinePhone = this.authorizedPhoneMonthly;
    }
    if (this.businessNameInitialOffline) {
      switch (this.initialPaymentBy) {
        case 'BANK DRAFT':
          billingOfflinePhone = this.initPmtBankPhoneOnCheck;
          break;
        case 'CREDIT CARD':
          billingOfflinePhone = this.iniPmtCCBillingPhone;
          break;
      }
    } else if (this.businessOwnerInitialOnline) {
      billingOfflinePhone = this.authorizedPhoneInitial;
    }
    return billingOfflinePhone;
  }

  // based on the selection set the 'specific date' or 'Date of Application Approval' in the question level
  get paymentSpecificDate(): string {
    let paymentSpecificDate;
    if (this.requestedEffectiveDate === 'SpecificDate') {
      paymentSpecificDate = 'Specific Date of ' + this.nextOrSpecificDate;
    } else if (this.requestedEffectiveDate === 'OnTheNext') {
      paymentSpecificDate = 'on the next Date';
    } else {
      paymentSpecificDate = 'Date of Application Approval';
    }
    return paymentSpecificDate;
  }
}
