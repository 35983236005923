<div class="container-fluid p-4">
  <h4 class="p-4 text-danger">Environment = {{ environment }}</h4>
  <p class="pl-4 mb-0"><b>User Name:</b> {{ userName }}</p>

  <div class="p-4">
    <h4 style="display: inline;">Current Role</h4>
    <span><strong> (applicable role for the current environment)</strong></span>
    <div *ngFor="let role of roles">
      <strong>{{ role }}</strong>
    </div>
  </div>

  <div class="p-4">
    <div *ngIf="errorMessage === undefined; else errorTemplate">
      <h3>CallZone User Information</h3>
      <div class="row">
        <div class="col-1 font-weight-bold">Email:</div>
        <div class="col-11">{{ user?.email }}</div>
      </div>
      <div class="row">
        <div class="col-1 font-weight-bold">Name:</div>
        <div class="col-11">{{ user?.name }}</div>
      </div>
      <div class="row">
        <div class="col-1 font-weight-bold">Resource Id:</div>
        <div class="col-11">{{ user?.resourceId }}</div>
      </div>
    </div>
    <ng-template #errorTemplate>
      <h3>CallZone Error</h3>
      <p class="red">{{ errorMessage }}</p>
    </ng-template>
  </div>
</div>
