import {
  FilterParameter,
  FilterConfiguration,
} from '../question-filter.service';
import { BaseFilter } from './base-filter';

export class SameLastNameFilter extends BaseFilter {
  Type = 'SameLastNameFilter';
  parse(value1, comparator, value2) {
    switch (comparator) {
      case '>':
        return value1 > value2;
      case '<':
        return value1 < value2;
      case '===':
        return value1 === value2;
      case '!==':
        return value1 !== value2;
    }
  }
  Filter(parameter: FilterParameter) {
    let canDisplay = false;
    if (parameter.config.comparisonValue) {
      parameter.config.value = parameter.application[
        parameter.config.comparisonValue.split('.')[0]
      ][parameter.config.comparisonValue.split('.')[1]].toString();
    }

    if (parameter.config.params) {
      const comparisonOperator = parameter.config.params.filter((a) => {
        if (a.key === 'comparisonFilter') {
          return a.value;
        }
      });
      const parentProperty = parameter.config.path.split('.')[0];
      const childProperty = parameter.config.path.split('.')[1];
      if (
        !Array.isArray(parameter.application[parentProperty]) &&
        this.parse(
          parameter.application[parentProperty][childProperty],
          comparisonOperator[0],
          parameter.config.value
        )
      ) {
        canDisplay = true;
      } else if (Array.isArray(parameter.application[parentProperty])) {
        const value = parameter.application[parentProperty].filter(
          (obj) =>
            this.parse(
              obj[childProperty],

              comparisonOperator[0].value,

              parameter.config.value
            ) && obj.relationship === 'S'
        );
        if (value.length > 0) {
          canDisplay = true;
        }
      }
      return canDisplay;
    }
  }
}
