import { Injectable } from '@angular/core';
import { AddOnApplication } from 'src/app/underwriting/components/add-on/addon-application';
import { environment } from 'src/environments/environment';
import { HandledHttpClient } from '../handledHttpClient';

@Injectable({
  providedIn: 'root',
})
export class AddonApiService {
  private baseUrl: string;
  constructor(private http: HandledHttpClient) {
    this.baseUrl = environment.config.apis.vcall;
  }

  getAddOnApplication(appId: number) {
    return this.http.get(
      this.baseUrl +
        '/application/' +
        encodeURIComponent(appId) +
        '/AddOnApplication',
      {
        errorMessage: `Error in getting add on application details`,
      }
    );
  }

  insertAddOnApplication(application: AddOnApplication) {
    return this.http.post(this.baseUrl + '/AddOnApplication', application, {
      errorMessage: `Error in saving add on application`,
      successMessage: 'Add on Application added successfully!',
    });
  }

  insertAddOnApplicant(id: string, applicant: any) {
    return this.http.post(
      this.baseUrl +
        '/AddOnApplication/' +
        encodeURIComponent(id) +
        '/Applicant/',
      applicant,
      {
        // observe: 'response' as 'body',
        errorMessage: `Error in inserting addon applicant`,
        successMessage: 'Applicant Added Successfully'
      }
    );
  }

  updateAddOnApplicant(id: string, applicantId: string, applicant: any) {
    return this.http.put(
      this.baseUrl +
        '/AddOnApplication/' +
        encodeURIComponent(id) +
        '/Applicant/' +
        encodeURIComponent(applicantId),
      applicant,
      {
        errorMessage: `Error in inserting addon applicant`,
        successMessage: 'Applicant Updated Successfully'
      }
    );
  }

  deleteAddOnApplicant(id: string, applicantId: string) {
    return this.http.delete(
      this.baseUrl +
        '/AddOnApplication/' +
        encodeURIComponent(id) +
        '/Applicant/' +
        encodeURIComponent(applicantId),
      {
        errorMessage: `Error in deleting applicant`,
        successMessage: 'Applicant Deleted Successfully'
      }
    );
  }
}
