import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import {
  BaseQuestionType,
  NavigationAwareQuestionType,
  NavigationDirection,
  NavigationArgument,
} from '../../../types/baseQuestionType';
import { BillingInformation } from '../../payor-information/payor-information.component';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { UiServiceService } from 'src/app/shared/services/ui-service.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { Payment } from 'src/app/shared/models/application/payment';
import { Router, ActivatedRoute } from '@angular/router';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { Appointment } from 'src/app/shared/models/appointment';
import { Answer } from 'src/app/shared/models/application/answer';
import { first } from 'rxjs/operators';
import { ProcessingService } from 'src/app/shared/services/processors/processing.service';
import { ProcessorModel } from 'src/app/shared/services/processors/processor-model';
import { AppointmentAnswerService } from 'src/app/shared/services/appointmentAnswer/appointmentAnswer.service';
import { UserProfileService } from 'src/app/shared/services/user/user-profile.service.service';
import {
  thirdPartyIntroductionMessage,
  thirdPartyVoicemailMessage,
  voiceMailMessage,
  voiceMailMessageFollowup,
} from 'src/app/appointments/voice-mail-modal/voice-mail-modal';
import { CallZoneUser } from 'src/app/shared/models/callzone-user';
@Component({
  selector: 'app-third-party-payor-verification',
  templateUrl: './third-party-payor-verification.component.html',
  styleUrls: ['./third-party-payor-verification.component.scss'],
})
export class ThirdPartyPayorVerificationComponent
  extends BaseQuestionType<BillingInformation>
  implements OnDestroy, AfterViewInit {
  details: BillingInformation = new BillingInformation(null);
  payorOfflineMonthly: Answer;
  payorOfflineInitial: Answer;

  appointment: Appointment;
  processorModel: ProcessorModel = {};
  @ViewChild('callAnswered') callAnsweredSelection: ElementRef;
  @ViewChild('noResponse') noResponseSelection: ElementRef;
  openModal = false;
  thirdPartyVoicemailMessage: string;
  thirdPartyIntroMessage: string;
  businessName: string;
  businessOwner: string;
  answers: Answer[];
  agent: CallZoneUser;

  constructor(
    protected script: ScriptService,
    public appService: ApplicationService,
    protected ui: UiServiceService,
    private router: Router,
    private appointmentService: AppointmentService,
    private userProfileService: UserProfileService,
    private appointmentAnswerService: AppointmentAnswerService,
    private processor: ProcessingService,
    private cdr: ChangeDetectorRef
  ) {
    super(script, appService, ui);
    this.agent = new CallZoneUser();
    const userProfile = this.userProfileService.getUserProfile();
    this.agent.name = userProfile.name;
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((application) => {
        const payment = application.payment;
        if (
          payment.businessNameMonthlyOnline !== undefined ||
          payment.businessNameMonthlyOffline !== undefined
        ) {
          this.businessName = payment.businessNameMonthlyOnline
            ? payment.businessNameMonthlyOnline
            : payment.businessNameMonthlyOffline;
          this.businessOwner = payment.businessOwnerMonthlyOnline
            ? payment.businessOwnerMonthlyOnline
            : payment.businessOwnerMonthlyOffline;
        } else {
          this.businessName = payment.businessNameInitialOnline
            ? payment.businessNameInitialOnline
            : payment.businessNameInitialOffline;
          this.businessOwner = payment.businessOwnerInitialOnline
            ? payment.businessOwnerInitialOnline
            : payment.businessOwnerInitialOffline;
        }
      })
    );
  }

  ngAfterViewInit() {
    this.thirdPartyVoicemailMessage = this.uiService.interpolate(
      this.question.details.thirdPartyVoicemailMessage,
      this
    );
    this.thirdPartyIntroMessage = this.uiService.interpolate(
      this.question.details.thirdPartyIntroductionMessage,
      this
    );
    this.openModal = true;
    this.cdr.detectChanges();
  }
  ResetView() {
    this.callAnsweredSelection.nativeElement.checked = false;
    this.noResponseSelection.nativeElement.checked = false;

    if (this.answer) {
      this.callAnsweredSelection.nativeElement.checked =
        this.answer.value.toString() === 'PayorIsSomeoneElseAndOnlineNow';
      this.noResponseSelection.nativeElement.checked = !(
        this.answer.value.toString() === 'PayorIsSomeoneElseAndOnlineNow'
      );
    }
  }

  ApplicationChanged(): void {}

  QuestionChanged(q: Question): void {
    this.subscriptions.push(
      this.appointmentService.SelectedAppointment.subscribe(
        (a) => (this.appointment = a)
      )
    );
    this.payorOfflineMonthly = this.applicationService.GetAnswerForAnswerTag(
      'Payment.ApplicationPayorMonthly'
    );

    this.payorOfflineInitial = this.applicationService.GetAnswerForAnswerTag(
      'Payment.ApplicationPayorInitial'
    );
  }

  CanGoNext(): boolean {
    return true;
  }

  Navigating(direction: NavigationDirection): NavigationArgument {
    const arg = new NavigationArgument();

    if (
      direction === NavigationDirection.Next &&
      this.callAnsweredSelection.nativeElement.checked
    ) {
      this.continue();
    } else if (this.noResponseSelection.nativeElement.checked) {
      this.router.navigate(['/']);
    }
    arg.stopPropagation = true;

    return arg;
  }

  async optionChanged(isCallAnswered: boolean) {
    if (isCallAnswered) {
      this.continue();
    } else {
      const userProfile = this.userProfileService.getUserProfile();
      const stageResult = this.endCall();

      // TODO: Move this logic to an application processor
      this.appointmentAnswerService.updateAppointmentStatus(
        this.question,
        'complete'
      );

      this.applicationService.GetCallLog(this.application.id.toString());

      await this.applicationService.PersistAnswers();

      // TODO: Move this logic to an application processor
      if (userProfile) {
        this.appointmentService.EndCallAsCompleted(
          this.application,
          this.appointment,
          userProfile,
          0,
          null
        );
      }
      this.saveAnswer(true, 'PayorIsSomeoneElseAndNotAvailable'); // lint error was fixed
    }
  }

  continue() {
    const obj = new Answer();

    this.saveAnswer(true, 'PayorIsSomeoneElseAndOnlineNow');

    this.script.GoToNextQuestion(this.question);
  }

  async endCall(): Promise<void> {
    const val = await this.processor.RunCompleteStage(this.processorModel);
    return val;
  }

  closeModal() {
    this.getNotifyEvent();
  }

  getNotifyEvent() {
    this.openModal = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
