import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsComponent } from './appointments.component';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent as AppointmentsListComponent } from './list/list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { NotesDetailComponent } from './notes/notes.component';
import { PolicyDetailComponent } from './policy/policy.component';
import { DetailComponent as AppointmentsDetailComponent } from './detail/detail.component';
import { VoiceMailModalComponent } from './voice-mail-modal/voice-mail-modal.component';
import { RecallQuestionsComponent } from './recall-questions/recall-questions.component';
import { EzappNotesComponent } from './ezapp-notes/ezapp-notes.component';
import { SearchAppointmentsComponent } from './search-appointments/search-appointments.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AddendumNotesComponent } from './addendum-notes/addendum-notes.component';
import { SafePipeModule } from 'safe-pipe';
import { RescheduleAppointmentComponent } from './reschedule-appointment/reschedule-appointment.component';

const routes: Routes = [
  {
    path: '',
    component: AppointmentsComponent,
  },
  {
    path: 'search',
    component: SearchAppointmentsComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSpinnerModule,
    SafePipeModule,
  ],
  declarations: [
    AppointmentsComponent,
    AppointmentsDetailComponent,
    AppointmentsListComponent,
    NotesDetailComponent,
    PolicyDetailComponent,
    VoiceMailModalComponent,
    RecallQuestionsComponent,
    EzappNotesComponent,
    AddendumNotesComponent,
    SearchAppointmentsComponent,
    RescheduleAppointmentComponent,
  ],
  exports: [AppointmentsDetailComponent],
})
export class AppointmentsModule {}
