import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapValue',
})
export class MapValuePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    } else {
      return JSON.stringify(value).split('"').join('');
    }
  }
}
