import { Injectable } from '@angular/core';
import { ProcessingResult } from '../processing-result';
import { Processor } from '../processor';
import { ProcessorModel } from '../processor-model';
import { CopyAnswerTagProcessor } from './copyAnswerTagProcessor';

@Injectable({
  providedIn: 'root',
})
export abstract class CopyPaymentEffectiveDateProcessor
  extends CopyAnswerTagProcessor
  implements Processor {
  mustWaitForFinish: boolean;
  stopIfError: any;
  name = 'CopyEffectiveDate';

  Process(model: ProcessorModel): Promise<ProcessingResult> {
    return this.copy(
      'Payment.MonthlyEffectiveDate',
      'Payment.BankInitialAuthorization'
    );
  }
}
