import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalService,
} from '@azure/msal-angular';
import { LoggerService } from './core/logger.service';
import {
  UIModalBehavior,
  UIModalParameter,
  UiServiceService,
} from './shared/services/ui-service.service';
import { versionNo } from './version/version-number';
import { environment } from 'src/environments/environment';

import { VersionService } from './version/version-service';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApplicationStringsInit } from './APP_INITIALIZERS/application-strings.init';
import { AnalyticsService } from './shared/services/analytics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentService } from './shared/services/appointment/appointment.service';
import { ApplicationService } from './shared/services/application/application.service';
import { ScriptService } from './shared/services/script/script.service';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, DoCheck {
  title = 'app';
  version = versionNo;
  isIframe = false;
  private readonly destroying$ = new Subject<void>();

  constructor(
    private uiService: UiServiceService,
    private router: Router,
    private authService: MsalService,
    private logger: LoggerService,
    private msalBroadcastService: MsalBroadcastService,
    private analyticsService: AnalyticsService,
    private versionService: VersionService,
    private stringsSerivce: ApplicationStringsInit,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private modalService: NgbModal,
    private appService: ApplicationService,
    private scriptService: ScriptService,
    private appointmentService: AppointmentService,
    private cdr: ChangeDetectorRef
  ) {}

  openModal = false;
  modalParameters: UIModalParameter = new UIModalParameter();
  subscription: any;
  postLoginPerformed = false;
  getOrImportModal = false;

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.postLogin();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this.destroying$)
      )
      .subscribe((result) => {
        this.postLogin();
      });
    if (environment.features.clarity) {
      this.analyticsService.clarityInjection(environment.clarity);
    }

    this.uiService.ApplicationModalTrigger.subscribe((reason) => {
      if (reason) {
        this.openModal = true;
        this.modalParameters = reason;
      }
    });

    this.appService.importApplicationModal.pipe(takeUntil(this.destroying$)).subscribe((res) => {
      this.getOrImportModal = res;
    })
  }

  async postLogin() {
    if (this.postLoginPerformed) {
      return;
    }

    this.versionService.versionsCheck();
    const account = this.authService.instance.getAllAccounts()[0];
    this.authService.instance.setActiveAccount(account);
    this.postLoginPerformed = true;
    await this.stringsSerivce.Init();
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  okClickOnPermissionModal(params: UIModalParameter) {
    if (params.modalBehavior === UIModalBehavior.RedirectToDashboard) {
      this.appointmentService.Clear();
      this.appService.Clear();
      this.scriptService.Clear();
      this.openModal = false;
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
      this.router.navigate(['/']);
    } else if (params.modalBehavior === UIModalBehavior.Refresh) {
      window.location.reload();
    } else {
      this.openModal = false;
    }
  }

  closeModal() {
    this.getOrImportModal = false;
  }

  ngOnDestroy() {
    this.destroying$.next(null);
    this.destroying$.complete();
  }

  ngDoCheck() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  scrollToTop() {
    window.scroll(0, 0);
  }
}
