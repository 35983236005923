<div *ngIf="menuVisible">
  <div
    *ngIf="reasons"
    class="dropdown-menu dropdown-menu-right dropdown-endcall"
    aria-labelledby="menu"
  >
    <div *ngFor="let reason of reasons">
      <a class="dropdown-item" (click)="openReasonModal(reason)">{{
        reason.stopCallLabel
      }}</a>
    </div>
  </div>
</div>
<app-modal
  [open]="openModal"
  [buttonsConfig]="{
    modalMode: 'form',
    submitButtonName: isRescheduleEnabled
      ? 'Stop Call and Reschedule'
      : 'Stop Call',
    cancelButtonName: 'Cancel'
  }"
  (actions)="submiStopCall($event)"
  [formValidation]="stopCallForm.valid"
>
  <div title>
    <h4 class="modal-title" id="modal-basic-title">
      <span> Update Reasons for Stop Call </span>
    </h4>
  </div>
  <div body>
    <form [formGroup]="stopCallForm" id="addEndCall">
      <div>
        <div class="form-group">
          <label for="reason">Reason *</label>
          <div class="input-group">
            <select
              formControlName="reason"
              class="form-control"
              placeholder="Reason"
              autocomplete="disabled"
            >
              <option
                *ngFor="let reason of reasons"
                [value]="reason.stopCallLabel"
                >{{ reason.stopCallLabel }}</option
              >
            </select>
          </div>
          <div
            *ngIf="
              stopCallForm.controls['reason'].invalid &&
              (stopCallForm.controls['reason'].dirty ||
                stopCallForm.controls['reason'].touched)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="stopCallForm.controls['reason'].errors?.required">
              Reason is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="notes">Notes *</label>
          <div class="input-group">
            <textarea
              name="notes"
              id="notes"
              formControlName="notes"
              required
              class="form-control"
              placeholder="Please add notes here"
              rows="7"
              maxlength="160"
              autocomplete="off"
            ></textarea>
          </div>
          <div
            *ngIf="
              stopCallForm.controls['notes'].invalid &&
              (stopCallForm.controls['notes'].dirty ||
                stopCallForm.controls['notes'].touched)
            "
            class="mt-1 text-danger small"
          >
            <div *ngIf="stopCallForm.controls['notes'].errors?.required">
              Notes is required.
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-modal>
