import { VcallQuestionTypeBooleanDetail } from 'src/app/vcall/questions/types/boolean/boolean.component';
import { Answer } from '../application/answer';
import { RadiobuttonListComponentDetail } from 'src/app/vcall/questions/types/radiobuttonList/radiobutton-list.component';
import { VcallQuestionTypePaymentDetail } from './paymentDetail';
import { VcallQuestionTypeClosingDetail } from './closingDetail';
import { VcallQuestionTypeMedicalAilmentDetail } from 'src/app/vcall/questions/medical-ailment/medical-ailment.component';

export class DetailQuestion {
  id: number;
  text: string;
  details:
    | VcallQuestionTypeBooleanDetail
    | VcallQuestionTypePaymentDetail
    | VcallQuestionTypeClosingDetail
    | RadiobuttonListComponentDetail
    | VcallQuestionTypeMedicalAilmentDetail
    | any;
  type: string;
  answerTag: string;
  displayWhen: string | any;
  sequence: number;
  value: string | any;
  allowNextForValue: any;
  required: boolean;
  instructions: string;
  displayTag: string;
}

export enum DrilldownStatus {
  Completed = 'Completed', // 2,
  Inprogress = 'Inprogress', // 1,
  Pending = 'Pending', // 0,
}
